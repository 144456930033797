 <template>

    <header id="header">
        <a href="/home" id="logo">
            <h1><img src="@/assets/images/common/hlmandologo.svg" alt="logo"></h1>
        </a>
        <div class="btn_box settings" @click="openModal()">
            <button type="button">
                <img src="@/assets/images/common/header_settings.svg" alt="alert">
            </button>
        </div>
        <div class="btn_box" :class="{'msgAlert' : msgStatus}">
            <a href="/commu/message">
                <img src="@/assets/images/common/header_noti.svg" alt="alert">
            </a>
        </div>
    </header>
    <div id="memModal" ref="modalWrap" style="display:none;">
        <div class="info_modal radiusbox">
            <strong class="modal_title">사용자 정보 변경</strong>
            <div id="management">
                <div class="row_box" style="height:140px">
                    <div class="data_key">
                        <div class=img_box>
                            <img :src="imagePath" alt="">
                        </div>
                    </div>
                    <div class="data_val">
                        <input type="file" id="uploadFile" ref="uploadFile" @change="handleFileUpload"  accept="image/*">
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">이름</div>
                    <div class="data_val">
                        <input type="text" ref="memName" v-model="memName" maxlength="30" placeholder="이름을 입력해 주세요.">
                        <div class="text_length">
                            <span>{{memName.length}}</span>/30
                        </div>
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">연락처</div>
                    <div class="data_val" >
                        <input type="text" ref="memPhone" v-model="memPhone" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="30" placeholder="-를 포함하지 않은 전화번호를 입력해주세요.">
                    </div>
                </div>
            </div>
            <div class="save_box">
                <button type="button" class="close_btn" @click="closeModal()">취소</button>
                <button type="button" class="mcbtn" @click="infoSave()" :disabled="!requireCheck">저장</button>
                <button type="button" class="mem_del" @click="delUser()">사용자 탈퇴</button>
            </div>
            <button type="button" @click="closeModal()" class="btn_close"></button>
        </div>
    </div>
 </template>
 <script>
    import common from '@/assets/js/common';
    import Cookies from 'js-cookie';
    import { mapActions } from 'vuex';
    export default {
        name: 'HeaderLayout',
        setup(){
            
        },
        data(){
            return{
                msgStatus: '',
                memInfo: '',
                memID: '',
                memName: '',
                memPhone: '',
                imagePath: "", 
            };
        },
        computed:{
            requireCheck() {
                return this.memName.length > 0 && this.memPhone.length > 0;
            },
        },
        async mounted() {
            

            this.checkMessage();

            this.memInfo = await this.getMemberInfo();

            this.memID = this.memInfo.memberId;

            
        },
        methods:{
            ...mapActions(['login', 'logout']), 
            async logoutAction(){
                console.log("logout");
                await this.logout();
                this.$router.push('/login');
            },
            async delUser(){
                const comfirmVal = confirm('본 서비스에서 회원 탈퇴를 하시겠습니까? \n 회원 탈퇴 시 본 서비스에 다시 접속하실 수 없으며 \n 탈퇴일 기준 1년간 개인 정보 및 사용 이력을 보관 후 완전 삭제합니다. \n 동의하시겠습니까?');
                
                if(comfirmVal){
                    await this.delMember(this.memID);
                    this.logoutAction();
                }
            },
            async infoSave(){
                const comfirmVal = confirm('변경 사항을 저장하시겠습니까?');
                
                if(comfirmVal){
                    // 이미지 변경 
                    const formData = new FormData();
                    const fileUpload = this.$refs.uploadFile.files;

                    console.log(fileUpload);
                    if(typeof fileUpload != "undefined" && fileUpload != null){

                        if(fileUpload[0] != undefined){
                            
                            formData.append("multipartFile", fileUpload[0]);

                            await this.patchMemberfile(formData, this.memID);

                        }else{

                            formData.append("multipartFile", null);

                            await this.patchMemberfile(formData, this.memID);
                        }
                        
                    }

                    
                    // 이름 전화번호 변경
                    const data = {
                                    "name": this.memName,
                                    "phone": this.memPhone
                                }
                            
                    
                    await this.patchMemberInfo(data, this.memID);

                    

                    this.memInfo = await this.getMemberInfo();
                    
                    
                    const _userCK = JSON.stringify(Cookies.get('user'));
                    const _userData = JSON.parse(_userCK);
                    let _userObj = JSON.parse(_userData);

                    _userObj.name = this.memInfo.name;
                    _userObj.profileImage = this.memInfo.profileImage;
                    
                    Cookies.set('user', JSON.stringify(_userObj));
                    // 페이지 세로고침
                    location.reload();
                    
                }
            },
            handleFileUpload() {
                const fileInput = this.$refs.uploadFile;
                const file = fileInput.files[0];
                // console.log(file);
                if (file) {
                    this.imagePath = URL.createObjectURL(file);
                }
            },
            async checkMessage() {
                const data = await this.getkMessageStatus();
                this.msgStatus = data;
            },
            openModal(){
                const $modal = this.$refs.modalWrap;
                $modal.style.display = "block";
            },
            closeModal(){
                const $modal = this.$refs.modalWrap;
                $modal.style.display = "none";
            },
            // API
            async getkMessageStatus() {
                try {
                    const response = await common.apiGet('/notification/alert-check');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getMemberInfo() {
                try {
                    const response = await common.apiGet('/member/info');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async patchMemberfile(postData, itemID) {
                let response = "";
                try {
                    response = await common.apiPatchMulti('/member/info/file/'+itemID, postData);
                } catch (error) {
                    response = error.response;
                    console.error('Error fetching data:', error);
                }
                // console.log(response);
                return response;
            },
            async patchMemberInfo(postData, itemID) {
                let response = "";
                try {                    
                    response = await common.apiPatch('/member/info/'+itemID, postData);
                } catch (error) {
                    response = error.response;
                    console.error('Error posting data:', error);
                }
                return response;
            },
            async delMember(itemID) {
                try {
                    await common.apiDelete('/member/info/'+itemID);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    #header{
        width: 100%;
        height: 80px;
        border: 1px solid #EBEBEB;
        box-sizing: border-box;
        position: relative;
        #logo{
            padding-left: 40px;
            position: absolute;
            top: 50%;
            left: 80px;
            transform: translate(0, -50%);
        }
        .btn_box{
            position: absolute;
            top: 50%;
            right: 110px;
            transform: translate(0, -50%);
            &.settings{
                right: 50px;
            }
            &.msgAlert{
                &:after{
                    display:block;
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-radius: 4px;
                    background-color: #F4514D;
                    position: absolute;
                    top: -2px;
                    right: -2px;
                }
            }
        }
    }
    #memModal{
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.7);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        .info_modal{
            width: 640px;
            height: 500px;
            padding: 25px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .modal_title{
                display: block;
                font-size: $fontMenuTitle;
                font-weight: $bold;
                margin-bottom: 30px;
            }
            .save_box{
                width: 100%;
                height: 45px;
                text-align: center;
                position: relative;
                margin-top: 20px;
                button{
                    width: 90px;
                    height: 45px;
                    text-align: center;
                    border-radius: 10px;
                    margin: 0 10px;
                }
                .close_btn{
                    border: 1px solid #D5DAE3;
                }
                .mem_del{
                    width: 120px;
                    color: #fff;
                    background-color: #F4514D;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            .btn_close{
                width: 50px;
                height: 50px;
                background-repeat: no-repeat;
                background-image: url($baseURL+'/common/popup_cancel.svg');
                background-position: center;
                position: absolute;
                top: 6px;
                right: 6px;
            }
        }
        #management{
            .img_box{
                width:120px;
                height:140px;
                background-color: #E5E9EF;
                border: 1px solid #D5DAE3;
                overflow: hidden;
                img{
                    width: 100%;
                }
            }
        }
    }
    @media screen and (min-width: 1680px) {
        #header{
            #logo{
                left: 300px;
                
            }
        }
    }

    
</style>