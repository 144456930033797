<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>장치/배포 관리</span>
                    <span class="active">Fleet 그룹 목록</span>
                </div>
                <h2>Fleet 그룹 목록</h2>
                <div class="title_btn">
                    <button type="button" v-if="checkMemberRole" @click="goAddPage()" class="add_project bgicon">신규 Fleet 그룹 등록</button>
                </div>
            </div> -->
            <BreadcrumbLayout pageId="deviceNDelivery_fleetGroupList" :checkRole="manageRole"/>
            <div class="list_wrap">
                <div id="listFilter" class="list_filter_wrap float_box" ref="listFilter">
                    <div class="filter_list_box" ref="filterListBox">
                        <ul class="float_box" ref="filterBox">
                            <li v-if="filterList.length === 0" style="padding-right: 20px;"><span>필터</span> : <span>전체</span></li>
                            <li v-for="(item, index) in filterList" :key="index" ref="filterItem">
                                <span>{{ item.keyName }}</span> : <span>{{ item.value }}</span>
                                <button class="btn_close" @click="removeFilter(index)"></button>
                            </li>
                        </ul>
                    </div>
                    <button class="btn_all_filter" ref="filterAllBtn" @click="filterToggle">전체보기</button>
                </div>
                <div class="table_list">
                    <div class="table_set">
                        <div class="cont_count">
                            <span ii="cont_num">{{dataList.length}}</span> Contents
                        </div>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <!-- <colgroup>
                                <col style="width: 80%;">
                                <col style="width: 20%;">
                            </colgroup> -->
                            <thead>
                                <tr>
                                    <th v-for="(item, index) in columnDefs" :key="index">
                                        <div class="col_name">
                                            {{item.field}}
                                            <button class="btn_filter" @click="showFilter"></button>
                                        </div>
                                    </th>
                                </tr>
                                <tr id="filterWrap" ref="filterInputWrap">
                                    <th class="filter_input" v-for="(item,index) in columnId" :key="index">
                                        <div class="input_box" :data-id="item.id">
                                            <input type="text" @focus="setActive"  @keydown.enter="addFilter" >
                                            <button class="btn_add" ref="btnAddfilter" @click="addFilter" :data-index="index"></button>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="m_pointer" v-for="(item, index) in dataListSliced" :key="index" @click="goPage(item)">
                                    <td>{{item.fleetName}}</td>
                                    <td>{{item.edgeDeviceCount}}</td>
                                    <td>{{item.status}}</td>
                                    <td>{{item.prjTaskCnt}}</td>
                                    <td>{{item.registrantName}}</td>
                                    <td>{{item.belonging}}</td>
                                    <td>{{item.created}}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    <div class="list_table_footer">
                        <PaginationUi
                            :totalItems="totalItems"
                            :itemsPerPage="itemsPerPage"
                            @page-changed="onPageChanged"
                        ></PaginationUi>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import PaginationUi from "@/components/PagenationUi.vue";
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';

    import '@/assets/js/filter';
    import gsap from 'gsap';
    import common from '@/assets/js/common'
    export default {
        name: 'fleetGroupList',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            PaginationUi,
            BreadcrumbLayout,
        },
        computed:{
        },
        data(){
            const filterList = ([]);
            const filterRowData =([]);

            return{
                filterList,
                filterRowData,
                
                perPageList: [],
                perPage: 0,
                
                totalItems: 100,
                itemsPerPage: 10,
                currentPage: 1,
                
                dataList: [],
                dataListSliced:[],
                
                columnDefs: [
                    { field: "Fleet 그룹명" },
                    { field: "Edge Device 수" },
                    { field: "Status" },
                    { field: "연결 PRJ/TASK 수" },
                    { field: "등록자명" },
                    { field: "소속" },
                    { field: "등록일" },
                ],
                columnId:[
                    { id: "fleetName" },
                    { id: "edgeDeviceCount" },
                    { id: "status" },
                    { id: "prjTaskCnt" },
                    { id: "registrantName" },
                    { id: "belonging" },
                    { id: "created" },
                ],
                isfilter: true,
                filterDataList:{
                    origin:{}
                },

                // role
                viewRole: false,
                manageRole: false,
            };
        },
        mounted() {
            // Role
            this.setRole();

            this.setFleetGroupList();
        },
        methods:{
            async setRole() {
                this.manageRole = await common.getUserRole('type2');
                this.viewRole = await common.getUserRole('type1');

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },


            async setFleetGroupList() {
                const dataList = await this.getFleetGroupList();

                if(typeof dataList !== "undefined" && dataList !== null && dataList !== "") {
                    this.dataList = dataList;

                    this.dataList.forEach((item, index) => {
                        this.dataList[index]['prjTaskCnt'] = item.connectedProjectCount+'/'+item.connectedTaskCount;
                    });
                }
                
                this.currentPage = 1;
                this.totalItems = this.dataList.length;
                this.displayDataPerPage(this.dataList);
            },

            goPage(item){
                if ( this.viewRole ) {
                    const fleetGroupId = item.id;
                    this.$router.push({ 
                        name: 'detailFleetGroup',
                        params: { fleetGroupId: fleetGroupId}
                    });
                }
            },

            goAddPage(){
                this.$router.push('/deviceNDelivery/addFleetGroup');
            },
            onPageChanged(page) {
                this.currentPage = page;
                this.displayDataPerPage(this.dataList);
            },

            filterToggle(){
                const $listFilter = this.$refs.listFilter;
                const $filterBox = this.$refs.filterListBox;
                const $filterBtn = this.$refs.filterAllBtn;
                const hasOpenClass = $filterBox.classList.contains("open");
                if(hasOpenClass){
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.remove("open");
                            $filterBtn.classList.remove("active");
                            $filterBtn.innerText = '전체보기'
                        },
                        height: '70px',
                        duration: .3
                    });
                }else{
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.add("open");
                            $filterBtn.classList.add("active");
                            $filterBtn.innerText = '접기'
                        },
                        height: 'auto',
                        duration: .3
                    });
                }
                
            },
            async addFilter(event){
                // console.log("addFilter");
                
                const _this = event.target;
                const $thTag = _this.parentNode.parentNode;
                const $addBtn = $thTag.querySelector("button"); 
                const $input = $thTag.querySelector("input");
                // input 글자수
                const trimVal = $input.value.trim();

                // 컬럼 이름 가져오기
                const keyNameArr = _this.parentNode.parentNode.parentNode.parentNode.querySelector("tr:first-child").querySelectorAll(".btn_filter"); 
                let activeIndex = Array.from(keyNameArr).findIndex(element => element.classList.contains('active'));
                const keyName = String(keyNameArr[activeIndex].parentNode.textContent);                 


                const dataKeyArr = _this.parentNode.parentNode.parentNode.querySelectorAll('.input_box');
                const dataKeyindex = Array.from(dataKeyArr).findIndex(element => element.classList.contains('active'))
                const keyId = String(dataKeyArr[dataKeyindex].getAttribute('data-id')) 

                const obj = {};
                // console.log();
                // console.log(this.getAttribute('data-index'))
                // console.log($input);

                window.filterData.taget = _this.getAttribute('data-index')

                if(0 < trimVal.length){
                    obj["keyId"] = keyId;
                    obj["value"] = trimVal;
                    obj["keyName"] = keyName;
                    $input.value = "";
                    this.filterList.push(obj);
                    this.$refs.filterAllBtn.style.display = "block";
                    $addBtn.parentNode.classList.add("disable");
                    $addBtn.previousElementSibling.disabled = true;
                    this.filterAction();
                }
                
            },
            removeFilter(index) {
                const $filterItem = this.$refs.filterItem;
                const $filterInputWrap = this.$refs.filterInputWrap;
                const dataId = this.filterList[index].keyId.trim();
                // console.log(dataId);
                // console.log($filterInputWrap.querySelector(`[data-id='${dataId}']`).classList);
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).classList.remove("disable");
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).querySelector("input").disabled = false;
                this.filterList.splice(index, 1);
                if($filterItem.length <= 1){
                    this.$refs.filterAllBtn.style.display = "none";
                }
                this.filterAction(true);
            },
            setActive(event) {
                const _this = event.target;
                const $filterDiv = _this.parentNode.parentNode.parentNode.querySelectorAll("div");
                const $filterIcon = _this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                
                for(var i = 0 ; i < $filterDiv.length ; i ++){
                    $filterDiv[i].classList.remove("active");
                }
                _this.parentNode.classList.add("active");
                
                // filter icon 
                let activeIndex = Array.from($filterDiv).findIndex(element => element.classList.contains('active'));
                
                for(var j = 0 ; j < $filterIcon.length ; j ++){
                    $filterIcon[j].classList.remove("active");
                }
                $filterIcon[activeIndex].classList.add("active");

            },


            displayDataPerPage(rowData) {
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                this.dataListSliced =  rowData.slice(startIndex, endIndex);
            },

            filterAction(remove){
                const vm = this;
                // 필터 리스트
                const _filterLsit =  vm.filterList;
                // 테이블 리스트
                let _tableList = vm.dataList;
                if(vm.isfilter){
                    vm.filterDataList.origin = _tableList;
                    vm.isfilter = false;
                }
                
                
                if(_filterLsit.length > 0){
                    const lodash = require('lodash');
                    
                    // 필터 삭제
                    if(remove){
                        let _filterDataListOrign = [];
                        vm.filterDataList.origin.forEach(item => {
                            _filterDataListOrign.push(item);
                        });

                        for( var j = 0 ; j < _filterLsit.length; j++){

                            const searchString = common.getUpper(_filterLsit[j].value);
                            const regexPattern = new RegExp(searchString);

                            // _tableList > 검색 1 리트스 > 검색 2 리스트

                            const keyid = _filterLsit[j].keyId;
                            const filteredData = lodash.filter(_filterDataListOrign, (item) => regexPattern.test(common.getUpper(item[keyid].toString())));
                            vm.dataList = filteredData;
                            _filterDataListOrign = filteredData;
                        }
                    // 필터 추가
                    }else{
                        for( var i = 0 ; i < _filterLsit.length; i++){

                            const searchString = common.getUpper(_filterLsit[i].value);
                            const regexPattern = new RegExp(searchString);

                            // _tableList > 검색 1 리트스 > 검색 2 리스트
                            
                            const keyid = _filterLsit[i].keyId;
                            const filteredData = lodash.filter(_tableList, (item) => regexPattern.test(common.getUpper(item[keyid].toString())));
                            vm.dataList = filteredData;
                        }
                    }
                    
                    // console.log("필터 동작 시작");
                }else{
                    vm.dataList = vm.filterDataList.origin;
                }
                vm.currentPage = 1;
                vm.totalItems = vm.dataList.length;
                vm.displayDataPerPage(vm.dataList);
            },
            showFilter(event){
                const _this = event.target;
                
                const $filterBtn = _this.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                const $filterWrap = document.querySelector("#filterWrap");
                const $filterInput = $filterWrap.querySelectorAll(".filter_input");

                const hasShowClass = $filterWrap.classList.contains("show");
                const hasActiveClass = _this.classList.contains("active");
                
                for(var i = 0 ; i < $filterBtn.length ; i ++){
                    $filterBtn[i].classList.remove("active");
                }
                _this.classList.add("active");
                if(hasShowClass){
                    if(hasActiveClass){
                        $filterWrap.classList.remove("show");
                        _this.classList.remove("active");
                    }
                }else{
                    $filterWrap.classList.add("show");
                }
                let activeIndex = Array.from($filterBtn).findIndex(element => element.classList.contains('active'));
                // console.log(activeIndex);
                // input 
                for(var j = 0 ; j < $filterInput.length ; j ++){
                    $filterInput[j].querySelector("div").classList.remove("active");
                }
                // console.log($filterInput[activeIndex]);
                if(activeIndex > -1){
                    $filterInput[activeIndex].querySelector("div").classList.add("active");
                }
                
                
            },

            // ----- API
            async getFleetGroupList() {
                try {
                    const response = await common.apiGet('/fleet-group');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getMemberRole() {
                let response;
                try {
                    let url = `/member/check-role`;
                    response = await common.apiGet(url);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                }
            },
        }
    }
</script>
<style scoped lang="scss">
.list_wrap{
    position: relative;
    .table_list{
        margin-top: 110px;
        height: 715px;
    }
    .list_filter_wrap{
        position: absolute;
        top: -90px;
    }
    .mando_table{
        tr{
            cursor: pointer;
        }
    }
    
}
.list_wrap .table_list .mando_table thead th .col_name{
        // min-width: auto !important;
        padding-right: 15px;
    }
</style>