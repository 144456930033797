
window.filterData = {
    taget: null,
    originalData: [], // Initialize it to null or any default value
    beforeData:[],
    workData: [],  
};


// window.filterData.taget
// window.filterData.originalData
// window.filterData.workData

