<template>
    <div id="listPagination" class="list_pagination">
        <button type="button" id="btnPrevious" @click="prevPage" :disabled="pageNum === 1"></button>
        <div id="listNum">
            <div v-if="moreThenFiveContent">
              <button type="button" @click="goToPage($event,1)" v-if="showFirst">1</button>
              <button type="button" disabled v-if="showFirst">...</button>
              <button 
              v-for="page in visiblePages"
              :key="page"
              type="button"
              @click="goToPage($event, page)"
              :class="{ active: pageNum == getButtonText(page) }"
              >
              {{ getButtonText(page) }}
              </button>
              <button type="button" disabled v-if="showLast">...</button>
              <button type="button" @click="goToPage($event, totalPages)" v-if="showLast">{{ totalPages }}</button>
            </div>
            <div v-else-if="lessThenFiveContent">
              <button 
              v-for="page in totalPages"
              :key="page"
              type="button"
              @click="goToPage($event, page)"
              :class="{ active: pageNum == page }"
              >
              {{ page }}
              </button>
            </div>
            
        </div>
        <button type="button" id="btnNext" @click="nextPage" :disabled="pageNum === totalPages"></button>
    </div>
</template>
<script>
export default {
    name: 'PaginationUi',
    props: {
        totalItems: Number,
        itemsPerPage: Number,
    },
    data() {
        return {
            currentPage: 1,
            visiblePages: 5,
            pageNum:1,
            showLast: true,
            showFirst: false,
            lessThenFiveContent: false,
            moreThenFiveContent: true,
        };
    },
    mounted(){
      this.pageNationSet();
      this.calcPagenation();
      
      

    },
    watch: {
      /*
        최소 totalPages 변경 감지후 pagenation > totalItems 토탈아이탬 감지후 변경 
        ** 검색전 리스트 갯수와 필터 검색후 리스트 겹치면 pagenation 초기화 안됨
      */
      
      totalPages: {
          handler: "calcPagenation",// totalPages가 변경될 때 calcPagenation 메서드 호출
          immediate: true, // 한번만 적용되도록
      },
      totalItems: {
        handler: "calcPagenation",
        immediate: false, 
      },
     },
    computed: {
        totalPages() {
          if (this.totalItems <= this.itemsPerPage) {
          return 1;
          }
          return Math.ceil(this.totalItems / this.itemsPerPage);
        },
        startPage() {
          if (this.currentPage <= 2) {
            return 1;
          } else if (this.currentPage >= this.totalPages - 1) {
            return this.totalPages - 3;
          } else {
            return this.currentPage - 2;
          }
        },
        
     },
     methods: {
      pageNationSet(){
        // console.log(this.visiblePages);
        // console.log(this.totalPages);
        if(this.totalPages < this.visiblePages){
          this.visiblePages = this.totalPages;
        }
        // console.log(this.visiblePages);
        
      },
      goToPage(event) {
        // console.log(page);
        // console.log(event.target.innerHTML);
        // console.log(this.visiblePages);
        this.pageNum = Number(event.target.innerHTML);
        this.currentPage = this.pageNum;
        this.$emit('page-changed', this.currentPage);
        // console.log('this.currentPage : ' + this.currentPage);
       
        if(this.pageNum == 1){
            this.showLast = true;
            this.showFirst = false; 
            // console.log(this.showLast);
        }
      },
      prevPage() {
        if (this.pageNum > 1) {
          this.pageNum--;
          this.$emit('page-changed', this.pageNum);
        }
      },
      nextPage() {
        if (this.pageNum < this.totalPages) {
          this.pageNum++;
          this.$emit('page-changed', this.pageNum);
        }
      },
      getButtonText(page) {
        if (this.pageNum > 3) {
          // const centeredPage = this.currentPage;
          // console.log(this.totalPages);
          // 마지막 조건 걸기 토탈 넘거가지 않도록  
          if(this.pageNum > (this.totalPages-4)){
            // console.log(this.pageNum);
            this.showLast = false;
            this.showFirst = true; 
            const centeredPage = (this.totalPages-5) + page;
            // console.log("centeredPage : " + centeredPage);
            return centeredPage;
          } else if (this.pageNum == (this.totalPages-4)){
            // console.log(this.pageNum);
            this.showLast = false;
            this.showFirst = true; 
            const centeredPage = Number(this.pageNum) - 2 + (page-1);
            // console.log("centeredPage : " + centeredPage);
            return centeredPage;
          } else{
            this.showLast = true;
            this.showFirst = false; 
            const centeredPage = Number(this.pageNum) - 2 + (page-1);
            // console.log("centeredPage : " + centeredPage);
            return centeredPage;
          }
        } else {
          // console.log(this.showLast);
          this.showLast = true;
          this.showFirst = false;
          return page;
        }
    },
    calcPagenation() {
      console.log("---- calcPagenation ----");
      this.currentPage = 1;
      this.pageNum = this.currentPage;
      if(this.totalPages <= 5) {
        console.log("this.totalPages : " + this.totalPages);
        this.moreThenFiveContent = false;
        this.lessThenFiveContent = true;
      } else {
        this.moreThenFiveContent = true;
        this.lessThenFiveContent = false;
      }
    },
    

  },
     
}
</script>