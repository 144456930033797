<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>데이터/ TASK 운영</span>
                    <span>차량 모니터링 목록</span>
                    <span class="active">Remote Diagnostic Service 대시보드</span>
                </div>
                <h2>[TASK_RD] {{taskData.taskName}}</h2>
            </div> -->
            <BreadcrumbLayout pageId="monTASK_taskRD" :name="'[TASK_RD] ' + taskData.taskName"/>
            <div class="item_info">
                <div class="item_title">
                    <strong class="task_project">[PROJECT] {{prjData.project_name}}</strong>
                    <div class="item_info_btn_box">
                        <button type="button" class="btn_fold" v-bind:style="prjInfoToggle ? 'display:none': ''" @click="clickTogglePrj()">접기</button>
                        <button type="button" class="btn_fold active" v-bind:style="!prjInfoToggle ? 'display:none': ''" @click="clickTogglePrj()">펼치기</button>
                        <button type="button" class="btn_evt_group" @click="hideUtils"></button>
                        <ul class="evt_btn_box radiusbox" :style="{ display: InferenceUtil ? 'block' : 'none' }">
                            <li class="download_item">
                                <button type="button" @click="makePDF">데이터 다운로드</button>
                            </li>
                            <li class="share_item">
                                <button type="button" @click="clipCoppy">공유하기</button>
                            </li>
                            <li class="retouch_item" v-if="manageRole">
                                <button type="button" @click="taskModify">TASK 수정</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item_table radiusbox" v-bind:style="prjInfoToggle ? 'display:none': ''">
                    <table>
                        <thead>
                            <tr>
                                <!-- <th>Project Name</th>
                                <th>Task Name</th>
                                <th>Task Type</th>
                                <th>Manager</th>
                                <th>Team</th>
                                <th>Last Visit</th>
                                <th>Create Date</th> -->
                                <th>Project ID</th>
                                <th>AWS ID</th>
                                <th>PM</th>
                                <th>Project Type</th>
                                <th>Unit</th>
                                <th>Last Activity</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            <!-- <tr v-if="taskData"> -->
                            <!-- <td>{{taskData.projectName}}</td>
                                <td>{{taskData.awsId}}</td>
                                <td>{{taskData.type}}</td>
                                <td>{{taskData.creatorName}}</td>
                                <td>{{taskData.teamName}}</td>
                                <td>{{taskData.lastVisit}}</td>
                                <td>{{taskData.createDate}}</td> -->
                            <tr>
                                <td>{{prjData.project_name}}</td>
                                <td>{{prjData.aws_id}}</td>
                                <td>{{prjData.pm}}</td>
                                <td>{{prjData.project_type}}</td>
                                <td>{{prjData.team_name}}</td>
                                <td>{{prjData.last_activity}}</td>
                            </tr>
                            <!-- <tr v-for="(item, index) in taskData"  :key="index" >
                                <td>{{item.project_name}}</td>
                                <td>{{item.task_name}}</td>
                                <td>{{item.task_type}}</td>
                                <td>{{item.creator_name}}</td>
                                <td>{{item.team_name}}</td>
                                <td>{{item.last_visit}}</td>
                                <td>{{item.create_date}}</td>
                                
                            </tr> -->
                            <!-- <tr v-bind:style="taskData.length > 0 ? 'display:none' : '' " >
                                <td colspan="7"> {{ taskData.description }} </td>
                            </tr> -->
                        </tbody>
                    </table>
                    <div class="item_desc">
                        <div class="desc_title">Description</div>
                        <div class="desc_txt">{{ prjData.project_description }}</div>
                    </div>
                </div>
            </div>
            <div id="monCont" class="dct_dashboard">

                <div class="filter_box">
                    <select name="oem" id="oem" style="width: 185px; background-position: 155px center;" v-model="oemSelect">
                        <option value="0">OEM 선택</option>
                        <option v-for="oem in oemData" :key="oem.id" :value="oem.id">
                                    {{oem.value}}
                        </option>
                    </select>
                    <select name="vehicle" id="vehicle" style="width: 185px; background-position: 155px center;" v-model="vehicleSelect">
                        <option value="0" selected>Vehicle 선택</option>
                        <option v-for="vehicle in vehicleData" :key="vehicle.id" :value="vehicle.id">
                                    {{vehicle.value}}
                        </option>
                    </select>
                    <select name="a" id="a" style="width: 335px; background-position: 305px center;" v-model="edgeSelect">
                        <option value="0" >Edge ID 선택</option>
                        <option v-for="edge in edgeData" :key="edge.id" :value="edge.value">
                                    {{edge.value}}
                        </option>
                    </select>
                    <strong>{{paramEdge}}</strong>
                    <!--
                    <button class="dtcsearch_icon active" @click="searchData()"></button>
                    <div class="dct_type radiusbox">
                        <ul>
                            <li>
                                <div>Suspension</div>
                            </li>
                            <li class="warning active">
                                <div>Steering</div>
                            </li>
                            <li>
                                <div>Brake</div>
                            </li>
                        </ul>
                    </div>
                    -->
                </div>
                <div id="dtcData" class="warning">
                    <div class="img_dashboard radiusbox">
                        <div class="float_box">
                            <div class="img_dct">
                                <button type="button" :class="['icon_brake' , {'warning':activetedSUS.wIDB} , {'active':activetedSUS.aIDB}]" @click="getDetail('IDB')"> </button>
                                <button type="button" :class="['icon_susp' , {'warning':activetedSUS.wSUS} , {'active':activetedSUS.aSUS}]" @click="getDetail('SUS')"> </button>
                                <button type="button" :class="['icon_steer' , {'warning':activetedSUS.wEPS} , {'active':activetedSUS.aEPS}]" @click="getDetail('EPS')"> </button>
                            </div>
                            <div class="dct_info">
                                <strong>{{dct.info}}</strong>
                                <div class="dtc_msg">
                                    <span>Vehicle Gear</span>
                                    <p>{{dct.gear}}</p>
                                </div>
                                <div class="dtc_msg">
                                    <span>Device DTC {{ dtcTitle }} status</span>
                                    <p>{{dct.msg}}</p>
                                </div>
                            </div>
                            <div class="dct_btn" v-if="manageRole">
                                <div>
                                    <button type="button" @click="showModalMessege('Refresh', 'F')">Refresh</button>
                                    <button type="button" @click="showModalMessege('DTC Read', 'R')">DTC Read</button>
                                    <button type="button" @click="showModalMessege('DTC Clear', 'C')" class="mcbtn">DTC Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="float_box">
                        <div class="dct_list radiusbox">
                            <div class="cont_title_box">
                                <strong>DTC({{ dtcTitle }}) List</strong>
                            </div>
                            <div class="mando_table_wrap">
                                <table class="mando_table">
                                    <colgroup>
                                        <col style="width: 70px;">
                                        <col style="width: 60px;">
                                        <col style="width: 50px;">
                                        <col style="width: 50px;">
                                        <col style="width: 160px;">
                                        <col style="width: 120px;">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Device</th>
                                            <th>DTC</th>
                                            <th>Status</th>
                                            <th>Type</th>
                                            <th>Desc</th>
                                            <th>Manual</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in deviceList"  :key="index" @click="dtcListClick(index, item.code_id)" :class="{ 'active' : dtcSelectedItem === index }">
                                            <td style="overflow: hidden; text-overflow: ellipsis;">{{item.dvc_id}}</td>
                                            <td>{{item.code_id}}</td>
                                            <td>{{item.sts}}</td>
                                            <td>{{item.type}}</td>
                                            <td>{{item.desc_info}}</td>
                                            <td class="potal_row_item" style="cursor:pointer;" @click="showModalManual(index)">자세히 보기 <span style="cursor: pointer;"></span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="dct_chart radiusbox">
                            <div class="cont_title_box">
                                <!-- <strong>Steering Signal Chart</strong> -->
                                <!-- <strong>{{ dtcTitle }} Signal Chart</strong> -->
                                <strong>Signal Chart</strong>
                                <button class="setting_icon" @click="showModalMember()"></button>
                            </div>
                            <div  class="chart_box" >
                                <!-- <strong v-bind:style="prgressFlag ? '': 'display:none' ">in Progress...</strong> -->
                                <canvas id="signalChart" ref="dataChart" width="100%" height="100%" ></canvas>
                            </div>
                        </div>
                        <div class="dct_history radiusbox">
                            <div class="cont_title_box">
                                <strong>DTC({{ dtcTitle }}) History</strong>
                            </div>
                            <div class="mando_table_wrap">
                                <table class="mando_table">
                                    <colgroup>
                                        <col style="width: 70px;">
                                        <col style="width: 60px;">
                                        <col style="width: 50px;">
                                        <col style="width: 50px;">
                                        <col style="width: 150px;">
                                        <col style="width: 150px;">
                                        
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Device</th>
                                            <th>DTC</th>
                                            <th>Status</th>
                                            <th>Type</th>
                                            <th>Desc</th>
                                            <th>Create</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in deviceHistoryList"  :key="index" @click="dtcHistListClick(index, item)" :class="{ 'active' : dtcHistSelectedItem === index }" style="cursor: pointer;">
                                            <td style="overflow: hidden; text-overflow: ellipsis;">{{item.dvc_id}}</td>
                                            <td>{{item.code_id}}</td>
                                            <td>{{item.sts}}</td>
                                            <td>{{item.type}}</td>
                                            <td style="overflow: hidden; text-overflow: ellipsis;">{{item.desc_info}}</td>
                                            <td>{{item.create_dt}}</td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="dtc_map radiusbox">
                            <div class="cont_title_box">
                                <strong>DTC({{ dtcTitle }}) Map {{gpsMessage}} </strong>
                            </div>
                            <div class="map_box" >
                                <naver-map id="map" style="width: 100%; height: 100%" :mapOptions="mapOptions" :initLayers="initLayers" :key="gpsCenterKey">
                                    <naver-marker
                                        v-for="(gpsMarker, index) in gpsMarker"
                                        :key="index"
                                        :latitude="gpsMarker.latitude"
                                        :longitude="gpsMarker.longitude"
                                    >
                                    </naver-marker>
                                    
                                </naver-map>

                                <div class="time_stamp">Time stamp : {{timeStamp}}</div>
                            </div>
                            <div class="area_box">
                                <div>가까운 정비소</div>
                                <ul  >
                                    <li v-for="(item, index) in shopList"  :key="index">{{item.shop_name}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="modalWrap" ref="modalWrap" style="display: none;">
        <!-- 취소 -->
        <div class="radiusbox modal_contents message_check message_modal" ref="modalMessege" style="display: none;">
            <div class="message_box">
                {{ meseegeTxt }}를 실행하시겠습니까?
            </div>
            <button type="button" class="btn_cancel" @click="closeModal()">취소</button>
            <button type="button" class="btn_check mcbtn" @click="execDTC()">확인</button>
        </div>
        <!-- 데이터 카달로그 검색 -->
        <div class="radiusbox modal_contents add_item_modal add_member" ref="modalMomber" style="display : none;">
            <strong class="modal_title">
                데이터 카탈로그 검색
            </strong>
            <div class="filter_box">
                <input type="text" name="vehicleKeyword" ref="searchInput" placeholder="Vehicle">
                <button type="button" class="btn_search" @click="listSearch"></button>
            </div>
            <div class="float_box table_list">
                <div class="select_box mando_table_wrap">
                    <table class="mando_table">
                        <colgroup>
                            <col style="width: 20%">
                            <col style="width: 20%">
                            <col style="width: 60%">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <div class="all_chk">
                                        <input type="checkbox" id="allChkMember" v-model="allSelctMember">
                                        <label for="allChkMember" style="visibility: hidden;"></label>
                                        <span>No.</span>  
                                    </div>
                                </th>
                                <th>
                                    Domain
                                </th>
                                <th>
                                    Data Name
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in steeringList"  :key="index" >
                                <td>
                                    <div class="chk_item">
                                        <input type="checkbox" :id="'vItem' + index" :value="item" v-model="unSelectedVList">
                                        <label :for="'vItem' + index"></label>
                                        <span>{{ index + 1 }}</span>  
                                    </div>
                                </td>
                                <td>{{item.vehicle_name}}</td>
                                <td>{{item.measure_name}}</td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
                <div class="choose_box">
                    <div class="btn_wrap">
                        <button type="button" class="btn_add" @click="clickAddMember()">추가</button>
                        <button type="button" class="btn_add_del" @click="clickDelMember()">삭제</button>
                    </div>
                </div>
                <div class="select_box">
                    <div class="table_title">
                        <strong>선택 항목</strong>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <colgroup>
                                <col style="width: 20%">
                                <col style="width: 20%">
                                <col style="width: 60%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="all_chk">
                                            <input type="checkbox" id="allChk" v-model="allSelctedMember">
                                            <label for="allChk" style="visibility: hidden;"></label>
                                            <span>No.</span>  
                                        </div>
                                    </th>
                                    <th>
                                        Domain
                                    </th>
                                    <th>
                                        Data Name
                                    </th>
                                </tr>
                            </thead>

                            <tbody id="selectMemberTbody">
                                <tr v-for="(item, index) in selectSteeringList" :data-index="index" :key="index" >
                                    <td>
                                        <div class="chk_item">
                                            <input type="checkbox" :id="'vSelectedItem' + index" :value="item" v-model="selectedVList">
                                            <label :for="'vSelectedItem' + index"></label>
                                            <span>{{ index + 1 }}</span>  
                                        </div>
                                    </td>
                                    <td>{{item.vehicle_name}}</td>
                                    <td>{{item.measure_name}}</td>
                                </tr>
                            
                            </tbody>
                            <tbody>
                                <tr class="hover_none" v-bind:style="selectSteeringList.length > 0 ? 'display:none' : ''">
                                    <td colspan="3">
                                        <div class="none_itme">
                                            <div class="message">
                                                데이터 카달로그를 추가 해 주세요.
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="max_info"> 5개 까지 선택 가능합니다. </div>
                    </div>
                </div>
            </div>
            <div class="check_wrap">
                <button type="button" class="btn_check mcbtn " @click="setMeasure()">완료</button>    
            </div>
            <button type="button" class="btn_close" @click="closeModal()" ></button>
        </div>
        <!-- 매뉴얼 보기 -->
        <div class="radiusbox modal_contents manual_modal" ref="modalMenual" style="display: none;">
            <strong class="modal_title">
                Manual 보기
            </strong>
            <div class="modal_tab_wrap">
                <ul class="list_tab float_box" ref="listTab"  >
                    <li @click="tabEvent(index)" v-for="(item, index) in deviceManual" :data-index="index" :key="index" :class="{ 'active' : index===0 }" >
                        <div>Manual {{index+1}}</div>
                    </li>
                </ul>
                <div class="tab_data" ref="listTabBox">
                    <div  v-for="(item, index) in deviceManual" :data-index="index" :key="index" class="data_list_box active">
                        <iframe :srcdoc="menualHTML[index]"  frameborder="0" width="100%" height="100%"></iframe>
                    </div>                    
                </div>
            </div>
            <button type="button" class="btn_close" @click="closeModal()" ></button>
        </div>
    </div>
    <div id="lodingWrap" style="display:none" ref="lodingWrap">
        <div class="loading-container">
            <div class="loding-animation-holder">
                <div class="loading-animator"></div>
                <div class="loading-animator"></div>
                <div class="loading-animator"></div>
                <div class="loading-animator"></div>
                <div class="middle-circle"></div>
            </div>
        </div>
    </div> 
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';

    import dayjs from "dayjs";
    import Chart from 'chart.js/auto';
    import { NaverMap, NaverMarker } from "vue3-naver-maps";
    
    import common from '@/assets/js/common'

    
    export default {
        name: 'DCTDashboardView',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            NaverMap,
            NaverMarker,
            BreadcrumbLayout,
        },
        data(){
            const meseegeTxt = null;
            const btnExec = null;
            const taskData = ({});//JK1 Steering 이상 진단

            const paramData = ({
                "code_id":'',
                "type": '', 
                'measureList' : []
                });
            const deviceList = ({});
            const deviceHistoryList = ({});

            const steeringGraphData = ({});
            const steeringGraph = ({});

            const getSteeringData = ({});

            const steeringList = ([]);
            const selectSteeringList = ([]);
            const shopList= ({});
            //edgeId로 디바이스 id 구하기

            const edgeInfo = ({});

            const dct =({});

            const linedata =  {
                labels: ['Red', 'ㅁㅁㅁ', 'Yellow', 'Green', 'Purple', 'Orange'],
                datasets: [
                    {
                        label: 'Dataset 1',
                        data: [65, 59, 80, 81, 56, 55, 40],
                        borderColor: 'rgb(75, 192, 192)',
                    },
                    {
                        label: 'Dataset 2',
                        data: [1, 31, 20, 41, 52, 15, 89],
                        borderColor: 'rgb(75, 23, 231)',
                    }
                ]
            };

            const steeringChart = null;
            const prgressFlag = false;

            //dtc/device/activated get
            const activevated = ({});
            //이미지 처리 - 갯수 확인, type
            //active 시 갯수 표기
            //iot/dtc/shadow-sts 조회
            const timeStamp = '';

            return{
                prjInfoToggle: false,
                shopList,

                meseegeTxt,
                btnExec,
                menualHTML: [],
                oemData: [], //oem
                vehicleData: [], //vehicle
                edgeData: [], //project
                prgressFlag,
                paramEdge: '',
                paramEdgeEnd: '',

                oemSelect: '0',
                vehicleSelect: '0',
                edgeSelect: '0',

                activetedSUS: {
                    wEPS: false,
                    wSUS: false,
                    wIDB: false,
                    aEPS: false,
                    aSUS: false,
                    aIDB: false,
                    iEPS: 0,
                    iSUS: 0,
                    iIDB: 0
                },
                dtcSelectedItem: null,
                dtcHistSelectedItem: null,
                
                unSelectedVList: [],
                selectedVList: [],
                
                linedata,
                timeStamp,

                paramData,
                taskData,
                edgeInfo,
                activevated,

                dct,

                deviceList, //DTC list
                deviceManual: [],
                deviceHistoryList, //DTC History
                steeringGraphData,
                steeringGraph,
                steeringChart,
                
                steeringList,
                selectSteeringList,
                getSteeringData,

                prjData: {
                    project_name: "",
                    aws_id: "",
                    pm: "",
                    project_type: "",
                    team_name: "",
                    last_activity: "",
                    project_description: "",
                },

                dtcTitle: "Steering",

                // gps
                // default_gpsMarker: [
                //     {
                //         latitude: 37.409345,
                //         longitude: 127.114304
                //     },
                //     {
                //         latitude: 37.392113,
                //         longitude: 127.132446
                //     },
                //     {
                //         latitude: 37.368234,
                //         longitude: 127.100925
                //     },
                // ],
                // default_shoplist: [ "대림자동차정비서비스(유)",
                //     "(주)일급신분당검사정비사업소",
                //     "(주)현대자동차검사정비사업소",
                // ],
                gpsMarker: [],
                mapOptions : {
                    // latitude: 37.404355, // 지도 중앙 위도
                    // longitude: 127.110633, // 지도 중앙 경도
                    latitude: common.getDefaultLat(), // 지도 중앙 위도
                    longitude: common.getDefaultLong(), // 지도 중앙 경도
                    zoom: 11,
                    zoomControl: false,
                    zoomControlOptions: { position: "TOP_RIGHT" },
                },
                initLayers : [
                    "BACKGROUND",
                    "BACKGROUND_DETAIL",
                    "POI_KOREAN",
                    "TRANSIT",
                    "ENGLISH",
                ],
                gpsKey: 0,
                gpsCenterKey: 0,
                gpsMessage: "",

                InferenceUtil: false,
                // catalog key
                dtcCatalog : {
                    edgeDeviceId:"",
                    dtc:"",
                    historyCreateTime:"",
                },
            
                // role
                viewRole: false,
                manageRole: false,
            };
        },
        
        async mounted() {
            // Role
            await this.setRole();

            const vm = this;

            this.prjId = this.$route.query.prjId;
            this.taskId = this.$route.query.taskId;

            // prj info
            const prjData = await this.getPrjData(this.prjId);
            if(typeof prjData !== "undefined" && prjData !== null && prjData !== "") {
                this.prjData = prjData.project;
            }
           
            //결정할때까지는 프로젝트 목록으로
            vm.taskData = await vm.getTaskData(this.taskId);

            await vm.getOemData();
            await this.getEdgeOption();

            if ( this.edgeData.length > 0 ) {
                const edgeId = this.edgeData[0].id;

                // 로딩 시작
                vm.$refs.lodingWrap.style.display = 'block';
                
                vm.edgeInfo = await vm.getEdgeInfo(edgeId);
                this.paramEdge = vm.edgeInfo.edge_device_id + " 정보 조회 중....";
                this.paramEdgeEnd = vm.edgeInfo.edge_device_id ;
                this.paramData['dvc_id'] = vm.edgeInfo.edge_device_id;
                this.cleanStatus();
                this.getActivevated();

                this.edgeSelect = this.edgeData[0].value;
                this.vehicleSelect = this.edgeData[0].vehicle_id;
                this.oemSelect = this.edgeData[0].oem_id;
                await this.getDetail('EPS'); // DTC type 셋팅
                vm.activetedSUS.aEPS = true; // EPS 이미지 확대
                this.btnExec = 'R' // 버튼 DTC Read
                await this.execDTC(); // DTC read 실행

                // if ( this.deviceHistoryList.length > 0 ) {
                //     const device = this.deviceHistoryList[0];
                //     this.dtcHistListClick(0, device);
                // }

                // 로딩 시작 끝
                vm.$refs.lodingWrap.style.display = 'none';
            }
        },
        computed:{
            allSelctMember: {
                //getter
                get: function() {
                    return this.steeringList.length === this.unSelectedVList.length;
                },
                //setter
                set: function(e) {
                    this.unSelectedVList = e ? this.steeringList : [];
                },
            },
            allSelctedMember: {
                //getter
                get: function() {
                    return this.selectSteeringList.length === this.selectedVList.length;
                },
                //setter
                set: function(e) {
                    this.selectedVList = e ? this.selectSteeringList : [];
                },
            },
        },
        watch: {
            
            oemSelect() {
                if (this.oemSelect == "0") {
                    this.oemId = "";
                    this.vehicleData=[];
                    this.vehicleSelect="0";
                    this.edgeClean();
                    return;         
                }
                else this.oemId = this.oemSelect;

                this.getVehicleData(this.oemSelect);
               
            },
            vehicleSelect() {
                if (this.vehicleSelect == "0"){
                    this.vehicleId = "";
                    
                    this.edgeClean();
                    return;         
                }else this.vehicleId = this.vehicleSelect;
                
                this.getEdgeOption();
            },
            edgeSelect() {
                if (this.edgeSelect == "0") this.edgeId = "";
                else this.edgeId = this.edgeSelect;

                this.paramEdge = this.paramEdgeEnd = '';
                this.paramData['dvc_id'] = this.edgeSelect;
                this.cleanStatus();
                this.getActivevated();
            }
        },

        methods: {
            async setRole() {
                const prjId = this.$route.query.prjId;
                this.manageRole = ( await common.getUserRole('type2') || await common.getPrjRole('type2', prjId) );
                this.viewRole = ( await common.getUserRole('type2') || await common.getPrjRole('type1', prjId) );

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            // 데이터 카달로그 검색
            async listSearch() {
                // const lodash = require('lodash');
                const searchInput = this.$refs.searchInput.value;
                if(searchInput.length > 0){
                    const result = new RegExp(searchInput, 'g');
                    let newLsit = [];
                    this.steeringList.forEach((item, index) =>{
                        let _vehicleName = result.test(item.vehicle_name);
                        let _measureName = result.test(item.measure_name);
                        if(_vehicleName || _measureName){
                            newLsit.push({
                                'index': index,
                                'vehicle_name' : this.steeringList[index].vehicle_name,
                                'measure_name' : this.steeringList[index].measure_name
                            });
                            
                        }
                    });
                    if(newLsit != null && typeof newLsit != "undefined" && newLsit != "") {
                        this.steeringList = newLsit; 
                    }
                }else{
                    let keyword = this.edgeSelect;

                    if(typeof keyword == "undefined" || keyword == null || keyword == "") {
                        keyword = '';// 정의 되지 않았음. vehicle - this.paramData['dvc_id'];
                    }

                    const headers = {
                        
                        "startTime": this.paramData['startTime'],
                        "endTime": this.paramData['endTime'],
                        "vehicle": keyword
                       
                    };
                    const response = await common.apiDlGet('/dl/can/catalog-list', headers);
                    const resResults = response.data.queryResult.results;

                    if(resResults != null && typeof resResults != "undefined" && resResults != "") {
                        this.steeringList = resResults;
                    }
                    
                    if(this.selectSteeringList.length > 0){
                        this.steeringList.forEach((item, index) =>{
                            this.selectSteeringList.forEach((itemAct, indexAct) =>{
                                const resultVehicle = new RegExp(this.selectSteeringList[indexAct].vehicle_name, 'g');
                                const resultMeasure = new RegExp(this.selectSteeringList[indexAct].measure_name, 'g');

                                let _vehicleName = resultVehicle.test(item.vehicle_name);
                                let _measureName = resultMeasure.test(item.measure_name);
                                if(_vehicleName && _measureName){
                                    this.steeringList.splice(index, 1);
                                }
                            });
                        });
                    }
                }
            },
            //---- check member role
            async allowAccess() {
                const accessPage = await this.checkLoginUser();

                if(!accessPage) {
                        alert("SM, Project Manager,Project Researcher,Project Observer만 접근 가능");
                        this.$router.push('/monTASK/monTaskList');
                }
            },


            async checkLoginUser() {
                const role = await this.getMemberRole();
                const projectRole = await this.getProjectRole();

                if(role != null && role.role == 'SM') {
                    return true;
                } else if(projectRole != null && (projectRole.projectRole == "Manager" || projectRole.projectRole == "Researcher" || projectRole.projectRole == "Observer")) {
                    return true;
                } else {
                    return false;
                }
            },

            
           
            onLoadMarker(event) {
                this.naverMakert  = event;
                this.map = new window.naver.maps.Map('map', this.mapOptions);
            },

            edgeClean(){
                this.edgeData=[];
                this.edgeSelect="0";
                this.cleanStatus();
            },

            //---- API
            async getPrjData(prjId){
                try {
                    const response = await common.apiGet('/project/' + prjId );
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getTaskData(taskId){
                try {
                    const _taskId = encodeURIComponent(taskId);
                  
                    const response = await common.apiGet(`/monitoring-task/${_taskId}?viewRole=${this.viewRole}`);
                    // const response = await common.apiGet('/monitoring-task/project/' + _taskId );
                    // console.log(response.data.data);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getOemData(){
                
                try {
                    const response = await common.apiGet("/edge-device-group/oem");
                    const o = [];


                    response.data.data.forEach(e => {
                        o.push({id: e.id, value: e.name})
                    });
                    this.oemData = o;
                    
                } catch (error) {
                   console.error('Error fetching data:', error);
                }
            },
            async getVehicleData(oem){
                
                try {
                    // 정확한 UI 확인 필요...
                    //const response = await common.apiGet('/edge-device-group/vehicle' );
                    const response = await common.apiGet('/edge-device-group/domain/oem/vehicle/' + oem)
                    const o = [];
                    response.data.data.forEach(e => {
                        o.push({id: e.id, value: e.name})
                    });
                    this.vehicleData = o;

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getEdgeList(projectId){
                const _projectId = encodeURIComponent(projectId);
                try {
                    const response = await common.apiGet('/edge-device/project/' + _projectId );
                    // console.log(response.data.data);
                    const o = [];
                    response.data.data.forEach(e => {
                        o.push({id: e.id, value: e.edge_device_id})
                    });
                    this.edgeData = o;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getEdgeOption(){
                
                try {
                    const body = {};
                    
                    if (this.oemId)body["oem-id"] = this.oemId;
                    if (this.vehicleId)body["vehicle-id"] = this.vehicleId;
                    
                    // const response = await common.apiGet('/vehicle-diagnostic-service/edge-device', body );
                    const response = await common.apiGet('/edge-device/monitoring-task/edge-device-group/' + this.taskId);
                    const o = [];
                    response.data.data.forEach(e => {
                        // o.push({id: e.id, value: e.edge_device_id})
                        if (!o.some(item => item.id === e.id)) {
                            o.push({id: e.id, value: e.edge_device_id, oem_id: e.oem_id, vehicle_id: e.vehicle_id});
                        }
                    });
                    this.edgeData = o;
                    
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getEdgeInfo(edgeId){
                try {
                    const _edgeId = encodeURIComponent(edgeId);
                  
                    const response = await common.apiGet('/edge-device/' + _edgeId );
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getMemberRole() {
                let response;
                try {
                    let url = `/member/check-role`;
                    response = await common.apiGet(url);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },
            async getProjectRole() {
                let response;
                try {
                    let checkProjectRoleUrl = `/member/check-project-role/${this.prjId}`;
                    response = await common.apiGet(checkProjectRoleUrl);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },
            // catalog API 
            async getCatalogData(edgeDeviceId, dtc, historyCreateTime){
                const _edgeDeviceId = encodeURIComponent(edgeDeviceId);
                const _dtc = encodeURIComponent(dtc);
                const _historyCreateTime = encodeURIComponent(historyCreateTime);
                try {
                    const response = await common.apiGet('/dtc-data-catalog?edgeDeviceId='+ _edgeDeviceId +'&dtc='+ _dtc +'&historyCreateTime='+ _historyCreateTime );
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async setCatalogData(postData) {
                console.log(postData);
                try {
                    const response = await common.apiPost('/dtc-data-catalog', postData);
                    // return response.data.code;
                    return response;
                } catch (error) {
                    console.error('Error posting data:', error);
                }
            },
            async delCatalogData(edgeDeviceId, dtc, historyCreateTime) {
                const _edgeDeviceId = encodeURIComponent(edgeDeviceId);
                const _dtc = encodeURIComponent(dtc);
                const _historyCreateTime = encodeURIComponent(historyCreateTime);
                try {
                    await common.apiDelete('/dtc-data-catalog?edgeDeviceId='+ _edgeDeviceId +'&dtc='+ _dtc +'&historyCreateTime='+_historyCreateTime);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            //---- DTC API
            async getActivevated(){
                const vm = this;

                this.cleanStatus();
                try {
                    const headers = {
                        "dvc_id": vm.paramData["dvc_id"]
                    };
                    
                    const response = await common.apiDl2Post('/dtc/device/activated', headers, 'D');
                    
                    vm.activevated = response.data.info;

                    // vm.dct.info = "DTC Status 조회가 완료되었습니다.";
                    
                    this.paramEdge = this.paramEdgeEnd ;

                    vm.activevated.forEach(function (item) {
                        if (item.type == 'EPS' && item.count > 0 ){
                            vm.activetedSUS.wEPS = true;
                            vm.activetedSUS.iEPS = item.count;
                            vm.dct.info = "Steering (" + vm.activetedSUS.iEPS + ")";
                        }else if (item.type == 'IDB' && item.count > 0){
                            vm.activetedSUS.wIDB = true;
                            vm.activetedSUS.iIDB = item.count;
                        }else if (item.type == 'SUS' && item.count > 0){
                            vm.activetedSUS.wSUS = true;
                            vm.activetedSUS.iSUS = item.count;
                        }
                    });

                    //이미지 처리

                } catch (error) {
                    alert('통신 오류가 발생하였습니다.${event.target.tagName}' );
                    console.error('Error fetching data:', error);
                }
                
            },

            async getDetail(type){
                const vm = this;
                vm.paramData['type'] = type;

                await this.cleanDCT();

                if (type === 'EPS' ) {
                    // if(!vm.activetedSUS.wEPS) return;
                    // vm.activetedSUS.aEPS = true;
                    // vm.activetedSUS.aIDB = false;
                    // vm.activetedSUS.aSUS = false;

                    // vm.dct.info = "Steering (" + vm.activetedSUS.iEPS + ")";

                    // if(vm.activetedSUS.wEPS) {
                        this.deviceList = ({});
                        this.deviceHistoryList = ({});
                        this.dtcTitle = "Steering";
                        vm.activetedSUS.aEPS = true;
                        vm.activetedSUS.aIDB = false;
                        vm.activetedSUS.aSUS = false;

                        this.dct.info = "Steering (" + vm.activetedSUS.iEPS + ")";
                    // }

                }else if (type === 'IDB'){
                    // if(!vm.activetedSUS.wIDB) return;

                    this.deviceList = ({});
                    this.deviceHistoryList = ({});
                    this.dtcTitle = "Brake";
                    vm.activetedSUS.aEPS = false;
                    vm.activetedSUS.aIDB = true;
                    vm.activetedSUS.aSUS = false;

                    vm.dct.info = "Brake (" + vm.activetedSUS.iIDB + ")";


                }else if (type === 'SUS'){
                    // if(!vm.activetedSUS.wSUS) return;

                    this.deviceList = ({});
                    this.deviceHistoryList = ({});
                    this.dtcTitle = "Suspension";
                    vm.activetedSUS.aEPS = false;
                    vm.activetedSUS.aIDB = false;
                    vm.activetedSUS.aSUS = true;

                    vm.dct.info = "Suspension (" + vm.activetedSUS.iSUS + ")";
                }
                
                await vm.getShadowSTS();
               
            },

            async getShadowSTS(){
                const vm = this;
                try {
                    const headers = {
                        "dvc_id": vm.paramData['dvc_id'],
                        "type": vm.paramData['type']
                    };
                    
                    const res = await common.apiDl2Post('/iot/dtc/shadow-sts', headers, 'D');
                   
                    if(res.data.iot.gear_value == "P") vm.dct.gear = "Parking";
                    else if(res.data.iot.gear_value == "B") vm.dct.gear = "Engine Brake";
                    else if(res.data.iot.gear_value == "D") vm.dct.gear = "Drive";
                    else if(res.data.iot.gear_value == "N") vm.dct.gear = "Neutral";
                    else if(res.data.iot.gear_value == "R") vm.dct.gear = "Reverse";
                    
                    if(res.data.iot.status == "1") vm.dct.msg = "DTC 발생";
                    else if(res.data.iot.status == "2") vm.dct.msg = "DTC Clear 액션 상태";
                    else if(res.data.iot.status == "3") vm.dct.msg = "DTC Clear 액션 수행 성공";
                    else if(res.data.iot.status == "4") vm.dct.msg = "DTC Clear를 해도 없어지지 않음";
                    else if(res.data.iot.status == "5") vm.dct.msg = "DTC Read(Reserved)";
                    else vm.dct.msg = "이상 없음";

                } catch (error) {
                    vm.dct.info = '통신 오류가 발생하였습니다. ' + error ;
                    console.error('Error fetching data:', error);
                }
            },
            
            // 카탈로그 완료 
            async setMeasure(){
                
               
                const measureList = [];
                this.selectSteeringList.forEach(element => {
                    measureList.push(
                            element.measure_name
                        );
                    });
                    
                this.paramData['measureList'] = encodeURIComponent(measureList);

                // catalog Key 

                const _edgeDeviceId = this.dtcCatalog.edgeDeviceId;
                const _dtc = this.dtcCatalog.dtc;
                const _historyCreateTime = this.dtcCatalog.historyCreateTime;
                
                
                try {
                    console.log(_historyCreateTime);
                    if(this.selectSteeringList.length > 0){
                        // 저장 api 

                        const postData = {
                            "edgeDeviceId": _edgeDeviceId,
                            "dtc": _dtc,
                            "historyCreateTime": _historyCreateTime,
                            "dataName": []
                        }
                        this.selectSteeringList.forEach((index, item)=>{
                            postData.dataName.push(this.selectSteeringList[item].measure_name);
                        });
                        console.log("카탈로그 저장 API");
                        await this.setCatalogData(postData);

                    }else{
                        //  리스트 없으면 삭제
                        console.log("카탈로그 삭제 API");
                        await this.delCatalogData(_edgeDeviceId, _dtc, _historyCreateTime);

                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }

                this.closeModal();
                this.getSignalChart();

                
            },

            async getSignalChart(){
                if ( this.steeringChart !== null ) {
                    this.steeringChart.destroy();
                }
                
                const vm = this;
                // this.linedata = ({});
                
                this.prgressFlag =true;

                // 로딩 시작
                vm.$refs.lodingWrap.style.display = 'block';

                // catalog Key 
                const _edgeDeviceId = this.dtcCatalog.edgeDeviceId;
                const _dtc = this.dtcCatalog.dtc;
                const _historyCreateTime = this.dtcCatalog.historyCreateTime;
                
                try {
                    // get catalog
                    const response = await this.getCatalogData(_edgeDeviceId, _dtc, _historyCreateTime);


                    
                    // measure_name 
                    // 배열로 값 넘겨주는형식 
                    const keys = [];
                    if(response.length > 0 ){
                        response.forEach((item, index)=>{
                            keys.push(response[index].dataName);
                        });

                        //lable 과 data 로 재정의 필요
                        // chart data 생성 함수 - 배열로 키값 받아서 함수 내부에서 차트 데이터 생성
                        const _return = await this.getKeyValues(keys);

                        if (vm.steeringChart) {
                            vm.steeringChart.destroy(); // 기존 차트 파기
                        }

                        vm.steeringChart = new Chart(document.getElementById('signalChart'), {
                            type: 'line',
                            // data: this.linedata,
                            data: {
                                labels: _return[0].labels,
                                datasets: _return[0].datasets,
                            },
                            options: {
                                plugins: {
                                    legend: {
                                        labels: {
                                            boxWidth:10,
                                            boxHeight:2,

                                        },
                                        title: {
                                            display: false
                                        }
                                    }
                                },
                                scales: {
                                    x:{display: false}
                                }
                            }
                        });
                    }else{
                        
                        if (vm.steeringChart) {
                            vm.steeringChart.destroy(); // 기존 차트 파기
                        }

                        vm.steeringChart = new Chart(document.getElementById('signalChart'), {
                            type: 'line',
                            data: {
                                labels: ["1"],
                                datasets: [
                                    {
                                        label: ' ',
                                        // data: [65, 59, 80, 81, 56, 55, 40],
                                        borderColor: 'rgb(255, 255, 255)',
                                    }
                                ]
                            },
                            options: {
                                plugins: {
                                    legend: {
                                        labels: {
                                            boxWidth:10,
                                            boxHeight:2,

                                        },
                                        title: {
                                            display: false
                                        }
                                    }
                                },
                                scales: {
                                    x:{display: false}
                                }
                            }
                        });
                    }


                } catch (error) {
                    // 로딩 시작 끝
                    vm.$refs.lodingWrap.style.display = 'none';
                    console.error('Error fetching data:', error);
                }
                this.prgressFlag = false;

                // 로딩 시작 끝
                setTimeout(function() { vm.$refs.lodingWrap.style.display = 'none' }, 1000);
                

            },

            uniqueKeys() {
                const uniqueKeysSet = new Set();
                this.steeringGraphData.forEach(item => {
                    uniqueKeysSet.add(item.measure_name);             
                });
                return Array.from(uniqueKeysSet);
            },
            async getKeyValues(keys) {
                //this.linedata.labels = keys;
                // dl data call 
                const headers = {
                    "startTime": this.paramData['startTime'],
                    "endTime": this.paramData['endTime'],
                    "vehicle": this.edgeSelect,
                    "measureList": ""
                };
                
                keys.forEach((item, index)=>{
                    headers.measureList += "'"+keys[index] + "'" + ", ";
                });
                const response = await common.apiDlGet('/dl/can/graph', headers);

                const responseData = response.data.queryResult.results;
                
                const datasets = [];
                const labels = [];
                // let DATA_COUNT = 0;
                let iColor = ['#EA726F','#A701FF','#52B69C','#EABE6E','#4791FF'];
                // let i = 0;
                if(keys.length > 0){
                    keys.forEach((item, index) => {
                        
                        const dataset = ({});
                        const data = [];

                        for(var n = 0; n < responseData[0][keys[index]].length; n++){
                            data.push(responseData[0][keys[index]][n].measure_value);
                        }
                        dataset["label"] = keys[index],
                        dataset["data"] = data,
                        dataset["borderColor"] = iColor[index],

                        // this.steeringGraphData.filter(item => item.measure_name == key).forEach(item =>{
                        //     data.push(item.measure_value);
                        // });
                        // dataset["data"] = data,
                        // DATA_COUNT = data.length;

                        // if(i < 3){
                        //     dataset["borderColor"] = iColor[i];
                        //     i++;
                        // }
                        // dataset["borderWidth"] = 1;
                        // dataset["pointBorderWidth"] = 1;
                        // dataset["pointStyle"]=false;
                        
                        datasets.push(dataset);

                        
                    });
                    for (let i = 0; i < responseData[0][keys[0]].length; ++i) {
                        labels.push(responseData[0][keys[0]][i].time_stamp);
                    }

                    const result = []
                    result.push(
                        {
                            labels: labels,
                            datasets: datasets,
                        }
                    )

                    return result;
                }else{
                    //카탈로그 데이터 없을때 
                    const result = []
                    result.push(
                        {
                            labels: labels,
                            datasets: datasets,
                        }
                    )
                }
                // this.linedata.labels = labels;
                // this.linedata.datasets = datasets;
            },

            async getSteeringList(){
                this.selectSteeringList = ([]);
                
                // let keyword = this.$refs.vehicleKeyword.value;//.toUpperCase();
                let keyword = this.edgeSelect;
                
                try {
                   
                    if(typeof keyword == "undefined" || keyword == null || keyword == "") {
                        keyword = '';// 정의 되지 않았음. vehicle - this.paramData['dvc_id'];
                    }
                    
                    const headers = {
                        
                        "startTime": this.paramData['startTime'],
                        "endTime": this.paramData['endTime'],
                        "vehicle": keyword
                       
                    };

                    const response = await common.apiDlGet('/dl/can/catalog-list', headers);
                    
                    this.getSteeringData = response.data.queryResult.results;

                    this.steeringList = ([]);
                    if(this.getSteeringData !== null){
                        this.getSteeringData.forEach((item, index) => {
                            this.steeringList.push({
                                index: index,
                                vehicle_name: item.vehicle_name,
                                measure_name: item.measure_name
                            });
                        });

                        console.log(this.steeringList);
                        // get catalog list 
                        // catalog Key 
                        const _edgeDeviceId = this.dtcCatalog.edgeDeviceId;
                        const _dtc = this.dtcCatalog.dtc;
                        const _historyCreateTime = this.dtcCatalog.historyCreateTime;

                        const response = await this.getCatalogData(_edgeDeviceId, _dtc, _historyCreateTime);
                        console.log(response);
                        // active list 등록 
                        let _arrList = [ ]; 
                        response.forEach((item, index) => {
                            _arrList.push({
                                "index" : index,
                                "measure_name" : response[index].dataName,
                                "vehicle_name" : response[index].edgeDeviceId
                            })
                        });
                        this.selectSteeringList = _arrList;
                        console.log(this.selectSteeringList);
                        // steeringList 비교 제거

                        this.steeringList.forEach((item, index) =>{
                            this.selectSteeringList.forEach((itemAct, indexAct) =>{
                                const resultVehicle = new RegExp(this.selectSteeringList[indexAct].vehicle_name, 'g');
                                const resultMeasure = new RegExp(this.selectSteeringList[indexAct].measure_name, 'g');

                                let _vehicleName = resultVehicle.test(item.vehicle_name);
                                let _measureName = resultMeasure.test(item.measure_name);
                                if(_vehicleName && _measureName){
                                    this.steeringList.splice(index, 1);
                                }
                            });
                        });
                    }

                } catch (error) {
                    console.error('Error fetching data:', error);
                }

            },

            async execDTC(){
                if(this.btnExec == 'F'){
                    this.setRefresh();
                }else if(this.btnExec == 'R'){
                    await this.setDTCread();
                }else if(this.btnExec == 'C'){
                    
                    this.setDTCclear();

                    this.setRefresh();
                }
            },

            async setRefresh(){
                this.closeModal();
                
                this.getActivevated();

                this.dtcHistSelectedItem = null;
                this.shopList = [];
                this.gpsMarker = [];
                this.gpsMessage = '';

                this.updateCenter(common.getDefaultLat(), common.getDefaultLong());

                if ( this.steeringChart !== null ) {
                    this.steeringChart.destroy();
                }
            },

            async setDTCread(){
                const vm = this;
                
                this.closeModal();
                
                this.dtcSelectedItem = null;

                if(this.paramData['type'] == ''){
                    alert('DTC Type 을 선택 후 실행해주세요.');
                    return;
                }
              
                try {
                    // 로딩 시작
                    vm.$refs.lodingWrap.style.display = 'block';

                    const headers = {
                        "dvc_id": this.paramData['dvc_id'],
                        "type": this.paramData['type']
                    };
                    
                    const response = await common.apiDl2Post('/dtc/device/list', headers, 'D');
                    const response2 = await common.apiDl2Post('/dtc/device/history', headers, 'D');
                    
                    this.deviceList = response.data.list;
                     
                    this.deviceHistoryList = response2.data.history;
                    
                    // console.log(this.steeringGraph);

                    if ( this.deviceHistoryList.length > 0 ) {
                        const device = this.deviceHistoryList[0];
                        this.dtcHistListClick(0, device);
                    }

                    // 로딩 시작 끝
                    vm.$refs.lodingWrap.style.display = 'none';

                } catch (error) {
                    // 로딩 시작 끝
                    vm.$refs.lodingWrap.style.display = 'none';
                    console.error('Error fetching data:', error);
                }
             },

            async setDTCclear(){
                
                this.closeModal();

                if(this.paramData['code_id'] == ''){
                    alert('DTC Code 를 리스트에서 선택 후 실행해주세요.');
                    return;
                }

                try {
                    const headers = {
                        "dvc_id": this.paramData['dvc_id'],
                        "type": this.paramData['type'],
                        "code_id": this.paramData['code_id']
                    };
                    // const response = await common.apiDl2Post('/dtc/device/dtc-clear', headers, 'D');
                    await common.apiDl2Post('/dtc/device/dtc-clear', headers, 'D');
                    // console.log(response.data);
                    alert('DTC Clear 가 요청되었습니다. ');
                } catch (error) {
                    alert('통신 오류가 발생하였습니다. ' + error );
                    console.error('Error fetching data:', error);
                }
            },

            setEdgeList(edgeData){
                edgeData.then(list => {
                    const dataList = [];
                    list.forEach(element => {
                        dataList.push({
                            id: element.edge_device_id,
                            edge_device_group: element.edge_device_group,
                            name: element.edge_device_name,
                        });
                   });
                    this.edgeData = dataList;
                });
            },
           
           
            dtcListClick(index, code_id){
                this.dtcSelectedItem = this.dtcSelectedItem === index ? null : index;
                this.paramData['code_id'] = code_id;
            },
            
            async dtcHistListClick(index, item){
                this.$refs.lodingWrap.style.display = 'block';
                // catalog key update 
                this.dtcCatalog.edgeDeviceId = item.dvc_id;
                this.dtcCatalog.dtc = item.code_id;
                this.dtcCatalog.historyCreateTime = item.create_dt;
                
                if ( this.dtcHistSelectedItem === index ) {
                    this.dtcHistSelectedItem = null;
                    this.shopList = [];
                    this.gpsMarker = [];
                    this.gpsMessage = '';

                    this.updateCenter(common.getDefaultLat(), common.getDefaultLong());

                    if ( this.steeringChart !== null ) {
                        this.steeringChart.destroy();
                    }
                    this.$refs.lodingWrap.style.display = 'none';
                } else {
                    
                    this.dtcHistSelectedItem = index;

                    const create_dt = item.create_dt;
                    const lat = common.nullCheck(item.lat);
                    const lng = common.nullCheck(item.lng);
    
                    this.paramData['startTime'] = dayjs(create_dt).subtract(3, 'second').format('YYYY-MM-DD HH:mm:ss'); // 18초를 빼고
                    this.paramData['endTime'] = dayjs(create_dt).format('YYYY-MM-DD HH:mm:ss'); // create time 그대로
                    
                    this.timeStamp = dayjs(create_dt).format('YYYY-MM-DD HH:mm:ss');
                    this.paramData['measureList']='';
    
                    this.gpsMessage = '';
                    
                    // await this.getGps();
    
                    await this.getShopList(lat, lng);
    
                    await this.getSteeringList();
    
                    await this.getSignalChart();

                }
               
//this.paramData['code_id'] = create_dt;
            },
            
            async getGps(){
                try {
                    this.shopList =({});
                    const headers = {
                        
                            "device": this.paramData['dvc_id'], //'jk1ev', // 
                            "createTime": this.timeStamp //'2023-09-05 08:35:00' // 
                        
                    };
                    const response = await common.apiDlGet('/dl/gps/steering-hist', headers);
                    
                    if(response.data.queryResult.results == null){
                        this.gpsMessage = 'GPS 정보가 없습니다.';
                        return;
                    }
                    
                    this.latitude = response.data.queryResult.results[0].latitude;
                    this.longitude= response.data.queryResult.results[0].longitude;
                

                    const latLng = window.naver.maps.LatLng(this.latitude, this.longitude);
                    this.map.setCenter(latLng);
                    new window.naver.maps.Marker({
                        position: new window.naver.maps.LatLng(this.latitude, this.longitude),
                        map: this.map
                    });
                    
                   this.getShopList();

               } catch (error) {
                   console.error('Error fetching data:', error);
               }
            },

            async getShopList(lat, long){
                this.gpsMessage = '';

                if ( lat === '' || long === '' ) {
                    lat = common.getDefaultLat();
                    long = common.getDefaultLong();
                    this.gpsMessage = 'GPS 정보가 없습니다.';
                }

                try {
                    const headers = {
                        "latitude": lat,
                        "longitude": long,
                    };
                    const response = await common.apiDlGet('/rds/reds/shop-list', headers);
                    const _shopList = response.data.queryResult.results;

                    this.shopList = _shopList;

                    const _gpsMakrker = [];
                    _shopList.forEach(item => {
                        _gpsMakrker.push (
                            {
                                latitude: parseFloat(item.latitude),
                                longitude: parseFloat(item.longitude),
                            }
                        )
                    });

                    this.gpsMarker = _gpsMakrker;
                    this.updateCenter(lat, long);
                } catch (error) {
                    this.gpsMarker = [];
                    this.shopList = [];
                    this.gpsMessage = 'GPS 정보가 없습니다.';
                    console.error('Error fetching data:', error);
                }
            },

            // gps 
            updateCenter(lat, lng) {
                this.mapOptions.latitude = lat; // 새 위도 값으로 업데이트
                this.mapOptions.longitude = lng; // 새 경도 값으로 업데이트

                this.gpsCenterKey += 1;
            },

            cleanStatus(){
                this.activetedSUS.wEPS=false;
                this.activetedSUS.wIDB=false;
                this.activetedSUS.wSUS=false;
                this.activetedSUS.aEPS=false;
                this.activetedSUS.aIDB=false;
                this.activetedSUS.aSUS=false;

                this.cleanData();
                this.cleanDCT();
            },

            cleanData(){
                this.deviceList = ({});
                this.deviceHistoryList = ({});
                this.paramData['code_id'] = '';
                this.dtcSelectedItem = null;
                this.dtcHistSelectedItem = null;
            },

            cleanDCT(){
                this.dct.info= "";
                this.dct.gear = "";
                this.dct.msg = "";
  
            },

            setManual(index){
                this.deviceManual = this.deviceList[index].manual;
                if(this.deviceManual[0]==null) this.deviceManual=[];

                this.menualHTML = [];

                if(this.deviceManual.length == 0){
                    this.closeModal();
                    alert("Manual 이 없습니다.");
                }{
                    this.deviceManual.forEach(e =>{
                        this.showManual(e);
                    })
                    
                
                }
                
                // console.log(this.deviceManual);
            },

            async showManual(filename){
                try {
                   const headers = {
                       
                        "type": this.paramData['type'],
                        "file_name": encodeURIComponent(filename)
                      
                   };
                   const response = await common.apiDlGet('/dl/can/menual', headers);
                   
                   this.menualHTML.push(response.data);

               } catch (error) {
                   console.error('Error fetching data:', error);
               }
            },

            clickAddMember() {
                if(this.selectSteeringList.length > 4){
                    alert("선택 항목은 5개를 초과 할수 없습니다.");
                }else{
                    if ( this.unSelectedVList.length > 0 ) {
                    
                        this.unSelectedVList.forEach((item) => {
                            this.selectSteeringList.push(item);
                        });

                        
                        // console.log(this.steeringList);
                        
                        this.listSearch();

                        this.steeringList.forEach((item, index) => {
                            if(item.measure_name == this.unSelectedVList[0].measure_name){
                                this.steeringList.splice(index, 1);
                            }
                        })
                        // console.log(this.unSelectedVList[0].measure_name);
                        // console.log(this.steeringList);
                        // this.steeringList = common.deleteRowData(this.steeringList, this.unSelectedVList, "index");
                        this.unSelectedVList = [];
                        
                    }
                }
                    
            },

            clickDelMember() {
                if ( this.selectedVList.length > 0 ) {
                    this.selectedVList.forEach(item => {
                        this.steeringList.push(item);
                    });
                    
                    this.selectSteeringList = common.deleteRowData(this.selectSteeringList, this.selectedVList, "index");
                    this.selectedVList = [];

                    
                }
            },
  
            clickTogglePrj() {
                const toggle = !this.prjInfoToggle;
                this.prjInfoToggle = toggle;
            },

            hideUtils() { // 부가기능 - 보이기 숨기기
                if(this.InferenceUtil == true) {
                    this.InferenceUtil = false;
                } else if(this.InferenceUtil == false){
                    this.InferenceUtil = true;
                }
            },

            async clipCoppy() { // 공유하기
                let dummy = document.createElement("textarea");
                document.body.appendChild(dummy);
                try {
                    const url = window.document.location.href;
                    dummy.value = url;
                    dummy.select();
                    document.execCommand("copy");
                    document.body.removeChild(dummy);
                    alert('url copy success')
                } catch(error) {
                    console.error(error);
                } 
            },
            async taskModify() { // 수정
                if ( this.manageRole ) {
                    const taskId = this.$route.query.taskId;
                    const prjId = this.$route.query.prjId;
                    const taskTypeId = this.$route.query.taskTypeId;
                    const query= {
                        prjId: prjId,
                        taskId: taskId,
                        taskTypeId: taskTypeId,
                    };
                    // console.log(taskId);
                    try {
                        this.$router.push({
                            name: 'AddTask', query });
                    } catch(error) {
                        console.error('Error:', error);
                    }
                }
            },

            showModalMember(){
                const $modal = this.$refs.modalWrap;
                const $modalMomber = this.$refs.modalMomber;
                $modal.style.display = "block";
                $modalMomber.style.display = "block";
            },
            async showModalManual(index){
                const $modal = this.$refs.modalWrap;
                const $modalMenual = this.$refs.modalMenual;
               
                $modal.style.display = "block";
                $modalMenual.style.display = "block";

                this.setManual(index);
            },
            async showModalMessege(txt, exec){
                // const accessAllow = await this.checkAccessButton(txt, exec);
                // if(!accessAllow) {
                //     return;
                // }

                if ( this.manageRole ) {
                    const $modal = this.$refs.modalWrap;
                    const $modalMessege = this.$refs.modalMessege;
                    this.meseegeTxt = txt;
                    this.btnExec = exec;
                    $modal.style.display = "block";
                    $modalMessege.style.display = "block";
                }
            },

            // async checkAccessButton(txt, exec) {
            //     //member role, project role 확인
            //     const memberRole = await this.getMemberRole();
            //     const projectRole = await this.getProjectRole();
                
            //     //refresh 버튼 클릭시 권한 체크 (SM, Project Manager, Project Researcher, Project Observer 만 가능)
            //     if(txt == 'Refresh' && exec == 'F') {
            //         if(memberRole != null && memberRole.role == "SM") {
            //             return true;
            //         } else if(projectRole != null && (projectRole.projectRole == "Manager" || projectRole.projectRole == "Researcher" || projectRole.projectRole == "Observer")) {
            //             return true;
            //         } else {
            //             alert("SM, Project Manager, Project Researcher, Project Observer만 접근 가능");
            //             return false;
            //         }
            //     } 
            //     //DTC Read 버튼 클릭시 권한 체크 (SM, Project Manager, Project Researcher, Project Observer 만 가능)
            //     else if(txt == 'DTC Read' && exec == 'R') {
            //         if(memberRole != null && memberRole.role == "SM") {
            //             return true;
            //         } else if(projectRole != null && (projectRole.projectRole == "Manager" || projectRole.projectRole == "Researcher" || projectRole.projectRole == "Observer")) {
            //             return true;
            //         } else {
            //             alert("SM, Project Manager, Project Researcher, Project Observer만 접근 가능");
            //             return false;
            //         }
            //     }
            //     //DTC Clear 버튼 클릭시 권한 체크 (SM, Project Manager, Project Researcher 만 가능)
            //     else if(txt == 'DTC Clear' && exec == 'C') {
            //         if(memberRole != null && memberRole.role == "SM") {
            //             return true;
            //         } else if(projectRole != null && (projectRole.projectRole == "Manager" || projectRole.projectRole == "Researcher")) {
            //             return true;
            //         } else {
            //             alert("SM, Project Manager, Project Researcher만 접근 가능");
            //             return false;
            //         }
            //     }
            // },



            closeModal(){
                const $modal = this.$refs.modalWrap;
                const $modalMessege = this.$refs.modalMessege;
                const $modalMomber = this.$refs.modalMomber;
                const $modalMenual = this.$refs.modalMenual;
                $modal.style.display = "none";
                $modalMessege.style.display = "none";
                $modalMenual.style.display = "none";
                $modalMomber.style.display = "none";
            },
            tabEvent(index){
                const $listTab = this.$refs.listTab;
                const $listTabBox = this.$refs.listTabBox;
                let $listTabItem = $listTab.querySelectorAll('li');
                let $listTabBoxItem = $listTabBox.querySelectorAll('.data_list_box');
                for(var i=0 ; i < $listTabItem.length ; i ++ ){
                    $listTabItem[i].classList.remove("active");
                    $listTabBoxItem[i].classList.remove("active");
                }
                $listTabItem[index].classList.add("active");
                $listTabBoxItem[index].classList.add("active");
            },
            
            
        }
    }
</script>
<style scoped lang="scss">
    .dct_dashboard{
        .filter_box{
            width: 100%;
            height: 40px;
            margin: 15px 0;
            position: relative;
            // .target_path{
            //     height: 100%;
            //     line-height: 40px;
            //     display: inline-block;
            //     vertical-align: middle;
            //     span{
            //         font-weight: $bold;
            //         &::after{
            //             display: inline;
            //             content: ' > ';
            //             font-weight: $regular;
            //         }
            //         &:last-child::after{
            //             display: none;
            //         }
            //     }
            // }
            .setting_icon{
                margin-left: 10px;
            }
            .dtcsearch_icon{
                position: absolute;
                top: 0;
                right: 0;
                &.active{
                    background-color: $mainColor;
                    background-image: url($baseURL+'/common/dtcsearch_click_icon.svg');
                }   
            }
            .dct_type{
                width: 170px;
                position: absolute;
                top: 50px;
                right: 0;
                display: block;
                background-color: $whiteColor;
                box-shadow: 0px 0px 10px #002B6833;
                z-index: 1;
                ul{
                    li{
                        box-sizing: border-box;
                        padding: 10px 0 10px 20px;
                        border-bottom: 1px solid $line02Color;
                        background-repeat: no-repeat;
                        &:last-child{
                            border-bottom: none;
                        }
                        &.warning{
                            padding-left: 35px;
                            background-position: 10px 12px;
                            background-image: url($baseURL+'/common/dtc_warning.svg');
                            div{
                                color: #EF9B39;
                            }
                        }
                        &.active{
                            div{
                                background-position: 110px 4px;
                                background-image: url($baseURL+'/common/drpdown_check.svg');
                            }
                        }
                        div{
                            color: #B9BFCB;
                            background-repeat: no-repeat;
                        }
                    }
                }
                
            }
        }
        #dtcData{
            .img_dashboard{
                width: 100%;
                height: 308px;
                margin-bottom: 20px;
                box-sizing: border-box;
                padding: 34px 0;
                & > .float_box{
                   & > div{
                        height: 240px;
                        float: left;
                        box-sizing: border-box;
                        &.img_dct{
                            width: 640px;
                            background-color: #fff;
                            position: relative;
                            background-image: url($baseURL + "common/car_2d.svg");
                            background-repeat: no-repeat;
                            background-position: center;
                            button{
                                width: 55px;
                                height: 55px;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                                position: absolute;
                                &.active{
                                    width: 100px;
                                    height: 100px;
                                }
                                &.icon_brake{
                                    top: 93px;
                                    left: 72px;
                                    background-image: url($baseURL + "common/icon_brake_dis.svg");
                                    &.active{
                                        top: 70px;
                                        left: 46px;
                                    }
                                    &.warning{
                                        background-image: url($baseURL + "common/icon_brake_big.svg");
                                    }
                                }
                                &.icon_susp{
                                    top: 0;
                                    left: 180px;
                                    background-image: url($baseURL + "common/icon_susp_dis.svg");
                                    &.active{
                                        top: -22px;
                                        left: 152px;
                                    }
                                    &.warning{
                                        background-image: url($baseURL + "common/icon_susp_big.svg");
                                    }
                                }
                                &.icon_steer{
                                    top: 150px;
                                    left: 190px;
                                    background-image: url($baseURL + "common/icon_steer_dis.svg");
                                    &.active{
                                        top: 122px;
                                        left: 163px;
                                    }
                                    &.warning{
                                        background-image: url($baseURL + "common/icon_steer_big.svg");
                                    }
                                    
                                }
                            }

                        }
                        &.dct_info{
                            width: 448px;
                            border-right: 1px solid $line02Color;
                            border-left: 1px solid $line02Color;
                            box-sizing: border-box;
                            padding: 30px 20px;
                            strong{
                                font-size: $fontMenuTitle2;
                                font-weight: $bold;
                            }
                            .dtc_msg{
                                height: 52px;
                                margin-top: 30px;
                                span{
                                    font-weight: $bold;
                                }
                                p{
                                    margin-top: 15px;
                                    font-size: 20px;
                                }
                            }

                        }
                        &.dct_btn{
                            width: 188px;
                            &>div{
                                display: inline-block;
                                text-align: center;
                                margin-top: 30px;
                                button{
                                    width: 130px;
                                    height: 40px;
                                    border-radius: 10px;
                                    border: 1px solid #DEDEDE;
                                    box-sizing: border-box;
                                    margin: 10px 0;
                                    &.mcbtn{
                                        border: none;
                                    }
                                }
                            }
                        }
                    }
                }    
            }
            & > .float_box{
                & > div{
                    width: 628px;
                    height: 308px;
                    float: left;
                    position: relative;
                    &:nth-child(2n){
                        margin-left: 20px;
                    }
                    &.dct_history{
                        .mando_table_wrap{
                            height: 270px;
                        }
                        .mando_table{
                            tbody{
                                tr{
                                    &.active{
                                        background-color: $mainColor;
                                        td{
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.dct_list{
                        margin-bottom: 20px;
                        .mando_table{
                            tbody{
                                tr{
                                    &.active{
                                        background-color: $mainColor;
                                        td{
                                            color: $whiteColor;
                                            &.potal_row_item{
                                                span{
                                                    background-image: url($baseURL+'/common/external_link_wh.svg');
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.dct_chart{
                        margin-bottom: 20px;
                    }
                    .cont_title_box{
                        position: relative;
                        padding-top: 20px;
                        padding-left: 20px;
                        strong{
                            font-weight: $bold;
                        }
                        .setting_icon{
                            position: absolute;
                            top: 10px;
                            right: 20px;
                        }
                    }
                }
                .mando_table{
                    table-layout: fixed;
                    th, td{
                        padding: 14px 0;
                    }
                }
                .chart_box{
                    width: 568px;
                    height: 230px;
                    margin: 20px auto 0;
                    // background-color: #ff0;
                }
                .map_box{
                    width: 420px;
                    height: 255px;
                    margin-top: 15px;
                    border-top: 1px solid $line02Color;
                    box-sizing: border-box;
                    position: relative;
                    &>.time_stamp{
                        width: 100%;
                        height: 35px;
                        line-height: 35px;
                        color: $whiteColor;
                        text-align: center;
                        background-color: rgba(0,0,0,.5);
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        font-weight: $bold;
                        box-sizing: border-box;
                        z-index: 100;
                    }
                }
                .area_box{
                    width: 208px;
                    height: 308px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    border-radius: 0 10px 10px 0;
                    border-left: 1px solid $line02Color;
                    &>div{
                        width: 100%;
                        height: 54px;
                        border-bottom: 1px solid $line02Color;
                        text-align: center;
                        font-weight: $bold;
                        box-sizing: border-box;
                        padding-top: 20px;
                    }
                    &>ul{
                        width: 100%;
                        height: 254px;
                        overflow-y: scroll;
                        li{
                            padding: 12px 15px;
                            border-bottom: 1px solid $line02Color;
                            line-height: 25px;
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
            &.warning{
                .img_dashboard{
                    box-shadow: 0px 2px 10px #EF9B3967;
                }
                strong{
                    color: #EF9B39;
                }
            }
        }
    }
    #modalWrap{
        .modal_tab_wrap{
            .list_tab.float_box{
                height: auto;
                li{
                    top: 0;
                    &::after{
                        display: none;
                    }
                    &.active{
                        border: 1px solid $line01Color;
                        border-bottom: none;
                        box-shadow: none;
                        top: 1px;
                        &>div{
                            box-shadow: none;
                        }
                    }
                }
            }
            .tab_data{
                width: 100%;
                height: 665px;
                padding: 15px;
                border-radius: 0 10px 10px 10px;
                border: 1px solid $line01Color;
                box-sizing: border-box;
                overflow-y: scroll;
                .data_list_box{
                    display: none;
                    &.active{
                        display: block;
                        width: 100%;
                        height: 1000px;
                        background-color: #fff;
                    }
                }
            }
        }
    }
    
    
</style>