
import { createApp } from 'vue'
import App from '@/App.vue'
import store from './store';

// Naver map 
import { createNaverMap } from "vue3-naver-maps";


// 전역 
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';



// 생성한 뷰 라우터 받아오기
import { router } from '@/router/index.js'




const app = createApp(App)
app.use(VCalendar, {})
app.use(router)
// vue-naver-maps 4cnjtzfsi6 \
// mado navr k8r8gb1ti6
app.use(createNaverMap, {
    clientId: "k8r8gb1ti6", // Required
    category: "ncp", // Optional
    subModules: [], // Optional, "panorama" | "geocoder" | "drawing" | "visualization"
  })


app.use(store)
app.mount('#app')

app.config.globalProperties.baseURL = '@/assets/images/';


