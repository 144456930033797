import axios from 'axios';

// VPHM01 Backend API
const axiosInstanceVphm01 = axios.create({
  // baseURL: 'https://wy5is4458a.execute-api.ap-northeast-2.amazonaws.com',
  baseURL: 'https://cd70xlwu1j.execute-api.ap-northeast-2.amazonaws.com',
  headers: {
    'Content-Type': 'application/json',
    // Include the JWT token in the Authorization header
    // Replace 'token' with the actual token you have stored
    'X-API-Key': 'vehicle_key_1'
  },
});

const apiCallVphm01 = {
    get(url, params = {}) {
      return axiosInstanceVphm01.get(url, { params });
    },
    post(url, data = {}) {
      return axiosInstanceVphm01.post(url, data);
    },
    patch(url, data = {}) {
        return axiosInstanceVphm01.patch(url, data);
    },
    delete(url) {
      return axiosInstanceVphm01.delete(url);
    },
    put(url, data = {}) {
      return axiosInstanceVphm01.put(url, data);
  },
};

export default apiCallVphm01;
