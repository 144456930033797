<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>데이터/ TASK 운영</span>
                    <span>차량 모니터링 목록</span>
                    <span class="active">Vision 데이터 처리 통계</span>
                </div>
                <h2>[TASK_VAMS] {{jk1_steering.taskName}}</h2>
            </div> -->
            <BreadcrumbLayout pageId="monTASK_taskVAMS" :name="'[TASK_VAMS] ' + jk1_steering.taskName"/>
            <div class="item_info">
                <div class="item_title">
                    <strong class="task_project">[PROJECT] {{ jk1_steering.projectName }}</strong>
                    <div class="item_info_btn_box">
                        <button type="button" class="btn_fold" v-bind:style="prjInfoToggle ? 'display:none': ''" @click="clickTogglePrj()">접기</button>
                        <button type="button" class="btn_fold active" v-bind:style="!prjInfoToggle ? 'display:none': ''" @click="clickTogglePrj()">펼치기</button>
                        <button type="button" class="btn_evt_group" @click="hideUtils"></button>
                        <ul class="evt_btn_box radiusbox" :style="{ display: InferenceUtil ? 'block' : 'none' }">
                            <li class="download_item">
                                <button type="button" @click="makePDF">데이터 다운로드</button>
                            </li>
                            <li class="share_item">
                                <button type="button" @click="clipCoppy">공유하기</button>
                            </li>
                            <li class="retouch_item" v-if="manageRole">
                                <button type="button" @click="taskModify">TASK 수정</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item_table radiusbox" v-bind:style="prjInfoToggle ? 'display:none': ''">
                    <table>
                        <thead>
                            <tr>
                                <!-- <th>Project ID</th> -->
                                <th>Project ID</th>
                                <th>AWS ID</th>
                                <th>PM</th>
                                <th>Project Type</th>
                                <th>Unit</th>
                                <th>Last Activity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ jk1_steering.projectName }}</td>
                                <td>{{ jk1_steering.awsId }}</td>
                                <td>{{ jk1_steering.pm }}</td>
                                <td>{{ jk1_steering.type }}</td>
                                <td>{{ jk1_steering.teamName }}</td>
                                <td>{{ jk1_steering.lastVisit }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="item_desc">
                        <div class="desc_title">Description</div>
                        <div class="desc_txt">{{ jk1_steering.description }}</div>
                    </div>
                </div>
            </div>
            <div id="monCont" class="vision_data">
                <div class="filter_box">
                    <select name="a" v-model="selectedModel" style="width: 150px; background-position: 120px center;">
                        <option selected>차종</option>
                        <option v-for='model in modelList' :key="model.model_no" :value="model.model_no">{{ model.model_no }}</option>
                    </select>
                    <div class="date_filter">
                        <select name="datelist" v-model="durationselectedOption" style="width: 110px; background-position: 80px center;" @change="durationSelectChange">
                            <option selected>기간선택</option>
                            <option value="1">1일</option>
                            <option value="7">7일</option>
                            <option value="14">14일</option>
                            <option value="30">30일</option>
                        </select>
                        <div class="date_period">
                            <div class="date_box">
                                <span style="display:inline-block ; padding : 0 10px 0 10px;" :style="picStartTxtStyle" ref="picStartTxt">{{ picStartDate }}</span> ~ <span style="display:inline-block ; padding: 0 0 0 10px;" :style="picEndTxtStyle" ref="picEndTxt">{{picEndDate}}</span>
                            </div>
                            <button type="button" ref="datePicToggle" @click="datePicToggle()"></button>
                            <div class="calender_box radiusbox" ref="calenderBox" style="display:none;">
                                <div class="start_date date_box" ref="startDateBox" @click="onDatePic('S')" style="display:block">
                                    <!-- <VCalendarUi :dateInterface="'start'" :setDate="'-7'"/> -->
                                    <VCalendarUi :dateInterface="'start'" />
                                    <button type="button" @click="pickerNext()">다음</button>
                                </div>
                                <div class="end_date date_box" ref="endDateBox" @click="onDatePic('E')">
                                    <VCalendarUi :dateInterface="'end'" :minDate="picStartDate"/>
                                    <button type="button" @click="pickerPrev()">이전</button>
                                    <button type="button" class="mcbtn" @click="getDriList()">확정</button>
                                </div>
                            </div>
                        </div>
                        <button type="button" style="margin-left: 20px" @click="btn_search()" class="btn_send mcbtn">조회</button>
                    </div>
                </div>
                <div class="vision_list_wrap">
                    <ul class="list_tab float_box" ref="listTab">
                        <li @click="tabEvent(0)" class="active">
                            <div>Prediction</div>
                        </li>
                        <li @click="tabEvent(1)">
                            <div>Real Data</div>
                        </li>
                        <li @click="tabEvent(2)">
                            <div>Auto Labeling</div>
                        </li>
                    </ul>
                    <div class="tab_data" ref="listTabBox">
                        <div class="data_list_box mando_table_wrap active">
                            <table class="mando_table custom_thead">
                                <colgroup>
                                    <col style="width:20%">
                                    <col style="width:20%">
                                    <col style="width:20%">
                                    <col style="width:20%">
                                    <col style="width:20%">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th rowspan="2">구분</th>
                                        <th colspan="2" style="border-bottom: 1px solid #E5E9EF;">Prediction</th>
                                        <th colspan="2" style="border-bottom: 1px solid #E5E9EF;">Legacy</th>
                                    </tr>
                                    <tr>
                                        <th>OK</th>
                                        <th>NG</th>
                                        <th>OK</th>
                                        <th>NG</th>
                                    </tr>
                                </thead>
                                <tbody class="accordion_list">
                                    <template v-for="(p1_item, index) in predictionList_01" :key="p1_item.model_no">
                                    <!-- <tr class="item active" v-for="(p1_item, index) in predictionList_01" :key="p1_item.model_no" @click='api_st_pre_002_call(p1_item.model_no, index)'> -->
                                    <tr class="item active" @click='api_st_pre_002_call(p1_item.model_no, index)'>
                                        <td><button type="button" class="arrow_icon"></button>{{ p1_item.model_no }}</td>
                                        <td>{{ p1_item.prediction_ok_per }} ( {{ p1_item.prediction_ok }} / {{ p1_item.total }} )</td>
                                        <td>{{ p1_item.prediction_ng_per }} ( {{ p1_item.prediction_ng }} / {{ p1_item.total }} )</td>
                                        <td>{{ p1_item.legacy_ok_per }} ( {{ p1_item.legacy_ok }} / {{ p1_item.total }} )</td>
                                        <td>{{ p1_item.legacy_ng_per }} ( {{ p1_item.legacy_ng }} / {{ p1_item.total }} )</td>
                                    </tr>
                                    <tr class="item_detail" v-if="expandedRow === index">
                                        <td colspan="9">
                                            <div>
                                                <!-- <strong class="item_title">116 NG accuracy</strong> -->
                                                <swiper
                                                    :navigation="swiperNavigationOptions"
                                                    :modules="modules"
                                                    class="mySwiper chart_box"
                                                >
                                                    <swiper-slide>
                                                        <div class="chart">
                                                            <canvas :ref="'statistics-pre-' + index"></canvas>
                                                        </div>
                                                    </swiper-slide>
                                                    <!-- <swiper-slide>
                                                        <div class="chart">
                                                            chart2
                                                        </div>
                                                    </swiper-slide> -->
                                                    <!-- navigation -->
                                                    <div class="custom-button custom-prev-button"></div>
                                                    <div class="custom-button custom-next-button"></div>
                                                </swiper>
                                                <div class="chart_table mando_table_wrap">
                                                    <table class="mando_table">
                                                        <thead>
                                                            <tr>
                                                                <th>구분</th>
                                                                <th>항목</th>
                                                                <th v-for="p2_item_date in predictionList_02_date" :key="p2_item_date.date">{{ p2_item_date.date }}</th>
                                                                <!--<th>합계</th>-->
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="p2_item_model_point in predictionList_02_model_point" :key="p2_item_model_point.model_point">
                                                                <tr>
                                                                    <td rowspan="4">{{ p2_item_model_point.model_point }}</td>
                                                                    <td>prediction_ok</td>
                                                                    <template v-for="p2_item_date in predictionList_02_date" :key="p2_item_date.date">
                                                                        <template v-for="p2_item in predictionList_02" :key="p2_item.date">
                                                                            <template v-if="p2_item_model_point.model_no === p2_item.model_no 
                                                                                && p2_item_model_point.point === p2_item.point 
                                                                                && p2_item_date.date === p2_item.date">
                                                                                <td>{{ p2_item.prediction_ok_per }} ({{ p2_item.prediction_ok }} / {{ p2_item.total }})</td>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                </tr>
                                                                <tr>
                                                                    <td>prediction_ng</td>
                                                                    <template v-for="p2_item_date in predictionList_02_date" :key="p2_item_date.date">
                                                                        <template v-for="p2_item in predictionList_02" :key="p2_item.date">
                                                                            <template v-if="p2_item_model_point.model_no === p2_item.model_no 
                                                                                && p2_item_model_point.point === p2_item.point 
                                                                                && p2_item_date.date === p2_item.date">
                                                                                <td>{{ p2_item.prediction_ng_per }} ({{ p2_item.prediction_ng }} / {{ p2_item.total }})</td>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                    <!-- <td v-for="p2_item in predictionList_02" :key="p2_item.date">{{ p2_item.prediction_ng_per }} ({{ p2_item.prediction_ng }} / {{ p2_item.total }})</td> -->
                                                                </tr>
                                                                <tr>
                                                                    <td>legacy_ok</td>
                                                                    <template v-for="p2_item_date in predictionList_02_date" :key="p2_item_date.date">
                                                                        <template v-for="p2_item in predictionList_02" :key="p2_item.date">
                                                                            <template v-if="p2_item_model_point.model_no === p2_item.model_no 
                                                                                && p2_item_model_point.point === p2_item.point 
                                                                                && p2_item_date.date === p2_item.date">
                                                                                <td>{{ p2_item.legacy_ok_per }} ({{ p2_item.legacy_ok }} / {{ p2_item.total }})</td>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                    <!-- <td v-for="p2_item in predictionList_02" :key="p2_item.date">{{ p2_item.legacy_ok_per }} ({{ p2_item.legacy_ok }} / {{ p2_item.total }})</td> -->
                                                                </tr>
                                                                <tr>
                                                                    <td>legacy_ng</td>
                                                                    <template v-for="p2_item_date in predictionList_02_date" :key="p2_item_date.date">
                                                                        <template v-for="p2_item in predictionList_02" :key="p2_item.date">
                                                                            <template v-if="p2_item_model_point.model_no === p2_item.model_no 
                                                                                && p2_item_model_point.point === p2_item.point 
                                                                                && p2_item_date.date === p2_item.date">
                                                                                <td>{{ p2_item.legacy_ng_per }} ({{ p2_item.legacy_ng }} / {{ p2_item.total }})</td>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                    <!-- <td v-for="p2_item in predictionList_02" :key="p2_item.date">{{ p2_item.legacy_ng_per }} ({{ p2_item.legacy_ng }} / {{ p2_item.total }})</td> -->
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </template>
                                </tbody>
<!--
                                <tfoot class="statistics_box">
                                    <tr>
                                        <td>평균(합)</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                </tfoot>
-->
                            </table>
                        </div>
                        <div class="data_list_box mando_table_wrap ">
                            <table class="mando_table custom_thead">
                                <colgroup>
                                    <col style="width:14%">
                                    <col style="width:10%">
                                    <col style="width:10%">
                                    <col style="width:10%">
                                    <col style="width:10%">
                                    <col style="width:10%">
                                    <col style="width:10%">
                                    <col style="width:13%">
                                    <col style="width:13%">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th rowspan="2">구분</th>
                                        <th colspan="2" style="border-bottom: 1px solid #E5E9EF;">Prediction</th>
                                        <th colspan="2" style="border-bottom: 1px solid #E5E9EF;">Legacy</th>
                                        <th colspan="2" style="border-bottom: 1px solid #E5E9EF;">Real</th>
                                        <th rowspan="2">NG accuracy 1 <br>(prediction & Real)</th>
                                        <th rowspan="2">NG accuracy 2 <br>(prediction & Real)</th>
                                    </tr>
                                    <tr>
                                        <th>OK</th>
                                        <th>NG</th>
                                        <th>OK</th>
                                        <th>NG</th>
                                        <th>OK</th>
                                        <th>NG</th>
                                    </tr>
                                </thead>
                                <tbody class="accordion_list">
                                    <template v-for="(p1_item, index) in realDataList_01" :key="p1_item.model_no">
                                    <tr class="item active" @click='api_st_real_002_call(p1_item.model_no, index)'>
                                        <td style="padding-left: 60px" ><button type="button" class="arrow_icon"></button>{{ p1_item.model_no }}</td>
                                        <td>{{ p1_item.prediction_ok_per }} ( {{ p1_item.prediction_ok }} / {{ p1_item.total }} )</td>
                                        <td>{{ p1_item.prediction_ng_per }} ( {{ p1_item.prediction_ng }} / {{ p1_item.total }} )</td>
                                        <td>{{ p1_item.legacy_ok_per }} ( {{ p1_item.legacy_ok }} / {{ p1_item.total }} )</td>
                                        <td>{{ p1_item.legacy_ng_per }} ( {{ p1_item.legacy_ng }} / {{ p1_item.total }} )</td>
                                        <td>{{ p1_item.real_ok_per }} ( {{ p1_item.real_ok }} / {{ p1_item.total }} )</td>
                                        <td>{{ p1_item.real_ng_per }} ( {{ p1_item.real_ng }} / {{ p1_item.total }} )</td>
                                        <td>{{ p1_item.ng_accuracy1 }}</td>
                                        <td>{{ p1_item.ng_accuracy2 }}</td>
                                    </tr>
                                    <tr class="item_detail" v-if="expandedRowReal === index">
                                        <td colspan="9">
                                            <div>
                                                <!-- <strong class="item_title">116 NG accuracy</strong> -->
                                                <swiper
                                                    :navigation="swiperNavigationOptions"
                                                    :modules="modules"
                                                    class="mySwiper chart_box"
                                                >
                                                    <swiper-slide>
                                                        <div class="chart">
                                                            <canvas :ref="'statistics-real-' + index"></canvas>
                                                        </div>
                                                    </swiper-slide>
                                                    <!-- <swiper-slide>
                                                        <div class="chart">
                                                            chart2
                                                        </div>
                                                    </swiper-slide> -->
                                                    <!-- navigation -->
                                                    <div class="custom-button custom-prev-button"></div>
                                                    <div class="custom-button custom-next-button"></div>
                                                </swiper>
                                                <div class="chart_table mando_table_wrap">
                                                    <table class="mando_table">
                                                        <thead>
                                                            <tr>
                                                                <th>구분</th>
                                                                <th>항목</th>
                                                                <th :ref='realDate' v-for="p2_item_date in realDataList_02_date" :key="p2_item_date.date">{{ p2_item_date.date }}</th>
                                                                <!--<th>합계</th>-->
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="p2_item_model_point in realDataList_02_model_point" :key="p2_item_model_point.model_point">
                                                                <tr>
                                                                    <td rowspan="5">{{ p2_item_model_point.model_point }}</td>
                                                                    <td>prediction_ng</td>
                                                                    <template v-for="p2_item_date in realDataList_02_date" :key="p2_item_date.date">
                                                                        <template v-for="p2_item in realDataList_02" :key="p2_item.date">
                                                                            <template v-if="p2_item_model_point.model_no === p2_item.model_no 
                                                                                && p2_item_model_point.point === p2_item.point 
                                                                                && p2_item_date.date === p2_item.date">
                                                                                <td>{{ p2_item.prediction_ng }}</td>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                </tr>
                                                                <tr>
                                                                    <td>legacy_ng</td>
                                                                    <template v-for="p2_item_date in realDataList_02_date" :key="p2_item_date.date">
                                                                        <template v-for="p2_item in realDataList_02" :key="p2_item.date">
                                                                            <template v-if="p2_item_model_point.model_no === p2_item.model_no 
                                                                                && p2_item_model_point.point === p2_item.point 
                                                                                && p2_item_date.date === p2_item.date">
                                                                                <td>{{ p2_item.legacy_ng }}</td>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                    <!-- <td v-for="p2_item in predictionList_02" :key="p2_item.date">{{ p2_item.prediction_ng_per }} ({{ p2_item.prediction_ng }} / {{ p2_item.total }})</td> -->
                                                                </tr>
                                                                <tr>
                                                                    <td>real_ng</td>
                                                                    <template v-for="p2_item_date in realDataList_02_date" :key="p2_item_date.date">
                                                                        <template v-for="p2_item in realDataList_02" :key="p2_item.date">
                                                                            <template v-if="p2_item_model_point.model_no === p2_item.model_no 
                                                                                && p2_item_model_point.point === p2_item.point 
                                                                                && p2_item_date.date === p2_item.date">
                                                                                <td>{{ p2_item.real_ng }}</td>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                    <!-- <td v-for="p2_item in predictionList_02" :key="p2_item.date">{{ p2_item.legacy_ok_per }} ({{ p2_item.legacy_ok }} / {{ p2_item.total }})</td> -->
                                                                </tr>
                                                                <tr>
                                                                    <td>ng_accuracy1</td>
                                                                    <template v-for="p2_item_date in realDataList_02_date" :key="p2_item_date.date">
                                                                        <template v-for="p2_item in realDataList_02" :key="p2_item.date">
                                                                            <template v-if="p2_item_model_point.model_no === p2_item.model_no 
                                                                                && p2_item_model_point.point === p2_item.point 
                                                                                && p2_item_date.date === p2_item.date">
                                                                                <td>{{ p2_item.ng_accuracy1 }}</td>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                    <!-- <td v-for="p2_item in predictionList_02" :key="p2_item.date">{{ p2_item.legacy_ng_per }} ({{ p2_item.legacy_ng }} / {{ p2_item.total }})</td> -->
                                                                </tr>
                                                                <tr>
                                                                    <td>ng_accuracy2</td>
                                                                    <template v-for="p2_item_date in realDataList_02_date" :key="p2_item_date.date">
                                                                        <template v-for="p2_item in realDataList_02" :key="p2_item.date">
                                                                            <template v-if="p2_item_model_point.model_no === p2_item.model_no 
                                                                                && p2_item_model_point.point === p2_item.point 
                                                                                && p2_item_date.date === p2_item.date">
                                                                                <td>{{ p2_item.ng_accuracy2 }}</td>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                    <!-- <td v-for="p2_item in predictionList_02" :key="p2_item.date">{{ p2_item.legacy_ok_per }} ({{ p2_item.legacy_ok }} / {{ p2_item.total }})</td> -->
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </template>
                                    <!-- <tr class="item_detail">
                                        <td colspan="5">
                                            <div>
                                                <strong class="item_title">116 NG accuracy</strong>
                                              
                                            </div>
                                        </td>
                                    </tr> -->
                                </tbody>
                                <!-- <tfoot class="statistics_box">
                                    <tr>
                                        <td>평균(합)</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                </tfoot> -->
                            </table>
                        </div>
                        <div class="data_list_box auto_label_data ">
                            <strong>현황</strong>
                            <div class="float_box">
                                <div class="status_box">
                                    <span>Success</span>
                                    <div class="ok_box success_item">
                                        <div>OK</div>
                                        <div class="count_num">{{ autoLabeling.success_ok }}</div>
                                    </div>
                                    <div class="ng_box success_item">
                                        <div>NG</div>
                                        <div class="count_num">{{ autoLabeling.success_ng }}</div>
                                    </div>
                                </div>
                                <div class="status_box border_box">
                                    <span>Fail</span>
                                    <div class="ok_box fail_item">
                                        <div>OK</div>
                                        <div class="count_num">{{ autoLabeling.fail_ok }}</div>
                                    </div>
                                    <div class="ng_box fail_item">
                                        <div>NG</div>
                                        <div class="count_num">{{ autoLabeling.fail_ng }}</div>
                                    </div>
                                </div>
                                <div class="manual_count">
                                    <div class="text_box">
                                        <strong>Manual labeling 필요 건수</strong>
                                        <div class="count_num">
                                            <div>{{ autoLabeling.need_manual_check }}</div> <span>건</span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import VCalendarUi from '@/components/VCalendarUi.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';
    import axios from 'axios';
    import common from '@/assets/js/common'
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Pagination, Navigation } from 'swiper/modules';
    // Import Swiper styles
    import 'swiper/css';
    import 'swiper/css/navigation';
    // Import chart
    import Chart from 'chart.js/auto';
    
    export default {
        name: 'aaView',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            Swiper,
            SwiperSlide,  
            VCalendarUi,
            BreadcrumbLayout,
        },
        
        data(){
            const picStartDate = null;
            const picEndDate = null;
            return{
                prjInfoToggle: false,
                modules: [Pagination, Navigation],
                swiperNavigationOptions: {
                    prevEl: '.custom-prev-button', // Replace with the CSS selector of your custom previous button
                    nextEl: '.custom-next-button', // Replace with the CSS selector of your custom next button
                },
                prjData: {
                    projectName:'No Data Error',
                    awsId:'No Data Error',
                    pm:'No Data Error',
                    projectType:'No Data Error',
                    lastVisit:'No Data Error',
                    edgeDeviceId:'No Data Error',
                    projectId: "No Data Error",
                    id: "No Data Error",
                    description: "No Data Error",
                },
                urlParam:{
                    prjId: '',
                    edgeId: ''
                },
                // -- VCalendarUi / .date_period
                picStartDate,
                picEndDate,
                picColor: {
                    startStatus: false,
                    endStatus: false,
                    active: '#00B4ED',
                    default: '#020A0A'
                },
                //----
                isFocused: false,
                isDragging: false,
                interval : null,
                status: 'pause',
                nowMilliseconds: 0,
                fPoint: 0,
                ePoint: 0,
                alertMessege: "시작 시간이 종료 시간을 <br/> 초과할 수 없습니다.",
                color:[
                '#80BDFF', '#409DFF', '#187BFF', '#0F5CBF', '#073E80',
                '#B7A1E0', '#9371D1', '#6F42C1', '#533191', '#382261',
                '#EE9AA2', '#E56874', '#DC3545', '#A52833', '#6E1A23',
                '#94D3A2', '#5EBD74', '#27A745', '#1F7D35', '#145423',
                '#FDBE89', '#FE9F4F', '#FD7E14', '#BE5F0F', '#7F3F0B',
                '#DDD8D6', '#CBC5C2', '#BAB1AD', '#8C8582', '#5D5957',
                '#B388F9', '#8C4CF5', '#660FF2', '#4D0DB6', '#330879',
                '#F49FC6', '#EE6EA9', '#E83E8C', '#AE2F69', '#741F46',
                '#FEE083', '#FED145', '#FEC106', '#C09106', '#806106',
                '#90E4CB', '#5AD7B1', '#20C997', '#189771', '#10654C',
                '#8BD1DC', '#51B9CA', '#17A2B8', '#117A8A', '#0D525B'
                ],
                timeSet:{
                    max: '',
                    HH: '00',
                    MM: '00',
                    SS: '00',
                    ss: '00'
                },
                drivList: null,
                thisDdivListId: null,
                recList: null,
                durationselectedOption: "기간선택",
                // 테스트 주소
                // axiosBaseUrl: 'https://b5cpwcgot0.execute-api.ap-northeast-2.amazonaws.com',
                // 실주소 
                axiosBaseUrl: 'https://637ye1vdp2.execute-api.ap-northeast-2.amazonaws.com',
                axiosJsonType: 'application/json',
                axiosApiKey: 'sfskey1',

                jk1_steering: {
                    projectId: null,
                    projectName: null,
                    awsId: null,
                    creatorName: null,
                    type: null,
                    teamName: null,
                    description: null,
                    lastVisit: null,
                    taskName: null,
                },

                selectedModel: '차종',
                modelList: [],

                predictionList_01: [],
                predictionList_02: [],
                predictionList_02_date: [],
                predictionList_02_model_point: [],

                realDataList_01: [],
                realDataList_02: [],
                realDataList_02_date: [],
                realDataList_02_model_point: [],

                autoLabeling: {
                    success_ok: null,
                    success_ng: null,
                    fail_ok: null,
                    fail_ng: null,
                    need_manual_check: null
                },

                chartInstance: null,
                chartInstanceReal: null,

                expandedRow : null,
                expandedRowReal :   null,

                realDate: null,

                InferenceUtil: false,

                projectId: '',

                // role
                viewRole: false,
                manageRole: false,
            };
        },
        computed: {
            picStartTxtStyle() {
                return {
                    color: this.picColor.startStatus ? this.picColor.active : this.picColor.default
                };
            },
            picEndTxtStyle() {
                
                return {
                    color: this.picColor.endStatus ? this.picColor.active : this.picColor.default
                };
            }

        },
        async mounted() {
            // Role
            await this.setRole();

            this.projectId = this.$route.query.prjId;

            this.durationSelectChange(0);
            this.jk1_steering_func();
        },
        methods: {
            async setRole() {
                const prjId = this.$route.query.prjId;
                this.manageRole = ( await common.getUserRole('type2') || await common.getPrjRole('type2', prjId) );
                this.viewRole = ( await common.getUserRole('type2') || await common.getPrjRole('type1', prjId) );

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            clickTogglePrj() {
                const toggle = !this.prjInfoToggle;
                this.prjInfoToggle = toggle;
            },

            hideUtils() { // 부가기능 - 보이기 숨기기
                if(this.InferenceUtil == true) {
                    this.InferenceUtil = false;
                } else if(this.InferenceUtil == false){
                    this.InferenceUtil = true;
                }
            },
            async clipCoppy() { // 공유하기
                let dummy = document.createElement("textarea");
                document.body.appendChild(dummy);
                try {
                    const url = window.document.location.href;
                    dummy.value = url;
                    dummy.select();
                    document.execCommand("copy");
                    document.body.removeChild(dummy);
                    alert('url copy success')
                } catch(error) {
                    console.error(error);
                } 
            },
            async taskModify() { // 수정
                if ( this.manageRole ) {
                    const taskId = this.$route.query.taskId;
                    const prjId = this.$route.query.prjId;
                    const taskTypeId = this.$route.query.taskTypeId;
                    const query= {
                        prjId: prjId,
                        taskId: taskId,
                        taskTypeId: taskTypeId,
                    };
                    // console.log(taskId);
                    try {
                        this.$router.push({
                            name: 'AddTask', query });
                    } catch(error) {
                        console.error('Error:', error);
                    }
                }
            },
            
            tabEvent(index){
                const $listTab = this.$refs.listTab;
                const $listTabBox = this.$refs.listTabBox;
                let $listTabItem = $listTab.querySelectorAll('li');
                let $listTabBoxItem = $listTabBox.querySelectorAll('.data_list_box');
                for(var i=0 ; i < $listTabItem.length ; i ++ ){
                    $listTabItem[i].classList.remove("active");
                    $listTabBoxItem[i].classList.remove("active");
                }
                $listTabItem[index].classList.add("active");
                $listTabBoxItem[index].classList.add("active");
            },
            // -------- DATE PICKER -----
            datePicToggle(){
                const vm = this;
                const _display = vm.$refs.calenderBox.style.display;
                const _startDateBox = vm.$refs.startDateBox.style.display;
                // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
                // console.log(_display);
                
                if(_display == 'block'){
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'none';
                    vm.picColor.startStatus = false;
                    vm.picColor.endStatus = false;

                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'block';
                    console.log(_startDateBox);
                    if(_startDateBox == 'block'){
                        vm.picColor.startStatus = true;
                        vm.picColor.endStatus = false;
                    }else if( _startDateBox == 'none'){
                        vm.picColor.startStatus = false;
                        vm.picColor.endStatus = true;
                    }
                }
            },
            async getDriList(){
                const vm = this;
                // this.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', this.picStartDate, this.picEndDate);
                // 켈린더 display처리
                const $calenderBox = vm.$refs.calenderBox;
                const $startDateBox = vm.$refs.startDateBox;
                const $endDateBox = vm.$refs.endDateBox;
                
                $calenderBox.style.display = 'none';
                $startDateBox.style.display = 'block';
                $endDateBox.style.display = 'none';
                
                vm.picColor.startStatus = false;
                vm.picColor.endStatus = false;


            },
            onDatePic(flag){
                if(flag == 'S'){   
                    if(window.selectDate.startDate !== null ){
                        this.picStartDate = window.selectDate.startDate;
                    }
                    
                }else if(flag == 'E'){
                    if(window.selectDate.picEndDate !== null ){
                        this.picEndDate = window.selectDate.endDate;
                    }
                }
            },
            pickerNext(){
                this.$refs.startDateBox.style.display = 'none';
                this.$refs.endDateBox.style.display = 'block';

                this.picColor.startStatus = false;
                this.picColor.endStatus = true;
                console.log(this.picColor.endStatus);
                console.log(this.picColor.target);
                
            },
            pickerPrev(){
                this.$refs.startDateBox.style.display = 'block';
                this.$refs.endDateBox.style.display = 'none';

                this.picColor.startStatus = true;
                this.picColor.endStatus = false;
            },
            durationSelectChange(){
                this.setSearchDate(this.durationselectedOption);
            },
            setSearchDate(duration){
                const stoday = new Date();
                if (duration != '기간선택') {
                    stoday.setDate(stoday.getDate() - duration);
                }
                const syear = stoday.getFullYear();
                const smonth = stoday.getMonth() + 1;
                const sday = stoday.getDate();
                const start_formattedDate = syear + '-' + (smonth < 10 ? '0' : '') + smonth + '-' + (sday < 10 ? '0' : '') + sday;

                const etoday = new Date();
                const eyear = etoday.getFullYear();
                const emonth = etoday.getMonth() + 1;
                const eday = etoday.getDate();
                const end_formattedDate = eyear + '-' + (emonth < 10 ? '0' : '') + emonth + '-' + (eday < 10 ? '0' : '') + eday;

                //console.log(this.picStartDate);
                this.picStartDate = start_formattedDate;
                //console.log(this.picStartDate);
                this.picEndDate = end_formattedDate;
                window.selectDate.startDate = start_formattedDate;
                window.selectDate.endDate = end_formattedDate;
            },
            jk1_steering_func(){
                try {
                    //alert(this.picStartDate+"///"+this.picEndDate);

                    common.apiGet('monitoring-task/' + this.$route.query.taskId + '?viewRole=' + this.viewRole).then(r => {
                        this.jk1_steering.projectId = r.data.data.projectId;
                        this.jk1_steering.projectName = r.data.data.projectName;
                        this.jk1_steering.awsId = r.data.data.awsId;
                        this.jk1_steering.creatorName = r.data.data.creatorName;
                        this.jk1_steering.type = r.data.data.type;
                        this.jk1_steering.teamName = r.data.data.teamName;
                        this.jk1_steering.description = r.data.data.description;
                        this.jk1_steering.lastVisit = r.data.data.lastVisit;
                        this.jk1_steering.pm = r.data.data.pm;
                        this.jk1_steering.taskName = r.data.data.taskName;
                    });

                    this.api_st_comm_001_call();

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            btn_search(){
                try {
                    //alert(this.picStartDate+"///"+this.picEndDate);

                    this.expandedRow = null;
                    this.expandedRowReal = null;

                    this.api_st_comm_001_call();
                    this.api_st_pre_001_call();
                    this.api_st_real_001_call();
                    this.api_st_al_001_call();

                    
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            api_st_comm_001_call(){
                const axioscall = axios.create({
                    baseURL: this.axiosBaseUrl,
                    headers: {
                        'accept': this.axiosJsonType,
                        'Content-Type': this.axiosJsonType,
                        'X-API-Key': this.axiosApiKey
                    },
                });

                const param_st_comm_001 = {
                    start_date: this.picStartDate,
                    end_date: this.picEndDate
                }

                axioscall.post('/SF/st_comm_001', param_st_comm_001).then(r => {
                    this.modelList = r.data.model;
                });
            },

            api_st_pre_001_call(){
                const axioscall = axios.create({
                    baseURL: this.axiosBaseUrl,
                    headers: {
                        'accept': this.axiosJsonType,
                        'Content-Type': this.axiosJsonType,
                        'X-API-Key': this.axiosApiKey
                    },
                });

                let param_st_pre_001 = {
                    start_date: this.picStartDate,
                    end_date: this.picEndDate
                }

                if (this.selectedModel != "차종"){
                    param_st_pre_001 = {
                        start_date: this.picStartDate,
                        end_date: this.picEndDate,
                        model: this.selectedModel
                    }
                }

                axioscall.post('/SF/st_pre_001', param_st_pre_001).then(r => {
                    // console.log(JSON.stringify(r.data.result));
                    this.predictionList_01 = r.data.result;
                });
            },

            api_st_pre_002_call(selectedModel_pre_002, index){

                if (this.expandedRow === index) {
                    this.expandedRow = null; // 이미 확장된 행을 다시 클릭하면 축소합니다.
                } else {
                    this.expandedRow = index; // 확장되지 않은 행을 클릭하면 확장합니다.
                }

                if (this.expandedRow !== null) {

                    const axioscall = axios.create({
                        baseURL: this.axiosBaseUrl,
                        headers: {
                            'accept': this.axiosJsonType,
                            'Content-Type': this.axiosJsonType,
                            'X-API-Key': this.axiosApiKey
                        },
                    });

                    let param_st_pre_002 = {
                        start_date: this.picStartDate,
                        end_date: this.picEndDate,
                        model: selectedModel_pre_002
                    }

                    let temp_arr = [];
                    let temp_arr_date = [];

                    axioscall.post('/SF/st_pre_002', param_st_pre_002).then(r => {
                        //console.log(JSON.stringify(r.data.result));
                        
                        r.data.result.forEach((value) => {
                            let temp = value.model_no+'-'+value.point;
                            temp_arr.push({model_point:temp,model_no:value.model_no,point:value.point,date:value.date});
                            temp_arr_date.push({date:value.date});
                        });

                        this.predictionList_02_model_point = Array.from( temp_arr.reduce((m, t) => m.set(t.model_point, t), new Map()).values());
                        this.predictionList_02_date = Array.from( temp_arr_date.reduce((m, t) => m.set(t.date, t), new Map()).values());

                        this.predictionList_02 = r.data.result;
                        //console.log(this.predictionList_02_model_point);
                        this.makeChart(index);
                    });
                }
            },

            api_st_real_001_call(){
                const axioscall = axios.create({
                    baseURL: this.axiosBaseUrl,
                    headers: {
                        'accept': this.axiosJsonType,
                        'Content-Type': this.axiosJsonType,
                        'X-API-Key': this.axiosApiKey
                    },
                });

                let param_real_pre_001 = {
                    start_date: this.picStartDate,
                    end_date: this.picEndDate
                }

                if (this.selectedModel != "차종"){
                    param_real_pre_001 = {
                        start_date: this.picStartDate,
                        end_date: this.picEndDate,
                        model: this.selectedModel
                    }
                }

                axioscall.post('/SF/st_real_001', param_real_pre_001).then(r => {
                    //console.log(JSON.stringify(r.data.result));
                    this.realDataList_01 = r.data.result;
                });
            },

            api_st_real_002_call(selectedModel_real_002, index){
                console.log(index);
                if (this.expandedRowReal === index) {
                    this.expandedRowReal = null; // 이미 확장된 행을 다시 클릭하면 축소합니다.
                } else {
                    this.expandedRowReal = index; // 확장되지 않은 행을 클릭하면 확장합니다.
                }

                if (this.expandedRowReal !== null) {
                    const axioscall = axios.create({
                        baseURL: this.axiosBaseUrl,
                        headers: {
                            'accept': this.axiosJsonType,
                            'Content-Type': this.axiosJsonType,
                            'X-API-Key': this.axiosApiKey
                        },
                    });

                    let param_st_real_002 = {
                        start_date: this.picStartDate,
                        end_date: this.picEndDate,
                        model: selectedModel_real_002
                    }
                    
                    let temp_arr = [];
                    let temp_arr_date = [];

                    axioscall.post('/SF/st_real_002', param_st_real_002).then(r => {
                        // console.log(JSON.stringify(r.data.result));
                        
                        r.data.result.forEach((value) => {
                            let temp = value.model_no+'-'+value.point;
                            temp_arr.push({model_point:temp,model_no:value.model_no,point:value.point,date:value.date});
                            temp_arr_date.push({date:value.date});
                        });

                        this.realDataList_02_model_point = Array.from( temp_arr.reduce((m, t) => m.set(t.model_point, t), new Map()).values());
                        this.realDataList_02_date = Array.from( temp_arr_date.reduce((m, t) => m.set(t.date, t), new Map()).values());

                        this.realDataList_02 = r.data.result;
                        //console.log(this.realDataList_02_model_point);
                        
                        this.makeChartReal(index);

                    });

                }
            },

            api_st_al_001_call(){
                const axioscall = axios.create({
                    baseURL: this.axiosBaseUrl,
                    headers: {
                        'accept': this.axiosJsonType,
                        'Content-Type': this.axiosJsonType,
                        'X-API-Key': this.axiosApiKey
                    },
                });

                let param_al_001 = {
                    start_date: this.picStartDate,
                    end_date: this.picEndDate
                }

                // if (this.selectedModel != "차종"){
                //     param_al_001 = {
                //         start_date: this.picStartDate,
                //         end_date: this.picEndDate,
                //         model: this.selectedModel
                //     }
                // }

                axioscall.post('/SF/st_al_001', param_al_001).then(r => {
                    //console.log(JSON.stringify(r.data.result));
                    this.autoLabeling.success_ok = r.data.success_ok;
                    this.autoLabeling.success_ng = r.data.success_ng;
                    this.autoLabeling.fail_ok = r.data.fail_ok;
                    this.autoLabeling.fail_ng = r.data.fail_ng;
                    this.autoLabeling.need_manual_check = r.data.need_manual_check;
                });
            },

            makeChart(index) {

                if (this.chartInstance) {
                    this.chartInstance.destroy(); // 기존 차트 파기
                }

                // console.log("statistics-"+index);
                let canvas;
                let ctx;
                let temp_arr_chartdata_label = [];
                let temp_arr_chartdata_value = [];
                //Prediction
                canvas = this.$refs["statistics-pre-"+index][0];

                ctx = canvas.getContext('2d');

                // let temp_arr_chartdata_label = [];
                // let temp_arr_chartdata_value = [];

                this.predictionList_02_date.forEach((value) => {
                    temp_arr_chartdata_label.push(value.date);
                });
                
                this.predictionList_02_model_point.forEach((value) => {
                    
                    let temp_arr_item = ["_prediction_ok_per", "_prediction_ng_per", "_legacy_ok_per", "_legacy_ng_per"];
                    temp_arr_item.forEach((item) => {
                        temp_arr_chartdata_value.push({label:value.model_point+item,data:[]});
                    })
                });
                
                this.predictionList_02.forEach((all) => {
                    console.log(all)
                    this.predictionList_02_date.forEach((date) => {
                        this.predictionList_02_model_point.forEach((model_point) => {
                            //console.log(all.prediction_ok);
                            if (all.date === date.date && all.model_no === model_point.model_no && all.point === model_point.point){
                                temp_arr_chartdata_value.forEach((search) => {
                                    if (search["label"] === model_point.model_point+"_prediction_ok_per"){
                                        search.data.push(all.prediction_ok_per.replace("%",""));
                                    }
                                    if (search["label"] === model_point.model_point+"_prediction_ng_per"){
                                        search.data.push(all.prediction_ng_per.replace("%",""));
                                    }
                                    if (search["label"] === model_point.model_point+"_legacy_ok_per"){
                                        search.data.push(all.legacy_ok_per.replace("%",""));
                                    }
                                    if (search["label"] === model_point.model_point+"_legacy_ng_per"){
                                        search.data.push(all.legacy_ng_per.replace("%",""));
                                    }
                                })
                            }
                        })
                    })
                });
                // console.log(temp_arr_chartdata_value);
                this.chartInstance = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: temp_arr_chartdata_label,
                        datasets: temp_arr_chartdata_value
                        // datasets: [
                        //     {label:'aaa', data: [0.0, 86.0, 30, 40]},{label:'bbb', data: [10, 20, 30, 40]} 
                        // ]
                    },
                    options: {
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                beginAtZero: true,
                                max: 100,
                                min: 0
                            }
                        },
                        plugins: {
                            legend: {
                            display: true
                            }
                        }
                    }
                });
            },
            makeChartReal(index) {

                if (this.chartInstanceReal) {
                    this.chartInstanceReal.destroy(); // 기존 차트 파기
                }

                // console.log("statistics-"+index);
        
                let canvas;
                let ctx;
                let temp_arr_chartdata_label = [];
                let temp_arr_chartdata_value = [];
        
                // Real Data
                canvas = this.$refs["statistics-real-"+index][0];

                ctx = canvas.getContext('2d');  

                // let temp_arr_chartdata_label = [];
                // let temp_arr_chartdata_value = [];

                this.realDataList_02_date.forEach((value) => {
                    temp_arr_chartdata_label.push(value.date);
                });
                console.log(this.realDataList_02_model_point);
                this.realDataList_02_model_point.forEach((value) => {
                    let temp_arr_item = ["_ng_accuracy1", "_ng_accuracy2"];
                    temp_arr_item.forEach((item) => {
                        temp_arr_chartdata_value.push({label:value.model_point+item,data:[]});
                    })
                });
                console.log(this.realDataList_02);
                this.realDataList_02.forEach((all) => {
                    this.realDataList_02_date.forEach((date) => {
                        this.realDataList_02_model_point.forEach((model_point) => {
                            //console.log(all.prediction_ok);
                            if (all.date === date.date && all.model_no === model_point.model_no && all.point === model_point.point){
                                temp_arr_chartdata_value.forEach((search) => {
                                    if (search["label"] === model_point.model_point+"_ng_accuracy1"){
                                        search.data.push(all.ng_accuracy1.replace("%",""));
                                    }
                                    if (search["label"] === model_point.model_point+"_ng_accuracy2"){
                                        search.data.push(all.ng_accuracy2.replace("%",""));
                                    }
                                })
                            }
                        })
                    })
                });

                // console.log(temp_arr_chartdata_value);

                this.chartInstanceReal = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: temp_arr_chartdata_label,
                        datasets: temp_arr_chartdata_value
                        // datasets: [
                        //     {label:'aaa', data: [0.0, 86.0, 30, 40]},{label:'bbb', data: [10, 20, 30, 40]} 
                        // ]
                    },
                    options: {
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                beginAtZero: true,
                                max: 100,
                                min: 0
                            }
                        },
                        plugins: {
                            legend: {
                            display: true
                            }
                        }
                    }
                });
            },

            //-------API------
            async getMemberRole() {
                let response;
                try {
                    let url = `/member/check-role`;
                    response = await common.apiGet(url);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },
            async getProjectRole() {
                let response;
                try {
                    let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
                    response = await common.apiGet(checkProjectRoleUrl);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },
        }
    }
</script>
<style scoped lang="scss">
.list_tab{
    li{
        &>div{
            text-indent: 0;
        }
    }
}
.custom-button{
    width: 35px;
    height: 70px;
    background-image: url($baseURL+'/common/graph_right.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 65px;
    z-index: 9;
    border-radius: 10px;
    &:hover{
        background-color: $disable02Color;
    }
    &.swiper-button-disabled{
        opacity: .6;
        &:hover{
            background-color: $whiteColor;
        }
    }
    
    &.custom-prev-button{
        left: 0;
        transform: rotate(180deg);
    }
    &.custom-next-button{
        right: 0;
    }
}
.filter_box{
    height: 40px;
    box-sizing: border-box;
    
    .date_filter{
//        width: 100%;
//        height: 100%;
        padding-left: 0;
        &::after{
            display: none;
        }
        .date_period{
            width: 240px;
            left: 10px;
        }
    }
}
.vision_list_wrap{
    margin-top: 14px;
    .tab_data{
        height: auto;
        background-color: $whiteColor;
        border-radius: 10px;
        box-shadow: 0px 0px 10px #002B6833;
        box-sizing: border-box;
    }
    .data_list_box > .mando_table{
        position: relative;
        & > thead{
            tr{
              &:first-child{
                th{
                    vertical-align: middle;
                    &:nth-child(1){
                        border-right: 1px solid #E5E9EF;
                        &::after{
                            display: none;
                        }
                    }
                    &:nth-child(2){
                        border-right: 1px solid #E5E9EF;
                        &::after{
                            display: none;
                        }
                    }
                    &:nth-child(3){
                        border-right: 1px solid #E5E9EF;
                        &::after{
                            display: none;
                        }
                    }
                    &:nth-child(4){
                        border-right: 1px solid #E5E9EF;
                        &::after{
                            display: none;
                        }
                    }
                    &:nth-child(5){
                        border-right: 1px solid #E5E9EF;
                        &::after{
                            display: none;
                        }
                    }
                }
              }
              &:last-child{
                th{
                    &:nth-child(2n){
                        border-right: 1px solid #E5E9EF;
                        &::after{
                            display: none;
                        }
                    }
                }
              }
            }
        }
        tbody{
            .item td{
                &:first-child{
                    position: relative;
                    text-align: center;
                    .arrow_icon{
                        position: absolute;
                        top: 4px;
                        left: 10px;
                        
                    }
                }
            }
            .item{
                & + .item_detail{
                    display: none;
                    &:hover{
                        background-color: $whiteColor;
                    }
                    
                }
                &.active + .item_detail{
                    display: table-row;
                    td{
                        // background-color: #F8F9FA;
                        background-color: $whiteColor;
                    }
                    td > div {
                        width: 1236px;
                        
                        box-sizing: border-box;
                        padding: 0 20px;
                        .chart_box{
                            width: 100%;
                            height: 200px;
                            margin-top: 20px;
                            .chart{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .chart_table{
                            margin-top: 30px;
                        }
                        .mando_table{
                            position: relative;
                            thead{
                                background-color: $whiteColor;
                                box-shadow: none;
                            }
                            tr{
                                background-color: $whiteColor;
                            }
                            th, td{
                                text-align: center;
                                vertical-align: middle;
                            }
                            td{
                                &:first-child{
                                    padding: 14px 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
    .statistics_box{
        width: 100%;
        background-color: #EDFBFF;
        tr{
            td{
                color: $mainColor;
            }
        }
        
    }
    .auto_label_data{
        width: 100%;
        height: 500px;
        padding: 30px;
        box-sizing: border-box;
        & > strong{
            font-weight: $bold;
        }
        .float_box{
            width: 100%;
            height: 360px;
            margin-top: 20px;
            & > div{
                width: 33%;
                height: 100%;
                float: left;
                box-sizing: border-box;
                padding-left: 40px;
                &:first-child{
                    padding: 0;
                }
                
            }
            .border_box{
                border-left: 1px solid $disable02Color;
                border-right: 1px solid $disable02Color;
            }
            .status_box{
                
                & > span{
                    color: #6E7380;
                }
                div{
                    font-size: $fontMenuTitle;
                    color: #6E7380;
                }
                .ok_box, .ng_box{
                    width: 100%;
                    height: 80px;
                    box-sizing: border-box;
                    padding-left: 100px;
                    background-repeat: no-repeat;
                    background-position: center left;
                }
                .ok_box{
                    margin-top: 40px;
                    
                    &.fail_item{
                        background-image: url($baseURL+'/common/fail_ok.svg');
                    }
                    &.success_item{
                        background-image: url($baseURL+'/common/sucess_ok.svg');
                    }
                    .count_num{
                        color: $okColor;
                    }
                    
                }
                .ng_box{
                    margin-top: 60px;
                    
                    &.fail_item{
                        background-image: url($baseURL+'/common/fail_ng.svg');
                    }
                    &.success_item{
                        background-image: url($baseURL+'/common/sucess_ng.svg');
                    }
                    .count_num{
                        color: $failedColor;
                    }
                }
                .count_num{
                    height: 50px;
                    line-height: 50px;
                    font-size: 40px;
                    font-weight: 500;
                    font-family: $montserrat;
                    margin-top: 10px;
                }

            }
            .manual_count{
                
                .text_box{
                    margin-top: 120px;
                    strong{
                        font-weight: $bold;
                    }
                    .count_num{
                        height: 80px;
                        margin-top: 20px;
                        div{
                            display: inline-block;
                            font-weight: 500;
                            font-size: 65px;
                            line-height: 80px;
                            font-family: $montserrat;
                        }
                        span{
                            display: inline-block;
                            font-size: 32px;
                            font-family: $montserrat;
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
    }

}
</style>