<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>데이터/ TASK 운영</span>
                    <span class="active">Vehicle Diagnostic Service 대시보드</span>
                </div>
                <h2>Vehicle Diagnostic Service 대시보드</h2>
            </div> -->
            <BreadcrumbLayout pageId="monTASK_vehicleDashboard" />
            <div id="monCont" class="vehicle_dashboard">
                <div class="filter_box">
                    <select name="a" style="width: 185px; background-position: 155px center;" v-model="fleetSelect">
                        <option value="0" selected>Fleet 선택</option>
                        <option v-for="fleet in fleetOption" :key="fleet.id" :value="fleet.id">
                            {{ fleet.value }}
                        </option>
                    </select>
                    <select name="a" style="width: 185px; background-position: 155px center;" v-model="oemSelect">
                        <option value="0">OEM 선택</option>
                        <option v-for="oem in oemOption" :key="oem.id" :value="oem.id">
                            {{ oem.value }}
                        </option>
                    </select>
                    <select name="a" style="width: 185px; background-position: 155px center;" v-model="vehicleSelect">
                        <option value="0">Vehicle 선택</option>
                        <option v-for="vehicle in vehicleOption" :key="vehicle.id" :value="vehicle.id">
                            {{ vehicle.value }}
                        </option>
                    </select>
                    <select name="a" style="width: 185px; background-position: 155px center;" v-model="edgeSelect">
                        <option value="0">Edge ID 선택</option>
                        <option v-for="edge in edgeOption" :key="edge.id" :value="edge.id">
                            {{ edge.value }}
                        </option>
                    </select>
                </div>
                <div class="float_box value_box">
                    <div class="fleet_status_box radiusbox">
                        <strong>Fleet Status</strong>
                        <strong class=read_time>Read Time <br> {{statusReadTime}}</strong>
                        <div class="float_box">
                            <div>
                                <div>
                                    <span>등록대수</span>
                                    <div class="count_num">{{ regiCount }}</div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span>운영</span>
                                    <div class="count_num">{{ operCount }}</div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span>원격 진단</span>
                                    <div class="count_num">{{ remoteCount }}</div>
                                </div>
                            </div>
                            <div>
                                <div class="dislb">
                                    <span>정비 필요</span>
                                    <div class="count_num">{{ repaCount }}</div>
                                </div>
                                <div class="dislb">
                                    <span>이상 진단</span>
                                    <div class="count_num">{{ anomCount }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- chart -->
                    <div class="statistics_box radiusbox">
                        <strong>Statistics</strong>
                        <div class="chart_box">
                            <canvas ref="statistics"></canvas>
                        </div>
                    </div>
                    
                </div>
                <div class="radiusbox table_wrap">
                    <div class="title_box">
                        <strong>Vehicle List</strong>
                        <div class="filter_box search_from">
                            <input type="text" name="" id="" placeholder="검색어를 입력하세요." v-model="searchKey" @keydown.enter="searchGo();">
                            <button type="button" class="btn_search" @click="searchGo();"></button>
                        </div>
                    </div>
                    <div class="mando_table_wrap vehicle_table">
                        <table class="mando_table">
                            <colgroup>
                                <col style="width: 11%;">
                                <col style="width: 11%;">
                                <col style="width: 14%;">
                                <col style="width: 14%;">
                                <col style="width: 14%;">
                                <col style="width: 18%;">
                                <col style="width: 18%;">
                            </colgroup>
                            <thead>
                                <th v-for="(item, index) in columnDefs" :key="index">
                                    <div class="col_name">
                                        {{item.field}}
                                        <button class="btn_filter" @click="showFilter"></button>
                                    </div>
                                </th>
                                <!-- <tr>
                                    <th>Fleet</th>
                                    <th>OEM</th>
                                    <th>Vehicle</th>
                                    <th>Edge ID</th>
                                    <th>Status</th>
                                    <th>원격진단</th>
                                    <th>모터이상진단</th>
                                </tr> -->
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in rowData" :key="index">
                                    <td>{{ item.Data1 }}</td>
                                    <td>{{ item.Data2 }}</td>
                                    <td>{{ item.Data3 }}</td>
                                    <td>{{ item.Data4 }}</td>
                                    <td>{{ item.Data5 }}</td>
                                    <td class="potal_row_item">
                                        <button type="button" v-if="this.dtcEdgeIds.some(e => e.id === item.Data4)">
                                            TASK보기 <span @click="blockListModal('remote', item.Data1, item.Data2, item.Data3, item.Data4, item.Data6, 1)"></span>
                                        </button>
                                        <div v-else>
                                            [이상 없음]
                                        </div>
                                    </td>
                                    <td class="potal_row_item">
                                        <button type="button" v-if="this.motorEdgeIds.some(e => e.id === item.Data4)">
                                            TASK보기 <span @click="blockListModal('motor', item.Data1, item.Data2, item.Data3, item.Data4, item.Data6, 2)"></span>
                                        </button>
                                        <div v-else>
                                            [이상 없음]
                                        </div>
                                    </td>
                                </tr>
                                <!-- <tr>
                                    <td>Group01</td>
                                    <td>HKMC</td>
                                    <td>JK1</td>
                                    <td>ID123456</td>
                                    <td>Active</td>
                                    <td class="potal_row_item">
                                        <button type="button">
                                            Task01, 5개 <span @click="blockListModal('remote')"></span>
                                        </button>
                                    </td>
                                    <td class="potal_row_item">
                                        <button type="button">
                                            Task01, 5개 <span @click="blockListModal('motor')"></span>
                                        </button>
                                    </td>
                                </tr> -->

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="modalWrap" v-if="listModal">
        <div class="radiusbox modal_contents list_potal_modal">
            <strong class="modal_title">
                {{ modalTitle }}
                <!-- 원격진단 - DTC 검출 프로젝트 (5개) -->
                <!-- 이상진단 - V.PHM 모니터링 TASK (5개) -->
            </strong>
            <div class="group_breadcrumb">
                <span>{{ modalFleet }}</span>
                <span>{{ modalOem }}</span>
                <span>{{ modalVehicle }}</span>
                <span class="active">{{ modalEdgeId }}</span>
            </div>
            <div class="mando_table_wrap">
                <table class="mando_table">
                    <colgroup>
                        <col style="width: 40%;">
                        <col style="width: 30%;">
                        <col style="width: 15%;">
                        <col style="width: 15%;">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Project Name</th>
                            <th>Task Name</th>
                            <th>원격진단 건 수</th>
                            <th>아이템 수</th>
                        </tr>
                        <!-- 
                            <tr>
                                <th>Project Name</th>
                                <th>Task Name</th>
                                <th>Team</th>
                                <th>원격진단 건 수</th>
                                <th>아이템 수</th>
                            </tr>
                         -->
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in modalData" :key="index" @click="modalListClick(index, item.projectId, item.edgeDeviceId, item.taskId)" :class="{ 'active' : modalSelectedItem === index }" style="cursor: pointer;">
                            <td style="text-align: left;">{{ item.projectName }}</td>
                            <td style="text-align: left;">{{ item.taskName }}</td>
                            <td>4</td>
                            <td>5</td>
                        </tr>
                        <!-- 
                            <tr class="active">
                                <td>JK1 Steering 이상 진단</td>
                                <td>Taskname01</td>
                                <td>만도 프로젝트 1팀</td>
                                <td>4</td>
                                <td>5</td>
                            </tr>
                         -->
                    </tbody>
                </table>
            </div>
            <div class="check_wrap">
                <button type="button" class="btn_check mcbtn" @click="moveLink">이동</button>    
            </div>
            <button type="button" class="btn_close" @click="noneListModal"></button>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';

    import common from '@/assets/js/common';
    import apiCallVphm01 from '@/assets/js/apiCallVphm01';
    import apiCallVehicle from '@/assets/js/apiCallVehicle';

    import Chart from 'chart.js/auto';


    export default {
        name: 'VehicleDashboardView',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            BreadcrumbLayout,
        },
        data() {
            const columnDefs = ([]);
            const rowData = ([]);
            const modalData = ([]);
            const edgeIds = ([]);

            return{
                columnDefs,
                rowData,
                modalData,
                edgeIds,

                fleetSelect: '0',
                oemSelect: '0',
                vehicleSelect: '0',
                edgeSelect: '0',

                fleetSelectWatch: false,

                fleetId: 0,
                oemId: 0,
                vehicleId: 0,
                edgeId: 0,

                fleetOption: [],
                oemOption: [],
                vehicleOption: [],
                edgeOption: [],

                listModal: false,
                selectListModal: 'romote',

                regiCount: "-", // 등록대수
                operCount: "-", // 운영
                remoteCount: "-", // 원격진단
                repaCount: "-", // 정비필요
                anomCount: "-", // 이상진단
                dtcEdgeIds: [],
                motorEdgeIds: [],

                modalSelectedItem: null,
                modalSelectedQuery: {},

                chartInstance: null,

                searchKey: null,

                // role
                viewRole: false,
                manageRole: false,

                // read time 
                statusReadTime: '',
            };
        },
        async mounted() {
            // Role
            this.setRole();

            // List column
            this.columnDefs = [
                { field: "Fleet" },
                { field: "OEM" },
                { field: "Vehicle" },
                { field: "Edge ID" },
                { field: "Status" },
                { field: "원격진단" },
                { field: "모터이상진단" }
            ]

            // Fleet 선택(최초 진입시 첫번째 fleet 선택)
            await this.getFleetSelect();
            this.fleetSelect = this.fleetOption[0].id;
            this.fleetId = this.fleetOption[0].id;
            
            await this.makeVehicleList();

            this.fleetSelectWatch = true; // fleetSelect watch this.makeVehicleList() 활성화
        },
        watch: {
            fleetSelect() {
                if (this.fleetSelect == "0") this.fleetId = "";
                else this.fleetId = this.fleetSelect;
                if (this.fleetSelectWatch) this.makeVehicleList();
            },
            oemSelect() {
                if (this.oemSelect == "0") this.oemId = "";
                else this.oemId = this.oemSelect;
                this.getVehicleSelect(this.oemSelect);
                this.makeVehicleList();
            },
            vehicleSelect() {
                if (this.vehicleSelect == "0") this.vehicleId = "";
                else this.vehicleId = this.vehicleSelect;
                this.makeVehicleList();
            },
            edgeSelect() {
                if (this.edgeSelect == "0") this.edgeId = "";
                else this.edgeId = this.edgeSelect;
                this.makeVehicleList();
            }
        },
        methods: {
            async setRole() {
                this.manageRole = await common.getUserRole('type1');
                this.viewRole = await common.getUserRole('type1');

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            async getFleetSelect() {
                const response = await common.apiGet("/fleet-group/list");
                const o = [];
                response.data.data.forEach(e => {
                    o.push({id: e.fleetId, value: e.fleetName})
                });
                this.fleetOption = o;
            },
            async getOemSelect() {
                const response = await common.apiGet("/edge-device-group/domain/oem/vehicle/1");
                const o = [];
                response.data.data.forEach(e => {
                    o.push({id: e.id, value: e.name})
                });
                this.oemOption = o;
            },
            async getVehicleSelect(id) {
                const o = [];
                if (id != 0) {
                    const response = await common.apiGet("/edge-device-group/domain/oem/vehicle/" + id);
                    response.data.data.forEach(e => {
                        o.push({id: e.id, value: e.name})
                    });
                }
                this.vehicleOption = o;
            },
            async makeVehicleList() {
                // 페이지가 로딩될때, 셀렉트박스 선택이 바뀔때, 검색시 실행 됨

                // init fleet status
                this.regiCount = "-";
                this.operCount = "-";
                this.remoteCount = "-";
                this.repaCount = "-";
                this.anomCount = "-";
                this.dtcEdgeIds = [];
                this.motorEdgeIds = [];
                
                // request body jaon
                const body = {};
                if (this.fleetId) body["fleet-id"] = this.fleetId;
                if (this.oemId) body["oem-id"] = this.oemId;
                if (this.VehicleId) body["vehicle-id"] = this.VehicleId;
                if (this.edgeId) body["edge-device-id"] = this.edgeId;
                if (this.searchKey) body["search"] = this.searchKey;

                console.log('body ==> ');
                console.log(body);
                await common.apiGet("/vehicle-diagnostic-service/vehicle-list", body).then(r => {
                    console.log('vehicle list');
                    console.log(r.data);
                    const dataList = [];
                    const o = [];
                    this.edgeIds = [];
                    r.data.data.forEach(e => {
                        dataList.push({
                            Data1: e.fleetName, 
                            Data2: e.oem, 
                            Data3: e.vehicle, 
                            Data4: e.edgeId, 
                            Data5: e.status, 
                            Data6: e.edgeDeviceId
                        });
                        if (!o.some(item => item.id === e.edgeDeviceId)) {
                            o.push({id: e.edgeDeviceId, value: e.edgeId});
                            this.edgeIds.push(e.edgeId);
                        }
                        
                    });
                    this.rowData = dataList;
                    this.regiCount = dataList.length;
                    this.edgeOption = o;
                });

                // 만도 API 연동
                // 정비필요, 이상진단
                if (this.edgeIds.length) {
                    await this.apiCallVphm01("/inf_status/status", {"edgeids": this.edgeIds}).then(data => {
                        this.repaCount = data.data.problem
                        this.anomCount = data.data.issue
                        console.log("정비필요 / 이상진단");
                        console.log(data.data);
                    });
                
                    // chart
                    await this.makeChart();
                
                    // 운영(operCount)
                    // 두개의 api response 결과 edgeid 갯수의 합 , vehicle list의 모터이상진단
                    await this.apiCallVphm01("/inf_status/abnormal", {"edgeids": this.edgeIds}).then(data => {
                        console.log("운영1");
                        console.log(data.data);

                        // if (data.data.result == "success") this.operCount = data.data.edges.length;
                        if (data.data.result == "success") {
                            data.data.edges.forEach(e => {
                                if (!this.motorEdgeIds.some(item => item.id === e.edgeid)) {
                                    this.motorEdgeIds.push({id: e.edgeid});
                                }
                            });
                        }
                        this.operCount = this.motorEdgeIds.length;
                    });
                    // edge device 별 dtc 발생 개수 , vehicle list의 원격진단
                    await this.apiCallVehicle("/dtc/abnormals/count", {"dvc_id": this.edgeIds}).then(data => {
                        console.log("운영2");
                        console.log(data.data);

                        if (data.data.result == "success") {
                            data.data.edges.forEach(e => {
                                if (!this.dtcEdgeIds.some(item => item.id === e.edgeid)) {
                                    this.dtcEdgeIds.push({id: e.edgeid});
                                }
                            });
                        }
                        // console.log('dtcEdgeIds');
                        // console.log(this.dtcEdgeIds);
                        this.operCount = this.operCount + this.dtcEdgeIds.length;
                    });

                    // 원격진단(DTC 발생 차량 수)
                    await this.apiCallVehicle("/dtc/abnormals", {"dvc_id": this.edgeIds}).then(data => {
                        if (data.data.result == "success") this.remoteCount = data.data.abnormal;
                        console.log("원격진단");
                        console.log(data.data);
                    });
                    // Read 시간 표시                 


                    const currentDate = new Date();

                    const year = currentDate.getFullYear();
                    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
                    const day = String(currentDate.getDate()).padStart(2, '0');
                    const hours = String(currentDate.getHours()).padStart(2, '0');
                    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
                    const seconds = String(currentDate.getSeconds()).padStart(2, '0');

                    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                    this.statusReadTime = formattedDateTime;

                }


                // OEM 선택
                await this.getOemSelect();

            },

            // modal open
            async blockListModal(type, fleet, oem, vehicle, edgeId, edgeDeviceId, taskId) {
                this.selectListModal = type;
                this.modalFleet = fleet;
                this.modalOem = oem;
                this.modalVehicle = vehicle;
                this.modalEdgeId = edgeId;
                this.taskId = taskId;
                console.log(type, fleet, oem, vehicle, edgeId, edgeDeviceId, taskId);

                // const axios = require('axios');
                // const cookies = require('js-cookie');
                // const jwt = cookies.get('jwt');
                // const headers = {
                //     'Content-Type': 'application/json',
                //     'Authorization': `Bearer ${jwt}`,
                // };
                await common.apiGet("/edge-device/monitoring-task/" + edgeDeviceId + "/" + taskId).then(r => {
                    const dataList = [];
                    r.data.data.forEach(e => {
                        dataList.push({
                            edgeDeviceId: edgeDeviceId,
                            projectId: e.projectId, 
                            projectName: e.projectName,
                            taskId: e.taskId,
                            taskName: e.taskName
                        });
                    });
                    this.modalData = dataList;
                    console.log('dataList');
                    console.log(dataList);

                    if (this.selectListModal == "remote") {
                        this.selectListModal = type;
                        this.modalTitle = "원격진단 - DTC 검출 프로젝트 (" + dataList.length + "개)";
                    } else if (this.selectListModal == "motor") {
                        this.modalTitle = "이상진단 - V.PHM 모니터링 TASK (" + dataList.length + "개)"
                    }
                });

                // await common.apiGet("/project/edge-device/" + edgeDeviceId).then(r => {
                //     const dataList = [];
                //     r.data.data.forEach(e => {
                //         dataList.push({
                //             projectId: e.project_id, 
                //             projectName: e.project_name,
                //             teamID: e.team_id,
                //             teamName: e.team_name,
                //             edgeDeviceId: edgeDeviceId
                //         });
                //     });
                //     this.modalData = dataList;
                //     console.log('dataList');
                //     console.log(dataList);

                //     if (this.selectListModal == "remote") {
                //         this.selectListModal = type;
                //         this.modalTitle = "원격진단 - DTC 검출 프로젝트 (" + dataList.length + "개)";
                //     } else if (this.selectListModal == "motor") {
                //         this.modalTitle = "이상진단 - V.PHM 모니터링 TASK (" + dataList.length + "개)"
                //     }
                // });

                this.listModal = true;
                
            },

            // modal close
            noneListModal() {
                this.modalTitle = "";
                this.listModal = false;
            },

            // modal link
            moveLink() {
                if (this.selectListModal == "remote") {
                    this.$router.push({ name: 'dctDashboard', query: this.modalSelectedQuery });
                } else if (this.selectListModal == "motor") {
                    this.$router.push({ name: 'moaDetection', query: this.modalSelectedQuery });
                }
                
            },

            // modal list click
            modalListClick(index, prjId, edgeId, taskId) {
                this.modalSelectedItem = this.modalSelectedItem === index ? null : index;
                this.modalSelectedQuery = { "prjId": prjId, "edgeId": edgeId, "taskId": taskId };
                console.log(this.modalSelectedQuery);
            },
            /**************
             * modal end
             **************/

            /**************
             * 만도 API call
             **************/
            async apiCallVphm01(path, body) {
                return await apiCallVphm01.post(path, body);
            },
            async apiCallVehicle(path, body) {
                return await apiCallVehicle.post(path, body);
            },

            /**************
             * chart
             **************/
            async makeChart() {

                const vm = this;
                const canvas = vm.$refs.statistics;
                const ctx = canvas.getContext('2d');

                if (this.chartInstance) {
                    this.chartInstance.destroy(); // 기존 차트 파기
                }

                this.chartInstance = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: ['정비필요', '이상진단'],
                        datasets: [{
                            data: [this.repaCount, this.anomCount],
                            backgroundColor: ['rgba(234, 190, 110, 1)', 'rgba(239, 155, 57, 1)'],
                            borderWidth: 1,
                            barThickness: 30
                        }]
                    },
                    options: {
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        },
                        plugins: {
                            legend: {
                            display: false
                            }
                        }
                    }
                });
            },

            // 랜덤 숫자
            getRandom(min = 1, max = 20) {
                return Math.round(Math.random() * (max - min) + min);
            },

            // search
            async searchGo() {
                this.makeVehicleList();
            }
        }
    }
</script>
<style scoped lang="scss">
    .vehicle_dashboard{
        .value_box{
            height: 308px;
            margin: 15px 0;
            &>div{
                width: 630px;
                height: 100%;
                float: left;
                box-sizing: border-box;
                padding: 20px;
                &:first-child{
                    margin-right: 16px;
                }
                strong{
                    width: 100%;
                    display: block;
                    font-weight: $bold;
                    box-sizing: border-box;
                }
            }
        }
        .fleet_status_box{
            position: relative;
            .read_time{
                width: 170px;
                display: block;
                position: absolute;
                top: 15px;
                right: 0;
                font-weight: $regular;
                font-size: 15px;
            }
            .float_box{
                margin-top: 20px;
                &>div{
                    width: 50%;
                    height: 100px;
                    float: left;
                    box-sizing: border-box;
                    padding-left: 118px;
                    background-repeat: no-repeat;
                    background-position: 20px center;
                    &:nth-child(1){
                        background-image: url($baseURL+'/common/car_add.svg');
                        border-bottom: 1px solid $line02Color;
                        border-right: 1px solid $line02Color;
                        background-position: 0 center;
                        padding-left: 98px;
                        .count_num{
                            color: #52B69C;
                        }
                    }
                    &:nth-child(2){
                        background-image: url($baseURL+'/common/status_manage.svg');
                        .count_num{
                            color: #A701FF;
                        }
                    }
                    &:nth-child(3){
                        background-image: url($baseURL+'/common/status_diagnosis.svg');
                        background-position: 0 center;
                        padding-left: 98px;
                        .count_num{
                            color: #EA726F;
                        }
                    }
                    &:nth-child(4){
                        background-image: url($baseURL+'/common/status_warning.svg');
                        border-top: 1px solid $line02Color;
                        border-left: 1px solid $line02Color;
                       
                    }
                    &>div{
                        margin-top: 20px;
                    }
                    span{
                        display: block;
                        color:#8A8F9C;
                    }
                    .count_num{
                        height: 30px;
                        font-family: $montserrat;
                        font-weight: 500;
                        font-size: 30px;
                        line-height: 30px;
                        margin-top: 7px;
                        
                    }
                    .dislb{
                        display: inline-block;
                        &:first-child{
                            padding-right: 20px;
                            span, .count_num{
                                color: #EABE6E;    
                            } 

                        }
                        &:last-child{
                            padding-left: 20px;
                            border-left: 1px solid $line02Color;
                            span, .count_num{
                                color: #EF9B39;    
                            } 
                        }
                    }
                }
            }
        }
        .statistics_box{
            .chart_box{
                width: 100%;
                height: 228px;
                background-color: rgba(255, 255, 255, 0);
                margin-top: 20px;
            }

        }
        .table_wrap{
            width: 100%;
            height: 470px;
            padding: 20px;
            box-sizing: border-box;
            .title_box{
                height: 45px;
                position: relative;    
                margin-bottom: 20px;
                strong{
                    font-weight: $bold;
                }
                .search_from{
                    position: absolute !important;
                    top: -8px;
                    right: 0;
                    padding-right: 40px !important;
                    .btn_search{
                        width: 40px;
                    }
                }
            }
            
            .mando_table{
                position: relative;
                .potal_row_item{
                    span{
                        position: relative;
                        top: 3px;
                    }
                }
            }
        }
        .vehicle_table{
            height: 370px;
        }
    }
</style>