<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>장치/배포 관리</span>
                    <span>Fleet 그룹 목록</span>
                    <span class="active">Fleet 그룹 목록 - 상세</span>
                </div>
                <h2>Fleet 그룹 목록 - 상세</h2>
            </div> -->
            <BreadcrumbLayout pageId="deviceNDelivery_detailFleetGroup"/>
            <div class="item_info">
                <div class="item_title">
                    <strong>{{fleetGroupData.fleetName}}</strong>
                    <div class="item_info_btn_box">
                        <button type="button" class="btn_fold" v-bind:style="infoToggle ? 'display:none': ''" @click="clickToggle()">접기</button>
                        <button type="button" class="btn_fold active" v-bind:style="!infoToggle ? 'display:none': ''" @click="clickToggle()">펼치기</button>
                        <button type="button" class="btn_evt_group" @click="hideUtils"></button>
                        <ul class="evt_btn_box radiusbox" :style="{ display: InferenceUtil ? 'block' : 'none' }">
                            <li class="download_item">
                                <button type="button" @click="makePDF">데이터 다운로드</button>
                            </li>
                            <li class="share_item">
                                <button type="button" @click="clipCoppy">공유하기</button>
                            </li>
                            <!-- <li class="retouch_item" v-if="manageRole">
                                <button type="button" @click="goModPage">수정</button>
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div class="item_table radiusbox" v-bind:style="infoToggle ? 'display:none': ''">
                    <table>
                        <colgroup>
                            <col style="width: 20%;">
                            <col style="width: 15%;">
                            <col style="width: 15%;">
                            <col style="width: 15%;">
                            <col style="width: 20%;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Edge Device 수</th>
                                <th>Status</th>
                                <th>연결 PRJ/TASK 수</th>
                                <th>등록자명</th>
                                <th>소속</th>
                                <th>등록일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{fleetGroupData.fleetEdgeDeviceCount}}</td>
                                <td>{{fleetGroupData.status}}</td>
                                <td>{{fleetGroupData.connectedProjectCount}}건 / {{fleetGroupData.connectedTaskCount}}건</td>
                                <td>{{fleetGroupData.registrantName}}</td>
                                <td>{{fleetGroupData.belonging}}</td>
                                <td>{{fleetGroupData.created}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="item_desc">
                        <div class="desc_title">Description</div>
                        <div class="desc_txt">{{fleetGroupData.description}}</div>
                    </div>
                </div>


                <!-- 하단 영역 시작 -->
                <div class="fleet_data float_box">
                    <div class="map_box">
                        <naver-map id="map" style="display: block; width: 100%; height: 100%" :mapOptions="mapOptions" :initLayers="initLayers"  >
                            
                        </naver-map>
                    </div>
                    <div class="device_list">
                        <strong>Device 목록</strong>
                        <div class="total_mem"><span>{{edgeDeviceList.length}} </span> Devices</div>
                        <div class="filter_box search_from">
                            <input type="text" name="dataKeyword" ref="dataKeyword" placeholder="검색어를 입력하세요." @keydown.enter="clickSearch();">
                            <button type="button" class="btn_search" @click="clickSearch();"></button>
                        </div>
                        <div class="mando_table_wrap">
                            <table class="mando_table">
                                <thead>
                                    <tr>
                                        <th>Edge ID</th>
                                        <th>Edge Group</th>
                                        <th>Edge Name</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in edgeDeviceList" :key="index">
                                        <td>
                                            {{item.edge_device_id}}
                                        </td>
                                        <td>
                                            {{item.edge_device_group}}
                                        </td>
                                        <td>
                                            {{item.edge_device_name}}
                                        </td>
                                        <td>
                                            {{item.status}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    

                </div>
                
                <div>
                    
                </div>
                <!-- 하단 영역 끝 -->



            </div>
        </div>


    </div>
    <FooterLayout/>
</template>
<script setup>
    
    const mapOptions = {
        latitude: "37.404355", // 지도 중앙 위도
        longitude: "127.110633", // 지도 중앙 경도
        zoom: 11,
        zoomControlOptions: { position: "TOP_RIGHT" },
    };

    const initLayers = [
        "BACKGROUND",
        "BACKGROUND_DETAIL",
        "POI_KOREAN",
        "TRANSIT",
        "ENGLISH",
    ];

    
</script>

<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';

    import common from '@/assets/js/common';
    import { NaverMap } from "vue3-naver-maps";
    
    export default {
        name: 'fleetGroupList',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            NaverMap,
            BreadcrumbLayout,
        },
        computed:{
        },
        data(){
            return{
                fleetGroupId: "",

                fleetGroupData: {
                    fleetName: "",
                    fleetEdgeDeviceCount: "",
                    status: "",
                    connectedProjectCount: "",
                    connectedTaskCount: "",
                    registrantName: "",
                    belonging: "",
                    created: "",
                    description: "",
                },

                edgeDeviceList: [],

                infoToggle: false,

                InferenceUtil: false,

                // role
                viewRole: false,
                manageRole: false,
            }
        },
        mounted() {
            // Role
            this.setRole();

            this.fleetGroupId = this.$route.params.fleetGroupId;

            this.setFleetGroupData();
            this.setEdgeDeviceList();
        },
        methods:{
            async setRole() {
                this.manageRole = await common.getUserRole('type2');
                this.viewRole = await common.getUserRole('type1');

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            async setFleetGroupData() {
                const fleetGroupId = this.fleetGroupId

                const fleetGroupData = await this.getFleetGroupData(fleetGroupId);
                if(typeof fleetGroupData !== "undefined" && fleetGroupData !== null && fleetGroupData !== "") {
                    this.fleetGroupData = fleetGroupData;
                }
            },
            
            async setEdgeDeviceList() {
                const fleetGroupId = this.fleetGroupId

                const edgeDeviceList = await this.getEdgeDeviceList(fleetGroupId);
                if(typeof edgeDeviceList !== "undefined" && edgeDeviceList !== null && edgeDeviceList !== "") {
                    this.edgeDeviceList = edgeDeviceList;
                }
            },


            setSearchDevice(keyword) {
                const searchRow = [];
                this.edgeDeviceList.forEach(item => {
                    let edgeDeviceId = common.getUpper(item['edge_device_id']);
                    let edgeDeviceGroup = common.getUpper(item['edge_device_group']);
                    let edgeDeviceName = common.getUpper(item['edge_device_name']);
                    let status = common.getUpper(item['status']);

                    if ( edgeDeviceId.includes(keyword) || edgeDeviceGroup.includes(keyword) 
                            || edgeDeviceName.includes(keyword) || status.includes(keyword) ) {
                        searchRow.push(item);
                    }
                });

                this.edgeDeviceList = [];

                searchRow.forEach(item => {
                    this.edgeDeviceList.push(item);
                });
            },

            // ----- click 
            async clickSearch() {
                const keyword = this.$refs.dataKeyword.value.toUpperCase();
                if(typeof keyword !== "undefined" && keyword !== null && keyword !== "") {
                    this.setSearchDevice(keyword);
                } else {
                    this.setEdgeDeviceList();
                }
            },

            goModPage() {
                this.$router.push({ 
                    name: 'modFleetGroup',
                    params: { fleetGroupId: this.fleetGroupId}
                });
            },

            clickToggle() {
                const toggle = !this.infoToggle;
                this.infoToggle = toggle;
            },

            hideUtils() { // 부가기능 - 보이기 숨기기
                if(this.InferenceUtil == true) {
                    this.InferenceUtil = false;
                } else if(this.InferenceUtil == false){
                    this.InferenceUtil = true;
                }
            },
            async clipCoppy() { // 공유하기
                let dummy = document.createElement("textarea");
                document.body.appendChild(dummy);
                try {
                    const url = window.document.location.href;
                    dummy.value = url;
                    dummy.select();
                    document.execCommand("copy");
                    document.body.removeChild(dummy);
                    alert('url copy success')
                } catch(error) {
                    console.log(error);
                } 
            },

            // ----- API
            async getFleetGroupData(fleetGroupId) {
                try {
                    const response = await common.apiGet('/fleet-group/' + fleetGroupId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getEdgeDeviceList(fleetGroupId) {
            // async getEdgeDeviceList() {
                try {
                    const response = await common.apiGet('/edge-device/fleet-group/' + fleetGroupId);
                    // const response = await common.apiGet('/edge-device/fleet-group?fleet-group-id=4&edge-device-group-id=3');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

        }
    }
</script>
<style scoped lang="scss">
    .fleet_data{
        margin-top: 20px;
        & > div{
            width: 630px;
            height: 440px;
            overflow: hidden;
            border-radius: 10px;
            background-color: #fff;
            box-shadow: 0px 0px 10px #002B6833;
        }
        .map_box{
            float: left;
        }
        .device_list{
            float: right;
            position: relative;
            strong{
                font-size: 15px;
                font-weight: $bold;
                display: block;
                margin: 20px 0 0 20px;
            }
            .total_mem{
                font-size: $fontSub;
                display: inline-block;
                position: absolute;
                top: 20px;
                right: 260px;
                color: #8A8F9C;
                span{
                    color: $mainColor;
                    font-weight: $bold;
                    font-size: $fontSub;
                }
            }
            .filter_box{
                width: 180px;
                height: 40px;
                position: absolute;
                top: 10px;
                right: 10px;
                padding-right: 40px;
                padding-left: 15px;
                .btn_search{
                    width: 40px;
                }
                &::after{
                    width: 1px;
                    height: 20px;
                    background-color: #E5E9EF;
                    content: "";
                    display: block;
                    position: absolute;
                    top: 10px;
                    left: 0;
                }
            }
            .mando_table_wrap{
                margin-top: 25px;
                height: 378px;
            }
        }
    }
</style>