<!-- DivComponent.vue -->
<template>
    <div class="none_item">
        <div class="content_box">
            {{listDataType}} 항목이 없습니다.
            <!-- 데이터 목록 -->
            <!-- <a href="#" @click="goToProjectOps" class="add_item" v-if="roleMoreThenTeamManager" v-show="listDataType === '데이터 목록'" > 신규 {{listDataType}} 생성 </a> -->
            <a href="#" @click="goToProjectOps" class="add_item setting_btn" v-if="prjRole" v-show="listDataType === '데이터 목록'" > 프로젝트 관리 </a>
            <!-- 분석 TASK -->
            <a href="#" @click="addMonitoringTask" class="add_item" v-if="monTaskRole" v-show="listDataType === '분석 TASK'"> 신규 {{listDataType}} 생성 </a>
            <!-- MLOps TASK -->
            <a href="#" @click="goToProjectOps" class="add_item setting_btn" v-if="prjRole" v-show="listDataType === 'MLOps TASK'" > 프로젝트 관리 </a>
        </div>
    </div>
</template>

<script>
// import Cookies from 'js-cookie';
// import axios from 'axios';
import common from '@/assets/js/common';
    export default{
        name: 'dataListNoneItemBox',
        data(){
            return{
                listDataType: '',
                roleMoreThenTeamManager: false,
                projectId: 0,

                // role
                prjRole: false,
                monTaskRole: false,
            }
        },
        props:{
            dateInterface: String,
            prj : Number
        },
        async mounted() {
            
            this.listDataType = this.dateInterface;
            // await this.checkRole();
            this.projectId = this.prj;
            
            console.log('this.projectId');
            console.log(this.projectId);
            // Role
            // await this.setRole();
        },
        watch: {
            prj() {
                this.setRole();
            }
        },
        methods: {
            async getData(url) {
                // const jwt = Cookies.get('jwt');
                try {
                    // const headers = {
                    //     'Authorization': `Bearer ${jwt}`
                    // }
                    // const response = await axios.get(`https://api.dlfe.dev.micosa.io/${url}`, {headers:headers});
                    const response = await common.apiGet(`/${url}`);
                    return response.data.data;
                } catch (error) {
                    console.error("error", error);
                }
            },

            async setRole() {
                const prjId = this.prj;
                
                this.prjRole = ( await common.getUserRole('type2') || await common.getPrjRole('type3', prjId) );
                this.monTaskRole = ( await common.getUserRole('type2') || await common.getPrjRole('type2', prjId) );
            },

            async checkRole() {
                try {
                    const response = await this.getData('member/check-role');
                    const role = response;

                    console.log(role);

                    if(role.role == 'SM') {
                        this.roleMoreThenTeamManager = true;
                    } else if(role.memberRole == 'Division Manager' || role.memberRole == 'Team Manager' || role.memberRole == 'Division Observer') {
                        this.roleMoreThenTeamManager = true;
                    } else {
                        this.roleMoreThenTeamManager = false;
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            goToProjectOps(event) {
                event.preventDefault();
                console.log("######## go to projectOps");
                try {
                    this.$router.push({
                        name: 'modProject', params: { prjId : this.projectId }
                    });
                } catch(error) {
                    console.log('Error:', error);
                }
            },

            addMonitoringTask(event) {
                event.preventDefault();
                console.log("go to monitoring task");
                try {
                    const query = {
                        prjId: this.prj
                    }
                    this.$router.push({
                        name: 'AddTask', query
                    });
                } catch(error) {
                    console.log(error);
                }
            }
        }
        
    }
</script>
<style lang="scss">
    .none_item{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-top: 70px;
        .content_box{
            width: 250px;
            height: 170px;
            margin: 0 auto 0;
            box-sizing: border-box;
            text-align: center;
            color: $placeHolderColor;
            padding-top: 70px;
            background-repeat: no-repeat;
            background-position: center 0;
            background-image: url($baseURL+'/common/alert_guide_list.svg');
            .add_item{
                color: $mainColor;
                border: 1px solid $mainColor;
                padding: 10px 20px 10px 40px;
                margin-top: 30px;
                border-radius: 10px;
                box-sizing: border-box;
                display: inline-block;
                background-repeat: no-repeat;
                background-position:20px center;
                background-image: url($baseURL+'/common/add_15.svg');
                background-color: #EDFBFF;
                &.setting_btn{
                    background-image: url($baseURL+'/common/header_settings_main.svg');
                    background-size: 17px;
                    background-position: 18px center;
                }
            }
        }

    }

</style>