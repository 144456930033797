
<template>
    <div id="loginWrap">
      <div class="login_img_box">
        <div class="txt_box">
          <img src="@/assets/images/login/hlmandologo_wh.svg" alt="HL Mando logo">          
          <span>Our vision is your freedom.</span>
        </div>
      </div>
      <div class="login_form_box">
        <div>
          <strong>로그인</strong>
            <span>Welcome to HL Mando Data Monitoring website.</span>
            <div class="input_box">
              <div id="account">
                <div class="input_title">이메일</div>
                <input type="text" v-model="userAccont" class="input_txt" placeholder="이메일을 입력해 주세요.">
              </div>
              <div id="password">
                <div class="input_title">비밀번호</div>
                <input type="password" v-model="password" class="input_txt" placeholder="비밀번호를 입력해 주세요." @keyup.enter="loginAction">
              </div>
              <div class="save_info">
                <input type="checkbox" v-model="ckID" name="" id="saveInfo">
                <label for="saveInfo"></label>
                <span>아이디 저장</span>
                <a href="/find">비밀번호 찾기</a>
              </div>
              <div class="btn_wrap">
                <button type="button" id="loginBtn" @click="loginAction">로그인</button>
                <!-- 회원가입 버튼은 임시로 막아둠
                <a href="/addMember">회원이 아니신가요? <span>회원가입</span></a>
                -->
              </div>
            </div>
        </div>
      </div>
      
    </div>  
</template>
  
<script>
  import { mapState, mapActions } from 'vuex';
  import common from '@/assets/js/common';
  import Cookies from 'js-cookie';
  export default {
    name: 'LoginView',
    computed:{
      ...mapState(['user', 'isLoggedIn']),
    },
    data(){
      return{
        userAccont: "",
        password: "",
        ckID: false
      };
    },
    async mounted() {
      await common.loginCheck();
      
      console.log("login page1");

      console.log(this.$store.state.user);
      console.log(this.user);
      const userID = Cookies.get('userID');
      this.userAccont = userID;
    },
    methods:{
      ...mapActions(['login', 'logout']), 
      async loginAction(){
      // bukang@breezelab.io
      // mando1234!!
        const credentials = {
          "email": this.userAccont,
          "password": this.password
        }; 
        

        try {
          if(this.ckID){
            Cookies.set('userID', this.userAccont, { expires: 1 });
          }        
         await this.login(credentials);
        } catch (error) {
          const errorCode = error.response.data.code;
          if(errorCode === 'BEC-F0037') {
            alert("활성화 되지 않은 이메일입니다. 운영자에게 문의하세요");
          } else {
            alert("아이디 또는 비밀번호가 일치하지 않습니다.");
          }
          return;
        }
        
        // this.$router.push('/home');
        common.goHome();
      }
    }
  
}




</script>
  
<style lang="scss" scoped>
    #loginWrap{
      min-width: 1440px;
      width: 100%;
      height: 100vh;
      &>div{
        float: left;
      }
      .login_img_box{
        width: 55%;
        height: 100%;
        background-image: url($baseURL+'login/login_background_1920.jpg');
        background-size: cover;
        background-position: center;
        position: relative;
        .txt_box{
          width: 268px;
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          &>span{
            width: 100%;
            color: $whiteColor;
            display: block;
            margin-top: 10px;
            font-family: $montserrat;
            font-size: $fontMenuTitle;
          }
        }
      }
      .login_form_box{
        width: 45%;
        height: 100%;
        background-color: $whiteColor; 
        position: relative;
        &>div{
          width: 440px;
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          strong{
            display: block;
            font-size: 30px;
            line-height: 35px;
            font-weight: $bold;
            margin-bottom: 11px;
          }
          span{
            color: $placeHolderColor;
            line-height: 18px;
          }
          .input_title{
            margin-bottom: 10px;
          }
          #account{
            margin-top: 40px;
            text-align: left;
          }
          #password{
            margin-top: 30px;
            text-align: left;
          }
          .save_info{
            height: 22px;
            text-align: left;
            margin-top: 10px;
            span{
              display: inline-block;
              margin-left: 8px;
              position: relative;
              top: -5px;
              color: $blockColor;
            }
            a{
              float: right;
              position: relative;
              top: 3px;
              color: $placeHolderColor;
              text-decoration: underline;
            }
            &::after{
              content: "";
              display: block;
              clear: both;
            }
          }
        }
      }
      &::after{
        clear: both;
        content:"";
        display: block;
      }
      .btn_wrap{
        margin-top: 50px;
        #loginBtn{
          width: 100%;
          height: 50px;
          font-size: 17px;
          font-weight: $bold;
          color: $whiteColor;
          text-align: center;
          box-sizing: border-box;
          background-color: $mainColor;
          border-radius: 10px 10px 10px 10px;
          margin-bottom: 15px;
        }
        a{
          color: $placeHolderColor;
          span{
            display: inline-block;
            color: $mainColor;
            text-decoration: underline;
            padding-left: 10px;
          }
        }
      }
    }
</style>
  