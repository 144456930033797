<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>장치/배포관리</span>
                    <span>Edge Device 관리 - 목록</span>
                    <span class="active">Edge Device 상세</span>
                </div>
                <h2>Edge Device 관리 - 상세</h2>
            </div> -->
            <BreadcrumbLayout pageId="deviceNDelivery_detailEddgeDevice" />
            <div class="item_info">
                <div class="item_title">
                    <strong>Device Info</strong>
                    <div class="item_info_btn_box">
                        <button type="button" class="btn_fold" v-bind:style="infoToggle ? 'display:none': ''" @click="clickToggle()">접기</button>
                        <button type="button" class="btn_fold active" v-bind:style="!infoToggle ? 'display:none': ''" @click="clickToggle()">펼치기</button>
                        <button type="button" class="btn_evt_group" @click="hideUtils"></button>
                        <ul class="evt_btn_box radiusbox" :style="{ display: InferenceUtil ? 'block' : 'none' }">
                            <li class="download_item">
                                <button type="button" @click="makePDF">데이터 다운로드</button>
                            </li>
                            <li class="share_item">
                                <button type="button" @click="clipCoppy">공유하기</button>
                            </li>
                            <li class="retouch_item" v-if="manageRole">
                                <button type="button" @click="goModPage">수정</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item_table radiusbox" v-bind:style="infoToggle ? 'display:none': ''">
                    <table>
                        <thead>
                            <tr>
                                <th>Edge Device ID</th>
                                <th>Edge Device Name</th>
                                <th>Device Type</th>
                                <th>Computer Type</th>
                                <th>Status</th>
                                <th>Fleet / PRJ / TASK 수</th>
                                <th>기기 등록일</th>
                                <th>최종 수정일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{edgeDeviceData.edge_device_id}}</td>
                                <td>{{edgeDeviceData.edge_device_name}}</td>
                                <td>{{edgeDeviceData.device_type}}</td>
                                <td>{{edgeDeviceData.computer_type}}</td>
                                <td>{{edgeDeviceData.status}}</td>
                                <td>{{edgeDeviceData.fleet_cnt}} / {{edgeDeviceData.prj_cnt}} / {{edgeDeviceData.task_cnt}}</td>
                                <td>{{edgeDeviceData.regist_date}}</td>
                                <td>{{edgeDeviceData.update_date}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="item_desc">
                        <div class="desc_title">Description</div>
                        <div class="desc_txt">{{edgeDeviceData.description}}</div>
                    </div>
                </div>
            </div>
            <div id="monCont" class="vision_data">
                <div class="edge_list_wrap">
                    <ul class="list_tab float_box" ref="listTab">
                        <li @click="tabEvent(0)" class="active">
                            <div>기기 운영 정보</div>
                        </li>
                        <li @click="tabEvent(1)">
                            <div>PRJ/TASK 목록</div>
                        </li>
                        <li @click="tabEvent(2)">
                            <div>데이터 수집이력</div>
                        </li>
                    </ul>
                    <div class="tab_data" ref="listTabBox">
                        <div class="data_list_box device_info active">
                            <div class="float_box">
                                <div class="status_box device_img">
                                    <strong>Device Image</strong>
                                    <div class="img_box">
                                        <img :src="deviceImage(deviceOpsInfo.image)" alt="">
                                    </div>
                                </div>
                                <div class="status_box manage_box">
                                    <strong>기기 소유/관리</strong>
                                    <div class="txt_box">
                                        <div class="txt_title">기기 소유</div>
                                        <div class="txt_data">
                                            <div class="text_data_title">Division</div>
                                            <div>{{deviceOpsInfo.own_division_name}}</div><br>
                                            <div class="text_data_title">Team</div>
                                            <div>{{deviceOpsInfo.own_team_name}}</div>
                                        </div>
                                    </div>
                                    <div class="txt_box">
                                        <div class="txt_title">기기 관리</div>
                                        <div class="txt_data">
                                            <div class="text_data_title">Division</div>
                                            <div>{{deviceOpsInfo.manage_division_name}}</div><br>
                                            <div class="text_data_title">Team</div>
                                            <div>{{deviceOpsInfo.manage_team_name}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="status_box manager_box">
                                    <strong>담당자 정보</strong>
                                    <div class="total_mem">총 <span>{{edgeDeviceContactPeople.length}}</span>명</div>
                                    <div class="manager_team">
                                        기기 관리 소속 <span>{{deviceOpsInfo.manage_team_name}}</span>
                                    </div>
                                    <ul class="member_list_wrap">               
                                        <li v-for="(item, index) in edgeDeviceContactPeople" :key="index">
                                            <div class="img_box">
                                                <img :src="profileImage(item.profileImage)" alt="">
                                            </div>
                                            <div class="mbr_name">
                                                <span>{{item.name}}</span> <span>{{item.phone}}</span>
                                            </div>
                                            <div class="mbr_email">
                                                {{item.email}}
                                            </div>
                                            <div class="representative" v-if="item.representative === true">대표</div>
                                            <!-- <div class="representative" v-if="index == 0">대표</div> -->
                                        </li>
                                    </ul>
                                </div>
                                <div class="fleet_info">
                                    <strong>소속 Fleet 정보</strong>
                                    <div class="total_mem"><span>{{fleetResponseDtos.length}} </span> Fleets</div>
                                    <div class="mando_table_wrap">
                                        <table class="mando_table">
                                            <thead>
                                                <tr>
                                                    <th>Fleet 그룹명</th>
                                                    <th>Edge Device 수</th>
                                                    <th>Status</th>
                                                    <th>등록자명</th>
                                                    <th>소속</th>
                                                    <th>등록일</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in fleetResponseDtos" :key="index">
                                                    <td>
                                                        {{item.fleetName}}
                                                    </td>
                                                    <td>
                                                        {{item.fleetEdgeDeviceCount}}
                                                    </td>
                                                    <td>
                                                        {{item.status}}
                                                    </td>
                                                    <td>
                                                        {{item.registrantName}}
                                                    </td>
                                                    <td>
                                                        {{item.belonging}}
                                                    </td>
                                                    <td>
                                                        {{item.created}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="data_list_box prj_list_box">
                            <div class="table_box">
                                <strong>현재 연결 프로젝트 목록</strong>
                                <div class="total_mem"><span>{{edgeDevicePrjList.length}} </span> PRJs</div>
                                <div class="mando_table_wrap">
                                    <table class="mando_table">
                                        <thead>
                                            <tr>
                                                <th>Project Name</th>
                                                <th>Recent Driving Record</th>
                                                <th>Driving Record Count</th>
                                                <th>Status</th>
                                                <th>Team</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in edgeDevicePrjList" :key="index">
                                                <td>
                                                    {{item.project_name}}
                                                </td>
                                                <td>
                                                    {{item.recent_driving_record}}
                                                </td>
                                                <td>
                                                    {{item.driving_count}}
                                                </td>
                                                <td>
                                                    {{item.status}}
                                                </td>
                                                <td>
                                                    {{item.team_name}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="table_box last_box">
                                <strong>현재 연결 TASK 목록</strong>
                                <div class="total_mem"><span>{{edgeDeviceTaskList.length}} </span> TASKs</div>
                                <div class="mando_table_wrap">
                                    <table class="mando_table">
                                        <thead>
                                            <tr>
                                                <th>Project Name</th>
                                                <th>Task Name</th>
                                                <th>Task Type</th>
                                                <th>Manager</th>
                                                <th>Team</th>
                                                <th>Last Visit</th>
                                                <th>Create Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in edgeDeviceTaskList" :key="index">
                                                <td>
                                                    {{item.project_name}}
                                                </td>
                                                <td>
                                                    {{item.task_name}}
                                                </td>
                                                <td>
                                                    {{item.task_type}}
                                                </td>
                                                <td>
                                                    {{item.create_name}}
                                                </td>
                                                <td>
                                                    {{item.team_name}}
                                                </td>
                                                <td>
                                                    {{item.last_visit}}
                                                </td>
                                                <td>
                                                    {{item.create_date}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="data_list_box driv_history">
                            <strong>데이터 수집 이력 – 주행 이력</strong>
                            <div class="filter_box">
                                <div class="date_filter">
                                    <div class="date_period">
                                        <div class="date_box">
                                            <span style="display:inline-block ; padding : 0 5px 0 5px;" :style="picStartTxtStyle" ref="picStartTxt">{{ picStartDate }}</span> ~ <span style="display:inline-block ; padding: 0 0 0 5px;" :style="picEndTxtStyle" ref="picEndTxt">{{picEndDate}}</span>
                                        </div>
                                        <button type="button" ref="datePicToggle" @click="datePicToggle();"></button>
                                        <div class="calender_box radiusbox" ref="calenderBox" style="display:none">
                                            <div class="start_date date_box" ref="startDateBox" @click="onDatePic('S')" style="display:block">
                                                <VCalendarUi :dateInterface="'start'" :maxDate="maxDate"/>
                                                <button type="button" @click="pickerNext()">다음</button>
                                            </div>
                                            <div class="end_date date_box" ref="endDateBox" @click="onDatePic('E')">
                                                <VCalendarUi :dateInterface="'end'" :minDate="picStartDate" :maxDate="maxDate"/>
                                                <button type="button" @click="pickerPrev()">이전</button>
                                                <button type="button" class="mcbtn" @click="datePicToggle()">완료</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="search_from_box">
                                    <input type="text" name="dataKeyword" ref="dataKeyword" placeholder="검색어를 입력하세요." @keydown.enter="clickSearch();">
                                    <button type="button" class="btn_send mcbtn" @click="clickSearch();">조회</button>
                                </div>
                            </div>
                            <div class="mando_table_wrap">
                                <table class="mando_table">
                                    <thead>
                                        <tr>
                                            <th>시작 시간</th>
                                            <th>종료 시간</th>
                                            <th>Project Name</th>
                                            <th>등록자</th>
                                            <th>소속</th>
                                            <th>보기</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in driveRecordList" :key="index">
                                            <td>
                                                {{item.startTime}}
                                            </td>
                                            <td>
                                                {{item.endTime}}
                                            </td>
                                            <td>
                                                {{item.projectName}}
                                            </td>
                                            <td>
                                                {{item.creatorName}}
                                            </td>
                                            <td>
                                                {{item.belonging}}
                                            </td>
                                            <td>
                                                <div class="map_btn" @click="showModal(item.startTime, item.endTime, edgeDeviceData.edge_device_id)"> Map </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div id="modalWrap" ref="modalWrap" style="left: 10000px;">
        <div class="radiusbox modal_contents map_modal">
            <div class="title_box">
                <strong>Map</strong>
            </div>
            <div class="map_box">
                <naver-map id="map" style="width: 100%; height: 730px" :mapOptions="mapOptions" :initLayers="initLayers" :key="gpsCenterKey">
                    <naver-polyline v-if="gps.length > 0" :path="gps" :key="gpsKey" />
                </naver-map>
            </div>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import VCalendarUi from '@/components/VCalendarUi.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';
    import common from '@/assets/js/common';

    import { NaverMap, NaverPolyline } from "vue3-naver-maps";

    export default {
        name: 'detailEdgeDevice',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            VCalendarUi,
            NaverMap,
            NaverPolyline,
            BreadcrumbLayout,
        },
        computed:{
            // VCalendar 
            picStartTxtStyle() {
                return {
                    color: this.picColor.startStatus ? this.picColor.active : this.picColor.default
                };
            },
            picEndTxtStyle() {
                return {
                    color: this.picColor.endStatus ? this.picColor.active : this.picColor.default
                };
            },
        },
        data(){
            const edgeId = "";
            const edgeDeviceContactPeople = [];
            const fleetResponseDtos = [];
            const edgeDevicePrjList = [];
            const edgeDeviceTaskList = [];
            const driveRecordList = [];

            const picStartDate = null;
            const picEndDate = null;

            return{
                edgeId,

                edgeDeviceData: {
                    edge_device_id: "",
                    edge_device_name: "",
                    device_type: "",
                    computer_type: "",
                    status: "",
                    fleet_cnt: "",
                    prj_cnt: "",
                    task_cnt: "",
                    regist_date: "",
                    update_date: "",
                    description: "",
                },

                deviceOpsInfo: {
                    image: "",
                    own_division_name: "",
                    own_team_name: "",
                    manage_division_name: "",
                    manage_team_name: "",
                },

                edgeDeviceContactPeople,
                fleetResponseDtos,
                edgeDevicePrjList,
                edgeDeviceTaskList,
                driveRecordList,

                // -- VCalendarUi / .date_period
                maxDate: "9999-12-31",
                picStartDate,
                picEndDate,
                picColor: {
                    startStatus: false,
                    endStatus: false,
                    active: '#00B4ED',
                    default: '#020A0A'
                },

                infoToggle: false,

                // gps 
                gps: [
                        { lat: 37.404583333333335, lng: 127.11658055555556 },
                        { lat: 37.40456388888889, lng: 127.11654722222222 },
                        { lat: 37.40455, lng: 127.11653611111112 },
                ],
                mapOptions : {
                    latitude: 37.404583333333335, // 지도 중앙 위도
                    longitude: 127.11658055555556, // 지도 중앙 경도
                    zoom: 17,
                    zoomControl: false,
                    zoomControlOptions: { position: "TOP_RIGHT" },
                },
                initLayers : [
                    "BACKGROUND",
                    "BACKGROUND_DETAIL",
                    "POI_KOREAN",
                    "TRANSIT",
                    "ENGLISH",
                ],
                gpsKey: 0,
                gpsCenterKey: 0,

                InferenceUtil: false,

                // role
                viewRole: false,
                manageRole: false,
            };
        },
        watch: {
            gps() {
                this.gpsKey++;
            },

        },
        mounted() {
            // Role
            this.setRole();
            
            this.edgeId = this.$route.params.edgeId;

            this.setEdgeDeviceData();

            const start_formattedDate = common.getNowDt();
            const end_formattedDate = common.getNowDt();
            
            this.picStartDate = start_formattedDate;
            this.picEndDate = end_formattedDate;
            window.selectDate.startDate = start_formattedDate;
            window.selectDate.endDate = end_formattedDate;
        },
        methods:{
            async setRole() {
                this.manageRole = await common.getUserRole('type2');
                this.viewRole = await common.getUserRole('type1');

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            // profile img 
            profileImage(image) {
                if(image == null) return require('@/assets/images/common/member_sample.svg');
                else {
                    return image;
                }
            },
            // 
            deviceImage(image) {
                if(image == null ||image == undefined || image == '') return require('@/assets/images/common/deviceimg_sample.svg');
                else {
                    return image;
                }
            },
            //

            tabEvent(index){
                const $listTab = this.$refs.listTab;
                const $listTabBox = this.$refs.listTabBox;
                let $listTabItem = $listTab.querySelectorAll('li');
                let $listTabBoxItem = $listTabBox.querySelectorAll('.data_list_box');
                for(var i=0 ; i < $listTabItem.length ; i ++ ){
                    $listTabItem[i].classList.remove("active");
                    $listTabBoxItem[i].classList.remove("active");
                }
                $listTabItem[index].classList.add("active");
                $listTabBoxItem[index].classList.add("active");
            },

            // -------- DATE PICKER -----
            datePicToggle(){
                const vm = this;
                const _display = vm.$refs.calenderBox.style.display;
                const _startDateBox = vm.$refs.startDateBox.style.display;
                
                if(_display == 'block'){
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'none';
                    vm.picColor.startStatus = false;
                    vm.picColor.endStatus = false;

                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'block';
                    if(_startDateBox == 'block'){
                        vm.picColor.startStatus = true;
                        vm.picColor.endStatus = false;
                    }else if( _startDateBox == 'none'){
                        vm.picColor.startStatus = false;
                        vm.picColor.endStatus = true;
                    }
                }
            },

            onDatePic(flag){
                if(flag == 'S'){   
                    if(window.selectDate.startDate !== null ){
                        this.picStartDate = window.selectDate.startDate;
                    }
                    
                }else if(flag == 'E'){
                    if(window.selectDate.picEndDate !== null ){
                        this.picEndDate = window.selectDate.endDate;
                    }
                }
            },
            pickerNext(){
                this.$refs.startDateBox.style.display = 'none';
                this.$refs.endDateBox.style.display = 'block';

                this.picColor.startStatus = false;
                this.picColor.endStatus = true;
                
            },
            pickerPrev(){
                this.$refs.startDateBox.style.display = 'block';
                this.$refs.endDateBox.style.display = 'none';

                this.picColor.startStatus = true;
                this.picColor.endStatus = false;
            },
            // ------------

            // ----- click 
            async clickSearch() {
                await this.setDataHistory();

                const keyword = this.$refs.dataKeyword.value.toUpperCase();
                if(typeof keyword !== "undefined" && keyword !== null && keyword !== "") {
                    this.setSearchDataHistory(keyword);
                }
            },

            goModPage() {
                this.$router.push({ 
                    name: 'modEddgeDevice',
                    params: { edgeId: this.edgeId}
                });
            },

            clickToggle() {
                const toggle = !this.infoToggle;
                this.infoToggle = toggle;
            },

            hideUtils() { // 부가기능 - 보이기 숨기기
                if(this.InferenceUtil == true) {
                    this.InferenceUtil = false;
                } else if(this.InferenceUtil == false){
                    this.InferenceUtil = true;
                }
            },
            async clipCoppy() { // 공유하기
                let dummy = document.createElement("textarea");
                document.body.appendChild(dummy);
                try {
                    const url = window.document.location.href;
                    dummy.value = url;
                    dummy.select();
                    document.execCommand("copy");
                    document.body.removeChild(dummy);
                    alert('url copy success')
                } catch(error) {
                    console.log(error);
                } 
            },

            async setEdgeDeviceData() {
                const edgeDeviceData = await this.getEdgeDeviceData(this.edgeId);
                if(typeof edgeDeviceData !== "undefined" && edgeDeviceData !== null && edgeDeviceData !== "") {
                    this.edgeDeviceData = edgeDeviceData;
                }

                const deviceOpsInfo = await this.getEdgeDeviceOpsInfo(this.edgeId);
                if(typeof deviceOpsInfo !== "undefined" && deviceOpsInfo !== null && deviceOpsInfo !== "") {
                    this.deviceOpsInfo = deviceOpsInfo;
                    this.edgeDeviceContactPeople = this.deviceOpsInfo.edgeDeviceContactPeople;
                    this.fleetResponseDtos = this.deviceOpsInfo.fleetResponseDtos;
                }

                const _edgeDevicePrjList = await this.getEdgeDevicePrjList(this.edgeId);
                if ( common.nullCheck(_edgeDevicePrjList) !== '' ) {
                    this.edgeDevicePrjList = _edgeDevicePrjList;
                }

                const _edgeDeviceTaskList = await this.getEdgeDeviceTaskList(this.edgeId);
                if ( common.nullCheck(_edgeDeviceTaskList) !== '' ) {
                    this.edgeDeviceTaskList = _edgeDeviceTaskList;
                }

                this.setDataHistory();
            },
            
            async setDataHistory(){
                const startDt = this.picStartDate;
                const endDt = this.picEndDate;
                const _driveRecordList = await this.getDriveRecordList(this.edgeId, startDt, endDt);
                if ( common.nullCheck(_driveRecordList) !== '' ) {
                    this.driveRecordList = _driveRecordList;
                }
            },

            setSearchDataHistory(keyword) {
                const searchRow = [];
    
                this.driveRecordList.forEach(item => {
                    let projectName = common.getUpper(item['projectName']);
                    let creatorName = common.getUpper(item['creatorName']);
                    let belonging = common.getUpper(item['belonging']);

                    if ( projectName.includes(keyword) || creatorName.includes(keyword) || belonging.includes(keyword) ) {
                        searchRow.push(item);
                    }
                });

                this.driveRecordList = ([]);

                searchRow.forEach(item => {
                    this.driveRecordList.push(item);
                });
            },

            // ----- API 
            async getEdgeDeviceData(edgeId) {
                try {
                    const response = await common.apiGet('/edge-device/' + edgeId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getEdgeDeviceOpsInfo(edgeId) {
                try {
                    const response = await common.apiGet('/edge-device/detail/operation/' + edgeId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getEdgeDevicePrjList(edgeId) {
                try {
                    const response = await common.apiGet('/edge-device/detail/project/' + edgeId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getEdgeDeviceTaskList(edgeId) {
                try {
                    const response = await common.apiGet('/edge-device/detail/monitoring-task/' + edgeId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getDriveRecordList(edgeId, startDt, endDt) {
                try {
                    const response = await common.apiGet('/edge-device/detail/driving-record/' + edgeId
                                                        + '?start-time=' + startDt 
                                                        + '&end-time=' + endDt);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            // --- modal 
            async showModal(startTime, endTime, vehicle){
                const $modal = this.$refs.modalWrap;
                

                const _startTime = startTime;
                const _endTime = endTime;
                const _vehicle = vehicle;
                
                await this.callMap(_startTime, _endTime, _vehicle);

                $modal.style.left = await "0";

            },
            closeModal(){
                const $modal = this.$refs.modalWrap;
                $modal.style.left = "10000px";
            },
            // map api
            async callMap(startTime, endTime, vehicle){
                const vm = this;
                const _startTime = startTime;
                const _endTime = endTime;
                const _vehicle = vehicle;

                // const dlGPS = await vm.setGPS(_startTime, _endTime, _vehicle);
                const dlGPS = await vm.setGPS('2023-09-05 08:35:00', '2023-09-05 08:38:00', 'd-uset1-maici-fwvhcl-jk1ev-orin01-01');
                
                console.log(dlGPS);
                vm.gps = [];
                vm.gps = dlGPS;
                
                vm.updateCenter(dlGPS[0].lat, dlGPS[0].lng);


                console.log("==== MAP API CALL ====");
                console.log(_startTime, _endTime, _vehicle);
                console.log("======================");
                
            },
            // gps 
            updateCenter(lat, lng) {
                this.mapOptions.latitude = lat; // 새 위도 값으로 업데이트
                this.mapOptions.longitude = lng; // 새 경도 값으로 업데이트

                this.gpsCenterKey += 1;
            },
            async setGPS(startTime, endTime, vehicle){
                const _startTime = startTime;
                const _endTime = endTime;
                const _vehicle = vehicle;
                const headers = {
                    startTime: _startTime,
                    endTime: _endTime,
                    vehicle: _vehicle,
                    // startTime: '2023-09-05 08:35:00',
                    // endTime: '2023-09-05 08:36:00',
                    // vehicle: 'd-uset1-maici-fwvhcl-jk1ev-orin01-01',
                };
                const dlGPS = await this.getGPSData(headers);
                let _data = [];

                if(dlGPS != undefined){
                    for(var g = 0 ; g < dlGPS.length; g++){
                        await _data.push({'lat': dlGPS[g].latitude , 'lng': dlGPS[g].longitude});
                    }
                }
                return _data
                
            },
            async getGPSData(headers){
                console.log(headers);
                try {
                    const response = await common.apiDlGet('/dl/gps/gps', headers);
                    console.log(response);
                    return response.data.queryResult.results;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
        }
    }
</script>
<style scoped lang="scss">

.vision_data{
    .list_tab li > div{
        text-indent: 0 !important;
    }
    .edge_list_wrap{
        margin-top: 14px;
        .tab_data{
            height: auto;
            background-color: $whiteColor;
            border-radius: 10px;
            box-shadow: 0px 0px 10px #002B6833;
            box-sizing: border-box;
        }
        .data_list_box{
            width: 100%;
            height: 750px;
            padding: 20px;
            box-sizing: border-box;
        }
        .device_info{
            .status_box{
                & > strong{
                    font-weight: $bold;
                }
            }
            .device_img{
                width: 28.5%;
                height: 100%;
                border-right: 1px solid $line01Color;
                .img_box{
                    width: 330px;
                    height: 250px;
                    margin: 20px 20px 0 0;
                    overflow: hidden;
                    img{
                        width: 100%;
                    }
                }
            }
            .manage_box{
                width: 30%;
                height: 100%;
                padding-left: 20px;
                border-right: 1px solid $line01Color;
                .txt_box{
                    margin-top: 40px;
                    .txt_title{
                        color: #6E7380;
                        margin-bottom: 10px;
                    }
                    .txt_data{
                        &>div{
                            display: inline-block;
                            font-size: 15px;
                            height: 25px;
                        }
                        .text_data_title{
                            width: 60px;
                            font-weight: $bold;
                            margin-right: 20px;
                        }
                    }
                }
            }
            .manager_box{
                width: 41.5%;
                height: 100%;
                padding-left: 20px;
                position: relative;
                .manager_team{
                    margin-top: 20px;
                    font-size: 15px;
                    span{
                        display: inline-block;
                        font-size: 15px;
                        color: #00B4ED;
                        font-weight: $bold;
                        margin-left: 5px;
                    }
                }
                .total_mem{
                    font-size: $fontSub;
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: #8A8F9C;
                    span{
                        color: $mainColor;
                        font-weight: $bold;
                        font-size: $fontSub;
                    }
                }
                .member_list_wrap{
                    height: 215px;
                    overflow-y: auto;
                    border-top: 1px solid $line02Color;
                    margin-top: 15px;
                    li{
                        width: 100%;
                        height: 45px;
                        position: relative;
                        box-sizing: border-box;
                        padding-left: 60px;
                        margin: 14px 0 0;
                        .img_box{
                            position: absolute;
                            top: 2px;
                            left: 0;
                            width: 40px;
                            height: 40px;
                            border-radius: 44px;
                            overflow: hidden;
                            img{
                                width: 100%;
                            }
                        }
                        .mbr_name{
                            line-height: 25px;
                            span{
                                font-size: 15px;
                                display: inline-block;
                                padding-right: 10px;
                                &:last-child{
                                    padding:0 10px 0;
                                    border-left: 1px solid $line01Color;
                                }
                            }
                        }
                        .mbr_email{
                            color: $placeHolderColor;
                        }
                        .representative{
                            width: 40px;
                            height: 25px;
                            color: #fff;
                            background-color: #00B4ED;
                            border-radius: 5px;
                            line-height: 25px;
                            text-align: center;
                            font-size: 13px;
                            position: absolute;
                            top: 50%;
                            right: 40px;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
            .fleet_info{
                width: 100%;
                height: 400px;
                margin-top: 20px;
                position: relative;
                border: 1px solid $line01Color;
                box-sizing: border-box;
                border-radius: 10px;
                & > strong{
                    display: block;
                    margin: 20px 0 0 20px;
                }
                .total_mem{
                    font-size: $fontSub;
                    display: inline-block;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    color: #8A8F9C;
                    span{
                        color: $mainColor;
                        font-weight: $bold;
                        font-size: $fontSub;
                    }
                }
                .mando_table_wrap{
                    width: 100%;
                    height: 340px;
                    overflow-y: auto;
                    margin-top: 20px;
                    .mando_table{
                        
                        thead{
                            box-shadow: none !important;
                        }
                    }
                }
                

            }
            .float_box{
                width: 100%;
                height: 290px;
                & > div{
                    float: left;
                    box-sizing: border-box;
                }
                
            }
        }

        .data_list_box > .mando_table{
            position: relative;
            & > thead{
                tr{
                &:first-child{
                    th{
                        vertical-align: middle;
                        &:nth-child(1){
                            border-right: 1px solid #E5E9EF;
                            &::after{
                                display: none;
                            }
                        }
                        &:nth-child(2){
                            border-right: 1px solid #E5E9EF;
                            &::after{
                                display: none;
                            }
                        }
                        &:nth-child(3){
                            border-right: 1px solid #E5E9EF;
                            &::after{
                                display: none;
                            }
                        }
                        &:nth-child(4){
                            border-right: 1px solid #E5E9EF;
                            &::after{
                                display: none;
                            }
                        }
                        &:nth-child(5){
                            border-right: 1px solid #E5E9EF;
                            &::after{
                                display: none;
                            }
                        }
                    }
                }
                &:last-child{
                    th{
                        &:nth-child(2n){
                            border-right: 1px solid #E5E9EF;
                            &::after{
                                display: none;
                            }
                        }
                    }
                }
                }
            }
            tbody{
                .item{
                    & + .item_detail{
                        display: none;
                        &:hover{
                            background-color: $whiteColor;
                        }
                        
                    }
                    &.active + .item_detail{
                        display: table-row;
                        td{
                            background-color: #F8F9FA;
                        }
                        td > div {
                            width: 1236px;
                            
                            box-sizing: border-box;
                            padding: 0 20px;
                            .chart_box{
                                width: 100%;
                                height: 200px;
                                margin-top: 20px;
                                .chart{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .mando_table{
                                position: relative;
                                thead{
                                    background-color: #F8F9FA;
                                    box-shadow: none;
                                }
                                tr{
                                    background-color: #F8F9FA;
                                }
                                th, td{
                                    text-align: center;
                                    vertical-align: middle;
                                }
                                td{
                                    &:first-child{
                                        padding: 14px 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
        .prj_list_box{
            overflow: hidden;
            .table_box{
                width: 100%;
                height: 345px;
                border: 1px solid $line01Color;
                box-sizing: border-box;
                border-radius: 10px;
                position: relative;
                
                strong{
                    display: block;
                    margin: 20px 0 0 20px;
                    font-size: 15px;
                    font-weight: $bold;
                }
                .total_mem{
                    font-size: $fontSub;
                    display: inline-block;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    color: #8A8F9C;
                    span{
                        color: $mainColor;
                        font-weight: $bold;
                        font-size: $fontSub;
                    }
                }
                .mando_table_wrap{
                    height: 290px;
                    margin-top: 20px;
                }
                &.last_box{
                    margin-top: 20px;
                }
                
            }
        }
        .driv_history{
            padding: 0px;
            position: relative;
            strong{
                height: 45px;
                display: block;
                margin: 20px 0 0 20px;
                font-size: 15px;
                font-weight: $bold;
                line-height: 45px;
            }
            .filter_box{
                width: 565px;
                height: 40px;
                position: absolute;
                top: 0;
                right: 0;
                .date_filter{
                    width: 265px;
                    height: 40px;
                    position: relative;
                    display: inline-block;
                    &::after{
                        display: none;
                    }
                }
                
                .search_from_box{
                    width: 270px;
                    display: inline-block;
                    input{
                        width: 200px;
                        height: 40px;
                        border: 1px solid $disable02Color;
                        border-radius: 10px;
                        box-sizing: border-box;
                        padding: 0 15px;
                    }
                    .btn_send{
                        height: 40px;
                        padding: 0 15px;
                        border-radius: 10px;
                        line-height: 37px;
                        font-size: 15px;
                        margin-left: 10px;
                    }
                }
            }
            .mando_table_wrap{
                height: 685px;
                margin-top: 20px;
                .map_btn{
                    width: 76px;
                    margin: 0 auto;
                    cursor: pointer;
                    background-image: url($baseURL + "common/external_link.svg");
                    background-repeat: no-repeat;
                    background-position-x: right;
                    background-position-y: center;
                }
            }
        }

    }    
}

#modalWrap{
    .map_modal{
        width: 1200px;
        height: 800px; 
        position: relative;
        overflow: hidden;
        .title_box{
            padding: 25px 25px 25px;
            border-bottom: 2px solid #707070;
            strong{
                font-size: $fontMenuTitle;
            }
        }
        .map_box{
            width: 1200px;
            height: 730px;
            background-color: #ff0;
            
        }
    }
}



</style>