import gsap from 'gsap';

const mlops = {
    uiSlider(abid, max){
        const rengMax = max;
        const obj = document.getElementById(abid);
        const inputLeft = obj.querySelector("#input-left");
        const inputRight = obj.querySelector("#input-right");

        const thumbLeft = obj.querySelector(".thumb.left");
        const thumbRight = obj.querySelector(".thumb.right");
        
        const range = obj.querySelector(".range");
        
        const setLeftValue = e => {
            const _this = e.target;
            const { value, min, max } = _this;

            if (+inputRight.value - +value < 10) {
                _this.value = +inputRight.value - 10;
            }
            
            const percent = ((+_this.value - +min) / (+max - +min)) * 100;

            const startVal = Math.round(rengMax * (_this.value / 100));

            inputLeft.setAttribute("data-left", startVal);

            thumbLeft.style.left = `${percent}%`;
            range.style.left = `${percent}%`;
        };

        const setRightValue = e => {
            const _this = e.target;
            const { value, min, max } = _this;

            if (+value - +inputLeft.value < 10) {
                _this.value = +inputLeft.value + 10;
            }

            const percent = ((+_this.value - +min) / (+max - +min)) * 100;

            const endVal = Math.round(rengMax * (_this.value / 100));

            inputRight.setAttribute("data-right", endVal);

            thumbRight.style.right = `${100 - percent}%`;
            range.style.right = `${100 - percent}%`;
        };

        if (inputLeft && inputRight) {
            inputLeft.addEventListener("input", setLeftValue);
            inputRight.addEventListener("input", setRightValue);
        }
    },
    uiAcodion(obj){
        const $tagetObj = obj;
        const $tagetParentObj = $tagetObj.parentNode;
        const siblings = Array.from($tagetParentObj.parentNode.children);
        
        if($tagetParentObj.classList.contains("active")){
            gsap.to($tagetObj.nextElementSibling , {
                onStart: () => {
                    $tagetParentObj.classList.remove("active");
                },
                height: 0,
                duration: .3       
            });
        }else{
            siblings.forEach((sibling) => {
                if (sibling !== $tagetObj) {
                    gsap.to(sibling.querySelector('button').nextElementSibling , {
                        onStart: () => {
                            sibling.classList.remove("active");
                        },
                        height: 0,
                        duration: .3       
                    })
                    
                }
            });
            gsap.to($tagetObj.nextElementSibling , {
                onStart: () => {
                    $tagetParentObj.classList.add("active");
                },
                height: 'auto',
                duration: .3       
            });
        }
        
    }

    
}

export default mlops ;