<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>데이터/ TASK 운영</span>
                    <span>MLOps TASK 목록</span>
                    <span class="active">MLOps – Observation Log 모니터링</span>
                </div>
                <h2>MLOps – Observation Log 모니터링</h2>
            </div> -->
            <BreadcrumbLayout pageId="monTASK_mloObservation" />
            <div class="item_info">
                <div class="item_title">
                    <strong class="task_project">[PROJECT] {{pjtData.project_name}}</strong>
                    <div class="item_info_btn_box">
                        <button type="button" class="btn_fold" v-bind:style="prjInfoToggle ? 'display:none': ''" @click="clickTogglePrj()">접기</button>
                        <button type="button" class="btn_fold active" v-bind:style="!prjInfoToggle ? 'display:none': ''" @click="clickTogglePrj()">펼치기</button>
                        <button type="button" class="btn_evt_group" @click="hideUtils"></button>
                        <ul class="evt_btn_box radiusbox" :style="{ display: InferenceUtil ? 'block' : 'none' }">
                            <li class="download_item">
                                <button type="button" @click="makePDF">데이터 다운로드</button>
                            </li>
                            <li class="share_item">
                                <button type="button" @click="clipCoppy">공유하기</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item_table radiusbox" v-bind:style="prjInfoToggle ? 'display:none': ''">
                    <table>
                        <thead>
                            <tr>
                                <th>Project Name</th>
                                <th>AWS ID</th>
                                <th>PM</th>
                                <th>Project Type</th>
                                <th>Unit</th>
                                <th>Last Activity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{pjtData.project_name}}</td>
                                <td>{{pjtData.aws_id}}</td>
                                <td>{{pjtData.pm}}</td>
                                <td>{{pjtData.project_type}}</td>
                                <td>{{pjtData.team_name}}</td>
                                <td>{{pjtData.last_activity}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="item_desc">
                        <div class="desc_title">Description</div>
                        <div class="desc_txt">{{pjtData.project_description}}</div>
                    </div>
                </div>
                <div class="float_box">
                    <div class="log_filter radiusbox">
                        <strong>Filter</strong>
                        <ul class="log_filter_list">
                            <li class="active" style="z-index: 5;">
                                <!-- <button @click="uiAcodion($event)" type="button">기간</button> -->
                                <button type="button">기간</button>
                                <div class="log_filter_item">
                                    <select @change="dateSelectChange" v-model="dateSelectOption" style="width: 100%; background-position: 230px center; margin-top: 5px;">
                                        <option value="1" selected>1일</option>
                                        <option value="7">7일</option>
                                        <!-- <option value="14">14일</option> -->
                                        <option value="30">30일</option>
                                    </select>
                                    <div class="filter_box">
                                        <div class="date_filter">
                                            <div class="date_period">
                                                <div class="date_box">
                                                    <span style="display:inline-block ; padding : 0 5px 0 5px;" :style="picStartTxtStyle" ref="picStartTxt">{{ picStartDate }}</span> ~ <span style="display:inline-block ; padding: 0 0 0 5px;" :style="picEndTxtStyle" ref="picEndTxt">{{picEndDate}}</span>
                                                </div>
                                                <button type="button" ref="datePicToggle" @click="datePicToggle()"></button>
                                                <div class="calender_box radiusbox" ref="calenderBox" style="display:none">
                                                    <div class="start_date date_box" ref="startDateBox" @click="onDatePic('S')" style="display:block">
                                                        <VCalendarUi :dateInterface="'start'" :setDate="dateSelectOption" />
                                                        <button type="button" @click="pickerNext()">다음</button>
                                                    </div>
                                                    <div class="end_date date_box" ref="endDateBox" @click="onDatePic('E')">
                                                        <VCalendarUi :dateInterface="'end'" :minDate="picStartDate"/>
                                                        <button type="button" @click="pickerPrev()">이전</button>
                                                        <button type="button" class="mcbtn" @click="datePicToggle()">완료</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!-- -->
                            <li class="active">
                                <!-- <button @click="uiAcodion($event)" type="button">Domain</button> -->
                                <button type="button">Domain</button>
                                <ul class="log_filter_item">
                                    <li class="input_radio">
                                        <input type="radio" checked v-model="fliterKey.awsIdVal" value="awsIdVal1" name="awsId" id="a1">
                                        <label for="a1">
                                            <div class="radio_icon"></div>
                                            {{pjtData.project_type}}
                                        </label>
                                    </li>
                                    <!-- <li class="input_radio">
                                        <input type="radio" v-model="fliterKey.awsIdVal" value="awsIdVal2" name="awsId" id="a2">
                                        <label for="a2">
                                            <div class="radio_icon"></div>
                                            Data2
                                        </label>
                                    </li>
                                    <li class="input_radio">
                                        <input type="radio" v-model="fliterKey.awsIdVal" value="awsIdVal3" name="awsId" id="a3">
                                        <label for="a3">
                                            <div class="radio_icon"></div>
                                            Data3
                                        </label>
                                    </li> -->
                                </ul>
                            </li> 

                            <!-- -->
                            <li class="active">
                                <!-- <button @click="uiAcodion($event)" type="button">TASK</button> -->
                                <button type="button">TASK</button>
                                <ul class="log_filter_item">
                                    <li class="input_radio">
                                        <input type="radio" checked v-model="fliterKey.taskVal" value="taskVal1" name="task" id="b1">
                                        <label for="b1">
                                            <div class="radio_icon"></div>
                                            {{pjtData.aws_id}}
                                        </label>
                                    </li>

                                    <!-- <li class="input_radio">
                                        <input type="radio" v-model="fliterKey.taskVal" value="taskVal2" name="task" id="b2">
                                        <label for="b2">
                                            <div class="radio_icon"></div>
                                            Data2
                                        </label>
                                    </li>
                                    <li class="input_radio">
                                        <input type="radio" v-model="fliterKey.taskVal" value="taskVal3" name="task" id="b3">
                                        <label for="b3">
                                            <div class="radio_icon"></div>
                                            Data3
                                        </label>
                                    </li> -->
                                </ul>
                            </li> 
                            <!-- -->
                            <li class="active">
                                <!-- <button @click="uiAcodion($event)" type="button">Pipeline ID</button> -->
                                <button type="button">Pipeline ID</button>
                                <ul class="log_filter_item">
                                    <li class="input_radio">
                                        <input type="radio" checked v-model="fliterKey.pipIdVal" value="pipIdVal1" name="pipId" id="c1">
                                        <label v-if="pjtData.aws_id == 'vphm01'" for="c1">
                                            <div class="radio_icon"></div>
                                            c-apne2-vphm01-mpipe-02
                                        </label>
                                        <label v-else for="c1">
                                            <div class="radio_icon"></div>
                                            c-apne2-sfs01-mpipe-fv-P01
                                        </label>
                                    </li>
                                    <!-- <li class="input_radio">
                                        <input type="radio" v-model="fliterKey.pipIdVal" value="pipIdVal2" name="pipId" id="c2">
                                        <label for="c2">
                                            <div class="radio_icon"></div>
                                            Data2
                                        </label>
                                    </li>
                                    <li class="input_radio">
                                        <input type="radio" v-model="fliterKey.pipIdVal" value="pipIdVal3" name="pipId" id="c3">
                                        <label for="c3">
                                            <div class="radio_icon"></div>
                                            Data3
                                        </label>
                                    </li> -->
                                </ul>
                            </li> 
                            <!-- <li>
                                <button @click="uiAcodion($event)" type="button">상세 설정</button>
                                <ul class="log_filter_item detail_set">
                                    <li>
                                        <strong>Pipeline Version</strong>
                                        <div id="pipVer" class="renge_box">
                                            <input type="range" ref="pvThumbLeft" id="input-left" min="1" max="100" value="1" data-left="0"/>
                                            <input type="range" ref="pvThumbRight" id="input-right" min="1" max="100" value="100" data-right="30" />
                                            <div class="track">
                                                <div class="range"></div>
                                                <div class="thumb left"></div>
                                                <div class="thumb right"></div>
                                            </div>
                                            <div class="num_box">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                        <div class="renge_val">
                                            <span>{{ fliterKey.pipVerStart }}</span>~<span>{{ fliterKey.pipVerEnd }}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>Run ID</strong>
                                        <div id="runId" class="renge_box">
                                            <input type="range" ref="runThumbLeft" id="input-left" min="1" max="100" value="1" data-left="0"/>
                                            <input type="range" ref="runThumbRight" id="input-right" min="1" max="100" value="100" data-right="30"/>
                                            <div class="track">
                                                <div class="range"></div>
                                                <div class="thumb left"></div>
                                                <div class="thumb right"></div>
                                            </div>
                                            <div class="num_box">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                        <div class="renge_val">
                                            <span>{{ fliterKey.pipRunIdStart }}</span>~<span>{{ fliterKey.pipRunIdEnd }}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>Model Version</strong>
                                        <div class="search_from">
                                            <input type="text" name="" id="" placeholder="검색어를 입력해 주세요.">
                                            <button type="button" class="btn_search"></button>
                                        </div>
                                    </li>
                                </ul>
                            </li> -->
                        </ul>
                        <button type="button" class="mcbtn" @click="checkRadios" :disabled="!areRadiosSelected">조회</button>
                    </div>
                    <div class="log_data_box">
                        <ul class="list_tab float_box" ref="listTab">
                            <li @click="tabEvent(0)" class="active">
                                <div>Values</div>
                            </li>
                            <li @click="tabEvent(1)">
                                <div>Classification Report</div>
                            </li>
                            <li @click="tabEvent(2)">
                                <div>Confusion Matrix</div>
                            </li>
                            <li @click="tabEvent(3)">
                                <div>Inference Model Info</div>
                            </li>
                        </ul>
                        <div class="tab_data radiusbox" ref="listTabBox">
                            <div class="data_list_box val_item active">
                                <strong>Pipeline ID</strong>
                                <div class="chart_box">
                                    <canvas ref="valueLineChart"></canvas>
                                </div>
                                <div class="mando_table_wrap">
                                    <table class="mando_table">
                                        <thead>
                                            <tr>
                                                <th>Pipeline Version</th>
                                                <th>Run ID</th>
                                                <th>Model Version</th>
                                                <th>Run Date</th>
                                                <th>MAE</th>
                                                <th>MSE</th>
                                                <th>RMSE</th>
                                                <th>IS</th>
                                                <th>FID</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in valueList" :key="index">
                                                <td>{{item.pipeline_version}}</td>
                                                <td>{{item.run_id}}</td>
                                                <td>{{item.model_version}}</td>
                                                <td>{{item.run_date}}</td>
                                                <td>{{item.mae}}</td>
                                                <td>{{item.mse}}</td>
                                                <td>{{item.rmse}}</td>
                                                <td>{{item.is}}</td>
                                                <td>{{item.fid}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="data_list_box report_item ">
                                <strong>Classification_Report Table</strong>
                                <div class="mando_table_wrap">
                                    <table class="mando_table">
                                        <thead>
                                            <tr>
                                                <th>Domain</th>
                                                <th>Task</th>
                                                <th>Pipeline ID</th>
                                                <th>Pipeline Version</th>
                                                <th>Run ID</th>
                                                <th>Model version</th>
                                            </tr>
                                        </thead>
                                        <tbody class="accordion_list">
                                            <template v-for="(item, index) in classList" :key="index">
                                                <tr :id="'tr_class-' + index" :ref="'tr_class-' + index" class="item">
                                                    <td>
                                                        <button type="button" class="arrow_icon" :value="index" @click="clickClss($event)"></button>
                                                        {{domain}}
                                                    </td>
                                                    <td>{{pjtData.aws_id}}</td>
                                                    <td>{{item.pipeline_id}}</td>
                                                    <td>{{item.pipeline_version}}</td>
                                                    <td>{{item.run_id}}</td>
                                                    <td>{{item.model_version}}</td>
                                                </tr>
                                                <tr class="item_detail">
                                                    <td colspan="7">
                                                        <div>
                                                            <div class="mando_table_wrap">
                                                                <table class="mando_table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Title</th>
                                                                            <th>Precision</th>
                                                                            <th>Recall</th>
                                                                            <th>F1-Score</th>
                                                                            <th>Support</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="(item2, index2) in item.classification_report" :key="index2">
                                                                            <td>{{item2.title}}</td>
                                                                            <td>{{item2.precision}}</td>
                                                                            <td>{{item2.recall}}</td>
                                                                            <td>{{item2.f1_score}}</td>
                                                                            <td>{{item2.support}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="data_list_box matrix_item">
                                <strong>Confusion Matrix</strong>
                                <div class="float_box">
                                    <div>
                                        <div class="mando_table_wrap">
                                            <table class="mando_table">
                                                <!-- <colgroup>
                                                    <col style="width : 10%">
                                                    <col style="width : 10%">
                                                    <col style="width : 10%">
                                                    <col style="width : 10%">
                                                    <col style="width : 10%">
                                                    <col style="width : 12%">
                                                </colgroup> -->
                                                <thead>
                                                    <tr>
                                                        <th>Domain</th>
                                                        <th>Task</th>
                                                        <th>Pipeline ID</th>
                                                        <th>Pipeline <br/>Version</th>
                                                        <th>Run ID</th>
                                                        <th>Model version</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="item" v-for="(item, index) in conMatricList" :key="index" :value="item" @click="clickConMatrix(item, $event)">
                                                        <td>{{domain}}</td>
                                                        <td>{{pjtData.aws_id}}</td>
                                                        <td>{{item.pipeline_id}}</td>
                                                        <td>{{item.pipeline_version}}</td>
                                                        <td>{{item.run_id}}</td>
                                                        <td>{{item.model_version}}</td>
                                                    </tr>
                                                    <!-- <tr>
                                                        <td>vehicle</td>
                                                        <td>vphm01</td>
                                                        <td>xxxxx</td>
                                                        <td>Version</td>
                                                        <td>xxx</td>
                                                        <td>xxxxx</td>
                                                    </tr>
                                                    <tr class="active">
                                                        <td>vehicle</td>
                                                        <td>vphm01</td>
                                                        <td>xxxxx</td>
                                                        <td>Version</td>
                                                        <td>xxx</td>
                                                        <td>xxxxx</td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div>
                                        
                                        <div class="center_box">
                                            <div class="chart_info">
                                                <strong>Confusion Matrix with normalization</strong>
                                                <strong>Pipeline Id: {{conMatrixPipeId}}</strong>
                                                <strong>Pipeline Version: {{conMatrixPipeVersion}}</strong>
                                            </div>
                                            <div class="chart_box" @click="showModalChart">
                                                <div class="color-scale" ref="colorScale"></div>
                                                <svg style="width:340px; height:200px;" class="confusion_matrix" ref="confusionMatrix"></svg>
                                            </div>
                                        </div>
                                        
                                    </div>

                                </div>
                            </div>
                            <div class="data_list_box iminfo_item">
                                <strong>Inference_Model Version_info</strong> 
                                <div class="mando_table_wrap">
                                    <table class="mando_table">
                                        <thead>
                                            <tr>
                                                <th>Domain</th>
                                                <th>Task</th>
                                                <th>Pipeline Id</th>
                                                <th>Edge Name</th>
                                                <th>Model version</th>
                                                <th>Deploy Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr  v-for="(item, index) in imInfoList" :key="index">
                                                <td>{{item.domain}}</td>
                                                <td>{{item.task}}</td>
                                                <td>{{item.pipeline_id}}</td>
                                                <td>{{item.edge_name}}</td>
                                                <td>{{item.model_version}}</td>
                                                <td>{{item.deploy_date}}</td>
                                            </tr>
                                            <!-- <tr>
                                                <td>Smart Factory</td>
                                                <td>sfs01</td>
                                                <td>c-apne2-sfs01-iotdvc-00</td>
                                                <td>c-apne2-sfs01-cpipe-fv-p01-v1-r1</td>
                                            </tr> -->
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="modalWrap" ref="modalWrap" style="display: none;">
        <!-- 취소 -->
        <div class="radiusbox modal_contents add_item_modal" ref="modalChart">
            <div>
                <div class="center_box">
                    <div class="chart_info" style="margin-bottom: 50px;">
                        <strong>Confusion Matrix with normalization</strong>
                        <strong>Pipeline Id: {{conMatrixPipeId}}</strong>
                        <strong>Pipeline Version: {{conMatrixPipeVersion}}</strong>
                    </div>
                    <div class="chart_box">
                        <div class="color-scale" ref="colorScaleModal"></div>
                        <svg style="width:620px; height:400px;" class="confusion_matrix" ref="confusionMatrixModal"></svg>
                    </div>
                </div>
            </div>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';
    import common from '@/assets/js/common'

    import VCalendarUi from '@/components/VCalendarUi.vue';
    import { Chart } from 'chart.js';
    import * as d3 from 'd3';

    import mlops from '@/assets/js/mlops';


    export default {
        name: 'aaView',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            VCalendarUi,
            BreadcrumbLayout,
        },
        data(){
            const picStartDate = null;
            const picEndDate = null;
            const valueChart = null;
            return{
                // date select 
                dateSelectOption:'1',
                // -- VCalendarUi / .date_period
                picStartDate,
                picEndDate,
                picColor: {
                    startStatus: false,
                    endStatus: false,
                    active: '#00B4ED',
                    default: '#020A0A'
                },
                //---- filter
                fliterKey: {
                    awsIdVal: '',
                    taskVal: '',
                    pipIdVal: '',
                    pipVerMax: 30,
                    pipRunIdMax: 30,
                    pipVerStart: '0',
                    pipVerEnd: '30',
                    pipRunIdStart: '0',
                    pipRunIdEnd: '30',
                },
                isDragging: false,
                
                valueList: [],
                classList: [],
                conMatricList: [],
                imInfoList: [],
                // project
                prjId: "",
                domain: "",
                pipeId: "",
                modelVersion: "",
                pjtData: {},
                prjInfoToggle: false,

                valueChart,

                conMatrixPipeId: "",
                conMatrixPipeVersion: "",

                chartData: [],

                InferenceUtil: false,

                // role
                viewRole: false,
                manageRole: false,
            };
        },
        computed: {
            picStartTxtStyle() {
                return {
                    color: this.picColor.startStatus ? this.picColor.active : this.picColor.default
                };
            },
            picEndTxtStyle() {
                return {
                    color: this.picColor.endStatus ? this.picColor.active : this.picColor.default
                };
            },
            areRadiosSelected() {
            //   return this.fliterKey.awsIdVal !== '' && this.fliterKey.taskVal !== '' && this.fliterKey.pipIdVal !== '';
              return true;
            },
        },
        mounted() {
            // Role
            this.setRole();

            // set date 
            const vm = this;
            const stoday = new Date();
            stoday.setDate(stoday.getDate() - 1); 
            const syear = stoday.getFullYear();
            const smonth = stoday.getMonth() + 1;
            const sday = stoday.getDate();
            const start_formattedDate = syear + '-' + (smonth < 10 ? '0' : '') + smonth + '-' + (sday < 10 ? '0' : '') + sday;

            const etoday = new Date();
            const eyear = etoday.getFullYear();
            const emonth = etoday.getMonth() + 1;
            const eday = etoday.getDate();
            const end_formattedDate = eyear + '-' + (emonth < 10 ? '0' : '') + emonth + '-' + (eday < 10 ? '0' : '') + eday;
            
            vm.picStartDate = start_formattedDate;
            vm.picEndDate = end_formattedDate;
            window.selectDate.startDate = start_formattedDate;
            window.selectDate.endDate = end_formattedDate;
            //
            // filter ui
            // mlops.uiSlider('pipVer',vm.fliterKey.pipVerMax);
            // mlops.uiSlider('runId', vm.fliterKey.pipRunIdMax);
            // this.rengeUiData();

            //  ------ API CALL
            this.prjId = this.$route.query.prjId;
            this.domain = decodeURIComponent(this.$route.query.domain);
            this.pipeId = decodeURIComponent(this.$route.query.pipeId);
            this.modelVersion = decodeURIComponent(this.$route.query.modelVersion);

            this.setPrjView();
        },
        methods: {
            async setRole() {
                this.manageRole = await common.getUserRole('type1');
                this.viewRole = await common.getUserRole('type1');

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            async setPrjView() {
                const getPjtData = await this.getPrjInfo(this.prjId)
                this.pjtData = await getPjtData.project;
            },

            // Fillter Start-------- 
            uiAcodion(e){
                mlops.uiAcodion(e.target);
            },
            checkRadios(){
                if (this.areRadiosSelected) {
                    
                    // console.log('Radio select');

                    this.setTabs();

                    // 초기화
                    this.fliterKey.awsIdVal = '';
                    this.fliterKey.taskVal = '';
                    this.fliterKey.pipIdVal = '';
                    
                }
            },
            rengeUiData(){
                const vm = this;
                vm.isDragging =false;
                // ---- Pipeline Version
                vm.$refs.pvThumbLeft.addEventListener('mousedown', function() {
                    vm.isDragging = true;
                });
                vm.$refs.pvThumbLeft.addEventListener('mousemove', function() {
                    if (vm.isDragging) {
                        vm.fliterKey.pipVerStart = this.getAttribute('data-left');
                    }
                });
                vm.$refs.pvThumbLeft.addEventListener('mouseup', function() {
                    if (vm.isDragging) {
                        vm.isDragging = false;
                    }
                });
                vm.$refs.pvThumbRight.addEventListener('mousedown', function() {
                    vm.isDragging = true;
                });
                vm.$refs.pvThumbRight.addEventListener('mousemove', function() {
                    if (vm.isDragging) {
                        vm.fliterKey.pipVerEnd = this.getAttribute('data-right');
                    }
                });
                vm.$refs.pvThumbRight.addEventListener('mouseup', function() {
                    if (vm.isDragging) {
                        vm.isDragging = false;
                    }
                });
                // ---- Run ID
                vm.$refs.runThumbLeft.addEventListener('mousedown', function() {
                    vm.isDragging = true;
                });
                vm.$refs.runThumbLeft.addEventListener('mousemove', function() {
                    if (vm.isDragging) {
                        vm.fliterKey.pipRunIdStart = this.getAttribute('data-left');
                    }
                });
                vm.$refs.runThumbLeft.addEventListener('mouseup', function() {
                    if (vm.isDragging) {
                        vm.isDragging = false;
                    }
                });
                vm.$refs.runThumbRight.addEventListener('mousedown', function() {
                    vm.isDragging = true;
                });
                vm.$refs.runThumbRight.addEventListener('mousemove', function() {
                    if (vm.isDragging) {
                        vm.fliterKey.pipRunIdEnd = this.getAttribute('data-right');
                    }
                });
                vm.$refs.runThumbRight.addEventListener('mouseup', function() {
                    if (vm.isDragging) {
                        vm.isDragging = false;
                    }
                });

            },
             // select option
             dateSelectChange(){
                const vm = this;

                function dateSet(select){
                    if(select == 1){
                        const stoday1 = new Date();
                        stoday1.setDate(stoday1.getDate() - 1); 
                        const syear1 = stoday1.getFullYear();
                        const smonth1 = stoday1.getMonth() + 1;
                        const sday1 = stoday1.getDate();
                        const formattedDate1 = syear1 + '-' + (smonth1 < 10 ? '0' : '') + smonth1 + '-' + (sday1 < 10 ? '0' : '') + sday1;

                        vm.picStartDate = formattedDate1;
                        window.selectDate.startDate = formattedDate1;

                    }else if(select == 7){
                        const stoday7 = new Date();
                        stoday7.setDate(stoday7.getDate() - 7); 
                        const syear7 = stoday7.getFullYear();
                        const smonth7 = stoday7.getMonth() + 1;
                        const sday7 = stoday7.getDate();
                        const formattedDate7 = syear7 + '-' + (smonth7 < 10 ? '0' : '') + smonth7 + '-' + (sday7 < 10 ? '0' : '') + sday7;

                        vm.picStartDate = formattedDate7;
                        window.selectDate.startDate = formattedDate7;

                    }else if(select == 14){
                        const stoday14 = new Date();
                        stoday14.setDate(stoday14.getDate() - 14); 
                        const syear14 = stoday14.getFullYear();
                        const smonth14 = stoday14.getMonth() + 1;
                        const sday14 = stoday14.getDate();
                        const formattedDate14 = syear14 + '-' + (smonth14 < 10 ? '0' : '') + smonth14 + '-' + (sday14 < 10 ? '0' : '') + sday14;

                        vm.picStartDate = formattedDate14;
                        window.selectDate.startDate = formattedDate14;

                    }else if(select == 30){
                        const stoday30 = new Date();
                        stoday30.setDate(stoday30.getDate() - 30); 
                        const syear30 = stoday30.getFullYear();
                        const smonth30 = stoday30.getMonth() + 1;
                        const sday30 = stoday30.getDate();
                        const formattedDate30 = syear30 + '-' + (smonth30 < 10 ? '0' : '') + smonth30 + '-' + (sday30 < 10 ? '0' : '') + sday30;

                        vm.picStartDate = formattedDate30;
                        window.selectDate.startDate = formattedDate30;

                    }
                }

                switch (this.dateSelectOption) {
                    case "7":
                        dateSet("7");
                        break;
                    case "30":
                        dateSet("30");
                        break;
                    default:
                        dateSet("1");
                        break;
                }
            },
            // Fillter End-------- 
             // -------- DATE PICKER -----
             datePicToggle(){
                const vm = this;
                const _display = vm.$refs.calenderBox.style.display;
                const _startDateBox = vm.$refs.startDateBox.style.display;
                // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
                // console.log(_display);
                
                if(_display == 'block'){
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'none';
                    vm.picColor.startStatus = false;
                    vm.picColor.endStatus = false;

                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'block';
                    if(_startDateBox == 'block'){
                        vm.picColor.startStatus = true;
                        vm.picColor.endStatus = false;
                    }else if( _startDateBox == 'none'){
                        vm.picColor.startStatus = false;
                        vm.picColor.endStatus = true;
                    }
                }
                
                
                
            },
            onDatePic(flag){
                if(flag == 'S'){   
                    if(window.selectDate.startDate !== null ){
                        this.picStartDate = window.selectDate.startDate;
                    }
                    
                }else if(flag == 'E'){
                    if(window.selectDate.picEndDate !== null ){
                        this.picEndDate = window.selectDate.endDate;
                    }
                }
            },
            pickerNext(){
                this.$refs.startDateBox.style.display = 'none';
                this.$refs.endDateBox.style.display = 'block';

                this.picColor.startStatus = false;
                this.picColor.endStatus = true;
                // console.log(this.picColor.endStatus);
                // console.log(this.picColor.target);
                
            },
            pickerPrev(){
                this.$refs.startDateBox.style.display = 'block';
                this.$refs.endDateBox.style.display = 'none';

                this.picColor.startStatus = true;
                this.picColor.endStatus = false;
            },
            // ------------

            tabEvent(index){
                const $listTab = this.$refs.listTab;
                const $listTabBox = this.$refs.listTabBox;
                let $listTabItem = $listTab.querySelectorAll('li');
                let $listTabBoxItem = $listTabBox.querySelectorAll('.data_list_box');
                for(var i=0 ; i < $listTabItem.length ; i ++ ){
                    $listTabItem[i].classList.remove("active");
                    $listTabBoxItem[i].classList.remove("active");
                }
                $listTabItem[index].classList.add("active");
                $listTabBoxItem[index].classList.add("active");
            },

            async setTabs() {
                // const headers = {
                //     startTime: this.picStartDate + ' 00:00:00',
                //     endTime: this.picEndDate + ' 23:59:00',
                //     domain: this.fliterKey.awsIdVal,
                //     task: this.fliterKey.taskVal,
                //     pipelineId: this.fliterKey.pipIdVal,
                // };

                let pipelineId = "";
                if ( this.pjtData.aws_id == 'vphm01' ) {
                    pipelineId = "c-apne2-vphm01-mpipe-02";
                } else {
                    pipelineId = "c-apne2-sfs01-mpipe-fv-P01";
                }

                // 향후 수정 필요
                const headers = {
                    startTime: this.picStartDate + ' 00:00:00',
                    endTime: this.picEndDate + ' 23:59:00',
                    domain : this.pjtData.project_type,
                    task : this.pjtData.aws_id,
                    pipelineId : pipelineId,
                    modelversion : this.modelVersion
                };

                this.valueList = await this.getValueList(headers);
                this.classList = await this.getClassList(headers);
                this.conMatricList = await this.getConMatricList(headers);
                this.imInfoList = await this.getImInfoList(headers);

                this.setValueLineChart(this.valueList);
            },

            setValueLineChart(valueList) {
                const valueLineChart = this.$refs.valueLineChart;

                if ( this.valueChart !== null ) {
                    this.valueChart.destroy();
                }

                const labels = [];
                const datasets = [];

                const maeList = [];
                const mseList = [];
                const rmseList = [];
                const isList = [];
                const fidList = [];

                valueList.forEach(item => {
                    labels.push(item.run_id);
                    maeList.push(item.mae);
                    mseList.push(item.mse);
                    rmseList.push(item.rmse);
                    isList.push(item.is);
                    fidList.push(item.fid);
                });

                datasets.push({
                    label: 'MAE',
                    data: maeList,
                    borderColor: 'rgb(75, 192, 192)',
                });
                datasets.push({
                    label: 'MSE',
                    data: mseList,
                    borderColor: 'rgb(75, 23, 231)',
                });
                datasets.push({
                    label: 'RMSE',
                    data: rmseList,
                    borderColor: 'rgb(255, 0, 0)',
                });
                datasets.push({
                    label: 'IS',
                    data: isList,
                    borderColor: 'rgb(255, 51, 255)',
                });
                datasets.push({
                    label: 'FID',
                    data: fidList,
                    borderColor: 'rgb(51, 255, 255)',
                });

                let linedata =  {
                    labels: labels,
                    datasets: datasets
                }

                this.valueChart = new Chart(valueLineChart, {
                    type: 'line',
                    data: linedata,
                    options: {
                        scales: {
                            y: {
                                suggestedMin: 50,
                                suggestedMax: 100
                            }
                        }
                    }
                });
            },

            setValueMatricChart(chartData, _colorScale, _confusionMatrix) {
                 // Confusion Matrix 데이터
                const data = chartData;

                // 최대값과 최소값 계산
                const maxValue = d3.max(data, (row) => d3.max(row));
                const minValue = d3.min(data, (row) => d3.min(row));

                // 커스텀 색상 척도 생성
                const colorScale = d3.scaleSequential((t) => d3.interpolateReds(1 - t)).domain([minValue, maxValue]);

                // SVG 요소 크기 및 여백 설정
                const margin = { top: 30, right: 180, bottom: 30, left: 30 };
                const width = 395 - margin.left - margin.right;
                const height = 180 - margin.top - margin.bottom;

                // D3.js 스케일 설정
                const x = d3.scaleBand().domain(d3.range(data.length)).range([0, width]);
                const y = d3.scaleBand().domain(d3.range(data[0].length)).range([0, height]);

                // 커스텀 색상 척도 막대 바 생성
                const customColorScale = d3
                    .select(_colorScale)
                    .append('svg')
                    .attr('width', 60) // 레이블을 표시하기 위해 너비를 늘림
                    .attr('height', height)
                    .append('g')
                    .selectAll('g')
                    .data(d3.range(11))
                    .enter()
                    .append('g')
                    .attr('transform', (d) => `translate(0, ${(height / 10) * d})`);

                customColorScale
                    .append('rect')
                    .attr('x', 0)
                    .attr('width', 10)
                    .attr('height', height / 10)
                    .style('fill', (d) => colorScale((d / 10) * (maxValue - minValue) + minValue));

                customColorScale
                    .append('text')
                    .attr('x', 15) // 텍스트를 막대 바 오른쪽에 표시
                    .attr('y', height / 20) // 텍스트를 중앙에 정렬
                    .attr('dy', '0.35em')
                    .text((d) => d3.format('.1f')((d / 10) * (maxValue - minValue) + minValue))
                    .style('font-size', '10px');

                // SVG 요소 생성
                const svg = d3
                    .select(_confusionMatrix)
                    .append('svg')
                    .attr('width', width + margin.left + margin.right)
                    .attr('height', height + margin.top + margin.bottom)
                    .append('g')
                    .attr('transform', `translate(${margin.left},${margin.top})`);

                // Rect 요소를 사용하여 Confusion Matrix 시각화
                svg
                    .selectAll('rect')
                    .data(data.flat())
                    .enter()
                    .append('rect')
                    .attr('x', (d, i) => x(i % data.length))
                    .attr('y', (d, i) => y(Math.floor(i / data.length)))
                    .attr('width', x.bandwidth())
                    .attr('height', y.bandwidth())
                    .style('fill', (d) => colorScale(d));
                    

                
                svg
                    .selectAll('text')
                    .data(data.flat())
                    .enter()
                    .append('text')
                    .attr("dy", ".32em")
                    .attr('x', (d, i) => (x(i % data.length)) + (x.bandwidth() / 2) )
                    .attr('y', (d, i) => (y(Math.floor(i / data.length))) + (y.bandwidth() / 2) )
                    .text(function(d) { return d; })
                    .attr("text-anchor", "middle")
                    .style("fill", function(d){

                        const minVal = d3.min(data.flat());
                        const maxVal = d3.max(data.flat()); 
                    
                        const mp = (minVal + maxVal) / 2;
                        if(d > mp){
                        return "#000";
                        }else{
                        return "#fff";
                        }
                    });
                // 최대값과 최소값 눈금 생성
                svg
                    .append('text')
                    .attr('x', width + 10)
                    .attr('y', -5)
                    .text(`Max: ${maxValue.toFixed(1)}`)
                    .style('font-size', '10px');


                svg
                    .append('text')
                    .attr('x', width + 10)
                    .attr('y', height + 15)
                    .text(`Min: ${minValue.toFixed(1)}`)
                    .style('font-size', '10px');

                svg
                    .selectAll('rect')
                    .append('text')
                    .attr("dy", ".32em")
                    // .attr("x", x.bandwidth() / 2)
                    // .attr("y", y.bandwidth() / 2)
                    .attr('x', (d, i) => x(i % data.length))
                    .attr('y', (d, i) => y(Math.floor(i / data.length)))
                    .attr("text-anchor", "middle")
                    .style("fill", "black")
                    // .text(function(d, i) { return d; });
                    .text(function(d) { return d; });
            },

            setModalValueMatricChart(chartData, _colorScale, _confusionMatrix) {
                 // Confusion Matrix 데이터
                const data = chartData;

                // 최대값과 최소값 계산
                const maxValue = d3.max(data, (row) => d3.max(row));
                const minValue = d3.min(data, (row) => d3.min(row));

                // 커스텀 색상 척도 생성
                const colorScale = d3.scaleSequential((t) => d3.interpolateReds(1 - t)).domain([minValue, maxValue]);

                // SVG 요소 크기 및 여백 설정
                const margin = { top: 30, right: 180, bottom: 30, left: 30 };
                // const width = 395 - margin.left - margin.right;
                // const height = 180 - margin.top - margin.bottom;
                const width = 680 - margin.left - margin.right;
                const height = 400 - margin.top - margin.bottom;

                // D3.js 스케일 설정
                const x = d3.scaleBand().domain(d3.range(data.length)).range([0, width]);
                const y = d3.scaleBand().domain(d3.range(data[0].length)).range([0, height]);

                const scalesHeight = 400;
                // 초기화
                d3.select(_colorScale)
                .selectAll('svg')
                .remove();
                // 커스텀 색상 척도 막대 바 생성
                const customColorScale = d3
                    .select(_colorScale)
                    .append('svg')
                    .attr('width', 60) // 레이블을 표시하기 위해 너비를 늘림
                    .attr('height', scalesHeight)
                    .append('g')
                    .selectAll('g')
                    .data(d3.range(11))
                    .enter()
                    .append('g')
                    .attr('transform', (d) => `translate(0, ${(height / 10) * d})`);

                customColorScale
                    .append('rect')
                    .attr('x', 0)
                    .attr('width', 20)
                    .attr('height', scalesHeight / 10)
                    .style('fill', (d) => colorScale((d / 10) * (maxValue - minValue) + minValue));

                customColorScale
                    .append('text')
                    .attr('x', 30) // 텍스트를 막대 바 오른쪽에 표시
                    .attr('y', scalesHeight / 20) // 텍스트를 중앙에 정렬
                    .attr('dy', '0.35em')
                    .text((d) => d3.format('.1f')((d / 10) * (maxValue - minValue) + minValue))
                    .style('font-size', '16px');

                // SVG 요소 생성
                const svg = d3
                    .select(_confusionMatrix)
                    .append('svg')
                    .attr('width', width + margin.left + margin.right)
                    .attr('height', height + margin.top + margin.bottom)
                    .append('g')
                    .attr('transform', `translate(${margin.left},${margin.top})`);

                // Rect 요소를 사용하여 Confusion Matrix 시각화
                svg
                    .selectAll('rect')
                    .data(data.flat())
                    .enter()
                    .append('rect')
                    .attr('x', (d, i) => x(i % data.length))
                    .attr('y', (d, i) => y(Math.floor(i / data.length)))
                    .attr('width', x.bandwidth())
                    .attr('height', y.bandwidth())
                    .style('fill', (d) => colorScale(d));
                    

                
                svg
                    .selectAll('text')
                    .data(data.flat())
                    .enter()
                    .append('text')
                    .attr("dy", ".32em")
                    .attr('x', (d, i) => (x(i % data.length)) + (x.bandwidth() / 2) )
                    .attr('y', (d, i) => (y(Math.floor(i / data.length))) + (y.bandwidth() / 2) )
                    .text(function(d) { return d; })
                    .attr("text-anchor", "middle")
                    .style('font-size', '18px')
                    .style("fill", function(d){

                        const minVal = d3.min(data.flat());
                        const maxVal = d3.max(data.flat()); 
                    
                        const mp = (minVal + maxVal) / 2;
                        if(d > mp){
                        return "#000";
                        }else{
                        return "#fff";
                        }
                    });
                // 최대값과 최소값 눈금 생성
                svg
                    .append('text')
                    .attr('x', width + 10)
                    .attr('y', -5)
                    .text(`Max: ${maxValue.toFixed(1)}`)
                    .style('font-size', '18px');


                svg
                    .append('text')
                    .attr('x', width + 10)
                    .attr('y', height + 15)
                    .text(`Min: ${minValue.toFixed(1)}`)
                    .style('font-size', '18px');

                svg
                    .selectAll('rect')
                    .append('text')
                    .attr("dy", ".32em")
                    // .attr("x", x.bandwidth() / 2)
                    // .attr("y", y.bandwidth() / 2)
                    .attr('x', (d, i) => x(i % data.length))
                    .attr('y', (d, i) => y(Math.floor(i / data.length)))
                    .attr("text-anchor", "middle")
                    .style("fill", "black")
                    // .text(function(d, i) { return d; });
                    .text(function(d) { return d; });
            },

            // ----- click
            clickClss(event) {
                const id = event.target.value;
                const trEl = this.$refs['tr_class-' + id][0];
                const trStatus = trEl.getAttribute("class");

                if ( trStatus === 'item') {
                    trEl.classList.add('active');
                } else {
                    trEl.classList.remove('active');
                }
            },

            clickConMatrix(item, event) {
                const trEl = event.target.parentNode;
                const trStatus = trEl.getAttribute("class");

                const colorScale = this.$refs.colorScale;
                const confusionMatrix = this.$refs.confusionMatrix;
                colorScale.innerHTML = '';
                confusionMatrix.innerHTML = '';

                this.conMatrixPipeId = item['pipeline_id'];
                this.conMatrixPipeVersion = item['pipeline_version'];

                if ( trStatus === 'item') {
                    const tbody = trEl.parentNode;
                    const trList = tbody.getElementsByTagName("tr");
                    
                    [].forEach.call(trList, function(el) {
                        el.classList.remove("active");
                    });

                    trEl.classList.add('active');

                    const chartData = item['confusion_matrix'];
                    const colorScale = this.$refs.colorScale;
                    const confusionMatrix = this.$refs.confusionMatrix;
                    this.setValueMatricChart(chartData, colorScale, confusionMatrix);

                    this.chartData = chartData;
                } else {
                    this.conMatrixPipeId = "";
                    this.conMatrixPipeVersion = "";
                    trEl.classList.remove('active');
                }

            },

            clickTogglePrj() {
                const toggle = !this.prjInfoToggle;
                this.prjInfoToggle = toggle;
            },

            hideUtils() { // 부가기능 - 보이기 숨기기
                if(this.InferenceUtil == true) {
                    this.InferenceUtil = false;
                } else if(this.InferenceUtil == false){
                    this.InferenceUtil = true;
                }
            },
            async clipCoppy() { // 공유하기
                let dummy = document.createElement("textarea");
                document.body.appendChild(dummy);
                try {
                    const url = window.document.location.href;
                    dummy.value = url;
                    dummy.select();
                    document.execCommand("copy");
                    document.body.removeChild(dummy);
                    alert('url copy success')
                } catch(error) {
                    console.error(error);
                } 
            },

            // ----- Modal
            showModalChart() {
                if ( common.nullCheck(this.conMatrixPipeId) !== '' ) {
                    const $modal = this.$refs.modalWrap;
                    const $modalChart = this.$refs.modalChart;
                    $modal.style.display = "block";
                    $modalChart.style.display = "block";
    
                    const chartData = this.chartData;
                    const colorScale = this.$refs.colorScaleModal;
                    const confusionMatrix = this.$refs.confusionMatrixModal;
                    this.setModalValueMatricChart(chartData, colorScale, confusionMatrix);
                }
            },
            closeModal(){
                const $modal = this.$refs.modalWrap;
                const $modalChart = this.$refs.modalChart;
                $modal.style.display = "none";
                $modalChart.style.display = "none";
            },

            // ----- API
            async getPrjInfo(prjId) {
                try {
                    const response = await common.apiGet('/project/'+prjId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getValueList(headers) {
                try {
                    // const response = await common.apiDlGet('/dl/vehicle/plog-val', headers);
                    const response = await common.apiDlGet('/rds/vehicle/plog-val-mockup', headers);
                    return response.data.queryResult.results;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            
            async getClassList(headers) {
                try {
                    const response = await common.apiDlGet('/rds/vehicle/plog-cla', headers);
                    return response.data.queryResult.results;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getConMatricList(headers) {
                try {
                    const response = await common.apiDlGet('/rds/vehicle/plog-con', headers);
                    return response.data.queryResult.results;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getImInfoList(headers) {
                try {
                    const response = await common.apiDlGet('/rds/vehicle/inference', headers);
                    return response.data.queryResult.results;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getMemberRole() {
                let response;
                try {
                    let url = `/member/check-role`;
                    response = await common.apiGet(url);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },
            async getProjectRole() {
                let response;
                try {
                    let checkProjectRoleUrl = `/member/check-project-role/${this.prjId}`;
                    response = await common.apiGet(checkProjectRoleUrl);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },

        }
    }
</script>
<style scoped lang="scss">

.item_info{
    & > .float_box{
        width: 100%;
        height: 890px;
        margin-top: 20px;
        & > div{
            float: left;
        }
        .radiusbox{
            & > strong{
                width:100%;
                display: block;
                padding: 20px 0 0 20px;
                font-weight: $bold;

            }
        }
        .log_filter{
            width: 300px;
            height: 100%;
            background-color: $whiteColor;
            margin-right: 20px;
            box-sizing: border-box;
            position: relative;
            .log_filter_list{
                margin-top: 20px;
                & > li{
                    position: relative;
                    z-index: 1;
                    background-color: #fff;
                    border-bottom: 1px solid $line01Color;
                    &:last-child{
                        border: none;
                    }
                    & > button{
                        width: 100%;
                        padding: 20px;
                        display: block;
                        text-align: left;
                        font-weight: $bold;
                        position: relative;
                        background-repeat: no-repeat;
                        background-image: url($baseURL+'/common/select_arrowdown.svg');
                        background-position: 265px center;
                        
                    }
                    &.active{
                        z-index: 2;
                        // padding-bottom: 20px;
                        & > button{
                            background-image: url($baseURL+'/common/select_arrowup.svg');
                            background-image: none;
                        }
                        .log_filter_item{
                            height: auto;
                            overflow:visible;
                        }
                    }
                    .log_filter_item{
                        width: 100%;
                        height: 0;
                        overflow: hidden;
                        padding: 1px 20px 1px;
                        box-sizing: border-box;
                        &::after{
                            content: "";
                            display: block;
                            width: 100%;
                            height: 20px;
                        }
                        &.detail_set{
                            padding: 0;
                            li{
                                position: relative;
                                border-bottom: 1px solid $line01Color;
                                &:last-child{
                                    border-bottom: none;
                                }
                                strong{
                                    display: block;
                                    box-sizing: border-box;
                                    padding: 0 0 0 20px;
                                }
                                .renge_box{
                                    width: 100%;
                                    height: 60px;
                                    box-sizing: border-box;
                                    padding: 0 20px 0;
                                    margin: 10px 0;
                                }
                                .renge_val{
                                    position: absolute;
                                    top: 10px;
                                    right: 20px;
                                    color: $mainColor;
                                    span{
                                        color: $mainColor;
                                    }
                                }
                                .search_from{
                                    padding: 0 20px;
                                    margin: 20px 0;
                                    input{
                                        width: calc(100% - 55px);
                                    }
                                    .btn_search{
                                        width: 55px;
                                        right: 20px;
                                        
                                    }
                                }
                            }
                        }
                        .filter_box{
                            .date_filter{
                                position: relative;
                                padding: 0;
                                &::after{
                                    display: none;
                                }
                                .date_period{
                                    width: calc(100% - 40px);
                                    margin: 0;
                                    margin-top: 10px;
                                }
                            }
                        }
                        li{
                            padding: 10px 0;
                            &:last-child{
                                padding-bottom: 0;
                            }
                            label{
                                width: 100%;
                                height: 25px;
                                display: block;
                                line-height: 25px;
                                .radio_icon{
                                    position: relative;
                                    top: -2px;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
            &>button.mcbtn{
                // width: 60px;
                // height: 40px;
                // border-radius: 10px;                
                // position: absolute;
                // bottom: 20px;
                // right: 20px;

                width: 260px;
                height: 40px;
                border-radius: 10px;                
                margin: 0 auto;
                display: block;
                
            }
        }
        .log_data_box{  
            width: 956px;
            .list_tab{
                li{
                    width: 200px;
                    & > div{
                        text-indent: 0;
                    }
                }
            }
            .tab_data{
                width: 100%;
                height: 847px;
                .data_list_box{
                    strong{
                        width: 100%;
                        display: block;
                        box-sizing: border-box;
                        padding: 20px 0 0 20px;
                        font-weight: $bold;
                    }
                }
                .val_item{
                    .chart_box{
                        width: 100%;
                        height: 430px;
                        margin-bottom: 20px;
                    }
                    .mando_table_wrap{
                        .mando_table{
                            position: relative;
                        }
                    }
                }
                .iminfo_item{
                    .mando_table_wrap{
                        margin-top: 20px;
                        .mando_table{
                            position: relative;
                        }
                    }
                }
                .report_item{
                    & > .mando_table_wrap{
                        height: 790px;
                    }
                    .mando_table_wrap{
                        margin-top: 20px;
                        
                        .mando_table{
                            position: relative;
                            .accordion_list{
                                table tr{
                                    background-color: #FAFBFC;
                                    th{
                                        text-align: center;
                                    }
                                }
                                .item.active{
                                    background-color: #EDFBFF;
                                }
                                .item.active + .item_detail{
                                    display: table-row;
                                }
                                .item_detail{
                                    display: none;
                                    background-color: #FAFBFC;
                                     
                                    &:hover{
                                        background-color: #fff;
                                    }
                                    & > div {
                                        width: 100%;
                                        box-sizing: border-box;
                                        padding: 20px 50px;
                                    }
                                }
                            }
                        }
                    }
                }

                .matrix_item{
                    height: 100%;
                    .float_box{
                        height: 790px;
                        margin-top: 20px;
                        & > div{
                            height: 790px;
                            float: left;
                            &:first-child{
                                width: 560px;
                                .mando_table_wrap{
                                    table{
                                        table-layout: auto;
                                    }  
                                }
                            }
                            &:last-child{
                                width: 396px;
                                border-left: 1px solid $line01Color;
                                box-sizing: border-box;
                                .center_box{
                                    width: 100%;
                                    height: 260px;
                                    position: relative;
                                    top: 185px;
                                    .chart_info{
                                        width: 100%;
                                        height: 140px;
                                        position: absolute;
                                        top: -100px;
                                        left: 0;
                                    }
                                    .chart_box{
                                        width: 100%;
                                        height: 200px;
                                        margin-top: 20px;
                                        .color-scale{
                                            position: absolute;
                                            top:80px;
                                            left: 10px;
                                        }
                                        .confusion_matrix{
                                            position: absolute;
                                            top: 50px;
                                            left: 80px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .mando_table_wrap{
                        .mando_table{
                            position: relative;
                            table-layout: fixed;
                            thead th{
                                padding: 14px 0;
                                vertical-align: middle;
                            }
                            tbody{
                                tr.active{
                                    background-color: $mainColor;
                                    color: $whiteColor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
#modalWrap{
    .modal_contents{
        width: 880px;
        .center_box{
            strong{
                display: block;
                font-weight: $bold;
                margin-bottom: 10px;
            }
        }
        .chart_box{
            box-sizing: border-box;
            padding-left: 75px;
        }
    }
}

</style>