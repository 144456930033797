import axios from 'axios';
import Cookies from 'js-cookie';
import conf from '@/assets/js/conf'

// BFF
const jwt = Cookies.get('jwt');
const axiosInstance = axios.create({
  baseURL: conf.getBffBaseURL(),
  headers: {
    'Content-Type': 'application/json',
    // Include the JWT token in the Authorization header
    // Replace 'token' with the actual token you have stored
    'Authorization': `Bearer ${jwt}`,
  },
});

const axiosMultiInstance = axios.create({
  baseURL: conf.getBffBaseURL(),
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${jwt}`,
  },
});

const dlConfig = conf.getDlConfig();
const dlMDConfig = conf.getDlMDConfig();
const dlMBConfig = conf.getDlMBConfig();
const dlMFConfig = conf.getDlMFConfig();

const systemRoleConfig = {
  type1: ['SM', 'DM', 'TM', 'MM'],
  type2: ['SM', 'DM', 'TM'],
  type3: ['SM', 'DM'],
  type4: ['SM'],
}

const prjRoleConfig = {
  type1: [1, 2, 3],
  type2: [1, 2],
  type3: [1],
}

const dl = {
  headerSet(params) {
    let headerParams = params;
    headerParams['key'] = dlConfig.key;
    headerParams['role'] = dlConfig.role;
    headerParams['Authorization'] = `Bearer ${jwt}`;
  
    return headerParams;
  }
};

const dlMD = {
  headerSet(params, kind) {
    let headerParams = params;

    if (kind == 'D'){
      headerParams['X-API-Key'] = dlMDConfig.key;
    }else if(kind == 'B'){
      headerParams['X-API-Key'] = dlMBConfig.key;
    }else if(kind == 'F'){
      headerParams['X-API-Key'] = dlMFConfig.key;
    }else{
      headerParams['X-API-Key'] = dlMDConfig.key;
    }
  
    return headerParams;
  }
};

const latitude = 37.409345;
const longitude = 127.114304;

const common = {
    apiGet(url, params = {}) {
      return axiosInstance.get(url, { params });
    },
    apiPost(url, data = {}) {
      return axiosInstance.post(url, data);
    },
    apiPatch(url, data = {}) {
      return axiosInstance.patch(url, data);
    },
    apiDelete(url) {
      return axiosInstance.delete(url);
    },
    apiPostMulti(url, data = {}) {
      return axiosMultiInstance.post(url, data);
    },
    apiPatchMulti(url, data = {}) {
      return axiosMultiInstance.patch(url, data);
    },

    apiDlGet(url, params = {}) {
      const callUrl = dlConfig['baseURL'] + url;
      let headerParams = dl.headerSet(params);
      headerParams['key'] = dlConfig.key;

      return axios.get(callUrl, { headers: headerParams } )
    },

    apiDl2Get(url, params = {}, kind) {
      let callUrl = dlConfig['baseURL'] + url;
      let headerParams = dlMD.headerSet(params, kind);
      
      if (kind == 'D'){
        callUrl = dlMDConfig['baseURL'] + url;
      }else if(kind == 'B'){
        callUrl = dlMBConfig['baseURL'] + url;
      }else if(kind == 'F'){
        callUrl = dlMFConfig['baseURL'] + url;
      }
      
      return axios.get(callUrl, { headers: headerParams } )
    },

    apiDl2Post(url, params = {}, kind) {
      let callUrl = dlConfig['baseURL'] + url;
      let headerParams = dlMD.headerSet({}, kind);

      if (kind == 'D'){
        callUrl = dlMDConfig['baseURL'] + url;
      }else if(kind == 'B'){
        callUrl = dlMBConfig['baseURL'] + url;
      }else if(kind == 'F'){
        callUrl = dlMFConfig['baseURL'] + url;
      }
        
      return axios.post(callUrl, params, { headers: headerParams} )
    },

    getNowDt(type) {
      const today = new Date();
    
      if ( type == 'full' ) {
        return this.getFormTime(today);
      } else {
        return this.getFormDate(today);
      }
    },
    getEdgeStatusNm(type) {
      let statusNm = '';

      if(typeof type == "undefined" || type == null || type == "") {
        type = '';
      }

      if ( type == 'A' ) {
        statusNm = 'Active';
      } else if ( type == 'I' ) {
        statusNm = 'InActive';
      } else if ( type == 'R') {
        statusNm = 'Repare'
      } else if ( type == 'D') {
        statusNm = 'Delete'
      }

      return statusNm;
    },
    deleteRowData(targetList, compareList, key) {
      let i = targetList.length;
      
      while ( i-- ) {
          var targetRow = targetList[i];
          
          compareList.forEach(compareRow => {
            if ( targetRow[key] === compareRow[key] ) {
              targetList.splice(i, 1);
            }
          });
      }
      return targetList;
    },
    getUpper(value) {
      let returnValue = "";
      if(typeof value != "undefined" && value != null && value != "") {
        returnValue = value.toUpperCase();
      }
      return returnValue;
    },
    nullCheck(value) {
      let returnValue = "";
      if(typeof value != "undefined" && value != null && value != "") {
        returnValue = value;
      }
      return returnValue;
    },
    getDefaultLat() {
      return latitude;
    },
    getDefaultLong() {
      return longitude;
    },    
    sleep(delay) {
      var start = new Date().getTime();
      while (new Date().getTime() < start + delay);
    },
    getFormDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2,'0');
      const day = String(date.getDate()).padStart(2,'0');
      const formattedDate = year + '-' + month + '-' + day;
      return formattedDate;
    },
    getFormTime(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2,'0');
      const day = String(date.getDate()).padStart(2,'0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      const formattedDate = year + '-' + month + '-' + day+ ' ' + hours + ':' + minutes + ':' + seconds;
      return formattedDate;
    },
    setComma(value) {
      if ( typeof value === 'undefined' || value === null || value === '' ) {
        return 0;
      } else {
        const num = new Number(value);
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
      }
    },
    async getUserRole(type){
      const roles = systemRoleConfig[type];
      if ( this.nullCheck(roles) === '' ) {
        return false;
      }
      
      let role = '';
      try {
        const response = await this.apiGet('/member/check-role')
        if ( this.nullCheck(response) !== '' ) {
          role = response.data.data.role;
        } 
      } catch(error) {
        role = 'MM';
      }
      
      for ( let i = 0; i < roles.length; i++ ) {
        if ( roles[i] === role ) {
          return true;
        }
      }

      return false;
    },
    async getPrjRole(type, prjId) {
      const roles = prjRoleConfig[type];
      if ( this.nullCheck(roles) === '' ) {
        return false;
      }

      try {
        const response = await this.apiGet(`/member/check-project-role/${prjId}`)
        if ( this.nullCheck(response) !== '' ) {
          const role = response.data.data.projectRoleId;
          
          for ( let i = 0; i < roles.length; i++ ) {
            if ( roles[i] === role ) {
              return true;
            }
          }
        }

        return false;
      } catch(error) {
        return false;
      }
    },
    goHome() {
      location.href="/home";
    },
    goBack() {
      window.history.back();
    },
    async getUserInfo() {
      try {
        const response = await this.apiGet('/member/check-role')
        if ( this.nullCheck(response) !== '' ) {
          return response.data.data;
        }
      } catch(error) {
        return null;
      }
    },
    async loginCheck() {
      const user = Cookies.get('user');
      if ( typeof user !== 'undefined' && user !== null && user !== '' ) {
        location.href="/home";
      }
    },
};



//https://athena.dlfe.dev.micosa.io/dl/can/catalog
// DL 
// const axiosInstanceDL = axios.create({
//   baseURL: 'https://athena.dlfe.dev.micosa.io',
//   headers: {
//     'Content-Type': 'application/json',
//     // Include the JWT token in the Authorization header
//     // Replace 'token' with the actual token you have stored
//     'Authorization': `Bearer ${jwt}`,
//   },
// });

// const dlcommon = {
//   apiGet(url, params = {}) {
//     const axiosInstanceWithHeaders = axios.create(axiosInstanceDL.defaults);

//     // axiosInstanceWithHeaders.defaults.headers['key'] = 'Value';
//     axiosInstanceWithHeaders.defaults.headers['key'] = 'mando';
//     axiosInstanceWithHeaders.defaults.headers['role'] = 'arn:aws:iam::802844773267:role/c-apne2-dlfe-role-13-query_demo';
//     axiosInstanceWithHeaders.defaults.headers['startTime'] = '2023-09-05 08:35:00';
//     axiosInstanceWithHeaders.defaults.headers['endTime'] = '2023-09-05 08:36:00';
//     axiosInstanceWithHeaders.defaults.headers['vehicle'] = 'd-uset1-maici-fwvhcl-jk1ev-orin01-01';
//     console.log(axiosInstanceWithHeaders.defaults.headers);

//     return axiosInstanceWithHeaders.get(url, { params });
//   },
//   apiPost(url, data = {}) {
//     return axiosInstanceDL.post(url, data);
//   },
//   apiPatch(url, data = {}) {
//       return axiosInstanceDL.patch(url, data);
//     },
//   apiDelete(url) {
//   return axiosInstanceDL.delete(url);
//   }
// }

// // VPHM01 Backend API
// const axiosInstanceVphm01 = axios.create({
//   baseURL: 'https://wy5is4458a.execute-api.ap-northeast-2.amazonaws.com',
//   headers: {
//     'Content-Type': 'application/json',
//     // Include the JWT token in the Authorization header
//     // Replace 'token' with the actual token you have stored
//     'X-API-Key': 'vehicle_key_1'
//   },
// });

export default common ;