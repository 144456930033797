<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap" v-bind:style="taskId === '' ? '' : 'display:none'">
                <div class="breadcrumb">
                    <span>데이터/ TASK 운영</span>
                    <span>모니터링 TASK 목록</span>
                    <span>데이터 상세 </span>
                    <span class="active">모니터링 TASK - 신규 등록</span>
                </div>
                <h2>모니터링 TASK - 신규 등록</h2>
            </div>
            <div class="title_wrap" v-bind:style="taskId === '' ? 'display:none' : ''">
                <div class="breadcrumb">
                    <span>데이터/ TASK 운영</span>
                    <span>모니터링 TASK 목록</span>
                    <span>데이터 상세 </span>
                    <span class="active">모니터링 TASK 관리</span>
                </div>
                <h2>모니터링 TASK 관리</h2>
            </div> -->
            <BreadcrumbLayout :pageId="pageId" :subParams="bcParams" :subItems="bcSubItems"/>
            <div id="management">
                <div class="row_box">
                    <div class="data_key">프로젝트 선택</div>
                    <div class="data_val">
                        
                        <input v-if="hasPrjId" type="text" name="prjNm" ref="prjNm" :value="prjNm" disabled>

                        <select v-else name="prjId" ref="prjId" :value="prjId" @change="setPrjId($event)" style="width: 100%; background-position: calc(100% - 20px) center;">
                            <option value="" disabled selected>프로젝트를 선택해 주세요.</option>
                            <option v-for="(item, index) in prjList" :key="index" :value="item.id">
                                {{item.projectName}}
                            </option>

                        </select>

                        
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">모니터링 TASK 이름</div>
                    <div class="data_val" v-bind:class="{ failure : dupMonTaskNm}">
                        <input type="text" name="monTaskNm" ref="monTaskNm" v-model="monTaskNm" maxlength="50" placeholder="이름을 입력해 주세요.">
                        <div class="text_message" v-if="dupMonTaskNm">중복된 모니터링 TASK 이름입니다.</div>
                        <div class="text_length">
                            <span>{{monTaskNmLen}}</span>/50
                        </div>
                    </div>
                </div>
                <div class="row_box two_item">
                    <div>
                        <div class="data_key">등록자명</div>
                        <div class="data_val">
                            <input type="text" name="rgstNm" id="rgstNm" :value="rgstNm" disabled>
                        </div>
                    </div>
                    <div>
                        <div class="data_key">등록 일자</div>
                        <div class="data_val">
                            <input type="text" name="rgstDt" id="rgstDt" :value="rgstDt" disabled>
                        </div>
                    </div>
                </div>
                <div class="row_box two_item">
                    <div>
                        <div class="data_key">TASK 접근성</div>
                        <div class="data_val">
                            <select name="monTaskAccess" ref="monTaskAccess" :value="monTaskAccessId" @change="setMonTaskAccess($event)" style="width: 100%; background-position: calc(100% - 20px) center;">
                                <!-- <option disabled selected>Confidential</option> -->
                                <option value="" disabled selected>선택해주세요.</option>
                                <option v-for="(item, index) in monTaskAccessList" :key="index" :value="item.id">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <div class="data_key">모니터링 TASK 유형</div>
                        <div class="data_val">
                            <select name="monTaskType" ref="monTaskType" :value="monTaskTypeId" @change="setMonTaskType($event)" style="width: 100%; background-position: calc(100% - 20px) center;">
                                <!-- <option disabled selected>Vision Edge Inference Monitoring</option> -->
                                <option value="" disabled selected>선택해주세요.</option>
                                <option v-for="(item, index) in monTaskTypeList" :key="index" :value="item.id">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <!-- <div class="row_box two_item toggle_item"> -->
                <div class="row_box toggle_item">
                    <!-- <div>
                        <div class="data_key">TASK 운영기간</div>
                        <div class="data_val filter_box">

                            <div class="date_filter">
                                <div class="date_period">
                                    <div class="date_box">
                                        <span style="display:inline-block ; padding : 0 5px 0 5px;" :style="picStartTxtStyle" ref="picStartTxt">{{ picStartDate }}</span> ~ <span style="display:inline-block ; padding: 0 0 0 5px;" :style="picEndTxtStyle" ref="picEndTxt">{{picEndDate}}</span>
                                    </div>


                                    <button type="button" ref="datePicToggle" @click="datePicToggle()"></button>
                                    <div class="calender_box radiusbox" ref="calenderBox" style="display:none">
                                        <div class="start_date date_box" ref="startDateBox" @click="onDatePic('S')" style="display:block">
                                            <VCalendarUi :dateInterface="'start'" :maxDate="maxDate" :setDate="picStartDate"/>
                                            <button type="button" @click="pickerNext()">다음</button>
                                        </div>
                                        <div class="end_date date_box" ref="endDateBox" @click="onDatePic('E')">
                                            <VCalendarUi :dateInterface="'end'" :minDate="picStartDate" :maxDate="maxDate" :setDate="picEndDate"/>
                                            <button type="button" @click="pickerPrev()">이전</button>
                                            <button type="button" class="mcbtn" @click="datePicToggle()">완료</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div>
                        <div class="data_key">Permission Setting</div>
                        <div class="data_val">
                            <ul>
                                <li>
                                    원격제어
                                    <div class="toggle_box">
                                        <input type="checkbox" name="remoteControl" id="remoteControl" value="remoteControl" v-model="permSetChecked">
                                        <label for="remoteControl"></label>
                                    </div>
                                </li>
                                <li>
                                    데이터 외부 공유
                                    <div class="toggle_box">
                                        <input type="checkbox" name="externalShare" id="externalShare" value="externalShare" v-model="permSetChecked">
                                        <label for="externalShare"></label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row_box textarea_item">
                    <div class="data_key">Description</div>
                    <div class="data_val">
                        <textarea name="" ref="desc" v-model="desc" maxlength="200" placeholder="입력해주세요."></textarea>
                        <div class="text_length">
                            <span>{{descLen}}</span>/200
                        </div>
                    </div>
                </div>
                <div class="row_box add_item">
                    <div class="data_key">
                        Edge Device
                    </div>
                    <div class="data_val active">
                        <div class="add_box table_list">
                            <div class="total_item">
                                <span>{{ mainEdgeDeviceList.length }} </span> device
                            </div>
                            <button class="btn_add_item" v-bind:style="prjId === '' ? 'display:none' : ''" @click="showModalDevice()"></button>
                            <div class="none_item" v-bind:style="mainEdgeDeviceList.length > 0 ? 'display:none' : ''">
                                <div>Edge Device가 없습니다.</div>
                                <button class="btn_add_item" v-bind:style="prjId === '' ? 'display:none' : ''" @click="showModalDevice()">Edge 추가</button>
                            </div>
                            <div class="mando_table_wrap" v-bind:style="mainEdgeDeviceList.length > 0 ? '' : 'display:none'">
                                <table class="mando_table">
                                    <colgroup>
                                        <col style="width: 45%;">
                                        <col style="width: 18%;">
                                        <col style="width: 15%;">
                                        <col style="width: 12%;">
                                        <col style="width: 10%;">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div class="col_name">
                                                    Edge ID
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Edge Group
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Edge Name
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Status
                                                </div>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in mainEdgeDeviceList" :key="index">
                                            <td>
                                                <div class="profile_img_box">
                                                    <img src="@/assets/images/common/vehicle_sample.svg" alt="">
                                                </div>
                                                <span class="user_name">{{item.edgeDeviceId}}</span>
                                            </td>
                                            <td>
                                                <div class="profile_img_box">
                                                    <img src="@/assets/images/common/gateway_sample.svg" alt="">
                                                </div>
                                                <span class="user_name">{{item.edgeDeviceGroup}}</span>
                                            </td>
                                            <td>
                                                {{item.edgeDeviceName}}
                                            </td>
                                            <td>
                                                {{item.status}}
                                            </td>
                                            <td>
                                                <button type="button" class="btn_del" :data-index="item.id" @click="clickDelEdgeList(item.id)"></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="save_box">
                    <button type="button" @click="showModalAlert()">취소</button>
                    <button type="button" class="mcbtn" :disabled="!requireCheck" @click="clickRegist()">저장</button>
                </div>
            </div>
        </div>
    </div>
    <div id="modalWrap" ref="modalWrap" style="display: none;">
        <!-- 취소 -->
        <div class="radiusbox modal_contents message_alert message_modal" ref="modalAlert">
            <div class="message_box">
                변경 사항이 저장되지 않았습니다. <br>정말 취소하시겠습니까?
            </div>
            <button type="button" class="btn_check mcbtn" @click="backPage()">확인</button>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
        <!-- Edge Device 추가 -->
        <div class="radiusbox modal_contents add_item_modal add_edge_device" ref="modalDevice" style="display : none;">
            <strong class="modal_title">
                Edge Device 항목 추가
            </strong>
            <div class="filter_box">
                <input type="text" name="edgeKeyword" ref="edgeKeyword" @keydown.enter="clickSearchDevice()" placeholder="ID, 이름, 상태값 검색가능">
                <button type="button" class="btn_search" @click="clickSearchDevice()"></button>
            </div>
            <div class="float_box table_list">
                <div class="select_box mando_table_wrap">
                    <table class="mando_table">
                        <colgroup>
                            <col style="width: 60%;">
                            <col style="width: 20%">
                            <col style="width: 20%">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <div class="all_chk">
                                        <input type="checkbox" id="allChkEdge" v-model="allSelctEdge">
                                        <label for="allChkEdge"></label>
                                        <span>Edge ID</span>
                                    </div>
                                </th>
                                <th>Edge Name</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody id="edgeTbody">
                            <tr v-for="(item, index) in edgeDeviceRowData" :data-index="index" :key="index">
                                <td>
                                    <div class="chk_item">
                                        <input type="checkbox" :id="'EdgeItem' + item.id" :value="item" v-model="unSelectEdgeList">
                                        <label :for="'EdgeItem' + item.id"></label>
                                        <div class="text_box">
                                            <div class="group_path" data-index="edgeDeviceGroup">
                                                {{item.edgeDeviceGroup}}
                                            </div>
                                            <div class="edge_id" data-index="edgeDeviceId">
                                                {{item.edgeDeviceId}}
                                            </div> 
                                        </div>
                                    </div>
                                </td>
                                <td data-index="edgeDeviceName">{{item.edgeDeviceName}}</td>
                                <td data-index="status">{{item.status}}</td>
                                <td data-index="id" style="display:none">{{item.id}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="choose_box">
                    <div class="btn_wrap">
                        <button type="button" class="btn_add" @click="clickAddDevice()">추가</button>
                        <button type="button" class="btn_add_del" @click="clickDelDevice()">삭제</button>
                    </div>
                </div>
                <div class="select_box">
                    <div class="table_title">
                        <strong>선택 항목</strong>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <colgroup>
                                <col style="width: 60%">
                                <col style="width: 20%">
                                <col style="width: 20%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="all_chk">
                                            <input type="checkbox" id="allChkSelectedEdge" v-model="allSelctedEdge">
                                            <label for="allChkSelectedEdge"></label>
                                            <span>Edge ID</span>
                                        </div>
                                    </th>
                                    <th>Edge Name</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody id="selectEdgeTbody">
                                <tr v-for="(item, index) in selectEdgeDeviceRowData" :data-index="index" :key="index">
                                    <td>
                                        <div class="chk_item">
                                            <input type="checkbox" :id="'EdgeSelectedItem' + item.id" :value="item" v-model="selectedEdgeList">
                                            <label :for="'EdgeSelectedItem' + item.id"></label>
                                            <div class="text_box">
                                                <div class="group_path" data-index="edgeDeviceGroup">
                                                    {{item.edgeDeviceGroup}}
                                                </div>
                                                <div class="edge_id" data-index="edgeDeviceId">
                                                    {{item.edgeDeviceId}}
                                                </div> 
                                            </div>
                                        </div>
                                    </td>
                                    <td data-index="edgeDeviceName">{{item.edgeDeviceName}}</td>
                                    <td data-index="status">{{item.status}}</td>
                                    <td data-index="id" style="display:none">{{item.id}}</td>
                                </tr>
                                <tr class="hover_none" v-bind:style="selectEdgeDeviceRowData.length > 0 ? 'display:none' : ''">
                                    <td colspan="3">
                                        <div class="none_itme">
                                            <div class="message">
                                                Edge Device를 추가 해 주세요.
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="check_wrap">
                <button type="button" class="btn_check mcbtn" @click="clickCompleteDevice()" v-bind:disabled="selectEdgeDeviceRowData.length === 0">완료</button>    
            </div>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    // import VCalendarUi from '@/components/VCalendarUi.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';
    import common from '@/assets/js/common'
    import { mapGetters } from 'vuex';

    export default {
        name: 'aaView',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            // VCalendarUi,
            BreadcrumbLayout,
        },
        computed:{
            ...mapGetters(['getUserData']),

            monTaskNmLen() {
                return this.monTaskNm.length;
            },
            descLen() {
                return this.desc.length;
            },

            allSelctEdge: {
                //getter
                get: function() {
                    return this.edgeDeviceRowData.length === this.unSelectEdgeList.length;
                },
                //setter
                set: function(e) {
                    this.unSelectEdgeList = e ? this.edgeDeviceRowData : [];
                },
            },
            allSelctedEdge: {
                //getter
                get: function() {
                    return this.selectEdgeDeviceRowData.length === this.selectedEdgeList.length;
                },
                //setter
                set: function(e) {
                    this.selectedEdgeList = e ? this.selectEdgeDeviceRowData : [];
                },
            },

            requireCheck() {
                return this.manageRole && this.prjId !=='' && this.monTaskNmLen > 0 && this.monTaskAccessId !== '' && this.monTaskTypeId !== '';
            },

            // VCalendar 
            picStartTxtStyle() {
                return {
                    color: this.picColor.startStatus ? this.picColor.active : this.picColor.default
                };
            },
            picEndTxtStyle() {
                
                return {
                    color: this.picColor.endStatus ? this.picColor.active : this.picColor.default
                };
            },
        },
        data(){
            const userData = ({});

            const picStartDate = null;
            const picEndDate = null;

            const edgeDeviceRowData = ([]);
            const selectEdgeDeviceRowData= ([]);
            
            return{
                userData,

                // -- VCalendarUi / .date_period
                maxDate: "9999-12-31",
                picStartDate,
                picEndDate,
                picColor: {
                    startStatus: false,
                    endStatus: false,
                    active: '#00B4ED',
                    default: '#020A0A'
                },


                prjList: [],
                prjId: "",
                prjNm: "",
                monTaskAccessList: [],
                monTaskTypeList: [],
                hasPrjId: false,

                taskId: "",
                monTaskNm: "",
                monTaskAccessId: "",
                monTaskTypeId: "",

                desc: "",
                rgstNm: "",
                rgstDt: "",

                permSetChecked: [],


                edgeDeviceRowData,
                selectEdgeDeviceRowData,

                unSelectEdgeList: [],
                selectedEdgeList: [],
                mainEdgeDeviceList: [],

                // breadcrumb
                pageId: "monTASK_addMonTask",
                bcParams: [],
                bcSubItems: [],

                originMonTaskNm: "",
                dupMonTaskNm: false,

                // role
                viewRole: false,
                manageRole: false,
            };
        },
        async mounted() {
            // Role
            await this.setRole();

            const prjId = this.$route.query.prjId;

            if ( typeof prjId !== "undefined" && prjId !== null && prjId !== "" ) {
                this.prjId = prjId;
                this.hasPrjId = true;
            }

            const getCKUser = this.getUserData;
            const getUser = this.$store.state.user;
            if(getCKUser == undefined){
                this.userData = getUser;
            }else{
                this.userData = getCKUser;
            }


            const taskId = this.$route.query.taskId;
            if ( typeof taskId != "undefined" && taskId != null && taskId != "" ) {
                this.taskId = taskId;
                await this.setPage();
                this.pageId = "monTASK_modMonTask";
                await this.setBreadcrumb();
            } else {
                this.pageId = "monTASK_addMonTask";
                this.rgstNm = this.userData.name + ' | ' + common.nullCheck(this.userData.teamName);
                this.rgstDt = common.getNowDt();

                const start_formattedDate = common.getNowDt();
                const end_formattedDate = common.getNowDt();
                
                this.picStartDate = start_formattedDate;
                this.picEndDate = end_formattedDate;
                window.selectDate.startDate = start_formattedDate;
                window.selectDate.endDate = end_formattedDate;
            }

            await this.setSelectBox();
            await this.setPrjSelect();

            // this.checkAccess();
        },
        methods: {
            async setRole() {
                const prjId = this.$route.query.prjId;
                
                if(typeof prjId !== "undefined" && prjId !== null && prjId !== "") {
                    this.manageRole = ( await common.getUserRole('type2') || await common.getPrjRole('type2', prjId) );
                    this.viewRole = ( await common.getUserRole('type2') || await common.getPrjRole('type2', prjId) );
                } else {
                    this.manageRole = await common.getUserRole('type1');
                    this.viewRole = await common.getUserRole('type1');
                }

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            setBreadcrumb() {
                const taskTypeId = this.$route.query.taskTypeId;
                let name = "";
                let url = "";

                switch(taskTypeId) {
                    // Remote Diagnostics
                    case '1': 
                        url = 'dctDashboard';
                        name = 'Remote Diagnostic Service 대시보드';
                        break;
                    // Anomaly Detection
                    case '2': 
                        url = 'moaDetection';
                        name = 'Motor Output Anomaly Detection 상세';
                        break;
                    // Vision Edge Inference Monitoring
                    case '3': 
                        url = 'EdgeInfer';
                        name = 'Edge Inference 모니터링';
                        break;
                    // Vision Auto Labeling Monitoring
                    case '4': 
                        url = 'AutoLabe';
                        name = 'Auto Labeling 검토 상세';
                        break;
                    // Vision AI/ML Statistics
                    case '5': 
                        url = 'VisionData';
                        name = 'Vision 데이터 처리 통계';
                        break;
                    // Vision AI/ML Statistics
                    case '6': 
                        url = 'SmartMiningDashboard';
                        name = 'Smart Mining Dashboard';
                        break;
                }

                this.bcSubItems.push({
                    name: name,
                    url: url,
                });

                this.bcParams.push({
                    prjId: this.prjId, 
                    taskId: this.taskId, 
                    // teamId: item.teamId,
                    taskTypeId: taskTypeId,
                });

                
            },

            async setPage() {
                const taskId = this.taskId;
                console.log(this.viewRole);
                const data = await this.getTaskData(taskId, this.viewRole);

                if ( typeof data != "undefined" && data != null && data != "" ) {
                    this.monTaskNm = data.taskName;
                    this.rgstNm = data.creatorName + " | " + data.teamName;
                    this.rgstDt = data.createDate;

                    this.originMonTaskNm = data.taskName;

                    this.monTaskAccessId = data.accessibilityId;
                    this.monTaskTypeId = data.typeId;

                    this.picStartDate = data.startTime;
                    this.picEndDate = data.endTime;
                    window.selectDate.startDate = data.startTime;
                    window.selectDate.endDate = data.endTime;
                    
                    if ( data.remoteControl === 'Y' ) {
                        this.permSetChecked.push('remoteControl');
                    }
                    
                    if ( data.dataSharing === 'Y' ) {
                        this.permSetChecked.push('externalShare');
                    }

                    this.desc = data.description

                    const edgeDeviceList = data.edgeDeviceList;
                    this.mainEdgeDeviceList = this.setDeviceData(edgeDeviceList);
                }
            },

            async setPrjSelect() {
                // 231012   
                // this.prjId 데이터가 있는지 구분자
                let prjData;
                if(this.prjId){
                    prjData = await this.getPrjData(this.prjId);
                    if(typeof prjData !== "undefined" && prjData !== null && prjData !== "") {
                        this.prjNm = prjData.project.project_name;
                    }
                }else{
                    prjData = await this.getPrjListData();
                    this.prjList = prjData;
                }
            },
             
            async setSelectBox() {
                this.monTaskAccessList = await this.getMonTaskAccessList();
                this.monTaskTypeList = await this.getMonTaskTypeList();
            },

            setPrjId(event) {
                
                this.prjId = event.target.value;

                this.selectEdgeDeviceRowData = ([]);
                this.mainEdgeDeviceList = [];

            },

            setMonTaskAccess(event) {
                this.monTaskAccessId = event.target.value;
            },

            setMonTaskType(event) {
                this.monTaskTypeId = event.target.value;
            },

            // ----- click
            async clickSearchDevice() {
                const keyword = this.$refs.edgeKeyword.value.toUpperCase();

                if(typeof keyword == "undefined" || keyword == null || keyword == "") {
                    const prjId = this.prjId;
                    const getDeviceList = this.getDeviceList(prjId);
                    this.setDeviceTable(getDeviceList);

                } else {
                    this.setSearchDevice(keyword);
                }
            },

            setSearchDevice(keyword) {
                const searchRow = [];
    
                this.edgeDeviceRowData.forEach(item => {
                    let edgeDeviceId = common.getUpper(item['edgeDeviceId']);
                    let edgeDeviceName = common.getUpper(item['edgeDeviceName']);
                    let status = common.getUpper(item['status']);

                    if ( edgeDeviceId.includes(keyword) || edgeDeviceName.includes(keyword) || status.includes(keyword) ) {
                        searchRow.push(item);
                    }
                });

                this.edgeDeviceRowData = ([]);

                searchRow.forEach(item => {
                    this.edgeDeviceRowData.push(item);
                });
            },
            
            clickAddDevice() {
                if ( this.unSelectEdgeList.length > 0 ) {
                    this.unSelectEdgeList.forEach(item => {
                        this.selectEdgeDeviceRowData.push(item);
                    });

                    this.edgeDeviceRowData = common.deleteRowData(this.edgeDeviceRowData, this.unSelectEdgeList, 'id');
                    this.unSelectEdgeList = [];
                }
            },
            
            clickDelDevice() {
                if ( this.selectedEdgeList.length > 0 ) {
                    this.selectedEdgeList.forEach(item => {
                        this.edgeDeviceRowData.push(item);
                    });
                    
                    this.selectEdgeDeviceRowData = common.deleteRowData(this.selectEdgeDeviceRowData, this.selectedEdgeList, 'id');
                    this.selectedEdgeList = [];

                    const keyword = this.$refs.edgeKeyword.value.toUpperCase();
                    if ( typeof keyword != "undefined" && keyword != null && keyword != "" ) {
                        this.setSearchDevice(keyword);
                    }
                }
            },

            clickCompleteDevice() {
                this.mainEdgeDeviceList = [];
                this.selectEdgeDeviceRowData.forEach(item => {
                    this.mainEdgeDeviceList.push(item);
                });
                this.closeModal();
            },
            
            clickDelEdgeList(id) {
                let i = this.mainEdgeDeviceList.length;
                while ( i-- ) {
                    let item = this.mainEdgeDeviceList[i];
                    if ( item.id == id ) {
                        this.mainEdgeDeviceList.splice(i, 1);
                        this.edgeDeviceRowData.push(item);
                    }
                }
                let j = this.selectEdgeDeviceRowData.length;
                while ( j-- ) {
                    let item = this.selectEdgeDeviceRowData[j];
                    if ( item.id == id ) {
                        this.selectEdgeDeviceRowData.splice(j, 1);
                    }
                }
            },

            async clickRegist() {
                const allowRegist = await this.checkAllowAccessCreateBtn();

                if ( this.requireCheck === true && allowRegist) {
                    const monTaskNm = this.$refs.monTaskNm.value;
                    if ( this.originMonTaskNm !== monTaskNm && this.monTaskNmLen > 0 ) {
                        const monTaskNmCheckParam = {
                            "name": monTaskNm,
                        };
                        const checkMonTaskNmResult = await this.checkMonTaskNm(monTaskNmCheckParam);

                        this.dupMonTaskNm = await this.getDupResult(checkMonTaskNmResult);
                        if ( this.dupMonTaskNm ) {
                            this.$refs.monTaskNm.focus();
                        }
                    }

                    if ( !this.dupMonTaskNm ) {
                        const edgeDeviceIds = [];
                        this.mainEdgeDeviceList.forEach(item => {
                            edgeDeviceIds.push(item.id);
                        });
        
                        const postData = {
                            "project_id": this.prjId,
                            "name": this.$refs.monTaskNm.value,
                            "monitoring_task_accessibility_id": this.$refs.monTaskAccess.value,
                            "monitoring_task_type_id": this.$refs.monTaskType.value,
                            "start_time": this.picStartDate,
                            "end_time": this.picEndDate,
                            "remote_control": await this.getCheckYn(this.permSetChecked, 'remoteControl'),
                            "data_sharing": await this.getCheckYn(this.permSetChecked, 'externalShare'),
                            "description": this.$refs.desc.value,
                            "status": "",
                            "monitoringTaskEdgeDeviceRequestDtoList": edgeDeviceIds
                        };
        
                        let responseCode = "";
                        let taskId = this.taskId;
    
                        // console.log(postData)
    
                        if ( taskId != '' && taskId > 0 ) {
                            const response = await this.updateMonTask(taskId, postData)
                            try {
                                responseCode = response['data']['code'];
                            } catch (error) {
                                responseCode = 500;
                            }
                        } else {
                            const response = await this.addMonTask(postData);
                            try {
                                responseCode = response['data']['code'];
                                taskId = response['data']['data'];
                            } catch (error) {
                                responseCode = 500;
                            }
                        }
        
                        if ( responseCode === 200 || responseCode === "200" ) {
                            this.goTask(taskId);
                        }
                    }

                } else if(!allowRegist) {
                    alert("SM, Project Manager, Project Researcher만 monitoring task 등록이 가능합니다.");
                    return;
                }

            },

            goTask(taskId) {
                const taskType = this.$refs.monTaskType.value;

                const query= {
                    prjId: this.prjId, 
                    taskId: taskId, 
                    // teamId: item.teamId,
                    taskTypeId: this.$refs.monTaskType.value
                } 

                switch(taskType) {
                    // Remote Diagnostics
                    case '1': 
                        this.$router.push({
                            name: 'dctDashboard', query
                        });
                        break;
                    // Anomaly Detection
                    case '2': 
                        this.$router.push({
                            name: 'moaDetection' , query
                        });
                        break;
                    // Vision Edge Inference Monitoring
                    case '3': 
                        this.$router.push({
                            name: 'EdgeInfer', query
                        });
                        break;
                    // Vision Auto Labeling Monitoring
                    case '4': 
                        this.$router.push({ 
                            name: 'AutoLabe', query
                        });
                        break;
                    // Vision AI/ML Statistics
                    case '5': 
                        this.$router.push({
                            name: 'VisionData', query
                        });
                        break;
                    // Smart Mining Dashboard
                    case '6': 
                        this.$router.push({
                            name: 'SmartMiningDashboard', query
                        });
                        break;
                }
            },

            async getCheckYn(list, value) {
                let result = 'N';

                list.forEach(item => {
                    if ( item === value ) {
                        result = 'Y';
                    }
                });

                return result;
            },

            async getDupResult(response) {
                if ( typeof response !== "undefined" && response !== null && response !== "" ) {
                    console.log(response)
                    const status = response.status;
                    const code = response.code;

                    if ( status === 200 && code === '200' ) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            },

            setDeviceTable(deviceList){
                deviceList.then(list => {
                    const dataList = [];
                    list.forEach(element => {
                        dataList.push({
                            id: element.id,
                            edgeDeviceId: element.edge_device_id,
                            edgeDeviceGroup: element.edge_device_group,
                            edgeDeviceName: element.edge_device_name,
                            status: element.status,
                        });
                    });
                    // this.edgeDeviceRowData = common.deleteRowData(dataList, this.mainEdgeDeviceList, 'id');
                    this.edgeDeviceRowData = common.deleteRowData(dataList, this.selectEdgeDeviceRowData, 'id');
                });
            },

            setDeviceData(deviceList){
                const dataList = [];
                deviceList.forEach(item => {
                    dataList.push({
                        id: item.id,
                        edgeDeviceId: item.edge_device_id,
                        edgeDeviceGroup: item.edge_device_group,
                        edgeDeviceName: item.edge_device_name,
                        status: item.status,
                    });

                });
                return dataList;
            },

            // ----- modal
            async showModalDevice(){
                const $modal = this.$refs.modalWrap;
                const $modalDevice = this.$refs.modalDevice;
                $modal.style.display = "block";
                $modalDevice.style.display = "block";

                this.$refs.edgeKeyword.value = "";

                const prjId = this.prjId;
                const getDeviceList = this.getDeviceList(prjId);

                this.selectEdgeDeviceRowData = ([]);
                this.mainEdgeDeviceList.forEach(item => {
                    this.selectEdgeDeviceRowData.push(item);
                });

                this.setDeviceTable(getDeviceList);
            },
            showModalAlert(){
                const $modal = this.$refs.modalWrap;
                const $modalAlert = this.$refs.modalAlert;
                $modal.style.display = "block";
                $modalAlert.style.display = "block";
            },

            closeModal(){
                const $modal = this.$refs.modalWrap;
                const $modalDevice = this.$refs.modalDevice;
                const $modalAlert = this.$refs.modalAlert;
                $modal.style.display = "none";
                $modalDevice.style.display = "none";
                $modalAlert.style.display = "none";
            },
            backPage(){
                this.$router.push('/monTASK/monTaskList');
            },

            // -------- DATE PICKER -----
            datePicToggle(){
                const vm = this;
                const _display = vm.$refs.calenderBox.style.display;
                const _startDateBox = vm.$refs.startDateBox.style.display;
                
                if(_display == 'block'){
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'none';
                    vm.picColor.startStatus = false;
                    vm.picColor.endStatus = false;

                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'block';
                    if(_startDateBox == 'block'){
                        vm.picColor.startStatus = true;
                        vm.picColor.endStatus = false;
                    }else if( _startDateBox == 'none'){
                        vm.picColor.startStatus = false;
                        vm.picColor.endStatus = true;
                    }
                }
            },

            onDatePic(flag){
                if(flag == 'S'){   
                    if(window.selectDate.startDate !== null ){
                        this.picStartDate = window.selectDate.startDate;
                    }
                    
                }else if(flag == 'E'){
                    if(window.selectDate.picEndDate !== null ){
                        this.picEndDate = window.selectDate.endDate;
                    }
                }
            },
            pickerNext(){
                this.$refs.startDateBox.style.display = 'none';
                this.$refs.endDateBox.style.display = 'block';

                this.picColor.startStatus = false;
                this.picColor.endStatus = true;
                
            },
            pickerPrev(){
                this.$refs.startDateBox.style.display = 'block';
                this.$refs.endDateBox.style.display = 'none';

                this.picColor.startStatus = true;
                this.picColor.endStatus = false;
            },
            // ------------

            async checkAllowAccessCreateBtn() {
                const role = await this.getMemberRole();
                const projectRole = await this.getProjectRole();

                // console.log(role);
                // console.log(projectRole);

                if(role != null && role.role == 'SM') {
                    return true;
                } else if(projectRole != null && (projectRole.projectRole == "Manager" || projectRole.projectRole == "Researcher")) {
                    return true;
                } else {
                    return false;
                }
            },

             // ----- API
            async getPrjListData() {
                try {
                    const response = await common.apiGet('/project?project-role=Researcher');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getPrjData(prjId) {
                try {
                    const response = await common.apiGet('/project/' + prjId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getTaskData(taskId, viewRole) {
                try {
                    const response = await common.apiGet(`/monitoring-task/${taskId}?viewRole=${viewRole}`);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getMonTaskAccessList() {
                try {
                    const response = await common.apiGet('/monitoring-task-accessibility');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getMonTaskTypeList() {
                try {
                    const response = await common.apiGet('/monitoring-task-type');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getDeviceList(prjId) {
                try {
                    const response = await common.apiGet('/edge-device/project/'+prjId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async checkMonTaskNm(postData) {
                try {
                    const response = await common.apiPost('/monitoring-task/check-name', postData);
                    return response.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async addMonTask(postData) {
                let response = "";
                try {                    
                    response = await common.apiPost('/monitoring-task', postData);
                } catch (error) {
                    response = error.response;
                    console.error('Error posting data:', error);
                }
                return response;
            },
            async updateMonTask(taskId, postData) {
                let response = "";
                try {                    
                    response = await common.apiPatch('/monitoring-task/'+taskId, postData);
                } catch (error) {
                    response = error.response;
                    console.error('Error posting data:', error);
                }
                return response;
            },
            
            
            
            //-------API------
            async getMemberRole() {
                let response;
                try {
                    let url = `/member/check-role`;
                    response = await common.apiGet(url);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },
            async getProjectRole() {
                let response;
                try {
                    let checkProjectRoleUrl = `/member/check-project-role/${this.prjId}`;
                    response = await common.apiGet(checkProjectRoleUrl);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },
        }
    }
</script>
<style scoped lang="scss">
#management{
    .row_box.toggle_item{
        .data_key{
            height: 50px;
            line-height: 50px;
        }
        .data_val{
            height: 50px;
            ul{
                height: 50px;
                li{
                    width: 210px;
                    margin-right: 15px;
                    &:last-child{
                        margin: 0;
                    }
                }
            }
            
        }
    }
    .table_list {
        .total_num{
            padding-bottom: 20px;
        }
    }
    .row_box {
        .data_val.search_from{
            width: calc(100% - 270px);
            input[type=text]{
                border-radius: 10px 0 0 10px;
            }
        }
        
        // &.add_item{
        //     .add_box{
        //         padding-top: 60px;
        //     }
        //     .data_val{
        //         padding-top: 60px;
        //         .filter_box{
        //             width: 970px;
        //             height: 40px;
        //             position: absolute;
        //             top: 0;
        //             left: 100px;
        //             .all_chk{
        //                 width: 500px;
        //                 height: 100%;
        //                 position: absolute;
        //                 top: 10px;
        //                 left: 40px;
        //                 line-height: 40px;
        //                 label{
        //                     width: 15px;
        //                     height: 15px;
        //                     vertical-align: middle;
        //                     margin-right: 5px;
        //                     margin-top: -4px;
        //                 }
        //                 span{
        //                     color: $mainColor;
        //                 }
        //                 &::after{
        //                     content: "";
        //                     display: block;
        //                     width: 1px;
        //                     height: 15px;
        //                     background-color: #E5E9EF;
        //                     position: absolute;
        //                     top: 12px;
        //                     left: -14px;
        //                 }
        //             }
        //             .search_from{
        //                 width: 190px;
        //                 height: 40px;
        //                 position: absolute;
        //                 top: 10px;
        //                 right: 20px;
        //                 padding-right: 40px;
        //                 input{
        //                     border-radius: 10px 0 0 10px;
        //                 }
        //                 .btn_search{
        //                     width: 40px;
        //                 }
        //             }

        //         }
        //     }
            
        // }
        
        &.textarea_item{
            &>div{
                height: 100px;
            }
            .data_key{
                line-height: 100px;
            }
        }
        
    }
    .filter_box{
        .date_filter{
            position: static;
            padding-left: 0;
            &::after{
                display: none;
            }
            .date_period{
                width: calc(100% - 70px);
                height: 45px;
                padding-right: 70px;
                button{
                    width: 70px;
                    height: 45px;
                }
            }
        }
    } 
    // .mando_table{
    //     table-layout: fixed;
    //     td{
    //         .profile_img_box{
    //             margin-left: 20px;
    //         }
    //     }
    // }  
      
}
  
</style>