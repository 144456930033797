<template>
    <HeaderLayout/>
    <div id="container">
        <div id="contents">
            <h2> 회원가입 </h2>
            <div id="management">
                <div class="row_box">
                    <div class="data_key">이메일</div>
                    <div class="data_val">
                        <input type="text" name="email" ref="email" maxlength="50" v-model="email" placeholder="입력해 주세요." style="width: 420px;">
                        <button class="email_btn"  :disabled="validateEmail" @click="sendEmailBtn">전송</button>
                        <div class="text_length" style="right: 110px;">
                            <span>{{emailLen}}</span>/50
                        </div>
                        <div class="txt_instructions">
                            <span v-if="emailIsInvalid">유효하지 않은 이메일입니다. </span>
                            <span v-else-if="emailIsValid">유효한 이메일입니다. </span>    
                        </div>
                        
                    </div>
                </div>
                <div class="row_box" v-if="emailCertification">
                    <div class="data_key">이메일 인증</div>
                    <div class="data_val" v-bind:class="{ failure : invalidNumber }">
                        <input type="text" name="emailValidate" ref="emailValidate" maxlength="50" v-model="emailValidate" placeholder="메일로 전송된 인증번호를 입력해 주세요.">
                        <div class="text_message" v-if="invalidNumber">인증 코드가 일치하지 않습니다.</div>
                        <button @click="emailCheck">인증 확인</button>
                        <div class="text_length">
                            <span>{{resTimeData}}</span>
                        </div>
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">비밀번호</div>
                    <div class="data_val">
                        <input type="password" name="pswd" ref="pswd" maxlength="30" v-model="pswd" placeholder="영어 대/소문자, 숫자, 특수문자를 조합하여 8~30자">
                        <div class="text_length">
                            <span>{{pswdLen}}</span>/30
                        </div>
                        <div class="txt_instructions">
                            <span v-if="pswdIsInvalid">영어 대/소문자, 숫자, 특수문자를 조합하여 8~30자 </span>
                            <span v-else-if="pswdIsValid">유효한 비밀번호입니다. </span>
                        </div>
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">비밀번호 확인</div>
                    <div class="data_val">
                        <input type="password" name="pswdCnfirm" ref="pswdCnfirm" maxlength="30" v-model="pswdCnfirm" placeholder="영어 대/소문자, 특수문자를 조합하여 8~30자">
                        <div class="text_length">
                            <span>{{pswdCnfirmLen}}</span>/30
                        </div>
                        <div class="txt_instructions">
                            <span v-if="pswdIsNotMatch">비밀번호가 일치하지 않습니다. </span>
                            <span v-else-if="pswdIsMatch">비밀번호가 일치합니다. </span>
                        </div>
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">이름</div>
                    <div class="data_val">
                        <input type="text" name="nm" ref="nm" maxlength="50" v-model="nm" placeholder="입력해주세요.">
                        <div class="text_length">
                            <span>{{nmLen}}</span>/50
                        </div>
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">연락처</div>
                    <div class="data_val">
                        <input type="text" name="contactNum" ref="contactNum" maxlength="50" v-model="contactNum" placeholder="입력해주세요.">
                        <div class="text_length">
                            <span>{{contactNumLen}}</span>/50
                        </div>
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">회사명</div>
                    <div class="data_val">
                        <input type="text" name="companyNm" ref="companyNm" maxlength="50" v-model="companyNm" placeholder="입력해주세요.">
                        <div class="text_length">
                            <span>{{companyNmLen}}</span>/50
                        </div>
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">대표자명</div>
                    <div class="data_val">
                        <input type="text" name="representNm" ref="representNm" maxlength="50" v-model="representNm" placeholder="입력해주세요.">
                        <div class="text_length">
                            <span>{{representNmLen}}</span>/50
                        </div>
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">사업자번호</div>
                    <div class="data_val">
                        <input type="text" name="businessNum" ref="businessNum" maxlength="50" v-model="businessNum" placeholder="입력해주세요.">
                        <div class="text_length">
                            <span>{{businessNumLen}}</span>/50
                        </div>
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">개인정보 동의</div>
                    <div class="data_val">
                        <div class="input_radio">
                            <input type="radio" name="personalInfoConsent" value="agree" v-model="setCheck" id="agreement">
                            <label for="agreement">
                                <div class="radio_icon"></div>
                                동의
                            </label>
                        </div>
                        <div class="input_radio" style="margin-left:100px">
                            <input type="radio" name="personalInfoConsent" value="disagree" v-model="setCheck" checked id="noAgreement">
                            <label for="noAgreement">
                                <div class="radio_icon"></div>
                                미동의
                            </label>
                        </div>
                    </div>
                </div>
                

            </div>
        </div>
        <div class="save_box">
            <button type="button" @click="showModalAlert()">취소</button>
            <button type="button" class="mcbtn" @click="clickRegist()" :disabled="!requireCheck">회원가입</button>
        </div>
    </div>
    <div id="modalWrap" ref="modalWrap" style="display: none;">
        <!-- 취소 -->
        <div class="radiusbox modal_contents message_alert message_modal" ref="modalAlert">
            <div class="message_box">
                변경 사항이 저장되지 않았습니다. <br>정말 취소하시겠습니까?
            </div>
            <button type="button" class="btn_check mcbtn" @click="goBackPage()">확인</button>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import common from '@/assets/js/common'
    export default {
        name: 'AddMember',
        components: {
            HeaderLayout,
            FooterLayout,
        },
        computed:{
            emailLen() {
                return this.email.length;
            },
            pswdLen() {
                return this.pswd.length;
            },
            pswdCnfirmLen() {
                return this.pswdCnfirm.length;
            },
            nmLen() {
                return this.nm.length
            },
            contactNumLen() {
                return this.contactNum.length;
            },
            companyNmLen() {
                return this.companyNm.length;
            },
            
            representNmLen() {
                return this.representNm.length;
            },
            businessNumLen() {
                return this.businessNum.length;
            },

            requireCheck() {
                let checkNm = this.nmLen > 0 && this.nmLen <= 50 && this.nm != "";
                let checkContactNum = this.contactNumLen > 0 && this.contactNumLen <= 50 && this.contactNum != "";
                let checkCompanyNm = this.companyNmLen > 0 && this.companyNmLen <= 50 && this.companyNm != "";
                let checkRepresentNm = this.representNmLen > 0 && this.representNmLen <= 50 && this.representNm != "";
                let checkBusinessNum = this.businessNumLen > 0 && this.businessNumLen <= 50 && this.businessNum != "";
                let checkPersonalInfoConsent = this.setCheck == "agree";

                if(checkNm && 
                checkContactNum && 
                checkCompanyNm && 
                checkRepresentNm && 
                checkBusinessNum && 
                checkPersonalInfoConsent && 
                this.emailAuthCode) {
                    return true;
                }
                return false;
            },
        },
        data(){
            return{
                email: "",
                emailValidate:"",
                validateEmail: true,
                emailIsInvalid: true,
                emailIsValid: false,
                emailCertification: false,
                emailAuthCode: false,
                emailDuplicateExCode: "BEC-F0029",
                emailCertificationExCode: "BEC-F0015",

                pswd: "",
                pswdIsInvalid: true,
                pswdIsValid: false,
                pswdCnfirm: "",
                pswdIsNotMatch: null,
                pswdIsMatch: false,

                nm: "",
                contactNum: "",
                companyNm: "",
                representNm: "",
                businessNum: "",

                //=========Timer
                timer: null,
                timerCounter: 300,
                resTimeData: '05:00',

                setCheck: "",
                invalidNumber: false
            }
        },
        watch: {
            email: function() {
                let validation = (this.email)
                    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,);
                if(validation != null) {
                    this.validateEmail = false;
                    this.emailIsInvalid = false;
                    this.emailIsValid = true;
                } else {
                    this.validateEmail = true;
                    this.emailIsInvalid = true;
                    this.emailIsValid = false;
                }
            },
            pswd: function() {
                let validation = (this.pswd)
                    .match(/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,30}$/);
                if(validation != null) {
                    this.pswdIsInvalid = false;
                    this.pswdIsValid = true;
                } else {
                    this.pswdIsInvalid = true;
                    this.pswdIsValid = false;
                }
            },
            pswdCnfirm: function() {
                if(this.pswd != "" && this.pswd == this.pswdCnfirm) {
                    this.pswdIsNotMatch = false;
                    this.pswdIsMatch = true;
                } else if(this.pswd != "" && this.pswd != this.pswdCnfirm) {
                    this.pswdIsNotMatch = true;
                    this.pswdIsMatch = false;
                } else {
                    this.pswdIsNotMatch = null;
                    this.pswdIsMatch = false;
                }
            }

        },
        mounted() {
            common.loginCheck();
            clearInterval(this.timer);
            if(this.timer != null) {
                this.timerStop();
                this.timer = null;
            }
            // this.timer = this.start();
        },
        methods: {
            // ----- Modal
            showModalAlert(){
                const $modal = this.$refs.modalWrap;
                const $modalAlert = this.$refs.modalAlert;
                $modal.style.display = "block";
                $modalAlert.style.display = "block";
            },
            closeModal(){
                const $modal = this.$refs.modalWrap;
                const $modalAlert = this.$refs.modalAlert;
                // const $modalMember = this.$refs.modalMember;
                $modal.style.display = "none";
                $modalAlert.style.display = "none";
                // $modalMember.style.display = "none";
            },
            goBackPage(){
                this.$router.push('/login');
            },
        
            //===========email
            async sendEmailBtn() {
                try {
                    this.timerInit();
                    this.invalidNumber = false;
                    const response = await this.sendEmail();
                    if(response != null) {
                        let responseCode = response.data.code;
                        if(responseCode == 200 || responseCode == "200") {
                            this.start();
                            this.emailCertification = true;
                        }
                        else {
                            const errorCode = response.data.code;
                            if(errorCode == this.emailDuplicateExCode) {
                                alert("이미 사용되고 있는 이메일입니다.");
                            }
                        }
                        
                    }
                } catch(error) {
                    console.error('Error fetching data:', error);
                }
            },
            //=========email certification
            async emailCheck() {
                try {
                    if(this.emailAuthCode) {
                        return;
                    }

                    const response = await this.receiptEmail();
                    if(response != null && response != "" && typeof response != undefined) {
                        
                        console.log(response);
                        const responseStatus = response.status;
                        const responseCode = response.code;
                        const exCode = this.emailCertificationExCode;

                        if(responseStatus == 200 || responseStatus == "200") {
                            this.invalidNumber = false;
                            this.timerStop();
                            this.emailAuthCode = true;
                            this.$refs.emailValidate.blur();
                        } else if(responseCode == exCode){
                            // alert("인증코드 일치하지 않음");
                            this.invalidNumber = true;
                            this.$refs.emailValidate.focus();
                        }
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            //==========email certification Timer
            start() {
                this.timer = setInterval(() => {
                    this.timerCounter--;
                    this.resTimeData = this.prettyTime();
                    console.log(this.resTimeData);
                    if(this.timerCounter <= 0) this.timerStop();
                }, 1000);
                return this.timer;
            },
            timerStop() {
                clearInterval(this.timer);
                this.timerCounter = 0;
                this.resTimeData = "05:00";

            },
            timerInit() {
                clearInterval(this.timer);
                this.timerCounter = 300;
                this.resTimeData = "05:00";

            },
            prettyTime() {
                let time = this.timerCounter / 60;
                let minutes = parseInt(time);
                let secondes = Math.round((time - minutes) * 60);
                return (
                    minutes.toString().padStart(2,"0") + ":" + secondes.toString().padStart(2,"0")
                )
            },

            //===========submit
            async clickRegist() {
                if(this.requireCheck == true) {
                    const data = {
                        "email" : this.$refs.email.value,
                        "password" : this.$refs.pswd.value,
                        "name" : this.$refs.nm.value,
                        "phone" : this.$refs.contactNum.value,
                        "companyName" : this.$refs.companyNm.value,
                        "ceo_name" : this.$refs.representNm.value,
                        "business_number" : this.$refs.businessNum.value
                    }
                    // console.log(data);
                    let responseCode = "";
                    const response = await this.registMember(data);
                    console.log(response);
                    if(typeof response !== "undefined" && response != null && response != "") {
                        responseCode = response['data']['code'];
                    }

                    if( responseCode === 200 || responseCode === "200" ) {
                        this.$router.push({
                            name: 'Login'
                        });
                    } else {
                        console.log("error");
                    }
                }
            },
            


            //------- API
            async sendEmail() {
                const data = {
                    email: this.email
                }
                try {
                    if(this.email == null || this.email == "") {
                        throw new Error("email is null");
                    }
                    const response = await common.apiPost("/email", data);
                    return response;
                } catch (error) {
                    console.error('Error fetching data:', error);
                    return null;
                }
            },
            async receiptEmail() {
                const data ={
                    email: this.email,
                    authCode: this.emailValidate
                }
                try {
                    const response = await common.apiPost("/email/verification", data);
                    console.log(response);
                    return response.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                    return null;
                }
            },
            async registMember(data) {
                let response = "";
                try {
                    response = await common.apiPost("/auth/sign-up", data);
                } catch (error) {
                    response = error.response;
                    console.error("error regist member data", error);
                }
                return response;
                
            }

        }
    }
    
    
</script>
<style scoped lang="scss">
#contents{
    left: 0;
}
h2{
    width: 100%;
    height: 30px; 
    font-size: 30px;
    text-align: center;
    font-weight: $bold;
    padding: 30px 0; 
}
#management{
    width: 800px;
    box-sizing: border-box;
    padding: 20px 40px;
    background: #fff;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #a2c3f233;
    .email_btn{
        width: 90px;
        height: 45px;
        color: $mainColor;
        box-sizing: border-box;
        border: 1px solid $mainColor;
        border-radius: 10px;
        background-color: #EDFBFF;
        margin-left: 10px;
    }
    .input_radio{
        display: inline-block;
        .radio_icon{
            width: 20px;
            height: 20px;
        }
    }
}
.save_box{
    text-align:center;
    padding-bottom: 100px;
    button{
        width: 90px;
        height: 45px;
        border: 1px solid #DEDEDE;
        background-color: #fff;
        margin: 0 10px;
        border-radius: 10px;
        &.mcbtn{
            border: 1px solid $mainColor;
        }
    }
}
</style>