<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap" v-bind:style="edgeId === '' ? '' : 'display:none'">
                 <div class="breadcrumb">
                    <span>장치/배포관리</span>
                    <span>Edge Device 관리</span>
                    <span class="active">신규 Edge Device 등록</span>
                </div>
                <h2>신규 Edge Device 등록</h2>

            </div>
            <div class="title_wrap" v-bind:style="edgeId === '' ? 'display:none' : ''">
                <div class="breadcrumb">
                    <span>장치/배포관리</span>
                    <span>Edge Device 관리</span>
                    <span class="active">Edge Device 관리</span>
                </div>
                <h2>Edge Device 관리</h2>
            </div> -->
            <BreadcrumbLayout :pageId="pageId" :subParams="bcParams"/>
            <div id="management">
                <div class="row_box two_item">
                    <div>
                        <div class="data_key">Edge Device ID</div>
                        <div class="data_val">
                            <input type="text" ref="edgeDeviceId" v-model="edgeDeviceId" maxlength="50" placeholder="외부 고유 ID(Thing ID 등)를 입력해 주세요.">
                            <div class="text_length">
                                <span>{{edgeDeviceIdLen}}</span>/50
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="data_key">Edge Device Name</div>
                        <div class="data_val">
                            <input type="text" ref="edgeDeviceNm" v-model="edgeDeviceNm" maxlength="50" placeholder="입력해 주세요.">
                            <div class="text_length">
                                <span>{{edgeDeviceNmLen}}</span>/50
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row_box two_item">
                    <div>
                        <div class="data_key">Edge Device Type</div>
                        <div class="data_val">
                            <select name="edgeTypeId" ref="edgeTypeId" :value="edgeTypeId" @change="setEdgeType($event)">
                                <option value="" disabled selected>선택해주세요.</option>
                                <option v-for="(item, index) in edgeDeviceTypeList" :key="index" :value="item.id">
                                    {{item.type}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <div class="data_key">Computer Type</div>
                        <div class="data_val">
                            <select name="computerTypeId" ref="computerTypeId" :value="computerTypeId" @change="setComputerType($event)">
                                <option value="" disabled selected>선택해주세요.</option>
                                <option v-for="(item, index) in edgeComputerTypeList" :key="index" :value="item.id">
                                    {{item.computerType}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row_box two_item" style="height: 200px;">
                    <div>
                        <div class="data_key" style="line-height: 20px; position: relative; top: 80px;">Edge Device <br> Image Upload</div>
                        <div class="data_val">
                            <div class="img_upload_box">
                                <!-- <button class="upload_btn" id="btn-file-upload" @click="clickUploadFile($event)">파일선택</button> -->
                                <input type="file" id="uploadFile" ref="uploadFile" @change="handleFileUpload"  accept="image/*">
                                <div class="img_view">
                                    <img :src="imagePath" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="data_key" style="line-height: 20px; position: relative; top: 86px;">Edge Device Description</div>
                        <div class="data_val">
                            <div class="textarea_box">
                                <textarea name="" ref="desc" v-model="desc" maxlength="200" placeholder="입력해주세요."></textarea>
                                <div class="text_length">
                                    <span>{{descLen}}</span>/200
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row_box two_item">
                    <div>
                        <div class="data_key">등록 일자</div>
                        <div class="data_val filter_box">
                            <div class="date_filter">

                                <div class="date_period">
                                    <div class="date_box" style="text-align: center">
                                        <span style="display:inline-block ; padding: 0 0 0 5px;" :style="picRgstDtTxtStyle" ref="picEndTxt">{{rgstDt}}</span>
                                    </div>
                                    <button type="button" ref="datePicToggle" @click="datePicToggle()"></button>
                                    <div class="calender_box radiusbox" ref="calenderBox" style="display:none">
                                        <div class="rgstDt date_box" ref="rgstDtBox" @click="onDatePic()" style="display:block">
                                            <VCalendarUi :dateInterface="'end'" :maxDate="maxDate" :setDate="rgstDt"/>
                                            <button type="button" class="mcbtn" @click="datePicToggle()">완료</button>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="data_key">Status</div>
                        <div class="data_val">
                            <div class=status_btn_box>
                                <div class="chk_item_box float_box">
                                    <div class="chk_item">
                                        <input type="checkbox" name="status" id="disabled" value="I" v-model="statusChecked" @change="setStatusCheck($event)">
                                        <label for="disabled"> Disabled </label>
                                    </div>
                                    <div class="chk_item">
                                        <input type="checkbox" name="status" id="active" value="A" v-model="statusChecked" @change="setStatusCheck($event)">
                                        <label for="active">Active</label>
                                    </div>
                                    <div class="chk_item">
                                        <input type="checkbox" name="status" id="reparing" value="R" v-model="statusChecked" @change="setStatusCheck($event)">
                                        <label for="reparing">Repairing</label>
                                    </div>
                                    <div class="delete">
                                        <input type="checkbox" name="status" id="dispose" value="D" v-model="statusChecked" @change="setStatusCheck($event)">
                                        <label for="dispose">폐기</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row_box two_item" style="height: 75px">
                    <div>
                        <div class="data_key">소속 - 기기 소유</div>
                        <div class="data_val">
                            <select name="ownerTeamId" ref="ownerTeamId" :value="ownerTeamId" @change="setOwnerTeamId($event)">
                                <option value="" disabled selected>선택해주세요.</option>
                                <option v-for="(item, index) in teamList" :key="index" :value="item.teamId">
                                    {{item.teamName}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <div class="data_key">소속 - 기기 관리</div>
                        <div class="data_val">
                            <select name="manageTeamId" ref="manageTeamId" :value="manageTeamId" v-bind:disabled="checkSame.length > 0" @change="setManageTeamId($event)">
                                <option value="" disabled selected>선택해주세요.</option>
                                <option v-for="(item, index) in teamList" :key="index" :value="item.teamId">
                                    {{item.teamName}}
                                </option>
                            </select>
                            <div class="chk_item" style="margin-top: 16px;">
                                <input type="checkbox" name="checkSame" id="checkSame" value="checkSame" v-model="checkSame" @change="setSame()">
                                <label for="checkSame"></label> 
                                <span style="position: relative; top: -5px; left: 8px;">기기 소유 소속과 동일</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row_box add_item">
                    <div class="data_key">
                        기기 담당자
                    </div>
                    <div class="data_val active">
                        <div class="add_box table_list">
                            <div class="total_item">
                                <span>{{ mainMemberList.length }} </span> Members
                            </div>
                            <button class="btn_add_item" v-if="manageTeamId" @click="showModalMember()"></button>
                            <div class="none_item" v-if="mainMemberList.length == 0">
                                <div>프로젝트 멤버가 없습니다.</div>
                                <button class="btn_add_item" v-if="manageTeamId" @click="showModalMember()">멤버 추가</button>
                            </div>
                            <div class="mando_table_wrap" v-if="mainMemberList.length > 0">
                                <table class="mando_table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div class="col_name">
                                                    이름 및 직책
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    전화번호
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Email
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    대표 지정
                                                </div>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody id="mainMemberTbody">
                                        <tr v-for="(item, index) in mainMemberList" :key="index">
                                            <td>
                                                <div class="agent_box">
                                                    <div v-bind:style="representId === item.memberId ? '' : 'display:none'" class="agent">대표</div>
                                                </div>
                                                <div class="profile_img_box">
                                                    <img src="https://d2rsr3zb8mvgil.cloudfront.net/images/member/member_sample.png" alt="">
                                                </div>
                                                <span class="user_name">{{item.name}}</span>
                                                
                                            </td>
                                            <td>
                                                <input type="text" :value="item.phone" :ref="'phone_' + item.memberId" @input="setPhone($event, item)" >
                                            </td>
                                            <td>
                                                {{item.email}}
                                            </td>
                                            <td>
                                                <div class="toggle_box">
                                                    <input type="checkbox" name="represent" :id="'repCheck' + item.memberId" :ref="'repCheck' + item.memberId" :value="item.memberId" v-model="representChecked" @change="setRepresent(item)">
                                                    <label :for="'repCheck' + item.memberId"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <button v-bind:style="representId === item.memberId ? 'display:none' : ''" type="button" class="btn_del" :data-index="item.memberId" @click="clickDelMemberList(item.memberId)"></button>
                                            </td>
                                            <td data-index="memberId" style="display:none">
                                                {{item.memberId}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">Edge Device Group</div>
                    <div class="data_val">
                        <div class="edge_device_select_box">
                            <select name="domainId" ref="domainId" :value="domainId" @change="setDomainId($event)">
                                <option value="" disabled selected>Domain 선택</option>
                                <option v-for="(item, index) in domainList" :key="index" :value="item.id">
                                    {{item.name}}
                                </option>
                            </select>
                            <select name="oemId" ref="oemId" :value="oemId" @change="setOemId($event)">
                                <option value="" disabled selected>OEM 선택</option>
                                <option v-for="(item, index) in oemList" :key="index" :value="item.id">
                                    {{item.name}}
                                </option>
                            </select>
                            <select name="vehicleId" ref="vehicleId" :value="vehicleId" @change="setVehicleId($event)">
                                <option value="" disabled selected>Vehicle 선택</option>
                                <option v-for="(item, index) in vehicleList" :key="index" :value="item.id">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="save_box">
                    <button type="button" @click="showModalAlert()">취소</button>
                    <button type="button" class="mcbtn" @click="clickRegist()" :disabled="!requireCheck">저장</button>
                </div>
            </div>
        </div>
    </div>
    <div id="modalWrap" ref="modalWrap" style="display: none;">
        <!-- 취소 -->
        <div class="radiusbox modal_contents message_alert message_modal" ref="modalAlert">
            <div class="message_box">
                변경 사항이 저장되지 않았습니다. <br>정말 취소하시겠습니까?
            </div>
            <button type="button" class="btn_check mcbtn" @click="goBackPage()">확인</button>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
        <!-- 맴버 등록 -->
        <div class="radiusbox modal_contents add_item_modal add_member" ref="modalMember" style="display : none;">
            <strong class="modal_title">
                프로젝트 멤버 추가
            </strong>
            <div class="filter_box">
                <input type="text" name="memberKeyword" ref="memberKeyword" @keydown.enter="clickSearchMember()" placeholder="이름, 팀, 이메일 주소 검색가능">
                <button type="button" class="btn_search" @click="clickSearchMember()"></button>
            </div>
            <div class="float_box table_list">
                <div class="select_box mando_table_wrap">
                    <table class="mando_table">
                        <colgroup>
                            <col style="width: 20%">
                            <col style="width: 20%">
                            <col style="width: 60%">
                        </colgroup>
                        <thead>
                                <tr>
                                    <th>
                                        <div class="all_chk">
                                            <input type="checkbox" id="allChkMember" v-model="allSelctMember">
                                            <label for="allChkMember"></label>
                                            <span>Name</span>  
                                        </div>
                                    </th>
                                    <th>Team</th>
                                    <th>E-mail</th>
                                </tr>
                            </thead>
                        <tbody id="memberTbody">
                            <tr v-for="(item, index) in memberRowData" :data-index="index" :key="index">
                                <td>
                                    <div class="chk_item">
                                        <input type="checkbox" :id="'MemberItem' + item.memberId" :value="item" v-model="unSelectMemberList">
                                        <label :for="'MemberItem' + item.memberId"></label>
                                        <span>{{item.name}}</span>
                                    </div>
                                </td>
                                <td>
                                    {{item.teamName}}
                                </td>
                                <td>
                                    {{item.email}}
                                </td>
                                <td style="display:none">
                                    {{ item.memberId }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="choose_box">
                    <div class="btn_wrap">
                        <button type="button" class="btn_add" @click="clickAddMember()">추가</button>
                        <button type="button" class="btn_add_del" @click="clickDelMember()">삭제</button>
                    </div>
                </div>
                <div class="select_box">
                    <div class="table_title">
                        <strong>선택 항목</strong>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <colgroup>
                                <col style="width: 20%">
                                <col style="width: 20%">
                                <col style="width: 60%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="all_chk">
                                            <input type="checkbox" id="allChkSelectedMember" v-model="allSelctedMember">
                                            <label for="allChkSelectedMember"></label>
                                            <span>Name</span>  
                                        </div>
                                    </th>
                                    <th>Team</th>
                                    <th>E-mail</th>
                                </tr>
                            </thead>
                            
                            <tbody id="selectMemberTbody">
                                <tr v-for="(item, index) in selectMemberRowData" :data-index="index" :key="index">
                                    <td>
                                        <div class="chk_item">
                                            <input type="checkbox" :id="'MemberSelectedItem' + item.memberId" :value="item" v-model="selectedMemberList">
                                            <label :for="'MemberSelectedItem' + item.memberId"></label>
                                            <span>{{item.name}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        {{item.teamName}}
                                    </td>
                                    <td>
                                        {{item.email}}
                                    </td>
                                    <td style="display:none">
                                        {{ item.memberId }}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr class="hover_none" v-bind:style="selectMemberRowData.length > 0 ? 'display:none' : ''">
                                    <td colspan="3">
                                        <div class="none_itme">
                                            <div class="message">
                                                프로젝트 멤버를 추가 해 주세요.
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="check_wrap">
                <button type="button" class="btn_check mcbtn" @click="clickCompleteMember()" v-bind:disabled="selectMemberRowData.length === 0">완료</button>   
            </div>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import VCalendarUi from '@/components/VCalendarUi.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';

    import common from '@/assets/js/common'
    export default {
        name: 'detailEdgeDevice',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            VCalendarUi,
            BreadcrumbLayout,
        },
        computed:{
            edgeDeviceIdLen() {
                return this.edgeDeviceId.length;
            },
            edgeDeviceNmLen() {
                return this.edgeDeviceNm.length;
            },
            descLen() {
                return this.desc.length;
            },

            allSelctMember: {
                //getter
                get: function() {
                    return this.memberRowData.length === this.unSelectMemberList.length;
                },
                //setter
                set: function(e) {
                    this.unSelectMemberList = e ? this.memberRowData : [];
                },
            },
            allSelctedMember: {
                //getter
                get: function() {
                    return this.selectMemberRowData.length === this.selectedMemberList.length;
                },
                //setter
                set: function(e) {
                    this.selectedMemberList = e ? this.selectMemberRowData : [];
                },
            },

            // VCalendar 
            picRgstDtTxtStyle() {
                return {
                    color: this.picColor.endStatus ? this.picColor.active : this.picColor.default
                };
            },

            requireCheck() {
                return this.manageRole && this.edgeDeviceIdLen > 0 && this.edgeDeviceNmLen > 0  && this.edgeTypeId !== '' && this.computerTypeId !== '' && this.vehicleId !== '' && this.mainMemberList.length > 0;
            },
        },
        data(){
            const edgeId = "";

            const memberRowData = ([]);
            const selectMemberRowData= ([]);

            const rgstDt = null;

            return{
                edgeId,

                edgeDeviceId: "",
                edgeDeviceNm: "",
                edgeTypeId: "",
                computerTypeId: "",
                desc: "",

                ownerTeamId: "",
                manageTeamId: "",

                checkSame: [],
                representChecked: [],
                representId: "",

                domainId: "",
                oemId: "",
                vehicleId: "",

                edgeDeviceTypeList: [],
                edgeComputerTypeList: [],
                statusChecked: [],
                teamList: [],
                domainList: [],
                oemList: [],
                vehicleList: [],

                memberRowData,
                selectMemberRowData,

                unSelectMemberList: [],
                selectedMemberList: [],
                mainMemberList: [],

                imagePath: "", 
                // -- VCalendarUi / .date_period
                maxDate: "9999-12-31",
                rgstDt,
                picColor: {
                    startStatus: false,
                    endStatus: false,
                    active: '#00B4ED',
                    default: '#020A0A'
                },

                // breadcrumb
                pageId: "deviceNDelivery_addEddgeDevice",
                bcParams: [],

                // role
                viewRole: false,
                manageRole: false,
            };
        },

        mounted() {
            // Role
            this.setRole();

            this.edgeId = this.$route.params.edgeId;

            if ( typeof this.edgeId != "undefined" && this.edgeId != null && this.edgeId != "" ) {
                this.pageId = 'deviceNDelivery_modEddgeDevice';
                this.setPage();
                this.bcParams.push({
                    edgeId: this.edgeId
                });
            } else {
                this.pageId = 'deviceNDelivery_addEddgeDevice';
                this.edgeId = "";
                const formattedDate = common.getNowDt();
                this.rgstDt = formattedDate;
                window.selectDate.endDate = formattedDate;
            }

            this.setSelectBox();

        },
        methods:{
            async setRole() {
                this.manageRole = await common.getUserRole('type2');
                this.viewRole = await common.getUserRole('type2');

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            async setPage() {
                const edgeId = this.edgeId;
                const edgeDeviceData = await this.getEdgeDeviceData(edgeId);
                if ( typeof edgeDeviceData != "undefined" && edgeDeviceData != null && edgeDeviceData != "" ) {

                    this.edgeDeviceId = edgeDeviceData.edge_device_id;
                    this.edgeDeviceNm = edgeDeviceData.edge_device_name;
                    this.edgeTypeId = edgeDeviceData.device_type_id;
                    this.computerTypeId = edgeDeviceData.device_type_id;
                    this.desc = edgeDeviceData.description;

                    this.ownerTeamId = edgeDeviceData.owner_team_id;
                    this.manageTeamId = edgeDeviceData.manage_team_Id;

                    if ( this.ownerTeamId === this.manageTeamId ) {
                        this.checkSame[0] = 'checkSame';
                    }

                    this.statusChecked[0] = edgeDeviceData.status;

                    const edgeDeviceGroups = edgeDeviceData.edgeDeviceGroups;
                    
                    try {
                        this.domainId = edgeDeviceGroups.domainId;
                    } catch (error) {
                        this.domainId = "";
                    }

                    if ( typeof this.domainId != "undefined" && this.domainId != null && this.domainId != "" ) {
                        this.oemList = await this.getEdgeGroupList(this.domainId);

                        this.oemId = edgeDeviceGroups.oemId;
                        this.vehicleList = await this.getEdgeGroupList(this.oemId);

                        this.vehicleId = edgeDeviceGroups.vehicleId;
                    }

                    const edgeDeviceContactPeople = edgeDeviceData.edgeDeviceContactPeople;
                    const memberList = this.setMemberData(edgeDeviceContactPeople);
                    this.mainMemberList = memberList;

                    edgeDeviceContactPeople.forEach(item => {
                        const representative = item.representative;
                        if ( representative === true ) {
                            this.representChecked[0] = item.member_id;
                            this.representId = item.member_id;
                        }
                    });

                    const formattedDate = edgeDeviceData.regist_date.split(" ")[0];
                    this.rgstDt = formattedDate;
                    window.selectDate.endDate = formattedDate;
                    window.selectDate.startDateTime = edgeDeviceData.regist_date;
                    window.selectDate.endDateTime = edgeDeviceData.regist_date;

                }
            },

            async setSelectBox() {
                this.edgeDeviceTypeList = await this.getEdgeDeviceTypeList();
                this.edgeComputerTypeList = await this.getEdgeComputerTypeList();
                this.teamList = await this.getTeamList();
                this.domainList = await this.getEdgeGroupList(0);
            },
            
            setEdgeType(event) {
                this.edgeTypeId = event.target.value;
            },
            setComputerType(event) {
                this.computerTypeId = event.target.value;
            },
            setOwnerTeamId(event) {
                this.ownerTeamId = event.target.value;

                if ( this.checkSame.length > 0 ) {
                    this.manageTeamId = this.ownerTeamId;
                }
            },
            setManageTeamId(event) {
                this.manageTeamId = event.target.value;
            },
            async setDomainId(event) {
                this.domainId = event.target.value;

                this.oemList = await this.getEdgeGroupList(this.domainId);

                this.vehicleList = [];

                this.oemId = "";
                this.vehicleId = "";
            },
            async setOemId(event) {
                this.oemId = event.target.value;
                this.vehicleList = await this.getEdgeGroupList(this.oemId);
                this.vehicleId = "";
            },
            setVehicleId(event) {
                this.vehicleId = event.target.value;
            },

            setStatusCheck(event) {
                let status = event.target.value;

                if ( this.statusChecked.length > 0 ) {
                    this.statusChecked = [];
                    this.statusChecked[0] = status;
                }
            },

            setSame() {
                if ( this.checkSame.length > 0 ) {
                    this.manageTeamId = this.ownerTeamId;
                }
            },

            setRepresent(item) {
                let memberId = item.memberId;

                if ( this.representChecked.length > 0 ) {
                    this.representChecked = [];
                    this.representChecked[0] = memberId;
                    this.representId = memberId;
                } else {
                    this.representId = "";
                }
            },

            setMemberTable(memberList) {
                memberList.then(list => {
                    const dataList = [];
                    list.forEach(element => {
                        dataList.push({
                            name: element.name,
                            teamName: element.teamName,
                            email: element.email,
                            memberId: element.id,
                            profileImage: element.profileImage,
                            recentLogin: element.recentLogin,
                            divisonRoleId: 1
                        });
                    });
                    this.memberRowData = common.deleteRowData(dataList, this.selectMemberRowData, 'memberId');
                });

            },

            setMemberData(memberList) {
                const dataList = [];
                memberList.forEach(item => {
                    dataList.push({
                        name: item.name,
                        teamName: item.team_name,
                        email: item.email,
                        memberId: item.member_id,
                        profileImage: item.profile_image,
                        phone: item.phone,
                        recentLogin: String(item.last_login),
                        divisonRoleId: item.divisonRoleId
                    });

                });
                return dataList;
            },
            //  images 등록
            handleFileUpload() {
                const fileInput = this.$refs.uploadFile;
                const file = fileInput.files[0];
                // console.log(file);
                if (file) {
                    this.imagePath = URL.createObjectURL(file);
                }
            },

            // ----- Modal
            async showModalMember(){
                const $modal = this.$refs.modalWrap;
                const $modalMember = this.$refs.modalMember;
                $modal.style.display = "block";
                $modalMember.style.display = "block";

                this.$refs.memberKeyword.value = "";

                const teamId = this.manageTeamId;
                const getMemberList = this.getMemberData(teamId);

                this.selectMemberRowData = ([]);
                this.mainMemberList.forEach(item => {
                    this.selectMemberRowData.push(item);
                });

                this.setMemberTable(getMemberList);
            },
            showModalAlert(){
                const $modal = this.$refs.modalWrap;
                const $modalAlert = this.$refs.modalAlert;
                $modal.style.display = "block";
                $modalAlert.style.display = "block";
            },
            closeModal(){
                const $modal = this.$refs.modalWrap;
                const $modalMember = this.$refs.modalMember;
                const $modalAlert = this.$refs.modalAlert;
                $modal.style.display = "none";
                $modalAlert.style.display = "none";
                $modalMember.style.display = "none";
            },
            goBackPage(){
                const edgeId = this.edgeId;
                if ( typeof edgeId != "undefined" && edgeId != null && edgeId != "" ) {
                    this.$router.push({ 
                        name: 'detailEdgeDevice',
                        params: { edgeId: edgeId }
                    });
                } else {
                    this.$router.push('/deviceNDelivery/edgeDeviceList');
                }
            },


            // ----- click
            async clickSearchMember() {
                const keyword = this.$refs.memberKeyword.value.toUpperCase();

                if(typeof keyword == "undefined" || keyword == null || keyword == "") {
                    const teamId = this.manageTeamId;
                    const getMemberList = this.getMemberData(teamId);
                    this.setMemberTable(getMemberList);
                } else {
                    this.setSearchMember(keyword);
                }
            },
            
            setSearchMember(keyword) {
                const searchRow = [];
    
                this.memberRowData.forEach(item => {
                    let name = common.getUpper(item['name']);
                    let teamName = common.getUpper(item['teamName']);
                    let email = common.getUpper(item['email']);

                    if ( name.includes(keyword) || teamName.includes(keyword) || email.includes(keyword) ) {
                        searchRow.push(item);
                    }
                });

                this.memberRowData = ([]);
                
                searchRow.forEach(item => {
                    this.memberRowData.push(item);
                });
            },

            clickAddMember() {
                if ( this.unSelectMemberList.length > 0 ) {
                    this.unSelectMemberList.forEach(item => {
                        this.selectMemberRowData.push(item);
                    });

                    this.memberRowData = common.deleteRowData(this.memberRowData, this.unSelectMemberList, "memberId");
                    this.unSelectMemberList = [];
                }
            },

            clickDelMember() {
                if ( this.selectedMemberList.length > 0 ) {
                    this.selectedMemberList.forEach(item => {
                        this.memberRowData.push(item);
                    });
                    
                    this.selectMemberRowData = common.deleteRowData(this.selectMemberRowData, this.selectedMemberList, "memberId");
                    this.selectedMemberList = [];

                    const teamId = this.manageTeamId;
                    const getMemberList = this.getMemberData(teamId);
                    this.setMemberTable(getMemberList);
                }
            },
            
            clickCompleteMember() {
                this.mainMemberList = [];
                this.selectMemberRowData.forEach(item => {
                    this.mainMemberList.push(item);
                });
                this.closeModal();
            },

            clickDelMemberList(id) {
                let i = this.mainMemberList.length;
                while ( i-- ) {
                    let item = this.mainMemberList[i];
                    if ( item.memberId == id ) {
                        this.mainMemberList.splice(i, 1);
                        this.memberRowData.push(item);
                    }
                }
                let j = this.selectMemberRowData.length;
                while ( j-- ) {
                    let item = this.selectMemberRowData[j];
                    if ( item.memberId == id ) {
                        this.selectMemberRowData.splice(j, 1);
                    }
                }
            },

            clickUploadFile() {
                const inputFileUpload = this.$refs.uploadFile;
                inputFileUpload.click();
                
            },

            async clickRegist() {
                if ( this.requireCheck === true ) {
                    let status = "";
                    if ( this.statusChecked.length > 0 ) {
                        status = this.statusChecked[0];
                    }

                    const contactPersonList = [];
                    const mainMemberTbody = document.querySelectorAll("#mainMemberTbody > tr");
                    mainMemberTbody.forEach(trElement => {
                        const memberId = parseInt(trElement.querySelector('td[data-index="memberId"]').innerHTML);
                        const inputPhone = this.$refs['phone_' + memberId][0];
                        const phone = inputPhone.value;
                        const representative = this.$refs['repCheck' + memberId][0].checked;

                        contactPersonList.push({
                            member_id: parseInt(memberId),
                            phone: phone,
                            representative: String(representative),
                        });
                    });

                    const formData = new FormData();
                    const fileUpload = this.$refs.uploadFile.files[0];

                    const request = {
                        "edge_device_id": this.$refs.edgeDeviceId.value,
                        "name": this.$refs.edgeDeviceNm.value,
                        "description": this.$refs.desc.value,
                        "edge_device_type_id": parseInt(this.edgeTypeId),
                        "edge_device_computer_type_id": parseInt(this.computerTypeId),
                        "edge_device_group_id": parseInt(this.vehicleId),
                        "owner_team_id": parseInt(this.ownerTeamId),
                        "manage_team_id": parseInt(this.manageTeamId),
                        "status": status,
                        "contactPersonList": contactPersonList
                    }
                    const json = JSON.stringify(request);
                    const blob = new Blob([json], { type: 'application/json' });
                    formData.append("request", blob);

                    if ( typeof fileUpload != "undefined" && fileUpload != null && fileUpload != "" ) {
                        formData.append("file", fileUpload);
                    } 

                    let responseCode = "";
                    let edgeId = this.edgeId;
                    if ( edgeId != '' && edgeId > 0) {
                        const response = await this.updateEdgeDevice(edgeId, formData);
                        try {
                            responseCode = response['data']['code'];
                        } catch (error) {
                            responseCode = 500;
                        }
                    } else {
                        const response = await this.addEdgeDevice(formData);
                        try {
                            responseCode = response['data']['code'];
                            edgeId = response['data']['data'];
                        } catch (error) {
                            responseCode = 500;
                        }
                    }
                    
                    if ( responseCode === 200 || responseCode === "200" ) {
                        // this.$router.push('/deviceNDelivery/edgeDeviceList');
                        this.$router.push({ 
                            name: 'detailEdgeDevice',
                            params: { edgeId: edgeId }
                        });
                    }
                }
            },

            setPhone(event, item) {
                const phone = event.target.value;
                const memberId = item.memberId;

                this.mainMemberList.forEach(item => {
                    if ( item.memberId === memberId ) {
                        item.phone = phone;
                    }
                });
            },

            // -------- DATE PICKER -----
            datePicToggle(){
                const vm = this;
                const _display = vm.$refs.calenderBox.style.display;
                const _rgstDtBox = vm.$refs.rgstDtBox.style.display;
                
                if(_display == 'block'){
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'none';
                    vm.picColor.endStatus = false;
                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'block';
                    if(_rgstDtBox == 'block'){
                        vm.picColor.endStatus = false;
                    }else if( _rgstDtBox == 'none'){
                        vm.picColor.endStatus = true;
                    }
                }
                
            },
            onDatePic(){
                this.rgstDt = window.selectDate.endDate;
            },
            // ------------

            // ----- API 
            async getEdgeDeviceData(edgeId) {
                try {
                    const response = await common.apiGet('/edge-device/management/' + edgeId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getEdgeDeviceTypeList() {
                try {
                    const response = await common.apiGet('/edge-device-type');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getEdgeComputerTypeList() {
                try {
                    const response = await common.apiGet('edge-device-computer-type');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getTeamList() {
                try {
                    const response = await common.apiGet('/team/list');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getMemberData(teamId) {
                try {
                    const response = await common.apiGet(`/member/team/${teamId}/members`);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getEdgeGroupList(id) {
                try {
                    const response = await common.apiGet('/edge-device-group/domain/oem/vehicle/'+id);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async addEdgeDevice(postData) {
                let response = "";
                try {                
                    response = await common.apiPostMulti('/edge-device', postData);
                } catch (error) {
                    response = error.response;
                    console.error('Error posting data:', error);
                }
                return response;
            },
            async updateEdgeDevice(edgeId,postData) {
                let response = "";
                try {                    
                    response = await common.apiPatchMulti(`/edge-device/${edgeId}`, postData);
                } catch (error) {
                    response = error.response;
                    console.error('Error posting data:', error);
                }
                return response;
            },
        }
    }
</script>
<style scoped lang="scss">
#management{
    .table_list {
        .total_num{
            padding-bottom: 20px;
        }
    }
    .row_box {
        &.two_item{
            &>div:nth-child(1){
                width: 48%;
            }
            &>div:nth-child(2){
                width: 52%;
            }
        }
        .data_key{
            width: 230px;
        }
        .data_val{
            width: calc(100% - 230px);
        }
        .data_val.search_from{
            width: calc(100% - 270px);
            input[type=text]{
                border-radius: 10px 0 0 10px;
            }
        }
        // &.add_item{
        //     .data_val.active{
        //         height: 300px;
        //         .add_box{
        //             padding-top: 0;
        //         }
        //     }
        // }
        
        &.textarea_item{
            &>div{
                height: 100px;
            }
            .data_key{
                line-height: 100px;
            }
        }
        .img_upload_box{
            width: 100%;
            height: 200px;
            background-color: $whiteColor;
            box-sizing: border-box;
            border: 1px solid $disable02Color;
            border-radius: 10px;
            text-align: center;
            input{
                width: 100%;
            }
            .upload_btn{
                padding: 5px 10px;
                background-color: #E5E9EF;
                vertical-align: top;
                margin: 10px 0 0 10px

            }
            .img_view{
                width: 168px;
                height: 168px;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
                img{
                    width: 100%;
                }
            }
        }
        .textarea_box{
            width: 100%;
            height: 200px;
            position: relative;
        }
        .status_btn_box{
            .chk_item_box{
                width: 440px;
                .chk_item{
                    width: 100px; 
                    height: 45px;
                    float: left;
                    box-sizing: border-box;
                    text-align: center;
                    label{
                        width: 100%;
                        height: 45px;
                        line-height: 42px;
                        display: block;
                        cursor: pointer;
                        background-image: none;
                        border: 1px solid $disable02Color;
                        background-color: #fff;
                    }
                    input:checked + label{
                        background-color: #EDFBFF;
                        border-color: $mainColor;
                        color: $mainColor;
                    }
                    &:nth-child(1){
                        label{
                            border-radius: 10px 0 0 10px;
                        }
                    }
                    &:nth-child(3){
                        label{
                            border-radius: 0 10px 10px 0;
                        }
                    }
                    
                }
                .delete{
                    width: 83px;
                    height: 45px;
                    float: right;
                    margin-right: 4px;
                    margin-left: 30px;
                    label{
                        width: 83px;
                        height: 45px;
                        display: block;
                        border: 1px solid $failedColor;
                        border-radius: 10px;
                        box-sizing: border-box;
                        text-align: center;
                        color: $failedColor;
                        text-indent: 14px;
                        line-height: 42px;
                        background-color: #fff;
                        background-image: url($baseURL + "common/trashicon_15.svg");
                        background-repeat: no-repeat;
                        background-position: 14px 13px;
                        background-size: auto;
                        cursor: pointer;
                    }
                    input:checked + label{
                        background-color: #fff4f4;
                    }
                }
            }
            
        }
        .edge_device_select_box{
            select{
                width: 335px;
                margin-right: 20px;
                &:last-child{
                    margin: 0;
                }
            }
        }
        
    }
    .filter_box{
        .date_filter{
            position: static;
            padding-left: 0;
            &::after{
                display: none;
            }
            .date_period{
                width: calc(100% - 70px);
                height: 45px;
                padding-right: 70px;
                button{
                    width: 70px;
                    height: 45px;
                }
                .calender_box{
                    width: 100%;
                }
            }
        }
    } 
    .add_box{
        .mando_table {
            input[type=checkbox] + label{
                width: 35px;
                height: 35px;
            }
        }
        .agent_box{
            width: 40px;
            height: 25px;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            left: -12px;
            .agent{
                width: 40px;
                height: 25px;
                line-height: 24px;
                font-size: 13px;
                background-color: $gnbColor;
                color: #fff; 
                border-radius: 5px;
            }
        }
    }
    
      
}
 
</style>