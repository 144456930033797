<template>
  <div class="common_editor" v-if="editor">
    <button class="tt_bold" @click="editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
      <!-- bold -->
    </button>
    <button class="tt_italic" @click="editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
      <!-- italic -->
    </button>
    <button class="tt_strike" @click="editor.chain().focus().toggleStrike().run()" :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
      <!-- strike -->
    </button>
    
    <button class="division_btn" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
      H1
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
      H2
    </button>

    <button class="tt_left division_btn" @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
      <!-- left -->
    </button>
    <button class="tt_center" @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
      <!-- center -->
    </button>
    <button class="tt_right" @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
      <!-- right -->
    </button>


    <button class="tt_bullet_list division_btn" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
      <!-- bullet list -->
    </button>
    <button class="tt_ordered_list" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
      <!-- ordered list -->
    </button>
    <button class="tt_images division_btn" @click="addImage">
      <!-- ordered list -->
      <!-- image -->
    </button>
    <button @click="editor.chain().focus().setHorizontalRule().run()">
      <!-- horizontal rule -->
      &#95; &#95;
    </button>
    <button class="tt_undo division_btn" @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">
      <!-- undo -->
    </button>
    <button class="tt_redo" @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()">
      <!-- redo -->
    </button>
  </div>
  <editor-content :class="'tiptap_text'" :editor="editor" />
</template>

<script>

import TextAlign from '@tiptap/extension-text-align'
import Image from '@tiptap/extension-image';
import StarterKit from "@tiptap/starter-kit"; // starterKit를 사용하면 에디터 extensions를 하나씩 import하지 않고 starterKit에 내장되어 있는 extensions을 사용할 수 있다.
import { Editor, EditorContent } from "@tiptap/vue-3";
export default {
  components: {
    EditorContent
  },

  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      editor: null,
    };
  },

  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value;

      // JSON으로 변환시 사용
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit, 
        Image, 
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
      ],
      content: this.modelValue,
      onUpdate: () => {
        this.$emit("update:modelValue", this.editor.getHTML());

        // JSON일 경우
        // this.$emit("update:modelValue", this.editor.getJSON());
      },
    });
  },
  methods: {
    addImage() {
      const url = window.prompt('URL')

      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run()
      }
    },
  },
  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>
