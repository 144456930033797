<template>
    <div class="notFound">
        <h1>404</h1>
        <div class="test1">없는페이지 입니다</div>
        <a href="/home">Home 이동</a>
    </div>
</template>

<style lang="scss" scoped>
    .notFound{
        background-color: #aaa;
        color: #f00;
    }
</style>