<template>
    <div id="gnb">
        <ul>
            <li>
                <a href="/" router-link>Home</a>
            </li>
            <li>
                <a href="/data" router-link>Data</a>
            </li>
            <li>
                <a href="/gragh" router-link>Graph</a>
            </li>
            <li>
                <a href="/timeController" router-link>Time Controller</a>
            </li>
            <li>
                <a href="/map" router-link>Naver Map</a>
            </li>
            <li>
                <a href="/aggrid" router-link>AG grid</a>
            </li>
        </ul>
    </div>
  </template>
  
  <script>
    export default {
        name: 'GlobalNavBar'
    }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    h3 {
        margin: 40px 0 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }
  </style>
  