<template>
  <div>
    <div class="color-scale" ref="colorScale"></div>
    <svg style="width:400px; height:400px;" ref="confusionMatrix"></svg>
  </div>
  <div>
    <svg width="200" height="100">
      
      <rect x="0" y="0" width="100" height="50" style="fill: rgb(251, 116, 85);">
        
      </rect>
      <text x="0" y="0" font-size="20" fill="#000">안녕하세요!</text> 
    </svg>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  mounted() {
    // Confusion Matrix 데이터

    
    const data = [
      [10, 2, 3, 0],
      [1, 15, 2, 1],
      [0, 3, 12, 2],
      [1, 2, 1, 8],
    ];

    // 최대값과 최소값 계산
    const maxValue = d3.max(data, (row) => d3.max(row));
    const minValue = d3.min(data, (row) => d3.min(row));

    // 커스텀 색상 척도 생성
    const colorScale = d3.scaleSequential((t) => d3.interpolateReds(1 - t)).domain([minValue, maxValue]);

    // SVG 요소 크기 및 여백 설정
    const margin = { top: 30, right: 180, bottom: 30, left: 30 };
    const width = 480 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    // D3.js 스케일 설정
    const x = d3.scaleBand().domain(d3.range(data.length)).range([0, width]);
    const y = d3.scaleBand().domain(d3.range(data[0].length)).range([0, height]);

    // 커스텀 색상 척도 막대 바 생성
    const customColorScale = d3
      .select(this.$refs.colorScale)
      .append('svg')
      .attr('width', 40) // 레이블을 표시하기 위해 너비를 늘림
      .attr('height', height)
      .append('g')
      .selectAll('g')
      .data(d3.range(11))
      .enter()
      .append('g')
      .attr('transform', (d) => `translate(0, ${(height / 10) * d})`);

    customColorScale
      .append('rect')
      .attr('x', 0)
      .attr('width', 20)
      .attr('height', height / 10)
      .style('fill', (d) => colorScale((d / 10) * (maxValue - minValue) + minValue));

    customColorScale
      .append('text')
      .attr('x', 25) // 텍스트를 막대 바 오른쪽에 표시
      .attr('y', height / 20) // 텍스트를 중앙에 정렬
      .attr('dy', '0.35em')
      .text((d) => d3.format('.1f')((d / 10) * (maxValue - minValue) + minValue));

    // SVG 요소 생성
    const svg = d3
      .select(this.$refs.confusionMatrix)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

// Rect 요소를 사용하여 Confusion Matrix 시각화
  svg
      .selectAll('rect')
      .data(data.flat())
      .enter()
      .append('rect')
      .attr('x', (d, i) => x(i % data.length))
      .attr('y', (d, i) => y(Math.floor(i / data.length)))
      .attr('width', x.bandwidth())
      .attr('height', y.bandwidth())
      .style('fill', (d) => colorScale(d));
      

  
  svg
      .selectAll('text')
      .data(data.flat())
      .enter()
      .append('text')
      .attr("dy", ".32em")
      .attr('x', (d, i) => (x(i % data.length)) + (x.bandwidth() / 2) )
      .attr('y', (d, i) => (y(Math.floor(i / data.length))) + (y.bandwidth() / 2) )
      .text(function(d) { return d; })
      .attr("text-anchor", "middle")
      .style("fill", function(d){

          const minVal = d3.min(data.flat());
          const maxVal = d3.max(data.flat()); 
      
          const mp = (minVal + maxVal) / 2;
          if(d > mp){
          return "#000";
          }else{
          return "#fff";
          }
      });


      
    svg
      .selectAll('text' )
      .data(data.flat())
      .enter()
      .append('text')
      .attr("dy", ".32em")
      .attr('x', (d, i) => (x(i % data.length)) + (x.bandwidth() / 2) )
      .attr('y', (d, i) => (y(Math.floor(i / data.length))) + (y.bandwidth() / 2) )
      .text(function(d) { return d; })
      .attr("text-anchor", "middle")
      .style("fill", function(d){

        const minVal = d3.min(data.flat());
        const maxVal = d3.max(data.flat());
        
        console.log(minVal);
        console.log(maxVal);

        console.log("--------");
        console.log(d);
        const mp = (minVal + maxVal) / 2;
        if(d > mp){
          return "#000";
        }else{
          return "#fff";
        }
        

      });
      
      // .text(function(d) { return d; });



    // 최대값과 최소값 눈금 생성
    svg
      .append('text')
      .attr('x', width + 10)
      .attr('y', -5)
      .text(`Max: ${maxValue.toFixed(1)}`)
      .style('font-size', '12px');

    svg
      .append('text')
      .attr('x', width + 10)
      .attr('y', height + 15)
      .text(`Min: ${minValue.toFixed(1)}`)
      .style('font-size', '12px');

  },
};
</script>

<style>
.color-scale {
  float: left;
  margin-right: 10px;
  height: 400px; /* Confusion Matrix와 같은 높이로 설정 */
}
</style>
