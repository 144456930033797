<template>
    <div id="containser">
        <h1>Controller</h1>
        <div>
            <h3>test data</h3>
            <div>yyyy-MM-DD-hh-mm</div>
            <div id="startTime">2023-05-16-02-30</div>
            <div id="endTime">2023-05-16-02-35</div>
        </div>
        <div class="contens">
            <div class="slidecontainer">
                <div class="cont_btn_wrap">
                    <button type="button" id="startPoint">{</button>
                    <button type="button" id="backSeconds">&lt;&lt;</button>
                    <button type="button" id="playPause">play</button>
                    <button type="button" id="forwardSeconds"> &gt;&gt;</button>
                    <button type="button" id="endPoint">}</button>
                </div>
                <p>Custom range slider:</p>
                <input id="exIn" type="range" min="0" max="10000" value="0" class="slider">
                <div id="exOut">0</div>
                <div id="totalTime">
                    <strong>Total :</strong> <span>hh:mm:ss</span>
                </div>
                <div id="remainingTime">
                    <strong>Remaining :</strong> <span>00:00:00</span>
                </div>
                <div id="currentTime">
                    <strong>Current :</strong> <span>00:00:00</span>
                </div>
                <input type="text" id="dataIn" placeholder="HH:MM:SS">
            </div>
            <div class="making_wrap">
                <ul id="makingList"> </ul>
            </div>
        </div>
    </div>
    <div style="padding: 0 0 300px;"></div>


    <PaginationUi
      :totalItems="totalItems"
      :itemsPerPage="itemsPerPage"
      @page-changed="onPageChanged"
    ></PaginationUi>
</template>
<script>
import $comm from '@/assets/js/common.js'
import PaginationUi from "@/components/PagenationUi.vue";
export default {
  components: {
    PaginationUi,
  },
  data(){ 
    return {
        totalItems: 100,
        itemsPerPage: 10,
        currentPage: 1,
    }
  },
  mounted() {
        // common.$test.testFn("asd");
        $comm.$ctrl.qselectID("#ex-in");

        // time element
        const $exIn = document.querySelector('#exIn');
        const $exOut = document.querySelector('#exOut');
        const $currentTime = document.querySelector('#currentTime');
        const $remainingTime = document.querySelector('#remainingTime');
        const $dataIn =  document.querySelector("#dataIn");

        function calculateSeconds(startYear, startMonth, startDay, startHour, startMinute, endYear, endMonth, endDay, endHour, endMinute) {
            // 시작 시간을 Date 객체로 변환
            var startDate = new Date(startYear, startMonth - 1, startDay, startHour, startMinute);
            
            // 종료 시간을 Date 객체로 변환
            var endDate = new Date(endYear, endMonth - 1, endDay, endHour, endMinute);
            
            // 시작 시간과 종료 시간의 차이 계산 (밀리초 단위)
            var difference = endDate.getTime() - startDate.getTime();
            
            // 차이를 초 단위로 변환
            var seconds = Math.floor(difference / 1000);
        
            return seconds;
        }

        let startTime = document.querySelector('#startTime').textContent; 
        startTime = startTime.split('-');

    

        let endTime = document.querySelector('#endTime').textContent; 
        endTime = endTime.split('-');

        // 사용 예시
        const startYear = Number(startTime[0]);
        const startMonth = Number(startTime[1]);
        const startDay = Number(startTime[2]);
        const startHour = Number(startTime[3]);
        const startMinute = Number(startTime[4]);

        const endYear = Number(endTime[0]);
        const endMonth = Number(endTime[1]);
        const endDay = Number(endTime[2]);
        const endHour = Number(endTime[3]);
        const endMinute = Number(endTime[4]);

        const totalTime = calculateSeconds(startYear, startMonth, startDay, startHour, startMinute, endYear, endMonth, endDay, endHour, endMinute);
        console.log(totalTime); // 결과 출력

        $exIn.setAttribute('max' ,totalTime);

        $exIn.getAttribute('value');

        // ss > hh:mm:ss 
        function convertSecondsToTime(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;
            
            const formattedTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + remainingSeconds.toString().padStart(2, '0');
            
            return formattedTime;
        }

        // hh:mm:ss > ss 
        function convertTimeToSeconds(str){
            
            const _strLength = str.length;
            if(_strLength > 8 ){
                alert("잘못된 형식의 내용입니다. \n convertTimeToSeconds");
                return -1;
            } else {
                let _process = str.split(":");
                let _hours = Number(_process[0]);
                let _minutes = Number(_process[1]);
                let _seconds = Number(_process[2]);

                let totalSeconds = (_hours * 3600) + (_minutes * 60) + _seconds;
                
                return totalSeconds;
            }
            
        }

        const totalTimeData= convertSecondsToTime(totalTime);

        let $totalTime = document.querySelector("#totalTime").querySelector("span");
        $totalTime.innerText = totalTimeData;

        // btn element
        const $startPoint = document.querySelector('#startPoint');
        const $endPoint = document.querySelector('#endPoint');
        const $playPause = document.querySelector('#playPause');
        const $forwardSeconds = document.querySelector('#forwardSeconds');
        const $backSeconds = document.querySelector('#backSeconds');
        const $delMarking = document.querySelector('#makingList');
        
        // time setinterval
        let nowSeconds = 0;
        let rangeStatus = "play"; 
        let rangeSliderInterval;
        function rangeSliderIntervalStartTask(){
            rangeSliderInterval = setInterval(() => {
                // console.log(nowSeconds + '초');
                $exIn.value = Number(nowSeconds);
                // txt 
                timeTxtChainge(nowSeconds);
                nowSeconds++;
                //end
                if (Number(nowSeconds) > totalTime) {
                    clearInterval(rangeSliderInterval);
                    playPauseClick();
                }
            }, 1000);
        } 
        rangeSliderIntervalStartTask();
    
        function rangeSliderIntervalStopTask(){
            clearInterval(rangeSliderInterval);
        }

        // range event 
        var isDragging = false;
        $exIn.addEventListener('mousedown', function() {
            isDragging = true;
            console.log('마우스 드래그 시작');
            // 재생 상태에서 드래그 시작경우 일시장지
            if(rangeStatus == "play"){
                playPauseClick();
            }
            
        });

        $exIn.addEventListener('mousemove', function() {
            if (isDragging) {
            // console.log('드래그 중');
            // console.log('현재 값:', e.target.value);
            }
        });

        $exIn.addEventListener('mouseup', function(e) {
            if (isDragging) {
                isDragging = false;
                console.log('마우스 드래그 종료');
                console.log('최종 값:', e.target.value);
                playPauseClick();
                secondsChange(e.target.value);
                // 일시정지 상태에서 드래그 끝
                if(rangeStatus == "pause"){
                    playPauseClick();
                }
            }
        });

        //  time marking array 
        /*
            array index : making number
            array in object : making data 
            
            array sample
            timeMarkingArray = [
                {
                    "point" : [start point ss, end point ss ],
                    "time" : [start time, end time],
                    "color" : "#000000",
                    "position" : [start % , end %],
                    "width" : ""

                },
                ...

            ] 
        */
        const colorList = ['#000000','#000000','#000000','#000000','#000000'] ;
        let timeMarkingArray =[];
        let makingCount = 0;
        
        // controller fn
        function calculatePercentage(now, tatal) {
            const percentage = (now / tatal) * 100;
            const roundedPercentage = percentage.toFixed(2);
            return parseFloat(roundedPercentage);
        }

        function calculatePercentageWidth(start, end, tatal) {
            const size = end - start; 
            const percentage = (size / tatal) * 100;
            const roundedPercentage = percentage.toFixed(2);
            return parseFloat(roundedPercentage);
        }

        

        const $markingListWrap = document.querySelector("#makingList");

        function addMakingList(mstart, mend, index){
            
            const liElement = document.createElement("li");

            const htmlTag = '<div class="start_making">start time : <span>'+ mstart +'</span> </div>'+
                            '<div class="end_making">mking time : <span>'+ mend +'</span> </div>'+
                            '<button type="button">delete</button>';
                            
            liElement.innerHTML = htmlTag;

            $markingListWrap.appendChild(liElement);
             // delete btn 생성 후 핸들러 등록 

            let _$delBtn = $delMarking.querySelectorAll("button");

            console.log(_$delBtn[index]);

            _$delBtn[index].addEventListener('click', function(event){
                
                const _parentElement = event.target.parentNode;
                let _index = Array.prototype.indexOf.call(_parentElement.parentNode.children, _parentElement);
                
                if(_index >= 0 && _index < _parentElement.parentNode.children.length) {
                    const liToRemove = _parentElement.parentNode.children[_index];
                    _parentElement.parentNode.removeChild(liToRemove);
                    timeMarkingArray.splice(_index,1);
                    --makingCount
                }
            });

        }
        

        function startPointClick() {
            console.log("------------------");
            console.log('startPointClick');
            console.log(nowSeconds);
            if(makingCount == timeMarkingArray.length){
                let _nowSeconds = Number(nowSeconds);
                let _convertSecondsToTime = convertSecondsToTime(_nowSeconds);
                let _calculatePercentage = calculatePercentage(_nowSeconds, totalTime);
                timeMarkingArray.push({});
                timeMarkingArray[makingCount]["point"] =[];
                timeMarkingArray[makingCount]["point"].push(_nowSeconds);

                timeMarkingArray[makingCount]["time"] =[];
                timeMarkingArray[makingCount]["time"].push(_convertSecondsToTime);

                timeMarkingArray[makingCount]["position"] =[];
                timeMarkingArray[makingCount]["position"].push(_calculatePercentage);

                timeMarkingArray[makingCount]["color"] = colorList[makingCount];
                console.log(timeMarkingArray);
                console.log(timeMarkingArray.length);
            }else if(makingCount < timeMarkingArray.length){
                alert("이미 선택 하셨습니다. \n" + timeMarkingArray[makingCount]["point"][0]);
            }
        }
        function endPointClick() {
            console.log("------------------");
            console.log('endPointClick');
            console.log(nowSeconds);
            if(makingCount < timeMarkingArray.length && timeMarkingArray[makingCount]["point"].length < 2){
                let _nowSeconds = Number(nowSeconds);
                let _convertSecondsToTime = convertSecondsToTime(_nowSeconds);
                let _calculatePercentage = calculatePercentage(_nowSeconds, totalTime);
                

                timeMarkingArray[makingCount]["point"].push(_nowSeconds);
                // startTime > endTime
                if( timeMarkingArray[makingCount]["point"][0] > timeMarkingArray[makingCount]["point"][1] ){
                    alert("완료 시점은 시작 시점을 초과 할수 없습니다. \n 시작포인트 부터 다시 마킹해주세요, ");
                    timeMarkingArray.splice(makingCount,1);
                    console.log(timeMarkingArray);
                }else{

                    timeMarkingArray[makingCount]["time"].push(_convertSecondsToTime);

                    timeMarkingArray[makingCount]["position"].push(_calculatePercentage);

                    let _calculatePercentageWidth = calculatePercentageWidth(timeMarkingArray[makingCount]["point"][0], timeMarkingArray[makingCount]["point"][1], totalTime);
                    timeMarkingArray[makingCount]["width"] = _calculatePercentageWidth;

                    console.log(timeMarkingArray);
                    console.log(timeMarkingArray.length);

                    let _startMaking = timeMarkingArray[makingCount]["time"][0];
                    let _endMaking = timeMarkingArray[makingCount]["time"][1];

                    addMakingList(_startMaking, _endMaking, makingCount);    
                    makingCount++;
                }
                

                

            }else{
                alert("시작 마킹을 먼저 하세요");
                
            }
        }
        
        function playPauseClick() {
            console.log('playPauseClick');
            if(rangeStatus == "play"){
                rangeStatus = "pause";
                rangeSliderIntervalStopTask();
                $playPause.textContent = "pause";

            }else if(rangeStatus == "pause" && Number(nowSeconds) < totalTime){
                rangeStatus = "play";
                rangeSliderIntervalStartTask();
                $playPause.textContent = "play";
            }
            console.log(rangeStatus);
        }

        let secondsData = 3;
        function secondsChange(ss){
            nowSeconds = ss;
            $exIn.value = Number(nowSeconds);
            timeTxtChainge(ss);

        }
        function forwardSecondsClick() {
            let _seconds = Number(nowSeconds) + secondsData;
            console.log('forwardSecondsClick');
            console.log(_seconds);
            if(_seconds < totalTime){
                secondsChange(_seconds);
            }else{
                secondsChange(totalTime);
            }
            // totalTime > totalTime == totalTime
            
        }
        function backSecondsClick() {
            let _seconds = Number(nowSeconds) - secondsData;
            console.log('backSecondsClick');
            console.log(nowSeconds - secondsData);
            if(_seconds < 0){
                secondsChange(0);
            }else{
                secondsChange(_seconds);
            }
            // nowSeconds < 0  == 0 
        }

        function timeTxtChainge(val) {
            let _rangeVal = val;
            let _RemainingVal = totalTime - _rangeVal;
            $exOut.innerHTML= _rangeVal;
            $currentTime.querySelector('span').innerHTML= convertSecondsToTime(_rangeVal);
            $remainingTime.querySelector('span').innerHTML= convertSecondsToTime(_RemainingVal);
            $dataIn.value = convertSecondsToTime(_rangeVal);
        }
        

        // EventListener
        $startPoint.addEventListener('click', startPointClick);
        $endPoint.addEventListener('click', endPointClick);
        $playPause.addEventListener('click', playPauseClick);
        $forwardSeconds.addEventListener('click', forwardSecondsClick);
        $backSeconds.addEventListener('click', backSecondsClick);

        // input time / evnet
        $dataIn.addEventListener("keydown", function(e){
            const _val = this.value.trim();
            
            if(e.keyCode === 13){
                /*
                    Tatal tiam 초과하지 못한다
                    hh:mm:ss 형식의 데이터를 입력 해야한다
                */
                console.log("click enter");
                console.log("_val : " + _val );
                let _typeData = convertTimeToSeconds(_val);
                console.log("enter : " + _typeData);
                // 텍스트 형식 검사  
                if(_typeData > -1){
                    // _typeData > tatalTime 
                    if(_typeData >= totalTime){
                        secondsChange(totalTime);
                    }else{
                        secondsChange(_typeData);
                    }
                }else{
                    // 숫자가 아닌 다른 형식
                    alert("잘못된 형식의 내용입니다. \n keyCode==13");
                    
                }
                
            }
        });

        // focus 
        $dataIn.addEventListener("focus", function() {
            // focus 이벤트 발생 시 실행할 코드 작성
            // console.log("Input에 focus가 되었습니다.");
            if(rangeStatus == "play"){
                /* renge data end > renge back > focus > play Error */  
                playPauseClick();
            }
        });
        $dataIn.addEventListener("blur", function() {
            // blur 이벤트 발생 시 실행할 코드 작성
            // console.log("Input에서 focus가 해제되었습니다.");
            playPauseClick();
        });
    }
  
}
</script>



<style lang="scss" scoped>
    .slidecontainer {
        width: 100%;
        .slider {
            -webkit-appearance: none;
            width: 100%;
            height: 25px;
            background: #d3d3d3;
            outline: none;
            opacity: 0.7;
            -webkit-transition: .2s;
            transition: opacity .2s;
            &:hover {
                opacity: 1;
            }
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 25px;
                height: 25px;
                background: #04AA6D;
                cursor: pointer;
            }
            &::-moz-range-thumb {
                width: 25px;
                height: 25px;
                background: #04AA6D;
                cursor: pointer;
            }
        }
    }
    .cont_btn_wrap{
        button{
            padding: 5px 30px;
        }
    }

    .making_wrap{
        width: 80%;
        margin: 50px auto 0;
        border: 2px solid #000;
        
        ul{
            text-align: left;
            li{
                margin: 15px 0;
            }
        }
    }
</style>