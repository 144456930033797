<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap" v-bind:style="taskId === '' ? '' : 'display:none'">
                <div class="breadcrumb">
                    <span>데이터/ TASK 운영</span>
                    <span>모니터링 TASK 목록</span>
                    <span>데이터 상세 </span>
                    <span class="active">모니터링 TASK - 신규 등록</span>
                </div>
                <h2>모니터링 TASK - 신규 등록</h2>
            </div>
            <div class="title_wrap" v-bind:style="taskId === '' ? 'display:none' : ''">
                <div class="breadcrumb">
                    <span>데이터/ TASK 운영</span>
                    <span>모니터링 TASK 목록</span>
                    <span>데이터 상세 </span>
                    <span class="active">모니터링 TASK 관리</span>
                </div>
                <h2>모니터링 TASK 관리</h2>
            </div> -->
            <BreadcrumbLayout :pageId="pageId" :subParams="bcParams" :subItems="bcSubItems"/>
            <div id="management">
                <div class="row_box two_item">
                    <div>
                        <div class="data_key">등록자명</div>
                        <div class="data_val">
                            <input type="text" name="rgstNm" id="rgstNm" :value="rgstNm" disabled>
                        </div>
                    </div>
                    <div>
                        <div class="data_key">등록 일자</div>
                        <div class="data_val">
                            <input type="text" name="rgstDt" id="rgstDt" :value="rgstDt" disabled>
                        </div>
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">제목</div>
                    <div class="data_val" v-bind:class="{ failure : dupMonTaskNm}">
                        <input type="text" ref="itemTitle" v-model="itemTitle" maxlength="50" placeholder="제목을 입력해 주세요.">
                        <div class="text_length">
                            <span>{{itemTitle.length}}</span>/50
                        </div>
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">본문</div>
                    <div id="tiptap" class="data_val">
                        <tiptap v-model="contentTxt" ref="contentTxt"/>
                        <!-- <div class="content">
                            <h3>Content</h3>
                            <pre><code>{{ contentTxt }}</code></pre>
                        </div> -->
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">
                        첨부파일
                    </div>
                    <div class="data_val">
                        <input type="file" ref="uploadFile" multiple>
                    </div>
                </div>
                <div class="row_box two_item">
                    <div>
                        <div class="data_key">게시 상태</div>
                        <div class="data_val">
                            <ul>
                                <li class="input_radio">
                                    <input type="radio" checked v-model="itemStatus" value="p" name="openStatus" id="openItem">
                                    <label for="openItem">
                                        <div class="radio_icon"></div>
                                        게시
                                    </label>
                                </li>
                                <li class="input_radio"> 
                                    <input type="radio" v-model="itemStatus" value="u" name="openStatus" id="unopenItem">
                                    <label for="unopenItem">
                                        <div class="radio_icon"></div>
                                        게시 안함
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div class="data_key">공지 유형</div>
                        <div class="data_val">
                            <ul>
                                <li class="input_radio">
                                    <input type="radio" checked v-model="itemType" value="false" name="openType" id="nomalItem">
                                    <label for="nomalItem">
                                        <div class="radio_icon"></div>
                                        일반
                                    </label>
                                </li>
                                <li class="input_radio">
                                    <input type="radio" v-model="itemType" value="true" name="openType" id="importantItem">
                                    <label for="importantItem">
                                        <div class="radio_icon"></div>
                                        긴급
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="save_box">
                    <button type="button" @click="showModalAlert()">취소</button>
                    <button type="button" class="mcbtn" :disabled="!requireCheck" @click="clickRegist()">저장</button>
                </div>
            </div>
        </div>
    </div>
    <div id="modalWrap" ref="modalWrap" style="display: none;">
        <!-- 취소 -->
        <div class="radiusbox modal_contents message_alert message_modal" ref="modalAlert">
            <div class="message_box">
                변경 사항이 저장되지 않았습니다. <br>정말 취소하시겠습니까?
            </div>
            <button type="button" class="btn_check mcbtn" @click="backPage()">확인</button>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import Tiptap from '@/components/TiptapUi.vue'
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';
    import common from '@/assets/js/common'
    import { mapGetters } from 'vuex';



    export default {
        name: 'aaView',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            Tiptap,
            BreadcrumbLayout,
        },
        computed:{
            ...mapGetters(['getUserData']),

            itemTitleLen() {
                console.log(this.itemTitle);
                return this.itemTitle;
            },
            descLen() {
                return this.desc.length;
            },

            allSelctEdge: {
                //getter
                get: function() {
                    return this.edgeDeviceRowData.length === this.unSelectEdgeList.length;
                },
                //setter
                set: function(e) {
                    this.unSelectEdgeList = e ? this.edgeDeviceRowData : [];
                },
            },
            allSelctedEdge: {
                //getter
                get: function() {
                    return this.selectEdgeDeviceRowData.length === this.selectedEdgeList.length;
                },
                //setter
                set: function(e) {
                    this.selectedEdgeList = e ? this.selectEdgeDeviceRowData : [];
                },
            },
            // 저장버튼 활성필수 조건
            requireCheck() {
                return this.manageRole && this.itemTitle.length > 0 && this.extractTextContent(this.contentTxt).length > 0;
            },

            // VCalendar 
            picStartTxtStyle() {
                return {
                    color: this.picColor.startStatus ? this.picColor.active : this.picColor.default
                };
            },
            picEndTxtStyle() {
                
                return {
                    color: this.picColor.endStatus ? this.picColor.active : this.picColor.default
                };
            },
        },
        data(){
            const userData = ({});

            const picStartDate = null;
            const picEndDate = null;

            const edgeDeviceRowData = ([]);
            const selectEdgeDeviceRowData= ([]);
            
            return{
                contentTxt: "",
                itemTitle: "",
                itemStatus:"p",
                itemType: false,
                //
                userData,

                // -- VCalendarUi / .date_period
                maxDate: "9999-12-31",
                picStartDate,
                picEndDate,
                picColor: {
                    startStatus: false,
                    endStatus: false,
                    active: '#00B4ED',
                    default: '#020A0A'
                },


                

                itemID: "",
                
                desc: "",
                rgstNm: "",
                rgstDt: "",

                permSetChecked: [],


                edgeDeviceRowData,
                selectEdgeDeviceRowData,

                unSelectEdgeList: [],
                selectedEdgeList: [],
                mainEdgeDeviceList: [],

                // breadcrumb
                pageId: "communication_addNotice",
                bcParams: [],
                bcSubItems: [],

                originMonTaskNm: "",
                dupMonTaskNm: false,

                // role
                viewRole: false,
                manageRole: false,
            };
        },
        mounted() {
            
            // Role
            this.setRole();

            const prjId = this.$route.query.prjId;

            if ( typeof prjId !== "undefined" && prjId !== null && prjId !== "" ) {
                this.prjId = prjId;
                this.hasPrjId = true;
            }

            const getCKUser = this.getUserData;
            const getUser = this.$store.state.user;
            if(getCKUser == undefined){
                this.userData = getUser;
            }else{
                this.userData = getCKUser;
            }

            const itemID = this.$route.query.itemID;
            if ( typeof itemID != "undefined" && itemID != null && itemID != "" ) {
                this.itemID = itemID;
                this.setPage(this.itemID);
                this.pageId = "communication_addNotice_correction";
                this.setBreadcrumb();
            } else {
                this.pageId = "communication_addNotice";
                this.rgstNm = this.userData.name + ' | ' + common.nullCheck(this.userData.teamName);
                this.rgstDt = common.getNowDt();

                const start_formattedDate = common.getNowDt();
                const end_formattedDate = common.getNowDt();
                
                this.picStartDate = start_formattedDate;
                this.picEndDate = end_formattedDate;
                window.selectDate.startDate = start_formattedDate;
                window.selectDate.endDate = end_formattedDate;
            }

            
        },
        methods: {
            // tiptap
            extractTextContent(html){
                const tempElement = document.createElement("div");
                tempElement.innerHTML = html;

                return tempElement.textContent || tempElement.innerText
            },

            async setRole() {
                const prjId = this.$route.query.prjId;
                
                if(typeof prjId !== "undefined" && prjId !== null && prjId !== "") {
                    this.manageRole = ( await common.getUserRole('type2') || await common.getPrjRole('type2', prjId) );
                    this.viewRole = ( await common.getUserRole('type2') || await common.getPrjRole('type2', prjId) );
                } else {
                    this.manageRole = await common.getUserRole('type1');
                    this.viewRole = await common.getUserRole('type1');
                }

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            setBreadcrumb() {
                const taskTypeId = this.$route.query.taskTypeId;
                let name = "";
                let url = "";

                switch(taskTypeId) {
                    // Remote Diagnostics
                    case '1': 
                        url = 'dctDashboard';
                        name = 'Remote Diagnostic Service 대시보드';
                        break;
                    // Anomaly Detection
                    case '2': 
                        url = 'moaDetection';
                        name = 'Motor Output Anomaly Detection 상세';
                        break;
                    // Vision Edge Inference Monitoring
                    case '3': 
                        url = 'EdgeInfer';
                        name = 'Edge Inference 모니터링';
                        break;
                    // Vision Auto Labeling Monitoring
                    case '4': 
                        url = 'AutoLabe';
                        name = 'Auto Labeling 검토 상세';
                        break;
                    // Vision AI/ML Statistics
                    case '5': 
                        url = 'VisionData';
                        name = 'Vision 데이터 처리 통계';
                        break;
                }

                this.bcSubItems.push({
                    name: name,
                    url: url,
                });

                this.bcParams.push({
                    prjId: this.prjId, 
                    taskId: this.taskId, 
                    // teamId: item.teamId,
                    taskTypeId: taskTypeId,
                });

                
            },

            async setPage(itemId) {
                
                
                const data = await this.getDetailData(itemId);
                
                if ( typeof data != "undefined" && data != null && data != "" ) {
                    this.rgstNm = data.registerName;
                    this.rgstDt = data.created;    
                    this.itemTitle = data.title;
                    this.contentTxt = data.description;
                    this.itemType = data.important;
                    if(data.status === '게시'){
                        this.itemStatus = 'p';
                    }else{  
                        this.itemStatus = 'u';
                    }
                    

                }
            },
            async getDetailData(itemID) {
                try {
                    const response = await common.apiGet('/notice/'+itemID);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async setPrjSelect() {
                // 231012   
                // this.prjId 데이터가 있는지 구분자
                let prjData;
                if(this.prjId){
                    prjData = await this.getPrjData(this.prjId);
                    if(typeof prjData !== "undefined" && prjData !== null && prjData !== "") {
                        this.prjNm = prjData.project.project_name;
                    }
                }else{
                    prjData = await this.getPrjListData();
                    this.prjList = prjData;
                }
            },
             
            async setSelectBox() {
                this.monTaskAccessList = await this.getMonTaskAccessList();
                this.monTaskTypeList = await this.getMonTaskTypeList();
            },

            setPrjId(event) {
                
                this.prjId = event.target.value;

                this.selectEdgeDeviceRowData = ([]);
                this.mainEdgeDeviceList = [];

            },

            setMonTaskAccess(event) {
                this.monTaskAccessId = event.target.value;
            },

            setMonTaskType(event) {
                this.monTaskTypeId = event.target.value;
            },

            // ----- click
            async clickSearchDevice() {
                const keyword = this.$refs.edgeKeyword.value.toUpperCase();

                if(typeof keyword == "undefined" || keyword == null || keyword == "") {
                    const prjId = this.prjId;
                    const getDeviceList = this.getDeviceList(prjId);
                    this.setDeviceTable(getDeviceList);

                } else {
                    this.setSearchDevice(keyword);
                }
            },

            setSearchDevice(keyword) {
                const searchRow = [];
    
                this.edgeDeviceRowData.forEach(item => {
                    let edgeDeviceId = common.getUpper(item['edgeDeviceId']);
                    let edgeDeviceName = common.getUpper(item['edgeDeviceName']);
                    let status = common.getUpper(item['status']);

                    if ( edgeDeviceId.includes(keyword) || edgeDeviceName.includes(keyword) || status.includes(keyword) ) {
                        searchRow.push(item);
                    }
                });

                this.edgeDeviceRowData = ([]);

                searchRow.forEach(item => {
                    this.edgeDeviceRowData.push(item);
                });
            },
            
            clickAddDevice() {
                if ( this.unSelectEdgeList.length > 0 ) {
                    this.unSelectEdgeList.forEach(item => {
                        this.selectEdgeDeviceRowData.push(item);
                    });

                    this.edgeDeviceRowData = common.deleteRowData(this.edgeDeviceRowData, this.unSelectEdgeList, 'id');
                    this.unSelectEdgeList = [];
                }
            },
            
            clickDelDevice() {
                if ( this.selectedEdgeList.length > 0 ) {
                    this.selectedEdgeList.forEach(item => {
                        this.edgeDeviceRowData.push(item);
                    });
                    
                    this.selectEdgeDeviceRowData = common.deleteRowData(this.selectEdgeDeviceRowData, this.selectedEdgeList, 'id');
                    this.selectedEdgeList = [];

                    const keyword = this.$refs.edgeKeyword.value.toUpperCase();
                    if ( typeof keyword != "undefined" && keyword != null && keyword != "" ) {
                        this.setSearchDevice(keyword);
                    }
                }
            },

            clickCompleteDevice() {
                this.mainEdgeDeviceList = [];
                this.selectEdgeDeviceRowData.forEach(item => {
                    this.mainEdgeDeviceList.push(item);
                });
                this.closeModal();
            },
            
            clickDelEdgeList(id) {
                let i = this.mainEdgeDeviceList.length;
                while ( i-- ) {
                    let item = this.mainEdgeDeviceList[i];
                    if ( item.id == id ) {
                        this.mainEdgeDeviceList.splice(i, 1);
                        this.edgeDeviceRowData.push(item);
                    }
                }
                let j = this.selectEdgeDeviceRowData.length;
                while ( j-- ) {
                    let item = this.selectEdgeDeviceRowData[j];
                    if ( item.id == id ) {
                        this.selectEdgeDeviceRowData.splice(j, 1);
                    }
                }
            },

            async clickRegist() {
                const allowRegist = await this.checkAllowAccessCreateBtn();
                const hasItemID = this.itemID;

                // console.log(allowRegist);
                if ( this.requireCheck === true && allowRegist) {
                    const formData = new FormData();
                    const itemTitle = this.itemTitle;
                    const itemTxt = this.contentTxt;
                    const itemStatus = this.itemStatus;
                    
                    const fileUpload = this.$refs.uploadFile.files;
                    let itemType; 
                    if(this.itemType === "true"){
                        itemType = true;
                    }else{
                        itemType = false;
                    }

                    if ( !this.dupMonTaskNm ) {
                        
                        const postData = {
                            "title": itemTitle,
                            "description": itemTxt,
                            "important": itemType,
                            "status": itemStatus
                        };
                        // 등록
                        let responseCode = "";

                        if(hasItemID !== ''){
                            console.log("수정");
                            
                            const amendNoticeItem = await this.amendNoticeItem(postData, hasItemID);
                            
                            try {
                                responseCode = amendNoticeItem['code'];
                            } catch (error) {
                                responseCode = 500;
                            }
                            // 파일등록
                            if ( typeof fileUpload != "undefined" && fileUpload != null && fileUpload != "" && responseCode !== 500) {
                                for(let i = 0; i<fileUpload.length; i++) {
                                    formData.append("multipartFile", fileUpload[i]);
                                }
                                
                                // console.log(typeof formData.get('multipartFile'));
                                const response = await this.addNoticeItemfile(formData, hasItemID);
                                // const response = await this.addNoticeItemfile(formData, 20);

                                try {
                                    responseCode = response['data']['code'];
                                } catch (error) {
                                    responseCode = 500;
                                }

                            }
                        }else{
                            console.log("신규등록");

                            const noticeItem = await this.addNoticeItem(postData);
                            
                            // console.log(noticeItem['data'])
                            try {
                                responseCode = noticeItem['code'];
                            } catch (error) {
                                responseCode = 500;
                            }
                            // 파일등록
                            if ( typeof fileUpload != "undefined" && fileUpload != null && fileUpload != "" && responseCode !== 500) {
                                for(let i = 0; i<fileUpload.length; i++) {
                                    formData.append("multipartFile", fileUpload[i]);
                                }
                                
                                // console.log(typeof formData.get('multipartFile'));
                                const response = await this.addNoticeItemfile(formData, noticeItem['data']);
                                // const response = await this.addNoticeItemfile(formData, 20);

                                try {
                                    responseCode = response['data']['code'];
                                } catch (error) {
                                    responseCode = 500;
                                }

                            }
                        }

                        
                        
        
                        if ( responseCode === 200 || responseCode === "200" ) {
                            // console.log("리스트로 이동")
                            this.$router.push('/commu/notice');
                        }
                    }

                } else if(!allowRegist) {
                    alert("SM 권한만 등록이 가능합니다.");
                    return;
                }

            },


            async getCheckYn(list, value) {
                let result = 'N';

                list.forEach(item => {
                    if ( item === value ) {
                        result = 'Y';
                    }
                });

                return result;
            },

            async getDupResult(response) {
                if ( typeof response !== "undefined" && response !== null && response !== "" ) {
                    // console.log(response)
                    const status = response.status;
                    const code = response.code;

                    if ( status === 200 && code === '200' ) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            },

            setDeviceTable(deviceList){
                deviceList.then(list => {
                    const dataList = [];
                    list.forEach(element => {
                        dataList.push({
                            id: element.id,
                            edgeDeviceId: element.edge_device_id,
                            edgeDeviceGroup: element.edge_device_group,
                            edgeDeviceName: element.edge_device_name,
                            status: element.status,
                        });
                    });
                    // this.edgeDeviceRowData = common.deleteRowData(dataList, this.mainEdgeDeviceList, 'id');
                    this.edgeDeviceRowData = common.deleteRowData(dataList, this.selectEdgeDeviceRowData, 'id');
                });
            },

            setDeviceData(deviceList){
                const dataList = [];
                deviceList.forEach(item => {
                    dataList.push({
                        id: item.id,
                        edgeDeviceId: item.edge_device_id,
                        edgeDeviceGroup: item.edge_device_group,
                        edgeDeviceName: item.edge_device_name,
                        status: item.status,
                    });

                });
                return dataList;
            },
            
            // ----- modal
            async showModalDevice(){
                const $modal = this.$refs.modalWrap;
                const $modalDevice = this.$refs.modalDevice;
                $modal.style.display = "block";
                $modalDevice.style.display = "block";

                this.$refs.edgeKeyword.value = "";

                const prjId = this.prjId;
                const getDeviceList = this.getDeviceList(prjId);

                this.selectEdgeDeviceRowData = ([]);
                this.mainEdgeDeviceList.forEach(item => {
                    this.selectEdgeDeviceRowData.push(item);
                });

                this.setDeviceTable(getDeviceList);
            },
            showModalAlert(){
                const $modal = this.$refs.modalWrap;
                const $modalAlert = this.$refs.modalAlert;
                $modal.style.display = "block";
                $modalAlert.style.display = "block";
            },

            closeModal(){
                const $modal = this.$refs.modalWrap;
                const $modalDevice = this.$refs.modalDevice;
                const $modalAlert = this.$refs.modalAlert;
                $modal.style.display = "none";
                $modalDevice.style.display = "none";
                $modalAlert.style.display = "none";
            },
            backPage(){
                this.$router.push('/commu/notice');
            },

            // -------- DATE PICKER -----
            datePicToggle(){
                const vm = this;
                const _display = vm.$refs.calenderBox.style.display;
                const _startDateBox = vm.$refs.startDateBox.style.display;
                
                if(_display == 'block'){
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'none';
                    vm.picColor.startStatus = false;
                    vm.picColor.endStatus = false;

                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'block';
                    if(_startDateBox == 'block'){
                        vm.picColor.startStatus = true;
                        vm.picColor.endStatus = false;
                    }else if( _startDateBox == 'none'){
                        vm.picColor.startStatus = false;
                        vm.picColor.endStatus = true;
                    }
                }
            },

            onDatePic(flag){
                if(flag == 'S'){   
                    if(window.selectDate.startDate !== null ){
                        this.picStartDate = window.selectDate.startDate;
                    }
                    
                }else if(flag == 'E'){
                    if(window.selectDate.picEndDate !== null ){
                        this.picEndDate = window.selectDate.endDate;
                    }
                }
            },
            pickerNext(){
                this.$refs.startDateBox.style.display = 'none';
                this.$refs.endDateBox.style.display = 'block';

                this.picColor.startStatus = false;
                this.picColor.endStatus = true;
                
            },
            pickerPrev(){
                this.$refs.startDateBox.style.display = 'block';
                this.$refs.endDateBox.style.display = 'none';

                this.picColor.startStatus = true;
                this.picColor.endStatus = false;
            },
            // ------------

            async checkAllowAccessCreateBtn() {
                const role = await this.getMemberRole();
                
                // SM만 등록 가능
                if(role != null && role.role == 'SM') {
                    return true;
                }
            },

             // ----- API

             async addNoticeItem(postData) {
                try {
                    const response = await common.apiPost('/notice', postData);
                    return response.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async amendNoticeItem(postData, itemID) {
                try {
                    const response = await common.apiPatch('/notice/'+itemID, postData);
                    return response.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async addNoticeItemfile(postData, itemID) {
                let response = "";
                try {
                    response = await common.apiPatchMulti('/notice/file/'+itemID, postData);
                } catch (error) {
                    response = error.response;
                    console.error('Error fetching data:', error);
                }
                // console.log(response);
                return response;
            },

            async getPrjListData() {
                try {
                    const response = await common.apiGet('/project?project-role=Researcher');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getPrjData(prjId) {
                try {
                    const response = await common.apiGet('/project/' + prjId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getTaskData(taskId, viewRole) {
                try {
                    const response = await common.apiGet(`/monitoring-task/${taskId}?viewRole=${viewRole}`);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getMonTaskAccessList() {
                try {
                    const response = await common.apiGet('/monitoring-task-accessibility');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getMonTaskTypeList() {
                try {
                    const response = await common.apiGet('/monitoring-task-type');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getDeviceList(prjId) {
                try {
                    const response = await common.apiGet('/edge-device/project/'+prjId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async checkMonTaskNm(postData) {
                try {
                    const response = await common.apiPost('/monitoring-task/check-name', postData);
                    return response.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async addMonTask(postData) {
                let response = "";
                try {                    
                    response = await common.apiPost('/monitoring-task', postData);
                } catch (error) {
                    response = error.response;
                    console.error('Error posting data:', error);
                }
                return response;
            },
            async updateMonTask(taskId, postData) {
                let response = "";
                try {                    
                    response = await common.apiPatch('/monitoring-task/'+taskId, postData);
                } catch (error) {
                    response = error.response;
                    console.error('Error posting data:', error);
                }
                return response;
            },
            
            
            
            //-------API------
            async getMemberRole() {
                let response;
                try {
                    let url = `/member/check-role`;
                    response = await common.apiGet(url);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },
            async getProjectRole() {
                let response;
                try {
                    let checkProjectRoleUrl = `/member/check-project-role/${this.prjId}`;
                    response = await common.apiGet(checkProjectRoleUrl);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },
        }
    }
</script>
<style scoped lang="scss">
#management{
    .row_box.toggle_item{
        .data_key{
            height: 50px;
            line-height: 50px;
        }
        .data_val{
            height: 50px;
            ul{
                height: 50px;
                li{
                    width: 210px;
                    margin-right: 15px;
                    &:last-child{
                        margin: 0;
                    }
                }
            }
            
        }
    }
    .table_list {
        .total_num{
            padding-bottom: 20px;
        }
    }
    .row_box {
        .data_val.search_from{
            width: calc(100% - 270px);
            input[type=text]{
                border-radius: 10px 0 0 10px;
            }
        }
        ul{
            padding-top: 10px;
        }
       .input_radio{
            display: inline-block;
            margin-right: 30px;
            .radio_icon{
                width: 17px;
                height: 17px;
                margin-top: -5px;
            }
       }
        
        &.textarea_item{
            &>div{
                height: 100px;
            }
            .data_key{
                line-height: 100px;
            }
        }
        
    }
    .filter_box{
        .date_filter{
            position: static;
            padding-left: 0;
            &::after{
                display: none;
            }
            .date_period{
                width: calc(100% - 70px);
                height: 45px;
                padding-right: 70px;
                button{
                    width: 70px;
                    height: 45px;
                }
            }
        }
    } 
    // .mando_table{
    //     table-layout: fixed;
    //     td{
    //         .profile_img_box{
    //             margin-left: 20px;
    //         }
    //     }
    // }  
    #tiptap{
        height: 500px;
    }
}


.content {
  padding: 1rem 0 0;

  h3 {
    margin: 1rem 0 0.5rem;
  }

  pre {
    border-radius: 5px;
    color: #333;
  }

  code {
    display: block;
    white-space: pre-wrap;
    font-size: 0.8rem;
    padding: 0.75rem 1rem;
    background-color:#e9ecef;
    color: #495057;
  }
}

</style>