import axios from 'axios';

// Vehicle Backend API
const axiosInstanceVehicle = axios.create({
  baseURL: 'https://x9eylgsqmg.execute-api.ap-northeast-2.amazonaws.com',
  headers: {
    'Content-Type': 'application/json',
    // Include the JWT token in the Authorization header
    // Replace 'token' with the actual token you have stored
    'X-API-Key': 'vphmapi1'
  },
});

const apiCallVehicle = {
    get(url, params = {}) {
      return axiosInstanceVehicle.get(url, { params });
    },
    post(url, data = {}) {
      return axiosInstanceVehicle.post(url, data);
    },
    patch(url, data = {}) {
        return axiosInstanceVehicle.patch(url, data);
      },
    delete(url) {
        return axiosInstanceVehicle.delete(url);
    },
};

export default apiCallVehicle;
