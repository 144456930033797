<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <div class="title_wrap" v-bind:style="driveData.drId === '' ? '' : 'display:none'">
                <div class="breadcrumb">
                    <span>주행기록 등록</span>
                    <span>프로젝트 워크스페이스</span>
                    <span>데이터 상세 </span>
                    <span class="active">주행기록 등록</span>
                </div>
                <h2>주행기록 등록</h2>
            </div>
            <div class="title_wrap" v-bind:style="driveData.drId === '' ? 'display:none' : ''">
                <div class="breadcrumb">
                    <span>주행기록 등록</span>
                    <span>프로젝트 워크스페이스</span>
                    <span>데이터 상세 </span>
                    <span class="active">주행기록 수정</span>
                </div>
                <h2>주행기록 수정</h2>
            </div>
            <div id="management">
                <div class="row_box">
                    <!-- <div class="data_key">프로젝트 선택</div> -->
                    <div class="data_key">프로젝트</div>
                    <div class="data_val">
                        <input type="text" name="prjNm" id="prjNm" :value="driveData.prjNm" disabled>
                    </div>
                </div>
                <div class="row_box two_item">
                    <div>
                        <div class="data_key">등록자명</div>
                        <div class="data_val">
                            <input type="text" name="rgstNm" id="rgstNm" :value="driveData.rgstNm" disabled>
                        </div>
                    </div>
                    <div>
                        <div class="data_key">등록 일자</div>
                        <div class="data_val">
                            <input type="text" name="rgstDt" id="rgstDt" :value="driveData.rgstDt" disabled>
                        </div>
                    </div>
                </div>
                <div class="row_box two_item">
                    <div>
                        <div class="data_key">주행자명</div>
                        <div class="data_val search_from">
                            <input type="text" name="driverName" id="driverName" :value="driveData.driverName" placeholder="검색을 통해 주행자를 선택해주세요." disabled>
                            <button type="button" @click="showModalMember()" class="btn_search"></button>
                        </div>
                    </div>
                    <div>
                        <div class="data_key">주행일시</div>
                        <div class="data_val filter_box" :class="{ 'failure' : !durationCheck }">
                            <div class="date_filter">
                                <div class="date_period">
                                    <div class="date_box">
                                        <span style="display:inline-block ; padding : 0 10px 0 10px;" :style="picStartTxtStyle" ref="picStartTxt">{{picStartDate}}</span> ~ <span style="display:inline-block ; padding: 0 0 0 10px;" :style="picEndTxtStyle" ref="picEndTxt">{{picEndDate}}</span>
                                    </div>
                                    <button type="button" ref="datePicToggle" @click="datePicToggle()"></button>
                                    <div class="calender_box radiusbox" ref="calenderBox" style="display:none">
                                        <div class="start_date date_box date_time_box" ref="startDateBox" @click="onDatePic('S')" style="display:block">
                                            <VCalendarTimeUi :dateInterface="'start'" :setDate="picStartDate"/>
                                            <button type="button" @click="pickerNext()">다음</button>
                                        </div>
                                        <div class="end_date date_box date_time_box" ref="endDateBox" @click="onDatePic('E')">
                                            <VCalendarTimeUi :dateInterface="'end'" :setDate="picEndDate" :minDate="picStartDate"/>
                                            <button type="button" @click="pickerPrev()">이전</button>
                                            <button type="button" class="mcbtn" @click="datePicToggle()">완료</button>
                                        </div>
                                    </div>
                                    <div class="text_message" v-if="!durationCheck">주행 시간은 최소 3초 이상이어야 합니다.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row_box textarea_item">
                    <div class="data_key">Description</div>
                    <div class="data_val">
                        <textarea name="desc" ref="desc" maxlength="200" v-model="desc" placeholder="입력해주세요."></textarea>
                        <div class="text_length">
                            <span>{{descLen}}</span>/200
                        </div>
                    </div>
                </div>
                <div class="row_box add_item">
                    <div class="data_key">
                        Edge Device
                    </div>
                    <div class="data_val" style="height:120px">
                        <div class="add_box table_list">
                            <div class="mando_table_wrap">
                                <table class="mando_table">
                                    <colgroup>
                                        <col style="width: 45%;">
                                        <col style="width: 18%;">
                                        <col style="width: 15%;">
                                        <col style="width: 12%;">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div class="col_name">
                                                    Edge ID
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Edge Group
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Edge Name
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Status
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="profile_img_box">
                                                    <img src="@/assets/images/common/vehicle_sample.svg" alt="">
                                                </div>
                                                <span class="user_name">{{deviceData.edge_device_id}}</span>
                                            </td>
                                            <td>
                                                <div class="profile_img_box">
                                                    <img src="@/assets/images/common/gateway_sample.svg" alt="">
                                                </div>
                                                <span class="user_name">{{deviceData.edge_device_group}}</span>
                                            </td>
                                            <td>
                                                {{deviceData.edge_device_name}}
                                            </td>
                                            <td>
                                                {{deviceData.status}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="save_box">
                    <button type="button" @click="showModalAlert()">취소</button>
                    <button type="button" class="mcbtn" @click="clickRegist()" :disabled="!requireCheck">저장</button>
                </div>
            </div>
        </div>
    </div>
    <div id="modalWrap" ref="modalWrap" style="display: none;">
        <!-- 취소 -->
        <div class="radiusbox modal_contents message_alert message_modal" ref="modalAlert">
            <div class="message_box">
                변경 사항이 저장되지 않았습니다. <br>정말 취소하시겠습니까?
            </div>
            <button type="button" class="btn_check mcbtn" @click="goBackPage()">확인</button>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
        <!-- 맴버 등록 -->
        <div class="radiusbox modal_contents add_item_modal add_member" ref="modalMember" style="display : none;">
            <strong class="modal_title">
                주행자 검색
            </strong>
            <div class="filter_box">
                <input type="text" name="memberKw" id="memberKw" :value="driveData.memberKw" @keydown.enter="clickSearchMember()" placeholder="이름, 팀, 이메일 주소 검색가능">
                <button type="button" class="btn_search" @click="clickSearchMember()"></button>
            </div>
            <div class="float_box table_list">
                <div class="select_box mando_table_wrap">
                    <table class="mando_table">
                        <colgroup>
                            <col style="width: 20%">
                            <col style="width: 20%">
                            <col style="width: 60%">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Team</th>
                                <th>E-mail</th>
                            </tr>
                        </thead>
                        <tbody id="memberTbody">
                            <tr v-for="(item, index) in memberRowData" :data-index="index" :key="index" @click="clickMember($event)">
                                <td v-for="(value, colIndex) in item" v-bind:style="colIndex==='memberId' ? 'display:none' : ''" :data-index="colIndex" :key="colIndex">
                                    {{value}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="choose_box">
                    <div class="btn_wrap">
                        <button type="button" class="btn_add" @click="clickAddMember()">추가</button>
                        <button type="button" class="btn_add_del" @click="clickDelMember()">삭제</button>
                    </div>
                </div>
                <div class="select_box">
                    <div class="table_title">
                        <strong>선택 항목</strong>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <colgroup>
                                <col style="width: 20%">
                                <col style="width: 20%">
                                <col style="width: 60%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Team</th>
                                    <th>E-mail</th>
                                </tr>
                            </thead>
                            <tbody id="selectMemberTbody">
                                <tr v-for="(item, index) in selectMemberRowData" :data-index="index" :key="index" @click="clickMember($event)">
                                    <td v-for="(value, colIndex) in item" v-bind:style="colIndex==='memberId' ? 'display:none' : ''" :data-index="colIndex" :key="colIndex">
                                        {{value}}
                                    </td>
                                </tr>
                                <tr class="hover_none" v-bind:style="selectMemberRowData.length > 0 ? 'display:none' : ''">
                                    <td colspan="3">
                                        <div class="none_itme">
                                            <div class="message">
                                                 주행자를 추가 해 주세요.
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="check_wrap">
                <button type="button" class="btn_check mcbtn" @click="clickCompleteMember()" v-bind:disabled="selectMemberRowData.length === 0">완료</button>    
            </div>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';

    import VCalendarTimeUi from '@/components/VCalendarTimeUi.vue';
    import common from '@/assets/js/common'
    import { mapGetters } from 'vuex';
    
    export default {
        name: 'aaView',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            VCalendarTimeUi
        },
        computed:{
            ...mapGetters(['getUserData']),

            descLen() {
                return this.desc.length;
            },

            requireCheck() {
                return this.manageRole && this.driveData.driverName !== '' && this.durationCheck;
            },

            allSelctEdge: {
                //getter
                get: function() {
                    return this.edgeDeviceRowData.length === this.unSelectEdgeList.length;
                },
                //setter
                set: function(e) {
                    this.unSelectEdgeList = e ? this.edgeDeviceRowData : [];
                },
            },
            allSelctedEdge: {
                //getter
                get: function() {
                    return this.selectEdgeDeviceRowData.length === this.selectedEdgeList.length;
                },
                //setter
                set: function(e) {
                    this.selectedEdgeList = e ? this.selectEdgeDeviceRowData : [];
                },
            },
            // VCalendar 
            picStartTxtStyle() {
                return {
                    color: this.picColor.startStatus ? this.picColor.active : this.picColor.default
                };
            },
            picEndTxtStyle() {
                
                return {
                    color: this.picColor.endStatus ? this.picColor.active : this.picColor.default
                };
            },
        },
        data(){
            const userData = ({});

            const desc = "";

            const memberRowData = ([]);
            const selectMemberRowData= ([]);
            
            const edgeDeviceRowData = ([]);
            const selectEdgeDeviceRowData= ([]);
            
            const picStartDate = null;
            const picEndDate = null;
            return{
                // -- VCalendarUi / .date_period
                picStartDate,
                picEndDate,
                picColor: {
                    startStatus: false,
                    endStatus: false,
                    active: '#00B4ED',
                    default: '#020A0A'
                },
                //----
                userData,

                desc,

                memberRowData,
                selectMemberRowData,

                edgeDeviceRowData,
                selectEdgeDeviceRowData,

                unSelectEdgeList: [],
                selectedEdgeList: [],
                mainEdgeDeviceList: [],

                driveData: {
                    prjNm: "",
                    rgstNm: "",
                    rgstDt: "",
                    driverId: "",
                    driverName: "",
                    create_name: "",
                },
                deviceData: {
                    edge_device_id: "",
                    edge_device_group: "",
                    edge_device_name: "",
                    status: "",
                },
                projectId:'',
                edgeDeviceId:'',

                durationCheck: false,

                // role
                viewRole: false,
                manageRole: false,
            }
        },
        watch: {
            picStartDate() {
                this.setDurationCheck();
            },
            picEndDate() {
                this.setDurationCheck();
            }
        },
        async mounted() {
            // Role
            this.setRole();

            const getCKUser = this.getUserData;
            const getUser = this.$store.state.user;
            if(getCKUser == undefined){
                this.userData = getUser;
            }else{
                this.userData = getCKUser;
            }
            
            this.driveData['prjId'] = this.$route.query.prjId;
            this.driveData['drId'] = this.$route.query.drId;
            this.driveData['edgeId'] = this.$route.query.edgeId;

            if ( typeof this.driveData['drId'] != "undefined" && this.driveData['drId'] != null && this.driveData['drId'] != "" ) {
                this.setPage();
            } else {
                this.driveData['drId'] = "";

                const nowDateStr = common.getNowDt('full');
                const nowDate = new Date(nowDateStr)

                nowDate.setSeconds(nowDate.getSeconds() - 3);
                const preTime = common.getFormTime(nowDate);

                const start_formattedDate = preTime;
                const end_formattedDate = nowDateStr;
                
                this.picStartDate = start_formattedDate;
                this.picEndDate = end_formattedDate;
                window.selectDate.startDate = start_formattedDate;
                window.selectDate.endDate = end_formattedDate;

                this.durationCheck = true;
            }
            
            this.setPrjInfo();
            this.setEdgeInfo();

            this.projectId = this.$route.query.prjId;
            this.edgeDeviceId = this.$route.query.edgeId;
        },
        methods:{

            async setRole() {
                const prjId = this.$route.query.prjId;
                
                this.manageRole = ( await common.getUserRole('type2') || await common.getPrjRole('type2', prjId) );
                this.viewRole = await common.getUserRole('type1');

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },


            // -------- DATE PICKER -----
            datePicToggle(){
                const vm = this;
                const _display = vm.$refs.calenderBox.style.display;
                const _startDateBox = vm.$refs.startDateBox.style.display;
                
                if(_display == 'block'){
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'none';
                    vm.picColor.startStatus = false;
                    vm.picColor.endStatus = false;
                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'block';
                    if(_startDateBox == 'block'){
                        vm.picColor.startStatus = true;
                        vm.picColor.endStatus = false;
                    }else if( _startDateBox == 'none'){
                        vm.picColor.startStatus = false;
                        vm.picColor.endStatus = true;
                    }
                }
            },
            setDurationCheck() {
                const startTime = new Date(this.picStartDate);
                const endTime = new Date(this.picEndDate);

                const diff = endTime.getTime() - startTime.getTime();

                let result = false;
                if ( diff >= 3000 ) {
                    result = true;
                }

                this.durationCheck = result;
            },
            onDatePic(flag){
                if(flag == 'S'){
                    if(window.selectDate.startDateTime !== null && window.selectDate.startDateTime !== undefined ){
                        this.picStartDate = window.selectDate.startDateTime;
                    }
                    
                }else if(flag == 'E'){
                    if(window.selectDate.endDateTime !== null && window.selectDate.startDateTime !== undefined){
                        this.picEndDate = window.selectDate.endDateTime;
                    }
                }
            },
            pickerNext(){
                this.$refs.startDateBox.style.display = 'none';
                this.$refs.endDateBox.style.display = 'block';

                this.picColor.startStatus = false;
                this.picColor.endStatus = true;
                
            },
            pickerPrev(){
                this.$refs.startDateBox.style.display = 'block';
                this.$refs.endDateBox.style.display = 'none';

                this.picColor.startStatus = true;
                this.picColor.endStatus = false;
            },
            // ------------

            async setPrjInfo() {
                const prjId = this.driveData['prjId']
                const prjData = await this.getPrjData(prjId);
                
                if(typeof prjData !== "undefined" && prjData !== null && prjData !== "") {
                    this.driveData['prjNm'] = prjData.project.project_name;
                    this.driveData['rgstNm'] = this.userData.name;
                    this.driveData['rgstDt'] = common.getNowDt();
                }
            },

            async setPage() {
                const drId = this.driveData['drId'];
                const drData = await this.getDRInfo(drId);
                if(typeof drData !== "undefined" && drData !== null && drData !== "") {
                    this.setDRInfo(drData);
                }

            },
            
            async setEdgeInfo() {
                const edgeId = this.driveData['edgeId']
                const deviceData = await this.getDeviceData(edgeId);
                if(typeof deviceData !== "undefined" && deviceData !== null && deviceData !== "") {
                    this.deviceData = deviceData;
                }
            },
            
            async setDRInfo(drData) {
                this.driveData['rgstNm'] = drData.create_name;
                this.driveData['rgstDt'] = drData.regist_date;
                this.driveData['driverId'] = drData.driver_id;
                this.driveData['driverName'] = drData.driver_name + ' | ' + drData.driver_teamName;

                const start_formattedDate = drData.start_time;
                const end_formattedDate = drData.end_time;

                this.picStartDate = start_formattedDate;
                this.picEndDate = end_formattedDate;
                window.selectDate.startDate = start_formattedDate;
                window.selectDate.endDate = end_formattedDate;

                this.desc = drData.description;

                const data = {};
                data['name'] = drData.driver_name;
                data['teamName'] = drData.driver_teamName;
                data['email'] = drData.driver_email;
                data['memberId'] = drData.driver_id;

                const dataList = [];
                dataList.push(data);
                this.selectMemberRowData = dataList;

                const mainEdgeDeviceList = this.setDeviceData(drData.edgeDevices);
                mainEdgeDeviceList.forEach(item => {
                    this.mainEdgeDeviceList.push(item);
                    this.selectEdgeDeviceRowData.push(item);
                });

            },


            setMemberTable(memberList){
                memberList.then(list => {
                    const dataList = [];
                    list.forEach(element => {
                        dataList.push({
                            name: element.name,
                            teamName: element.teamName,
                            email: element.email,
                            memberId: element.id
                        });
                    });
                    this.memberRowData = dataList;
                });
            },
            
            setDeviceTable(deviceList){
                deviceList.then(list => {
                    const dataList = [];
                    list.forEach(element => {
                        let statusNm = common.getEdgeStatusNm(element.status);
                        
                        dataList.push({
                            id: element.id,
                            edgeDeviceId: element.edge_device_id,
                            edgeDeviceGroup: element.edge_device_group,
                            edgeDeviceName: element.edge_device_name,
                            status: element.status,
                            statusNm: statusNm
                        });
                    });
                    // this.edgeDeviceRowData = dataList;
                    // this.edgeDeviceRowData = common.deleteRowData(dataList, this.mainEdgeDeviceList, 'id');
                    this.edgeDeviceRowData = common.deleteRowData(dataList, this.selectEdgeDeviceRowData, 'id');
                });
            },

            setDeviceData(deviceList){
                const dataList = [];
                deviceList.forEach(item => {
                    let statusNm = common.getEdgeStatusNm(item.status);
    
                    dataList.push({
                        id: item.id,
                        edgeDeviceId: item.edge_device_id,
                        edgeDeviceGroup: item.edge_device_group,
                        edgeDeviceName: item.edge_device_name,
                        status: item.status,
                        statusNm: statusNm
                    });

                });
                return dataList;
            },

            // ----- click
            clickMember(event) {
                const trEl = event.target.parentNode;                
                let trStatus = trEl.getAttribute("class")

                if ( trStatus == 'on' ) {
                    trEl.classList.remove('on');
                } else {
                    // const tableEl = trEl.parentNode;                    
                    // const onEl = tableEl.querySelectorAll(".on");
                    const onEl = document.querySelectorAll(".on");

                    [].forEach.call(onEl, function(el) {
                        el.classList.remove("on");
                    });

                    trEl.setAttribute('class', 'on');
                }
            },

            async clickSearchMember() {
                this.driveData['memberKw'] = document.getElementById("memberKw").value;
                const getMemberList = this.getMemberList(this.driveData['memberKw']);
                this.setMemberTable(getMemberList);

            },
            
            clickAddMember() {
                var selectCnt = 0;

                if ( this.selectMemberRowData.length > 0 ) {
                    selectCnt = this.selectMemberRowData.length;
                }

                if ( selectCnt === 0 ) {
                    const element = document.querySelectorAll("#memberTbody > .on");
                    const dataList = [];
                    
                    [].forEach.call(element, function(trEl) {
                        const tdEl = trEl.getElementsByTagName("td");
                        const data = {};
                        
                        [].forEach.call(tdEl, function(tEl) {
                            const id = tEl.getAttribute("data-index");
                            const val = tEl.innerHTML;
                            data[id] = val;
                        });
                        dataList.push(data);
                    });
                    this.selectMemberRowData = dataList;
                }
            },

            clickDelMember() {
                if ( Object.keys(this.selectMemberRowData).length > 0 ) {
                    const element = document.querySelectorAll("#selectMemberTbody > .on");

                    if ( element.length > 0 ) {
                        this.selectMemberRowData = ([]);
                    }
                }
            },
            
            clickCompleteMember() {
                const rowData = this.selectMemberRowData[0];

                this.driveData['driverId'] = rowData.memberId;
                this.driveData['driverName'] = rowData.name + " | " + rowData.teamName;

                this.closeModal();
            },

            async clickSearchDevice() {
                const keyword = this.$refs.edgeKeyword.value.toUpperCase();

                if(typeof keyword == "undefined" || keyword == null || keyword == "") {
                    const prjId = this.driveData['prjId'];
                    const getDeviceList = this.getDeviceList(prjId);
                    this.setDeviceTable(getDeviceList);

                } else {
                    this.setSearchDevice(keyword);
                }
            },

            setSearchDevice(keyword) {
                const searchRow = [];
    
                this.edgeDeviceRowData.forEach(item => {
                    let edgeDeviceId = common.getUpper(item['edgeDeviceId']);
                    let edgeDeviceName = common.getUpper(item['edgeDeviceName']);
                    let statusNm = common.getUpper(item['statusNm']);

                    if ( edgeDeviceId.includes(keyword) || edgeDeviceName.includes(keyword) || statusNm.includes(keyword) ) {
                        searchRow.push(item);
                    }
                });

                this.edgeDeviceRowData = ([]);

                searchRow.forEach(item => {
                    this.edgeDeviceRowData.push(item);
                });
            },
            
            clickAddDevice() {
                if ( this.unSelectEdgeList.length > 0 ) {
                    this.unSelectEdgeList.forEach(item => {
                        this.selectEdgeDeviceRowData.push(item);
                    });

                    this.edgeDeviceRowData = common.deleteRowData(this.edgeDeviceRowData, this.unSelectEdgeList, 'id');
                    this.unSelectEdgeList = [];
                }
            },
            
            clickDelDevice() {
                if ( this.selectedEdgeList.length > 0 ) {
                    this.selectedEdgeList.forEach(item => {
                        this.edgeDeviceRowData.push(item);
                    });
                    
                    this.selectEdgeDeviceRowData = common.deleteRowData(this.selectEdgeDeviceRowData, this.selectedEdgeList, 'id');
                    this.selectedEdgeList = [];

                    const keyword = this.$refs.edgeKeyword.value.toUpperCase();
                    if ( typeof keyword != "undefined" && keyword != null && keyword != "" ) {
                        this.setSearchDevice(keyword);
                    }
                }
            },

            clickCompleteDevice() {
                this.mainEdgeDeviceList = [];
                this.selectEdgeDeviceRowData.forEach(item => {
                    this.mainEdgeDeviceList.push(item);
                });
                this.closeModal();
            },
            
            clickDelEdgeList(id) {
                let i = this.mainEdgeDeviceList.length;
                while ( i-- ) {
                    let item = this.mainEdgeDeviceList[i];
                    if ( item.id == id ) {
                        this.mainEdgeDeviceList.splice(i, 1);
                        this.edgeDeviceRowData.push(item);
                    }
                }
                let j = this.selectEdgeDeviceRowData.length;
                while ( j-- ) {
                    let item = this.selectEdgeDeviceRowData[j];
                    if ( item.id == id ) {
                        this.selectEdgeDeviceRowData.splice(j, 1);
                    }
                }
            },

            async clickRegist() {
                if ( this.requireCheck === true ) {
                    const edgeDeviceIds = [];
                    edgeDeviceIds.push(Number(this.driveData['edgeId']));
                    // this.mainEdgeDeviceList.forEach(item => {
                    //     edgeDeviceIds.push(item.id);
                    // });


                    const postData = {
                        "project_id": this.driveData.prjId,
                        "driver_id": this.driveData.driverId,
                        "start_time": this.picStartDate,
                        "end_time": this.picEndDate,

                        "description": this.$refs.desc.value,

                        "purpose_data_id": 1,
                        "edgeDeviceIds": edgeDeviceIds
                    };

    
                    let responseCode = null;
                    if ( this.driveData['drId'] != '' && this.driveData['drId'] > 0) {
                        responseCode = await this.updateDriving(this.driveData['drId'], postData);
                    } else {
                        const response = await this.addDriving(postData);
                        responseCode = response['data']['code'];
                    }
    
                    if ( responseCode === 200 || responseCode === "200" ) {
                        this.$router.push({ 
                            path: '/dataList/detailDataSet',
                            query: { prjId: this.driveData['prjId'], edgeId: this.driveData['edgeId']}
                        });
                    }
                }
            },

            // ----- Modal
            async showModalMember(){
                const $modal = this.$refs.modalWrap;
                const $modalMember = this.$refs.modalMember;
                $modal.style.display = "block";
                $modalMember.style.display = "block";

                this.driveData['memberKw'] = this.userData.teamName;
                const getMemberList = this.getMemberList(this.driveData['memberKw']);
                this.setMemberTable(getMemberList);
            },
            async showModalDevice(){
                const $modal = this.$refs.modalWrap;
                const $modalDevice = this.$refs.modalDevice;
                $modal.style.display = "block";
                $modalDevice.style.display = "block";

                this.$refs.edgeKeyword.value = "";

                const prjId = this.driveData['prjId'];
                const getDeviceList = this.getDeviceList(prjId);

                this.selectEdgeDeviceRowData = ([]);
                this.mainEdgeDeviceList.forEach(item => {
                    this.selectEdgeDeviceRowData.push(item);
                });

                this.setDeviceTable(getDeviceList);
            },
            showModalAlert(){
                const $modal = this.$refs.modalWrap;
                const $modalAlert = this.$refs.modalAlert;
                $modal.style.display = "block";
                $modalAlert.style.display = "block";
            },

            closeModal(){
                const $modal = this.$refs.modalWrap;
                // const $modalDevice = this.$refs.modalDevice;
                const $modalMember = this.$refs.modalMember;
                const $modalAlert = this.$refs.modalAlert;
                $modal.style.display = "none";
                // $modalDevice.style.display = "none";
                $modalAlert.style.display = "none";
                $modalMember.style.display = "none";
            },
            goBackPage(){
                this.$router.push({ 
                    path: '/dataList/detailDataSet',
                    query: { prjId: this.driveData['prjId'], edgeId: this.driveData['edgeId']}
                });
            },

            // ----- API 
            async getPrjData(prjtId) {
                try {
                    const response = await common.apiGet('/project/'+prjtId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getMemberList(keyword) {
                try {
                    if(typeof keyword == "undefined" || keyword == null || keyword == "") {
                        keyword = '';
                    }
                    const response = await common.apiGet('/member/members-active?keyword='+keyword);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getDeviceData(edgeId) {
                try {
                    const response = await common.apiGet('/edge-device/'+edgeId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getDeviceList(prjId) {
                try {
                    const response = await common.apiGet('/edge-device/project/'+prjId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getDRInfo(drId) {
                try {
                    const response = await common.apiGet('/driving-record/'+drId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            
            async addDriving(postData) {
                try {
                    const response = await common.apiPost('/driving-record', postData);
                    // return response.data.code;
                    return response;
                } catch (error) {
                    console.error('Error posting data:', error);
                }
            },

            async updateDriving(drId, postData) {
                try {
                    const response = await common.apiPatch('/driving-record/' + drId, postData);
                    return response.status;
                } catch (error) {
                    console.error('Error posting data:', error);
                }
            },

            async getMemberRole() {
                let response;
                try {
                    let url = `/member/check-role`;
                    response = await common.apiGet(url);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                }
            },
            async getProjectRole() {
                let response;
                try {
                    let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
                    response = await common.apiGet(checkProjectRoleUrl);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                }
            }

        }
    }
</script>
<style scoped lang="scss">
@import "@/assets/styles/_common/_variables.scss";
#management{
    .table_list {
        .total_num{
            padding-bottom: 20px;
        }
    }
    .row_box {
        .data_val.search_from{
            width: calc(100% - 270px);
            input[type=text]{
                border-radius: 10px 0 0 10px;
            }
        }
        // &.add_item{
        //     .data_val.active{
        //         height: 300px;
        //         .add_box{
        //             padding-top: 0;
        //         }
        //     }
        // }
        
        &.textarea_item{
            &>div{
                height: 100px;
            }
            .data_key{
                line-height: 100px;
            }
        }
        
    }
    .filter_box{
        .date_filter{
            position: static;
            padding-left: 0;
            &::after{
                display: none;
            }
            .date_period{
                width: calc(100% - 70px);
                height: 45px;
                padding-right: 70px;
                button{
                    width: 70px;
                    height: 45px;
                }
                .calender_box{
                    width: 100%;
                }
            }
        }
    }
    &.failure{
        input[type='text']{
            border-color: $failedColor;
            background-image: url($baseURL+'/common/textbox_warning.svg');
            background-position: 1010px center ;
            background-repeat: no-repeat;
        }
        .text_message{
            font-size: 13px;
            color: #F4514D;
            margin-top: 5px;
            margin-left: 5px;
        }
    }
      
}
 
</style>