import axios from "axios";
// Vector Logger API
const axiosInstanceVision = axios.create({
  baseURL: "https://8vgy1yjbbi.execute-api.ap-northeast-2.amazonaws.com",
  headers: {
    "Content-Type": "application/json",
    // Include the JWT token in the Authorization header
    // Replace 'token' with the actual token you have stored
    "X-API-Key": "vision_key_1",
  },
});

const apiVision = {
  get(url, params = {}) {
    return axiosInstanceVision.get(url, { params });
  },
  post(url, data = {}) {
    return axiosInstanceVision.post(url, data);
  },
  patch(url, data = {}) {
    return axiosInstanceVision.patch(url, data);
  },
  delete(url) {
    return axiosInstanceVision.delete(url);
  },
  put(url, data = {}) {
    return axiosInstanceVision.put(url, data);
  },
};

export default apiVision;
