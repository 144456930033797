<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>시스템 운영</span>
                    <span class="active">Team 목록</span>
                </div>
                <h2>Team 목록</h2>
                <div class="title_btn">
                    <button type="button" v-if="checkUserRole" @click="goAddPage()" class="add_project bgicon">신규 Team 등록</button>
                </div>
            </div> -->
            <BreadcrumbLayout pageId="communication_cs" :checkRole="manageRole"/>
            <div class="list_wrap">
                <div id="listFilter" class="list_filter_wrap float_box" ref="listFilter">
                    <div class="filter_list_box" ref="filterListBox">
                        <ul class="float_box" ref="filterBox">
                            <li v-if="filterList.length === 0" style="padding-right: 20px;"><span>필터</span> : <span>전체</span></li>
                            <li v-for="(item, index) in filterList" :key="index" ref="filterItem">
                                <span>{{ item.keyName }}</span> : <span>{{ item.value }}</span>
                                <button class="btn_close" @click="removeFilter(index)"></button>
                            </li>
                        </ul>
                    </div>
                    <button class="btn_all_filter" ref="filterAllBtn" @click="filterToggle">전체보기</button>
                </div>
                <div class="table_list">
                    <div class="table_set">
                        <div class="cont_count">
                            <span ii="cont_num">{{dataList.length}}</span> Contents
                        </div>
                        <div id="recentFilter">
                            <div class="filter_box">
                                <div class="date_filter">
                                    <select name="itemType" v-model="selectedTypeValue" @change="selectTypeChange" style="width: 150px; background-position: 120px center;" >
                                        <option value='' selected>분류 상태</option>
                                        <option value="1">시스템 오류</option>
                                        <option value="2">프로젝트</option>
                                        <option value="3">기타</option>
                                    </select>
                                    <select name="itemStatus" v-model="selectedStatusValue" @change="selectStatusChange" style="width: 150px; background-position: 120px center;" >
                                        <option value='' selected>문의 접수</option>
                                        <option value="progress">처리 중</option>
                                        <option value="complete">처리 완료</option>
                                    </select>
                                    <div class="date_period">
                                        <div class="date_box">
                                            <span :style="picStartTxtStyle" ref="picStartTxt">{{ picStartDate }}</span> ~ <span :style="picEndTxtStyle" ref="picEndTxt">{{picEndDate}}</span>
                                        </div>
                                        <button type="button" ref="datePicToggle" @click="datePicToggle()"></button>
                                        <div class="calender_box radiusbox" ref="calenderBox" style="display:none">
                                            <div class="start_date date_box" ref="startDateBox" @click="onDatePic('S')" style="display:block" >
                                                <VCalendarUi :dateInterface="'start'"/>
                                                <button type="button" @click="pickerNext()">다음</button>
                                            </div>
                                            <div class="end_date date_box" ref="endDateBox" @click="onDatePic('E')">
                                                <VCalendarUi :dateInterface="'end'" :minDate="picStartDate"/>
                                                <button type="button" @click="pickerPrev()">이전</button>
                                                <button type="button" class="mcbtn" @click="getDriList()">조회</button>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" class="btn_send mcbtn" @click="getDriList()">조회</button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="col_name">
                                            No
                                        </div>
                                    </th>
                                    <th>
                                        <div class="col_name">
                                            문의번호
                                            <button class="btn_filter" @click="showFilter"></button>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="col_name">
                                            분류
                                            <button class="btn_filter" @click="showFilter"></button>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="col_name">
                                            요청자
                                            <button class="btn_filter" @click="showFilter"></button>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="col_name">
                                            제목
                                            <button class="btn_filter" @click="showFilter"></button>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="col_name">
                                            문의일자
                                            <button class="btn_filter" @click="showFilter"></button>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="col_name">
                                            담당자
                                            <button class="btn_filter" @click="showFilter"></button>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="col_name">
                                            처리상태
                                            <button class="btn_filter" @click="showFilter"></button>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="col_name">
                                            처리완료일
                                            <button class="btn_filter" @click="showFilter"></button>
                                        </div>
                                    </th>
                                </tr>
                                <tr id="filterWrap" ref="filterInputWrap">
                                    <template v-for="(item,index) in columnId" :key="index">
                                        <th class="filter_input">
                                            <div v-if="index === 0 || index === 2 || index === 7" class="input_box disable">
                                                <input type="text" disabled>
                                                <button class="btn_add" ref="btnAddfilter" @click="addFilter" :data-index="index"></button>
                                            </div>
                                            <div v-else class="input_box" :data-id="item.id">
                                                <input type="text" @focus="setActive"  @keydown.enter="addFilter" >
                                                <button class="btn_add" ref="btnAddfilter" @click="addFilter" :data-index="index"></button>
                                            </div>
                                        </th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="m_pointer" v-for="(item, index) in dataListSliced" :key="index" @click="goPage(item.csId)" :class="{'impo_item' : item.important}">
                                    <td>{{item.index}}</td>
                                    <td> {{item.csCode}}</td>
                                    <td>{{item.csType}}</td>
                                    <td>{{item.requester}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.created}}</td>
                                    <td>{{item.respondent}}</td>
                                    <td>{{item.status}}</td>
                                    <td>{{item.completedDate}}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    <div class="list_table_footer">
                        <PaginationUi
                            :totalItems="totalItems"
                            :itemsPerPage="itemsPerPage"
                            @page-changed="onPageChanged"
                        ></PaginationUi>
                    </div>
                </div>
            </div>
            <div id="modalWrap" ref="modalWrap" style="display: none;">
                <div class="modal_contents radiusbox detail_item" ref="modalDeail" style="display: none;">
                    <strong class="modal_title">CS 등록 사항</strong>
                    <div class="writer_info">
                        <div>
                            <span class="sub_title">문의일</span>
                            <span>{{listData.created}}</span>
                        </div>
                        <div>
                            <span class="sub_title">문의 번호</span>
                            <span>{{listData.csCode}}</span>
                        </div>
                        <div>
                            <span class="sub_title">문의분류</span>
                            <span>{{listData.csType}}</span>
                        </div>
                        <br/>
                        <div>
                            <span class="sub_title">문의자</span>
                            <span>{{listData.requester}} ({{listData.requesterEmail}})</span>
                        </div>
                        <div>
                            <span class="sub_title">소속</span>
                            <span>{{listData.teamName}}</span>
                        </div>
                        <div>
                            <span class="sub_title">처리상태</span>
                            <span>{{listData.status}}</span>
                        </div>
                    </div>
                    <div class="text_box">
                        <div class="text_title">
                            {{listData.title}} 
                            <button type="button" v-if="listData.requesterEmail === userEmail && listData.status != '처리 완료'" @click="itemCorrection(listData.csId)">수정 하기</button>
                        </div>
                        <div class="dsecription_box tiptap_text"  v-if="listData.fileNames.length > 0" v-html="listData.description"></div>
                        <div class="dsecription_box tiptap_text no_file" v-else v-html="listData.description"></div>
                        <div class="file_list" v-if="listData.fileNames.length > 0">
                            <span>총 {{listData.fileNames.length}}개의 파일</span>
                            <div class="file_list_box float_box">
                                <div class="file_item" v-for="(item, index) in listData.fileNames" :key="index">
                                    <a href="#" @click="getFile(item)">
                                        {{item}}
                                        <span>다운로드</span>
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <!-- 답글 -->
                    <template v-if="itemReply.length > 0">
                    <div class="reply_box" ref="replyBox" v-for="(item, index) in itemReply" :key="index">
                        <div class="reply_header">
                            <strong>답변</strong>
                            <button type="button" v-if="item.email === userEmail && listData.status != '처리 완료'" @click="replyCorrection(item.title, item.description, item.csReplyId)">수정 하기</button>
                        </div>
                        <div class="reply_text">
                            <div class="text_title">
                                {{item.title}} 
                                <span>{{item.created}}</span>
                            </div>
                            <div class="dsecription_box tiptap_text no_file" v-html="item.description"></div>
                        </div>
                    </div>
                    </template>
                    <!-- 답글 에디터 -->
                    <template v-if="opneEditor">
                    <div class="reply_box" ref="editorBox">
                        <div class="reply_header" v-if="listData.status != '처리 완료'">
                            <strong v-if="replyCorrectionStatus">재답변 수정하기</strong>
                            <strong v-else>재답변 하기</strong>
                            <button type="button" class="mcbtn" v-if="replyCorrectionStatus" @click="replyPatch(listData.csId, csReplyId)">수정 하기</button>
                            <button type="button" class="mcbtn" v-else @click="replyUpload(listData.csId)">답변 하기</button>
                        </div>
                        <div class="reply_text">
                            <div class="text_title">
                                <input type="text" ref="itemTitle" v-model="itemTitle" maxlength="50" placeholder="제목을 입력해 주세요.">
                                <div class="text_length">
                                    <span>{{itemTitle.length}}</span>/50
                                </div>
                            </div>
                            <tiptap v-model="contentTxt" ref="contentTxt"/>
                        </div>
                    </div>
                    </template>
                    <div class="save_box" v-if="viewTypeRole">
                        <!--member cs 요청 취소-->
                        <button type="button" class="reply_btn" v-if="editorBtn && listData.status != '처리 완료'" @click="editorOpne()"> 재답변 하기</button>
                        <button type="button" class="close_btn" @click="closeModal()">닫기</button>
                        <button type="button" class="mcbtn" v-if="listData.status != '처리 완료'" @click="csComplete()">처리완료</button>
                        <button type="button" class="delbtn" v-if="listData.status != '처리 완료'" @click="itemDelete(listData.csId)" >CS 요청 취소</button>
                    </div>
                    <button type="button" class="btn_close" @click="closeModal()"></button>
                </div>
                <!--
                <div class="modal_contents radiusbox detail_item">
                    <strong class="modal_title">공지</strong>
                    <div class="writer_info">
                        작성자
                        작성일
                    </div>
                    <div class="text_box">

                    </div>
                    <button type="button" class="btn_close" @click="closeModal()"></button>
                </div>
                -->
            </div>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import PaginationUi from "@/components/PagenationUi.vue";
    import VCalendarUi from '@/components/VCalendarUi.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';
    import Tiptap from '@/components/TiptapUi.vue';
    import Cookies from 'js-cookie';

    import '@/assets/js/filter';
    import gsap from 'gsap';
    import common from '@/assets/js/common'
    export default {
        name: 'teamList',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            PaginationUi,
            BreadcrumbLayout,
            VCalendarUi,
            Tiptap,
            
        },
        computed:{
            // datepic 
            picStartTxtStyle() {
                return {
                    color: this.picColor.startStatus ? this.picColor.active : this.picColor.default
                };
            },
            picEndTxtStyle() {
                return {
                    color: this.picColor.endStatus ? this.picColor.active : this.picColor.default
                };
            }
        },
        data(){
            const filterList = ([]);
            const filterRowData =([]);

            const picStartDate = null;
            const picEndDate = null;

            return{
                selectedTypeValue: '',
                selectedStatusValue: '',
                // -- VCalendarUi / .date_period
                picStartDate,
                picEndDate,
                picColor: {
                    startStatus: false,
                    endStatus: false,
                    active: '#00B4ED',
                    default: '#020A0A'
                },
                // ---
                filterList,
                filterRowData,

                totalItems: 100,
                itemsPerPage: 10,
                currentPage: 1,

                dataList: [],
                dataListSliced: [],
                
                // 
                columnId:[
                    { id: "no" },
                    { id: "csCode" },
                    { id: "csType" },
                    { id: "requester" },
                    { id: "title" },
                    { id: "created" },
                    { id: "respondent" },
                    { id: "status" },
                    { id: "completedDate" },
                ],
                isfilter: true,
                filterDataList:{
                    origin:{}
                },
                selectedValue: '',
                // role
                viewRole: false,
                manageRole: false,
                viewTypeRole: true,
                // tiptap
                contentTxt: "",
                itemTitle: "", 
                itemReply: [],
                opneEditor: false,
                userEmail: '',
                editorBtn: false,
                sameUser: false,
                replyCorrectionStatus: false, 
                csReplyId: '',
                // listdata
                listData: {
                    csId : "",
                    csCode : "",
                    csTypeId : "",
                    csType : "",
                    requesterI : "",
                    requester : "",
                    teamName : "",
                    fileNames : [],
                    status : "",
                    title : "",
                    description : "",
                    created : "",
                    completedDate : "",
                    requesterEmail: ""
                },
            };
        },

        mounted() {

            //Cookies
            const userData = JSON.parse(Cookies.get('user'));
            this.userEmail = userData.sub;

            // Role
            this.setRole();

            this.setNoticeList();

            // set date 
            const vm = this;
            const stoday = new Date();
            stoday.setDate(stoday.getDate() - 1); 
            const syear = stoday.getFullYear();
            const smonth = stoday.getMonth() + 1;
            const sday = stoday.getDate();
            const start_formattedDate = syear + '-' + (smonth < 10 ? '0' : '') + smonth + '-' + (sday < 10 ? '0' : '') + sday;

            const etoday = new Date();
            const eyear = etoday.getFullYear();
            const emonth = etoday.getMonth() + 1;
            const eday = etoday.getDate();
            const end_formattedDate = eyear + '-' + (emonth < 10 ? '0' : '') + emonth + '-' + (eday < 10 ? '0' : '') + eday;
            
            vm.picStartDate = start_formattedDate;
            vm.picEndDate = end_formattedDate;
            window.selectDate.startDate = start_formattedDate;
            window.selectDate.endDate = end_formattedDate;

        },
        methods:{
            async setRole() {
                // this.manageRole = await common.getUserRole('type3');
                this.manageRole = true;
                // this.viewRole = await common.getUserRole('type2');
                this.viewRole = true;

                
            },

            async setNoticeList() {
                const dataList = await this.getCSList();

                if(typeof dataList !== "undefined" && dataList !== null && dataList !== "") {
                    this.dataList = dataList;
                }
                
                this.currentPage = 1;
                this.totalItems = this.dataList.length;
                this.displayDataPerPage(this.dataList);
                
            },

            async goPage(item){

                const itemData = await this.getDetailData(item);
                this.listData = itemData;
                
                if(this.listData.fileNames == null){
                    this.listData.fileNames = []
                }
                
                const itemReplyData = await this.getReplyData(item);
                
                this.itemReply = itemReplyData;
                
                
                console.log(itemData);
                
                console.log(itemReplyData);
                
                let lastIndex = itemReplyData.length;

                if(lastIndex !== undefined){
                         
                    if(lastIndex > 0){
                        let lastReply = this.itemReply[lastIndex-1].email;
                        //--------
                        if(lastReply === this.userEmail){
                            this.sameUser = true; 
                        }
                        //--------
                        if(this.userEmail !== lastReply){
                            this.editorBtn = true;
                        }
                    }else{
                        if(this.userEmail !== this.listData.requesterEmail){
                            console.log("댓글 없음");
                            this.editorBtn = true;
                        }
                    }
                
                    

                    if(this.listData.title !== null){
                        this.showModal();
                    }else{
                        alert("접근권한이 없습니다.");
                    }
                    
                }

                
                
            },
            async editorOpne(){
                this.opneEditor = true;
            },
            onPageChanged(page) {
                this.currentPage = page;
                this.displayDataPerPage(this.dataList);

                
            },
            

            // ----- Modal
            showModal(){
                const $modal = this.$refs.modalWrap;
                const $modalAlert = this.$refs.modalDeail;
                $modal.style.display = "block";
                $modalAlert.style.display = "block";
                
            },
            closeModal(){
                const $modal = this.$refs.modalWrap;
                const $modalAlert = this.$refs.modalDeail;
                
                $modal.style.display = "none";
                $modalAlert.style.display = "none";


                this.opneEditor = false;
                this.editorBtn = false;
                this.replyCorrectionStatus = false;
                
            },
            // column filter
            filterToggle(){
                const $listFilter = this.$refs.listFilter;
                const $filterBox = this.$refs.filterListBox;
                const $filterBtn = this.$refs.filterAllBtn;
                const hasOpenClass = $filterBox.classList.contains("open");
                if(hasOpenClass){
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.remove("open");
                            $filterBtn.classList.remove("active");
                            $filterBtn.innerText = '전체보기'
                        },
                        height: '70px',
                        duration: .3
                    });
                }else{
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.add("open");
                            $filterBtn.classList.add("active");
                            $filterBtn.innerText = '접기'
                        },
                        height: 'auto',
                        duration: .3
                    });
                }
                
            },
            async addFilter(event){
                
                const _this = event.target;
                const $thTag = _this.parentNode.parentNode;
                const $addBtn = $thTag.querySelector("button"); 
                const $input = $thTag.querySelector("input");
                // input 글자수
                const trimVal = $input.value.trim();

                // 컬럼 이름 가져오기
                const keyNameArr = _this.parentNode.parentNode.parentNode.parentNode.querySelector("tr:first-child").querySelectorAll(".btn_filter"); 
                let activeIndex = Array.from(keyNameArr).findIndex(element => element.classList.contains('active'));
                const keyName = String(keyNameArr[activeIndex-1].parentNode.textContent);                 

                const dataKeyArr = _this.parentNode.parentNode.parentNode.querySelectorAll('.input_box');
                const dataKeyindex = Array.from(dataKeyArr).findIndex(element => element.classList.contains('active'))
                const keyId = String(dataKeyArr[dataKeyindex].getAttribute('data-id')) 

                const obj = {};

                window.filterData.taget = _this.getAttribute('data-index')

                if(0 < trimVal.length){
                    obj["keyId"] = keyId;
                    obj["value"] = trimVal;
                    obj["keyName"] = keyName;
                    $input.value = "";
                    this.filterList.push(obj);
                    this.$refs.filterAllBtn.style.display = "block";
                    $addBtn.parentNode.classList.add("disable");
                    $addBtn.previousElementSibling.disabled = true;
                    this.filterAction();
                }
                
            },
            removeFilter(index) {
                const $filterItem = this.$refs.filterItem;
                const $filterInputWrap = this.$refs.filterInputWrap;
                const dataId = this.filterList[index].keyId.trim();
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).classList.remove("disable");
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).querySelector("input").disabled = false;
                this.filterList.splice(index, 1);
                if($filterItem.length <= 1){
                    this.$refs.filterAllBtn.style.display = "none";
                }
                this.filterAction(true);
            },
            setActive(event) {
                const _this = event.target;
                const $filterDiv = _this.parentNode.parentNode.parentNode.querySelectorAll("div");
                const $filterIcon = _this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                
                for(var i = 0 ; i < $filterDiv.length ; i ++){
                    $filterDiv[i].classList.remove("active");
                }
                _this.parentNode.classList.add("active");
                
                // filter icon 
                let activeIndex = Array.from($filterDiv).findIndex(element => element.classList.contains('active'));
                
                for(var j = 0 ; j < $filterIcon.length ; j ++){
                    $filterIcon[j].classList.remove("active");
                }
                $filterIcon[activeIndex].classList.add("active");

            },

            displayDataPerPage(rowData) {
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                this.dataListSliced =  rowData.slice(startIndex, endIndex);
                
                let index = startIndex + 1;
                let dataList = [];

                this.dataListSliced.forEach(item => {
                    let data = {
                        index: index,
                        csId: item.csId,
                        csCode: item.csCode,
                        csTypeId: item.csTypeId,
                        csType: item.csType,
                        requesterId: item.requesterId,
                        requester: item.requester,
                        respondentId: item.respondentId,
                        respondent: item.respondent,
                        title: item.title,
                        status: item.status,
                        created: item.created,
                        completedDate: item.completedDate
                    };
                    dataList.push(data);
                    index += 1;
                });
                this.dataListSliced.splice(0, this.dataListSliced.length);
                this.dataListSliced = dataList;
            },
            filterAction(remove){
                const vm = this;
                // 필터 리스트
                const _filterLsit =  vm.filterList;
                // 테이블 리스트
                let _tableList = vm.dataList;
                if(vm.isfilter){
                    vm.filterDataList.origin = _tableList;
                    vm.isfilter = false;
                }
                
                
                if(_filterLsit.length > 0){
                    const lodash = require('lodash');
                    
                    // 필터 삭제
                    if(remove){
                        let _filterDataListOrign = [];
                        vm.filterDataList.origin.forEach(item => {
                            _filterDataListOrign.push(item);
                        });

                        for( var j = 0 ; j < _filterLsit.length; j++){

                            const searchString = common.getUpper(_filterLsit[j].value);
                            const regexPattern = new RegExp(searchString);

                            // _tableList > 검색 1 리트스 > 검색 2 리스트

                            const keyid = _filterLsit[j].keyId;
                            const filteredData = lodash.filter(_filterDataListOrign, (item) => regexPattern.test(common.getUpper(item[keyid])));
                            vm.dataList = filteredData;
                            _filterDataListOrign = filteredData;
                        }
                    // 필터 추가
                    }else{
                        for( var i = 0 ; i < _filterLsit.length; i++){

                            const searchString = common.getUpper(_filterLsit[i].value);
                            const regexPattern = new RegExp(searchString);

                            // _tableList > 검색 1 리트스 > 검색 2 리스트
                            
                            const keyid = _filterLsit[i].keyId;
                            const filteredData = lodash.filter(_tableList, (item) => regexPattern.test(common.getUpper(item[keyid])));
                            vm.dataList = filteredData;
                        }
                    }
                    
                }else{
                    vm.dataList = vm.filterDataList.origin;
                }
                vm.currentPage = 1;
                vm.totalItems = vm.dataList.length;
                vm.displayDataPerPage(vm.dataList);
            },
            showFilter(event){
                const _this = event.target;
                
                const $filterBtn = _this.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                const $filterWrap = document.querySelector("#filterWrap");
                const $filterInput = $filterWrap.querySelectorAll(".filter_input");

                const hasShowClass = $filterWrap.classList.contains("show");
                const hasActiveClass = _this.classList.contains("active");
                
                for(var i = 0 ; i < $filterBtn.length ; i ++){
                    $filterBtn[i].classList.remove("active");
                }
                _this.classList.add("active");
                if(hasShowClass){
                    if(hasActiveClass){
                        $filterWrap.classList.remove("show");
                        _this.classList.remove("active");
                    }
                }else{
                    $filterWrap.classList.add("show");
                }
                let activeIndex = Array.from($filterBtn).findIndex(element => element.classList.contains('active'));
                // input 
                for(var j = 0 ; j < $filterInput.length ; j ++){
                    $filterInput[j].querySelector("div").classList.remove("active");
                }
                if(activeIndex > -1){
                    $filterInput[activeIndex].querySelector("div").classList.add("active");
                }
                
                
            },
            async itemDelete(itemID) {
                try{
                    await this.delItem(itemID);
                    
                } catch(error){
                    console.error('Error deleting item:', error);
                }
                window.location.reload();
            },
            async itemCorrection(itemID) {
                this.$router.push("/commu/addCS?itemID="+itemID);
            },
            async replyUpload(itemID) {
                console.log(itemID);
                if(this.itemTitle === ''){
                    alert("답변의 제목이 비어있습니다.");
                }else if(this.contentTxt === ''){
                    alert("답변의 본문이 비어있습니다.");
                }else{
                    const postData = {
                        "csId": itemID,
                        "title": this.itemTitle,
                        "description": this.contentTxt,
                        "completed": false
                    }

                    console.log(postData);
                    
                    await this.setReplyData(postData);

                    // 초기화 
                    this.editorBtn = false;
                    this.opneEditor = false;
                    // 불러오기
                    this.goPage(itemID);
                }
            },
            async replyPatch(itemID, replyId) {
                
                if(this.itemTitle === ''){
                    alert("답변의 제목이 비어있습니다.");
                }else if(this.contentTxt === ''){
                    alert("답변의 본문이 비어있습니다.");
                }else{
                    const postData = {
                        "title": this.itemTitle,
                        "description": this.contentTxt,
                     
                    }
                    
                    await this.patchReplyData(postData, replyId);

                    // 초기화 
                    this.editorBtn = false;
                    this.opneEditor = false;
                    this.replyCorrectionStatus = false;
                    // 불러오기
                    this.goPage(itemID);
                }
            },
            async replyCorrection(title, desc, itemId) {
                const $modal = this.$refs.modalWrap;

                $modal.querySelector('.modal_contents').scrollTop = 0;

                this.opneEditor = true;
                this.editorBtn = false;
                this.replyCorrectionStatus = true;
                
                this.itemTitle = title;
                this.contentTxt = desc;

                this.csReplyId = itemId;
                
                
                setTimeout(function(){
                    
                    $modal.querySelector('.modal_contents').scrollTop = $modal.querySelector('.modal_contents').scrollHeight;
                }, 100)
            
                // $modal.querySelector('.modal_contents').scrollTop = 2049 + 720;
                
            },

            async csComplete() {
                const csId = this.listData.csId;

                const response = await this.patchStatusComplete(csId);
                const resStatus = response.data.status;

                if(resStatus === 200) {
                    location.reload();
                }

            },

            // ----- API 
            async getCSList(startDate, endDate, status, type) {
                try {
                    // cs?start-date=2023-11-01&end-date=2023-11-30&cs-type=1&status=progress
                    if(endDate !== undefined && startDate !== undefined && type !== undefined && status !== undefined){
                        const response = await common.apiGet('/cs?start-date='+startDate+'&end-date='+endDate+'&cs-type='+type+'&status='+status);
                        return response.data.data;
                    }
                    else if(endDate !== undefined && startDate !== undefined  && type !== undefined){
                        const response = await common.apiGet('/cs?start-date='+startDate+'&end-date='+endDate+'&cs-type='+type);
                        return response.data.data;
                    }else if(endDate !== undefined && startDate !== undefined && status !== undefined){
                        const response = await common.apiGet('/cs?start-date='+startDate+'&end-date='+endDate+'&status='+status);
                        return response.data.data;
                    }else if(endDate !== undefined && startDate !== undefined){
                        const response = await common.apiGet('/cs?cs-type='+type+'&status='+status);
                        return response.data.data;
                    }else if(type !== undefined){
                        const response = await common.apiGet('/cs?cs-type='+type);
                        return response.data.data;
                    }else if(status !== undefined){
                        const response = await common.apiGet('/cs?status='+status);
                        return response.data.data;
                    }else{
                        const response = await common.apiGet('/cs');
                        return response.data.data;
                    }
                    
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getDetailData(itemID) {
                try {
                    const response = await common.apiGet('/cs/'+itemID);
                    return response.data.data;
                } catch (error) {
                    console.log(error);
                    console.error('Error fetching data:', error);
                }
            },

            async getFile(filename) {
                try {
                    const response = await common.apiGet(`/cs/file/${filename}`);

                    const data = await fetch(response.data);
                    const blob = await data.blob();

                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();

                    URL.revokeObjectURL(link.href);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async delItem(itemId) {
                try {
                    await common.apiDelete('/cs/' + itemId);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async patchStatusComplete(csId) {
                try {
                    const response = await common.apiPatch(`/cs/status/${csId}/C`);
                    return response;
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            },

            // reply 
            async getReplyData(itemID) {
                try {
                    const response = await common.apiGet('/cs-reply/'+itemID);
                    return response.data.data;
                } catch (error) {
                    console.log(error);
                    console.error('Error fetching data:', error);
                }
            },
            async setReplyData(postData) {
                try {
                    const response = await common.apiPost('/cs-reply', postData);
                    return response.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async patchReplyData(postData, itemID) {
                try {
                    const response = await common.apiPatch('/cs-reply/'+itemID, postData);
                    return response.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            // 
            

            // -------- DATE PICKER ----
            datePicToggle(){
                const vm = this;
                const _display = vm.$refs.calenderBox.style.display;
                const _startDateBox = vm.$refs.startDateBox.style.display;
                if(_display == 'block'){
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'none';
                    vm.picColor.startStatus = false;
                    vm.picColor.endStatus = false;

                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'block';
                    if(_startDateBox == 'block'){
                        vm.picColor.startStatus = true;
                        vm.picColor.endStatus = false;
                    }else if( _startDateBox == 'none'){
                        vm.picColor.startStatus = false;
                        vm.picColor.endStatus = true;
                    }
                }
                
                
                
            },

            memAccordion(event){
                const _this = event.target;
                const hasShowClass = _this.classList.contains('show_list');
                const $listWrap = _this.parentNode.nextElementSibling;
                if(hasShowClass){
                    gsap.to($listWrap , {
                        onStart: () => {
                            _this.classList.remove("show_list");
                        },
                        height: 0,
                        duration: .3
                    });
                }else{
                    gsap.to($listWrap , {
                        onStart: () => {
                            _this.classList.add("show_list");
                        },
                        height: 'auto',
                        duration: .3
                    });
                }
            },
            async getDriList(){
                const vm = this;
                // this.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', this.picStartDate, this.picEndDate);
                // 켈린더 display처리
                const $calenderBox = vm.$refs.calenderBox;
                const $startDateBox = vm.$refs.startDateBox;
                const $endDateBox = vm.$refs.endDateBox;
             
                
                $calenderBox.style.display = 'none';
                $startDateBox.style.display = 'block';
                $endDateBox.style.display = 'none';
           
                
                vm.picColor.startStatus = false;
                vm.picColor.endStatus = false;


                const dataList = await vm.getCSList(vm.picStartDate, vm.picEndDate);

                if(typeof dataList !== "undefined" && dataList !== null && dataList !== "") {
                    vm.dataList = dataList;
                }
                
                vm.currentPage = 1;
                vm.totalItems = vm.dataList.length;
                vm.displayDataPerPage(vm.dataList);

                // vm.drivList = await vm.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', '2022-12-10', '2023-11-10');
                
                // console.log(vm.drivList);
                
            },
            onDatePic(flag){
                if(flag == 'S'){   
                    if(window.selectDate.startDate !== null ){
                        this.picStartDate = window.selectDate.startDate;
                    }
                    
                }else if(flag == 'E'){
                    if(window.selectDate.picEndDate !== null ){
                        this.picEndDate = window.selectDate.endDate;
                    }
                }
            },
            pickerNext(){
                this.$refs.startDateBox.style.display = 'none';
                this.$refs.endDateBox.style.display = 'block';

                this.picColor.startStatus = false;
                this.picColor.endStatus = true;
                
            },
            pickerPrev(){
                this.$refs.startDateBox.style.display = 'block';
                this.$refs.endDateBox.style.display = 'none';

                this.picColor.startStatus = true;
                this.picColor.endStatus = false;
            },

            // 
            async selectTypeChange(){
                const vm = this;
                const dataList = await vm.getCSList(undefined, undefined , vm.selectedStatusValue, vm.selectedTypeValue);

                if(typeof dataList !== "undefined" && dataList !== null && dataList !== "") {
                    vm.dataList = dataList;
                }
                
                vm.currentPage = 1;
                vm.totalItems = vm.dataList.length;
                vm.displayDataPerPage(vm.dataList);
            },
            async selectStatusChange(){
                const vm = this;
                const dataList = await vm.getCSList(undefined, undefined ,vm.selectedStatusValue, );

                if(typeof dataList !== "undefined" && dataList !== null && dataList !== "") {
                    vm.dataList = dataList;
                }
                
                vm.currentPage = 1;
                vm.totalItems = vm.dataList.length;
                vm.displayDataPerPage(vm.dataList);
            }
        }
    }
</script>
<style scoped lang="scss">
.list_wrap{
    position: relative;
    .table_list{
        margin-top: 110px;
        height: 715px;
    }
    .list_filter_wrap{
        position: absolute;
        top: -90px;
    }
    
}
.list_wrap .table_list .mando_table thead th .col_name{
        // min-width: auto !important;
        padding-right: 15px;
    }

.table_set {
    #recentFilter{
        width: 100%;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        .filter_box{
            width: 100%;
            height: 50px;
            .date_filter{
                top: 10px;
                right: 20px;
                select{
                    position: relative;
                    top: 0;
                    left: 0;
                }
                &::after{
                    display:none;
                }
            }
        }
    }
}
.mando_table{
    tr{
        td:nth-child(2){
            text-align: left;
        }
    }
    .impo_item{
        background: #FFF8EB;
        td:nth-child(2){
            font-weight: $bold;
        }
        .important{
            width: 40px;
            height :25px;
            display: inline-block;
            background-color: #F4514D; 
            border-radius: 5px;
            color : #fff;
            line-height: 25px;
            text-align:center;
            font-size: 13px;
            margin-right: 10px;
        }
    }
    .normal{
        padding-left: 74px;
    }
}
#modalWrap{
    .detail_item{
        width: 1200px;
        height: 730px;
        padding: 25px;
        box-sizing: border-box;
        overflow-y: scroll;
        .text_box{
            .dsecription_box{
                width: 100%;
                height: 290px;
                overflow-y: auto;
                padding-top: 20px;
                padding-bottom: 60px;
                &.no_file{
                    height: 390px;
                }
                
            }
        }
        .file_list{
            border-top: 1px solid #D5DAE3;
            padding-top: 20px;
            span{
                font-size: 15px;
                color: #6E7380;
                font-weight: $bold;
            }
            .file_list_box{
                height: 60px;
                overflow-y: auto;
            }
            .file_item{
                width: 504px;
                height: 50px;
                box-sizing: border-box;
                background-color: #F2F4F7;
                border: 1px solid #B9BFCB;
                border-radius: 10px;
                float: left;
                position: relative;
                margin-bottom: 10px;
                &:nth-child(2n){
                    margin-left: 50px;
                }
                a{
                    display: block;
                    width: 100%;
                    height: 100%;
                    font-weight: $bold;
                    line-height: 50px;
                    padding: 0 30px;
                    box-sizing: border-box;
                    span{
                        display: block;
                        padding-left: 25px;
                        position: absolute;
                        top: 14px;
                        right: 20px;
                        font-size: 13px;
                        font-weight: 300;
                        background-repeat: no-repeat;
                        background-position: 5px center;
                        background-image: url($baseURL + "common/btnicon_download_gr.svg" );
                    }
                }
            }
        }
    }
    .writer_info{
        width: 100%;
        height: 60px;
        &>div{
            width: 380px;
            height: 20px;
            margin-bottom: 20px;
            position: relative;
            display: inline-block;
            span{
                height: 100%;
                display: inline-block;
                line-height: 20px;
                &.sub_title{
                    font-weight: $bold;
                }
                &:nth-child(1){
                    padding-right: 10px;
                    border-right: 1px solid #E5E9EF;
                    
                }
                &:nth-child(2){
                    padding-left: 10px;
                }
            }
        }
    }
    .text_box{
        width: 100%;
        height : 500px;
        border-radius: 10px;
        border: 1px solid #D5DAE3;
        margin: 20px 0 10px; 
        box-sizing: border-box;
        padding: 20px;
        .text_title{
            border-bottom: 1px solid #D5DAE3;
            padding-bottom : 20px;
            font-weight: $bold;
            line-height: 40px;
            
            &:after{
                display: block;
                content: "";
                clear: both;
            }
            &>button{
                width: 90px;
                height: 40px;
                display: inline-block;
                border: 1px solid #D5DAE3;
                border-radius: 10px;
                float: right;
            }
            .important{
                width: 40px;
                height :25px;
                display: inline-block;
                background-color: #F4514D; 
                border-radius: 5px;
                color : #fff;
                line-height: 25px;
                text-align:center;
                font-size: 13px;
                margin-right: 10px;
            }
        }
    }
    .reply_box{
        margin-top: 50px;
        .reply_header{
            line-height: 45px;
            &:after{
                display: block;
                content: "";
                clear: both;
            }
            strong{
                font-weight: $bold;
            }
            button{
                width: 90px;
                height: 40px;
                display: inline-block;
                border: 1px solid #D5DAE3;
                border-radius: 10px;
                float: right;
            }
        }
        .reply_text{
            border: 1px solid #D5DAE3;
            margin: 10px 0 10px;
            box-sizing: border-box;
            padding: 20px;
            border-radius: 10px;
            .text_title{
                border-bottom: 1px solid #D5DAE3;
                padding-bottom : 20px;
                font-weight: $bold;
                line-height: 40px;
                position: relative;
                &>span{
                    position: absolute;
                    top: 10px;
                    right: 20px;
                }
                input{
                    width: 100%;
                    height: 100%;
                    line-height: 45px;
                    box-sizing: border-box;
                    border: 1px solid #D5DAE3;
                    border-radius: 10px;
                    background-color: #fff;
                    font-size: 15px;
                    padding: 10px 15px;
                }
                .text_length{
                    position: absolute;
                    bottom: 32px;
                    right: 10px;
                    font-size: 17px;
                    color: #A8A8A8;
                    span{
                        font-size: 17px;
                        color: #A8A8A8;
                    }
                }
                
            }
            .dsecription_box{
                overflow-y: auto;
            }
        }
    }
    .save_box{
        text-align: center;
        &:after{
            display: block;
            content: "";
            clear: both;
        }
        .delbtn{
            width: 110px;
            height: 45px;
            border-radius: 10px;
            border: 1px solid #F4514D;
            color: #F4514D;
            float: right;
        }
        .mcbtn{
            width: 90px;
            height: 45px;
            border-radius: 10px;
            margin-left: 5px;
        }
        .close_btn{
            width: 90px;
            height: 45px;
            display: inline-block;
            border: 1px solid #D5DAE3;
            border-radius: 10px;
            margin-right: 5px;
        }
        .reply_btn{
            width: 110px;
            height: 45px;
            display: inline-block;
            border: 1px solid #D5DAE3;
            border-radius: 10px;
            float: left;
        }
    }
    
}
</style>