<template>
    <div id="containser">
        <h1>Gragh</h1>
        <div class="contens">
            <ul>
                <li>
                    5분 10분당 그래프 갱신
                </li>
                <li>
                    갱신될때 그래프 추가 / 삭제 
                </li>
                <li>
                    중간에 끈기는 데이터 처리
                </li>
                <li>
                    라인그래프 , 바그래프 
                </li>
                <li>
                    위젯에서 보여지는 시간대 24시간, 12시간 x축 정책 받아야함
                </li>
            </ul>
            <h3>metric</h3>
            <div style="width: 500px; height: 500px;">
                <svg ref="confusionMatrix"></svg>
                <div ref="colorScale"></div>
            </div>

            <div>
                <canvas id="myChart"></canvas>
            </div>
            <h3>bar chart 222 </h3>
            <div>
                <canvas id="myChart2"></canvas>
            </div>
            <h3>Line chart</h3>
            <div>
                <canvas id="myLineChart"></canvas>
            </div>
            <h3>Progressive Line</h3>
            <div id="pline">
                <div class="dimmer-test"></div>
                <canvas id="pLineChart"></canvas>
            </div>
            <div style="padding: 0 0 300px;"></div>
        </div>
    </div>
</template>

<script>

import { Chart } from 'chart.js';

import * as d3 from 'd3';

export default{
    setup(){
        
    },  
    data(){
        return{
            
        }
    },
    mounted() {
        
        const data = [
            [10, 2, 3, 0],
            [1, 15, 2, 1],
            [0, 3, 12, 2],
            [1, 2, 1, 8],
        ];

        // SVG 요소 크기 및 여백 설정
    const margin = { top: 30, right: 30, bottom: 30, left: 30 };
    const width = 400 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    // D3.js 스케일 설정
    const x = d3.scaleBand().domain(d3.range(data.length)).range([0, width]);
    const y = d3.scaleBand().domain(d3.range(data[0].length)).range([0, height]);

    // D3.js 색상 척도 설정
    const color = d3.scaleSequential(d3.interpolateBlues).domain([0, d3.max(data, (row) => d3.max(row))]);

    // SVG 요소 생성
    const svg = d3
      .select(this.$refs.confusionMatrix)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Rect 요소를 사용하여 Confusion Matrix 시각화
    svg
      .selectAll('rect')
      .data(data.flat())
      .enter()
      .append('rect')
      .attr('x', (d, i) => x(i % data.length))
      .attr('y', (d, i) => y(Math.floor(i / data.length)))
      .attr('width', x.bandwidth())
      .attr('height', y.bandwidth())
      .style('fill', (d) => color(d));

          // 색상 척도 막대 바 생성
    const colorScale = d3
      .select(this.$refs.colorScale)
      .append('svg')
      .attr('width', 20)
      .attr('height', height)
      .append('g')
      .selectAll('rect')
      .data(color.ticks(10))
      .enter()
      .append('rect')
      .attr('x', 0)
      .attr('y', (d) => color(d))
      .attr('width', 20)
      .attr('height', height / 10)
      .style('fill', (d) => color(d));

      colorScale
      .append('text')
      .attr('x', 30)
      .attr('y', (d) => color(d) + height / 10 / 2)
      .attr('dy', '0.35em')
      .text((d) => d.toFixed(1))

        //--
        const ctx = document.getElementById('myChart');
        
        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
                datasets: [{
                    label: '# of Votes',
                    data: [122, 19, 3, 5, 2, 8],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        });


        const ctx2 = document.getElementById('myChart2');
        
        new Chart(ctx2, {
            type: 'bar',
            data: {
                labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
                datasets: [{
                    label: '# of Votes',
                    data: [122, 19, 3, 5, 2, 8],
                    borderWidth: 1
                }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false, 
                    }
                },
                scales: {
                    x: {
                        display: false,
                        beginAtZero: true,
                    },
                    y: {
                        display: true,
                        beginAtZero: true,
                    }
                }
            }
        });

        const lineCtx = document.getElementById('myLineChart');

        let linedata =  {
                labels: ['Red', 'ㅁㅁㅁ', 'Yellow', 'Green', 'Purple', 'Orange'],
                datasets: [
                    {
                        label: 'Dataset 1',
                        data: [65, 59, 80, 81, 56, 55, 40],
                        borderColor: 'rgb(75, 192, 192)',
                    },
                    {
                        label: 'Dataset 2',
                        data: [1, 31, 20, 41, 52, 15, 89],
                        borderColor: 'rgb(75, 23, 231)',
                    }
                ]
            }

        new Chart(lineCtx, {
            type: 'line',
            data: linedata,
            options: {
                scales: {
                    y: {
                        suggestedMin: 50,
                        suggestedMax: 100
                    }
                }
            }
        });
        //----------------------- 
        
        
        const plineCtx = document.getElementById('pLineChart');
        const pdata1 = [];
        const pdata2 = [];
        
        let prev = 100;
        let prev2 = 80;
        
        for (let i = 0; i < 60; i++) {
            prev += 5 - Math.random() * 10;

            pdata1.push({x: i, y: prev});

            prev2 += 5 - Math.random() * 10;
            
            pdata2.push({x: i, y: prev2});
        }
        console.log(pdata1)
        // const totalDuration = 10000;
        // const delayBetweenPoints = totalDuration / pdata1.length;
        // const previousY = (plineCtx) => plineCtx.index === 0 ? plineCtx.chart.scales.y.getPixelForValue(100) : plineCtx.chart.getDatasetMeta(plineCtx.datasetIndex).data[plineCtx.index - 1].getProps(['y'], true).y;
        // const animation = {
        //     x: {
        //         type: 'number',
        //         easing: 'linear',
        //         duration: delayBetweenPoints,
        //         from: NaN, // the point is initially skipped
        //         delay(ctx) {
        //         if (ctx.type !== 'data' || ctx.xStarted) {
        //             return 0;
        //         }
        //         ctx.xStarted = true;
        //         return ctx.index * delayBetweenPoints;
        //         }
        //     },
        //     y: {
        //         type: 'number',
        //         easing: 'linear',
        //         duration: delayBetweenPoints,
        //         from: previousY,
        //         delay(ctx) {
        //         if (ctx.type !== 'data' || ctx.yStarted) {
        //             return 0;
        //         }
        //         ctx.yStarted = true;
        //         return ctx.index * delayBetweenPoints;
        //         }
        //     }
        // };

        let plinedata =  {
                labels: ['0', '10', '20', '30', '40', '50'],
                datasets: [
                    {
                        label: "Data1",
                        data: pdata1,
                        borderColor: 'rgb(75, 192, 192)',
                        pointRadius: 0,
                    },
                    {
                        label: 'Data2',
                        data: pdata2,
                        borderColor: 'rgb(75, 23, 231)',
                        pointRadius: 0,
                    }
                ]
            }

        new Chart(plineCtx, {
            type: 'line',
            data: plinedata,
            options: {
                // animation,
                interaction: {
                    intersect: false
                },
                plugins: {
                    legend: false
                },
                scales: {
                    x: {
                        type: 'linear'
                    }
                }
            }
        });

    }   
}
    
</script>
<style lang="scss" scoped>
    #pline{
        position: relative;
        .dimmer-test{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #f0f;
            opacity: 0.3;

        }
    }
    #colorScale {
  float: left;
  margin-right: 10px;
}
</style>