<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>데이터/ TASK 운영</span>
                    <span>차량 모니터링 목록</span>
                    <span class="active">Motor Output Anomaly Detection 상세</span>
                </div>
                <h2>[TASK_AD] {{taskData.taskName}}</h2>
            </div> -->
            <BreadcrumbLayout pageId="monTASK_taskAD" :name="'[TASK_AD] ' + taskData.taskName"/>
            <div class="item_info">
                <div class="item_title">
                    <strong class="task_project">[PROJECT] {{ prjData.project_name }}</strong>
                    <div class="item_info_btn_box">
                        <button type="button" class="btn_fold" @click="hideProjectInfo">{{ foldStatus }}</button>
                        <button type="button" class="btn_evt_group" @click="hideUtils"></button>
                        <ul class="evt_btn_box radiusbox" :style="{ display: InferenceUtil ? 'block' : 'none' }">
                            <li class="download_item">
                                <button type="button" @click="makePDF">데이터 다운로드</button>
                            </li>
                            <li class="share_item">
                                <button type="button" @click="clipCoppy">공유하기</button>
                            </li>
                            <li class="retouch_item" v-if="manageRole">
                                <button type="button" @click="taskModify">TASK 수정</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item_table radiusbox" v-if="visibleProjectInfo">
                    <table>
                        <thead>
                            <tr>
                                <th>Project ID</th>
                                <th>AWS ID</th>
                                <th>PM</th>
                                <th>Project Type</th>
                                <th>Unit</th>
                                <th>Last Activity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <!-- <td>{{ taskData.projectName }}</td>
                                <td>{{ taskData.awsId }}</td>
                                <td>{{ taskData.creatorName }}</td>
                                <td>{{ taskData.type }}</td>
                                <td>{{ taskData.teamName }}</td>
                                <td>{{ taskData.lastVisit }}</td> -->
                                <td>{{prjData.project_name}}</td>
                                <td>{{prjData.aws_id}}</td>
                                <td>{{prjData.pm}}</td>
                                <td>{{prjData.project_type}}</td>
                                <td>{{prjData.team_name}}</td>
                                <td>{{prjData.last_activity}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="item_desc">
                        <div class="desc_title">Description</div>
                        <!-- <div class="desc_txt">{{ taskData.description }}</div> -->
                        <div class="desc_txt">{{ prjData.project_description }}</div>
                    </div>
                </div>
            </div>
            <div id="monCont" class="moa_dete">
                <div class="filter_box">

                    <!-- selectBox -->
                    <select name="a" style="width: 185px; background-position: 155px center;" v-model="oemSelect">
                        <option value="0">OEM 선택</option>
                        <option v-for="oem in oemOption" :key="oem.id" :value="oem.id">
                            {{ oem.value }}
                        </option>
                    </select>
                    <select name="a" style="width: 185px; background-position: 155px center;" v-model="vehicleSelect">
                        <option value="0">Vehicle 선택</option>
                        <option v-for="vehicle in vehicleOption" :key="vehicle.id" :value="vehicle.id">
                            {{ vehicle.value }}
                        </option>
                    </select>
                    <select name="a" style="width: 185px; background-position: 155px center;" v-model="edgeSelect">
                        <option value="0">Edge ID 선택</option>
                        <option v-for="edge in edgeOption" :key="edge.id" :value="edge.id">
                            {{ edge.value }}
                        </option>
                    </select>
                    <!-- selectBox -->

                    <!-- date filter -->
                    <div class="date_filter">
                        <select name="datelist" style="width: 110px; background-position: 80px center;" v-model="selectDuration">
                            <option value="0" selected>기간선택</option>
                            <option value="1">1일</option>
                            <option value="7">7일</option>
                            <option value="14">14일</option>
                            <option value="30">30일</option>
                        </select>
                        <div class="date_period">
                            <div class="date_box">
                                <span style="display:inline-block ; padding : 0 10px 0 10px;" :style="picStartTxtStyle" ref="picStartTxt">{{ picStartDate }}</span> ~ <span style="display:inline-block ; padding: 0 0 0 10px;" :style="picEndTxtStyle" ref="picEndTxt">{{picEndDate}}</span>
                            </div>
                            <button type="button" ref="datePicToggle" @click="datePicToggle()"></button>
                            <div class="calender_box radiusbox" ref="calenderBox" style="display:none">
                                <div class="start_date date_box" ref="startDateBox" @click="onDatePic('S')" style="display:block">
                                    <VCalendarUi :dateInterface="'start'" :setDate="'7'"/>
                                    <button type="button" @click="pickerNext()">다음</button>
                                </div>
                                <div class="end_date date_box" ref="endDateBox" @click="onDatePic('E')">
                                    <VCalendarUi :dateInterface="'end'" :minDate="picStartDate"/>
                                    <button type="button" @click="pickerPrev()">이전</button>
                                    <button type="button" class="mcbtn" @click="apiCallMando()">조회</button>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn_send mcbtn" @click="apiCallMando()">조회</button>
                    </div>
                    <!-- date filter -->

                </div>
                <div class="float_box status_box">
                    <div class="status radiusbox" :class="{'normal': activeStatus === 'normal', 'danger': activeStatus === 'danger'}">
                        <button type="button" v-if="manageRole" @click="showModalClear();">Clear</button>
                        <div>
                            <span>Status</span>
                            <strong>{{ statusText }}</strong>
                        </div>
                    </div>
                    <div class="time_distance radiusbox">
                        <div class="float_box">
                            <div>
                                <span>총 주행 거리</span>
                                <strong>{{ statusKm }}</strong>
                                <sup>km</sup>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="time_distance radiusbox">
                        <div class="float_box">
                            <div>
                                <span>총 운행 시간</span>
                                <strong>{{ statusHour }}</strong>
                                <sup>hour</sup>
                            </div>
                            <div>
                                <span>총 주행 거리</span>
                                <strong>{{ statusKm }}</strong>
                                <sup>km</sup>
                            </div>
                        </div>
                    </div> -->
                    <div class="update radiusbox" ref="autoMonitoringDiv">
                        <strong ref="autoMonitoringStrong">Monitoring {{ autoMonitoringText1 }}</strong>
                        <p ref="autoMonitoringP">자동 Monitoring이<br> {{ autoMonitoringText2 }} 된 상태입니다.</p>
                        <div class="img_box"></div>
                        <span ref="autoMonitoringSpan">{{ autoMonitoringTime }}</span>
                        <div class="toggle_box">
                            <input type="checkbox" name="monControl" id="monControl" value="monControl" v-model="autoMonitoring" @change="isAutoMonitoring()">
                            <label for="monControl"></label>
                        </div>
                    </div>
                </div>

                <!-- Inference Result -->
                <div class="result_box radiusbox">
                    <div class="title_box">
                        <strong>Inference Result</strong>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <colgroup>
                                <col style="width: 18%;">
                                <col style="width: 50%">
                                <col style="width: 17%;">
                                <col style="width: 17%;">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>Date Time</th>
                                    <th>Item</th>
                                    <th>Model version</th>
                                    <th>Anomaly Score</th>
                                    <th>Anomaly Result</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in infData" :key="index" @click="showModalChart(item)" style="cursor: pointer;">
                                    <td>{{ item.dateTime }}</td>
                                    <td>{{ item.item }}</td>
                                    <td>{{ item.version }}</td>
                                    <td>{{ item.score }}</td>
                                    <td class="result ng">{{ item.result }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- Inference Result -->

                <div class="float_box sub_data_box">
                    <div class="anomaly_status radiusbox">
                        <div class="title_box">
                            <strong>Anomaly Status</strong>
                        </div>
                        <div class="float_box">
                            <div class="normal" :class="{ active: isNormalActive }">
                                Normal
                            </div>
                            <div class="mild" :class="{ active: isMildActive }">
                                Mild
                            </div>
                            <div class="caution" :class="{ active: isCautionActive }">
                                Caution
                            </div>
                            <div class="servere" :class="{ active: isServereActive }">
                                Servere
                            </div>
                        </div>
                    </div>
                    <div class="anomaly_count radiusbox">
                        <div class="title_box">
                            <strong>Anomaly Count</strong>
                        </div>
                        <ul>
                            <li>
                                <div class="icon"></div>
                                <span>Normal</span>
                                <div class="txt_box">
                                    <strong>{{ nomalCnt }}</strong>
                                    <span>times</span>
                                </div> 
                            </li>
                            <li>
                                <div class="icon"></div>
                                <span>Mild</span>
                                <div class="txt_box">
                                    <strong>{{ mildCnt }}</strong>
                                    <span>times</span>
                                </div> 
                            </li>
                            <li>
                                <div class="icon"></div>
                                <span>Caution</span>
                                <div class="txt_box">
                                    <strong>{{ cauntionCnt }}</strong>
                                    <span>times</span>
                                </div> 
                            </li>
                            <li>
                                <div class="icon"></div>
                                <span>Servere</span>
                                <div class="txt_box">
                                    <strong>{{ servereCnt }}</strong>
                                    <span>times</span>
                                </div> 
                            </li>

                        </ul>
                    </div>
                    <div class="anomaly_history radiusbox">
                        <div class="title_box">
                            <strong>Anomaly History</strong>
                        </div>
                        <button type="button" class="detail_btn" @click="detailModal">자세히 보기</button>
                        <div class="mando_table_wrap">
                            <table class="mando_table">
                                <thead>
                                    <tr>
                                        <th>Date time</th>
                                        <th>item</th>
                                        <th>MOdel version</th>
                                        <th>Anomaly Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="item" v-for="(item, index) in historyData" :id="'histTr_'+index" :key="index" @click="clickHistory(item, index)" :class="{ 'active' : selectHistItem === index }" >
                                        <td>{{ item.dateTime }}</td>
                                        <td>{{ item.item }}</td>
                                        <td>{{ item.version }}</td>
                                        <td>{{ item.score }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="dtc_map radiusbox">
                        <div class="title_box">
                            <strong>DTC Map</strong>
                        </div>
                        <div class="map">
                            <naver-map id="map" style="width: 100%; height: 100%" :mapOptions="mapOptions" :initLayers="initLayers" :key="gpsCenterKey" >
                                <naver-marker
                                    v-for="(gpsMarker, index) in gpsMarker"
                                    :key="index"
                                    :latitude="gpsMarker.latitude"
                                    :longitude="gpsMarker.longitude"
                                >
                                </naver-marker>
                            </naver-map>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- modal -->
    <div id="modalWrap" ref="modalWrap" style="display: none;">

        <!-- chart modal -->
        <div class="radiusbox modal_contents chart_modal" ref="modalChart" style="display: none;">
            <strong class="modal_title">
                {{ modalTitle }}
            </strong>
            <div class="chart_box" style="background-color: rgba(255, 255, 255, 0)">
                <canvas ref="chartCanvas"></canvas>
            </div>
            <div class="info_box">
                <div class="evt_time">
                    Event time : {{ modalEventTime }}
                </div>
                <div class="result">
                    Result : 
                    <span style="color: #4791FF">{{ modalResult }}</span>
                </div>
            </div>
            <button type="button" class="btn_close" @click="closeModal()" ></button>
        </div>

        <!-- clear modal -->
        <div class="radiusbox modal_contents message_check message_modal" ref="modalClear" style="display: none;">
            <div class="message_box">
                Clear 를 실행하시겠습니까?
            </div>
            <button type="button" class="btn_cancel" @click="closeModalClear()">취소</button>
            <button type="button" class="btn_check mcbtn" @click="execModalClear()">확인</button>
        </div>

        <!-- Anomaly History modal -->
        <div class="radiusbox modal_contents filter_modal" ref="modalFilter" style="display: none;">
            <strong class="modal_title">Anomaly History</strong>
            <div id="recentFilter">
                <div class="filter_box">
                    <div class="filter_list_box" ref="filterListBox">
                        <ul class="float_box" ref="filterBox">
                            <li v-if="filterList.length === 0" style="padding-right: 20px;"><span>필터</span> : <span>전체</span></li>
                            <li v-for="(item, index) in filterList" :key="index" ref="filterItem">
                                <span>{{ item.keyName }}</span> : <span>{{ item.value }}</span>
                                <button class="btn_close" @click="removeFilter(index)"></button>
                            </li>
                        </ul>
                        <button style="display: none" class="btn_all_filter" ref="filterAllBtn" @click="filterToggle">전체보기</button>
                    </div>
                </div>
            </div>
            
            <div class="list_wrap">
                <div class="table_list"> 
                    <div class="filter_box">
                        <div class="date_filter">
                            <select name="datelist"  @change="dateSelectChange" v-model="selectDate" style="width: 150px; background-position: 120px center;" >
                                <option value="0" selected>기간선택</option>
                                <option value="1">1일</option>
                                <option value="7">7일</option>
                                <option value="14">14일</option>
                                <option value="30">30일</option>
                            </select>
                            <div class="date_period">
                                <div class="date_box">
                                    <span :style="picStartTxtStyleModal" ref="picStartTxtModal">{{ picStartDateModal }}</span> ~ <span :style="picEndTxtStyleModal" ref="picEndTxtModal">{{picEndDateModal}}</span>
                                </div>
                                <button type="button" ref="datePicToggleModal" @click="datePicToggleModal()"></button>
                                <div class="calender_box radiusbox" ref="calenderBoxModal" style="display:none">
                                    <div class="start_date date_box" ref="startDateBoxModal" @click="onDatePicModal('S')" style="display:block" >
                                        <VCalendarUi :dateInterface="'start'" :setDate="'7'"/>
                                        <button type="button" @click="pickerNextModal()">다음</button>
                                    </div>
                                    <div class="end_date date_box" ref="endDateBoxModal" @click="onDatePicModal('E')">
                                        <VCalendarUi :dateInterface="'end'" :minDate="picStartDateModal"/>
                                        <button type="button" @click="pickerPrevModal()">이전</button>
                                        <button type="button" class="mcbtn" @click="getDriListModal()">조회</button>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn_send mcbtn" @click="getDriListModal()">조회</button>
                        </div>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <thead>
                                <tr>
                                    <th v-for="(item, index) in rpaColumnDefs" :key="index">
                                        <div class="col_name">
                                            {{item.field}}
                                            <button class="btn_filter" @click="showFilter"></button>
                                        </div>
                                    </th>
                                </tr>
                                <tr id="filterWrap" ref="filterInputWrap">
                                    <th class="filter_input" v-for="(item,index) in rpaColumnId" :key="index">
                                        <div class="input_box" :data-id="item.id">
                                            <input type="text" @focus="setActive"  @keydown.enter="addFilter" >
                                            <button class="btn_add" ref="btnAddfilter" @click="addFilter" :data-index="index"></button>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  v-for="(item, index) in rpaSliced.history" :key="index">
                                    <td>{{item.Datetime}}</td>
                                    <td>{{item.InferenceModel}}</td>
                                    <td>{{item.VehicleModel}}</td>
                                    <td>{{item.FaultProb.toFixed(1)}}</td>
                                    <td :style="{color: item.Flevel === 'NG' ? '#F4514D' : (item.Flevel === 'OK' ? '#00B96B' : '#020A0A'), 'font-weight': 700, 'font-family': 'Montserrat, sans-serif'}">
                                        {{item.Flevel}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="list_table_footer">
                        <PaginationUi
                            :totalItems="totalItems"
                            :itemsPerPage="itemsPerPage"
                            @page-changed="onPageChanged"
                        ></PaginationUi>
                    </div>
                </div>
            </div>
            <button type="button" class="btn_close" @click="closeModal()" ></button>
        </div>
    </div>
    <!-- modal -->

    <div id="lodingWrap" style="display:none" ref="lodingWrap">
        <div class="loading-container">
            <div class="loding-animation-holder">
                <div class="loading-animator"></div>
                <div class="loading-animator"></div>
                <div class="loading-animator"></div>
                <div class="loading-animator"></div>
                <div class="middle-circle"></div>
            </div>
        </div>
    </div> 
    <FooterLayout/>
</template>
<!-- <script setup>
    import { ref } from "vue";
    import { NaverMap, NaverPolyline } from "vue3-naver-maps";

    //path 
    const path = ref([
        { lat: 36.4145180, lng: 129.37381 },
        { lat: 36.414555, lng: 129.373835 },
        { lat: 36.414518, lng: 129.37381 },
    ]);

    const map = ref();
    const mapOptions = {
        latitude: 37.40455253359307, // 지도 중앙 위도
        longitude: 127.10015795115307, // 지도 중앙 경도
        zoom: 17,
        zoomControl: false,
        zoomControlOptions: { position: "TOP_RIGHT" },
    };
    const initLayers = [
        "BACKGROUND",
        "BACKGROUND_DETAIL",
        "POI_KOREAN",
        "TRANSIT",
        "ENGLISH",
    ];

    const onLoadMap = (mapObject) => {
        map.value = mapObject;
    };
</script> -->
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import VCalendarUi from '@/components/VCalendarUi.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';

    import common from '@/assets/js/common'
    import apiCallVphm01 from '@/assets/js/apiCallVphm01';

    import gsap from 'gsap';
    import PaginationUi from "@/components/PagenationUi.vue";
    
    import Chart from 'chart.js/auto';
    import { NaverMap, NaverMarker } from "vue3-naver-maps";

    export default {
        name: 'MOADetectionView',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            VCalendarUi,
            PaginationUi,
            NaverMap,
            NaverMarker,
            BreadcrumbLayout
        },
        data(){
            const picStartDate = null;
            const picEndDate = null;
            return{
                // project , task 정보
                taskData: {
                    projectId: null,
                    projectName: null,
                    awsId: null,
                    taskName: null,
                    creatorName: null,
                    type: null,
                    teamName: null,
                    description: null
                },

                // OEM 선택, Vehicle 선택, Edge ID 선택
                oemSelect: '0',
                vehicleSelect: '0',
                edgeSelect: '0',
                edgeSelectName: '',

                oemId: 0,
                vehicleId: 0,
                edgeId: 0,

                oemOption: [],
                vehicleOption: [],
                edgeOption: [],

                // 기간선택
                selectDuration: 7,

                // VCalendarUi / .date_period
                picStartDate,
                picEndDate,
                picColor: {
                    startStatus: false,
                    endStatus: false,
                    active: '#00B4ED',
                    default: '#020A0A'
                },
                picStartDateModal: '',
                picEndDateModal: '',
                picColorModal: {
                    startStatus: false,
                    endStatus: false,
                    active: '#00B4ED',
                    default: '#020A0A'
                },

                // Status , clear , 총 운행 시간 , 총 주행 거리
                statusText: '-',
                statusHour: '0',
                statusKm: '0',
                activeStatus: 'servere',

                // 자동 모니터링
                autoMonitoring: false,
                autoMonitoringText1: 'OFF',
                autoMonitoringText2: '비활성화',
                autoMonitoringTime: '',


                // Inference Result
                infData: [],

                // modal
                modalTitle: '',
                modalEventTime: '',
                modalResult: '',
                chartInstance: null,
                selectDate:'1',
                filterList:[],
                rPA: '0',
                rpaColumnDefs: [
                    { field : "Date time" },
                    { field : "Item" },
                    { field : "Model Version" },
                    { field : "Anomaly Score" },
                    { field : "Result" }
                ],
                rpaColumnId:[
                    { id : "date_time " },
                    { id : "project_name" },
                    { id : "user_name" },
                    { id : "activity_code" },
                    { id : "activity_log" }
                ],
                rpaSliced:[],

                // Anomaly Status
                isNormalActive: false,
                isMildActive: false,
                isCautionActive: false,
                isServereActive: false,

                // Anomaly Count
                nomalCnt: 0,
                mildCnt: 0,
                cauntionCnt: 0,
                servereCnt: 0,

                // Anomaly History
                historyData: [],

                //DTC Map

                foldStatus: '접기',
                InferenceUtil: false,

                visibleProjectInfo: true,

                prjData: {
                    project_name: "",
                    aws_id: "",
                    pm: "",
                    project_type: "",
                    team_name: "",
                    last_activity: "",
                    project_description: "",
                },
                // gps
                gpsMarker: [],
                mapOptions : {
                    latitude: common.getDefaultLat(), // 지도 중앙 위도
                    longitude: common.getDefaultLong(), // 지도 중앙 경도
                    zoom: 11,
                    zoomControl: false,
                    zoomControlOptions: { position: "TOP_RIGHT" },
                },
                initLayers : [
                    "BACKGROUND",
                    "BACKGROUND_DETAIL",
                    "POI_KOREAN",
                    "TRANSIT",
                    "ENGLISH",
                ],
                gpsCenterKey: 0,

                selectHistItem: null,
                projectId:'',

                // role
                viewRole: false,
                manageRole: false,

                 // PaginationUi
                totalItems: 0,
                itemsPerPage: 0,
                currentPage: 1,
                //Interval
                apiCallMandoInterval: null,

            };
        },
        // created() {
        //     setInterval(() => {
        //         this.isAutoMonitoring(); 
        //     }, 10000); // * (1000 밀리초)마다 실행
        // },
        computed: {
            picStartTxtStyle() {
                return {
                    color: this.picColor.startStatus ? this.picColor.active : this.picColor.default
                };
            },
            picEndTxtStyle() {
                
                return {
                    color: this.picColor.endStatus ? this.picColor.active : this.picColor.default
                };
            },
            picStartTxtStyleModal() {
                return {
                    color: this.picColorModal.startStatus ? this.picColorModal.active : this.picColorModal.default
                };
            },
            picEndTxtStyleModal() {
                
                return {
                    color: this.picColorModal.endStatus ? this.picColorModal.active : this.picColorModal.default
                };
            },
            
        },
        async mounted() {
            // Role
            await this.setRole();

            this.setDate(this.selectDuration);

            // prj info
            const prjId = this.$route.query.prjId;
            const prjData = await this.getPrjData(prjId);
            if(typeof prjData !== "undefined" && prjData !== null && prjData !== "") {
                this.prjData = prjData.project;
            }

            try { // monitoring-task 정보
                await common.apiGet('/monitoring-task/' + this.$route.query.taskId + '?viewRole=' + this.viewRole).then(r => {
                    console.log('0. monitoring-task 정보');
                    console.log(r.data.data);
                    this.taskData.projectId = r.data.data.projectId;
                    this.taskData.projectName = r.data.data.projectName;
                    this.taskData.awsId = r.data.data.awsId;
                    this.taskData.taskName = r.data.data.taskName;
                    this.taskData.creatorName = r.data.data.creatorName;
                    this.taskData.type = r.data.data.type;
                    this.taskData.teamName = r.data.data.teamName;
                    this.taskData.lastVisit = r.data.data.lastVisit;
                    this.taskData.description = r.data.data.description;
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            try { // monitoring-task 에 속한 edge-device 정보
                await common.apiGet('/edge-device/monitoring-task/edge-device-group/' + this.$route.query.taskId).then(r => {
                    console.log('0. monitoring-task 에 속한 edge-device 정보');
                    console.log(r.data.data);
                    const o = [];
                    r.data.data.forEach(e => {
                        if (!o.some(item => item.id === e.id)) {
                            o.push({id: e.id, value: e.edge_device_id, oem_id: e.oem_id, vehicle_id: e.vehicle_id});
                        }
                    });
                    this.edgeOption = o; // edge 선택 option
                    this.edgeSelect = this.edgeOption[0].id; // 첫번째 선택
                    this.edgeSelectName = this.edgeOption[0].value
                    this.oemSelect = this.edgeOption[0].oem_id // 첫번째 oem 선택
                    this.vehicleSelect = this.edgeOption[0].vehicle_id // 첫번째 vehicle 선택
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            // mando api call
            await this.apiCallMando();

            //projectId
            this.projectId = this.$route.query.prjId;
            // await this.allowAccess();

        },
        watch: {
            oemSelect() {
                if (this.oemSelect == "0") this.oemId = "";
                else this.oemId = this.oemSelect;
                this.getVehicleSelect(this.oemSelect);
            },
            vehicleSelect() {
                if (this.vehicleSelect == "0") this.vehicleId = "";
                else this.vehicleId = this.vehicleSelect;
            },
            edgeSelect() {
                if (this.edgeSelect == "0") this.edgeId = "";
                else this.edgeId = this.edgeSelect;
            },
            selectDuration () {
                this.setDate(this.selectDuration);
            },

        },
        methods: {
            async setRole() {
                const prjId = this.$route.query.prjId;
                this.manageRole = ( await common.getUserRole('type2') || await common.getPrjRole('type2', prjId) );
                this.viewRole = ( await common.getUserRole('type2') || await common.getPrjRole('type1', prjId) );

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            // async allowAccess() {
            //     const accessPage = await this.checkLoginUser();

            //     if(!accessPage) {
            //             alert("SM, Project Manager,Project Researcher,Project Observer만 접근 가능");
            //             this.$router.push('/monTASK/monTaskList');
            //     }
            // },


            // async checkLoginUser() {
            //     const role = await this.getMemberRole();
            //     const projectRole = await this.getProjectRole();

            //     if(role != null && role.role == 'SM') {
            //         return true;
            //     } else if(projectRole !=null && (projectRole.projectRole == "Manager" || projectRole.projectRole == "Researcher" || projectRole.projectRole == "Observer")) {
            //         return true;
            //     } else {
            //         return false;
            //     }
            // },



            /*****************
             * init anomaly
             *****************/
            async initAnomaly() {

                // Status
                this.statusText = '-';
                this.statusHour = 0;
                this.statusKm = 0;

                // Inference Result
                this.infData = [];

                // anomaly status 초기화
                this.isNormalActive = false;
                this.isMildActive = false;
                this.isCautionActive = false;
                this.isServereActive = false;

                // anomaly count
                this.nomalCnt = this.mildCnt = 0;
                this.cauntionCnt = 0;
                this.servereCnt = 0;

                // anomaly History
                this.historyData = [];

                // auto monitoring time
                this.autoMonitoringTime = this.nowTime();
            },

            /**************
             * select box
             **************/
            async getOemSelect() {
                try {
                    const response = await common.apiGet("/edge-device-group/domain/oem/vehicle/1");
                    const o = [];
                    response.data.data.forEach(e => {
                        o.push({id: e.id, value: e.name})
                    });
                    this.oemOption = o;
                } catch(error) {
                    console.log('Fail: /edge-device-group/domain/oem/vehicle/1')
                }
            },
            async getVehicleSelect(id) {
                try {
                    const o = [];
                    if (id != 0) {
                        const response = await common.apiGet("/edge-device-group/domain/oem/vehicle/" + id);
                        response.data.data.forEach(e => {
                            o.push({id: e.id, value: e.name})
                        });
                    }
                    this.vehicleOption = o;
                } catch(error) {
                    console.log('Fail: /edge-device-group/domain/oem/vehicle/'+id)
                }
            },

            /**************
             * chart
             **************/
            async makeChart(data) {

                const vm = this;
                const canvas = vm.$refs.chartCanvas;
                const ctx = canvas.getContext('2d');

                if (this.chartInstance) {
                    this.chartInstance.destroy(); // 기존 차트 파기
                }

                const labels = [];
                const datasets = [];

                const mValueList = [];
                const eValueList = [];
                
                data.forEach(item => {
                    labels.push(item.uid)
                    mValueList.push(item.Mvalue)
                    eValueList.push(item.Evalue)
                });

                datasets.push({
                    label: 'Mvalue',
                    data: mValueList,
                    borderColor: 'rgb(75, 192, 192)',
                })
                datasets.push({
                    label: 'Evalue',
                    data: eValueList,
                    borderColor: 'rgb(75, 23, 231)',
                })

                this.chartInstance = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: datasets,
                    },
                    options: {
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        },
                        plugins: {
                            legend: {
                            display: false
                            }
                        }
                    }
                });
            },

            async isAutoMonitoring() {
                const vm = this;
                const $autoMonitoringDiv = vm.$refs.autoMonitoringDiv; // 바탕색
                const $autoMonitoringStrong = vm.$refs.autoMonitoringStrong; // text1
                const $autoMonitoringP = vm.$refs.autoMonitoringP; // text2
                const $autoMonitoringSpan = vm.$refs.autoMonitoringSpan; // time

                if (this.autoMonitoring) {
                    this.autoMonitoringText1 = 'ON';
                    this.autoMonitoringText2 = '활성화';
                    $autoMonitoringDiv.style.backgroundColor = '#4791FF';
                    $autoMonitoringStrong.style.color = '#FFFFFF';
                    $autoMonitoringP.style.color = '#FFFFFF';
                    $autoMonitoringSpan.style.color = '#9AE9FF';

                    console.log(this.apiCallMandoInterval);
                    await this.apiCallMando();
                    if(this.apiCallMandoInterval === null){
                        this.apiCallMandoInterval = setInterval(()=>{
                            this.apiCallMando();
                        }, 10000)
                    }

                } else {
                    this.autoMonitoringText1 = 'OFF';
                    this.autoMonitoringText2 = '비활성화';
                    $autoMonitoringDiv.style.backgroundColor = '#fff';
                    $autoMonitoringStrong.style.color = '#020A0A';
                    $autoMonitoringP.style.color = '#020A0A';
                    $autoMonitoringSpan.style.color = '#020A0A';

                    clearInterval(this.apiCallMandoInterval);
                    this.apiCallMandoInterval = null; 
                }


            },
            
            async apiCallMando() {

                
                // init
                this.initAnomaly();
                
                const vm = this;
                const $calenderBox = vm.$refs.calenderBox;
                const $startDateBox = vm.$refs.startDateBox;
                const $endDateBox = vm.$refs.endDateBox;
                // const $drivReclistBox = vm.$refs.drivReclistBox;
                
                $calenderBox.style.display = 'none';
                $startDateBox.style.display = 'block';
                $endDateBox.style.display = 'none';
                // $drivReclistBox.style.display = 'block';
                
                // 로딩 시작
                vm.$refs.lodingWrap.style.display = 'block';

                // Anomaly Status
                // 차량 1대의 상세 정보 조회
                try {
                    const r = await apiCallVphm01.post("/inf_detail/status", {"edgeid": this.edgeSelectName});
                    console.log("1. /inf_detail/status - 차량 1대의 상세 정보 조회");
                    console.log(r.data);

                    // 임시(정상 , 정비 필요)
                    if (r.data.is_promlem === 0) {
                        this.statusText = '정상';
                        this.activeStatus = 'normal';
                    } else if (r.data.is_promlem === 1) {
                        this.statusText = '정비 필요';
                        this.activeStatus = 'danger';
                    }
                    
                    // this.statusHour = '1,234';
                    this.statusKm = common.setComma(r.data.mileage);
                    // console.log(r.data.Flevel);
                    
                    
                } catch(error) {
                    console.error('Fail: /inf_detail/status');
                }
                
                // Inference Result
                // 차량 1대에 대해서 최근 진행한 Inference 결과 2개 조회
                try{
                    const r = await apiCallVphm01.post("/inf_detail/inferences", {"edgeid": this.edgeSelectName});
                    console.log("2. /inf_detail/inferences - 차량 1대에 대해서 최근 진행한 Inference 결과 2개 조회");
                    console.log(r.data);

                    const infDataList = [];
                    r.data.inferences.forEach(e => {
                        infDataList.push({
                            dateTime: e.Datetime,
                            item: e.InferenceModel,
                            version: e.VehicleModel,
                            score: (e.FaultProb * 1).toFixed(1) + '%',
                            result: e.result
                        });
                    });
                    this.infData = infDataList;
                    
                } catch(error) {
                    console.error('Fail: /inf_detail/inferences');
                }

                // 차량 1대에 대해서 조회 희망하는 기간만큼의 Inference 결과 조회
                this.selectHistItem = null;
                try {
                    const r = await apiCallVphm01.post("/inf_detail/history", {
                        "edgeid": this.edgeSelectName,
                        "start": this.picStartDate,
                        "end": this.picEndDate,
                        "page_no": 1
                    })
                    console.log("3. /inf_detail/history - 차량 1대에 대해서 조회 희망하는 기간만큼의 Inference 결과 조회");
                    console.log(r.data);

                    const historyDataList = [];
                    r.data.history.forEach(e => {
                        historyDataList.push({
                            dateTime: e.Datetime,
                            item: e.InferenceModel,
                            version: e.VehicleModel,
                            score: (e.FaultProb * 10).toFixed(1) + '%'
                        });
                        if (e.Flevel === 'Nomal') this.nomalCnt++;
                        if (e.Flevel == 'Mild') this.mildCnt = this.mildCnt + 1;
                        if (e.Flevel === 'Caution') this.cauntionCnt++;
                        if (e.Flevel === 'Servere') this.servereCnt++;
                    });


                    if(this.nomalCnt > 0){
                        this.isNormalActive = true;
                    }else{
                        this.isNormalActive = false;
                    }

                    if(this.mildCnt > 0){
                        this.isMildActive = true;
                    }else{
                        this.isMildActive = false;
                    }

                    if(this.cauntionCnt > 0){
                        this.isCautionActive = true;
                    }else{
                        this.isCautionActive = false;
                    }

                    if(this.servereCnt > 0){
                        this.isServereActive = true;
                    }else{
                        this.isServereActive = false;
                    }

                    this.historyData = historyDataList;
                    

                    if ( this.historyData.length > 0) {
                        const data = this.historyData[0];
                        this.clickHistory(data, 0);
                    }

                } catch(error) {
                    console.error('Fail: /inf_detail/history');
                }

                // 차량 1대의 운행 좌표 조회 (추후구현)
                // await apiCallVphm01.post("/inf_detail/location").then(r => {
                //     console.log("4. /inf_detail/location - 차량 1대의 운행 좌표 조회 (추후구현)");
                //     console.log(r.data);
                // });

                // OEM 선택
                await this.getOemSelect();

                // 로딩 시작 끝
                vm.$refs.lodingWrap.style.display = 'none';
            },

            // -------- DATE PICKER START -----
            datePicToggle(){
                this.autoMonitoring = false;
                this.isAutoMonitoring();

                const vm = this;
                const _display = vm.$refs.calenderBox.style.display;
                const _startDateBox = vm.$refs.startDateBox.style.display;
                // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
                // console.log(_display);
                
                if(_display == 'block'){
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'none';
                    vm.picColor.startStatus = false;
                    vm.picColor.endStatus = false;

                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'block';
                    console.log(_startDateBox);
                    if(_startDateBox == 'block'){
                        vm.picColor.startStatus = true;
                        vm.picColor.endStatus = false;
                    }else if( _startDateBox == 'none'){
                        vm.picColor.startStatus = false;
                        vm.picColor.endStatus = true;
                    }
                }
            },
            onDatePic(flag){
                if(flag == 'S'){   
                    if(window.selectDate.startDate !== null ){
                        this.picStartDate = window.selectDate.startDate;
                    }
                    
                }else if(flag == 'E'){
                    if(window.selectDate.picEndDate !== null ){
                        this.picEndDate = window.selectDate.endDate;
                    }
                }
            },
            pickerNext(){
                this.$refs.startDateBox.style.display = 'none';
                this.$refs.endDateBox.style.display = 'block';

                this.picColor.startStatus = false;
                this.picColor.endStatus = true;
                // console.log(this.picColor.endStatus);
                // console.log(this.picColor.target);
                
            },
            pickerPrev(){
                this.$refs.startDateBox.style.display = 'block';
                this.$refs.endDateBox.style.display = 'none';

                this.picColor.startStatus = true;
                this.picColor.endStatus = false;
            },

            setDate(day) {
                const vm = this;
                const stoday = new Date();
                stoday.setDate(stoday.getDate() - day); 
                const syear = stoday.getFullYear();
                const smonth = stoday.getMonth() + 1;
                const sday = stoday.getDate();
                const start_formattedDate = syear + '-' + (smonth < 10 ? '0' : '') + smonth + '-' + (sday < 10 ? '0' : '') + sday;
                

                const etoday = new Date();
                const eyear = etoday.getFullYear();
                const emonth = etoday.getMonth() + 1;
                const eday = etoday.getDate();
                const end_formattedDate = eyear + '-' + (emonth < 10 ? '0' : '') + emonth + '-' + (eday < 10 ? '0' : '') + eday;
                // console.log(start_formattedDate + ' ~ ' + end_formattedDate);

                vm.picStartDate = start_formattedDate;
                vm.picEndDate = end_formattedDate;
                window.selectDate.startDate = start_formattedDate;
                window.selectDate.endDate = end_formattedDate;

                this.selectDate = day;

            },
            // -------- DATE PICKER END -----

            nowTime() {
                const date = new Date();
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            },

            clickHistory(item, index) {
                if (this.selectHistItem === index) {
                    this.selectHistItem = null;
                } else {
                    this.selectHistItem = index;

                    this.setMap(item);
                }
            },

            async setMap(item) {
                const dateTime = item.dateTime;
                const date = dateTime.split(' ')[0];

                const postData = {
                    "edgeid": item.version,
                    "start": date,
                    "end": date,
                };

                let _lat = common.getDefaultLat();
                let _long = common.getDefaultLong();

                const locations = await this.getLocation(postData);
                if ( typeof locations !== "undefined" && locations !== null && locations !== "" ) {
                    for ( let i = 0; i < locations.length; i++ ) {
                        const location = locations[i];
                        if ( location.Datetime == dateTime ) {

                            if ( location.gps_y !== null && location.gps_x !== null ) {
                                _lat = parseFloat(location.gps_x);
                                _long = parseFloat(location.gps_y);
                            }
                            break;
                        }
                    }
                }
                
                const _gpsMakrker = [];
                _gpsMakrker.push (
                    {
                        latitude: _lat,
                        longitude: _long,
                    }
                )

                this.gpsMarker = _gpsMakrker;
                this.updateCenter(_lat, _long);
            },

            // gps 
            updateCenter(lat, lng) {
                this.mapOptions.latitude = lat; // 새 위도 값으로 업데이트
                this.mapOptions.longitude = lng; // 새 경도 값으로 업데이트

                this.gpsCenterKey += 1;
            },

            // modal
            async showModalChart(item){
                const $modal = this.$refs.modalWrap;
                const $modalChart = this.$refs.modalChart;
                $modal.style.display = "block";
                $modalChart.style.display = "block";
                this.modalTitle = item.version;
                this.modalEventTime = item.dateTime;
                this.modalResult = item.result;

                const postData = {
                    "VehicleModel": item.version,
                    "InferenceModel": item.item,
                    "date": item.dateTime,
                };
                const signalData = await this.getAdSignal(postData);
                
                if ( typeof signalData !== "undefined" && signalData !== null && signalData !== "" ) {
                    await this.makeChart(signalData);
                }
            },
            async closeModal(){
                const $modal = this.$refs.modalWrap;
                const $modalChart = this.$refs.modalChart;
                const $modalFilter = this.$refs.modalFilter;
                
                $modal.style.display = "none";
                $modalChart.style.display = "none";
                $modalFilter.style.display = "none";
                this.modalTitle = '';
                this.modalEventTime = '';
                this.modalResult = '';
            },
            async showModalClear() {
                if ( this.manageRole ) {
                    const $modal = this.$refs.modalWrap;
                    const $modalChart = this.$refs.modalClear;
                    $modal.style.display = "block";
                    $modalChart.style.display = "block";
                }
            },
            async closeModalClear() {
                const $modal = this.$refs.modalWrap;
                const $modalChart = this.$refs.modalClear;
                $modal.style.display = "none";
                $modalChart.style.display = "none";
            },
            async execModalClear() {
                await apiCallVphm01.put("/inf_detail/del_abnormal", {'edgeid': this.edgeSelectName}).then(r => {
                    console.log("5. /inf_detail/del_abnormal - 차량 Edge 1개의 이상진단/정비필요 표시 삭제");
                    console.log(r.data);
                    this.closeModalClear();
                    this.apiCallMando();
                });
            },

            // 프로젝트정보 , 부가기능
            async hideProjectInfo() { // 프로젝트 정보 - 접기 , 펼치기
                if(this.visibleProjectInfo == true) {
                    this.visibleProjectInfo = false;
                    this.foldStatus = '펼치기';
                } else {
                    this.visibleProjectInfo = true;
                    this.foldStatus = '접기';
                }
            },
            hideUtils() { // 부가기능 - 보이기 숨기기
                if(this.InferenceUtil == true) {
                    this.InferenceUtil = false;
                } else if(this.InferenceUtil == false){
                    this.InferenceUtil = true;
                }
            },
            async clipCoppy() { // 공유하기
                let dummy = document.createElement("textarea");
                document.body.appendChild(dummy);
                try {
                    const url = window.document.location.href;
                    dummy.value = url;
                    dummy.select();
                    document.execCommand("copy");
                    document.body.removeChild(dummy);
                    alert('url copy success')
                } catch(error) {
                    console.error(error);
                } 
            },
            async taskModify() { // 수정
                if ( this.manageRole ) {
                    const taskId = this.$route.query.taskId;
                    const prjId = this.$route.query.prjId;
                    const taskTypeId = this.$route.query.taskTypeId;
                    const query= {
                        prjId: prjId,
                        taskId: taskId,
                        taskTypeId: taskTypeId,
                    };
                    // console.log(taskId);
                    try {
                        this.$router.push({
                            name: 'AddTask', query });
                    } catch(error) {
                        console.error('Error:', error);
                    }
                }
            },

            async getPrjData(prjId){
                try {
                    const response = await common.apiGet('/project/' + prjId );
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getAdSignal(postData){
                let response = "";
                try {
                    response = await apiCallVphm01.post('/inf_popup/inf_detail/', postData);
                    return response.data.values;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
                return response;
            },
            async getLocation(postData){
                let response = "";
                try {
                    response = await apiCallVphm01.post('/inf_detail/location/', postData);
                    return response.data.locations;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
                return response;
            },

            //-------API------
            async getMemberRole() {
                let response;
                try {
                    let url = `/member/check-role`;
                    response = await common.apiGet(url);
                    return response.data.data;
                } catch(error) {
                    console.error("error", error);
                    return null;
                }
            },
            async getProjectRole() {
                let response;
                try {
                    let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
                    response = await common.apiGet(checkProjectRoleUrl);
                    return response.data.data;
                } catch(error) {
                    console.error("error", error);
                    return null;
                }
            },
            // Anomaly History modal
            dateSelectChange() {
                switch(this.selectDate) {
                    case "7":
                        this.dateSet = 7;
                        break;
                    case "14":
                        this.dateSet = 14;
                        break;
                    case "30":
                        this.dateSet = 30;
                        break;
                    default:
                        this.dateSet = 1;
                        break;
                }

                const todayDate = new Date();

                todayDate.setDate(todayDate.getDate() - this.dateSet);
                    const year1 = todayDate.getFullYear();
                    const month1 = todayDate.getMonth() + 1;
                    const day1 = todayDate.getDate();
                    const formattedDate1 = year1 + '-' + (month1 < 10 ? '0' : '') + month1 + '-' + (day1 < 10 ? '0' : '') + day1;

                    this.picStartDateModal = formattedDate1;
                    window.selectDate.startDate = formattedDate1;
            },
            //filter=========
            filterToggle(){
                const $listFilter = this.$refs.listFilter;
                const $filterBox = this.$refs.filterListBox;
                const $filterBtn = this.$refs.filterAllBtn;
                const hasOpenClass = $filterBox.classList.contains("open");
                if(hasOpenClass){
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.remove("open");
                            $filterBtn.classList.remove("active");
                            $filterBtn.innerText = '전체보기'
                        },
                        height: '70px',
                        duration: .3
                    });
                }else{
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.add("open");
                            $filterBtn.classList.add("active");
                            $filterBtn.innerText = '접기'
                        },
                        height: 'auto',
                        duration: .3
                    });
                }
                
            },
            removeFilter(index) {
                const $filterItem = this.$refs.filterItem;
                const $filterInputWrap = this.$refs.filterInputWrap;
                const dataId = this.filterList[index].keyId.trim();
                console.log(dataId);
                console.log($filterInputWrap.querySelector(`[data-id='${dataId}']`).classList);
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).classList.remove("disable");
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).querySelector("input").disabled = false;
                this.filterList.splice(index, 1);
                if($filterItem.length <= 1){
                    this.$refs.filterAllBtn.style.display = "none";
                }
                this.filterAction(true);
            },
            showFilter(event){
                const _this = event.target;
                
                const $filterBtn = _this.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                const $filterWrap = document.querySelector("#filterWrap");
                const $filterInput = $filterWrap.querySelectorAll(".filter_input");

                const hasShowClass = $filterWrap.classList.contains("show");
                const hasActiveClass = _this.classList.contains("active");
                
                for(var i = 0 ; i < $filterBtn.length ; i ++){
                    $filterBtn[i].classList.remove("active");
                }
                _this.classList.add("active");
                if(hasShowClass){
                    if(hasActiveClass){
                        $filterWrap.classList.remove("show");
                        _this.classList.remove("active");
                    }
                }else{
                    $filterWrap.classList.add("show");
                }
                let activeIndex = Array.from($filterBtn).findIndex(element => element.classList.contains('active'));
                // console.log(activeIndex);
                // input 
                for(var j = 0 ; j < $filterInput.length ; j ++){
                    $filterInput[j].querySelector("div").classList.remove("active");
                }
                // console.log($filterInput[activeIndex]);
                if(activeIndex > -1){
                    $filterInput[activeIndex].querySelector("div").classList.add("active");
                }
                
                
            },
            setActive(event) {
                const _this = event.target;
                const $filterDiv = _this.parentNode.parentNode.parentNode.querySelectorAll("div");
                const $filterIcon = _this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                
                for(var i = 0 ; i < $filterDiv.length ; i ++){
                    $filterDiv[i].classList.remove("active");
                }
                _this.parentNode.classList.add("active");
                
                // filter icon 
                let activeIndex = Array.from($filterDiv).findIndex(element => element.classList.contains('active'));
                
                for(var j = 0 ; j < $filterIcon.length ; j ++){
                    $filterIcon[j].classList.remove("active");
                }
                $filterIcon[activeIndex].classList.add("active");

            },
            async addFilter(event){
                console.log("addFilter");
                
                const _this = event.target;
                const $thTag = _this.parentNode.parentNode;
                const $addBtn = $thTag.querySelector("button"); 
                const $input = $thTag.querySelector("input");
                // input 글자수
                const trimVal = $input.value.trim();

                // 컬럼 이름 가져오기
                const keyNameArr = _this.parentNode.parentNode.parentNode.parentNode.querySelector("tr:first-child").querySelectorAll(".btn_filter"); 
                let activeIndex = Array.from(keyNameArr).findIndex(element => element.classList.contains('active'));
                const keyName = String(keyNameArr[activeIndex].parentNode.textContent);                 


                const dataKeyArr = _this.parentNode.parentNode.parentNode.querySelectorAll('.input_box');
                const dataKeyindex = Array.from(dataKeyArr).findIndex(element => element.classList.contains('active'))
                const keyId = String(dataKeyArr[dataKeyindex].getAttribute('data-id')) 

                const obj = {};
                console.log();
                // console.log(this.getAttribute('data-index'))
                // console.log($input);

                window.filterData.taget = _this.getAttribute('data-index')

                if(0 < trimVal.length){
                    obj["keyId"] = keyId;
                    obj["value"] = trimVal;
                    obj["keyName"] = keyName;
                    $input.value = "";
                    this.filterList.push(obj);
                    this.$refs.filterAllBtn.style.display = "block";
                    $addBtn.parentNode.classList.add("disable");
                    $addBtn.previousElementSibling.disabled = true;
                    this.filterAction();
                }
                
            },
            filterAction(remove){
                const vm = this;
                // 필터 리스트
                const _filterLsit =  vm.filterList;
                // 테이블 리스트
                let _tableList = vm.rPA;
                if(vm.isfilter){
                    vm.filterDataList.origin = _tableList;
                    vm.isfilter = false;
                }
                
                
                if(_filterLsit.length > 0){
                    
                    const lodash = require('lodash');
                    if(remove){
                        // 필터 삭제
                        for( var j = 0 ; j < _filterLsit.length; j++){

                            const searchString = _filterLsit[j].value;

                            const regexPattern = new RegExp(searchString);

                            // _tableList > 검색 1 리트스 > 검색 2 리스트

                            const keyid = _filterLsit[j].keyId;
                            const filteredData = lodash.filter(vm.filterDataList.origin, (item) => regexPattern.test(item[keyid]));
                            vm.rPA = filteredData;
                        }
                        
                    }else{
                        // 필터 추가
                        for( var i = 0 ; i < _filterLsit.length; i++){

                            const searchString = _filterLsit[i].value;

                            const regexPattern = new RegExp(searchString);

                            // _tableList > 검색 1 리트스 > 검색 2 리스트
                            
                            const keyid = _filterLsit[i].keyId;
                            const filteredData = lodash.filter(_tableList, (item) => {
                                return regexPattern.test(item[keyid]);
                            });
                            vm.rPA = filteredData;
                        }
                    }
                    console.log("필터 동작 시작");
                }else{
                    vm.rPA = vm.filterDataList.origin;
                    
                }
                vm.displayActivityLogInModal(vm.rPA);
            },
            //=========
             // -------- DATE PICKER ----
             datePicToggleModal(){
                const vm = this;
                const _display = vm.$refs.calenderBoxModal.style.display;
                const _startDateBox = vm.$refs.startDateBoxModal.style.display;
                // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
                // console.log(_display);
                console.log(vm.$refs.startDateBoxModal);
                if(_display == 'block'){
                    vm.$refs.datePicToggleModal.nextElementSibling.style.display = 'none';
                    vm.picColorModal.startStatus = false;
                    vm.picColorModal.endStatus = false;

                }else if(_display == 'none'){   
                    vm.$refs.datePicToggleModal.nextElementSibling.style.display = 'block';
                    console.log(_startDateBox);
                    if(_startDateBox == 'block'){
                        vm.picColorModal.startStatus = true;
                        vm.picColorModal.endStatus = false;
                    }else if( _startDateBox == 'none'){
                        vm.picColorModal.startStatus = false;
                        vm.picColorModal.endStatus = true;
                    }
                }
                
                
                
            },

            memAccordion(event){
                const _this = event.target;
                const hasShowClass = _this.classList.contains('show_list');
                const $listWrap = _this.parentNode.nextElementSibling;
                if(hasShowClass){
                    console.log("hasClass");
                    gsap.to($listWrap , {
                        onStart: () => {
                            _this.classList.remove("show_list");
                        },
                        height: 0,
                        duration: .3
                    });
                }else{
                    console.log("noClass");
                    gsap.to($listWrap , {
                        onStart: () => {
                            _this.classList.add("show_list");
                        },
                        height: 'auto',
                        duration: .3
                    });
                }
            },
            async getDriListModal(){
                // 팝업 조회
                const vm = this;
                // this.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', this.picStartDate, this.picEndDate);
                // 켈린더 display처리
                const $calenderBox = vm.$refs.calenderBoxModal;
                const $startDateBox = vm.$refs.startDateBoxModal;
                const $endDateBox = vm.$refs.endDateBoxModal;
             
                
                $calenderBox.style.display = 'none';
                $startDateBox.style.display = 'block';
                $endDateBox.style.display = 'none';
           
                
                vm.picColorModal.startStatus = false;
                vm.picColorModal.endStatus = false;

                console.log(vm.picStartDate);
                console.log(vm.picEndDate);
                
                // PaginationUi 최기화
                this.totalItems = 0;
                this.itemsPerPage = 0;
                this.currentPage = 1;
                
                this.callHistory(1);

                // vm.drivList = await vm.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', '2022-12-10', '2023-11-10');
                
                // console.log(vm.drivList);
                
            },
            onDatePicModal(flag){
                if(flag == 'S'){   
                    if(window.selectDate.startDate !== null ){
                        this.picStartDateModal = window.selectDate.startDate;
                    }
                    
                }else if(flag == 'E'){
                    if(window.selectDate.picEndDate !== null ){
                        this.picEndDateModal = window.selectDate.endDate;
                    }
                }
            },
            pickerNextModal(){
                this.$refs.startDateBoxModal.style.display = 'none';
                this.$refs.endDateBoxModal.style.display = 'block';

                this.picColorModal.startStatus = false;
                this.picColorModal.endStatus = true;
                console.log(this.picColorModal.endStatus);
                console.log(this.picColorModal.target);
                
            },
            pickerPrevModal(){
                this.$refs.startDateBoxModal.style.display = 'block';
                this.$refs.endDateBoxModal.style.display = 'none';

                this.picColorModal.startStatus = true;
                this.picColorModal.endStatus = false;
            },
            //Anomaly History 
             detailModal(){
                const $modal = this.$refs.modalWrap;
                const $Anomaly = this.$refs.modalFilter;
                
                this.picStartDateModal = this.picStartDate;
                this.picEndDateModal = this.picEndDate;
                
                this.callHistory(1);    
                
                $modal.style.display = 'block';
                $Anomaly.style.display = 'block';


                
                
            },
            async callHistory(no){
                if(no === null || no === undefined || no === ''){
                    no = 1;
                }
                try {
                    const r = await apiCallVphm01.post("/inf_detail/history", {
                        "edgeid": this.edgeSelectName,
                        "start": this.picStartDateModal,
                        "end": this.picEndDateModal,
                        "page_no": no
                    })
                    console.log("3. /inf_detail/history - 차량 1대에 대해서 조회 희망하는 기간만큼의 Inference 결과 조회");
                    console.log(this.totalItems);

                    console.log(r.data.total);
                    

                    if(r.data.result === "success"){
                        this.rpaSliced = r.data;

                        this.itemsPerPage = r.data.size;
                        this.totalItems = r.data.total;
                        

                    }else if(r.data.result === "fail"){
                        this.rpaSliced = {
                            history : [
                                
                            ] 
                        };
                        this.totalItems = 0;
                        this.itemsPerPage = 0;
                    }

                } catch(error) {
                    console.error('Fail: /inf_detail/history');
                }
            },
            // -------pagination
            onPageChanged(page) {
                this.currentPage = page;
                this.callHistory(this.currentPage);
            },
        }
    }
</script>
<style scoped lang="scss">
    .item_info{
        .item_title{
            .btn_fold{
                right: 90px;
            }
        }    
    }
    
    .moa_dete{
        .status_box{
            height: 144px;
            margin: 20px 0;
            &>div{
                height: 100%;
                float: left;
                position: relative;
                &.status{
                    // width: 303px;
                    width: 412px;
                    button{
                        width: 64px;
                        height: 40px;
                        box-sizing: border-box;
                        border: 1px solid $disable02Color;
                        border-radius: 10px;
                        position: absolute;
                        top: 15px;
                        right: 15px
                    }
                    &:hover{
                        &.normal{
                            button{
                                background-color: $okColor;
                                color: #fff;
                                border: none; 
                            }
                        }
                        &.warning{
                            button{
                                background-color: #EABE6E;
                                color: #fff;
                                border: none; 
                            }
                        }
                        &.danger{
                            button{
                                background-color: $failedColor;
                                color: #fff;
                                border: none; 
                            }
                        }
                    }
                    div{
                        box-sizing: border-box;
                        // padding: 40px 0 0 20px;
                        padding: 40px 0 0 40px;
                        span{
                            color: #6E7380;
                            display: block;
                        }
                        strong{
                            display: block;
                            font-size: 30px;
                            line-height: 52px;
                        }
                    }
                    &.normal{
                        strong{
                            color: $okColor;
                        }   
                    }
                    &.warning{
                        strong{
                            color: #EABE6E;
                        }
                    }
                    &.danger{
                        strong{
                            color: $failedColor;
                        }
                    }
                }
                &.time_distance{
                    // width: 630px;
                    width: 412px;
                    margin: 0 20px;
                    box-sizing: border-box;
                    padding: 30px 0;
                    .float_box{
                        &>div{
                            width: 50%;
                            float: left;
                            box-sizing: border-box;
                            padding: 10px 0 0 40px;
                            span{
                                color: #6E7380;
                                display: block;
                            }
                            strong{
                                font-size: 30px;
                                line-height: 52px;
                                font-family: $montserrat;
                                font-weight: 500;
                            }
                            sup{
                                font-family: $montserrat;
                                display: inline-block;
                                margin-left: 5px;
                            }
                            
                            // &:last-child{
                            //     border-left: 1px solid $line02Color;
                            // }
                        }
                    }
                }
                &.update{
                    // width: 303px;
                    width: 412px;
                    box-sizing: border-box;
                    // padding: 20px 0 10px 15px;
                    padding: 20px 0 10px 40px;
                    strong{
                        font-weight: $bold;
                    }
                    p{
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
                    .img_box{
                        width: 14px;
                        height: 14px;
                        display: inline-block;
                        position: relative;
                        top: 3px;
                        margin-right: 5px;
                        background-image: url($baseURL+'/common/refresh_icon.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                    span{
                        color: $disable01Color;
                        font-size: 13px;
                    }
                    .toggle_box{
                        position: absolute;
                        top: 15px;
                        right: 20px;
                    }
                }   
            }
        }
        .result_box{
            width: 100%;
            height: 308px;
            .title_box{
                position: relative;
                padding-top: 20px;
                padding-left: 20px;
                padding-bottom: 15px;
                strong{
                    font-weight: $bold;
                }
            }
            .mando_table{
                td{
                    padding: 25px 20px;
                }
                .result{
                    font-size: 25px;
                    font-weight: 700;
                    font-family: $montserrat;
                    &.ok{
                        color: $okColor;
                    }
                    &.ng{
                        color: $failedColor;
                    }
                }
            }
            
        }
        .sub_data_box{
            margin-top: 20px;
            &>div{
                width: 304px;
                height: 308px;
                float: left;
                margin-right: 20px;
                &:last-child{
                    margin-right: 0;
                }
                .title_box{
                    position: relative;
                    padding-top: 20px;
                    padding-left: 20px;
                    padding-bottom: 15px;
                    strong{
                        font-weight: $bold;
                    }
                } 
                &.anomaly_status{
                    .float_box{
                        width: 100%;
                        height: 255px;
                        box-sizing: border-box;
                        padding: 0 10px 10px 10px;
                        &>div{
                            width: 137px;
                            height: 119px;
                            float: left;
                            text-align: center;
                            background-color: $line01Color;
                            box-sizing: border-box;
                            border-radius: 10px;
                            color: $whiteColor;
                            box-sizing: border-box;
                            padding-top: 50px;
                            cursor: pointer;
                            &:nth-child(1){
                                margin-right: 10px;
                                margin-bottom: 10px;
                            }
                            &:nth-child(2){
                                margin-bottom: 10px;
                            }
                            &:nth-child(3){
                                margin-right: 10px;
                            }
                            &.normal{
                                &.active{
                                    background-color: #00B96B;
                                }
                            }
                            &.mild{
                                &.active{
                                    background-color: #4791FF;
                                }
                            }
                            &.caution{
                                &.active{
                                    background-color: #EABE6E;
                                }
                            }
                            &.servere{
                                &.active{
                                    background-color: #F4514D;
                                }
                            }
                        }
                    }
                }  
                &.anomaly_count{
                    ul{
                        width: 100%;
                        height: 255px;
                        li{
                            width: 100%;
                            height: 63px;
                            border-bottom: 1px solid $line01Color;
                            box-sizing: border-box;
                            padding: 20px 15px;
                            vertical-align: middle;
                            position: relative;
                            .icon{
                                width: 7px;
                                height: 7px;
                                display: inline-block;
                                border-radius: 7px;
                                vertical-align: middle;
                            }
                            &>span{
                                display: inline-block;
                                border-radius: 7px;
                                vertical-align: middle;
                                margin-left: 10px;
                            }
                            .txt_box{
                                display: inline-block;
                                position: absolute;
                                top: 20px;
                                right: 30px;
                                strong{
                                    font-size: 20px;
                                    font-family: $montserrat;
                                    font-weight: 500;
                                }
                                span{
                                    display: inline-block;
                                    margin-left: 4px;
                                }
                            }
                            &:nth-child(1){
                                .icon{
                                    background-color: #00B96B;
                                    box-shadow: 0px 0px 10px #00B96B;
                                }
                            }
                            &:nth-child(2){
                                .icon{
                                    background-color: #4791FF;
                                    box-shadow: 0px 0px 10px #4791FF;
                                }
                            }
                            &:nth-child(3){
                                .icon{
                                    background-color: #EABE6E;
                                    box-shadow: 0px 0px 10px #EABE6E;
                                }
                            }
                            &:nth-child(4){
                                .icon{
                                    background-color: #F4514D;
                                    box-shadow: 0px 0px 10px #F4514D;
                                }
                            }
                        }
                    }
                }
                &.anomaly_history{
                    position: relative;
                    .detail_btn{
                        position: absolute;
                        top: 7px;
                        right: 15px;
                        padding:14px 20px 10px 10px ;
                        background-image: url($baseURL + "common/external_link.svg");
                        background-repeat: no-repeat;
                        background-position-x: right;
                        background-position-y: center;
                    }
                    .mando_table_wrap{
                        height: 255px;
                        .item.active{
                            background-color: #EDFBFF;
                        }

                    }
                }
                &.dtc_map{
                    overflow: hidden;
                    .map{
                        width: 100%;
                        height: 255px;
                        background-color: #ff0;
                    }
                }
            }
        }
    }

#modalWrap{
    .float_box{
        height: auto;
    }
    .filter_modal{
        width: 1200px;
        height: 690px;
        padding: 25px;
        box-sizing: border-box;
        #recentFilter{
            display: none;
            width: 100%;
            min-height: 70px;
            border: 1px solid $line01Color;
            border-radius: 10px;
            box-sizing: border-box;
            
            .filter_list_box{
                width: 100%;
                height: 100%;
                position: relative;
                .float_box{
                    width: 100%;
                    box-sizing: border-box;
                    position: relative;
                    margin-top: 0;
                    padding: 5px 10px;
                    li{
                        display: inline-block;
                        background-color: #EDFBFF;
                        border: 1px solid $mainColor;
                        border-radius: 10px;
                        padding: 10px 30px 10px 15px;
                        position: relative;
                        margin-right: 10px;
                        margin-top: 10px;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                    .btn_close_item{
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        top: 9px;
                        right: 4px;
                        &::before{
                            display: block;
                            content: "";
                            width: 1.5px;
                            height: 10px;
                            background-color: $placeHolderColor;                    
                            position: absolute;
                            top: 5px;
                            left: 8px;
                            transform: rotate(45deg);
                        }
                        &::after{
                            display: block;
                            content: "";
                            width: 1.5px;
                            height: 10px;
                            background-color: $placeHolderColor;                    
                            position: absolute;
                            top: 5px;
                            left: 8px;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
            
            
        }
        .list_wrap{
            width: 100%;
            height: 570px;

            //스크롤 기능 추가
            // overflow: auto;
            .table_list{
                box-shadow: none;
                border: 1px solid $line01Color;
                .filter_box{
                    padding :10px 20px;
                }
                .date_filter{
                    padding-left: 20px;
                    top: 10px; 
                    right: 20px;
                    &:after{
                        display:none;
                    }    
                }
                .total_num{
                    box-sizing: border-box;
                    padding: 20px;
                    padding-bottom: 0;
                    font-size: $fontSub;
                    span{
                        color: $mainColor;
                        font-weight: $bold;
                        font-size: $fontSub;
                    }
                }
            }
            .mando_table_wrap{
                .mando_table{
                    position: relative;
                }   
            }    
        }
    }
    .mando_table_wrap{
        height: 450px;
    }
    .btn_filter{
        display: none;
    }
}
</style>