<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>My 프로젝트</span>
                    <span>프로젝트 워크스페이스</span>
                    <span>데이터 목록</span>
                    <span class="active">데이터 상세</span>
                </div>
                <h2>데이터셋 상세</h2>
            </div> -->
            <BreadcrumbLayout pageId="monTASK_detailData" />
            <div class="item_info">
                <div class="item_title">
                    <strong>[PROJECT] {{prjData.projectName}}</strong>
                    <div class="item_info_btn_box">
                        <button type="button" class="btn_fold" v-bind:style="prjInfoToggle ? 'display:none': ''" @click="clickTogglePrj()">접기</button>
                        <button type="button" class="btn_fold active" v-bind:style="!prjInfoToggle ? 'display:none': ''" @click="clickTogglePrj()">펼치기</button>
                        <button type="button" class="btn_evt_group" @click="evtBox($event)"></button>
                        <ul class="evt_btn_box radiusbox">
                            <li class="download_item">
                                <button type="button">데이터 다운로드</button>
                            </li>
                            <li class="share_item">
                                <button type="button" @click="clipCopy">공유하기</button>
                            </li>
                            <li class="retouch_item" v-if="managePrjRole">
                                <button type="button" @click="goModePrj">Project 수정</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item_table radiusbox" v-bind:style="prjInfoToggle ? 'display:none': ''">
                    <table>
                        <thead>
                            <tr>
                                <th>Project Name</th>
                                <th>AWS ID</th>
                                <th>PM</th>
                                <th>Project Type</th>
                                <th>Unit</th>
                                <th>Last Activity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{prjData.projectName}}</td>
                                <td>{{prjData.awsId}}</td>
                                <td>{{prjData.pm}}</td>
                                <td>{{prjData.projectType}}</td>
                                <td>{{prjData.teamName}}</td>
                                <td>{{prjData.lastVisit}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="item_desc">
                        <div class="desc_title">Description</div>
                        <div class="desc_txt">{{ prjData.description }}</div>
                    </div>
                </div>
                <div class="group_breadcrumb">
                    <span>Vehicle</span>
                    <span>HKMC</span>
                    <span>JK1</span>
                    <span class="active">{{prjData.edgeDeviceId}}</span>
                </div>
                <div class="float_box">
                    <div class="driving_record radiusbox">
                        <div class="title_box">
                            <strong>주행 기록</strong>
                            <button type="button" v-if="manageDrRole" @click="goAddDriving()" class="mcbtn">추가</button>
                        </div>
                        <div class="filter_box">
                            <div class="date_filter">
                                <div class="date_period">
                                    <div class="date_box">
                                        <span style="display:inline-block ; padding : 0 10px 0 10px;" :style="picStartTxtStyle" ref="picStartTxt">{{ picStartDate }}</span> ~ <span style="display:inline-block ; padding: 0 0 0 10px;" :style="picEndTxtStyle" ref="picEndTxt">{{picEndDate}}</span>
                                    </div>
                                    <button type="button" ref="datePicToggle" @click="datePicToggle()"></button>
                                    <div class="calender_box radiusbox" ref="calenderBox" style="display:none">
                                        <div class="start_date date_box" ref="startDateBox" @click="onDatePic('S')" style="display:block">
                                            <VCalendarUi :dateInterface="'start'" :setDate="'60'"/>
                                            <button type="button" @click="pickerNext()">다음</button>
                                        </div>
                                        <div class="end_date date_box" ref="endDateBox" @click="onDatePic('E')">
                                            <VCalendarUi :dateInterface="'end'" :minDate="picStartDate"/>
                                            <button type="button" @click="pickerPrev()">이전</button>
                                            <button type="button" class="mcbtn" @click="getDriList()">검색</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="driving_record_list" ref="drivReclistBox" style="display: block">
                            <table>
                                <thead>
                                    <tr>
                                        <th>주행 기록 시간</th>
                                        <th>등록자</th>
                                    </tr>
                                </thead>
                                <tbody ref="drivListWrap">
                                    <tr class="id_box" v-for="(item, index) in drivList" :key="index" :data-id="item.id" >
                                        <td @click="drivRecActive($event, item.id, index, item.start_time, item.end_time)">
                                            <div>
                                                <span>시작시간</span>
                                                {{item.start_time}}
                                            </div>
                                            <div>
                                                <span>종료시간</span>
                                                {{item.end_time}}
                                            </div>
                                            <div class="description_box">
                                                <strong>Description</strong>
                                                <p>{{item.description}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="name_box">{{item.creatorName}}</span>
                                            <button type="button" v-if="getManageDrRole(item)" class="btn_evt_group" @click="btnEvtGroup($event)"></button>
                                            <!-- <button type="button" class="btn_evt_group" @click="btnEvtGroup($event)"></button> -->
                                            <ul class="evt_btn_box radiusbox">
                                                <!-- <li class="download_item">
                                                    <button type="button">데이터 다운로드</button>
                                                </li>
                                                <li class="share_item">
                                                    <button type="button">공유하기</button>
                                                </li> -->
                                                <li class="retouch_item">
                                                    <button type="button" @click="goModPage(item.id)">수정</button>
                                                </li>
                                                <li class="delete_item">
                                                    <button type="button" @click="deleteDrivBtn($event ,item.id)">삭제</button>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <!-- 
                                    <tr class="id_box">
                                        <td>
                                            <div>
                                                <span>시작시간</span>
                                                2023-05-23 12:05
                                            </div>
                                            <div>
                                                <span>종료시간</span>
                                                2023-05-23 12:05
                                            </div>
                                            <div class="description_box">
                                                <strong>Description</strong>
                                                <p> 영역입니다. asdadasdasdasdjasdlkasd;asda;sdl;a;dl;asdl;akl;dl;aksdlkasdakd;akd;akd;lakd;akd;lka;sdka;lsd</p>
                                            </div>
                                        </td>
                                        <td>
                                            김만도
                                            <button type="button" class="btn_evt_group"></button>
                                            <ul class="evt_btn_box radiusbox">
                                                <li class="download_item">
                                                    <button type="button">데이터 다운로드</button>
                                                </li>
                                                <li class="share_item">
                                                    <button type="button">공유하기</button>
                                                </li>
                                                <li class="retouch_item">
                                                    <button type="button">수정</button>
                                                </li>
                                                <li class="delete_item">
                                                    <button type="button">삭제</button>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                     -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="data_set_mon" ref="dataSetBox">
                        <div class="none_data radiusbox" style="display:block;">
                            <div class="text_box">
                                <span>
                                    <!-- 검색하고자 하는 날짜를 선택한 후 <br>검색 버튼을 클릭해주세요. -->
                                    상세 주행기록시간을 선택하시면 <br>원하시는 기록의 대시보드를 확인할 수 있습니다.
                                </span>    
                            </div>
                        </div>
                        <div class="data_set" style="display:none">
                            <div class="time_controller radiusbox">
                                <div class="controller_box">
                                    <button type="button" class="start_point btn_point" ref="fPointBtn" @click="rangePoint($event, 'fPoint')"></button>
                                    <div class="btn_wrap" ref="controlBtnBox">
                                        <button type="button" class="first_point" @click="controlBtn($event,'fTime')"></button>
                                        <button type="button" class="back_seconds" @click="controlBtn($event,'back3')"></button>
                                        <button type="button" class="play_pause pause" ref="playPauseBtn" @click="controlBtn($event,'playPause')"></button>
                                        <button type="button" class="forward_seconds" @click="controlBtn($event, 'forward3')"></button>
                                        <button type="button" class="end_point" @click="controlBtn($event, 'eTime')"></button>
                                    </div>
                                    <button type="button" class="end_point btn_point" ref="ePointBtn" @click="rangePoint($event,'ePoint')"></button>
                                </div>
                                <button type="button" class="add_select_section" ref="selectBtn" @click="addDrivRecBtnModal()" style="display:none">선택구간 추가</button>
                                <div class="start_end" ref="selecTimeBox">
                                    <div class="start_point_time">
                                        <div>시작 시간</div> <span>00:00:00:00</span>
                                    </div>
                                    <div class="end_point_time">
                                        <div>종료 시간</div> <span>00:00:00:00</span>
                                    </div>
                                </div>
                                <div class="range_box">
                                    <div class="time_move_box" ref="timeInputBox">
                                        <strong>00:00:00:00</strong>
                                        <div class="time_input_box" :style="{ opacity: isFocused ? '1' : '0' }">
                                            <input type="text" name="" id="hh" maxlength="2" :value="timeSet.HH" @focus="handleFocus" @blur="handleBlur" @keyup.enter="handleBlur">:<input type="text" name="" id="mm" maxlength="2" :value="timeSet.MM" @focus="handleFocus" @blur="handleBlur" @keyup.enter="handleBlur">:<input type="text" name="" id="ss" maxlength="2" :value="timeSet.SS" @focus="handleFocus" @blur="handleBlur" @keyup.enter="handleBlur">:<input type="text" name="" id="ms" maxlength="2" :value="timeSet.ss" @focus="handleFocus" @blur="handleBlur" @keyup.enter="handleBlur">
                                        </div>
                                    </div>
                                    <div class="range_item" ref="rangeItem">
                                        <input id="exIn" type="range" min="0" :max=timeSet.max value="0" class="slider">
                                        <div class="flow_bg"></div>
                                        <div class="range_bg"></div>
                                        <div class="pin_point f_point" style="display:none;"></div>
                                        <div class="pin_point e_point" style="display:none;"></div>
                                        <div class="time_mark" v-for="(item, index) in recList" :key="index" :style="{display: 'block',  width: ((timeStringToMilliseconds(item.end_time) - timeStringToMilliseconds(item.start_time)) / timeSet.max) * 100 + '%', left: (timeStringToMilliseconds(item.start_time) / timeSet.max) * 100 + '%' }">
                                            <div :style="{backgroundColor: item.record_color}"></div>
                                            <div class="mark_index" :style="{backgroundColor: item.record_color}">{{ item.no }}</div>
                                        </div>
                                        <!--
                                        <div class="time_mark" style="display:none;">
                                            <div style="background-color: #000;"></div>
                                            <div class="mark_index" style="background-color: #000;">1</div>
                                        </div>
                                        -->
                                    </div>
                                    <div class="end_time" ref="endTime">
                                        <strong>00:00:00:00</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="float_box">
                                <div class="radiusbox list_box">
                                    <div class="title_box">
                                        <strong>선택 구간</strong>
                                    </div>
                                    <div class="mando_table_wrap">
                                        <table class="mando_table select_section">
                                            <colgroup>
                                                <col style="width: 55px;">
                                                <col style="width: 100px;">
                                                <col style="width: 100px;">
                                                <col style="width: 50px;">
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Start time</th>
                                                    <th>End time</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody ref="recListWrap">
                                                <tr class="" v-for="(item, index) in recList" :key="index" :data-st="item.start_time">
                                                    
                                                    <td @click="selectStartTime($event)" :style="{ color: item.record_color }">
                                                        <div class="list_item_icon" :style="{ borderColor: item.record_color }">
                                                            <div :style="{ borderLeftColor: item.record_color }"></div>
                                                        </div>
                                                        {{ item.no }}
                                                    </td>
                                                    <td @click="selectStartTime($event)">
                                                        {{ item.start_time }}
                                                    </td>
                                                    <td @click="selectStartTime($event)">
                                                        {{ item.end_time }}
                                                    </td>
                                                    <td>
                                                        <button type="button" class="btn_evt_group" @click="btnEvtGroup($event)"></button>
                                                        <ul class="evt_btn_box radiusbox">
                                                            <li class="copy_item" @click="copyTextTime($event ,item.start_time, item.end_time)">
                                                                <button type="button">키 복사</button>
                                                            </li>
                                                            <!-- <li class="retouch_item">
                                                                <button type="button">수정</button>
                                                            </li> -->
                                                            <li class="delete_item">
                                                                <button type="button" @click="deleteDriRec($event, item.id, item.driving_record_id)">삭제</button>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="radiusbox" style="width: 630px;">
                                    <div class="title_box">
                                        <strong>데이터 모니터링 차트</strong>
                                        <button type="button" @click="showModalMember()" class="setting_icon"></button>
                                    </div>
                                    <div class="chart_box" >
                                        <canvas width="580px" height="250px" ref="dataChart" ></canvas>
                                        <div class="chart_time">
                                            <div ref="chartBar" style="width: 0%;"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="radiusbox list_box">
                                    <div class="title_box">
                                        <strong>데이터 모니터링 통계</strong>
                                    </div>
                                    <div class="mando_table_wrap">
                                        <table class="mando_table chart_item_data">
                                            <thead>
                                                <tr>
                                                    <th>Data Name</th>
                                                    <th>Min</th>
                                                    <th>Max</th>
                                                    <th>Med</th>
                                                    <th>Deviation</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr  v-for="(item, index) in dataMoniterList" :key="index" data-index="index">
                                                    <td>
                                                        <div class="list_item_icon" :style="{ backgroundColor: chartlabelColor.datasets[index].borderColor }"></div>
                                                        {{item.measure_name}}
                                                    </td>
                                                    <td>
                                                        {{item.min}}
                                                    </td>
                                                    <td>
                                                        {{item.max}}
                                                    </td>
                                                    <td>
                                                        {{item.med}}
                                                    </td>
                                                    <td>
                                                        {{item.stddev}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="radiusbox list_box task_list">
                                    <div class="title_box">
                                        <strong>모니터링 TASK 목록</strong>
                                    </div>
                                    <div class="mando_table_wrap">
                                        <table class="mando_table">
                                            <colgroup>
                                                <col style="width: 55px;">
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Task Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr  v-for="(item, index) in motTaskList" :key="index" data-index="index">
                                                    <td>
                                                        {{index + 1}}
                                                    </td>
                                                    <td>
                                                        {{item.task_name}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="radiusbox">
                                    <div class="title_box">
                                        <strong>GPS 지도</strong>
                                    </div>
                                    <div class="map_box">
                                        <naver-map id="map" style="width: 100%; height: 250px" :mapOptions="mapOptions" :initLayers="initLayers" :key="gpsCenterKey">
                                            <naver-polyline v-if="gps.length > 0" :path="gps" :key="gpsKey" />
                                        </naver-map>
                                    </div>
                                </div>

                                <!-- <div class="radiusbox">
                                    <div class="title_box">
                                        <strong>주행 영상정보</strong>
                                    </div>
                                    <div class="video_box">

                                    </div>
                                </div> -->

                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div id="modalWrap" ref="modalWrap" style="display: none;">
        <!-- error 알림 -->
        <div class="radiusbox modal_contents message_alert message_modal" ref="modalAlert" style="display: none">
            <div class="message_box" v-html="alertMessege"> </div>
            <button type="button" class="btn_check mcbtn" @click="closeModal()">확인</button>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
        <!-- 선택구간 추가 알림 -->
        <div class="radiusbox modal_contents message_check message_modal" ref="modalAddAlert" style="display: none">
            <div class="message_box">
                선택 구간을 추가하시겠습니까?
            </div>
            <button type="button" class="btn_close" @click="closeModal()"></button>
            <button type="button" class="btn_check mcbtn" @click="addDrivRecBtn()">확인</button>
        </div>
        <!-- 데이터 카달로그 검색 -->
        <div class="radiusbox modal_contents add_item_modal add_member" ref="modalMomber" style="display : none;">
            <strong class="modal_title">
                데이터 카탈로그 검색
            </strong>
            <div class="filter_box">
                <input type="text" name="" id="" placeholder="Vehicle" ref="searchInput">
                <button type="button" class="btn_search" @click="listSearch"></button>
            </div>
            <div class="float_box table_list">
                <div class="select_box mando_table_wrap">
                    <table class="mando_table">
                        <colgroup>
                            <col style="width: 20%">
                            <col style="width: 20%">
                            <col style="width: 60%">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <div class="all_chk">
                                        <input type="checkbox" id="allChkMember">
                                        <!-- <label for="allChkMember" style="visibility: hidden;"></label> -->
                                        <span>No.</span>  
                                    </div>
                                </th>
                                <th>
                                    Domain
                                </th>
                                <th>
                                    Data Name
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in catalogList" :key="index" :data-index="index">
                                <td>
                                    <div class="chk_item">
                                        <input type="checkbox" v-model="catalogListSelectedItems" :disabled="catalogListSelectedItems.length >= 5 && !catalogListSelectedItems.includes(index)" :id="'catalogItem' + index " :value="index">
                                        <label :for="'catalogItem' + index" ></label>
                                        <span>{{ index + 1 }}</span>  
                                    </div>
                                </td>
                                <td>{{item.vehicle_name}}</td>
                                <td>{{item.measure_name}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="choose_box">
                    <div class="btn_wrap">
                        <button type="button" class="btn_add" @click="addCatalog()">추가</button>
                        <button type="button" class="btn_add_del" @click="delCatalog()">삭제</button>
                    </div>
                </div>
                <div class="select_box">
                    <div class="table_title">
                        <strong>선택 항목</strong>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <colgroup>
                                <col style="width: 20%">
                                <col style="width: 20%">
                                <col style="width: 60%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="all_chk">
                                            
                                            <!-- <label for="allChk"></label> -->
                                            <span>No.</span>  
                                        </div>
                                    </th>
                                    <th>
                                        Domain
                                    </th>
                                    <th>
                                        Data Name
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(aItem, index) in activeCatalog" :key="index" :data-index="index">
                                    <td>
                                        <div class="chk_item">
                                            <input type="checkbox"  :id="'catalogActiveItem' + index " v-model="activeCatalogSelectedItems" :value="index">
                                            <label :for="'catalogActiveItem' + index" ></label>
                                            <span>{{ index + 1 }}</span>  
                                        </div>
                                    </td>
                                    <td>{{aItem.vehicle_name}}</td>
                                    <td>{{aItem.measure_name}}</td>
                                </tr>
                                <tr v-if="activeCatalog.length === 0" class="hover_none">
                                    <td colspan="3">
                                        <div class="none_itme">
                                            <div class="massage">
                                                데이터 카달로그를 추가 해 주세요.
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="max_info"> 5개 까지 선택 가능합니다. </div>
                    </div>
                </div>
            </div>
            <div class="check_wrap">
                <button type="button" class="btn_check mcbtn"  @click="addCatalogChart">완료</button>
            </div>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
    </div>
    <div id="lodingWrap" style="display:none" ref="lodingWrap">
        <div class="loading-container">
            <div class="loding-animation-holder">
                <div class="loading-animator"></div>
                <div class="loading-animator"></div>
                <div class="loading-animator"></div>
                <div class="loading-animator"></div>
                <div class="middle-circle"></div>
            </div>
        </div>
    </div> 
    <FooterLayout/>
</template>
<script setup>

    // import { ref } from "vue";
    // import { NaverMap, NaverPolyline } from "vue3-naver-maps";

    //path 
    // const path = ref([
    //     { lat: 36.4145180, lng: 129.37381 },
    //     { lat: 36.414555, lng: 129.373835 },
    //     { lat: 36.414518, lng: 129.37381 },
    // ]);

    // const map = ref();
    // const mapOptions = {
    //     latitude: 36.4145180, // 지도 중앙 위도
    //     longitude: 129.37381, // 지도 중앙 경도
    //     zoom: 17,
    //     zoomControl: false,
    //     zoomControlOptions: { position: "TOP_RIGHT" },
    // };
    // const initLayers = [
    //     "BACKGROUND",
    //     "BACKGROUND_DETAIL",
    //     "POI_KOREAN",
    //     "TRANSIT",
    //     "ENGLISH",
    // ];

    // const onLoadMap = (mapObject) => {
        
    //     map.value = mapObject;
    // };


</script>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import VCalendarUi from '@/components/VCalendarUi.vue';
    import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";

    import { NaverMap, NaverPolyline } from "vue3-naver-maps";
    import common from '@/assets/js/common'

    import Chart from 'chart.js/auto';
    
    
    export default {
        name: 'DetailDataSetView',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            VCalendarUi,
            BreadcrumbLayout,
        },
        data(){
            const userData = ({});

            const picStartDate = null;
            const picEndDate = null;
            
            return{
                userData,

                prjInfoToggle: false,
                prjData: {
                    projectName:'No Data Error',
                    awsId:'No Data Error',
                    pm:'No Data Error',
                    projectType:'No Data Error',
                    lastVisit:'No Data Error',
                    edgeDeviceId:'No Data Error',
                    projectId: "No Data Error",
                    id: "No Data Error",
                    description: "No Data Error",
                },
                urlParam:{
                    prjId: '',
                    edgeId: ''
                },
                // -- VCalendarUi / .date_period
                picStartDate,
                picEndDate,
                picColor: {
                    startStatus: false,
                    endStatus: false,
                    active: '#00B4ED',
                    default: '#020A0A'
                },
                //----
                isFocused: false,
                isDragging: false,
                interval : null,
                status: 'pause',
                nowMilliseconds: 0,
                fPoint: 0,
                ePoint: 0,
                alertMessege: "시작 시간이 종료 시간을 <br/> 초과할 수 없습니다.",
                color:[
                '#80BDFF', '#409DFF', '#187BFF', '#0F5CBF', '#073E80',
                '#B7A1E0', '#9371D1', '#6F42C1', '#533191', '#382261',
                '#EE9AA2', '#E56874', '#DC3545', '#A52833', '#6E1A23',
                '#94D3A2', '#5EBD74', '#27A745', '#1F7D35', '#145423',
                '#FDBE89', '#FE9F4F', '#FD7E14', '#BE5F0F', '#7F3F0B',
                '#DDD8D6', '#CBC5C2', '#BAB1AD', '#8C8582', '#5D5957',
                '#B388F9', '#8C4CF5', '#660FF2', '#4D0DB6', '#330879',
                '#F49FC6', '#EE6EA9', '#E83E8C', '#AE2F69', '#741F46',
                '#FEE083', '#FED145', '#FEC106', '#C09106', '#806106',
                '#90E4CB', '#5AD7B1', '#20C997', '#189771', '#10654C',
                '#8BD1DC', '#51B9CA', '#17A2B8', '#117A8A', '#0D525B'
                ],
                timeSet:{
                    max: '',
                    HH: '00',
                    MM: '00',
                    SS: '00',
                    ss: '00'
                },
                drivList: null,
                thisDdivListId: null,
                recList: null,
                activeDrivID: '',
                //---
                drivStartTime: '',
                drivEndTime: '',
                // catalog list 
                dataMoniterList:[],
                catalogList: [],
                catalogListSelectedItems: [],
                activeCatalog: [],
                activeCatalogSelectedItems:[],
                
                // 
                motTaskList:[],
                // gps 
                gps: [
                        { lat: 37.404583333333335, lng: 127.11658055555556 },
                        { lat: 37.40456388888889, lng: 127.11654722222222 },
                        { lat: 37.40455, lng: 127.11653611111112 },
                ],
                mapOptions : {
                    latitude: 37.404583333333335, // 지도 중앙 위도
                    longitude: 127.11658055555556, // 지도 중앙 경도
                    zoom: 17,
                    zoomControl: false,
                    zoomControlOptions: { position: "TOP_RIGHT" },
                },
                initLayers : [
                    "BACKGROUND",
                    "BACKGROUND_DETAIL",
                    "POI_KOREAN",
                    "TRANSIT",
                    "ENGLISH",
                ],
                mapData:{},
                gpsKey: 0,
                gpsCenterKey: 0,
                // chart 
                chartlabelColor: {
                    datasets: [
                        {
                            borderColor: 'rgb(102, 15, 242)',
                        },
                        {
                            borderColor: 'rgb(232, 62, 140)',
                        },
                        {
                            borderColor: 'rgb(254, 193, 6)',
                        },
                        {
                            borderColor: 'rgb(32, 201, 151)',
                        },
                        {
                            borderColor: 'rgb(23, 162, 184)',
                        },
                    ]
                },
                    
                chartData: {
                    labels: [],
                    datasets: [
                        // {
                        //     label: 'CHASSIS.MDPS.MDPS_01_10ms.MDPS_PaStrAnglVal',
                        //     data: [65, 59, 80, 81, 56, 55, 40],
                        //     borderColor: 'rgb(102, 15, 242)',
                        // },
                        // {
                        //     label: 'CHASSIS.MDPS.MDPS_01_10ms.MDPS_StrTqSnsrVal',
                        //     data: [2, 3, 20, 41, 52, 15, 89],
                        //     borderColor: 'rgb(232, 62, 140)',
                        // },
                        // {
                        //     label: 'CHASSIS.MDPS.MDPS_01_10ms.MDPS_EstStrAnglVal',
                        //     data: [1, 31, 23, 41, 52, 15, 49],
                        //     borderColor: 'rgb(254, 193, 6)',
                        // },
                        // {
                        //     label: 'Dataset 4',
                        //     data: [1, 57, 20, 41, 52, 15, 19],
                        //     borderColor: 'rgb(32, 201, 151)',
                        // },
                        // {
                        //     label: 'Dataset 5',
                        //     data: [1, 83, 20, 11, 56, 35, 29],
                        //     borderColor: 'rgb(23, 162, 184)',
                        // }
                    ]
                },

                projectId: '',

                // role
                viewRole: false,
                managePrjRole: false,
                manageDrRole: false,
            };
        },
        watch: {
            gps() {
                this.gpsKey++;
            },
            
        },
        computed: {
            //
            isButtonDisabled() {
                return this.activeCatalog.length < 1;
            },
            picStartTxtStyle() {
                return {
                    color: this.picColor.startStatus ? this.picColor.active : this.picColor.default
                };
            },
            picEndTxtStyle() {
                
                return {
                    color: this.picColor.endStatus ? this.picColor.active : this.picColor.default
                };
            }
           


        },
        async mounted() {
            // Role
            await this.setRole();

            this.userData = await common.getUserInfo();

            const vm = this;
            const stoday = new Date();
            stoday.setDate(stoday.getDate() - 60); 
            const syear = stoday.getFullYear();
            const smonth = stoday.getMonth() + 1;
            const sday = stoday.getDate();
            const start_formattedDate = syear + '-' + (smonth < 10 ? '0' : '') + smonth + '-' + (sday < 10 ? '0' : '') + sday;
            

            const etoday = new Date();
            const eyear = etoday.getFullYear();
            const emonth = etoday.getMonth() + 1;
            const eday = etoday.getDate();
            const end_formattedDate = eyear + '-' + (emonth < 10 ? '0' : '') + emonth + '-' + (eday < 10 ? '0' : '') + eday;
            
            
            

            console.log(end_formattedDate);
            

            vm.picStartDate = start_formattedDate;
            vm.picEndDate = end_formattedDate;
            window.selectDate.startDate = start_formattedDate;
            window.selectDate.endDate = end_formattedDate;

            // ----- Chart ----- 
            this.drawChart();
            
            // const dataChart = vm.$refs.dataChart;
            // let linedata =  {
            //     labels: ['a', 'b', 'c', 'd', 'e', 'f'],
            //     datasets: [
            //         {
            //             label: 'Dataset 1',
            //             data: [65, 59, 80, 81, 56, 55, 40],
            //             borderColor: 'rgb(75, 192, 192)',
            //         },
            //         {
            //             label: 'Dataset 2',
            //             data: [2, 3, 20, 41, 52, 15, 89],
            //             borderColor: 'rgb(75, 23, 231)',
            //         },
            //         {
            //             label: 'Dataset 3',
            //             data: [1, 31, 23, 41, 52, 15, 49],
            //             borderColor: 'rgb(75, 23, 231)',
            //         },
            //         {
            //             label: 'Dataset 4',
            //             data: [1, 57, 20, 41, 52, 15, 19],
            //             borderColor: 'rgb(75, 232, 231)',
            //         },
            //         {
            //             label: 'Dataset 5',
            //             data: [1, 83, 20, 11, 56, 35, 29],
            //             borderColor: 'rgb(175, 23, 31)',
            //         }
            //     ]
            // }

            // new Chart(dataChart, {
            //     type: 'line',
            //     data: linedata,
            //     options: {
            //         scales: {
            //             y: {
            //                 suggestedMin: 0,
            //                 suggestedMax: 100
            //             }
            //         }
            //     }
            // });


            // ------------------ 
            

            


            const currentURL = window.location.href;

            const urlParams = new URLSearchParams(new URL(currentURL).search);

            const prjId = urlParams.get('prjId');
            const edgeId = urlParams.get('edgeId');
        
            console.log('prjId:', prjId);
            console.log('edgeId:', edgeId);
            vm.urlParam = {
                prjId: prjId,
                edgeId: edgeId
            }
            // --api
            // 날짜로 검색해오는 데이터 리스트 갱신
            
            vm.prjData = await vm.getPJTData(prjId, edgeId);
            
            console.log(vm.prjData);
            // this.getDLVicData();
            // this.getDriRecListData(10);
            console.log(vm.picStartDate)
            console.log(vm.picEndDate)
            vm.drivList = await vm.getDriPrjListData(vm.prjData.projectId, vm.prjData.id, vm.picStartDate, vm.picEndDate);
            
            console.log(vm.drivList);

            //---

            const getMonTaskData = await this.getMonTaskData(edgeId);

            this.motTaskList = await getMonTaskData;
             
            this.projectId = this.$route.query.prjId;
            

        },
        methods: {
            async setRole() {
                const prjId = this.$route.query.prjId;
                console.log(prjId);
                this.managePrjRole = ( await common.getUserRole('type2') || await common.getPrjRole('type3', prjId) );
                this.manageDrRole = ( await common.getUserRole('type2') || await common.getPrjRole('type2', prjId) );
                this.viewRole = await common.getUserRole('type1');

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            // item_table ui
            evtBox(e) {
                const btn = e.target;
                if(btn.classList.contains('active')){
                    btn.classList.remove('active');
                    btn.nextElementSibling.style.display = 'none';
                }else{
                    btn.classList.add('active');
                    btn.nextElementSibling.style.display = 'block';
                }
            },
            clickTogglePrj() {
                const toggle = !this.prjInfoToggle;
                this.prjInfoToggle = toggle;
            },
            
            addCatalog(){
                // 5개이상 추가 안됨 
                const vm = this;
                let counst = vm.catalogListSelectedItems.length + vm.activeCatalog.length;
                if(counst > 5){
                    alert("선택 항목은 5개를 초과 할수 없습니다.");
                }else{
                    // console.log(vm.catalogListSelectedItems);
                    // console.log(vm.catalogList);
                    setTimeout( () => {
                        vm.catalogListSelectedItems.forEach(async (index) => {
                            // console.log(index);
                            if(vm.catalogList[index] != undefined){
                                const item = await vm.catalogList[index];
                                vm.activeCatalog.push(item);
                            }
                            vm.catalogList.splice(index, 1);
                            
                        });
                        vm.catalogListSelectedItems = [];
                        // console.log("---------add------");
                        // console.log(vm.catalogList);
                        // console.log(vm.activeCatalog);
                    }, 400); 
                    
                      
                }                
            },
            async delCatalog(){
                
                const vm = this;
                // console.log("---------del------");
                // console.log(await vm.activeCatalog);
                // console.log(vm.activeCatalogSelectedItems);
                setTimeout(() => {
                    vm.activeCatalogSelectedItems.forEach( async (index) => {
                        // console.log(index);
                        // console.log(vm.activeCatalog[index]);
                        if(vm.activeCatalog[index] != undefined ){
                            const item = await vm.activeCatalog[index];
                            vm.catalogList.push(item);    
                        }
                        vm.activeCatalog.splice(index, 1);                    
                    });
                    vm.activeCatalogSelectedItems = [];    
                }, 400);
                
                console.log(vm.catalogList);
            },

            // 데이터 카달로그 검색
            async listSearch() {
                // const lodash = require('lodash');
                const searchInput = this.$refs.searchInput.value;
                if(searchInput.length > 0){
                    const result = new RegExp(searchInput, 'g');
                    let newLsit = [];
                    this.catalogList.forEach((item, index) =>{
                        let _vehicleName = result.test(item.vehicle_name);
                        let _measureName = result.test(item.measure_name);
                        if(_vehicleName || _measureName){
                            newLsit.push({
                                'vehicle_name' : this.catalogList[index].vehicle_name,
                                'measure_name' : this.catalogList[index].measure_name
                            });
                            
                        }
                    });    
                    this.catalogList = newLsit; 
                }else{
                    
                    await this.setCatalog();
                    
                    if(this.activeCatalog.length > 0){
                        this.catalogList.forEach((item, index) =>{
                            this.activeCatalog.forEach((itemAct, indexAct) =>{
                                const resultVehicle = new RegExp(this.activeCatalog[indexAct].vehicle_name, 'g');
                                const resultMeasure = new RegExp(this.activeCatalog[indexAct].measure_name, 'g');

                                let _vehicleName = resultVehicle.test(item.vehicle_name);
                                let _measureName = resultMeasure.test(item.measure_name);
                                if(_vehicleName && _measureName){
                                    this.catalogList.splice(index, 1);
                                }
                            });
                        });
                    }
                    
                    

                }
                

            },
            // 카탈로그 완료 
            async addCatalogChart() {
                const vm = this;
                const _activeList = this.activeCatalog;
                console.log("---- end ----");
                console.log(_activeList);
                let _newAct = [] 
                _activeList.forEach((item, index) => {
                    _newAct.push(
                        {
                            "drivingRecordId": this.activeDrivID,
                            "domain": _activeList[index].vehicle_name,
                            "dataName":_activeList[index].measure_name
                        }
                    )
                });
                
                const postData = {
                    "drivingRecordDataCatalogs": _newAct,                        
                }
                if(_newAct.length > 0){
                    try {
                        await this.setCatalogData(postData);    
                    } catch (error) {
                        console.error("예외가 발생했습니다:", error.message);     
                    }
                }else{
                    console.log("키탈로그 삭제");
                    await this.delCatalogData(this.activeDrivID);    
                }
                
                
                // measure_name 추출
                let _strMeasure = '';
                _newAct.forEach((item, index) => {
                    _strMeasure += "'"+_newAct[index].dataName + "'" + ", ";
                });


                //
                this.closeModal();

                vm.$refs.lodingWrap.style.display = 'block';
                // 데이터 모니터링 차트
                try {
                    // 초기화
                    vm.chartData.labels = [];
                    vm.chartData.datasets = [];

                    const moniterChartData = await vm.setChartData(_strMeasure);
                    console.log(moniterChartData[0]);
                    
                    for( var i = 0; i <  moniterChartData[0][_newAct[0].dataName].length; i++){
                        vm.chartData.labels.push(moniterChartData[0][_newAct[0].dataName][i].time_stamp);
                    }
                    // chartData.datasets.data
                    _newAct.forEach((item, index)=>{
                        vm.chartData.datasets.push({
                            label: _newAct[index].dataName,
                            borderColor: vm.chartlabelColor.datasets[index].borderColor,
                            data: []
                        });
                        moniterChartData[0][_newAct[index].dataName].forEach((item, indexM)=>{
                            vm.chartData.datasets[index].data.push(moniterChartData[0][_newAct[index].dataName][indexM].measure_value);
                        });
                    })
                    this.drawChart();
                    
                } catch (error) {
                    console.error("예외가 발생했습니다:", error.message);
                    vm.chartData.labels = [];
                    vm.chartData.datasets = [];
                    this.drawChart();
                    // 로딩 시작 끝
                    vm.$refs.lodingWrap.style.display = 'none';
                }

                // 데이터 모니터링 통계
                try {
                    await vm.setMoniter(_strMeasure);
                } catch (error) {
                    console.error("예외가 발생했습니다:", error.message);
                    // 로딩 시작 끝
                    vm.$refs.lodingWrap.style.display = 'none';
                }

                vm.$refs.lodingWrap.style.display = 'none';
            },

            // chart
            
            drawChart() {
                const vm = this;
                const canvas = vm.$refs.dataChart;
                const ctx = canvas.getContext('2d');

                if (this.chart) {
                    this.chart.destroy(); // 기존 차트 파기
                }
                
                this.chart = new Chart(ctx, {
                    type: 'line',
                    data: this.chartData,
                    options: {
                        plugins: {
                            legend: {
                                display: true, 
                            }
                        },
                        scales: {
                            x: {
                                display: false,
                                beginAtZero: true,
                            },
                            y: {
                                display: true,
                                beginAtZero: true,
                            }
                        }
                    },
                });
            },
            // gps 
            updateCenter(lat, lng) {
                this.mapOptions.latitude = lat; // 새 위도 값으로 업데이트
                this.mapOptions.longitude = lng; // 새 경도 값으로 업데이트

                this.gpsCenterKey += 1;
            },
            // call Api 
            async setCatalog() {
                const headers = {
                    // startTime: this.picStartDate + ' 00:00:00',
                    // endTime: this.picEndDate + ' 00:00:00',
                    startTime: this.drivStartTime,
                    endTime: this.drivEndTime,
                    vehicle: this.prjData.edgeDeviceId
                    // vehicle: 'd-uset1-maici-fwvhcl-jk1ev-orin01-01'
                };

                 const _data = await this.getDLcatalogData(headers);
                 
                //  console.log("========catalogList========");
                //  console.log(_data);
                //  console.log("========");

                 this.catalogList = _data;
            },
            async setMoniter(list) {
                const headers = {
                    // startTime: this.picStartDate + ' 00:00:00',
                    // endTime: this.picEndDate + ' 00:00:00',
                    startTime: this.drivStartTime,
                    endTime: this.drivEndTime,
                    vehicle: this.prjData.edgeDeviceId,
                    // vehicle: 'd-uset1-maici-fwvhcl-jk1ev-orin01-01',
                    measureList: list
                };

                 const _data = await this.getDLMonitorData(headers);
                 console.log("========dataMoniterList========");
                 console.log(_data);
                 console.log("========");
                 this.dataMoniterList = _data;
            },
            async setChartData(list) {
                const headers = {
                    // startTime: this.picStartDate + ' 00:00:00',
                    // endTime: this.picEndDate + ' 00:00:00',
                    startTime: this.drivStartTime,
                    endTime: this.drivEndTime,
                    vehicle: this.prjData.edgeDeviceId,
                    // vehicle: 'd-uset1-maici-fwvhcl-jk1ev-orin01-01',
                    measureList: list
                };

                 const _data = await this.getChartData(headers);
                 
                 return _data;
            },
            async setGPS(){
                const headers = {
                    // startTime: this.picStartDate + ' 00:00:00',
                    // endTime: this.picEndDate + ' 00:00:00',
                    startTime: this.drivStartTime,
                    endTime: this.drivEndTime,
                    vehicle: this.prjData.edgeDeviceId
                    // vehicle: 'd-uset1-maici-fwvhcl-jk1ev-orin01-01',
                };
                const dlGPS = await this.getGPSData(headers);
                let _data = [];

                if(dlGPS != undefined){
                    for(var g = 0 ; g < dlGPS.length; g++){
                        await _data.push({'lat': dlGPS[g].latitude , 'lng': dlGPS[g].longitude});
                    }
                }
                return _data
                
            },
            // ------ API
            async delCatalogData(drivId) {
                try {
                    await common.apiDelete('/driving-record-data-catalog/' + drivId);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async setCatalogData(postData) {
                try {
                    const response = await common.apiPost('/driving-record-data-catalog', postData);
                    // return response.data.code;
                    return response;
                } catch (error) {
                    console.error('Error posting data:', error);
                }
            },
            async getDrivCatalogData(drivId){
                try {
                    const response = await common.apiGet('/driving-record-data-catalog/' + drivId);
                    // console.log(response);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getChartData(headers){
                console.log(headers);
                try {
                    const response = await common.apiDlGet('/dl/can/graph', headers);
                    console.log(response);
                    return response.data.queryResult.results;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getGPSData(headers){
                console.log(headers);
                try {
                    const response = await common.apiDlGet('/dl/gps/gps', headers);
                    console.log(response);
                    return response.data.queryResult.results;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getMonTaskData(edgeId){
                try {
                    const _edgeId = encodeURIComponent(edgeId);
                    const response = await common.apiGet('/monitoring-task/edge-device/' + _edgeId);
                    
                    console.log(response);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getDLcatalogData(headers){
                console.log(headers);
                try {
                    const response = await common.apiDlGet('/dl/can/catalog-list', headers);
                    console.log(response);
                    return response.data.queryResult.results;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getDLMonitorData(headers){
                console.log(headers);
                try {
                    const response = await common.apiDlGet('/dl/can/stddev', headers);
                    console.log(response);
                    return response.data.queryResult.results;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },


            
            

            // -------- Time Control 
            handleFocus() {
                const vm = this;
                this.isFocused = true;
                vm.rangeDataClearInterval();
            },
            handleBlur() {
                this.isFocused = false; 
                const $exIn = this.$refs.rangeItem.querySelector("#exIn");
                const $nowTime = this.$refs.timeInputBox.querySelector("strong");
                const $inpuTime = this.$refs.timeInputBox.querySelectorAll("input");
                const vm = this ;
                // timeInputBox 값 업데이트
                this.timeSet.HH = $inpuTime[0].value;
                this.timeSet.MM = $inpuTime[1].value;
                this.timeSet.SS = $inpuTime[2].value;
                this.timeSet.ss = $inpuTime[3].value;
                
                $nowTime.innerText = this.timeSet.HH + ":" + this.timeSet.MM + ":" + this.timeSet.SS + ":" + this.timeSet.ss;

                vm.nowMilliseconds = vm.timeStringToMilliseconds($nowTime.innerText);
                if(vm.nowMilliseconds > this.timeSet.max){
                    console.log("최대시간을 초과 합니다.");
                }else{
                    $exIn.value = vm.nowMilliseconds;
                    vm.rangeGageEvent(vm.nowMilliseconds, this.timeSet.max);
                }
            },
            
            controlBtn(e,type){
                const $exIn = this.$refs.rangeItem.querySelector("#exIn");
                const $nowTime = this.$refs.timeInputBox.querySelector("strong");
                
                const vm = this;
                vm.nowMilliseconds = vm.timeStringToMilliseconds($nowTime.innerText);

                vm.rangeDataClearInterval();
                if(type == 'fTime'){
                    console.log("fTime");
                    let stringTime = vm.convertMillisecondsToTime(0);
                    $exIn.value = 0;
                    this.timeSet.HH = '00';
                    this.timeSet.MM = '00';
                    this.timeSet.SS = '00';
                    this.timeSet.ss = '00';
                    vm.rangeGageEvent(0, this.timeSet.max);
                    $nowTime.innerText = stringTime;

                    vm.status = 'pause';
                    vm.$refs.playPauseBtn.classList.remove('play');
                    vm.$refs.playPauseBtn.classList.add('pause');
                }else if( type == 'back3'){
                    console.log("back3");
                    vm.nowMilliseconds = vm.nowMilliseconds - 3000;
                    if(vm.nowMilliseconds < 0){
                        vm.nowMilliseconds = 0;
                        $exIn.value = vm.nowMilliseconds;
                        this.timeSet.HH = '00';
                        this.timeSet.MM = '00';
                        this.timeSet.SS = '00';
                        this.timeSet.ss = '00';
                    }
                    let stringTime = vm.convertMillisecondsToTime(vm.nowMilliseconds);
                    const fomatTime = stringTime.split(':');
                    this.timeSet.HH = fomatTime[0];
                    this.timeSet.MM = fomatTime[1];
                    this.timeSet.SS = fomatTime[2];
                    this.timeSet.ss = fomatTime[3];
                    $nowTime.innerText = stringTime;
                    $exIn.value = vm.nowMilliseconds;
                    vm.rangeGageEvent(vm.nowMilliseconds, this.timeSet.max);
                    vm.status = 'pause';
                    vm.$refs.playPauseBtn.classList.remove('play');
                    vm.$refs.playPauseBtn.classList.add('pause');
                }else if( type == 'playPause'){
                    
                    if(vm.status == 'pause'){
                        vm.status = 'play';
                        // console.log(vm.status);
                        e.target.classList.remove('pause');
                        e.target.classList.add('play');
                        rangeSliderIntervalStartTask();
                    }else if(vm.status == 'play'){
                        vm.status = 'pause';
                        // console.log(vm.status);
                        e.target.classList.remove('play');
                        e.target.classList.add('pause');
                        console.log("play");
                    }

                }else if( type == 'forward3'){
                    console.log("forward3");
                    
                    vm.nowMilliseconds = vm.nowMilliseconds + 3000;
                    if(vm.nowMilliseconds > this.timeSet.max){
                        vm.nowMilliseconds = this.timeSet.max;
                    }
                    let stringTime = vm.convertMillisecondsToTime(vm.nowMilliseconds);
                    $nowTime.innerText = stringTime;
                    const fomatTime = stringTime.split(':');
                    this.timeSet.HH = fomatTime[0];
                    this.timeSet.MM = fomatTime[1];
                    this.timeSet.SS = fomatTime[2];
                    this.timeSet.ss = fomatTime[3];
                    $exIn.value = vm.nowMilliseconds;
                    vm.rangeGageEvent(vm.nowMilliseconds, this.timeSet.max);

                    vm.status = 'pause';
                    vm.$refs.playPauseBtn.classList.remove('play');
                    vm.$refs.playPauseBtn.classList.add('pause');
                    
                }else if( type == 'eTime'){
                    // console.log("eTime");
                    let stringTime = vm.convertMillisecondsToTime(this.timeSet.max);
                    $nowTime.innerText = stringTime;
                    const fomatTime = stringTime.split(':');
                    this.timeSet.HH = fomatTime[0];
                    this.timeSet.MM = fomatTime[1];
                    this.timeSet.SS = fomatTime[2];
                    this.timeSet.ss = fomatTime[3];
                    $exIn.value = this.timeSet.max;

                    vm.rangeGageEvent(this.timeSet.max, this.timeSet.max);
                    vm.status = 'pause';
                    vm.$refs.playPauseBtn.classList.remove('play');
                    vm.$refs.playPauseBtn.classList.add('pause');
                    
                }

                vm.rangePoint();
                // intaval 
                function rangeSliderIntervalStartTask(){
                    
                    vm.interval = setInterval(() => {
                        // txt 
                        vm.nowMilliseconds = vm.nowMilliseconds + 10
                        // console.log(vm.nowMilliseconds);
                        let textTime = vm.convertMillisecondsToTime(vm.nowMilliseconds);

                        $nowTime.innerText = String(textTime);
                        $exIn.value = vm.nowMilliseconds;
                        vm.rangeGageEvent(vm.nowMilliseconds, vm.timeSet.max);
                        
                        if (Number(vm.nowMilliseconds) > vm.timeSet.max) {
                            vm.rangeDataClearInterval();
                            $nowTime.innerText =  vm.convertMillisecondsToTime(vm.timeSet.max);
                            vm.$refs.playPauseBtn.classList.remove('play');
                            vm.$refs.playPauseBtn.classList.add('pause');
                            vm.$refs.fPointBtn.style.display = 'block';
                            vm.$refs.ePointBtn.style.display = 'block';
                            vm.$refs.selectBtn.style.display = 'block';
                            vm.$refs.selecTimeBox.style.display = 'block';
                        }
                        
                    }, 10);
                }
                // 타임 게이지/ 타임 움직이는값 
                // now text  
                
                
            },
            rangeDataClearInterval(){
                const vm = this;
                const $nowTime = this.$refs.timeInputBox.querySelector("strong");
                clearInterval(vm.interval);
                const fomatTime = $nowTime.innerText.split(':');
                this.timeSet.HH = fomatTime[0];
                this.timeSet.MM = fomatTime[1];
                this.timeSet.SS = fomatTime[2];
                this.timeSet.ss = fomatTime[3];

            },
            convertMillisecondsToTime(milliseconds) {
                //  milliseconds > HH:MM:SS:ss
                const seconds = Math.floor(milliseconds / 1000);
                const hours = Math.floor(seconds / 3600);
                const minutes = Math.floor((seconds % 3600) / 60);
                const remainingSeconds = seconds % 60;

                const formattedMilliseconds = (milliseconds % 1000).toString().padStart(2, '0');

                const forMilliseconds = formattedMilliseconds.slice(0, 2);
                
                const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}:${forMilliseconds}`;

                return formattedTime;
            },
            timeStringToMilliseconds(timeString) {
                // HH:MM:SS:ss > ss
                if(timeString != undefined){
                    const [hours, minutes, seconds, milliseconds] = timeString.split(":").map(Number);
                    const totalMilliseconds =
                        (hours * 60 * 60 * 1000) +
                        (minutes * 60 * 1000) +
                        (seconds * 1000) +
                        milliseconds * 10;
                    return totalMilliseconds;
                }
                
            },
            rangeGageEvent(newTime, maxTime){
                const $rangeGage = this.$refs.rangeItem.querySelector(".flow_bg");
                const $chartBar = this.$refs.chartBar;
                let nowTime = (newTime / maxTime) * 100;
                $rangeGage.style.width = nowTime +'%';
                $chartBar.style.width = nowTime +'%';
            },
            rangePoint(e,type){
                const vm = this ;
                const $fPointBtn = vm.$refs.fPointBtn;
                const $ePointBtn = vm.$refs.ePointBtn;
                const $selectBtn = vm.$refs.selectBtn;
                const $selecTimeBox = vm.$refs.selecTimeBox;
                const $rangeItem = vm.$refs.rangeItem;
                
                
                if(vm.status == 'play'){
                    $fPointBtn.style.display = 'none';
                    $ePointBtn.style.display = 'none';
                    $selectBtn.style.display = 'none';
                    $selecTimeBox.style.display = 'none';
                    $rangeItem.querySelector('.f_point').style.display = 'none';
                    $rangeItem.querySelector('.e_point').style.display = 'none';
                }else if(vm.status == 'pause'){
                    $fPointBtn.style.display = 'block';
                    $ePointBtn.style.display = 'block';
                    $selecTimeBox.style.display = 'block';
                    if($fPointBtn.classList.contains('active')){
                        $rangeItem.querySelector('.f_point').style.display = 'block';
                    }
                    if($ePointBtn.classList.contains('active')){
                        $rangeItem.querySelector('.e_point').style.display = 'block';
                    } 
                    if($fPointBtn.classList.contains('active') && $ePointBtn.classList.contains('active')){
                        $selectBtn.style.display = 'block';
                    }
                }

                if(type == 'fPoint'){
                    vm.fPoint = 0;
                    vm.fPoint = vm.nowMilliseconds;
                    let nowTime = ((vm.fPoint / vm.timeSet.max) * 100);
                    
                    if($fPointBtn.classList.contains('active')){
                        $fPointBtn.classList.remove('active');
                        $ePointBtn.classList.remove('active');
                        $selecTimeBox.querySelector('.start_point_time').querySelector("span").innerText = "00:00:00:00";
                        $rangeItem.querySelector('.f_point').style.display = 'none';
                        $rangeItem.querySelector('.e_point').style.display = 'none';
                        
                    }else{
                        
                        $fPointBtn.classList.add('active');
                        $selecTimeBox.querySelector('.start_point_time').querySelector("span").innerText = vm.convertMillisecondsToTime(vm.fPoint);
                        $rangeItem.querySelector('.f_point').style.display = 'block';
                        $rangeItem.querySelector('.f_point').style.left = nowTime +'%';
                    }

                    // 선택구간 검사 
                    if($fPointBtn.classList.contains('active') && $ePointBtn.classList.contains('active')){
                        $selectBtn.style.display = 'block';
                    }else{
                        $selectBtn.style.display = 'none';
                    }

                    console.log(vm.nowMilliseconds);
                    
                }else if(type == 'ePoint'){
                    vm.ePoint = 0;
                    vm.ePoint = vm.nowMilliseconds;
                    let nowTime = ((vm.ePoint / vm.timeSet.max) * 100);
                    if($ePointBtn.classList.contains('active')){
                        $ePointBtn.classList.remove('active');
                        $fPointBtn.classList.remove('active');
                        $selecTimeBox.querySelector('.end_point_time').querySelector("span").innerText = "00:00:00:00";
                        $rangeItem.querySelector('.e_point').style.display = 'none';
                        $rangeItem.querySelector('.f_point').style.display = 'none';
                    }else{
                        $ePointBtn.classList.add('active');
                        $selecTimeBox.querySelector('.end_point_time').querySelector("span").innerText = vm.convertMillisecondsToTime(vm.ePoint);
                        $rangeItem.querySelector('.e_point').style.display = 'block';
                        $rangeItem.querySelector('.e_point').style.left = nowTime +'%';
                    }
                    
                    // 선택구간 검사 
                    if($fPointBtn.classList.contains('active') && $ePointBtn.classList.contains('active')){
                        $selectBtn.style.display = 'block';
                    }else{
                        $selectBtn.style.display = 'none';
                    }
                    console.log(vm.nowMilliseconds);
                }
            },
            getRandomColor() {
                const vm = this;
                const min = 0;
                const max = vm.color.length -1;
                const randomDecimal = Math.random();
                const randomNumber = Math.floor(randomDecimal * (max - min + 1)) + min;
            
                return vm.color[randomNumber];
            },
            drawDrivingRecordd(fp, ep, color){
                const vm = this;
                const boxWidth = (((ep - fp) / vm.timeSet.max) * 100);
                const position = ((fp / vm.timeSet.max) * 100);
                const colorCode = color;
                console.log(boxWidth);
                console.log(position);

                const timeMarkHTML = `
                <div class="time_mark" id="${colorCode}" style="width:${boxWidth}%; left:${position}%"; background-color:${colorCode}>
                    <div style="background-color: #000;"></div>
                    <div class="mark_index" style="background-color: #000;">1</div>
                </div>
            `;

                vm.$refs.rangeItem.innerHTML += timeMarkHTML;
            },
            btnEvtGroup(e){
                const $target = e.target;
                if($target.classList.contains('active')){
                    $target.classList.remove('active');
                    $target.nextElementSibling.style.display = "none";
                }else{
                    $target.classList.add('active');
                    $target.nextElementSibling.style.display = "block";
                }
                
                //contains
            },
            // DL Data Call 
            async drivRecActive(e, id , clickedIndex, startTime, endTime){
                const vm = this;
                vm.activeDrivID = id;
                vm.drivStartTime = startTime;
                vm.drivEndTime = endTime;
                console.log(vm.activeDrivID);
                console.log(vm.drivStartTime);
                console.log(vm.drivEndTime);
                const startDate = new Date(startTime);
                const endDate = new Date(endTime);
                const timeDifference = endDate - startDate;
                
                vm.timeSet.max = timeDifference;
                console.log(timeDifference);
                const $rangeItem = vm.$refs.rangeItem;
                const maxTime = vm.timeSet.max;
                const $endTime = vm.$refs.endTime.querySelector("strong");
                console.log(maxTime);

                let totalTime = vm.convertMillisecondsToTime(maxTime);


                $endTime.innerText = String(totalTime);

                // ui                
                const $exIn = this.$refs.rangeItem.querySelector("#exIn");
                const $nowTime = this.$refs.timeInputBox.querySelector("strong");
                let timeSet = this.timeSet;

                // 초기화 
                $exIn.value = 0;
                vm.rangeGageEvent(0, vm.timeSet.max);
                vm.rangeDataClearInterval();

                const timeMark = $rangeItem.querySelectorAll('.time_mark');

                timeMark.forEach((mark) => {
                    console.log(mark.style.display);
                    mark.style.display = 'none';
                });
                //HH
                vm.timeSet.HH = '00';
                //MM
                vm.timeSet.MM = '00';
                //SS
                vm.timeSet.SS = '00';
                //ss
                vm.timeSet.ss = '00';
                $nowTime.innerText = vm.timeSet.HH + ":" + vm.timeSet.MM + ":" + vm.timeSet.SS + ":" + vm.timeSet.ss;

                $exIn.addEventListener('mousedown', function(){
                    vm.isDragging = true;
                    // console.log(vm.isDragging);
                    vm.status = 'pause';
                    vm.rangeDataClearInterval()
                });
                $exIn.addEventListener('mousemove', function(e){
                    if (vm.isDragging) {
                        
                        vm.rangeGageEvent(e.target.value, vm.timeSet.max);
                        vm.nowMilliseconds = e.target.value;
                        let nowTime = vm.convertMillisecondsToTime(e.target.value);
                        $nowTime.innerText = String(nowTime);
                    }
                });
                $exIn.addEventListener('mouseup', function (e) {
                    if (vm.isDragging) {
                        vm.isDragging = false;
                        // console.log('마우스 드래그 종료');
                        // console.log('최종 값:', e.target.value);
                        console.log("vm.timeSet.max");
                        console.log(vm.timeSet.max);
                        console.log("--------");
                        console.log(e.target.value);
                        vm.rangeGageEvent(e.target.value, vm.timeSet.max);
                        vm.nowMilliseconds = e.target.value;
                        let nowTime = vm.convertMillisecondsToTime(e.target.value);
                        $nowTime.innerText = String(nowTime);

                        // 일시정지 화면 

                        vm.status = 'pause';

                        vm.$refs.playPauseBtn.classList.remove('play');
                        vm.$refs.playPauseBtn.classList.add('pause');
                        vm.$refs.fPointBtn.style.display = 'block';
                        vm.$refs.ePointBtn.style.display = 'block';
                        vm.$refs.selecTimeBox.style.display = 'block';
                        
                        // [0]:HH, [1]:MM, [2]:SS, [3]:ss
                        let timeData = nowTime.split(":");
                        //HH
                        timeSet.HH = timeData[0];
                        //MM
                        timeSet.MM = timeData[1];
                        //SS
                        timeSet.SS = timeData[2];
                        //ss
                        timeSet.ss = timeData[3];
                        // playPauseClick();
                        // secondsChange(e.target.value);
                        // 일시정지 상태에서 드래그 끝
                        // if(rangeStatus == "pause"){
                        //     // playPauseClick();
                        // }
                    }
                });



                // 로딩 시작
                vm.$refs.lodingWrap.style.display = 'block';

                // active 카탈로그 데이터 호출
                
                let activeDrivCatalog = await vm.getDrivCatalogData(id);
                // measure_name 추출
                let _strMeasure = '';
                activeDrivCatalog.forEach((item, index) => {
                    _strMeasure += "'"+activeDrivCatalog[index].dataName + "'" + ", ";
                });
                
                // 데이터 모니터링 차트
                
                try {
                    // 초기화
                    vm.chartData.labels = [];
                    vm.chartData.datasets = [];

                    let moniterChartData = await vm.setChartData(_strMeasure);
                    for( var i = 0; i <  moniterChartData[0][activeDrivCatalog[0].dataName].length; i++){
                        vm.chartData.labels.push(moniterChartData[0][activeDrivCatalog[0].dataName][i].time_stamp);
                    }
                    // chartData.datasets.data
                    activeDrivCatalog.forEach((item, index)=>{
                        // console.log(activeDrivCatalog[index].dataName);
                        vm.chartData.datasets.push({
                            label: activeDrivCatalog[index].dataName,
                            borderColor: vm.chartlabelColor.datasets[index].borderColor,
                            data: []
                        });
                        moniterChartData[0][activeDrivCatalog[index].dataName].forEach((item, indexM)=>{
                            vm.chartData.datasets[index].data.push(moniterChartData[0][activeDrivCatalog[index].dataName][indexM].measure_value);
                        });
                    })
                    this.drawChart();
                    
                } catch (error) {
                    console.error("예외가 발생했습니다:", error.message);
                    vm.chartData.labels = [];
                    vm.chartData.datasets = [];
                    this.drawChart();
                    // 로딩 시작 끝
                    vm.$refs.lodingWrap.style.display = 'none';
                }
                // 로딩 시작
                vm.$refs.lodingWrap.style.display = 'block';

                // 데이터 모니터링 통계
                try {
                    // console.log(_strMeasure);
                    await vm.setMoniter(_strMeasure);
                } catch (error) {
                    console.error("예외가 발생했습니다:", error.message);
                    // 로딩 시작 끝
                    vm.$refs.lodingWrap.style.display = 'none';
                }

                try{
                    // 카달로그 초기화
                    vm.catalogList = [];
                    vm.activeCatalog = [];
                    // 카탈로그
                    await vm.setCatalog();
                    let allCatalog = vm.catalogList;
                    console.log(activeDrivCatalog);
                    allCatalog.forEach((item, index) => {
                        let allCatalogList = allCatalog[index].measure_name
                        activeDrivCatalog.forEach((item, indexAct) => {
                            if(allCatalogList == activeDrivCatalog[indexAct].dataName){
                                vm.activeCatalog.push({
                                    'vehicle_name' : allCatalog[index].vehicle_name,
                                    'measure_name' : allCatalog[index].measure_name
                                })
                                vm.catalogList.splice(index, 1);
                            }
                             
                            
                        });
                    });

                    

                }catch (error){
                    console.error("예외가 발생했습니다:", error.message);
                    // 로딩 시작 끝
                    vm.$refs.lodingWrap.style.display = 'none';
                }
                
                // 로딩 시작
                vm.$refs.lodingWrap.style.display = 'block';

                try{
                    const $targetList = this.$refs.drivListWrap.querySelectorAll('tr');
                    const $dataSetBox = this.$refs.dataSetBox;

                    $targetList.forEach( async (element, index) => {
                        if (index === clickedIndex) {
                            element.classList.add('active'); 
                            // id 값으로 데이터 호출 
                            // onoff
                            $dataSetBox.querySelector('.data_set').style.display = "block";
                            $dataSetBox.querySelector('.none_data').style.display = "none";
                            // 선택구간
                            vm.recList = await vm.getDriRecListData(id);
                            vm.thisDdivListId = await id;
                            // 선택구간 그리기
                            console.log(vm.getDriRecListData(id));
                        } else {
                            element.classList.remove('active');
                        }
                    });

                    // gps 

                    const dlGPS = await vm.setGPS();
                    
                    // console.log(dlGPS);
                    vm.gps = [];
                    vm.gps = dlGPS;
                    vm.updateCenter(dlGPS[0].lat, dlGPS[0].lng);
                } catch (error){
                    console.error("예외가 발생했습니다:", error.message);
                    // 로딩 시작 끝
                    vm.$refs.lodingWrap.style.display = 'none';
                }
              
                vm.$refs.lodingWrap.style.display = 'none';
            },
            async deleteDriRec(e, recID, drivid){
                const vm = this;
                const $recListWrap = vm.$refs.recListWrap;
                const $recListGB = $recListWrap.querySelectorAll('.btn_evt_group');
                const $recListBB = $recListWrap.querySelectorAll('.evt_btn_box');
                $recListGB.forEach((element) => {
                    element.classList.remove('active');
                });
                $recListBB.forEach((element) => {
                    element.style.display = 'none';
                });

                await this.delDriRecData(recID);
                
                vm.recList = await vm.getDriRecListData(drivid);

            },
            async addDrivRecBtnModal(){
                const allowAccess = await this.checkRoleToAccessBtn();
                if(!allowAccess) {
                    alert("SM, Project Manager, Project Researcher만 접근 가능");
                    return;
                }

                const vm = this;
                const $rangeItem = vm.$refs.rangeItem;
                const $fPointBtn = vm.$refs.fPointBtn;
                const $ePointBtn = vm.$refs.ePointBtn;
                const $modalWrap = vm.$refs.modalWrap;
                const $modalAlert = vm.$refs.modalAlert;
                const $selecTimeBox = vm.$refs.selecTimeBox;
                const $selectBtn = vm.$refs.selectBtn;

                console.log(Number(vm.fPoint));
                console.log(Number(vm.ePoint));

                console.log(Number(vm.fPoint) > Number(vm.ePoint));
                if(Number(vm.fPoint) > Number(vm.ePoint)){
                    console.log("시작 시간이 종료 시간을 <br/> 초과할 수 없습니다.");
                    $modalWrap.style.display = 'block';
                    $modalAlert.style.display = 'block';
                    $rangeItem.querySelector('.f_point').style.display = 'none';
                    $rangeItem.querySelector('.e_point').style.display = 'none';
                    $selectBtn.style.display = 'none';
                    $ePointBtn.classList.remove('active');
                    $fPointBtn.classList.remove('active');
                    $selecTimeBox.querySelector('.start_point_time').querySelector("span").innerText = "00:00:00:00";
                    $selecTimeBox.querySelector('.end_point_time').querySelector("span").innerText = "00:00:00:00";
                    vm.fPoint = 0;
                    vm.ePoint = 0;

                }else{
                    vm.showmodalAddAlert();
                    // const color = vm.getRandomColor();
                    
                    // console.log(color);
                    // console.log(vm.drawDrivingRecordd(vm.fPoint, vm.ePoint, color));
                    // 확인 팝업 ui만들어야함
                    // vm.addDriRecData(10, color, vm.convertMillisecondsToTime(vm.fPoint), vm.convertMillisecondsToTime(vm.ePoint));
                }
            },
            async checkRoleToAccessBtn() {
                const memberRole = await this.getMemberRole();
                const projectRole = await this.getProjectRole();

                if(memberRole != null && memberRole.role == "SM") {
                    return true;
                } 
                else if(projectRole != null && (projectRole.projectRole == "Manager" || projectRole.projectRole == "Researcher")) {
                    return true;
                } 
                else {
                    return false;
                }
            },



            async addDrivRecBtn(){
                const vm = this;
                const $rangeItem = vm.$refs.rangeItem;
                const $fPointBtn = vm.$refs.fPointBtn;
                const $ePointBtn = vm.$refs.ePointBtn;
                // const $modalWrap = vm.$refs.modalWrap;
                const $selecTimeBox = vm.$refs.selecTimeBox;
                const $selectBtn = vm.$refs.selectBtn;

                const color = await vm.getRandomColor();
                console.log( typeof vm.thisDdivListId);
                const DdivListId = await Number(vm.thisDdivListId);

                console.log(vm.thisDdivListId);
                console.log(color);
                console.log(vm.convertMillisecondsToTime(vm.fPoint));

                await vm.addDriRecData( DdivListId, color, vm.convertMillisecondsToTime(vm.fPoint), vm.convertMillisecondsToTime(vm.ePoint));

                vm.recList = await vm.getDriRecListData(vm.thisDdivListId);

                $rangeItem.querySelector('.f_point').style.display = 'none';
                $rangeItem.querySelector('.e_point').style.display = 'none';
                $selectBtn.style.display = 'none';
                $ePointBtn.classList.remove('active');
                $fPointBtn.classList.remove('active');
                $selecTimeBox.querySelector('.start_point_time').querySelector("span").innerText = "00:00:00:00";
                $selecTimeBox.querySelector('.end_point_time').querySelector("span").innerText = "00:00:00:00";
                vm.fPoint = 0;
                vm.ePoint = 0;

               
                vm.closeModal();
                
            },
            // --- 선택 구간 선택 시작 시간 이동
            selectStartTime(e){
                const vm = this;
                const startTime = e.target.closest("tr").getAttribute("data-st");
                const stMilliseconds = vm.timeStringToMilliseconds(startTime);
                const maxTime = this.timeSet.max;
                const $nowTime = this.$refs.timeInputBox.querySelector("strong");
                const $fPointBtn = vm.$refs.fPointBtn;
                const $ePointBtn = vm.$refs.ePointBtn;
                const $selectBtn = vm.$refs.selectBtn;
                const $selecTimeBox = vm.$refs.selecTimeBox;
                const $rangeItem = vm.$refs.rangeItem;
                
                // 인터벌 종료
                vm.rangeDataClearInterval();
                // 정지 모드로 만들기
                vm.status = 'pause';
                vm.$refs.playPauseBtn.classList.remove('play');
                vm.$refs.playPauseBtn.classList.add('pause');
                $fPointBtn.style.display = 'block';
                $ePointBtn.style.display = 'block';
                $selecTimeBox.style.display = 'block';
                if($fPointBtn.classList.contains('active')){
                    $rangeItem.querySelector('.f_point').style.display = 'block';
                }
                if($ePointBtn.classList.contains('active')){
                    $rangeItem.querySelector('.e_point').style.display = 'block';
                } 
                if($fPointBtn.classList.contains('active') && $ePointBtn.classList.contains('active')){
                    $selectBtn.style.display = 'block';
                }

                //시간 이동
                //
                let nowTime = vm.convertMillisecondsToTime(stMilliseconds);
                $nowTime.innerText = String(nowTime);
                vm.rangeGageEvent(stMilliseconds, maxTime);
                vm.$refs.rangeItem.querySelector('#exIn').value = stMilliseconds;

            },
            //-- 선택 구간 복사  
            copyTextTime(e ,st, et){
                e.target.closest('.evt_btn_box').previousElementSibling.classList.remove('active');
                e.target.closest('.evt_btn_box').style.display ='none';
                const textToCopy = 'Start Time : ' + st + ', End Time : ' + et;
                const textarea = document.createElement("textarea");
                textarea.value = textToCopy;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand("copy");
                document.body.removeChild(textarea);

            },
            async deleteDrivBtn(e, driId){
                if ( this.manageDrRole ) {
                    const vm = this;
                    const btn = vm.$refs.drivListWrap.querySelectorAll('.btn_evt_group');
                    const ul = vm.$refs.drivListWrap.querySelectorAll('.evt_btn_box');
                    await this.deleteDriv(driId);

                    vm.drivList = await vm.getDriPrjListData(vm.prjData.projectId, vm.prjData.id, vm.picStartDate, vm.picEndDate);
                    console.log();
                    console.log();
                    btn.forEach((obj) => {
                        obj.classList.remove("active");
                    });
                    ul.forEach((obj) => {
                        obj.style.display = 'none';
                    });
                }
            },
            //---- API
            async getPJTData(prjId, edgeId){
                try {
                    const _pjtName = encodeURIComponent(prjId);
                    const _edgeDevId = encodeURIComponent(edgeId);
                    const response = await common.apiGet('/edge-device/' + _pjtName + '/' + _edgeDevId);
                    
                    // console.log(response.data.data);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            
            async getDriPrjListData(prjId, edgeId, startD, endD) {
                try {
                    const _pjtName = encodeURIComponent(prjId);
                    const _edgeDevId = encodeURIComponent(edgeId);
                    const _startD = encodeURIComponent(startD + ' 00:00');
                    const _endD = encodeURIComponent(endD + ' 23:59');
                    // console.log(_startD)
                    const response = await common.apiGet('/driving-record/project/'+ _pjtName +'/'+ _edgeDevId + '?' + 'start-time='+ _startD +'&end-time=' + _endD);
                    // ?start-time=2022-12-10%2000%3A00&end-time=2023-11-10%2000%3A00
                    // console.log(response.data.data);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async deleteDriv(driId){
                try {
                    await common.apiDelete('/driving-record/' + driId + "/" + this.projectId);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getDriRecListData(driRecId) {
                try {
                    const response = await common.apiGet('/driving-record/select-range/' + driRecId);
                    
                    // console.log(response.data.data);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async addDriRecData(driRecId, color, sT, eT) {
                const data = {
                    "driving_record_id": driRecId,
                    "record_color": color,
                    "start_time": sT,
                    "end_time": eT
                }
                try {
                    const response = await common.apiPost('/driving-record/select-range', data);
                    
                    console.log(response.data.data);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async UpdateDriRecData(itemId, color, sT, eT) {
                const data = {
                    "record_color": color,
                    "start_time": sT,
                    "end_time": eT
                }
                try {
                    const response = await common.apiPost('/driving-record/select-range/' + itemId , data);
                    
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },           
            async delDriRecData(driRecId) {
                try {
                    const response = await common.apiDelete('/driving-record/select-range/' + driRecId);
                    
                    // console.log(response.data.data);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getMemberRole() {
                let response;
                try {
                    let url = `/member/check-role`;
                    response = await common.apiGet(url);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },
            async getProjectRole() {
                let response;
                try {
                    let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
                    response = await common.apiGet(checkProjectRoleUrl);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },

            // dl 
            // async getDLVicData() {
            //     try {
            //         const response = await dlcommon.apiGet('/dl/can/catalog-list');
                    
            //         console.log(response.data.data);
            //         return response.data.data;
                    
            //     } catch (error) {
            //         console.error('Error fetching data:', error);
            //     }
            // },
            // -------- DATE PICKER -----
            
            datePicToggle(){
                const vm = this;
                const _display = vm.$refs.calenderBox.style.display;
                const _startDateBox = vm.$refs.startDateBox.style.display;
                // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
                // console.log(_display);
                
                if(_display == 'block'){
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'none';
                    vm.picColor.startStatus = false;
                    vm.picColor.endStatus = false;

                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'block';
                    console.log(_startDateBox);
                    if(_startDateBox == 'block'){
                        vm.picColor.startStatus = true;
                        vm.picColor.endStatus = false;
                    }else if( _startDateBox == 'none'){
                        vm.picColor.startStatus = false;
                        vm.picColor.endStatus = true;
                    }
                }
                
                
                
            },
            async getDriList(){
                const vm = this;
                // this.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', this.picStartDate, this.picEndDate);
                // 켈린더 display처리
                const $calenderBox = vm.$refs.calenderBox;
                const $startDateBox = vm.$refs.startDateBox;
                const $endDateBox = vm.$refs.endDateBox;
                const $drivReclistBox = vm.$refs.drivReclistBox;
                
                $calenderBox.style.display = 'none';
                $startDateBox.style.display = 'block';
                $endDateBox.style.display = 'none';
                $drivReclistBox.style.display = 'block';
                
                vm.picColor.startStatus = false;
                vm.picColor.endStatus = false;

                console.log(vm.prjData.projectName);
                console.log(vm.prjData.edgeDeviceId);
                console.log(vm.picStartDate);
                console.log(vm.picEndDate);

                vm.drivList = await vm.getDriPrjListData(vm.prjData.projectId, vm.prjData.id, vm.picStartDate, vm.picEndDate);
                
                console.log(vm.drivList);
                
            },
            onDatePic(flag){
                if(flag == 'S'){   
                    if(window.selectDate.startDate !== null ){
                        this.picStartDate = window.selectDate.startDate;
                    }
                    
                }else if(flag == 'E'){
                    if(window.selectDate.picEndDate !== null ){
                        this.picEndDate = window.selectDate.endDate;
                    }
                }
            },
            pickerNext(){
                this.$refs.startDateBox.style.display = 'none';
                this.$refs.endDateBox.style.display = 'block';

                this.picColor.startStatus = false;
                this.picColor.endStatus = true;
                console.log(this.picColor.endStatus);
                console.log(this.picColor.target);
                
            },
            pickerPrev(){
                this.$refs.startDateBox.style.display = 'block';
                this.$refs.endDateBox.style.display = 'none';

                this.picColor.startStatus = true;
                this.picColor.endStatus = false;
            },
            // ------------
            async showModalMember(){
                const allowAccess = await this.checkRoleToAccessBtn();
                if(!allowAccess) {
                    alert("SM, Project Manager, Project Researcher만 접근 가능");
                    return;
                }

                const $modal = this.$refs.modalWrap;
                const $modalMomber = this.$refs.modalMomber;
                $modal.style.display = "block";
                $modalMomber.style.display = "block";
            },
            showmodalAddAlert(){
                const $modal = this.$refs.modalWrap;
                const $modalAlert = this.$refs.modalAddAlert;
                $modal.style.display = "block";
                $modalAlert.style.display = "block";

              
            },
            
            closeModal(){
                const $modal = this.$refs.modalWrap;
                const $modalAlert = this.$refs.modalAlert;
                const $modalAddAlert = this.$refs.modalAddAlert;
                const $modalMomber = this.$refs.modalMomber;

                $modal.style.display = "none";
                $modalAlert.style.display = "none";
                $modalMomber.style.display = "none";
                $modalAddAlert.style.display = "none";
            },
            // 페이지 이동
            async goAddDriving(){
                if ( this.manageDrRole ) {
                    const vm = this;
                    console.log(vm.prjData.projectId)
                    this.$router.push({ 
                        name: 'AddDrivingRecord',
                        query: { prjId: vm.prjData.projectId, edgeId: vm.prjData.id}
                    });
                }
            },

            goModPage(_drId) {
                if ( this.manageDrRole ) {
                    const vm = this;
                    console.log(vm.prjData.projectId)
                    this.$router.push({ 
                        name: 'ModDrivingRecord',
                        query: { prjId: vm.prjData.projectId, edgeId: vm.prjData.id, drId: _drId}
                        // params: { edgeId: this.edgeId, drId: this.drId }
                    });
                }
            },

            goModePrj() {
                const vm = this;
                this.$router.push({
                    name: 'modProject', params: { prjId : vm.prjData.projectId }
                });
            },

            //공유하기 버튼 클릭시 url 복사
            async clipCopy() {
                let dummy = document.createElement("textarea");
                document.body.appendChild(dummy);

                try {
                    const url = window.document.location.href;
                    dummy.value = url;
                    dummy.select();
                    document.execCommand("copy");
                    document.body.removeChild(dummy);
                    alert('url copy success')
                } catch(error) {
                    console.log(error);
                }
                
            },

            getManageDrRole(item) {
                const id = item.creatorId.toString();
                
                if ( this.managePrjRole ) {
                    return true;
                } else if ( this.manageDrRole ) {
                    if ( common.nullCheck(this.userData) === '' ) {
                        return false;
                    } else if ( id === this.userData.memberId ) {
                        return true;
                    }
                }
            },
        }
    }
</script>
<style>
    .item_info > .float_box .data_set_mon .data_set .time_controller .range_box .range_item .time_mark{
        width: 20%;
        height: 10px;
        position: absolute;
        bottom: 4px;
        left: 20%;
        z-index: 2;
    }
    .item_info > .float_box .data_set_mon .data_set .time_controller .range_box .range_item .time_mark div{
        width: 100%;
        height: 100%;
        opacity: 0.7;
    }
    .item_info > .float_box .data_set_mon .data_set .time_controller .range_box .range_item .time_mark .mark_index{
        width: 20px;
        height: 20px;
        border-radius: 10px;
        color: #fff;
        text-align: center;
        font-weight: 700;
        font-family: "Montserrat", sans-serif;
        font-size: 13px;
        line-height: 20px;
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 1;
    }

</style>
<style scoped lang="scss">
    .item_info{
        
        & > .float_box{
            width: 100%;
            height: 800px;
            & > div{
                float: left;
            }
            .driving_record{
                width: 300px;
                height: 100%;
                background-color: $whiteColor;
                margin-right: 20px;
                box-sizing: border-box;
                padding: 20px 0 ;
                .title_box{
                    position: relative;
                    width: 100%;
                    height: 40px;
                    padding: 0 10px;
                    box-sizing: border-box;
                    strong{
                        font-weight: $bold;    
                    }
                    button{
                        width: 86px;
                        height: 40px;
                        background-repeat: no-repeat;
                        background-image: url($baseURL + "common/add_15_wh.svg" );
                        background-position: 15px center;
                        box-sizing: border-box;
                        padding-left: 20px;
                        border-radius: 10px;
                        position: absolute;
                        top: -10px;
                        right: 10px;
                    }
                }   
                .filter_box{
                    height: 40px;
                    box-sizing: border-box;
                    
                    .date_filter{
                        width: 100%;
                        height: 100%;
                        padding-left: 0;
                        &::after{
                            display: none;
                        }
                        .date_period{
                            width: 240px;
                            left: 10px;
                        }
                    }
                }
                .driving_record_list{
                    width: 100%;
                    height: 670px;
                    margin-top: 20px;
                    overflow-y: auto;
                    table{
                        width: 100%;
                        thead{
                            border-bottom: 1px solid $line01Color;
                            th{
                                padding: 0 0 10px;
                                color: #6E7380;
                            }
                        }
                        tbody{
                            tr{
                                border-bottom: 1px solid $line01Color;
                                &:last-child{
                                    border-bottom: none;
                                }
                                &:hover{
                                    background-color: #EDFBFF;
                                    td{
                                        .description_box{
                                            display: block;
                                        }
                                        
                                    }
                                    
                                    // color: $whiteColor;
                                    // td{
                                    //     color: $whiteColor;
                                    // }
                                    // span{
                                    //     color: $whiteColor;
                                    // }
                                    // div{
                                    //     color: $whiteColor;
                                    // }
                                    .btn_evt_group{
                                        background-image: url($baseURL+'/common/moremenu_clicklist.svg');
                                    }
                                }
                                &.active{
                                    background-color: $mainColor;
                                    color: $whiteColor;
                                    td{
                                        color: $whiteColor;
                                    }
                                    span{
                                        color: $whiteColor;
                                    }
                                    div{
                                        color: $whiteColor;
                                    }
                                    .btn_evt_group{
                                        background-image: url($baseURL+'/common/moremenu_clicklist.svg');
                                    }
                                }
                                
                            }
                            td{
                                padding: 10px 0 10px 10px;
                                vertical-align: middle;
                                position: relative;
                                .name_box{
                                    display: inline-block;
                                    width: 50px;
                                    overflow:hidden;
                                    text-overflow:ellipsis;
                                    white-space:nowrap;
                                }
                                div{
                                    margin-bottom: 5px;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                    span{
                                        display: inline-block;
                                        color: #6E7380;
                                        margin-right: 5px;
                                    }
                                    &.description_box{
                                        width: 215px;
                                        min-height: 66px;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        z-index: 10;
                                        background-color: #EDFBFF;
                                        padding: 10px;
                                        box-sizing: border-box;
                                        word-wrap: break-word;
                                        display:none; 
                                        strong{
                                            display: block;
                                            color: #6E7380;
                                            margin-bottom: 10px;
                                        }
                                        p{
                                            font-size: $fontSub;
                                        }
                                    }
                                }
                                .btn_evt_group{
                                    width: 18px;
                                    height: 18px;
                                    background-size: cover;
                                    position: relative;
                                    top: -2px;
                                    margin-left: 2px;
                                    margin-right: 6px;
                                    &.active{
                                        background-image: url($baseURL+'/common/moremenu_list_click.svg');
                                    }
                                }
                                .evt_btn_box{
                                    right: 5px;
                                }
                            }
                        }
                    }
                }
            }
            .data_set_mon{
                width: 956px;
                height: 100%;
                position: relative;
                .none_data{
                    width: 100%;
                    height: 100%;
                    background-color: $line02Color;
                    .text_box{
                        position: absolute;
                        text-align: center;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        padding-top: 100px;
                        background-image: url($baseURL+'/common/info_guide_list.svg');
                        background-repeat: no-repeat;
                        background-position: center 0;
                        span{
                            color: $placeHolderColor;
                            line-height: 20px;
                        }
                    }
                }
                .data_set{
                    .time_controller{
                        width: 100%;
                        height: 144px;
                        margin-bottom: 20px;
                        position: relative;
                        .controller_box{
                            width: 350px;
                            height: 40px;
                            position: absolute;
                            top: 20px;
                            left: 50%;
                            transform: translateX(-50%);
                            .btn_point{
                                width: 50px;
                                height: 100%;
                                background-repeat: no-repeat;
                                background-position: center;
                                &.start_point{
                                    position: absolute;
                                    top: 0;
                                    left: 8px;
                                    background-image: url($baseURL+'/common/edit_start.svg');
                                    &:hover{
                                        background-image: url($baseURL+'/common/edit_start_over.svg');
                                    }
                                    &.active{
                                        background-image: url($baseURL+'/common/edit_click_start.svg');
                                    }
                                }
                                &.end_point{
                                    position: absolute;
                                    top: 0;
                                    right: 8px;
                                    background-image: url($baseURL+'/common/edit_end.svg');
                                    &:hover{
                                        background-image: url($baseURL+'/common/edit_end_over.svg');
                                    }
                                    &.active{
                                        background-image: url($baseURL+'/common/edit_click_end.svg');
                                    }
                                }
                                
                            }
                            .btn_wrap{
                                width: 250px;
                                height: 100%;
                                margin: 0 auto 0;
                                background-color: #EDFBFF;
                                border-radius: 20px;
                                position: relative;
                                button{
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 15px;
                                    position: absolute;
                                    top: 5px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-color: #EDFBFF;
                                    &:hover{
                                        background-color: $line01Color;
                                    }
                                    &.first_point{
                                        left: 15px;
                                        background-image: url($baseURL+'/common/start.svg');
                                    }
                                    &.back_seconds{
                                        left: 55px;
                                        background-image: url($baseURL+'/common/3sleft.svg');
                                    }
                                    &.play_pause{
                                        width: 60px;
                                        height: 60px;
                                        top: 50%;
                                        left: 50%;
                                        border-radius: 30px;
                                        transform: translate(-50%, -50%);
                                        box-shadow: 0px 0px 6px #00B4ED99;
                                        &.pause{
                                            background-image: url($baseURL+'/common/pause.svg');    
                                        }
                                        &.play{
                                            background-image: url($baseURL+'/common/play.svg');    
                                        }
                                    }
                                    &.forward_seconds{
                                        right: 55px;
                                        background-image: url($baseURL+'/common/3sright.svg');
                                    }
                                    &.end_point{
                                        right: 15px;
                                        background-image: url($baseURL+'/common/end.svg');
                                    }
                                }
                            }
                        }
                        .add_select_section{
                            width: 135px;
                            height: 40px;
                            border-radius: 10px;
                            box-sizing: border-box;
                            background-color: #EDFBFF;
                            border: 1px solid $mainColor;
                            padding-left: 30px;
                            color: $mainColor;
                            position: absolute;
                            top: 20px;
                            right: 20px;
                            background-repeat: no-repeat;
                            background-position: 10px center;
                            background-image: url($baseURL+'/common/add_section.svg');
                            &:disabled{
                                background-color: $line02Color;
                                border: 1px solid $disable02Color;
                                background-image: url($baseURL+'/common/add_section_dis.svg');
                            }
                        }
                        .start_end{
                            width: 300px;
                            position: absolute;
                            top: 20px;
                            left: 10px;
                            & > div{
                                div{
                                    color: $placeHolderColor;
                                    display: inline-block;
                                    font-family: $montserrat;
                                    padding-left: 20px;
                                    margin-right: 10px;
                                    background-repeat: no-repeat;
                                    background-position: 10px center;
                                }
                                span{
                                    font-family: $montserrat;
                                }
                                &.start_point_time{
                                    div{
                                        background-image: url($baseURL+'/common/icon_starttime.svg');
                                    }
                                }
                                &.end_point_time{
                                    div{
                                        background-image: url($baseURL+'/common/icon_endtime.svg');    
                                    }
                                }
                            }
                        }
                        .range_box{
                            width: 100%;
                            height: 50px;
                            position: absolute;
                            left: 0;
                            bottom: 20px;
                            .range_item{
                                width: 620px;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 50%;
                                transform: translate(-50%);
                                input.slider{
                                    width: 100%;
                                    height: 10px;
                                    position: absolute;
                                    bottom: 5px;
                                    left: 0;
                                    z-index: 3;
                                    background: transparent;
                                    outline: none;
                                    -webkit-appearance: none;
                                    margin: 0;
                                    border-radius: 10px;
                                    &::-webkit-slider-thumb {
                                        -webkit-appearance: none;
                                        // appearance: none;
                                        width: 20px;
                                        height: 20px;
                                        background-color: $mainColor;
                                        box-sizing: border-box;
                                        border: 5px solid #fff;
                                        border-radius: 10px;
                                        box-shadow: 0px 0px 10px #00000029;
                                        cursor: pointer;
                                    }
                                    &::-moz-range-thumb {
                                        appearance: none;
                                        width: 20px;
                                        height: 20px;
                                        background-color: $mainColor;
                                        box-sizing: border-box;
                                        border: 5px solid #fff;
                                        border-radius: 10px;
                                        box-shadow: 0px 0px 10px #00000029;
                                        cursor: pointer;
                                    }
                                }
                                .flow_bg{
                                    width: 0;
                                    height: 10px;
                                    position: absolute;
                                    bottom: 4px;
                                    left: 0;
                                    background: $mainColor;
                                    border-radius: 10px;
                                    z-index: 1;
                                }
                                .range_bg{
                                    width: 100%;
                                    height: 10px;
                                    position: absolute;
                                    bottom: 4px;
                                    left: 0;
                                    background: #EFEFEF;
                                    border-radius: 10px;
                                }
                                .pin_point{
                                    width: 2px;
                                    height: 32px;
                                    background-color: #4791FF;
                                    position: absolute;
                                    bottom: -6px;
                                    left: 20%;
                                    z-index: 2;
                                }
                                .time_mark{
                                    width: 20%;
                                    height: 10px;
                                    position: absolute;
                                    bottom: 4px;
                                    left: 20%;
                                    z-index: 2;
                                    div{
                                        width: 100%;
                                        height: 100%;
                                        opacity: 0.7;
                                    }
                                    .mark_index{
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 10px;
                                        color: $whiteColor;
                                        text-align: center;
                                        font-weight: 700;
                                        font-family: $montserrat;
                                        font-size: $fontSub;
                                        line-height: 20px;
                                        position: absolute;
                                        top: -25px;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        opacity: 1;
                                    }
                                }
                            }
                            .time_move_box{
                                position: absolute;
                                bottom: 0;
                                left: 25px;
                                strong{
                                    font-size: $fontMenuTitle;
                                    font-family: $montserrat;
                                    font-weight: 700;
                                    color: $secondaryColor;
                                    // color: #0f0;
                                    display: inline-block;
                                    border-bottom: 1px solid $secondaryColor;
                                }
                                .time_input_box{
                                    // display: none;
                                    width: 125px;
                                    font-size: $fontMenuTitle;
                                    font-family: $montserrat;
                                    font-weight: 700;
                                    color: $secondaryColor;
                                    position: absolute;
                                    top: -11px;
                                    left: -6px;
                                    background-color: $whiteColor;
                                    border: 1px solid $mainColor;
                                    padding: 7px 5px;
                                    border-radius: 10px;
                                    input{
                                        width: 27px;
                                        height: 21px;
                                        font-size: $fontMenuTitle;
                                        font-family: $montserrat;
                                        font-weight: 700;
                                        outline: none;
                                        text-align: center;
                                        padding: 0;
                                        border:none;
                                        color: $secondaryColor;
                                        border-bottom: 1px solid $secondaryColor;
                                    }
                                }

                            }
                            .end_time{
                                position: absolute;
                                bottom: 0;
                                right: 50px;
                                strong{
                                    color: #6E7380;
                                    font-family: $montserrat;
                                }
                            }
                        }

                    }
                    & > .float_box{
                        & > div{
                            width: 305px;
                            height: 308px;
                            float: left;
                            overflow: hidden;
                            &.list_box{
                                &.task_list{
                                    table{
                                        table-layout: fixed;
                                    }
                                }
                                .mando_table_wrap{
                                    height: 268px;
                                }
                            }
                            &:nth-child(1){
                                margin-bottom: 20px;
                            }
                            &:nth-child(2){
                                margin-bottom: 20px;
                                margin-left: 20px;
                                
                            }
                            &:nth-child(3){
                                // margin-right: 20px;
                            }
                            &:nth-child(4){
                                margin-bottom: 20px;
                                margin-left: 20px;
                                margin-right: 20px;
                            }
                            .title_box{
                                width: 100%;
                                height: 40px;
                                position: relative;
                                box-sizing: border-box;
                                padding: 20px 20px 0;
                                strong{
                                    font-weight: $bold;
                                }
                                .setting_icon{
                                    position: absolute;
                                    top: 10px;
                                    right: 20px;
                                }
                            }
                            .mando_table{
                              th{
                                &:first-child{
                                    min-width: 100px;
                                }
                              }
                            } 
                            .select_section{
                                table-layout: fixed;
                                th,td{
                                    padding: 10px 0;
                                }
                                td{
                                    position: relative;
                                    vertical-align: middle;
                                    &:first-child{
                                        font-weight: $bold;
                                    }
                                    .btn_evt_group{
                                        width: 18px;
                                        height: 18px;
                                        background-size: cover;
                                        position: relative;
                                        top: -2px;
                                        margin-left: 0;
                                        &.active{
                                            background-image: url($baseURL+'/common/moremenu_list_click.svg');
                                        }
                                    }
                                    .evt_btn_box{
                                        right: 10px;
                                    }
                                    .list_item_icon{
                                        width: 13px;
                                        height: 13px;
                                        display: inline-block;
                                        border: 1px solid;
                                        border-color: #000;
                                        border-radius: 7px;
                                        position: relative;
                                        top: 2px;
                                        overflow: hidden;
                                        &> div{
                                            width: 0;
                                            height: 0;
                                            border-top: 3px solid transparent;
                                            border-bottom: 3px solid transparent;
                                            border-left: 4px solid #000;
                                            border-right: 3px solid transparent;
                                            position: absolute;
                                            top: 4px;
                                            left: 5px;
                                            
                                        }
                                    }
                                }
                            }
                            .chart_item_data{
                                th,td{
                                    padding: 10px 10px;
                                }
                                td{
                                    vertical-align: middle;
                                    &:first-child{
                                        text-align: left;
                                    }
                                    .list_item_icon{
                                        width: 8px;
                                        height: 8px;
                                        border-radius: 4px;
                                        display: inline-block;
                                        margin-right: 10px;
                                        position: relative;
                                        top: -2px;
                                    }
                                }
                            }
                            .chart_box{
                                width: 100%;
                                height: 250px;
                                margin-top: 18px;
                                box-sizing: border-box;
                                padding: 20px;
                                padding-bottom: 10px;
                                position: relative;
                                top: 0;
                                left: 0;
                                z-index: 1;
                                canvas{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    z-index: 1;
                                }
                                .chart_time{
                                    width: 560px;
                                    height: 30px;
                                    position: absolute;
                                    bottom: 0;
                                    left: 25px;
                                    z-index: 0;
                                    div{
                                        width: 100%;
                                        height: 100%;
                                        background-color: rgba(0, 180, 237, .5);
                                    }
                                }
                            }
                            .map_box{
                                width: 100%;
                                height: 250px;
                                margin-top: 18px;
                                background-color: #ff0;
                            }
                            .video_box{
                                width: 100%;
                                height: 250px;
                                margin-top: 18px;
                                background-color: #ff0;
                            }
                        }
                    }
                }
            }

        }
    }
    // 선택 구간 사용 메뉴얼


    // 선택구간 추가 알림
    
    


</style>
