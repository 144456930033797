<template>
    <div id="allPage">
        <h1>Mando Page Link</h1>
        <div class="table_wrap">
            <table>
                <tr class="color_box">
                    <th>CODE</th>
                    <th>PAGE</th>
                    <th>LINK</th>
                </tr>
                <tr>
                    <th>
                        -
                    </th>
                    <td>
                        로그인
                    </td>
                    <td>
                        <a href="/login" target="_blank" rel="noopener noreferrer">
                            /login
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_WS_1000
                    </th>
                    <td>
                        My 프로젝트(Home)
                    </td>
                    <td>
                        <a href="/home" target="_blank" rel="noopener noreferrer">
                            /home
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_WS_1100
                    </th>
                    <td>
                        프로젝트 워크스페이스
                    </td>
                    <td>
                        <a href="/projectOps/workspace/2" target="_blank" rel="noopener noreferrer">
                            /projectOps/workspace
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_WS_1110-1
                    </th>
                    <td>
                        프로젝트 생성
                    </td>
                    <td>
                        <a href="/projectOps/addProject" target="_blank" rel="noopener noreferrer">
                            /projectOps/addProject
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_WS_1110-2
                    </th>
                    <td>
                        프로젝트 관리
                    </td>
                    <td>
                        <a href="/projectOps/modProject/1" target="_blank" rel="noopener noreferrer">
                            /projectOps/modProject/:prjId
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DTO_1110
                    </th>
                    <td>
                        데이터 목록
                    </td>
                    <td>
                        <a href="/monTASK/dataList" target="_blank" rel="noopener noreferrer">
                            /monTASK/dataList
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DTO_1111
                    </th>
                    <td>
                        주행기록 등록
                    </td>
                    <td>
                        <a href="/dataList/addDriving" target="_blank" rel="noopener noreferrer">
                            /dataList/addDriving
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DTO_1112
                    </th>
                    <td>
                        데이터 상세
                    </td>
                    <td>
                        <a href="/dataList/detailDataSet" target="_blank" rel="noopener noreferrer">
                            /dataList/detailDataSet
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DTO_1120
                    </th>
                    <td>
                        모니터링 TASK 목록
                    </td>
                    <td>
                        <a href="/monTASK/monTaskList" target="_blank" rel="noopener noreferrer">
                            /monTASK/monTaskList
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DTO_1121
                    </th>
                    <td>
                        모니터링 TASK 등록
                    </td>
                    <td>
                        <a href="/monTASK/addTask" target="_blank" rel="noopener noreferrer">
                            /monTASK/addTask
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DTO_1122_T1
                    </th>
                    <td>
                        Remote Diagnostic Service 대시보드
                    </td>
                    <td>
                        <a href="/monTASK/dctDashboard" target="_blank" rel="noopener noreferrer">
                            /monTASK/dctDashboard
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DTO_1122_T2
                    </th>
                    <td>
                        Motor Output Anomaly Detection 상세
                    </td>
                    <td>
                        <a href="/monTASK/moaDetection" target="_blank" rel="noopener noreferrer">
                            /monTASK/moaDetection
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DTO_1122_T3
                    </th>
                    <td>
                        Edge Inference 모니터링
                    </td>
                    <td>
                        <a href="/monTASK/edgeInferencn" target="_blank" rel="noopener noreferrer">
                            /monTASK/edgeInferencn
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DTO_1122_T4
                    </th>
                    <td>
                        Auto Labeling 검토 상세
                    </td>
                    <td>
                        <a href="/monTASK/autoLabe" target="_blank" rel="noopener noreferrer">
                            /monTASK/autoLabe
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DTO_1122_T5-1<br>
                        CL_DTO_1122_T5-2<br>
                        CL_DTO_1122_T5-3
                    </th>
                    <td>
                        Vision 데이터 처리 통계(TAB 3개)
                    </td>
                    <td>
                        <a href="/monTASK/visionData" target="_blank" rel="noopener noreferrer">
                            /monTASK/visionData
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DTO_1122_T2-1
                    </th>
                    <td>
                        Vehicle Diagnostic Service 대시보드
                    </td>
                    <td>
                        <a href="/vehicleDashboard" target="_blank" rel="noopener noreferrer">
                            /vehicleDashboard
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DTO_1140
                    </th>
                    <td>
                        MLOps TASK 목록
                    </td>
                    <td>
                        <a href="/monTASK/mlopsList" target="_blank" rel="noopener noreferrer">
                            /monTASK/mlopsList
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DTO_1140-1
                    </th>
                    <td>
                        MLOps – Pipeline Log 모니터링
                    </td>
                    <td>
                        <a href="/monTASK/mlopsPiplog" target="_blank" rel="noopener noreferrer">
                            /monTASK/mlopsPiplog
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DTO_1141-1<br>
                        CL_DTO_1141-2<br>
                        CL_DTO_1141-3<br>
                        CL_DTO_1141-4
                    </th>
                    <td>
                        MLOps – Observation Log 모니터링(TAB 4개)
                    </td>
                    <td>
                        <a href="/monTASK/mlopsObslog" target="_blank" rel="noopener noreferrer">
                            /monTASK/mlopsObslog
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        -
                    </th>
                    <td>
                        Team 목록
                    </td>
                    <td>
                        <a href="/sysOps/teamList" target="_blank" rel="noopener noreferrer">
                            /sysOps/teamList
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_WS_1200
                    </th>
                    <td>
                        Team 생성
                    </td>
                    <td>
                        <a href="/sysOps/addTeam" target="_blank" rel="noopener noreferrer">
                            /sysOps/addTeam
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_WS_1200-1
                    </th>
                    <td>
                        Team 관리
                    </td>
                    <td>
                        <a href="/sysOps/modTeam/1" target="_blank" rel="noopener noreferrer">
                            /sysOps/modTeam/:teamId
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        -
                    </th>
                    <td>
                        Division 목록
                    </td>
                    <td>
                        <a href="/sysOps/teamList" target="_blank" rel="noopener noreferrer">
                            /sysOps/divisionList
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_WS_1300
                    </th>
                    <td>
                        Division 생성
                    </td>
                    <td>
                        <a href="/sysOps/addDivision" target="_blank" rel="noopener noreferrer">
                            /sysOps/addDivision
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_WS_3200-1
                    </th>
                    <td>
                        Division 관리
                    </td>
                    <td>
                        <a href="/sysOps/modDivision/1" target="_blank" rel="noopener noreferrer">
                            /sysOps/modDivision/:divsonId
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DOM_1100
                    </th>
                    <td>
                        Edge Device 관리 - 목록
                    </td>
                    <td>
                        <a href="/deviceNDelivery/edgeDeviceList" target="_blank" rel="noopener noreferrer">
                            /deviceNDelivery/edgeDeviceList
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DOM_1100
                    </th>
                    <td>
                        Edge Device 관리 - 목록
                    </td>
                    <td>
                        <a href="/deviceNDelivery/edgeDeviceList" target="_blank" rel="noopener noreferrer">
                            /deviceNDelivery/edgeDeviceList
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DOM_1110
                    </th>
                    <td>
                        신규 Edge Device 등록
                    </td>
                    <td>
                        <a href="/deviceNDelivery/addEddgeDevice" target="_blank" rel="noopener noreferrer">
                            /deviceNDelivery/addEddgeDevice
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        -
                    </th>
                    <td>
                        Edge Device 관리
                    </td>
                    <td>
                        <a href="/deviceNDelivery/modEddgeDevice/1" target="_blank" rel="noopener noreferrer">
                            /deviceNDelivery/modEddgeDevice/:edgeId
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DOM_1120-1<br>
                        CL_DOM_1120-2<br>
                        CL_DOM_1120-3
                    </th>
                    <td>
                        Edge Device 관리 – 상세
                    </td>
                    <td>
                        <a href="/deviceNDelivery/detailEdgeDevice/1" target="_blank" rel="noopener noreferrer">
                            /deviceNDelivery/detailEdgeDevice/:edgeId
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DOM_1200
                    </th>
                    <td>
                        Fleet 그룹 목록
                    </td>
                    <td>
                        <a href="/deviceNDelivery/FleetGroupList" target="_blank" rel="noopener noreferrer">
                            /deviceNDelivery/FleetGroupList
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DOM_1210
                    </th>
                    <td>
                        신규 Fleet 그룹 등록
                    </td>
                    <td>
                        <a href="/deviceNDelivery/AddFleetGroup" target="_blank" rel="noopener noreferrer">
                            /deviceNDelivery/AddFleetGroup
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>
                        CL_DOM_1220
                    </th>
                    <td>
                        Fleet 그룹 목록 – 상세
                    </td>
                    <td>
                        <a href="/deviceNDelivery/DetailFleetGroup" target="_blank" rel="noopener noreferrer">
                            /deviceNDelivery/DetailFleetGroup
                        </a>
                    </td>
                </tr>
            </table>
        </div>
        
    </div>
</template>
<script>
    export default {
        name: 'allView',
        components: {
            
        },
        data(){
            return{};
        },
        mounted() {
            
        }
    }
</script>
<style scoped lang="scss">
#allPage{
    padding: 80px;
    box-sizing: border-box;
    h1{
        font-size: 48px;
        font-weight: 600;
        letter-spacing: -2px;
        line-height: 60px;
    }
    .table_wrap{
        margin-top: 30px;
        table{
            .color_box{
                th{
                    background-color: #E5E9EF;
                    font-weight: 500;
                }
            }
            th, td{
                border: 1px solid #000;
                padding: 10px 30px;
                font-size: 20px;
                line-height: 28px;
                vertical-align: middle;
                a{
                    color: #00B4ED;
                    font-size: 20px;
                    font-weight: 500;
                }
            }
        }
    }
    
}
</style>