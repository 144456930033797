<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap" v-bind:style="prjData.prjId === '' ? '' : 'display:none'">
                <div class="breadcrumb">
                    <span>Home</span>
                    <span>프로젝트 운영</span>
                    <span>My 프로젝트(Home)</span>
                    <span class="active">프로젝트생성</span>
                </div>
                <h2>프로젝트 생성</h2>
            </div>
            <div class="title_wrap" v-bind:style="prjData.prjId === '' ? 'display:none' : ''">
                <div class="breadcrumb">
                    <span>Home</span>
                    <span>프로젝트 운영</span>
                    <span>My 프로젝트(Home)</span>
                    <span class="active">프로젝트관리</span>
                </div>
                <h2>프로젝트 관리</h2>
                <div class="title_btn">
                    <button type="button" v-if="isProjectManagerOrSM" class="btn_view_log" disabled>PRJ 수정 로그 보기</button>
                </div>
            </div> -->
            <BreadcrumbLayout :pageId="pageId" :subParams="bcParams" :checkRole="isProjectManagerOrSM"/>
            <div id="management">
                <div class="row_box">
                    <div class="data_key">Project Name</div>
                    <div class="data_val" v-bind:class="{ failure : dupPrjNm}">
                        <input type="text" name="prjNm" ref="prjNm" maxlength="50" v-model="prjNm" placeholder="입력해주세요.">
                        <div class="text_message" v-if="dupPrjNm">중복된 프로젝트명입니다.</div>
                        <div class="text_length">
                            <span>{{prjNmLen}}</span>/50
                        </div>
                    </div>
                </div>
                <div class="row_box">
                    <div class="data_key">AWS ID</div>
                    <div class="data_val" v-bind:class="{ failure : dupAwsId}">
                        <!-- <input type="text" name="awsId" ref="awsId" maxlength="12" @input="numberInput" v-model="awsId" placeholder="입력해주세요."> -->
                        <input type="text" name="awsId" ref="awsId" maxlength="50" v-model="awsId" placeholder="입력해주세요.">
                        <div class="text_message" v-if="dupAwsId">중복된 AWS ID입니다.</div>
                        <div class="text_length">
                            <span>{{awsIdLen}}</span>/50
                        </div>
                    </div>
                </div>
                <div class="row_box two_item">
                    <div>
                        <div class="data_key">Project Type</div>
                        <div class="data_val">
                            <select name="prjType" ref="prjType" :value="prjData.prjType" @change="setPrjType($event)">
                                <option value="" disabled selected>선택해주세요.</option>
                                <option v-for="(item, index) in prjTypeList" :key="index" :value="item.id">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <div class="data_key">Division / Team</div>
                        <div class="data_val">
                            <select name="divTeam" id="divTeam" :value="divTeamId" @change="setTeamId($event)">
                                <option value="" disabled selected>선택해주세요.</option>
                                <option v-for="(item, index) in divTeamList" :key="index" :value="item.divisionId+'|'+item.teamId">
                                    {{item.divisionName}} | {{item.teamName}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row_box textarea_item">
                    <div class="data_key">Project Description</div>
                    <div class="data_val">
                        <textarea name="desc" ref="desc" maxlength="200" v-model="desc" placeholder="입력해주세요."></textarea>
                        <div class="text_length">
                            <span>{{descLen}}</span>/200
                        </div>
                    </div>
                </div>
                <div class="row_box toggle_item">
                    <div class="data_key">Permission Setting</div>
                    <div class="data_val">
                        <ul>
                            <li>
                                원격제어
                                <div class="toggle_box">
                                    <input type="checkbox" name="remoteControl" id="remoteControl" value="remoteControl" v-model="permSetChecked">
                                    <label for="remoteControl"></label>
                                </div>
                            </li>
                            <li>
                                데이터 외부 공유
                                <div class="toggle_box">
                                    <input type="checkbox" name="externalShare" id="externalShare" value="externalShare" v-model="permSetChecked">
                                    <label for="externalShare"></label>
                                </div>
                            </li>
                            <li>
                                데이터 분석/MLOps 활용
                                <div class="toggle_box">
                                    <input type="checkbox" name="dataAnalyticsMLOps" id="dataAnalyticsMLOps" value="dataAnalyticsMLOps" v-model="permSetChecked">
                                    <label for="dataAnalyticsMLOps"></label>
                                </div>
                            </li>
                            <li>
                                외부 프레임워크 사용
                                <div class="toggle_box">
                                    <input type="checkbox" name="externalFramework" id="externalFramework" value="externalFramework" v-model="permSetChecked">
                                    <label for="externalFramework"></label>
                                </div>
                            </li>
                            <li>
                                자체 API 외부 송출
                                <div class="toggle_box">
                                    <input type="checkbox" name="externalFramework" id="externalAPI" value="externalAPI" v-model="permSetChecked">
                                    <label for="externalAPI"></label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row_box add_item">
                    <div class="data_key">
                        프로젝트 멤버
                    </div>
                    <div class="data_val active">
                        <div class="add_box table_list">
                            <div class="total_item">
                                <span>{{ mainMemberList.length }} </span> Members
                            </div>
                            <button class="btn_add_item" v-bind:style="divTeamId === '' ? 'display:none' : ''" @click="showModalMember()"></button>
                                <div class="none_item" v-bind:style="mainMemberList.length > 0 ? 'display:none' : ''">
                                    <div>프로젝트 멤버가 없습니다.</div>
                                    <button class="btn_add_item" v-bind:style="divTeamId === '' ? 'display:none' : ''" @click="showModalMember()">멤버 추가</button>
                                </div>
                                <div class="mando_table_wrap" v-bind:style="mainMemberList.length > 0 ? '' : 'display:none'">
                                    <table class="mando_table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div class="col_name">
                                                        Name
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="col_name">
                                                        Team
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="col_name">
                                                        Role
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="col_name">
                                                        Recent Login
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="col_name">
                                                        Email
                                                    </div>
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody id="mainMemberTbody">
                                            <tr v-for="(item, index) in mainMemberList" :key="index">
                                                <td>
                                                    <div class="profile_img_box">
                                                        <img src="https://d2rsr3zb8mvgil.cloudfront.net/images/member/member_sample.png" alt="">
                                                    </div>
                                                    <span class="user_name">{{item.name}}</span>
                                                    
                                                </td>
                                                <td>
                                                    {{item.teamName}}
                                                </td>
                                                <td>
                                                    <!-- <select name="" :id="'selectRoleId' + item.memberId" style="width: 200px;background-position: 170px;" :value="item.projectRoleId" @change="setPrjRole(item, $event)"> -->
                                                    <select name="" :id="'selectRoleId' + item.memberId" style="width: 200px;background-position: 170px;" :value="item.projectRoleId" @change="setPrjRole(item, $event)">
                                                        <option v-for="(item, index) in prjRoleList" :key="index" :value="item.id">
                                                            {{item.roleNm}}
                                                        </option>
                                                    </select>
                                                </td>
                                                <td>
                                                    {{item.recentLogin}}
                                                </td>
                                                <td>
                                                    {{item.email}}
                                                </td>
                                                <td>
                                                    <button type="button" class="btn_del" :data-index="item.memberId" @click="clickDelMemberList(item.memberId)"></button>
                                                </td>
                                                <td data-index="memberId" style="display:none">
                                                    {{item.memberId}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                           
                        </div>
                    </div>
                </div>
                <div class="row_box add_item">
                    <div class="data_key">
                        Edge Device
                    </div>
                    <div class="data_val active">
                        <div class="add_box table_list">
                            <div class="total_item">
                                <span>{{ mainEdgeDeviceList.length }} </span> device
                            </div>
                            <button class="btn_add_item" v-bind:style="divTeamId === '' ? 'display:none' : ''" @click="showModalDevice()"></button>
                            <div class="none_item" v-bind:style="mainEdgeDeviceList.length > 0 ? 'display:none' : ''">
                                <div>Edge Device가 없습니다.</div>
                                <button class="btn_add_item" v-bind:style="divTeamId === '' ? 'display:none' : ''" @click="showModalDevice()">Edge 추가</button>
                            </div>
                            <div class="mando_table_wrap" v-bind:style="mainEdgeDeviceList.length > 0 ? '' : 'display:none'">
                                <table class="mando_table">
                                    <colgroup>
                                        <col style="width: 45%;">
                                        <col style="width: 18%;">
                                        <col style="width: 15%;">
                                        <col style="width: 12%;">
                                        <col style="width: 10%;">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div class="col_name">
                                                    Edge ID
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Edge Group
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Edge Name
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Status
                                                </div>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in mainEdgeDeviceList" :key="index">
                                            <td>
                                                <div class="profile_img_box">
                                                    <img src="@/assets/images/common/vehicle_sample.svg" alt="">
                                                </div>
                                                <span class="user_name">{{item.edgeDeviceId}}</span>
                                            </td>
                                            <td>
                                                <div class="profile_img_box">
                                                    <img src="@/assets/images/common/gateway_sample.svg" alt="">
                                                </div>
                                                <span class="user_name">{{item.edgeDeviceGroup}}</span>
                                            </td>
                                            <td>
                                                {{item.edgeDeviceName}}
                                            </td>
                                            <td>
                                                {{item.status}}
                                            </td>
                                            <td>
                                                <button type="button" class="btn_del" :data-index="item.id" @click="clickDelEdgeList(item.id)"></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="save_box">
                    <button type="button" @click="showModalAlert()">취소</button>
                    <button type="button" class="mcbtn" @click="clickRegist()" :disabled="!requireCheck">저장</button>
                    <!-- 관리 페이지 -->
                    <!-- 추후 구현 -->
                    <!-- <button type="button" class="prj_del">프로젝트 삭제</button> -->
                </div>
            </div>
        </div>
    </div>
    <div id="modalWrap" ref="modalWrap" style="display: none;">
        <!-- 취소 -->
        <div class="radiusbox modal_contents message_alert message_modal" ref="modalAlert">
            <div class="message_box">
                변경 사항이 저장되지 않았습니다. <br>정말 취소하시겠습니까?
            </div>
            <button type="button" class="btn_check mcbtn" @click="goBackPage()">확인</button>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
        <!-- 맴버 등록 -->
        <div class="radiusbox modal_contents add_item_modal add_member" ref="modalMember" style="display : none;">
            <strong class="modal_title">
                프로젝트 멤버 추가
            </strong>
            <div class="filter_box">
                <input type="text" name="memberKeyword" ref="memberKeyword" @keydown.enter="clickSearchMember()" placeholder="이름, 팀, 이메일 주소 검색가능">
                <button type="button" class="btn_search" @click="clickSearchMember()"></button>
            </div>
            <div class="float_box table_list">
                <div class="select_box mando_table_wrap">
                    <table class="mando_table">
                        <colgroup>
                            <col style="width: 20%">
                            <col style="width: 20%">
                            <col style="width: 60%">
                        </colgroup>
                        <thead>
                                <tr>
                                    <th>
                                        <div class="all_chk">
                                            <input type="checkbox" id="allChkMember" v-model="allSelctMember">
                                            <label for="allChkMember"></label>
                                            <span>Name</span>  
                                        </div>
                                    </th>
                                    <th>Team</th>
                                    <th>E-mail</th>
                                </tr>
                            </thead>
                        <tbody id="memberTbody">
                            <tr v-for="(item, index) in memberRowData" :data-index="index" :key="index">
                                <td>
                                    <div class="chk_item">
                                        <input type="checkbox" :id="'MemberItem' + item.memberId" :value="item" v-model="unSelectMemberList">
                                        <label :for="'MemberItem' + item.memberId"></label>
                                        <span>{{item.name}}</span>
                                    </div>
                                </td>
                                <td>
                                    {{item.teamName}}
                                </td>
                                <td>
                                    {{item.email}}
                                </td>
                                <td style="display:none">
                                    {{ item.memberId }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="choose_box">
                    <div class="btn_wrap">
                        <button type="button" class="btn_add" @click="clickAddMember()">추가</button>
                        <button type="button" class="btn_add_del" @click="clickDelMember()">삭제</button>
                    </div>
                </div>
                <div class="select_box">
                    <div class="table_title">
                        <strong>선택 항목</strong>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <colgroup>
                                <col style="width: 20%">
                                <col style="width: 20%">
                                <col style="width: 60%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="all_chk">
                                            <input type="checkbox" id="allChkSelectedMember" v-model="allSelctedMember">
                                            <label for="allChkSelectedMember"></label>
                                            <span>Name</span>  
                                        </div>
                                    </th>
                                    <th>Team</th>
                                    <th>E-mail</th>
                                </tr>
                            </thead>
                            
                            <tbody id="selectMemberTbody">
                                <tr v-for="(item, index) in selectMemberRowData" :data-index="index" :key="index">
                                    <td>
                                        <div class="chk_item">
                                            <input type="checkbox" :id="'MemberSelectedItem' + item.memberId" :value="item" v-model="selectedMemberList">
                                            <label :for="'MemberSelectedItem' + item.memberId"></label>
                                            <span>{{item.name}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        {{item.teamName}}
                                    </td>
                                    <td>
                                        {{item.email}}
                                    </td>
                                    <td style="display:none">
                                        {{ item.memberId }}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr class="hover_none" v-bind:style="selectMemberRowData.length > 0 ? 'display:none' : ''">
                                    <td colspan="3">
                                        <div class="none_itme">
                                            <div class="message">
                                                프로젝트 멤버를 추가 해 주세요.
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="check_wrap">
                <button type="button" class="btn_check mcbtn" @click="clickCompleteMember()" v-bind:disabled="selectMemberRowData.length === 0">완료</button>   
            </div>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
        <!-- Edge Device 추가 -->
        <div class="radiusbox modal_contents add_item_modal add_edge_device" ref="modalDevice" style="display : none;">
            <strong class="modal_title">
                Edge Device 항목 추가
            </strong>
            <div class="filter_box">
                <input type="text" name="edgeKeyword" ref="edgeKeyword" @keydown.enter="clickSearchDevice()" placeholder="ID, 이름, 상태값 검색가능">
                <button type="button" class="btn_search" @click="clickSearchDevice()"></button>
            </div>
            <div class="float_box table_list">
                <div class="select_box mando_table_wrap">
                    <table class="mando_table">
                        <colgroup>
                            <col style="width: 60%;">
                            <col style="width: 20%">
                            <col style="width: 20%">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <div class="all_chk">
                                        <input type="checkbox" id="allChkEdge" v-model="allSelctEdge">
                                        <label for="allChkEdge"></label>
                                        <span>Edge ID</span>
                                    </div>
                                </th>
                                <th>Edge Name</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody id="edgeTbody">
                            <tr v-for="(item, index) in edgeDeviceRowData" :data-index="index" :key="index">
                                <td>
                                    <div class="chk_item">
                                        <input type="checkbox" :id="'EdgeItem' + item.id" :value="item" v-model="unSelectEdgeList">
                                        <label :for="'EdgeItem' + item.id"></label>
                                        <div class="text_box">
                                            <div class="group_path" data-index="edgeDeviceGroup">
                                                {{item.edgeDeviceGroup}}
                                            </div>
                                            <div class="edge_id" data-index="edgeDeviceId">
                                                {{item.edgeDeviceId}}
                                            </div> 
                                        </div>
                                    </div>
                                </td>
                                <td data-index="edgeDeviceName">{{item.edgeDeviceName}}</td>
                                <td data-index="status">{{item.status}}</td>
                                <td data-index="id" style="display:none">{{item.id}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="choose_box">
                    <div class="btn_wrap">
                        <button type="button" class="btn_add" @click="clickAddDevice()">추가</button>
                        <button type="button" class="btn_add_del" @click="clickDelDevice()">삭제</button>
                    </div>
                </div>
                <div class="select_box">
                    <div class="table_title">
                        <strong>선택 항목</strong>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <colgroup>
                                <col style="width: 60%">
                                <col style="width: 20%">
                                <col style="width: 20%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="all_chk">
                                            <input type="checkbox" id="allChkSelectedEdge" v-model="allSelctedEdge">
                                            <label for="allChkSelectedEdge"></label>
                                            <span>Edge ID</span>
                                        </div>
                                    </th>
                                    <th>Edge Name</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody id="selectEdgeTbody">
                                <tr v-for="(item, index) in selectEdgeDeviceRowData" :data-index="index" :key="index">
                                    <td>
                                        <div class="chk_item">
                                            <input type="checkbox" :id="'EdgeSelectedItem' + item.id" :value="item" v-model="selectedEdgeList">
                                            <label :for="'EdgeSelectedItem' + item.id"></label>
                                            <div class="text_box">
                                                <div class="group_path" data-index="edgeDeviceGroup">
                                                    {{item.edgeDeviceGroup}}
                                                </div>
                                                <div class="edge_id" data-index="edgeDeviceId">
                                                    {{item.edgeDeviceId}}
                                                </div> 
                                            </div>
                                        </div>
                                    </td>
                                    <td data-index="edgeDeviceName">{{item.edgeDeviceName}}</td>
                                    <td data-index="status">{{item.status}}</td>
                                    <td data-index="id" style="display:none">{{item.id}}</td>
                                </tr>
                                <tr class="hover_none" v-bind:style="selectEdgeDeviceRowData.length > 0 ? 'display:none' : ''">
                                    <td colspan="3">
                                        <div class="none_itme">
                                            <div class="message">
                                                Edge Device를 추가 해 주세요.
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="check_wrap">
                <button type="button" class="btn_check mcbtn" @click="clickCompleteDevice()" v-bind:disabled="selectEdgeDeviceRowData.length === 0">완료</button>   
            </div>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
        <!-- PRJ 수정 로그 -->
        <div class="modal_contents radiusbox log_filter_modal" style="display : none;">
            <strong class="modal_title">PRJ 수정 로그</strong>
            <div id="recentFilter">
                <div class="filter_box">
                    <div class="filter_list_box">
                        <!-- ListView script -->
                        <ul class="float_box">
                            <li>
                                <span>aaa</span> : <span>bbb</span>
                                <button class="btn_close_item"></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="list_wrap">
                <div class="table_list"> 
                    <div class="total_num">총 <span>1</span>항목</div>
                    <div class="filter_box">
                        <div class="date_filter">
                            <select name="datelist" style="width: 150px; background-position: 120px center;">
                                <option selected>기간선택</option>
                                <option value="a">1일</option>
                                <option value="b">7일</option>
                                <option value="c">14일</option>
                                <option value="d">30일</option>
                            </select>
                            <div class="date_period">
                                <div class="date_box">
                                    <span>2023-05-23</span> ~ <span>2023-05-23</span>
                                </div>
                                <button type="button"></button>
                            </div>
                            <button type="button" class="btn_send mcbtn">조회</button>
                        </div>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="col_name">
                                            Date time
                                            <button class="btn_filter"></button>    
                                        </div>
                                    </th>
                                    <th>
                                        <div class="col_name">
                                            User Name
                                            <button class="btn_filter"></button>    
                                        </div>
                                    </th>
                                    <th>
                                        <div class="col_name">
                                            Activity Code
                                            <button class="btn_filter"></button>    
                                        </div>
                                    </th>
                                    <th>
                                        <div class="col_name">
                                            Activity Log
                                            <button class="btn_filter"></button>    
                                        </div>
                                    </th>
                                </tr>
                                <tr id="filterWrap">
                                    <th class="filter_input">
                                        <div>
                                            <input type="text">
                                            <button class="btn_add"></button>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        2022/05/22 13:07:46:00
                                    </td>
                                    <td>
                                        김만도
                                    </td>
                                    <td>
                                        a123456
                                    </td>
                                    <td>
                                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque…
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2022/05/22 13:07:46:00
                                    </td>
                                    <td>
                                        김만도
                                    </td>
                                    <td>
                                        a123456
                                    </td>
                                    <td>
                                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque…
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="list_table_footer">
                        <!-- <button class="btn_list_delete"> 삭제 </button> -->
                        <div id="listPagination" class="list_pagination">
                            <button type="button" id="btnPrevious" disabled="disabled"></button>
                            <div id="listNum">
                                <button type="button">1</button>
                                <button type="button">2</button>
                                <button type="button">3</button>
                            </div>
                            <button type="button" id="btnNext"></button>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" class="btn_close"></button>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';

    import common from '@/assets/js/common'
    import { mapGetters } from 'vuex';
    export default {
        name: 'addProject',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            BreadcrumbLayout,
        },
        computed:{
            ...mapGetters(['getUserData']),

            prjNmLen() {
                return this.prjNm.length;
            },
            awsIdLen() {
                return  this.awsId.length;
            },
            descLen() {
                return this.desc.length;
            },

            requireCheck() {
                return this.manageRole && this.prjNmLen > 0 && this.awsIdLen > 0 && this.prjData.prjType !== '' && this.divTeamId !== '' && this.mainMemberList.length > 0;
            },

            allSelctMember: {
                //getter
                get: function() {
                    return this.memberRowData.length === this.unSelectMemberList.length;
                },
                //setter
                set: function(e) {
                    this.unSelectMemberList = e ? this.memberRowData : [];
                },
            },
            allSelctedMember: {
                //getter
                get: function() {
                    return this.selectMemberRowData.length === this.selectedMemberList.length;
                },
                //setter
                set: function(e) {
                    this.selectedMemberList = e ? this.selectMemberRowData : [];
                },
            },

            allSelctEdge: {
                //getter
                get: function() {
                    return this.edgeDeviceRowData.length === this.unSelectEdgeList.length;
                },
                //setter
                set: function(e) {
                    this.unSelectEdgeList = e ? this.edgeDeviceRowData : [];
                },
            },
            allSelctedEdge: {
                //getter
                get: function() {
                    return this.selectEdgeDeviceRowData.length === this.selectedEdgeList.length;
                },
                //setter
                set: function(e) {
                    this.selectedEdgeList = e ? this.selectEdgeDeviceRowData : [];
                },
            },
        },
        data(){
            const userData = ({});

            const divTeamId = "";
            const divisionId = "";
            const teamId = "";
            
            const prjNm = "";
            const awsId = "";
            const desc = "";

            const memberRowData = ([]);
            const selectMemberRowData= ([]);
            
            const edgeDeviceRowData = ([]);
            const selectEdgeDeviceRowData= ([]);

            // const prjData = ({});

            const dupPrjNmExCode="BEC-F0023";
            // const dupPrjNm = "";
            
            return{
                userData,

                divTeamId,
                divisionId,
                teamId,

                prjNm,
                awsId,
                desc,

                permSetChecked: [],

                prjTypeList: [],
                divTeamList: [],
                prjRoleList: [],
                
                memberRowData,
                selectMemberRowData,

                unSelectMemberList: [],
                selectedMemberList: [],
                mainMemberList: [],

                edgeDeviceRowData,
                selectEdgeDeviceRowData,

                unSelectEdgeList: [],
                selectedEdgeList: [],
                mainEdgeDeviceList: [],

                prjData: {
                    prjId: "",
                    prjType: "",
                },
                dupPrjNmExCode,

                originPrjNm: "",
                dupPrjNm: false,

                originAwsId: "",
                dupAwsId: false,

                isProjectManagerOrSM: false,

                // breadcrumb
                pageId: "project_addProject",
                bcParams: [],

                // role
                viewRole: false,
                manageRole: false,
            };
        },
        async mounted() {
            // Role
            this.setRole();

            // const prjId = this.$route.query.prjId;
            const prjId = this.$route.params.prjId;
            
            if(typeof prjId == "undefined" || prjId == null || prjId == "") {
                this.prjData['prjId'] = '';
                this.prjData['prjType'] = '';
                this.pageId = 'project_addProject';
            } else {
                this.prjData['prjId'] = prjId;
                this.setPrjInfo(prjId);
                this.pageId = 'project_moddProject';
                this.bcParams.push({
                    index: prjId
                });
            }
            

            this.setPrjTypeList(this.getPrjTypeData());
            this.setDivTeamList(this.getDivTeamData());
 
            const getPrjRoleList = this.getPrjRoleList();
            this.setPrjRoleList(getPrjRoleList);

            await this.checkAccess(prjId);
            
        },
        watch: {
            divTeamList() {
                const prjId = this.$route.params.prjId;
            
                if(typeof prjId == "undefined" || prjId == null || prjId == "") {
                    this.setDefaultMember();
                }
            }
        },
        methods:{
            async setRole() {
                const prjId = this.$route.params.prjId;
                const manageRole = await common.getUserRole('type2');
                const viewRole = await common.getUserRole('type2');

                if(typeof prjId !== "undefined" && prjId !== null && prjId !== "") {
                    const prjRole = await common.getPrjRole('type1', prjId);
                    this.manageRole = manageRole || prjRole;
                    this.viewRole = viewRole || prjRole;
                } else {
                    this.manageRole = manageRole;
                    this.viewRole = viewRole;
                }

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            // AWS ID 12자리 숫자
            numberInput(){
                this.awsId = this.awsId.replace(/\D/g, "");
            },

            async setDefaultMember() {
                const getCKUser = this.getUserData;
                const getUser = this.$store.state.user;
                if(getCKUser == undefined){
                    this.userData = getUser;
                }else{
                    this.userData = getCKUser;
                }

                const userNm = this.userData.name;
                const teamNm = this.userData.teamName;

                const divListSize = this.divTeamList.length;
                for ( let i = 0; i < divListSize; i++ ) {
                    const item = this.divTeamList[i];
                    if ( item.teamName === teamNm ) {
                        this.divTeamId = item.divisionId + '|' + item.teamId;
                        this.divisionId = item.divisionId;
                        this.teamId = item.teamId;
                        break;
                    }
                }

                const teamId = this.teamId;
                const getMemberList = await this.getMemberData(teamId);

                if ( typeof getMemberList !== 'undefined' && getMemberList !== null && getMemberList !== '' ) {
                    const memListSize = getMemberList.length;
                    for ( let i = 0; i < memListSize; i++ ) {
                        const item = getMemberList[i];
                        if ( item.name === userNm ) {
                            this.mainMemberList.push({
                                name: item.name,
                                teamName: item.teamName,
                                email: item.email,
                                memberId: item.id,
                                profileImage: item.profileImage,
                                recentLogin: item.lastLogin,
                                projectRoleId: 1
                            });

                            this.selectMemberRowData.push({
                                name: item.name,
                                teamName: item.teamName,
                                email: item.email,
                                memberId: item.id,
                                profileImage: item.profileImage,
                                recentLogin: item.lastLogin,
                                projectRoleId: 1
                            });
                            break;
                        }
                    }
                }
            },

            async setPrjInfo(prjId) {
                const prjInfo = await this.getPrjData(prjId);
                
                if(typeof prjInfo !== "undefined" && prjInfo !== null && prjInfo !== "") {
                    const project = prjInfo.project;
                    const memberList = prjInfo.member_list;
                    const edgeDeviceList = prjInfo.edge_device_list;

                    this.prjNm = project.project_name;
                    this.awsId = project.aws_id;
                    this.desc = project.project_description;

                    this.originPrjNm = project.project_name;
                    this.originAwsId = project.aws_id;

                    this.prjData['prjType'] = project.project_type_id;
                    
                    this.divTeamId = project.division_id + '|' + project.team_id;
                    this.divisionId = project.division_id;
                    this.teamId = project.team_id;
    
    
                    if ( project.remote_control === 'Y' ) {
                        this.permSetChecked.push('remoteControl');
    
                    }
                    
                    if ( project.data_sharing === 'Y' ) {
                        this.permSetChecked.push('externalShare');
    
                    }
                    
                    if ( project.data_mlops === 'Y' ) {
                        this.permSetChecked.push('dataAnalyticsMLOps');
    
                    }
                    
                    if ( project.external_framework === 'Y' ) {
                        this.permSetChecked.push('externalFramework');
    
                    }
                    
                    if ( project.external_api === 'Y' ) {
                        this.permSetChecked.push('externalAPI');
                    } 

                    if ( memberList !== null ) {
                        const mainMemberList = this.setMemberData(memberList);
                        mainMemberList.forEach(item => {
                            this.mainMemberList.push(item);
                            this.selectMemberRowData.push(item);
                        });
                    }
    
                    if ( edgeDeviceList !== null ) {
                        const mainEdgeDeviceList = this.setDeviceData(edgeDeviceList);
                        mainEdgeDeviceList.forEach(item => {
                            this.mainEdgeDeviceList.push(item);
                            this.selectEdgeDeviceRowData.push(item);
                        });
                    }
                }
            },

            async setPrjTypeList(prjTypeList){
                prjTypeList.then(list => {
                    const dataList = [];
                    list.forEach(element => {
                        dataList.push({
                            id: element.projectTypeId,
                            name: element.name,
                        });
                    });
                    this.prjTypeList = dataList;
                });
            },

            setDivTeamList(divTeamList){
                divTeamList.then(list => {
                    const dataList = [];
                    list.forEach(element => {
                        dataList.push({
                            divisionId: element.divisionId,
                            teamId: element.teamId,
                            divisionName: element.divisionName,
                            teamName: element.teamName
                        });
                    });
                    this.divTeamList = dataList;
                });
            },

            setPrjRoleList(prjRoleList){
                prjRoleList.then(list => {
                    const dataList = [];
                    list.forEach(element => {
                        dataList.push({
                            id: element.projectRoleId,
                            roleNm: element.projectRoleName,
                        });
                    });
                    this.prjRoleList = dataList;
                });
            },

            setPrjType(event) {
                this.prjData['prjType'] = event.target.value;
            },

            setTeamId(event) {
                this.divTeamId = event.target.value;

                const divTeam = this.divTeamId.split("|");
                this.divisionId = divTeam[0];
                this.teamId = divTeam[1];

                this.selectMemberRowData = ([]);
                this.selectEdgeDeviceRowData = ([]);
                this.mainMemberList = [];
                this.mainEdgeDeviceList = [];
            },

            setPrjRole(item, event) {
                const memberId = item.memberId;
                const roleId = event.target.value;

                this.mainMemberList.forEach(item => {
                    if ( item.memberId === memberId ) {
                        item.projectRoleId = roleId;
                    }
                });
            },

            setMemberTable(memberList) {
                memberList.then(list => {
                    const dataList = [];
                    list.forEach(element => {
                        dataList.push({
                            name: element.name,
                            teamName: element.teamName,
                            email: element.email,
                            memberId: element.id,
                            profileImage: element.profileImage,
                            recentLogin: element.recentLogin,
                            projectRoleId: 3
                        });
                    });
                    // this.memberRowData = dataList;
                    // this.memberRowData = common.deleteRowData(dataList, this.mainMemberList, 'memberId');
                    this.memberRowData = common.deleteRowData(dataList, this.selectMemberRowData, 'memberId');
                });
            },

            setMemberData(memberList) {
                const dataList = [];
                memberList.forEach(item => {
                    dataList.push({
                        name: item.name,
                        teamName: item.teamName,
                        email: item.email,
                        memberId: item.id,
                        profileImage: item.profileImage,
                        recentLogin: item.lastLogin,
                        projectRoleId: item.projectRoleId
                    });

                });
                return dataList;
            },

            setDeviceTable(deviceList){
                deviceList.then(list => {
                    const dataList = [];
                    list.forEach(element => {
                        dataList.push({
                            id: element.id,
                            edgeDeviceId: element.edge_device_id,
                            edgeDeviceGroup: element.edge_device_group,
                            edgeDeviceName: element.edge_device_name,
                            status: element.status,
                        });
                    });
                    // this.edgeDeviceRowData = dataList;
                    // this.edgeDeviceRowData = common.deleteRowData(dataList, this.mainEdgeDeviceList, 'id');
                    this.edgeDeviceRowData = common.deleteRowData(dataList, this.selectEdgeDeviceRowData, 'id');
                });
            },

            setDeviceData(deviceList){
                const dataList = [];
                deviceList.forEach(item => {
                    dataList.push({
                        id: item.id,
                        edgeDeviceId: item.edge_device_id,
                        edgeDeviceGroup: item.edge_device_group,
                        edgeDeviceName: item.edge_device_name,
                        status: item.status,
                    });

                });
                return dataList;
            },

            // ----- click
            async clickSearchMember() {
                const keyword = this.$refs.memberKeyword.value.toUpperCase();
                
                if(typeof keyword == "undefined" || keyword == null || keyword == "") {
                    const teamId = this.teamId;
                    const getMemberList = this.getMemberData(teamId);
                    this.setMemberTable(getMemberList);
                    
                } else {
                    this.setSearchMember(keyword);
                }
            },
            
            setSearchMember(keyword) {
                const searchRow = [];
    
                this.memberRowData.forEach(item => {
                    let name = common.getUpper(item['name']);
                    let teamName = common.getUpper(item['teamName']);
                    let email = common.getUpper(item['email']);

                    if ( name.includes(keyword) || teamName.includes(keyword) || email.includes(keyword) ) {
                        searchRow.push(item);
                    }
                });

                this.memberRowData = ([]);

                searchRow.forEach(item => {
                    this.memberRowData.push(item);
                });
            },

            clickAddMember() {
                if ( this.unSelectMemberList.length > 0 ) {
                    this.unSelectMemberList.forEach(item => {
                        this.selectMemberRowData.push(item);
                    });

                    this.memberRowData = common.deleteRowData(this.memberRowData, this.unSelectMemberList, "memberId");
                    this.unSelectMemberList = [];
                }
            },

            clickDelMember() {
                if ( this.selectedMemberList.length > 0 ) {
                    this.selectedMemberList.forEach(item => {
                        this.memberRowData.push(item);
                    });
                    
                    this.selectMemberRowData = common.deleteRowData(this.selectMemberRowData, this.selectedMemberList, "memberId");
                    this.selectedMemberList = [];

                    const keyword = this.$refs.memberKeyword.value.toUpperCase();
                    if ( typeof keyword != "undefined" && keyword != null && keyword != "" ) {
                        this.setSearchMember(keyword);
                    }
                }
            },
            
            clickCompleteMember() {
                this.mainMemberList = [];
                this.selectMemberRowData.forEach(item => {
                    this.mainMemberList.push(item);
                });
                this.closeModal();
            },

            clickDelMemberList(id) {
                let i = this.mainMemberList.length;
                while ( i-- ) {
                    let item = this.mainMemberList[i];
                    if ( item.memberId == id ) {
                        this.mainMemberList.splice(i, 1);
                        this.memberRowData.push(item);
                    }
                }
                let j = this.selectMemberRowData.length;
                while ( j-- ) {
                    let item = this.selectMemberRowData[j];
                    if ( item.memberId == id ) {
                        this.selectMemberRowData.splice(j, 1);
                    }
                }
            },

            async clickSearchDevice() {
                const keyword = this.$refs.edgeKeyword.value.toUpperCase();

                if(typeof keyword == "undefined" || keyword == null || keyword == "") {
                    const teamId = this.teamId;
                    const getDeviceList = this.getEdgeDeviceData(teamId);
                    this.setDeviceTable(getDeviceList);

                } else {
                    this.setSearchDevice(keyword);
                }
            },

            setSearchDevice(keyword) {
                const searchRow = [];
    
                this.edgeDeviceRowData.forEach(item => {
                    let edgeDeviceId = common.getUpper(item['edgeDeviceId']);
                    let edgeDeviceName = common.getUpper(item['edgeDeviceName']);
                    let status = common.getUpper(item['status']);

                    if ( edgeDeviceId.includes(keyword) || edgeDeviceName.includes(keyword) || status.includes(keyword) ) {
                        searchRow.push(item);
                    }
                });

                this.edgeDeviceRowData = ([]);

                searchRow.forEach(item => {
                    this.edgeDeviceRowData.push(item);
                });
            },
            
            clickAddDevice() {
                if ( this.unSelectEdgeList.length > 0 ) {
                    this.unSelectEdgeList.forEach(item => {
                        this.selectEdgeDeviceRowData.push(item);
                    });

                    this.edgeDeviceRowData = common.deleteRowData(this.edgeDeviceRowData, this.unSelectEdgeList, "id");
                    this.unSelectEdgeList = [];
                }
            },
            
            clickDelDevice() {
                if ( this.selectedEdgeList.length > 0 ) {
                    this.selectedEdgeList.forEach(item => {
                        this.edgeDeviceRowData.push(item);
                    });
                    
                    this.selectEdgeDeviceRowData = common.deleteRowData(this.selectEdgeDeviceRowData, this.selectedEdgeList, "id");
                    this.selectedEdgeList = [];

                    const keyword = this.$refs.edgeKeyword.value.toUpperCase();
                    if ( typeof keyword != "undefined" && keyword != null && keyword != "" ) {
                        this.setSearchDevice(keyword);
                    }
                }
            },

            clickCompleteDevice() {
                this.mainEdgeDeviceList = [];
                this.selectEdgeDeviceRowData.forEach(item => {
                    this.mainEdgeDeviceList.push(item);
                });
                this.closeModal();
            },
            
            clickDelEdgeList(id) {
                let i = this.mainEdgeDeviceList.length;
                while ( i-- ) {
                    let item = this.mainEdgeDeviceList[i];
                    if ( item.id == id ) {
                        this.mainEdgeDeviceList.splice(i, 1);
                        this.edgeDeviceRowData.push(item);
                    }
                }
                let j = this.selectEdgeDeviceRowData.length;
                while ( j-- ) {
                    let item = this.selectEdgeDeviceRowData[j];
                    if ( item.id == id ) {
                        this.selectEdgeDeviceRowData.splice(j, 1);
                    }
                }
            },


            async clickRegist() {
                if ( this.requireCheck === true ) {
                    const prjNm = this.$refs.prjNm.value;
                    if ( this.originPrjNm !== prjNm && this.prjNmLen > 0 ) {
                        const prjNmCheckParam = {
                            "projectName": prjNm,
                        };
                        const checkPrjNmResult = await this.checkPrjNm(prjNmCheckParam);

                        this.dupPrjNm = await this.getDupResult(checkPrjNmResult);
                        if ( this.dupPrjNm ) {
                            this.$refs.prjNm.focus();
                        }
                    }

                    const awsId = this.$refs.awsId.value;
                    if ( this.originAwsId !== awsId && this.awsIdLen > 0 ) {
                        /*const awsCheckParam = {
                            "awsId": awsId,
                        };*/
                        //const checkAwsIdResult = await this.checkAwsId(awsCheckParam);

                        //중복 AWS ID 로직 제거
                        //this.dupAwsId = await this.getDupResult(checkAwsIdResult);
                        // if ( this.dupAwsId ) {
                        //     this.$refs.awsId.focus();
                        // }
                    } else if ( this.awsIdLen ===0 ) {
                        this.dupAwsId = false;
                    }

                    if ( !this.dupPrjNm && !this.dupAwsId ) {
                        const edgeDeviceIds = [];
                        this.mainEdgeDeviceList.forEach(item => {
                            edgeDeviceIds.push(item.id);
                        });
        
                        const memberProjectRoles = [];
                        const mainMemberTbody = document.querySelectorAll("#mainMemberTbody > tr");
                        mainMemberTbody.forEach(trElement => {
                            const memberId = parseInt(trElement.querySelector('td[data-index="memberId"]').innerHTML);
                            const projectRoleId = parseInt(trElement.querySelector('select').value);
        
                            memberProjectRoles.push({
                                member_id: memberId,
                                project_role_id: projectRoleId
                            });
                        });
    
                        const postData = {
                            "name": this.$refs.prjNm.value,
                            "aws_id": this.$refs.awsId.value,
                            "type": this.$refs.prjType.value,
    
                            "division_id": this.divisionId,
                            "team_id": this.teamId,
    
                            "description": this.$refs.desc.value,
    
                            "remote_control": await this.getCheckYn(this.permSetChecked, 'remoteControl'),
                            "data_sharing": await this.getCheckYn(this.permSetChecked, 'externalShare'),
                            "data_mlops": await this.getCheckYn(this.permSetChecked, 'dataAnalyticsMLOps'),
                            "external_framework": await this.getCheckYn(this.permSetChecked, 'externalFramework'),
                            "external_api": await this.getCheckYn(this.permSetChecked, 'externalAPI'),
    
                            "edgeDeviceIds": edgeDeviceIds,
                            "memberProjectRoles": memberProjectRoles
                        };
    
                        let responseCode = "";
                        let prjId = this.prjData['prjId'];
                        if ( prjId != '' && prjId > 0) {
                            const response = await this.updateProject(prjId, postData);
                            try {
                                responseCode = response['data']['code'];
                            } catch (error) {
                                responseCode = 500;
                            }
                        } else {
                            const response = await this.addProject(postData);
                            try {
                                responseCode = response['data']['code'];
                                prjId = response['data']['data'];
                            } catch (error) {
                                responseCode = 500;
                            }
                        }

                        if ( responseCode === 200 || responseCode === "200" ) {
                            this.$router.push({ 
                                name: 'Workspace',
                                params: { index: prjId }
                            });
                        } 
                    }
                }

            },

            async getCheckYn(list, value) {
                let result = 'N';

                list.forEach(item => {
                    if ( item === value ) {
                        result = 'Y';
                    }
                });

                return result;
            },

            async getDupResult(response) {
                if ( typeof response !== "undefined" && response !== null && response !== "" ) {
                    const status = response.status;
                    const code = response.code;

                    if ( status === 200 && code === '200' ) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            },

            // ----- Modal
            async showModalMember(){
                const $modal = this.$refs.modalWrap;
                const $modalMember = this.$refs.modalMember;
                $modal.style.display = "block";
                $modalMember.style.display = "block";

                this.$refs.memberKeyword.value = "";

                const teamId = this.teamId;
                const getMemberList = this.getMemberData(teamId);

                this.selectMemberRowData = ([]);
                this.mainMemberList.forEach(item => {
                    this.selectMemberRowData.push(item);
                });

                this.setMemberTable(getMemberList);
            },
            async showModalDevice(){
                const $modal = this.$refs.modalWrap;
                const $modalDevice = this.$refs.modalDevice;
                $modal.style.display = "block";
                $modalDevice.style.display = "block";

                this.$refs.edgeKeyword.value = "";

                const teamId = this.teamId;
                const getDeviceList = this.getEdgeDeviceData(teamId);

                this.selectEdgeDeviceRowData = ([]);
                this.mainEdgeDeviceList.forEach(item => {
                    this.selectEdgeDeviceRowData.push(item);
                });

                this.setDeviceTable(getDeviceList);
            },
            showModalAlert(){
                const $modal = this.$refs.modalWrap;
                const $modalAlert = this.$refs.modalAlert;
                $modal.style.display = "block";
                $modalAlert.style.display = "block";
            },
            closeModal(){
                const $modal = this.$refs.modalWrap;
                const $modalDevice = this.$refs.modalDevice;
                const $modalMember = this.$refs.modalMember;
                const $modalAlert = this.$refs.modalAlert;
                $modal.style.display = "none";
                $modalDevice.style.display = "none";
                $modalAlert.style.display = "none";
                $modalMember.style.display = "none";
            },
            goBackPage(){
                const prjId = this.prjData['prjId'];
                if ( prjId != '' && prjId > 0) {
                    this.$router.push({ 
                        name: 'Workspace',
                        params: { index: prjId }
                    });
                } else {
                    this.$router.push('/home');
                }
            },

            async checkAccess(prjId) {
                const role = await this.getMemberRole();
                if(typeof prjId != "undefined" && prjId != null && prjId != '' && prjId != 0) {
                    console.log("프로젝트 관리");
                    const projectRole = await this.getProjectRole(prjId);

                    if(role != null && (role.role == 'SM'|| role.role == "Division Manager" || role.role == "Team Manager")) {
                        this.isProjectManagerOrSM = true;
                        return;
                    } else if(projectRole != null && projectRole.projectRole == "Manager") {
                        this.isProjectManagerOrSM = true;
                        return;
                    } else {
                        this.isProjectManagerOrSM = false;
                        alert("SM, 해당 프로젝트 PM만 접근 가능");
                        this.$router.push("/home");
                    }
                } else {
                    console.log("프로젝트 생성");

                    if(role.role == 'SM') {
                        return;
                    } else if(role.memberRole == "Division Manager" || role.memberRole == "Team Manager") {
                        return;
                    } else {
                        alert("SM, Project Manager만 접근 가능");
                        this.$router.push("/home");
                    }
                    
                }
            },

            // ----- API 
            async getPrjData(prjtId) {
                try {
                    const response = await common.apiGet('/project/info/'+prjtId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getPrjTypeData() {
                try {
                    const response = await common.apiGet('/project-type');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getDivTeamData() {
                try {
                    const response = await common.apiGet('/team/division-team');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getMemberData(teamId) {
                try {
                    const response = await common.apiGet(`/member/team/${teamId}/members`);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getEdgeDeviceData(teamId) {
                try {
                    const response = await common.apiGet('/edge-device/team/'+teamId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async checkPrjNm(postData) {
                try {
                    const response = await common.apiPost('/project/name-check', postData);
                    return response.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            // async checkAwsId(postData) {
            //     try {
            //         const response = await common.apiPost('/project/aws-id-check', postData);
            //         return response.data;
            //     } catch (error) {
            //         console.error('Error fetching data:', error);
            //     }
            // },
            
            async getPrjRoleList() {
                try {
                    const response = await common.apiGet('/project-role');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            
            async addProject(postData) {
                let response = "";
                try {
                    response = await common.apiPost('/project', postData);
                } catch (error) {
                    response = error.response;
                    console.error('Error posting data:', error);
                }
                return response;
            },

            async updateProject(prjtId, postData) {
                let response = "";
                try {
                    response = await common.apiPatch('/project/'+prjtId, postData);
                } catch (error) {
                    response = error.response;
                    console.error('Error posting data:', error);
                }
                return response;
            },

            async getMemberRole() {
                let response;
                try {
                    let url = `/member/check-role`;
                    response = await common.apiGet(url);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },
            async getProjectRole(projectId) {
                let response;
                try {
                    let checkProjectRoleUrl = `/member/check-project-role/${projectId}`;
                    response = await common.apiGet(checkProjectRoleUrl);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            }

        }

    }
</script>
<style scoped lang="scss">
#modalWrap{
    
    .log_filter_modal {
        .list_wrap {
            .table_list {
                .total_num{
                    padding-bottom: 20px;
                }
            }
        }
        #recentFilter{
            min-height: 70px;
            ul.float_box{
                margin-top: 0;
                height: auto;
            }
        }
        .table_list{
            .filter_box{
                width: 500px;
                position: absolute;
                top: 10px;
                right: 20px;
                .date_filter{
                    &::after{
                        display: none;
                    }
                }
            }
        }
    }
} 
    
    

</style>
