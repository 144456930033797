<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>시스템 운영</span>
                    <span class="active">Team 목록</span>
                </div>
                <h2>Team 목록</h2>
                <div class="title_btn">
                    <button type="button" v-if="checkUserRole" @click="goAddPage()" class="add_project bgicon">신규 Team 등록</button>
                </div>
            </div> -->
            <BreadcrumbLayout pageId="communication_notice" :checkRole="manageRole"/>
            <div class="list_wrap">
                <div id="listFilter" class="list_filter_wrap float_box" ref="listFilter">
                    <div class="filter_list_box" ref="filterListBox">
                        <ul class="float_box" ref="filterBox">
                            <li v-if="filterList.length === 0" style="padding-right: 20px;"><span>필터</span> : <span>전체</span></li>
                            <li v-for="(item, index) in filterList" :key="index" ref="filterItem">
                                <span>{{ item.keyName }}</span> : <span>{{ item.value }}</span>
                                <button class="btn_close" @click="removeFilter(index)"></button>
                            </li>
                        </ul>
                    </div>
                    <button class="btn_all_filter" ref="filterAllBtn" @click="filterToggle">전체보기</button>
                </div>
                <div class="table_list">
                    <div class="table_set">
                        <div class="cont_count">
                            <span ii="cont_num">{{dataList.length}}</span> Contents
                        </div>
                        <div id="recentFilter">
                            <div class="filter_box">
                                <div class="date_filter">
                                    <select v-if="viewRole" name="itemStatus" v-model="selectedValue" @change="selectStatusChange" style="width: 150px; background-position: 120px center;" >
                                        <option value='' selected>게시 상태</option>
                                        <option value="open">게시</option>
                                        <option value="unopen">게시안함</option>
                                    </select>
                                    <div class="date_period">
                                        <div class="date_box">
                                            <span :style="picStartTxtStyle" ref="picStartTxt">{{ picStartDate }}</span> ~ <span :style="picEndTxtStyle" ref="picEndTxt">{{picEndDate}}</span>
                                        </div>
                                        <button type="button" ref="datePicToggle" @click="datePicToggle()"></button>
                                        <div class="calender_box radiusbox" ref="calenderBox" style="display:none">
                                            <div class="start_date date_box" ref="startDateBox" @click="onDatePic('S')" style="display:block" >
                                                <VCalendarUi :dateInterface="'start'"/>
                                                <button type="button" @click="pickerNext()">다음</button>
                                            </div>
                                            <div class="end_date date_box" ref="endDateBox" @click="onDatePic('E')">
                                                <VCalendarUi :dateInterface="'end'" :minDate="picStartDate"/>
                                                <button type="button" @click="pickerPrev()">이전</button>
                                                <button type="button" class="mcbtn" @click="getDriList()">조회</button>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" class="btn_send mcbtn" @click="getDriList()">조회</button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <colgroup>
                                <col style="width: 15%;">
                                <col style="width: 50%;">
                                <col style="width: 15%;">
                                <col style="width: 20%;">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="col_name">
                                            No
                                        </div>
                                    </th>
                                    <th>
                                        <div class="col_name">
                                            제목
                                            <button class="btn_filter" @click="showFilter"></button>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="col_name">
                                            등록자
                                            <button class="btn_filter" @click="showFilter"></button>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="col_name">
                                            등록 일자
                                            <button class="btn_filter" @click="showFilter"></button>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="col_name">
                                            조회 수
                                            <button class="btn_filter" @click="showFilter"></button>
                                        </div>
                                    </th>
                                    <th v-if="viewRole" >
                                        <div class="col_name">
                                            게시 상태
                                        </div>
                                    </th>
                                </tr>
                                <tr id="filterWrap" ref="filterInputWrap">
                                    <template v-if="viewRole"> 
                                        <th class="filter_input"  v-for="(item,index) in columnId" :key="index">
                                            <div v-if="index === 0 || index === 4 || index === 5" class="input_box disable">
                                                <input type="text" disabled>
                                                <button class="btn_add" ref="btnAddfilter" @click="addFilter" :data-index="index"></button>
                                            </div>
                                            <div v-else class="input_box" :data-id="item.id">
                                                <input type="text" @focus="setActive"  @keydown.enter="addFilter" >
                                                <button class="btn_add" ref="btnAddfilter" @click="addFilter" :data-index="index"></button>
                                            </div>
                                        </th>
                                    </template>
                                    <template v-else> 
                                        <template v-for="(item,index) in columnId" :key="index">
                                            <th class="filter_input" v-if="index !== 5">
                                                <div v-if="index === 0 || index === 4" class="input_box disable">
                                                    <input type="text" disabled>
                                                    <button class="btn_add" ref="btnAddfilter" @click="addFilter" :data-index="index"></button>
                                                </div>
                                                <div v-else class="input_box" :data-id="item.id">
                                                    <input type="text" @focus="setActive"  @keydown.enter="addFilter" >
                                                    <button class="btn_add" ref="btnAddfilter" @click="addFilter" :data-index="index"></button>
                                                </div>
                                            </th>
                                        </template>
                                    </template>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="m_pointer" v-for="(item, index) in dataListSliced" :key="index" @click="goPage(item)" :class="{'impo_item' : item.important}">
                                    <td>{{item.index}}</td>
                                    <td v-if="item.important">
                                         <span class="important">중요</span> {{item.title}}
                                    </td>
                                    <td v-else class="normal">
                                         {{item.title}}
                                    </td>
                                    <td>{{item.registerName}}</td>
                                    <td>{{item.created}}</td>
                                    <td>{{item.views}}</td>
                                    <td v-if="viewRole">{{item.status}}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    <div class="list_table_footer">
                        <PaginationUi
                            :totalItems="totalItems"
                            :itemsPerPage="itemsPerPage"
                            @page-changed="onPageChanged"
                        ></PaginationUi>
                    </div>
                </div>
            </div>
            <div id="modalWrap" ref="modalWrap" style="display: none;">
                <div class="modal_contents radiusbox detail_item" ref="modalDeail" style="display: none;">
                    <strong class="modal_title">공지</strong>
                    <div class="writer_info">
                        <div>
                            <span class="sub_title">작성자</span>
                            <span>{{listData.registerName}}</span>
                        </div>
                        <div>
                            <span class="sub_title">작성일</span>
                            <span>{{listData.created}}</span>
                        </div>
                    </div>
                    <div class="text_box">
                        <div v-if="listData.important" class="text_title">
                            <span class="important">중요</span> {{listData.title}}
                        </div>
                        <div v-else class="text_title">
                            {{listData.title}}
                        </div>
                        <div class="dsecription_box tiptap_text"  v-if="listData.fileNames.length > 0" v-html="listData.description"></div>
                        <div class="dsecription_box tiptap_text no_file" v-else v-html="listData.description"></div>
                        <div class="file_list" v-if="listData.fileNames.length > 0">
                            <span>총 {{listData.fileNames.length}}개의 파일</span>
                            <div class="file_list_box float_box">
                                <div class="file_item" v-for="(item, index) in listData.fileNames" :key="index">
                                    <a href ="#" @click="getFile(item)">
                                        {{item}}
                                        <span>다운로드</span>
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="save_box" v-if="viewRole">
                        <button type="button" class="delbtn" @click="itemDelete(listData.noticeId)">삭제</button>
                        <button type="button" class="mcbtn" @click="itemCorrection(listData.noticeId)">수정</button>
                    </div>
                    <button type="button" class="btn_close" @click="closeModal()"></button>
                </div>
                <!--
                <div class="modal_contents radiusbox detail_item">
                    <strong class="modal_title">공지</strong>
                    <div class="writer_info">
                        작성자
                        작성일
                    </div>
                    <div class="text_box">

                    </div>
                    <button type="button" class="btn_close" @click="closeModal()"></button>
                </div>
                -->
            </div>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import PaginationUi from "@/components/PagenationUi.vue";
    import VCalendarUi from '@/components/VCalendarUi.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';

    import '@/assets/js/filter';
    import gsap from 'gsap';
    import common from '@/assets/js/common'
    export default {
        name: 'teamList',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            PaginationUi,
            BreadcrumbLayout,
            VCalendarUi,
        },
        computed:{
            // datepic 
            picStartTxtStyle() {
                return {
                    color: this.picColor.startStatus ? this.picColor.active : this.picColor.default
                };
            },
            picEndTxtStyle() {
                return {
                    color: this.picColor.endStatus ? this.picColor.active : this.picColor.default
                };
            }
        },
        data(){
            const filterList = ([]);
            const filterRowData =([]);

            const picStartDate = null;
            const picEndDate = null;

            return{
                
                // -- VCalendarUi / .date_period
                picStartDate,
                picEndDate,
                picColor: {
                    startStatus: false,
                    endStatus: false,
                    active: '#00B4ED',
                    default: '#020A0A'
                },
                // ---
                filterList,
                filterRowData,

                totalItems: 100,
                itemsPerPage: 10,
                currentPage: 1,

                dataList: [],
                dataListSliced: [],
                
                // 
                columnId:[
                    { id: "no" },
                    { id: "title" },
                    { id: "registerName" },
                    { id: "created" },
                    { id: "views" },
                    { id: "status" },
                ],
                isfilter: true,
                filterDataList:{
                    origin:{}
                },
                selectedValue: '',
                // role
                viewRole: false,
                manageRole: false,

                // listdata
                listData: {
                    noticeId: '',
                    registerId: '',
                    title: '',
                    description: '',
                    fileNames: [],
                    important: '',
                    status: '',
                    views: '',
                    created: '',
                    updated: '',
                    registerName: ''
                },
            };
        },

        mounted() {
            // Role
            this.setRole();

            this.setNoticeList();

            // set date 
            const vm = this;
            const stoday = new Date();
            stoday.setDate(stoday.getDate() - 1); 
            const syear = stoday.getFullYear();
            const smonth = stoday.getMonth() + 1;
            const sday = stoday.getDate();
            const start_formattedDate = syear + '-' + (smonth < 10 ? '0' : '') + smonth + '-' + (sday < 10 ? '0' : '') + sday;

            const etoday = new Date();
            const eyear = etoday.getFullYear();
            const emonth = etoday.getMonth() + 1;
            const eday = etoday.getDate();
            const end_formattedDate = eyear + '-' + (emonth < 10 ? '0' : '') + emonth + '-' + (eday < 10 ? '0' : '') + eday;
            
            vm.picStartDate = start_formattedDate;
            vm.picEndDate = end_formattedDate;
            window.selectDate.startDate = start_formattedDate;
            window.selectDate.endDate = end_formattedDate;

        },
        methods:{
            async setRole() {
                this.manageRole = await common.getUserRole('type3');
                
                this.viewRole = await common.getUserRole('type2');

                
            },

            async setNoticeList() {
                const dataList = await this.getNoticeList();

                if(typeof dataList !== "undefined" && dataList !== null && dataList !== "") {
                    this.dataList = dataList;
                }
                
                this.currentPage = 1;
                this.totalItems = this.dataList.length;
                this.displayDataPerPage(this.dataList);
                
            },

            async goPage(item){

                const itemData = await this.getDetailData(item.noticeId);
                this.listData = itemData;
                console.log(this.listData.fileNames.length);
                this.showModal();
                
            },

            onPageChanged(page) {
                this.currentPage = page;
                this.displayDataPerPage(this.dataList);

                
            },
            

            // ----- Modal
            showModal(){
                const $modal = this.$refs.modalWrap;
                const $modalAlert = this.$refs.modalDeail;
                $modal.style.display = "block";
                $modalAlert.style.display = "block";
                
            },
            closeModal(){
                const $modal = this.$refs.modalWrap;
                const $modalAlert = this.$refs.modalDeail;
                
                $modal.style.display = "none";
                $modalAlert.style.display = "none";
                
            },
            // column filter
            filterToggle(){
                const $listFilter = this.$refs.listFilter;
                const $filterBox = this.$refs.filterListBox;
                const $filterBtn = this.$refs.filterAllBtn;
                const hasOpenClass = $filterBox.classList.contains("open");
                if(hasOpenClass){
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.remove("open");
                            $filterBtn.classList.remove("active");
                            $filterBtn.innerText = '전체보기'
                        },
                        height: '70px',
                        duration: .3
                    });
                }else{
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.add("open");
                            $filterBtn.classList.add("active");
                            $filterBtn.innerText = '접기'
                        },
                        height: 'auto',
                        duration: .3
                    });
                }
                
            },
            async addFilter(event){
                
                const _this = event.target;
                const $thTag = _this.parentNode.parentNode;
                const $addBtn = $thTag.querySelector("button"); 
                const $input = $thTag.querySelector("input");
                // input 글자수
                const trimVal = $input.value.trim();

                // 컬럼 이름 가져오기
                const keyNameArr = _this.parentNode.parentNode.parentNode.parentNode.querySelector("tr:first-child").querySelectorAll(".btn_filter"); 
                let activeIndex = Array.from(keyNameArr).findIndex(element => element.classList.contains('active'));
                const keyName = String(keyNameArr[activeIndex-1].parentNode.textContent);                 


                const dataKeyArr = _this.parentNode.parentNode.parentNode.querySelectorAll('.input_box');
                const dataKeyindex = Array.from(dataKeyArr).findIndex(element => element.classList.contains('active'))
                const keyId = String(dataKeyArr[dataKeyindex].getAttribute('data-id')) 

                const obj = {};

                window.filterData.taget = _this.getAttribute('data-index')

                if(0 < trimVal.length){
                    obj["keyId"] = keyId;
                    obj["value"] = trimVal;
                    obj["keyName"] = keyName;
                    $input.value = "";
                    this.filterList.push(obj);
                    this.$refs.filterAllBtn.style.display = "block";
                    $addBtn.parentNode.classList.add("disable");
                    $addBtn.previousElementSibling.disabled = true;
                    this.filterAction();
                }
                
            },
            removeFilter(index) {
                const $filterItem = this.$refs.filterItem;
                const $filterInputWrap = this.$refs.filterInputWrap;
                const dataId = this.filterList[index].keyId.trim();
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).classList.remove("disable");
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).querySelector("input").disabled = false;
                this.filterList.splice(index, 1);
                if($filterItem.length <= 1){
                    this.$refs.filterAllBtn.style.display = "none";
                }
                this.filterAction(true);
            },
            setActive(event) {
                const _this = event.target;
                const $filterDiv = _this.parentNode.parentNode.parentNode.querySelectorAll("div");
                const $filterIcon = _this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                
                for(var i = 0 ; i < $filterDiv.length ; i ++){
                    $filterDiv[i].classList.remove("active");
                }
                _this.parentNode.classList.add("active");
                
                // filter icon 
                let activeIndex = Array.from($filterDiv).findIndex(element => element.classList.contains('active'));
                
                for(var j = 0 ; j < $filterIcon.length ; j ++){
                    $filterIcon[j].classList.remove("active");
                }
                $filterIcon[activeIndex].classList.add("active");

            },

            displayDataPerPage(rowData) {
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                this.dataListSliced =  rowData.slice(startIndex, endIndex);

                let index = startIndex + 1;
                let dataList = [];

                this.dataListSliced.forEach(item => {
                    let data = {
                        index: index,
                        noticeId: item.noticeId,
                        registerId: item.registerId,
                        registerName: item.registerName,
                        status: item.status,
                        title: item.title,
                        views: item.views,
                        important: item.important,
                        created: item.created
                    };
                    dataList.push(data);
                    index += 1;
                });
                this.dataListSliced.splice(0, this.dataListSliced.length);
                this.dataListSliced = dataList;
            },
            filterAction(remove){
                const vm = this;
                // 필터 리스트
                const _filterLsit =  vm.filterList;
                // 테이블 리스트
                let _tableList = vm.dataList;
                if(vm.isfilter){
                    vm.filterDataList.origin = _tableList;
                    vm.isfilter = false;
                }
                
                
                if(_filterLsit.length > 0){
                    const lodash = require('lodash');
                    
                    // 필터 삭제
                    if(remove){
                        let _filterDataListOrign = [];
                        vm.filterDataList.origin.forEach(item => {
                            _filterDataListOrign.push(item);
                        });

                        for( var j = 0 ; j < _filterLsit.length; j++){

                            const searchString = common.getUpper(_filterLsit[j].value);
                            const regexPattern = new RegExp(searchString);

                            // _tableList > 검색 1 리트스 > 검색 2 리스트

                            const keyid = _filterLsit[j].keyId;
                            const filteredData = lodash.filter(_filterDataListOrign, (item) => regexPattern.test(common.getUpper(item[keyid])));
                            vm.dataList = filteredData;
                            _filterDataListOrign = filteredData;
                        }
                    // 필터 추가
                    }else{
                        for( var i = 0 ; i < _filterLsit.length; i++){

                            const searchString = common.getUpper(_filterLsit[i].value);
                            const regexPattern = new RegExp(searchString);

                            // _tableList > 검색 1 리트스 > 검색 2 리스트
                            
                            const keyid = _filterLsit[i].keyId;
                            const filteredData = lodash.filter(_tableList, (item) => regexPattern.test(common.getUpper(item[keyid])));
                            vm.dataList = filteredData;
                        }
                    }
                    
                }else{
                    vm.dataList = vm.filterDataList.origin;
                }
                vm.currentPage = 1;
                vm.totalItems = vm.dataList.length;
                vm.displayDataPerPage(vm.dataList);
            },
            showFilter(event){
                const _this = event.target;
                
                const $filterBtn = _this.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                const $filterWrap = document.querySelector("#filterWrap");
                const $filterInput = $filterWrap.querySelectorAll(".filter_input");

                const hasShowClass = $filterWrap.classList.contains("show");
                const hasActiveClass = _this.classList.contains("active");
                
                for(var i = 0 ; i < $filterBtn.length ; i ++){
                    $filterBtn[i].classList.remove("active");
                }
                _this.classList.add("active");
                if(hasShowClass){
                    if(hasActiveClass){
                        $filterWrap.classList.remove("show");
                        _this.classList.remove("active");
                    }
                }else{
                    $filterWrap.classList.add("show");
                }
                let activeIndex = Array.from($filterBtn).findIndex(element => element.classList.contains('active'));
                // input 
                for(var j = 0 ; j < $filterInput.length ; j ++){
                    $filterInput[j].querySelector("div").classList.remove("active");
                }
                if(activeIndex > -1){
                    $filterInput[activeIndex].querySelector("div").classList.add("active");
                }
                
                
            },
            async itemDelete(itemID) {
                await this.delItem(itemID);

                window.location.reload();

            },
            async itemCorrection(itemID) {
                this.$router.push("/commu/addNotice?itemID="+itemID);
            },

            // ----- API 
            async getNoticeList(startDate, endDate, status) {
                try {
                    if(endDate !== undefined && startDate !== undefined && status !== undefined){
                        const response = await common.apiGet('/notice?start-date='+startDate+'&end-date='+endDate+'&status='+status);
                        return response.data.data;
                    }
                    else if(endDate !== undefined && startDate !== undefined){
                        const response = await common.apiGet('/notice?start-date='+startDate+'&end-date='+endDate);
                        return response.data.data;
                    }else if(status !== undefined){
                        const response = await common.apiGet('/notice?status='+status);
                        return response.data.data;
                    }else{
                        const response = await common.apiGet('/notice');
                        return response.data.data;
                    }
                    
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getDetailData(itemID) {
                try {
                    const response = await common.apiGet('/notice/'+itemID);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getFile(filename) {
                try {
                    const response = await common.apiGet(`/notice/file/${filename}`);

                    const data = await fetch(response.data);
                    const blob = await data.blob();

                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();

                    URL.revokeObjectURL(link.href);

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async delItem(itemId) {
                try {
                    await common.apiDelete('/notice/' + itemId);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            // 
            

            // -------- DATE PICKER ----
            datePicToggle(){
                const vm = this;
                const _display = vm.$refs.calenderBox.style.display;
                const _startDateBox = vm.$refs.startDateBox.style.display;
                if(_display == 'block'){
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'none';
                    vm.picColor.startStatus = false;
                    vm.picColor.endStatus = false;

                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'block';
                    if(_startDateBox == 'block'){
                        vm.picColor.startStatus = true;
                        vm.picColor.endStatus = false;
                    }else if( _startDateBox == 'none'){
                        vm.picColor.startStatus = false;
                        vm.picColor.endStatus = true;
                    }
                }
                
                
                
            },

            memAccordion(event){
                const _this = event.target;
                const hasShowClass = _this.classList.contains('show_list');
                const $listWrap = _this.parentNode.nextElementSibling;
                if(hasShowClass){
                    gsap.to($listWrap , {
                        onStart: () => {
                            _this.classList.remove("show_list");
                        },
                        height: 0,
                        duration: .3
                    });
                }else{
                    gsap.to($listWrap , {
                        onStart: () => {
                            _this.classList.add("show_list");
                        },
                        height: 'auto',
                        duration: .3
                    });
                }
            },
            async getDriList(){
                const vm = this;
                // this.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', this.picStartDate, this.picEndDate);
                // 켈린더 display처리
                const $calenderBox = vm.$refs.calenderBox;
                const $startDateBox = vm.$refs.startDateBox;
                const $endDateBox = vm.$refs.endDateBox;
             
                
                $calenderBox.style.display = 'none';
                $startDateBox.style.display = 'block';
                $endDateBox.style.display = 'none';
           
                
                vm.picColor.startStatus = false;
                vm.picColor.endStatus = false;


                const dataList = await vm.getNoticeList(vm.picStartDate, vm.picEndDate);

                if(typeof dataList !== "undefined" && dataList !== null && dataList !== "") {
                    vm.dataList = dataList;
                }
                
                vm.currentPage = 1;
                vm.totalItems = vm.dataList.length;
                vm.displayDataPerPage(vm.dataList);

                // vm.drivList = await vm.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', '2022-12-10', '2023-11-10');
                
                // console.log(vm.drivList);
                
            },
            onDatePic(flag){
                if(flag == 'S'){   
                    if(window.selectDate.startDate !== null ){
                        this.picStartDate = window.selectDate.startDate;
                    }
                    
                }else if(flag == 'E'){
                    if(window.selectDate.picEndDate !== null ){
                        this.picEndDate = window.selectDate.endDate;
                    }
                }
            },
            pickerNext(){
                this.$refs.startDateBox.style.display = 'none';
                this.$refs.endDateBox.style.display = 'block';

                this.picColor.startStatus = false;
                this.picColor.endStatus = true;
                
            },
            pickerPrev(){
                this.$refs.startDateBox.style.display = 'block';
                this.$refs.endDateBox.style.display = 'none';

                this.picColor.startStatus = true;
                this.picColor.endStatus = false;
            },

            // 
            async selectStatusChange(){
                const vm = this;
                const dataList = await vm.getNoticeList(undefined, undefined ,vm.selectedValue);

                if(typeof dataList !== "undefined" && dataList !== null && dataList !== "") {
                    vm.dataList = dataList;
                }
                
                vm.currentPage = 1;
                vm.totalItems = vm.dataList.length;
                vm.displayDataPerPage(vm.dataList);
            }
        }
    }
</script>
<style scoped lang="scss">
.list_wrap{
    position: relative;
    .table_list{
        margin-top: 110px;
        height: 715px;
    }
    .list_filter_wrap{
        position: absolute;
        top: -90px;
    }
    
}
.list_wrap .table_list .mando_table thead th .col_name{
        // min-width: auto !important;
        padding-right: 15px;
    }

.table_set {
    #recentFilter{
        width: 100%;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        .filter_box{
            width: 100%;
            height: 50px;
            .date_filter{
                top: 10px;
                right: 20px;
                select{
                    position: relative;
                    top: 0;
                    left: 0;
                }
                &::after{
                    display:none;
                }
            }
        }
    }
}
.mando_table{
    tr{
        td:nth-child(2){
            text-align: left;
        }
    }
    .impo_item{
        background: #FFF8EB;
        td:nth-child(2){
            font-weight: $bold;
        }
        .important{
            width: 40px;
            height :25px;
            display: inline-block;
            background-color: #F4514D; 
            border-radius: 5px;
            color : #fff;
            line-height: 25px;
            text-align:center;
            font-size: 13px;
            margin-right: 10px;
        }
    }
    .normal{
        padding-left: 74px;
    }
}
#modalWrap{
    .detail_item{
        width: 1200px;
        height: 680px;
        padding: 25px;
        box-sizing: border-box;
        .text_box{
            .dsecription_box{
                width: 100%;
                height: 290px;
                overflow-y: auto;
                padding-top: 20px;
                padding-bottom: 60px;
                &.no_file{
                    height: 390px;
                }
                
            }
        }
        .file_list{
            border-top: 1px solid #D5DAE3;
            padding-top: 20px;
            span{
                font-size: 15px;
                color: #6E7380;
                font-weight: $bold;
            }
            .file_list_box{
                height: 60px;
                overflow-y: auto;
            }
            .file_item{
                width: 504px;
                height: 50px;
                box-sizing: border-box;
                background-color: #F2F4F7;
                border: 1px solid #B9BFCB;
                border-radius: 10px;
                float: left;
                position: relative;
                margin-bottom: 10px;
                &:nth-child(2n){
                    margin-left: 50px;
                }
                a{
                    display: block;
                    width: 100%;
                    height: 100%;
                    font-weight: $bold;
                    line-height: 50px;
                    padding: 0 30px;
                    box-sizing: border-box;
                    span{
                        display: block;
                        padding-left: 25px;
                        position: absolute;
                        top: 14px;
                        right: 20px;
                        font-size: 13px;
                        font-weight: 300;
                        background-repeat: no-repeat;
                        background-position: 5px center;
                        background-image: url($baseURL + "common/btnicon_download_gr.svg" );
                    }
                }
            }
        }
    }
    .writer_info{
        width: 100%;
        height: 20px;
        &>div{
            width: 400px;
            height: 100%;
            position: relative;
            display: inline-block;
            span{
                height: 100%;
                display: inline-block;
                line-height: 20px;
                &.sub_title{
                    font-weight: $bold;
                }
                &:nth-child(1){
                    padding-right: 10px;
                    border-right: 1px solid #E5E9EF;
                    
                }
                &:nth-child(2){
                    padding-left: 10px;
                }
            }
        }
    }
    .text_box{
        width: 100%;
        height : 500px;
        border-radius: 10px;
        border: 1px solid #D5DAE3;
        margin: 20px 0 10px; 
        box-sizing: border-box;
        padding: 20px;
        .text_title{
            border-bottom: 1px solid #D5DAE3;
            padding-bottom : 20px;
            font-weight: $bold;
            .important{
                width: 40px;
                height :25px;
                display: inline-block;
                background-color: #F4514D; 
                border-radius: 5px;
                color : #fff;
                line-height: 25px;
                text-align:center;
                font-size: 13px;
                margin-right: 10px;
            }
        }
    }
    .save_box{
        text-align: right;
        .delbtn{
            width: 90px;
            height: 45px;
            border-radius: 10px;
            margin-right: 15px;
            border: 1px solid #F4514D;
            color: #F4514D;
        }
        .mcbtn{
            width: 90px;
            height: 45px;
            border-radius: 10px;
        }
    }
    
}
</style>