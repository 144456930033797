
<template>
    <div id="loginWrap">
      <div class="login_img_box">
        <div class="txt_box">
          <img src="@/assets/images/login/hlmandologo_wh.svg" alt="HL Mando logo">          
          <span>Our vision is your freedom.</span>
        </div>
      </div>
      <div class="login_form_box">
        <div>
          <strong>비밀번호 찾기</strong>
            <div class="input_box">
              <div id="account" class="float_box">
                <div class="input_title">계정</div>
                <input type="text" v-model="userAccont" class="input_txt" placeholder="이메일 형식의 ID 입력해 주세요.">
                <div class="text_length">
                    <span>{{userAccontLength}}</span>/50
                </div>
                <button class="chk_btn" type="button" @click="checkEmailExist(userAccont)" >ID 확인</button>
                <div v-if="emailExist" class="call_msg"> * 가입된 ID 입니다. </div>
              </div>
              <div id="email" class="float_box">
                <div class="input_title">이메일</div>
                <input type="email" v-model="email" class="input_txt" placeholder="이메일을 입력해 주세요.">
                <div class="text_length">
                    <span>{{emailLength}}</span>/50
                </div>
                <button class="chk_btn" type="button" @click="sendAuthCodeEmail(email)" >이메일 전송</button>
                <div v-if="sendEmail" class="call_msg"> * 이메일로 인증 코드를 전송했습니다.</div>
              </div>
              <div id="code" class="float_box">
                <div class="input_title">
                    코드 입력
                    <span>{{ resTimeData }}</span>
                </div>
                <input type="code" v-model="pwkey" class="input_txt" placeholder="이메일로 전송된 코드를 입력해 주세요.">
                <button class="chk_btn" type="button" @click="sendAuthCodeEmail(email)">재전송</button>
              </div>
              <div class="btn_wrap">
                <button type="button" class="backBtn" @click="showModalAlert()">취소</button>
                <button type="button"  @click="goToChangePWPage()">확인</button>
                
              </div>
            </div>
        </div>
      </div>
      
    </div>  
    <div id="modalWrap" ref="modalWrap" style="display: none;">
        <!-- 취소 -->
        <div class="radiusbox modal_contents message_alert message_modal" ref="modalAlert">
            <div class="message_box">
                변경 사항이 저장되지 않았습니다. <br>정말 취소하시겠습니까?
            </div>
            <button type="button" class="btn_check mcbtn" @click="goBackPage()">확인</button>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
    </div>
</template>
  
<script>
  import { mapState, mapActions } from 'vuex';
  import common from '@/assets/js/common';
  import axios from 'axios';
  import conf from '@/assets/js/conf';


  export default {
    name: 'LoginView',
    computed:{
      ...mapState(['user', 'isLoggedIn']),
      userAccontLength() {
        return this.userAccont.length;
      },
      emailLength() {
        return this.email.length;
      },

      requireCheck() {
        const idCheck = this.emailExist;
        const sendEmail = this.sendEmail;
        const codeMatch = this.pwKeyMatch;
        const accountIsValid = this.accountIsValid;
        const emailIsValid = this.emailIsValid;

        if(idCheck === true && sendEmail === true 
        && codeMatch === true && accountIsValid === true 
        && emailIsValid === true) {
          console.log("requireCheck true");
          return true;
        } else {
          console.log("requireCheck false");
          return false;
        }
      }
    },

    data(){
      const baseURL = conf.getBffBaseURL();

      return{
        url: baseURL,
        // url: 'http://localhost:8080',
        userAccont: "",
        email: "",
        pwkey: "",
        emailExist: false,
        sendEmail: false,
        pwKeyMatch: false,

        accountIsValid:false,
        emailIsValid:false,

        //========= timer
        timer: null,
        timerCounter: 300,
        resTimeData: '05:00',

        setCheck: "",
        invalidNumber: false
      };
    },
    async mounted() {
      await common.loginCheck();

      
      console.log("login page1");
     



      console.log(this.$store.state.user);
      console.log(this.user);

      //=======timer
      this.timerInit();
      if(this.timer != null) {
          this.timerStop();
          this.timer = null;
      }

    },
    methods:{
      ...mapActions(['login', 'logout']),

      // ----- Modal
      showModalAlert(){
          const $modal = this.$refs.modalWrap;
          const $modalAlert = this.$refs.modalAlert;
          $modal.style.display = "block";
          $modalAlert.style.display = "block";
      },
      closeModal(){
          const $modal = this.$refs.modalWrap;
          const $modalAlert = this.$refs.modalAlert;
          // const $modalMember = this.$refs.modalMember;
          $modal.style.display = "none";
          $modalAlert.style.display = "none";
          // $modalMember.style.display = "none";
      },
      goBackPage(){
          this.$router.push('/login');
      },
      
      //==== userAccount
      async checkEmailExist(email) {
        if(this.userAccont === null || this.userAccont === "" || 
        this.userAccontLength == 0 || this.userAccontLength > 50) {
          alert("유효하지 않은 이메일 형식입니다.");
          return;
        }

        let validation = (email).match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,);
        if(validation == null) {
          alert("유효하지 않은 이메일 형식입니다.");
          return;
        }

        const data = {
          email: email
        };

        const response = await this.postApi("/email/check", data);
        const exist = response.data;

        if(exist === true) {
          this.emailExist = true;
          this.accountIsValid = true;
        } else {
          alert("가입되지 않은 이메일입니다.");
          return;
        }
      },

      //==== send auth code
      async sendAuthCodeEmail(email) {
        if(this.email === null || this.email === "" || 
        this.emailLength == 0 || this.emailLength > 50) {
          alert("유효하지 않은 이메일 형식입니다.");
          return;
        }

        let validation = (email).match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,);
        if(validation == null) {
          alert("유효하지 않은 이메일 형식입니다.");
          return;
        }

        const data = {
          email: email
        };

        const response = await this.postApi("/email/password-auth-code", data);
        const sendMail = response.data;

        console.log(sendMail);

        if(sendMail === "ok") {
          this.sendEmail = true;
          this.emailIsValid = true;
          this.start();
          return;
        }
      },

      //==== verify auth code

      async verifyPwKey(pwkey) {
        if(pwkey === null || pwkey === "") {
          alert("인증 코드를 입력해주세요");
          return false;
        }

        const data = {
          userAccount: this.userAccont,
          email: this.email,
          authCode: pwkey
        }

        const response = await this.postApi("/email/verification/password-auth-code", data);
        const verifyPwkey = response.data;

        console.log(verifyPwkey);
        if(verifyPwkey === "ok") {
          this.pwKeyMatch = true;
          alert("인증 완료 되었습니다.");
          this.timerStop();
          return true;
        } else {
          alert("인증 코드가 일치하지 않습니다.");
          return false;
        }

      },

      async goToChangePWPage() {
        const pwKey = this.pwkey;
        console.log(pwKey);
         // data chack 
        console.log(this.emailExist);
        if(!this.emailExist){
           alert("이메일 인증을 진행 해주세요.");
        }
        else if(!this.sendEmail){
          alert("이메일 전송을 진행 해주세요.");
        }else{
          const checkPWKey = await this.verifyPwKey(pwKey);

          if(this.requireCheck === true && checkPWKey === true) {
            const pwCode = pwKey;
            const encoded = encodeURIComponent(pwCode);

            this.$router.push({
              name: 'Change',
              params: { data: encoded }
            });
          }

        }
        
      },

      //========== authcode Timer
      start() {
        this.timerInit();
        this.timer = setInterval(() => {
            this.timerCounter--;
            this.resTimeData = this.prettyTime();
            if(this.timerCounter <= 0) this.timerStop();
        }, 1000);
        return this.timer;
      },
      timerStop() {
          clearInterval(this.timer);
          this.timerCounter = 0;
          this.resTimeData = "05:00";

      },
      timerInit() {
          clearInterval(this.timer);
          this.timerCounter = 300;
          this.resTimeData = "05:00";

      },
      prettyTime() {
          let time = this.timerCounter / 60;
          let minutes = parseInt(time);
          let secondes = Math.round((time - minutes) * 60);
          return (
              minutes.toString().padStart(2,"0") + ":" + secondes.toString().padStart(2,"0")
          )
      },

      //======== Api
      async postApi(api,data) {
        try {
          const response = await axios.post(this.url + api, data);
          return response.data;

        } catch (error) {
          console.log("error : ", error);
        }
      },
    }
  
}




</script>
  
<style lang="scss" scoped>
    #loginWrap{
      min-width: 1440px;
      width: 100%;
      height: 100vh;
      &>div{
        float: left;
      }
      .login_img_box{
        width: 55%;
        height: 100%;
        background-image: url($baseURL+'login/login_background_1920.jpg');
        background-size: cover;
        background-position: center;
        position: relative;
        .txt_box{
          width: 268px;
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          &>span{
            width: 100%;
            color: $whiteColor;
            display: block;
            margin-top: 10px;
            font-family: $montserrat;
            font-size: $fontMenuTitle;
          }
        }
      }
      .login_form_box{
        width: 45%;
        height: 100%;
        background-color: $whiteColor; 
        position: relative;
        &>div{
          width: 440px;
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          strong{
            display: block;
            font-size: 30px;
            line-height: 35px;
            font-weight: $bold;
            margin-bottom: 11px;
          }
          span{
            color: $placeHolderColor;
            line-height: 18px;
          }
          .input_title{
            margin-bottom: 10px;
            text-align: left;
          }
          .input_txt{
            margin-bottom: 15px;
          }
          .text_length {
            white-space: nowrap;
            text-align: left;
            position: absolute;
            bottom: 68px;
            right: 10px;
            font-size: 17px;
            color: #A8A8A8;
            span{
                font-size: 17px;
                color: #A8A8A8;
            }
          }
          .float_box{
            position: relative;
            margin-bottom: 20px;
          }
          .call_msg{
            height: 40px;
            text-align: left;
            line-height: 39px;
          }
          .chk_btn{
            width:90px;
            height: 40px;
            line-height: 39px;
            border: 1px solid #DEDEDE;
            font-weight: 400;
            background-color: #F2F4F7;
            border-radius: 10px;
            float: right;
          }
          #account{
            margin-top: 40px;
            text-align: left;
          }
          #email{
            margin-top: 30px;
            text-align: left;
          }
          #password{
            margin-top: 30px;
            text-align: left;
          }
          .save_info{
            height: 22px;
            text-align: left;
            margin-top: 10px;
            span{
              display: inline-block;
              margin-left: 8px;
              position: relative;
              top: -5px;
              color: $blockColor;
            }
            a{
              float: right;
              position: relative;
              top: 3px;
              color: $placeHolderColor;
              text-decoration: underline;
            }
            &::after{
              content: "";
              display: block;
              clear: both;
            }
          }
        }
      }
      &::after{
        clear: both;
        content:"";
        display: block;
      }
      .btn_wrap{
        margin-top: 20px;
        button{
          width: 140px;
          height: 50px;
          font-size: 17px;
          font-weight: $bold;
          color: $whiteColor;
          text-align: center;
          box-sizing: border-box;
          background-color: $mainColor;
          border-radius: 10px 10px 10px 10px;
          margin:0 15px;
          &.backBtn{
            color: #020A0A;
            background-color: #fff;
            border: 1px solid #DEDEDE;
            font-weight: 400;
            background-color: #F2F4F7;
          }
        }
        a{
          color: $placeHolderColor;
          span{
            display: inline-block;
            color: $mainColor;
            text-decoration: underline;
            padding-left: 10px;
          }
        }
      }
    }
</style>
  