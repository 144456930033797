import axios from "axios";
// Vector Logger API
const axiosInstanceEdge = axios.create({
  baseURL: "https://0os4gwjp8h.execute-api.ap-northeast-2.amazonaws.com",
  headers: {
    "Content-Type": "application/json",
    // Include the JWT token in the Authorization header
    // Replace 'token' with the actual token you have stored
    "X-API-Key": "edge_key_1",
  },
});

const apiCallEdge = {
  get(url, params = {}) {
    return axiosInstanceEdge.get(url, { params });
  },
  post(url, data = {}) {
    return axiosInstanceEdge.post(url, data);
  },
  patch(url, data = {}) {
    return axiosInstanceEdge.patch(url, data);
  },
  delete(url) {
    return axiosInstanceEdge.delete(url);
  },
  put(url, data = {}) {
    return axiosInstanceEdge.put(url, data);
  },
};

export default apiCallEdge;
