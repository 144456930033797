<template>
    <div>
        <HeaderLayout/>
        <GlobalNavBar/>
        <div id="container">
            <div id="contents">
                <!-- <div class="title_wrap">
                    <div class="breadcrumb">
                        <span>Home</span>
                        <span>프로젝트 운영</span>
                        <span class="active">My 프로젝트(Home)</span>
                    </div>
                    <h2>프로젝트 워크스페이스</h2>
                    <div class="title_btn">
                        <button type="button" class="setting_project bgicon" v-if="checkProjectRole" @click="projectOpsButton">프로젝트 관리</button>
                    </div>
                </div> -->
                <BreadcrumbLayout pageId="project_workspace" :params="bcParams" :checkRole="manageRole"/>
                <div class="block_1">
                    <section class="float_box">
                        <h3>[PROJECT] 만도 프로젝트</h3>
                        <div class="pjt_id">{{ projectData.project_name }}</div>
                        <div class="aws_id">AWD ID : {{ projectData.aws_id }}</div>
                        <div class="left_box">
                            <div class="project_info float_box">
                                <div>
                                    <strong>프로젝트 설명</strong>
                                    <p id="projectDisc">
                                        {{ projectData.project_description }}
                                    </p>
                                </div>
                                <div>
                                    <div>
                                        <strong>프로젝트 타입</strong>
                                        <p id="projectType">
                                            {{ projectData.project_type }}
                                        </p>
                                    </div>
                                    <div>
                                        <strong>소속 Unit</strong>
                                        <p id="projectUnit">
                                            {{ projectData.division_name }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="project_statistics">
                                <strong>프로젝트 통계</strong>
                                <ul class="item_info float_box">
                                    <li id="wVisit">
                                        <span>주간 방문자 수</span>
                                        <span class="count_num">{{ statistics.weeklyVisitedCnt }}</span>
                                    </li>
                                    <li id="mVisit">
                                        <span>월간 방문자 수</span>
                                        <span class="count_num">{{ statistics.monthlyVisitedCnt }}</span>
                                    </li>
                                    <li id="wPrjIssue">
                                        <span>주간 PRJ 이슈</span>
                                        <span class="count_num">{{ statistics.weeklyPrjIssueCnt }}</span>
                                    </li>
                                    <li id="wTaskIssue">
                                        <span>주간 TASK 이슈</span>
                                        <span class="count_num">{{ statistics.weeklyTaskIssueCnt }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="right_box">
                            <div class="project_member">
                                <strong>프로젝트 인원</strong>
                                <div class="total_mem">총 <span>{{ listCount(member_list) }}</span>명</div>
                                <ul class="membet_item_wrap">
                                    <li class="membet_item">
                                        <div id="prjM"> Project Manager (총<span>{{ listCount(manager_list) }}</span>명) <button class="show_list" @click="memAccordion"></button></div>
                                        <ul class="member_list_wrap">               
                                            <li v-for="(item, index) in manager_list" :key="index">
                                                <div class="img_box">
                                                    <img :src="profileImage(item.profileImage)" alt="">
                                                </div>
                                                <div class="mbr_name">
                                                    {{item.name}}
                                                </div>
                                                <div class="mbr_email">
                                                    {{item.email}}
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="membet_item">
                                        <div id="prjR"> Project Researcher (총<span>{{ listCount(researcher_list) }}</span>명) <button class="show_list" @click="memAccordion"></button></div>
                                        <ul class="member_list_wrap">
                                            <li v-for="(item, index) in researcher_list" :key="index">
                                                <div class="img_box">
                                                    <img src="@/assets/images/common/member_sample.svg" alt="">
                                                </div>
                                                <div class="mbr_name">
                                                    {{item.name}}
                                                </div>
                                                <div class="mbr_email">
                                                    {{item.email}}
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="membet_item">
                                        <div id="prjO"> Project Observer (총<span>{{ observerCount(observer_list) }}</span>명) <button class="show_list" @click="memAccordion"></button></div>
                                        <ul class="member_list_wrap">
                                            <li v-for="(item, index) in observer_list" :key="index">
                                                <div class="img_box">
                                                    <img src="@/assets/images/common/member_sample.svg" alt="">
                                                </div>
                                                <div class="mbr_name">
                                                    {{item.name}}
                                                </div>
                                                <div class="mbr_email">
                                                    {{item.email}}
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="block_2 float_box">
                    <div class="left_box">
                        <section>
                            <ul class="list_tab float_box">
                                <li class="active" @click="tabshow(0)">
                                    <div>데이터 목록 <button><a href="/list/dataList"></a></button></div>
                                </li>
                                <li @click="tabshow(1)">
                                    <div>분석 TASK <button><a href="/list/taskList"></a></button></div>
                                </li>
                                <li @click="tabshow(2)">
                                    <div>MLOps TASK <button><a href="/list/mlopsList"></a></button></div>
                                </li>
                            </ul>
                            <div class="tab_data">
                                <div class="data_list_box mando_table_wrap active">
                                    <dataListNoneItemBox :dateInterface="'데이터 목록'" :prj="Number(projectId)" projectId="projectId" v-if="dataSetTableInVisible" />
                                    
                                    <table class="mando_table" v-if="dataSetTableVisible">
                                        <thead>
                                            <tr>
                                                <th v-for="(txt, index) in dataSetDef" :key="index">{{txt.field}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="m_pointer" v-for="(item, index) in data_set_list" :key="index" @click="skipTheDataList(item.id, projectId)">
                                                <td>{{index + 1}}</td>
                                                <td>{{item.edgeDeviceId}}</td>
                                                <td>{{item.edgeDeviceName}}</td>
                                                <td>{{item.projectName}}</td>
                                                <td>{{item.status}}</td>
                                                <td>{{item.recentDrivingRecord}}</td>
                                                <td>{{item.drivingRecordCount}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                
                                </div>
                                <div class="data_list_box mando_table_wrap">
                                    <dataListNoneItemBox :dateInterface="'분석 TASK'" :prj="Number(projectId)"  projectId="projectId" v-if="monitoringTaskTableInVisible"/>
                                    <table class="mando_table" v-if="monitoringTaskTableVisible">
                                        <thead>
                                            <tr>
                                                <th v-for="(txt, index) in monitoringTaskDef" :key="index">{{txt.field}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="m_pointer" v-for="(item, index) in monitoringTaskData" :key="index" @click="skipTheMonitoringTaskList(item.task_id, item.task_type, item.monitoring_task_type_id , item.team_id,projectId)">
                                                <td>{{index + 1}}</td>
                                                <td>{{item.task_type}}</td>
                                                <td>{{item.task_name}}</td>
                                                <td>2023-10-10 11:12:23</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="data_list_box mando_table_wrap">
                                    <dataListNoneItemBox :dateInterface="'MLOps TASK'" :prj="Number(projectId)"  projectId="projectId"  v-if="mlopsListTableInvisible"/>
                                    <table class="mando_table" v-if="mlopsListTableVisible">
                                        <thead>
                                            <tr>
                                                <th v-for="(txt, index) in mlopsTaskDef" :key="index">{{txt.field}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="m_pointer" v-for="(item, index) in mlopsTaskData" :key="index">
                                                <td @click="skipTheMlList($event,projectId, item)">{{index+1}}</td>
                                                <td @click="skipTheMlList($event,projectId, item)">{{item.pipeline_id == null ? '' : item.pipeline_id}}</td>
                                                <td @click="skipTheMlList($event,projectId, item)">{{item.model_version == null ? '' : item.model_version}}</td>
                                                <td @click="skipTheMlList($event,projectId, item)">{{item.recent_run_date == null ? '' : item.recent_run_date}}</td>
                                                <td class="piplog" @click="mlops_task_pipeline(projectId, item)">바로 가기 
                                                    <span  >
                                                        <img src="@/assets/images/common/external_link.svg" alt=""/>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="right_box">
                        <section>
                            <h3>Recent Project Activity</h3>
                            <div class="total_num">
                                (Today : <span>{{ listCount(recentProjectActivityDataSliced) }}</span> EA)
                            </div>
                            <button type="button" @click="showModal()" class="detail_btn">자세히 보기</button>
                            <div class="mando_table_wrap">
                                <table class="mando_table">
                                    <thead>
                                        <tr>
                                            <th v-for="(txt, index) in recentProjectActivityDef" :key="index">{{txt.field}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in recentProjectActivityDataSliced" :key="index">
                                            <td>{{index + 1}}</td>
                                            <td>{{item.date_time}}</td>
                                            <td>{{item.project_name}}</td>
                                            <td>{{item.user_name}}</td>
                                            <td>{{item.activity_code}}</td>
                                            <td style="text-align: left;">{{item.activity_log}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </div>
                    
            </div>
                <div id="modalWrap" ref="modalWrap" style="display: none;">
                    <div class="modal_contents radiusbox log_filter_modal">
                        <strong class="modal_title">Recent Project Activity</strong>
                        <div id="recentFilter">
                            <div class="filter_box">
                                <select name="a" style="width: 600px; background-position: 570px center;" v-model="selectProject">
                                    <option disabled selected value="">프로젝트를 선택해 주세요.</option>
                                    <option v-for="(item, index) in projectListData" :key="index" :value="item.id">
                                        {{ item.projectName }}
                                    </option>
                                </select>
                                <div class="date_filter">

                                    <select name="datelist"  @change="dateSelectChange" style="width: 150px; background-position: 120px center;" v-model="selectDate">
                                        <option :value="'1'" selected>1일</option>
                                        <option value="7">7일</option>
                                        <option value="14">14일</option>
                                        <option value="30">30일</option>
                                    </select>
                                    <div class="date_period">
                                        <div class="date_box">
                                            <span :style="picStartTxtStyle" ref="picStartTxt">{{ picStartDate }}</span> ~ <span :style="picEndTxtStyle" ref="picEndTxt">{{picEndDate}}</span>
                                        </div>
                                        <button type="button" ref="datePicToggle" @click="datePicToggle()"></button>
                                        <div class="calender_box radiusbox" ref="calenderBox" style="display:none">
                                            <div class="start_date date_box" ref="startDateBox" @click="onDatePic('S')" style="display:block" >
                                                <VCalendarUi :dateInterface="'start'"/>
                                                <button type="button" @click="pickerNext()">다음</button>
                                            </div>
                                            <div class="end_date date_box" ref="endDateBox" @click="onDatePic('E')">
                                                <VCalendarUi :dateInterface="'end'" :minDate="picStartDate"/>
                                                <button type="button" @click="pickerPrev()">이전</button>
                                                <button type="button" class="mcbtn" @click="getDriList()">검색</button>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" class="btn_send mcbtn" @click="getDriList()">조회</button>
                                </div>
                                
                                <div class="filter_list_box" ref="filterListBox">
                                    <ul class="float_box" ref="filterBox">
                                        <li v-if="filterList.length === 0" style="padding-right: 20px;"><span>필터</span> : <span>전체</span></li>
                                        <li v-for="(item, index) in filterList" :key="index" ref="filterItem">
                                            <span>{{ item.keyName }}</span> : <span>{{ item.value }}</span>
                                            <button class="btn_close_item" @click="removeFilter(index)"></button>
                                        </li>
                                    </ul>
                                </div>
                                <button style="display: none" class="btn_all_filter" ref="filterAllBtn" @click="filterToggle">전체보기</button>
                            </div>
                        </div>
                        <div class="list_wrap">
                            <div class="table_list"> 
                                <div class="total_num">총 <span>{{ listCount(recentProjectActivityData) }}</span>항목</div>
                                <div class="mando_table_wrap">
                                    <table class="mando_table">
                                        <thead>
                                            <tr>
                                                <th v-for="(item, index) in rpaColumnDefs" :key="index">
                                                    <div class="col_name">
                                                        {{item.field}}
                                                        <button class="btn_filter" @click="showFilter"></button>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr id="filterWrap" ref="filterInputWrap">
                                                <th class="filter_input" v-for="(item,index) in rpaColumnId" :key="index">
                                                    <div class="input_box" :data-id="item.id">
                                                        <input type="text" @focus="setActive"  @keydown.enter="addFilter" >
                                                        <button class="btn_add" ref="btnAddfilter" @click="addFilter" :data-index="index"></button>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr  v-for="(item, index) in rpaSliced" :key="index">
                                                <td>{{item.date_time}}</td>
                                                <td>{{item.project_name}}</td>
                                                <td>{{item.user_name}}</td>
                                                <td>{{item.activity_code}}</td>
                                                <td style="text-align: left;">{{item.activity_log}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="list_table_footer">
                                    <PaginationUi
                                        :totalItems="totalItems"
                                        :itemsPerPage="itemsPerPage"
                                        @page-changed="onPageChanged"
                                    ></PaginationUi>
                                    <!-- <button class="btn_list_delete"> 삭제 </button> -->
                                    <div id="listPagination" class="list_pagination">
                                        <!-- <button type="button" id="btnPrevious" disabled="disabled"></button> -->
                                        <div id="listNum">
                                            <!-- <button type="button" class="active">1</button> -->
                                            <!-- <button type="button">2</button> -->
                                            <!-- <button type="button">3</button> -->
                                        </div>
                                        <!-- <button type="button" id="btnNext"></button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" @click="closeModal()" class="btn_close"></button>
                    </div>
                </div>
            </div>
        </div>
        <FooterLayout/>
    </div>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';
    import gsap from 'gsap';
    import dataListNoneItemBox from '@/views/projectOps/ProjectDataListNoneItem.vue';

    import PaginationUi from '@/components/PagenationUi.vue';
    import VCalendarUi from '@/components/VCalendarUi.vue';
    import common from '@/assets/js/common';
    // import Cookies from 'js-cookie';
    // import axios from 'axios';

    export default {
        name: 'WorkspaceView',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            dataListNoneItemBox,
            VCalendarUi,
            PaginationUi,
            BreadcrumbLayout,

            // props:['index']
        },
        data(){
            const rPA= ([]);

            const columnDefs= ([
                { field: "No" },
                { field: "Data1" },
                { field: "Data2" },
                { field: "Data3" },
                { field: "Data4" },
                { field: "Data5" },
                { field: "Data6" },
                { field: "Data7" },
                { field: "Data8" },
            ])
            const dataSetDef= ([
                { field: "No" },
                { field: "Edge Device Id" },
                { field: "Edge Name" },
                { field: "Project Name" },
                { field: "Status" },
                { field: "Recent Driving Record" },
                { field: "Driving Record Count" }
            ])
            const monitoringTaskDef= ([
                { field: "No" },
                { field: "Task Type" },
                { field: "Task Name" },
                { field: "Last Visit" }
            ])
            const mlopsTaskDef= ([
                { field: "No" },
                { field: "Pipeline ID" },
                { field: "Model version" },
                { field: "Recent Run Date" },
                { field: "Pipeline Log보기" }
            ])
            const recentProjectActivityDef= ([
                { field: "No" },
                { field: "Date time" },
                { field: "Project Name" },
                { field: "User Name" },
                { field: "Activity Code" },
                { field: "Activity Log" }
            ])

            const picStartDate = null;
            const picEndDate = null;
            const dateSet = 1;
            return{

                //filter
                filterList:[],
                rpaColumnDefs: [
                    { field : "Date time" },
                    { field : "Project Name" },
                    { field : "User Name" },
                    { field : "Activity Code" },
                    { field : "Activity Log" }
                ],
                rpaColumnId:[
                    { id : "date_time " },
                    { id : "project_name" },
                    { id : "user_name" },
                    { id : "activity_code" },
                    { id : "activity_log" }
                ],
                isfilter: true,
                filterDataList:{
                    origin:{}
                },
                // -- VCalendarUi / .date_period
                picStartDate,
                picEndDate,
                picColor: {
                    startStatus: false,
                    endStatus: false,
                    active: '#00B4ED',
                    default: '#020A0A'
                },

                rPA,
                rpaSliced:[],

                selectProject:'',
                selectDate: "1",
                
                checkProjectRole: false,
                projectId:Number,
                columnDefs,
                rowData:[],
                dataSetDef,
                monitoringTaskDef,
                mlopsTaskDef,
                recentProjectActivityDef,
                //project 동적 데이터
                projectData:'',
                member_list:[],
                manager_list:[],
                researcher_list:[],
                observer_list:[],
                //data set 동적 데이터
                data_set_list:[],
                monitoringTaskData:[],
                mlopsTaskData:[],

                projectListData:[],
                recentProjectActivityData:[],
                recentProjectActivityDataSliced:[],
                dataSetTableInVisible: true,
                dataSetTableVisible: false,
                monitoringTaskTableInVisible: true,
                monitoringTaskTableVisible: false,
                mlopsListTableInvisible: true,
                mlopsListTableVisible: false,

                totalItems: 100,
                itemsPerPage: 10,
                currentPage: 1,

                dateSet,

                bcParams: [],

                statistics: {},
                // role
                viewRole: false,
                manageRole: false,
            };
        },

        async mounted() {
            // Role
            this.setRole();
        
            const vm = this;
            const stoday = new Date();
            stoday.setDate(stoday.getDate() - 1); 
            const syear = stoday.getFullYear();
            const smonth = stoday.getMonth() + 1;
            const sday = stoday.getDate();
            const start_formattedDate = syear + '-' + (smonth < 10 ? '0' : '') + smonth + '-' + (sday < 10 ? '0' : '') + sday;

            const etoday = new Date();
            const eyear = etoday.getFullYear();
            const emonth = etoday.getMonth() + 1;
            const eday = etoday.getDate();
            const end_formattedDate = eyear + '-' + (emonth < 10 ? '0' : '') + emonth + '-' + (eday < 10 ? '0' : '') + eday;

            vm.picStartDate = start_formattedDate;
            vm.picEndDate = end_formattedDate;
            window.selectDate.startDate = start_formattedDate;
            window.selectDate.endDate = end_formattedDate;
            //-------

            const $prjO = document.querySelector("#prjO");
            const $prjR = document.querySelector("#prjR");
            const $prjM = document.querySelector("#prjM");
            const $totalMem = document.querySelector(".total_mem");
            $prjO.querySelector("span").innerText = "0";
            $prjR.querySelector("span").innerText = "0";
            $prjM.querySelector("span").innerText = "0";

            $totalMem.querySelector("span").innerText = "0";
            
            this.projectId = this.getProjectId();


            await this.getProjectData();
            await this.monitoring_task_list();
            await this.mlops_task_list();
            await this.recent_project_activity_list();
            // await this.checkLoginUser();
            // this.projectInfo();

            // this.setPrjData();
            // this.recent_project_activity_list();
            
            this.bcParams = {prjId: this.projectId};

            // statistics 
            const statisticsData = await this.getStatistics();

            this.statistics = statisticsData;
            console.log(this.statistics);

            
        },
        computed: {
            picStartTxtStyle() {
                return {
                    color: this.picColor.startStatus ? this.picColor.active : this.picColor.default
                };
            },
            picEndTxtStyle() {
                
                return {
                    color: this.picColor.endStatus ? this.picColor.active : this.picColor.default
                };
            }
        },
        methods:{
            async setRole() {
                const prjId = this.$route.params.index;
                
                this.manageRole = ( await common.getUserRole('type2') || await common.getPrjRole('type3', prjId) );
                this.viewRole = ( await common.getUserRole('type1') || await common.getPrjRole('type1', prjId) );

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            datePicToggle(){
                const vm = this;
                const _display = vm.$refs.calenderBox.style.display;
                const _startDateBox = vm.$refs.startDateBox.style.display;
                // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
                // console.log(_display);
                console.log(vm.$refs.startDateBox);
                if(_display == 'block'){
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'none';
                    vm.picColor.startStatus = false;
                    vm.picColor.endStatus = false;

                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'block';
                    console.log(_startDateBox);
                    if(_startDateBox == 'block'){
                        vm.picColor.startStatus = true;
                        vm.picColor.endStatus = false;
                    }else if( _startDateBox == 'none'){
                        vm.picColor.startStatus = false;
                        vm.picColor.endStatus = true;
                    }
                }
            },
            async getDriList(){
                this.currentPage = 1;

                const vm = this;
                // this.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', this.picStartDate, this.picEndDate);
                // 켈린더 display처리
                const $calenderBox = vm.$refs.calenderBox;
                const $startDateBox = vm.$refs.startDateBox;
                const $endDateBox = vm.$refs.endDateBox;
             
                
                $calenderBox.style.display = 'none';
                $startDateBox.style.display = 'block';
                $endDateBox.style.display = 'none';
           
                
                vm.picColor.startStatus = false;
                vm.picColor.endStatus = false;

                console.log(vm.picStartDate);
                console.log(vm.picEndDate);

                await this.clickRPAButton();

                // vm.drivList = await vm.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', '2022-12-10', '2023-11-10');
                
                // console.log(vm.drivList);
                
            },
            onDatePic(flag){
                if(flag == 'S'){   
                    if(window.selectDate.startDate !== null ){
                        this.picStartDate = window.selectDate.startDate;
                    }
                    
                }else if(flag == 'E'){
                    if(window.selectDate.picEndDate !== null ){
                        this.picEndDate = window.selectDate.endDate;
                    }
                }
            },
            pickerNext(){
                this.$refs.startDateBox.style.display = 'none';
                this.$refs.endDateBox.style.display = 'block';

                this.picColor.startStatus = false;
                this.picColor.endStatus = true;
                console.log(this.picColor.endStatus);
                console.log(this.picColor.target);
                
            },
            pickerPrev(){
                this.$refs.startDateBox.style.display = 'block';
                this.$refs.endDateBox.style.display = 'none';

                this.picColor.startStatus = true;
                this.picColor.endStatus = false;
            },
            memAccordion(event){
                const _this = event.target;
                const hasShowClass = _this.classList.contains('show_list');
                const $listWrap = _this.parentNode.nextElementSibling;
                if(hasShowClass){
                    console.log("hasClass");
                    gsap.to($listWrap , {
                        onStart: () => {
                            _this.classList.remove("show_list");
                        },
                        height: 0,
                        duration: .3
                    });
                }else{
                    console.log("noClass");
                    gsap.to($listWrap , {
                        onStart: () => {
                            _this.classList.add("show_list");
                        },
                        height: 'auto',
                        duration: .3
                    });
                }
            },
            tabshow(index){
                const _this = document.querySelector(".list_tab").querySelectorAll("div");
                const $listBox = document.querySelector(".tab_data").querySelectorAll(".data_list_box");
                // const _this = event.target;
                for( var i = 0; i < _this.length; i++){
                    _this[i].parentNode.classList.remove("active");
                    $listBox[i].classList.remove("active");
                }
                _this[index].parentNode.classList.add("active");
                $listBox[index].classList.add("active");
            },
            showModal(){
                const $modal = this.$refs.modalWrap;
                $modal.style.display = "block";
                this.recent_project_activity_list_modal();
            },
            closeModal(){
                const $modal = this.$refs.modalWrap;
                $modal.style.display = "none";
            },

            //filter=========
            filterToggle(){
                const $listFilter = this.$refs.listFilter;
                const $filterBox = this.$refs.filterListBox;
                const $filterBtn = this.$refs.filterAllBtn;
                const hasOpenClass = $filterBox.classList.contains("open");
                if(hasOpenClass){
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.remove("open");
                            $filterBtn.classList.remove("active");
                            $filterBtn.innerText = '전체보기'
                        },
                        height: '70px',
                        duration: .3
                    });
                }else{
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.add("open");
                            $filterBtn.classList.add("active");
                            $filterBtn.innerText = '접기'
                        },
                        height: 'auto',
                        duration: .3
                    });
                }
                
            },
            removeFilter(index) {
                const $filterItem = this.$refs.filterItem;
                const $filterInputWrap = this.$refs.filterInputWrap;
                const dataId = this.filterList[index].keyId.trim();
                console.log(dataId);
                console.log($filterInputWrap.querySelector(`[data-id='${dataId}']`).classList);
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).classList.remove("disable");
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).querySelector("input").disabled = false;
                this.filterList.splice(index, 1);
                if($filterItem.length <= 1){
                    this.$refs.filterAllBtn.style.display = "none";
                }
                this.filterAction(true);
            },
            showFilter(event){
                const _this = event.target;
                
                const $filterBtn = _this.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                const $filterWrap = document.querySelector("#filterWrap");
                const $filterInput = $filterWrap.querySelectorAll(".filter_input");

                const hasShowClass = $filterWrap.classList.contains("show");
                const hasActiveClass = _this.classList.contains("active");
                
                for(var i = 0 ; i < $filterBtn.length ; i ++){
                    $filterBtn[i].classList.remove("active");
                }
                _this.classList.add("active");
                if(hasShowClass){
                    if(hasActiveClass){
                        $filterWrap.classList.remove("show");
                        _this.classList.remove("active");
                    }
                }else{
                    $filterWrap.classList.add("show");
                }
                let activeIndex = Array.from($filterBtn).findIndex(element => element.classList.contains('active'));
                // console.log(activeIndex);
                // input 
                for(var j = 0 ; j < $filterInput.length ; j ++){
                    $filterInput[j].querySelector("div").classList.remove("active");
                }
                // console.log($filterInput[activeIndex]);
                if(activeIndex > -1){
                    $filterInput[activeIndex].querySelector("div").classList.add("active");
                }
                
                
            },
            setActive(event) {
                const _this = event.target;
                const $filterDiv = _this.parentNode.parentNode.parentNode.querySelectorAll("div");
                const $filterIcon = _this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                
                for(var i = 0 ; i < $filterDiv.length ; i ++){
                    $filterDiv[i].classList.remove("active");
                }
                _this.parentNode.classList.add("active");
                
                // filter icon 
                let activeIndex = Array.from($filterDiv).findIndex(element => element.classList.contains('active'));
                
                for(var j = 0 ; j < $filterIcon.length ; j ++){
                    $filterIcon[j].classList.remove("active");
                }
                $filterIcon[activeIndex].classList.add("active");

            },
            async addFilter(event){
                console.log("addFilter");
                
                const _this = event.target;
                const $thTag = _this.parentNode.parentNode;
                const $addBtn = $thTag.querySelector("button"); 
                const $input = $thTag.querySelector("input");
                // input 글자수
                const trimVal = $input.value.trim();

                // 컬럼 이름 가져오기
                const keyNameArr = _this.parentNode.parentNode.parentNode.parentNode.querySelector("tr:first-child").querySelectorAll(".btn_filter"); 
                let activeIndex = Array.from(keyNameArr).findIndex(element => element.classList.contains('active'));
                const keyName = String(keyNameArr[activeIndex].parentNode.textContent);                 


                const dataKeyArr = _this.parentNode.parentNode.parentNode.querySelectorAll('.input_box');
                const dataKeyindex = Array.from(dataKeyArr).findIndex(element => element.classList.contains('active'))
                const keyId = String(dataKeyArr[dataKeyindex].getAttribute('data-id')) 

                const obj = {};
                console.log();
                // console.log(this.getAttribute('data-index'))
                // console.log($input);

                window.filterData.taget = _this.getAttribute('data-index')

                if(0 < trimVal.length){
                    obj["keyId"] = keyId;
                    obj["value"] = trimVal;
                    obj["keyName"] = keyName;
                    $input.value = "";
                    this.filterList.push(obj);
                    this.$refs.filterAllBtn.style.display = "block";
                    $addBtn.parentNode.classList.add("disable");
                    $addBtn.previousElementSibling.disabled = true;
                    this.filterAction();
                }
                
            },
            filterAction(remove){
                const vm = this;
                // 필터 리스트
                const _filterLsit =  vm.filterList;
                // 테이블 리스트
                let _tableList = vm.rPA;
                if(vm.isfilter){
                    vm.filterDataList.origin = _tableList;
                    vm.isfilter = false;
                }
                
                
                if(_filterLsit.length > 0){
                    
                    const lodash = require('lodash');
                    if(remove){
                        // 필터 삭제
                        for( var j = 0 ; j < _filterLsit.length; j++){

                            const searchString = _filterLsit[j].value;

                            const regexPattern = new RegExp(searchString);

                            // _tableList > 검색 1 리트스 > 검색 2 리스트

                            const keyid = _filterLsit[j].keyId;
                            const filteredData = lodash.filter(vm.filterDataList.origin, (item) => regexPattern.test(item[keyid]));
                            vm.rPA = filteredData;
                            vm.currentPage = 1;
                            vm.totalItems = vm.rPA.length;
                            vm.displayActivityLogInModal(vm.rPA);
                        }
                        
                    }else{
                        // 필터 추가
                        for( var i = 0 ; i < _filterLsit.length; i++){

                            const searchString = _filterLsit[i].value;

                            const regexPattern = new RegExp(searchString);

                            // _tableList > 검색 1 리트스 > 검색 2 리스트
                            
                            const keyid = _filterLsit[i].keyId;
                            const filteredData = lodash.filter(_tableList, (item) => {
                                return regexPattern.test(item[keyid]);
                            });
                            vm.rPA = filteredData;
                            vm.currentPage = 1;
                            vm.totalItems = vm.rPA.length;
                            vm.displayActivityLogInModal(vm.rPA);
                        }
                    }
                    console.log("필터 동작 시작");
                }else{
                    vm.rPA = vm.filterDataList.origin;
                    vm.currentPage = 1;
                    vm.totalItems = vm.rPA.length;
                    vm.displayActivityLogInModal(vm.rPA);
                }
                vm.currentPage = 1;
                vm.totalItems = vm.rPA.length;
                vm.displayActivityLogInModal(vm.rPA);
                console.log(vm.currentPage);
            },
            //=========

            // JK1 Steering 이상 진단
            getProjectId() {
                let prjtId = window.location.href;
                prjtId = prjtId.split("/");
                prjtId = prjtId[(prjtId.length - 1)];
                return prjtId;
            },

            async getData(url) {
                // const jwt = Cookies.get('jwt');
                try {
                    // const headers = {
                    //     'Authorization': `Bearer ${jwt}`
                    // }
                    // const response = await axios.get(`https://api.dlfe.dev.micosa.io/${url}`, {headers:headers});
                    const response = await common.apiGet(`/${url}`);
                    return response.data.data;
                } catch (error) {
                    console.error("error", error);
                }
            },

            async getDataWithParams(url, params) {
                // const jwt = Cookies.get('jwt');
                try {
                    // const headers = {
                    //     'Authorization': `Bearer ${jwt}`
                    // }
                    // const response = await axios.get(`https://api.dlfe.dev.micosa.io/${url}`, {
                    //     params: params,
                    //     headers: headers
                    // });
                    const response = await common.apiGet(`/${url}`, params);
                    return response.data.data;
                } catch (error) {
                    console.error("error", error);
                }
            },

            async getProjectData() {
                let response  = await this.getProject();
                if(response != null && response.data.status === 200) {
                    this.projectData = response.data.data;
                    this.member_list = this.projectData.member_list;

                    // console.log(this.projectData);

                    this.manager_list = this.member_list.filter(item => item.role == 'Manager');
                    this.researcher_list = this.member_list.filter(item => item.role == 'Researcher');
                    this.observer_list = this.member_list.filter(item => item.role == 'Observer');
                    console.log("observer_list length= "+this.observer_list.length);


                    
                    this.data_set_List(this.projectId);
                    this.monitoring_task_list(this.projectId);
                    // const switchOn = this.projectData.data_mlops;
                } else if(response.data.code === 'BEC-F0019') {
                    alert("본인이 속한 프로젝트만 조회 가능");
                    this.$router.push('/home');
                }
            },

            async checkLoginUser() {
                try {
                    const projectRole = await this.getProjectRole();

                    const role = await this.getMemberRole();

                    if(role != null && role.role == 'SM') {
                        this.checkProjectRole = true;
                    } else if(projectRole != null && projectRole.projectRole == "Manager") {
                        this.checkProjectRole = true;
                    } else {
                        this.checkProjectRole = false;
                    }
                } catch (error) {
                    console.log("checkRole error : ", error);
                }
            },

            profileImage(image) {
                if(image == null) return require('@/assets/images/common/member_sample.svg');
                else {
                    return image;
                }
            },

            async data_set_List(projectId) {
                try {
                    let url = `edge-device/data/project/${projectId}`

                    const response = await this.getData(url);
                    this.data_set_list = response;

                    console.log("data list");
                    this.data_set_list_visible(this.data_set_list.length); 
                } catch (error) {
                    console.log("data list error : ",error);
                }
            },
            
            //분석 TASK
            async monitoring_task_list() {
                try {
                    let url = `monitoring-task/project/${this.projectId}`;

                    const response = await this.getData(url);
                    this.monitoringTaskData = response;

                    // console.log(response);
                    this.monitoring_task_visible(this.monitoringTaskData.length);
                } catch (error) {
                    console.error("monitoring task list error : ",error);
                }
            },
            //MLOps TASK

            async mlops_task_list() {
                if(this.projectData.data_mlops == 'N') {
                    this.mlopsListTableInvisible = true;
                    this.mlopsListTableVisible = false;
                    return;
                }
                let bffUrl_awsId = `project/mlops-task/${this.projectId}`;
                let projectDataList = [];
                try {
                    const response_bff = await this.getData(bffUrl_awsId);
                    let projectData = response_bff;
                    projectDataList.push(projectData);

                    let dlDataList = [];

                    let dlUrl = '/rds/vehicle/ml_list'; 

                    const responseDlData = await common.apiDlGet(dlUrl);
                    dlDataList = responseDlData.data.queryResult.results;

                    let mlopsDataList = [];
                
                    for(let i = 0; i<projectDataList.length; i++) {
                        for(let j = 0; j<dlDataList.length; j++) {
                            let projectData = projectDataList[i];
                            let dlData = dlDataList[j];
                            
                            if(projectData.aws_id == dlData.task) {
                                mlopsDataList.push({
                                    domain : projectData.domain,
                                    project_name : projectData.project_name,
                                    pipeline_id : dlData.pipeline_id,
                                    recent_run_date : dlData.recent_date,
                                    model_version : dlData.model_version
                                });
                            }
                        }
                    }

                    this.mlopsTaskData = mlopsDataList;
                
                    this.mlopsListTableInvisible = false;
                    this.mlopsListTableVisible = true;

                    this.mlops_task_visible();
                } catch(error) {
                    console.error("error : " , error);
                    return;
                }

                
            },

            async recent_project_activity_list() {
                try {
                    const response = await this.getData(`activitylog/project/${this.projectId}`);
                    this.recentProjectActivityDataSliced = response.filter(item =>item.activity_log = item.activity_log.substring(0, 30) + " ...");
                    this.totalItems = this.recentProjectActivityDataSliced.length;
                } catch (error) {
                    console.log("recent activity log error : ", error);
                }
            },

            async recent_project_activity_list_modal() {
                try {
                    let response = await this.getData(`activitylog/project/${this.projectId}`);
                    this.recentProjectActivityData = response;
                    this.rPA = this.recentProjectActivityData;
                    this.totalItems = this.recentProjectActivityData.length;
                    this.currentPage = 1;
                    this.displayActivityLogInModal(this.rPA);
                } catch (error) {
                    console.log("recent project activity list modal error : ", error);
                }
                await this.getProjectListData();
            },

            displayActivityLogInModal(rpa) {
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                this.rpaSliced = rpa.slice(startIndex, endIndex);
                console.log(this.currentPage);
                console.log(this.rpaSliced);
            },

            async getProjectListData() {
                try {
                    let response = await this.getData("project");
                    this.projectListData = response;
                } catch (error) {
                    console.log("project list error : ", error);
                }
            },

            async data_set_list_visible(dataSetLength) {
                console.log("data_list_visibledata_list_visible")
                console.log(dataSetLength);
                if(dataSetLength != 0) {
                    this.dataSetTableInVisible = false;
                    this.dataSetTableVisible = true;
                } else {
                    this.dataSetTableInVisible = true;
                    this.dataSetTableVisible = false;
                }
            },
            async monitoring_task_visible(monitoringTaskListLength) {
                if(monitoringTaskListLength != 0) {
                    this.monitoringTaskTableInVisible = false;
                    this.monitoringTaskTableVisible = true;
                } else {
                    this.monitoringTaskTableInVisible = true;
                    this.monitoringTaskTableVisible = false;
                }
            },
            async mlops_task_visible() {
                if(this.projectData.data_mlops == 'N') {
                    this.mlopsListTableVisible = false;
                    this.mlopsListTableInvisible = true;
                } else {
                    this.mlopsListTableVisible = true;
                    this.mlopsListTableInvisible = false;
                }
            },
            
            //MLOps TASK pipeline external link
            mlops_task_pipeline(projectId, item) {
                let query= {prjId: encodeURIComponent(projectId), 
                        domain: encodeURIComponent(item.domain),
                        pipeId: encodeURIComponent(item.pipeline_id), 
                        modelVersion: encodeURIComponent(item.model_version)};

                console.log(projectId);
                try {
                    this.$router.push({
                        name: 'MlopsPiplog', query
                    });
                } catch(error) {
                    console.log(error);
                }
                
            },
            listCount(list) {
                if(list === null || typeof list == 'undefined' || list.length == 0) return 0;
                return list.length;
            },
            observerCount() {
                return this.observer_list.length;
            },
            onPageChanged(page) {
                this.currentPage = page;
                this.displayActivityLogInModal(this.rPA);
            },
            getProjectOptions() {
                const response = this.getData('project');
                this.projectOptions = response;
            },
            async clickRPAButton() {
                const projectId = this.selectProject == "" ? 0 : this.selectProject;
                const beforeDate = this.selectDate == '기간선택' ? null : this.selectDate;

                const params = {
                    date:beforeDate,
                    'start-date':this.picStartDate,
                    'end-date': this.picEndDate
                }

                try {
                    const response = await common.apiGet(`/activitylog/project/${projectId}`, params);
                    this.recentProjectActivityData = response.data.data;
                    this.rPA = this.recentProjectActivityData;
                    this.currentPage = 1;
                    this.totalItems = this.recentProjectActivityData.length;
                    this.displayActivityLogInModal(this.rPA);
                } catch (error) {
                    console.log("error in click recent activity log : ",error);
                }
            },
            //project role researcher, manager 인 경우 보여지는 버튼 (프로젝트 관리)
            projectOpsButton() {
                try {
                    this.$router.push({
                        name: 'modProject', params: { prjId : this.projectId }
                    });
                } catch(error) {
                    console.log('Error:', error);
                }
            },

            //data list, monitoring list, mlops list에서 바로 세부페이지로 이동
            skipTheDataList(edId, projectId) {
                console.log("skip the data list");

                const query = {
                    prjId: projectId,
                    edgeId: edId
                }
                try {
                    this.$router.push({ name: 'DetailDataSet', query });
                } catch(error) {
                    console.log(error);
                }
            },

            skipTheMonitoringTaskList(taskId, taskType, taskTypeId,teamId,projectId) {
                console.log("skip the monitoring task list");

                const query = {
                    prjId: projectId,
                    taskId: taskId,
                    teamId: teamId,
                    taskTypeId: taskTypeId
                }

                console.log(taskType);

                switch(taskType) {
                        case 'Remote Diagnostics': 
                            this.$router.push({
                                name: 'dctDashboard', query  });
                            break;
                        case 'Anomaly Detection': 
                            this.$router.push({
                                name: 'moaDetection' , query });
                            break;
                        case 'Vision Edge Inference Monitoring': 
                            this.$router.push({
                                name: 'EdgeInfer', query
                            });
                            break;
                        case 'Vision Auto Labeling Monitoring': 
                            this.$router.push({ name: 'AutoLabe', query });
                            break;
                        case 'Vision AI/ML Statistics': 
                            this.$router.push({ name: 'VisionData', query});
                            break;
                        case 'Smart Mining Dashboard':
                            this.$router.push({ name: 'SmartMiningDashboard', query});
                            break;
                    }

            },

            skipTheMlList(e, projectId, item) {
                let query= {prjId: encodeURIComponent(projectId), 
                        domain: encodeURIComponent(item.domain),
                        pipeId: encodeURIComponent(item.pipeline_id), 
                        modelVersion: encodeURIComponent(item.model_version)};

                if(!e.target.classList.contains('piplog')) {
                    this.$router.push({
                            name: 'MlopsObslog' , query });
                }
            },
            //=============================================================
            
            dateSelectChange() {
                switch(this.selectDate) {
                    case "7":
                        this.dateSet = 7;
                        break;
                    case "14":
                        this.dateSet = 14;
                        break;
                    case "30":
                        this.dateSet = 30;
                        break;
                    default:
                        this.dateSet = 1;
                        break;
                }

                const todayDate = new Date();

                todayDate.setDate(todayDate.getDate() - this.dateSet);
                    const year1 = todayDate.getFullYear();
                    const month1 = todayDate.getMonth() + 1;
                    const day1 = todayDate.getDate();
                    const formattedDate1 = year1 + '-' + (month1 < 10 ? '0' : '') + month1 + '-' + (day1 < 10 ? '0' : '') + day1;

                    this.picStartDate = formattedDate1;
                    window.selectDate.startDate = formattedDate1;
            },

            // ------ API
            async getMemberRole() {
                let response;
                try {
                    let url = `/member/check-role`;
                    response = await common.apiGet(url);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },


            async getProject() {
                let response;
                try {
                    response = await common.apiGet(`/project/workspace/${this.projectId}`);
                } catch (error) {
                    console.error("prj data error", error);
                    response = error.response;
                }
                return response;
            },

            async getStatistics() {
                let response;
                try {
                    response = await common.apiGet(`/project-statistic/${this.projectId}`);
                } catch (error) {
                    console.error("prj data error", error);
                    response = error.response;
                }
                return response.data.data;
            },
            
            async getProjectRole() {
                let response;
                try {
                    let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
                    response = await common.apiGet(checkProjectRoleUrl);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },

            
        }
    }
</script>
<style scoped lang="scss">
    .block_1{
        .float_box{
            position: relative;
            &>h3{
                font-weight: $bold;
            }
            .pjt_id{
                font-size: $fontSub;
                color: $mainColor;
                position: absolute;
                top: 0;
                left: 205px;
            }
            .aws_id{
                font-size: $fontContTitle;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        .left_box{
            width: 850px;
            float: left;
            
            .project_info{
                width: 100%;
                height: 200px;    
                background-color: $whiteColor;
                border-radius: 10px;
                box-shadow: 0px 0px 10px #002B6833;
                box-sizing: border-box;
                padding: 20px;
                &>div{
                    width: 50%;
                    box-sizing: border-box;
                    float: left;
                    padding-right: 20px;
                    &:first-child{
                        height: 160px;
                        border-right: 1px solid $line02Color;
                    }
                    &:last-child{
                        padding-left: 20px;
                        div{
                            height: 80px;
                        }
                    }
                }
                #projectDisc{
                    box-sizing: border-box;
                    // padding-right: 20px;
                }
            } 
            .project_statistics{
                width: 100%;
                height: 120px;
                background-color: $whiteColor;
                border-radius: 10px;
                box-shadow: 0px 0px 10px #002B6833;
                margin-top: 10px;
                box-sizing: border-box;
                padding: 20px;
                .item_info{
                    height: 50px;
                    li{
                        height: 100%;
                        float: left;
                        box-sizing: border-box;
                        padding-left: 88px;
                        border-right: 1px solid $line02Color;
                        background-repeat: no-repeat;
                        background-position: 20px center;
                        width: 25.5%;
                        span{
                            font-size: 13px;
                            color: #8A8F9C;
                        }
                        .count_num{
                            display: block;
                            font-size: 24px;
                            line-height: 29px;
                            font-family: $montserrat;
                            font-weight: 500;
                        }

                        &:nth-child(1){
                            width: 23.5%;
                            background-position-x: 0;
                            padding-left: 73px;
                            background-image: url($baseURL + "common/weekly_icon.svg");
                            .count_num{
                                color: #EA726F;
                            }
                        }
                        &:nth-child(2){
                            background-image: url($baseURL + "common/monthly_icon.svg");
                            .count_num{
                                color: #52B69C;
                            }

                        }
                        &:nth-child(3){
                            background-image: url($baseURL + "common/project_icon.svg");
                            .count_num{
                                color: #EABE6E;
                            }
                            
                        }
                        &:nth-child(4){
                            background-image: url($baseURL + "common/task_icon.svg");
                            border: none;
                            .count_num{
                                color: #4791FF;
                            }
                        }
                        
                    }
                }
            }
        }
        .right_box{
            width: 420px;
            float: left;
            .project_member{
                width: 100%;
                height: 330px;
                background-color: $whiteColor;
                border-radius: 10px;
                box-shadow: 0px 0px 10px #002B6833;
                margin-left: 10px;
                box-sizing: border-box;
                padding: 20px;
                position: relative;
                .total_mem{
                    font-size: $fontSub;
                    display: inline-block;
                    position: absolute;
                    top: 20px;
                    right: 15px;
                    color: #8A8F9C;
                    span{
                        color: $mainColor;
                        font-weight: $bold;
                        font-size: $fontSub;
                    }
                }
                .membet_item_wrap{
                    width: 100%;
                    height: 260px;
                    overflow-y: scroll;
                }
                .membet_item{
                    &>div{
                        padding: 14px 0 10px;
                        color: #6E7380;
                        font-weight: $bold;
                        position: relative;
                        button{
                            width: 38px;
                            height: 38px;
                            background-image: url($baseURL + "common/textbox_arrowdown.svg");
                            background-repeat: no-repeat;
                            background-position: center;
                            transform: rotate(90deg);    
                            position: absolute;
                            top: 2px;
                            right: 0;
                            &.show_list{
                                transform: rotate(-90deg);
                            }
                        }
                        span{
                            color: #6E7380;
                            font-weight: $bold;
                        }
                        &::after{
                            display: block;
                            content: "";
                            width: 380px;
                            height: 1px;
                            background-color: $line02Color;
                            position: absolute;
                            bottom: 0px;
                            left: -20px;
                        }
                    }
                }
                .member_list_wrap{
                    overflow: hidden;
                    li{
                        width: 100%;
                        height: 45px;
                        position: relative;
                        box-sizing: border-box;
                        padding-left: 60px;
                        margin: 14px 0 0;
                        .img_box{
                            position: absolute;
                            top: 2px;
                            left: 0;
                            width: 40px;
                            height: 40px;
                            border-radius: 44px;
                            overflow: hidden;
                            img{
                                width: 100%;
                            }
                        }
                        .mbr_name{
                            line-height: 25px;
                        }
                        .mbr_email{
                            color: $placeHolderColor;
                        }
                    }
                }
            }
            
        }
    }
    .block_2{
        margin-top: 20px;
        h3{
            padding-top: 10px;
        }
        &>div{
            width: 49%;
            float: left;
        }
        .left_box{
            margin-right: 1%;
            position: relative;
            top: -5px;
            section{
                &>div{
                    height: 290px;
                    background-color: $whiteColor;
                    border-radius: 0 10px 10px 10px;
                    box-shadow: 0px 0px 10px #002B6833;
                    box-sizing: border-box;
                }
                .list_tab{
                    width: 100%;
                    height: 43px;
                    li{
                        width: 160px;
                        height: 38px;
                        float: left;
                        margin-right: 5px;
                        border-radius: 10px 10px 0 0;
                        background-color: $whiteColor;
                        border: 1px solid $line01Color;
                        box-sizing: border-box;
                        border-bottom: none;
                        position: relative;
                        top: 5px;
                        overflow: hidden;
                        &::after{
                            width: 100%;
                            height: 100%;
                            content: "";
                            display: block;
                            position: absolute;
                            left: 0;
                            bottom: -100%;
                            box-shadow: 0px 0px 10px #002B6833;
                        }
                        &>div{
                            display: block;
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            line-height: 38px;
                            text-indent: -20px;
                            cursor: pointer;
                            border-radius: 10px;
                            // background-color: $whiteColor;
                            position: relative;
                            z-index: 1;
                            button{
                                width: 50px;
                                height: 34px;
                                background-image: url($baseURL + "common/external_link.svg");
                                background-repeat: no-repeat;
                                background-position: center;
                                position: absolute;
                                top: 0;
                                right: 0;
                                z-index: 5;
                                a{
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        &.active{
                            border: none;
                            box-shadow: 0px 0px 10px #a2c3f233;
                            overflow: visible;
                            &::before{
                                width: 100%;
                                height: 10px;
                                content: "";
                                display: block;
                                background-color: $mainColor;
                                position: absolute;
                                z-index: 0;
                                left: 0;
                                top: -4px;
                                border-radius: 5px;
                            }
                            &::after{
                                width: 100%;
                                height: 10px;
                                content: "";
                                display: block;
                                background-color: $whiteColor;
                                position: absolute;
                                left: 0;
                                bottom: -5px;
                                box-shadow: none;
                            }
                            &>div{
                                font-weight: $bold;
                                box-shadow: 0px 0px 10px #a2c3f233;
                                &::after{
                                    width: 100%;
                                    height: 10px;
                                    display: block;
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    border-radius: 5px;
                                    background-color: $whiteColor;
                                }
                            }
                        }
                    }
                }
                .tab_data{
                    overflow: hidden;
                    .data_list_box{
                        display: none;
                        &.active{
                            display: block;
                        }
                    }
                    .none_item{
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                        padding-top: 70px;
                        .content_box{
                            width: 250px;
                            height: 170px;
                            margin: 0 auto 0;
                            box-sizing: border-box;
                            text-align: center;
                            color: $placeHolderColor;
                            padding-top: 70px;
                            background-repeat: no-repeat;
                            background-position: center 0;
                            background-image: url($baseURL+'/common/alert_guide_list.svg');
                            .add_item{
                                color: $mainColor;
                                border: 1px solid $mainColor;
                                padding: 10px 20px 10px 40px;
                                margin-top: 30px;
                                border-radius: 10px;
                                box-sizing: border-box;
                                display: inline-block;
                                background-repeat: no-repeat;
                                background-position:20px center;
                                background-image: url($baseURL+'/common/add_15.svg');
                                background-color: #EDFBFF;
                                
                            }
                        }

                    }
                }
            }
            
        }
        .right_box{
            margin-left: 1%;
            section{
                .total_num{
                    position: absolute;
                    top: 10px;
                    left: 190px;
                    color: #6E7380;
                    span{
                        color: $mainColor;
                        font-weight: $bold;
                    }
                }
                &>.mando_table_wrap{
                    height: 290px;
                    background-color: $whiteColor;
                    border-radius: 10px;
                    box-shadow: 0px 0px 10px #002B6833;
                    box-sizing: border-box;

                }
                .detail_btn{
                    top: -2px;
                }
            }
        }
        
    }
    #modalWrap{
        .float_box{
            height: auto;
        }
        .mando_table_wrap{
            height: 450px;
        }
    }
</style>