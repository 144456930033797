<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap" v-bind:style="fleetGroupId === '' ? '' : 'display:none'">
                <div class="breadcrumb">
                    <span>장치/배포 관리</span>
                    <span>Fleet 그룹 목록</span>
                    <span class="active">신규 Fleet 그룹 등록</span>
                </div>
                <h2>신규 Fleet 그룹 등록</h2>
            </div>
            <div class="title_wrap" v-bind:style="fleetGroupId === '' ? 'display:none' : ''">
                <div class="breadcrumb">
                    <span>장치/배포 관리</span>
                    <span>Fleet 그룹 목록</span>
                    <span class="active">Fleet 그룹 관리</span>
                </div>
                <h2>Fleet 그룹 관리</h2>
            </div> -->
            <BreadcrumbLayout :pageId="pageId" :subParams="bcParams"/>
            <div id="management">
                <div class="row_box two_item">
                    <div>
                        <div class="data_key">Fleet Group 명</div>
                        <div class="data_val">
                            <input type="text" name="fleetGroupNm" ref="fleetGroupNm" maxlength="30" v-model="fleetGroupNm" placeholder="입력해주세요.">
                            <div class="text_length">
                                <span>{{fleetGroupNmLen}}</span>/30
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div class="data_key">등록자명</div>
                            <div class="data_val">
                                <input type="text" name="rgstNm" id="rgstNm" :value="rgstNm" disabled>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row_box two_item">
                    <div>
                        <div>
                            <div class="data_key">등록 일자</div>
                            <div class="data_val">
                                <input type="text" name="rgstDt" id="rgstDt" :value="rgstDt" disabled>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div class="data_key">소속</div>
                            <div class="data_val">
                                <input type="text" name="teamNm" id="teamNm" :value="teamNm" disabled>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row_box textarea_item">
                    <div class="data_key">Description</div>
                    <div class="data_val">
                        <textarea name="desc" ref="desc" maxlength="200" v-model="desc" placeholder="입력해주세요."></textarea>
                        <div class="text_length">
                            <span>{{descLen}}</span>/200
                        </div>
                    </div>
                </div>
                <div class="row_box add_item">
                    <div class="data_key">
                        Edge Device
                    </div>
                    <div class="data_val active">
                        <div class="add_box table_list">
                            <div class="total_item">
                                <span>{{ mainEdgeDeviceList.length }} </span> device
                            </div>
                            <button class="btn_add_item" @click="showModalDevice()"></button>
                            <div class="none_item" v-bind:style="mainEdgeDeviceList.length > 0 ? 'display:none' : ''">
                                <div>Edge Device가 없습니다.</div>
                                <button class="btn_add_item" @click="showModalDevice()">Edge 추가</button>
                            </div>
                            <div class="mando_table_wrap" v-bind:style="mainEdgeDeviceList.length > 0 ? '' : 'display:none'">
                                <table class="mando_table">
                                    <colgroup>
                                        <col style="width: 45%;">
                                        <col style="width: 18%;">
                                        <col style="width: 15%;">
                                        <col style="width: 12%;">
                                        <col style="width: 10%;">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div class="col_name">
                                                    Edge ID
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Edge Group
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Edge Name
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Status
                                                </div>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in mainEdgeDeviceList" :key="index">
                                            <td>
                                                <div class="profile_img_box">
                                                    <img src="@/assets/images/common/vehicle_sample.svg" alt="">
                                                </div>
                                                <span class="user_name">{{item.edgeDeviceId}}</span>
                                            </td>
                                            <td>
                                                <div class="profile_img_box">
                                                    <img src="@/assets/images/common/gateway_sample.svg" alt="">
                                                </div>
                                                <span class="user_name">{{item.edgeDeviceGroup}}</span>
                                            </td>
                                            <td>
                                                {{item.edgeDeviceName}}
                                            </td>
                                            <td>
                                                {{item.status}}
                                            </td>
                                            <td>
                                                <button type="button" class="btn_del" :data-index="item.id" @click="clickDelEdgeList(item.id)"></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="save_box">
                    <button type="button" @click="showModalAlert()">취소</button>
                    <button type="button" class="mcbtn" @click="clickRegist()" :disabled="!requireCheck">저장</button>
                </div>
            </div>
        </div>
    </div>
    <div id="modalWrap" ref="modalWrap" style="display: none;">
        <!-- 취소 -->
        <div class="radiusbox modal_contents message_alert message_modal" ref="modalAlert">
            <div class="message_box">
                변경 사항이 저장되지 않았습니다. <br>정말 취소하시겠습니까?
            </div>
            <button type="button" class="btn_check mcbtn" @click="goBackPage()">확인</button>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
        <!-- Edge Device 추가 -->
        <div class="radiusbox modal_contents add_item_modal add_edge_device" ref="modalDevice" style="display : none;">
            <strong class="modal_title">
                Edge Device 항목 추가
            </strong>
            <div class="filter_box">
                <input type="text" name="edgeKeyword" ref="edgeKeyword" @keydown.enter="clickSearchDevice()" placeholder="ID, 이름, 상태값 검색가능">
                <button type="button" class="btn_search" @click="clickSearchDevice()"></button>
            </div>
            <div class="float_box table_list">
                <div class="select_box mando_table_wrap">
                    <table class="mando_table">
                        <colgroup>
                            <col style="width: 60%;">
                            <col style="width: 20%">
                            <col style="width: 20%">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <div class="all_chk">
                                        <input type="checkbox" id="allChkEdge" v-model="allSelctEdge">
                                        <label for="allChkEdge"></label>
                                        <span>Edge ID</span>
                                    </div>
                                </th>
                                <th>Edge Name</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody id="edgeTbody">
                            <tr v-for="(item, index) in edgeDeviceRowData" :data-index="index" :key="index">
                                <td>
                                    <div class="chk_item">
                                        <input type="checkbox" :id="'EdgeItem' + item.id" :value="item" v-model="unSelectEdgeList">
                                        <label :for="'EdgeItem' + item.id"></label>
                                        <div class="text_box">
                                            <div class="group_path" data-index="edgeDeviceGroup">
                                                {{item.edgeDeviceGroup}}
                                            </div>
                                            <div class="edge_id" data-index="edgeDeviceId">
                                                {{item.edgeDeviceId}}
                                            </div> 
                                        </div>
                                    </div>
                                </td>
                                <td data-index="edgeDeviceName">{{item.edgeDeviceName}}</td>
                                <td data-index="status">{{item.status}}</td>
                                <td data-index="id" style="display:none">{{item.id}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="choose_box">
                    <div class="btn_wrap">
                        <button type="button" class="btn_add" @click="clickAddDevice()">추가</button>
                        <button type="button" class="btn_add_del" @click="clickDelDevice()">삭제</button>
                    </div>
                </div>
                <div class="select_box">
                    <div class="table_title">
                        <strong>선택 항목</strong>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <colgroup>
                                <col style="width: 60%">
                                <col style="width: 20%">
                                <col style="width: 20%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="all_chk">
                                            <input type="checkbox" id="allChkSelectedEdge" v-model="allSelctedEdge">
                                            <label for="allChkSelectedEdge"></label>
                                            <span>Edge ID</span>
                                        </div>
                                    </th>
                                    <th>Edge Name</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody id="selectEdgeTbody">
                                <tr v-for="(item, index) in selectEdgeDeviceRowData" :data-index="index" :key="index">
                                    <td>
                                        <div class="chk_item">
                                            <input type="checkbox" :id="'EdgeSelectedItem' + item.id" :value="item" v-model="selectedEdgeList">
                                            <label :for="'EdgeSelectedItem' + item.id"></label>
                                            <div class="text_box">
                                                <div class="group_path" data-index="edgeDeviceGroup">
                                                    {{item.edgeDeviceGroup}}
                                                </div>
                                                <div class="edge_id" data-index="edgeDeviceId">
                                                    {{item.edgeDeviceId}}
                                                </div> 
                                            </div>
                                        </div>
                                    </td>
                                    <td data-index="edgeDeviceName">{{item.edgeDeviceName}}</td>
                                    <td data-index="status">{{item.status}}</td>
                                    <td data-index="id" style="display:none">{{item.id}}</td>
                                </tr>
                                <tr class="hover_none" v-bind:style="selectEdgeDeviceRowData.length > 0 ? 'display:none' : ''">
                                    <td colspan="3">
                                        <div class="none_itme">
                                            <div class="message">
                                                Edge Device를 추가 해 주세요.
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="check_wrap">
                <button type="button" class="btn_check mcbtn" @click="clickCompleteDevice()" v-bind:disabled="selectEdgeDeviceRowData.length === 0">완료</button>   
            </div>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';

    import common from '@/assets/js/common'
    import { mapGetters } from 'vuex';
    export default {
        name: 'fleetGroupList',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            BreadcrumbLayout,
        },
        computed:{
            ...mapGetters(['getUserData']),
            
            fleetGroupNmLen() {
                return this.fleetGroupNm.length;
            },

            descLen() {
                return this.desc.length;
            },

            requireCheck() {
                return this.manageRole && this.fleetGroupNmLen > 0 && this.mainEdgeDeviceList.length > 0;
            },

            allSelctEdge: {
                //getter
                get: function() {
                    return this.edgeDeviceRowData.length === this.unSelectEdgeList.length;
                },
                //setter
                set: function(e) {
                    this.unSelectEdgeList = e ? this.edgeDeviceRowData : [];
                },
            },
            allSelctedEdge: {
                //getter
                get: function() {
                    return this.selectEdgeDeviceRowData.length === this.selectedEdgeList.length;
                },
                //setter
                set: function(e) {
                    this.selectedEdgeList = e ? this.selectEdgeDeviceRowData : [];
                },
            },
        },
        data(){
            const userData = ({});

            const edgeDeviceRowData = ([]);
            const selectEdgeDeviceRowData= ([]);

            return{
                userData, 

                fleetGroupId: "",
                fleetGroupNm: "",
                rgstNm: "",
                rgstDt: "",
                teamNm: "",
                desc: "",

                edgeDeviceRowData,
                selectEdgeDeviceRowData,

                unSelectEdgeList: [],
                selectedEdgeList: [],
                mainEdgeDeviceList: [],

                // breadcrumb
                pageId: "deviceNDelivery_addFleetGroup",
                bcParams: [],

                // role
                viewRole: false,
                manageRole: false,
            }
        },
        mounted() {
            // Role
            this.setRole();

            const fleetGroupId = this.$route.params.fleetGroupId;

            if ( typeof fleetGroupId !== "undefined" && fleetGroupId !== null && fleetGroupId !== "" ) {
                this.pageId = 'deviceNDelivery_modFleetGroup';
                this.fleetGroupId = fleetGroupId;
                this.bcParams.push({
                    fleetGroupId: this.fleetGroupId
                });
                this.setPage();
            } else {
                this.pageId = 'deviceNDelivery_addFleetGroup';
                this.fleetGroupId = "";

                const getCKUser = this.getUserData;
                const getUser = this.$store.state.user;
                if(getCKUser == undefined){
                    this.userData = getUser;
                }else{
                    this.userData = getCKUser;
                }

                this.rgstNm = this.userData.name;
                this.teamNm = this.userData.teamName;
                this.rgstDt = common.getNowDt();
            }

        },
        methods:{
            async setRole() {
                this.manageRole = await common.getUserRole('type2');
                this.viewRole = await common.getUserRole('type2');

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            async setPage() {
                const fleetGroupId = this.fleetGroupId;
                const data = await this.getFleetGoupData(fleetGroupId);

                if ( typeof data != "undefined" && data != null && data != "" ) {
                    this.fleetGroupNm = data.fleetName;
                    this.rgstNm = data.registrantName;
                    this.rgstDt = data.created;
                    this.teamNm = data.belonging;
                    this.desc = data.description;

                    const edgeDeviceList = data.edgeDeviceList;
                    this.mainEdgeDeviceList = this.setDeviceData(edgeDeviceList);
                }
            },


            setDeviceTable(deviceList){
                deviceList.then(list => {
                    const dataList = [];
                    list.forEach(element => {
                        dataList.push({
                            id: element.id,
                            edgeDeviceId: element.edge_device_id,
                            edgeDeviceGroup: element.edge_device_group,
                            edgeDeviceName: element.edge_device_name,
                            status: element.status,
                        });
                    });
                    // this.edgeDeviceRowData = dataList;
                    // this.edgeDeviceRowData = common.deleteRowData(dataList, this.mainEdgeDeviceList, 'id');
                    this.edgeDeviceRowData = common.deleteRowData(dataList, this.selectEdgeDeviceRowData, 'id');
                });
            },

            setDeviceData(deviceList){
                const dataList = [];
                deviceList.forEach(item => {
                    dataList.push({
                        id: item.id,
                        edgeDeviceId: item.edge_device_id,
                        edgeDeviceGroup: item.edge_device_group,
                        edgeDeviceName: item.edge_device_name,
                        status: item.status,
                    });

                });
                return dataList;
            },

            // ----- click
            async clickSearchDevice() {
                const keyword = this.$refs.edgeKeyword.value.toUpperCase();

                if(typeof keyword == "undefined" || keyword == null || keyword == "") {
                    const getDeviceList = this.getEdgeDeviceList();
                    this.setDeviceTable(getDeviceList);

                } else {
                    this.setSearchDevice(keyword);
                }
            },

            setSearchDevice(keyword) {
                const searchRow = [];
    
                this.edgeDeviceRowData.forEach(item => {
                    let edgeDeviceId = common.getUpper(item['edgeDeviceId']);
                    let edgeDeviceName = common.getUpper(item['edgeDeviceName']);
                    let status = common.getUpper(item['status']);

                    if ( edgeDeviceId.includes(keyword) || edgeDeviceName.includes(keyword) || status.includes(keyword) ) {
                        searchRow.push(item);
                    }
                });

                this.edgeDeviceRowData = ([]);

                searchRow.forEach(item => {
                    this.edgeDeviceRowData.push(item);
                });
            },
            
            clickAddDevice() {
                if ( this.unSelectEdgeList.length > 0 ) {
                    this.unSelectEdgeList.forEach(item => {
                        this.selectEdgeDeviceRowData.push(item);
                    });

                    this.edgeDeviceRowData = common.deleteRowData(this.edgeDeviceRowData, this.unSelectEdgeList, "id");
                    this.unSelectEdgeList = [];
                }
            },
            
            clickDelDevice() {
                if ( this.selectedEdgeList.length > 0 ) {
                    this.selectedEdgeList.forEach(item => {
                        this.edgeDeviceRowData.push(item);
                    });
                    
                    this.selectEdgeDeviceRowData = common.deleteRowData(this.selectEdgeDeviceRowData, this.selectedEdgeList, "id");
                    this.selectedEdgeList = [];

                    const keyword = this.$refs.edgeKeyword.value.toUpperCase();
                    if ( typeof keyword != "undefined" && keyword != null && keyword != "" ) {
                        this.setSearchDevice(keyword);
                    }
                }
            },

            clickCompleteDevice() {
                this.mainEdgeDeviceList = [];
                this.selectEdgeDeviceRowData.forEach(item => {
                    this.mainEdgeDeviceList.push(item);
                });
                this.closeModal();
            },
            
            clickDelEdgeList(id) {
                let i = this.mainEdgeDeviceList.length;
                while ( i-- ) {
                    let item = this.mainEdgeDeviceList[i];
                    if ( item.id == id ) {
                        this.mainEdgeDeviceList.splice(i, 1);
                        this.edgeDeviceRowData.push(item);
                    }
                }
                let j = this.selectEdgeDeviceRowData.length;
                while ( j-- ) {
                    let item = this.selectEdgeDeviceRowData[j];
                    if ( item.id == id ) {
                        this.selectEdgeDeviceRowData.splice(j, 1);
                    }
                }
            },

            async clickRegist() {
                if ( this.requireCheck === true ) {
                    const edgeDeviceIds = [];
                    this.mainEdgeDeviceList.forEach(item => {
                        edgeDeviceIds.push(item.id);
                    });

                    const postData = {
                        "name": this.$refs.fleetGroupNm.value,
                        "description": this.$refs.desc.value,
                        "status": "",
                        "edgeDeviceIds": edgeDeviceIds
                    };

                    let responseCode = "";
                    let fleetGroupId = this.fleetGroupId;

                    if ( fleetGroupId != '' && fleetGroupId > 0) {
                        const response = await this.updateFleetGroup(fleetGroupId, postData);
                        try {
                            responseCode = response['data']['code'];
                        } catch (error) {
                            responseCode = 500;
                        }
                    } else {
                        const response = await this.addFleetGroup(postData);
                        try {
                            responseCode = response['data']['code'];
                            fleetGroupId = response['data']['data'];
                        } catch (error) {
                            responseCode = 500;
                        }
                    }

                    if ( responseCode === 200 || responseCode === "200" ) {
                        this.$router.push({ 
                            name: 'detailFleetGroup',
                            params: { fleetGroupId: fleetGroupId}
                        });
                    }

                }
            },


            // ----- Modal
            async showModalMember(){
                const $modal = this.$refs.modalWrap;
                const $modalMember = this.$refs.modalMember;
                $modal.style.display = "block";
                $modalMember.style.display = "block";

                this.$refs.memberKeyword.value = "";

                const teamId = this.teamId;
                const getMemberList = this.getMemberData(teamId);

                this.selectMemberRowData = ([]);
                this.mainMemberList.forEach(item => {
                    this.selectMemberRowData.push(item);
                });

                this.setMemberTable(getMemberList);
            },
            async showModalDevice(){
                const $modal = this.$refs.modalWrap;
                const $modalDevice = this.$refs.modalDevice;
                $modal.style.display = "block";
                $modalDevice.style.display = "block";

                this.$refs.edgeKeyword.value = "";

                const getDeviceList = this.getEdgeDeviceList();

                this.selectEdgeDeviceRowData = ([]);
                this.mainEdgeDeviceList.forEach(item => {
                    this.selectEdgeDeviceRowData.push(item);
                });

                this.setDeviceTable(getDeviceList);
            },
            showModalAlert(){
                const $modal = this.$refs.modalWrap;
                const $modalAlert = this.$refs.modalAlert;
                $modal.style.display = "block";
                $modalAlert.style.display = "block";
            },
            closeModal(){
                const $modal = this.$refs.modalWrap;
                const $modalDevice = this.$refs.modalDevice;
                const $modalAlert = this.$refs.modalAlert;
                $modal.style.display = "none";
                $modalDevice.style.display = "none";
                $modalAlert.style.display = "none";
            },
            goBackPage(){
                this.$router.push('/deviceNDelivery/fleetGroupList');
            },


            // ----- API 
            async getFleetGoupData(fleetGoupId) {
                try {
                    const response = await common.apiGet('/fleet-group/' + fleetGoupId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getEdgeDeviceList() {
                try {
                    const response = await common.apiGet('/edge-device/division');
                    console.log(response.data.data)
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async addFleetGroup(postData) {
                let response = "";
                try {
                    response = await common.apiPost('/fleet-group', postData);
                } catch (error) {
                    response = error.response;
                    console.error('Error posting data:', error);
                }
                return response;
            },
            async updateFleetGroup(fleetGroupId, postData) {
                let response = "";
                try {
                    response = await common.apiPatch('/fleet-group/' + fleetGroupId, postData);
                } catch (error) {
                    response = error.response;
                    console.error('Error posting data:', error);
                }
                return response;
            },
            async getMemberRole() {
                let response;
                try {
                    let url = `/member/check-role`;
                    response = await common.apiGet(url);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            }
            
        },
    }
</script>