
<template>
  
  <div id="wrap">
    <router-view></router-view>
  </div>
</template>



<script>

  
  export default {
    name: 'App',
    components: {

    },
    data(){
      return{};
    },
    mounted() {
      
    }
  
}

</script>

<style>
  #app {
    min-width: 1440px;
  }
</style>
