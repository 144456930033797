import { createStore } from 'vuex';
import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import conf from '@/assets/js/conf'

// const DOMAIN_URL = 'https://api.dlfe.dev.micosa.io';

function createUserData() {
  const getJWT = Cookies.get('jwt');
  if(getJWT != undefined && getJWT != null){
    const getUser = jwtDecode(getJWT);
    return {
      auth: getUser.auth,
      lastLogin: getUser.lastLogin,
      name: getUser.name,
      profileImage: getUser.profileImage,
      email: getUser.sub,
      teamName: getUser.teamName
    };
  }
  
}

const store = createStore({
  state: {
    user: null,
    jwt: Cookies.get('jwt') || null,
    isLoggedIn: false,
    userData: createUserData()
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      Cookies.set('user', JSON.stringify(user), { expires: 4/24 }); // 쿠키에 accessToken JWT 저장, 4시간 유효 기간 설정
    },
    SET_JWT(state, jwt) {
      state.jwt = jwt;
      Cookies.set('jwt', jwt, { expires: 4/24 }); // 쿠키에 accessToken JWT 저장, 4시간 유효 기간 설정
    },
    LOGIN(state) {
      state.isLoggedIn = true;
    },
    LOGOUT(state) {
      state.user = null;
      state.jwt = null;
      Cookies.remove('jwt'); // 로그아웃 시 쿠키 삭제
      Cookies.remove('user');
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        // 로그인 API 호출
        const url = conf.getBffBaseURL() + "/auth/login";
        const response = await axios.post(url, credentials, {
            headers: {
                'Content-Type': 'application/json', // Set the appropriate Content-Type header
              }
        });
        const jwt = response.data.data.accessToken;
        const user = jwtDecode(jwt);
        
        // console.log(jwtDecode(response.data.data.accessToken))
        // Vuex Store에 유저 정보와 JWT 저장
        
        commit('SET_USER', user);
        commit('SET_JWT', jwt);
        commit('LOGIN');

      } catch (error) {
        // console.log("error: " + JSON.stringify(localStorage));
        console.error('로그인 실패:', error);
        throw error;
      }
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
    updateUserData(state, data){
      state.userData = data;
    }
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    getUserData(state){
      return state.userData;
    }
  },
});

export default store;


