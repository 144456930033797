<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>데이터/ TASK 운영</span>
                    <span>차량 모니터링 목록</span>
                    <span class="active">Auto Labeling 검토 상세</span>
                </div>
                <h2>[TASK_VALM] {{taskInfo.taskName}}</h2>
            </div> -->
            <BreadcrumbLayout pageId="monTASK_taskSmartMining" :name="' ' + taskInfo.taskName"/>
            <div class="item_info">
                <div class="item_title">
                    <strong class="task_project">[PROJECT] {{pjtData.project_description}}</strong>
                    <div class="item_info_btn_box">
                        <button type="button" class="btn_fold" v-bind:style="infoToggle ? 'display:none': ''" @click="clickToggle()">접기</button>
                        <button type="button" class="btn_fold active" v-bind:style="!infoToggle ? 'display:none': ''" @click="clickToggle()">펼치기</button>
                        <button type="button" class="btn_evt_group" @click="hideUtils"></button>
                        <ul class="evt_btn_box radiusbox" :style="{ display: InferenceUtil ? 'block' : 'none' }">
                            <li class="download_item">
                                <button type="button" @click="makePDF">데이터 다운로드</button>
                            </li>
                            <li class="share_item">
                                <button type="button" @click="clipCoppy">공유하기</button>
                            </li>
                            <li class="retouch_item" v-if="manageRole">
                                <button type="button" @click="taskModify">TASK 수정</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item_table radiusbox" v-bind:style="infoToggle ? 'display:none': ''">
                    <table>
                        <thead>
                            <tr>
                                <th>Project ID</th>
                                <th>AWS ID</th>
                                <th>PM</th>
                                <th>Project Type</th>
                                <th>Unit</th>
                                <th>Last Activity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{pjtData.project_name}}</td>
                                <td>{{pjtData.aws_id}}</td>
                                <td>{{pjtData.pm}}</td>
                                <td>{{pjtData.project_type}}</td>
                                <td>{{pjtData.team_name}}</td>
                                <td>{{pjtData.last_activity}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="item_desc">
                        <div class="desc_title">Description</div>
                        <div class="desc_txt">{{pjtData.project_description}}</div>
                    </div>
                </div>
            </div>
            <br>
            <br>
            
            <div class="item_table radiusbox">
            <iframe id="downloadFrame" src="https://smartmining.dev.micosa.io/iframe" width="1290px" height="1800px"></iframe>
            </div>

            
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';

    import common from '@/assets/js/common'
    import dayjs from "dayjs";

    import { ref } from "vue";
    import axios from "axios";

    export default {
        name: 'SmartMiningDashboard',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            BreadcrumbLayout,
        },
        data(){
            const paramData = ({
                "start_date":'', "end_date": '', 'model_no' : '',
                "point": '', "status":'l' , "labeled_result": 'fail',
                "pred_class": ''
            });

            return{
                // project
                prjId: "",
                taskId: "",
                pjtData: {},
                carData: {},
                

                paramData,
                

                startDate: null,
                endDate: null,

                infoToggle: false,

                taskInfo: {
                    taskName: "",
                    taskType: "",
                },

                InferenceUtil: false,
                projectId:'',

                manualValCheck: true,

                // role
                viewRole: false,
                manageRole: false,

                pickedFrom: ref(new Date(Date.now() - 17 * 24 * 60 * 60 * 1000)),
                pickedTo: ref(new Date()),
                title: "Smart Mining Dashboard",
                contryCount:{
                    "Korea" : 40,
                    "America" : 35,
                    "China" : 30,
                    "Japan" : 25,
                    "India" : 11
                },
                jobWidgets: [
                    {
                    id: 1,
                    label: "Noise",
                    counter: "0%",
                    badge: "bx bx-trending-up",
                    badgeColor: "success",
                    percentage: "0%",
                    chartsColor: "#34c38f",
                    chartOptions: {
                        chart: {
                        width: "100%",
                        height: 46,
                        type: "area",
                        sparkline: {
                            enabled: true,
                        },
                        toolbar: {
                            show: false,
                        },
                        },
                        dataLabels: {
                        enabled: false,
                        },
                        stroke: {
                        curve: "smooth",
                        width: 1.5,
                        },
                        fill: {
                        type: "gradient",
                        gradient: {
                            shadeIntensity: 1,
                            inverseColors: false,
                            opacityFrom: 0.45,
                            opacityTo: 0.05,
                            stops: [50, 100, 100, 100],
                        },
                        },
                        colors: ["#34c38f"],
                    },
                    series: [
                        {
                        name: "Accuracy",
                        data: [],
                        },
                    ],
                    },
                    {
                    id: 2,
                    label: "Mode",
                    counter: "0%",
                    badge: "bx bx-trending-up",
                    badgeColor: "success",
                    percentage: "0%",
                    chartOptions: {
                        chart: {
                        width: "100%",
                        height: 46,
                        type: "area",
                        sparkline: {
                            enabled: true,
                        },
                        toolbar: {
                            show: false,
                        },
                        },
                        dataLabels: {
                        enabled: false,
                        },
                        stroke: {
                        curve: "smooth",
                        width: 1.5,
                        },
                        fill: {
                        type: "gradient",
                        gradient: {
                            shadeIntensity: 1,
                            inverseColors: false,
                            opacityFrom: 0.45,
                            opacityTo: 0.05,
                            stops: [50, 100, 100, 100],
                        },
                        },
                        colors: ["#34c38f"],
                    },
                    series: [
                        {
                        name: "Accuracy",
                        data: [],
                        },
                    ],
                    },
                    {
                    id: 3,
                    label: "Performance",
                    counter: "0%",
                    badge: "bx bx-trending-up",
                    badgeColor: "success",
                    percentage: "0%",
                    chartOptions: {
                        chart: {
                        width: "100%",
                        height: 46,
                        type: "area",
                        sparkline: {
                            enabled: true,
                        },
                        toolbar: {
                            show: false,
                        },
                        },
                        dataLabels: {
                        enabled: false,
                        },
                        stroke: {
                        curve: "smooth",
                        width: 1.5,
                        },
                        fill: {
                        type: "gradient",
                        gradient: {
                            shadeIntensity: 1,
                            inverseColors: false,
                            opacityFrom: 0.45,
                            opacityTo: 0.05,
                            stops: [50, 100, 100, 100],
                        },
                        },
                        colors: ["#34c38f"],
                    },
                    series: [
                        {
                        name: "Accuracy",
                        data: [],
                        },
                    ],
                    },
                    {
                    id: 4,
                    label: "ETC",
                    counter: "0%",
                    badge: "bx bx-trending-down",
                    badgeColor: "success",
                    percentage: "0%",
                    chartOptions: {
                        chart: {
                        width: "100%",
                        height: 46,
                        type: "area",
                        sparkline: {
                            enabled: true,
                        },
                        toolbar: {
                            show: false,
                        },
                        },
                        dataLabels: {
                        enabled: false,
                        },
                        stroke: {
                        curve: "smooth",
                        width: 1.5,
                        },
                        fill: {
                        type: "gradient",
                        gradient: {
                            shadeIntensity: 1,
                            inverseColors: false,
                            opacityFrom: 0.45,
                            opacityTo: 0.05,
                            stops: [50, 100, 100, 100],
                        },
                        },
                        colors: ["#f46a6a"],
                    },
                    series: [
                        {
                        name: "Accuracy",
                        data: [],
                        },
                    ],
                    },
                ],
                date: ref(),
                dataCount:{},
                dataTemp:{raw_total:"-"},
                dataROCnt:{},
                dataAccuracy:{},
                dataProcessedData:"-",
                dataLLMData:"-",
                dataResult:"-",
                dataRecentDate:"-",
                dataModelVersion:"-",
                dataLogRDSInfo:{},
                dataTotal:"-",
                dataKoreanLLM:"-",
            };
        },
        computed: {
        },
        async mounted() {
            // Role
            await this.setRole();

            const stoday = new Date();

            this.paramData['start_date'] = '2023-06-01';// dayjs(stoday).subtract(1, 'day').format('YYYY-MM-DD');
            this.paramData['end_date'] = dayjs(stoday).format('YYYY-MM-DD');

            this.startDate = this.paramData['start_date'];
            this.endDate = this.paramData['end_date'];

            //  ------ API CALL
            this.prjId = this.$route.query.prjId;
            this.taskId = this.$route.query.taskId;
            this.setPrjView();

            this.setTaskInfo();

            this.getDataCount();

            this.projectId = this.$route.query.prjId;

        },
        watch: {
            carSelect() {
                if (this.carSelect == "0") {
                    this.paramData['model_no'] = '';
                }
                else this.paramData['model_no'] = this.carSelect;
            },
            pointSelect() {
                if (this.pointSelect == "0") {
                    this.paramData['point'] = '';
                }
                else this.paramData['point'] = this.pointSelect;
            },
            labelSelect() {
               this.paramData['status'] = this.labelSelect;
            },
            resultSelect() {
               this.paramData['labeled_result'] = this.resultSelect;
            },
            predSelect() {
                if (this.predSelect == "0") {
                    this.paramData['pred_class'] = '';
                }
                else this.paramData['pred_class'] = this.predSelect;
            },
            manual_val() {
                if ( this.manual_val ==='ok' || this.manual_val === 'ng' ) {
                    this.manualValCheck = false;
                } else {
                    this.manualValCheck = true;
                }
            }
        },
        methods: {
            async setRole() {
                const prjId = this.$route.query.prjId;
                this.manageRole = ( await common.getUserRole('type2') || await common.getPrjRole('type2', prjId) );
                this.viewRole = ( await common.getUserRole('type2') || await common.getPrjRole('type1', prjId) );

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            clickToggle() {
                const toggle = !this.infoToggle;
                this.infoToggle = toggle;
            },

            hideUtils() { // 부가기능 - 보이기 숨기기
                if(this.InferenceUtil == true) {
                    this.InferenceUtil = false;
                } else if(this.InferenceUtil == false){
                    this.InferenceUtil = true;
                }
            },
            async clipCoppy() { // 공유하기
                let dummy = document.createElement("textarea");
                document.body.appendChild(dummy);
                try {
                    const url = window.document.location.href;
                    dummy.value = url;
                    dummy.select();
                    document.execCommand("copy");
                    document.body.removeChild(dummy);
                    alert('url copy success')
                } catch(error) {
                    console.error(error);
                } 
            },
            async taskModify() { // 수정
                if ( this.manageRole ) {
                    const taskId = this.$route.query.taskId;
                    const prjId = this.$route.query.prjId;
                    const taskTypeId = this.$route.query.taskTypeId;
                    const query= {
                        prjId: prjId,
                        taskId: taskId,
                        taskTypeId: taskTypeId,
                    };
                    // console.log(taskId);
                    try {
                        this.$router.push({
                            name: 'AddTask', query });
                    } catch(error) {
                        console.error('Error:', error);
                    }
                }
            },

            async getTaskData(taskId, viewRole){
                try {
                    const response = await common.apiGet(`/monitoring-task/${taskId}?viewRole=${viewRole}`);
                    console.log(response)
                    // console.log(response.data.data);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async setTaskInfo() {
                const taskId = this.taskId;
                const taskInfo = await this.getTaskData(taskId, this.viewRole);
                if(typeof taskInfo !== "undefined" && taskInfo !== null && taskInfo !== "") {
                    this.taskInfo = taskInfo;
                }
            },

            async setPrjView() {
                const getPjtData = await this.getPrjInfo(this.prjId)
                this.pjtData = await getPjtData.project;
            },

            changeRange(){
                if(this.rangeSelect=='0') return;

                this.paramData['start_date'] = dayjs(new Date()).subtract(this.rangeSelect, 'day').format('YYYY-MM-DD');

                this.startDate = this.paramData['start_date'];

                this.setFilter();
            },

            // ----- API
            async getPrjInfo(prjId) {
                try {
                    const response = await common.apiGet('/project/'+prjId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },

            async getSearch(){
            },

            onDatePic(flag){
                if(flag == 'S'){   
                    if(this.startDate !== null ){
                        this.paramData['start_date'] = dayjs(this.startDate).format('YYYY-MM-DD'); 
                    }
                    
                }else if(flag == 'E'){
                    if(this.endDate !== null ){
                        this.paramData['end_date'] = dayjs(this.endDate).format('YYYY-MM-DD'); 
                    }
                }
            },
            datePicToggle(){
                const vm = this;
                const _display = vm.$refs.calenderBox.style.display;
                const _startDateBox = vm.$refs.startDateBox.style.display;
                // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
                // console.log(_display);
                
                if(_display == 'block'){
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'none';
                    vm.picColor.startStatus = false;
                    vm.picColor.endStatus = false;

                    this.setFilter();
                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'block';
                    vm.$refs.startDateBox.style.display = 'block';
                    vm.$refs.endDateBox.style.display = 'none';
                    console.log(_startDateBox);
                }
            },


            //-------API------
            async getMemberRole() {
                let response;
                try {
                    let url = `/member/check-role`;
                    response = await common.apiGet(url);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },
            async getProjectRole() {
                let response;
                try {
                    let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
                    response = await common.apiGet(checkProjectRoleUrl);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },

            getDataCount: function() {
                // TO-DO : TreemapComponent, countryComponent구현시 아래 주석 해제할 것.
                //this.$refs.treemapComponent.setLoading(true);
                //this.$refs.countryComponent.setLoading(true);
                
                
                // eslint-disable-next-line no-unused-vars
                console.log(this.pickedFrom.getDate(), this.pickedFrom.getMonth()+1, this.pickedFrom.getFullYear())
                axios.defaults.headers.common['X-API-Key'] = 'tmkey1';
                axios.post("https://4g22bubeoi.execute-api.ap-northeast-2.amazonaws.com/log/rds", {
                    "start_date": this.toStringByFormatting(this.pickedFrom),
                    "end_date": this.toStringByFormatting(this.pickedTo),
                })
                .then(response=>{this.dataTemp=response.data; this.dataSendToChild()})
                .catch(function(error){
                    console.log(error)
                });
                axios.defaults.headers.common['X-API-Key'] = 'tmkey1';
                axios.post("https://4g22bubeoi.execute-api.ap-northeast-2.amazonaws.com/log/rds/cnt", {
                    "start_date": this.toStringByFormatting(this.pickedFrom),
                    "end_date": this.toStringByFormatting(this.pickedTo),
                })
                .then(response=>{this.dataROCnt=response.data; this.dataSendToROCnt()})
                .catch(function(error){
                    console.log(error)
                });
                axios.defaults.headers.common['X-API-Key'] = 'tmkey1';
                axios.post("https://4g22bubeoi.execute-api.ap-northeast-2.amazonaws.com/log/rds/accuracy", {
                    "start_date": this.toStringByFormatting(this.pickedFrom),
                    "end_date": this.toStringByFormatting(this.pickedTo),
                })
                .then(response=>{this.dataAccuracy=response.data; this.dataSetAccuracy()})
                .catch(function(error){
                    console.log(error)
                });
                axios.defaults.headers.common['X-API-Key'] = 'tmkey1';
                axios.post("https://4g22bubeoi.execute-api.ap-northeast-2.amazonaws.com/log/rds/info", {
                    "start_date": this.toStringByFormatting(this.pickedFrom),
                    "end_date": this.toStringByFormatting(this.pickedTo),
                })
                .then(response=>{this.dataLogRDSInfo=response.data; this.dataSetInfo()})
                .catch(function(error){
                    console.log(error)
                });

            },
            dataSetInfo: function(){
                let keys = Object.keys(this.dataLogRDSInfo.results);
                console.log(JSON.stringify(this.dataLogRDSInfo.results[keys[0]]));
                
                this.dataTotal = this.dataLogRDSInfo.results[keys[0]].RAW_CNT
                this.dataRecentDate = this.dataLogRDSInfo.results[keys[0]].CREATE_TIME == null ? "-":this.dataLogRDSInfo.results[keys[0]].CREATE_TIME;
                this.dataResult = this.dataLogRDSInfo.results[keys[0]].PAXATA_SUCCESS == null ? "-":this.dataLogRDSInfo.results[keys[0]].PAXATA_SUCCESS;
                let datas = 0;
                for (var i in keys){
                    datas += this.dataLogRDSInfo.results[keys[i]].DAY_CNT;
                }
                
                this.dataProcessedData = datas;
                this.dataModelVersion = "v.20230910.03";
            },
            dataSetAccuracy: function(){
                console.log(JSON.stringify(this.jobWidgets[0]))
                this.jobWidgets[0] = {...this.jobWidgets[0],
                    counter: this.dataAccuracy.results.NOISE.PERCENTAGE+"%",
                    series:
                    [
                        {
                        data:[]
                        }
                    ],
                }
                this.jobWidgets[1] = {...this.jobWidgets[1],
                    counter: this.dataAccuracy.results.MODE.PERCENTAGE+"%",
                    series:
                    [
                        {
                        data:[]
                        }
                    ],
                }
                this.jobWidgets[2] = {...this.jobWidgets[2],
                    counter: this.dataAccuracy.results.PERFORMANCE.PERCENTAGE+"%",
                    series:
                    [
                        {
                        data:[]
                        }
                    ],
                }
                this.jobWidgets[3] = {...this.jobWidgets[3],
                    counter: this.dataAccuracy.results.ETC.PERCENTAGE+"%",
                    series:
                    [
                        {
                        data:[]
                        }
                    ],
                }
                console.log(JSON.stringify(this.jobWidgets[0]))
            },
            dataSendToChild: function(){
                //TO-DO : TreemapComponent, countryComponent구현시 아래 주석 해제할 것.
                //this.$refs.treemapComponent.dataCountManager(this.dataTemp);
                //this.$refs.countryComponent.dataCountManager(this.dataTemp);
            },
            dataSendToROCnt: function(){
                //TO-DO : DailyROComponent구현시 아래 주석 해제할 것.
                //this.$refs.dailyROComponent.dataCountManager(this.dataROCnt);
                let keys = Object.keys(this.dataROCnt.results);
                let datasKorean=0;
                let dataFull=0;
                for (var i in keys){
                    let cntKorean = (isNaN(this.dataROCnt.results[keys[i]].KOREAN_CNT) ? 0 : this.dataROCnt.results[keys[i]].KOREAN_CNT)
                    let cntOther = (isNaN(this.dataROCnt.results[keys[i]].ELSE_CNT) ? 0 : this.dataROCnt.results[keys[i]].ELSE_CNT)
                    datasKorean += cntKorean
                    dataFull += cntKorean + cntOther
                }
                this.dataKoreanLLM = datasKorean;
                this.dataLLMData = dataFull;
            },
            leftPad: function(value) {
                if (value >= 10) {
                    return value;
                }
                return `0${value}`;
            },
            toStringByFormatting:function(source, delimiter = '-') {
                const year = source.getFullYear();
                const month = this.leftPad(source.getMonth() + 1);
                const day = this.leftPad(source.getDate());

                return [year, month, day].join(delimiter);
            },

        }
    }
</script>
<style scoped lang="scss">
    .card{
        background-clip: borrder-box;
        background-color: rgb(255, 255, 255);
        border-bottom-color: rgb(246, 246, 246);
        border-bottom-left-radius :4px;
        border-bottom-right-radius: 4px;
        border-bottom-style: solid;
        border-bottom-width :0px;
        border-image-outset: 0;
        border-image-repeat: stretch;
        border-image-slice: 100%;
        border-image-source :none;
        border-image-width: 1;
        border-left-color: rgb(246, 246, 246);
        border-left-style: solid;
        border-left-width :0px;
        border-right-color: rgb(246, 246, 246);
        border-right-style :solid;
        border-right-width: 0px;
        border-top-color: rgb(246, 246, 246);
        border-top-left-radius: 4px;
        border-top-right-radius :4px;
        border-top-style :solid;
        border-top-width :0px;
        box-shadow :rgba(18, 38, 63, 0.03) 0px 12px 24px 0px;
        box-sizing: border-box;
        color :rgb(73, 80, 87);
        display: flex;
        flex-direction: column;
        font-family :Poppins, sans-serif;
        font-size: 13px;
        font-weight :400;
        height: 280.531px;
        line-height: 19.5px;
        margin-bottom: 24px;
        min-width :0px;
        outline-color :rgb(73, 80, 87);
        outline-style :none;
        outline-width: 0px;
        overflow-wrap :break-word;
        position: relative;
        text-align :start;
        text-size-adjust :100%;
        -webkit-tap-highlight-color : rg;
        width:100%;
    }
    .card_top{
        color :rgb(73, 80, 87);
        display :block;
        flex-basis :auto;
        flex-grow :1;
        flex-shrink: 1;
        font-family: Poppins, sans-serif;
        font-size: 13px;
        font-weight: 400;
        height: 96.0312px;
        line-height: 19.5px;
        outline-color: rgb(73, 80, 87);
        outline-style: none;
        outline-width: 0px;
        overflow-wrap: break-word;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        text-align: start;
        text-size-adjust: 100%;
        -webkit-tap-highlight-color :rgba(0, 0, 0, 0);
        width:100%;
    }
    .row{

        color: rgb(73, 80, 87);
        display: flex;
        flex-wrap: wrap;
        font-family :Poppins, sans-serif;
        font-size :13px;
        font-weight :400;
        height: 56.0312px;
        line-height :19.5px;
        margin-left: -12px;
        margin-right :-12px;
        margin-top :0px;
        outline-color :rgb(73, 80, 87);
        outline-style: none;
        outline-width :0px;
        overflow-wrap :break-word;
        text-align: start;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        width:100%;
    }
    .label_list{
        background-color: $whiteColor;
        box-sizing: border-box;
        padding: 30px 20px;
        margin-top: 14px; 
        .box_header{
            height: 40px;
            line-height: 40px;
            position: relative;
            strong{
                font-weight: $bold;
                span{
                    color: $mainColor;
                }
            }
            button{
                width: 150px;
                height: 40px;
                line-height: 40px;
                border-radius: 10px;
                border: 1px solid $disable02Color;
                font-size: $fontBasic;
                margin-right: 10px; 
                &.mcbtn{
                    border: none;
                    margin-right: 0;
                }
            }
            .btn_box{
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        .label_item{
            ul{
                &::after{
                    content: "";
                    display: block;
                    clear: both;
                }
                li{
                    width: 300px;
                    height: 552px;
                    overflow: hidden;
                    // display: inline-block;
                    float: left;

                    padding: 20px 18px 20px 0;
                    border-bottom: 1px solid $line02Color;
                    position: relative;
                    &::after{
                        width: 1px;
                        height: 396px;
                        background-color: $line02Color;
                        content: "";
                        display: block;
                        position: absolute;
                        top: 20px;
                        right: 20px;
                    }
                    &:nth-child(4n) {
                        width: 280px;
                        padding-right: 0px;
                        &::after{
                            display: none;    
                        }
                    }
                    .img_box{
                        width: 280px;
                        height: 200px;
                        position: relative;
                        overflow: hidden;
                        background-color: #aaa;
                        img{
                            width: 100%;
                        }
                        &::after{
                            width: 30px;
                            height: 30px;
                            position: absolute;
                            bottom: 10px;
                            right: 10px;
                            border-radius: 5px;
                            content: "";
                            display: block;
                            background-color: rgba(0,0,0,0.8);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-image: url($baseURL+'/common/external_link.svg');
                        }
                    }
                    .item_info{
                        width: 280px;
                        margin-bottom: 20px;
                        table{
                            width: 100%;
                            
                            th{
                                width: 30%;
                                color: #6E7380;
                                vertical-align: middle;
                                padding-top: 15px;
                            }
                            td{
                                width: 65%;
                                word-break: break-all;
                                padding-top: 15px;
                                padding-left: 15px;
                                input[type="radio"]{
                                    display: none;
                                    &.manual_OK + label{
                                        border-radius: 10px 0 0 10px;
                                        border-right: none;
                                    }
                                    &.manual_OK:checked + label{
                                        height: 39px;
                                        background-color: $okColor;
                                        color: $whiteColor;
                                        font-weight: $bold;
                                        border-color: $okColor;
                                    }
                                    &.manual_NG +label{
                                        border-radius: 0 10px 10px 0;
                                        border-left: none;
                                    }
                                    &.manual_NG:checked + label{
                                        height: 39px;
                                        background-color: $failedColor;
                                        color: $whiteColor;
                                        font-weight: $bold;
                                        border-color: $failedColor;
                                    }
                                }
                                label{
                                    width: 75px;
                                    height: 40px;
                                    line-height: 40px;
                                    display: inline-block;
                                    text-align: center;
                                    background-color: $whiteColor;
                                    border: 1px solid $disable02Color;
                                }
                            }
                            
                        }
                        div{
                            float: left;
                            margin: 10px 0 0;
                            overflow-wrap: break-word;
                            display: table-cell;
                            vertical-align: middle;
                            &.item_key{
                                width: 35%;
                                color: #6E7380;
                            }
                            &.item_val{
                                width: 55%;
                                input[type="radio"]{
                                    display: none;
                                    &#manualOK + label{
                                        border-radius: 10px 0 0 10px;
                                        border-right: none;
                                    }
                                    &#manualOK:checked + label{
                                        background-color: $okColor;
                                        color: $whiteColor;
                                        font-weight: $bold;
                                        border-color: $okColor;
                                    }
                                    &#manualNG +label{
                                        border-radius: 0 10px 10px 0;
                                        border-left: none;
                                    }
                                    &#manualNG:checked + label{
                                        background-color: $failedColor;
                                        color: $whiteColor;
                                        font-weight: $bold;
                                        border-color: $failedColor;
                                    }
                                }
                                label{
                                    width: 75px;
                                    height: 40px;
                                    line-height: 40px;
                                    display: inline-block;
                                    text-align: center;
                                    background-color: $whiteColor;
                                    border: 1px solid $disable02Color;
                                }
                            }
                            &.item_toggle{
                                height: 40px;
                                line-height: 40px;
                            }
                        }
                        &::after{
                            content: "";
                            display: block;
                            clear: both;
                        }
                    }
                    .btn_save{
                        width: 280px;
                        height: 40px;
                        border-radius: 10px;
                        border: 1px solid $disable02Color;
                        background-color: $whiteColor;
                        margin-top: 20px;
                        font-size: $fontBasic;
                        &[disabled]{
                            background-color: $line02Color;
                            color: $disable01Color;
                            border-color: $disable02Color;
                        }
                    }
                }
            }
            
        }
    }
    #detailModal{
        display: none;
        .modal_contents{ 
            width: 1200px;
            box-sizing: border-box;
            padding: 25px 25px 30px;
            &::after{
                content: "";
                clear: both;
                display: block;
            }    
            .img_box{
                width: 700px;
                height: 640px;
                border: 1px solid $disable02Color;
                box-sizing: border-box;
                float: left;
                text-align: center;
                .detail_img{
                    padding-top: 50px;
                    margin-bottom: 20px;
                    img{
                        width: 100%;
                    }
                }
            }
            .info_box{
                width: 450px;
                float: left;
                box-sizing: border-box;
                padding-left:25px;
                table{
                    width: 100%;
                    text-align: left;
                    th,td{
                        font-size: $fontBasic;
                        border-bottom: 1px solid $line02Color;
                    }
                    th{
                        padding: 10px 20px;
                        color: #6E7380;
                        vertical-align: middle;
                    }
                    td{
                        padding: 10px 0;
                        vertical-align: middle;
                    }
                }
                input[type="radio"]{
                    display: none;
                    &#detailManualOK + label{
                        border-radius: 10px 0 0 10px;
                        border-right: none;
                    }
                    &#detailManualOK:checked + label{
                        background-color: $okColor;
                        color: $whiteColor;
                        font-weight: $bold;
                        border-color: $okColor;
                    }
                    &#detailManualNG +label{
                        border-radius: 0 10px 10px 0;
                        border-left: none;
                    }
                    &#detailManualNG:checked + label{
                        background-color: $failedColor;
                        color: $whiteColor;
                        font-weight: $bold;
                        border-color: $failedColor;
                    }
                }
                label{
                    width: 75px;
                    height: 40px;
                    line-height: 40px;
                    display: inline-block;
                    text-align: center;
                    background-color: $whiteColor;
                    border: 1px solid $disable02Color;
                }
            }
        }
        .btn_save{
            float: right;
            margin-top: 40px;
        }
    }
    .auto_label{
        .more_btn_box{
            width: 100%;
            height: 60px;
            .btn_call_itme{
                width: 100%;
                height: 100%;
                line-height: 60px;
                font-weight: $bold;
                text-align: center;
                background-repeat: no-repeat;
                background-image: url($baseURL+'/common/select_arrowdown.svg');
                background-position: 665px 27px;
                
            }
        }
    }
    


</style>