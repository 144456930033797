<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <div class="title_wrap">
                <div class="breadcrumb" v-if="listType === 'dataList'">
                    <span>My 프로젝트</span>
                    <span>프로젝트 워크스페이스</span>
                    <span class="active">데이터 목록</span>
                </div>
                <div class="breadcrumb" v-else-if="listType === 'taskList'">
                    <span>데이터/ TASK 운영</span>
                    <span class="active">모니터링 TASK 목록</span>
                </div>
                <div class="breadcrumb" v-else-if="listType === 'mlopsList'">
                    <span>데이터/ TASK 운영</span>
                    <span class="active">MLOps TASK 목록</span>
                </div>
                <h2>{{pageData.title}}</h2>
                <div class="title_btn" v-if="listType === 'taskList'">
                    <button type="button" @click="goTo()" class="add_project bgicon">{{pageData.addBtnName}}</button>
                </div>
            </div>
            <div class="list_wrap">
                <div id="listFilter" class="list_filter_wrap float_box" ref="listFilter">
                    <div class="filter_list_box" ref="filterListBox">
                        <ul class="float_box" ref="filterBox">
                            <li v-if="filterList.length === 0" style="padding-right: 20px;"><span>필터</span> : <span>전체</span></li>
                            <li v-for="(item, index) in filterList" :key="index" ref="filterItem">
                                <span>{{ item.keyName }}</span> : <span>{{ item.value }}</span>
                                <button class="btn_close" @click="removeFilter(index)"></button>
                            </li>
                        </ul>
                    </div>
                    <button class="btn_all_filter" ref="filterAllBtn" @click="filterToggle">전체보기</button>
                </div>
                <div class="table_list">
                    <div class="table_set">
                        <div class="cont_count">
                            <span ii="cont_num">{{pageData.counts}}</span> Contents
                        </div>
                        <select name="a" style="width: 585px; background-position: 555px center;" v-model="perPage" v-if="listType !== 'mlopsList'">
                            <option value="0">프로젝트를 선택해 주세요.</option>
                            <option v-for="perPage in perPageList" :key="perPage.id" :value="perPage.id">
                                {{ perPage.value }} 
                            </option>
                        </select>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <colgroup>
                                <col style="width: 60px;">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th v-for="(item, index) in columnDefs" :key="index">
                                        <div class="col_name">
                                            {{item.field}}
                                            <button class="btn_filter" @click="showFilter"></button>
                                        </div>
                                    </th>
                                </tr>
                                <tr id="filterWrap" ref="filterInputWrap">
                                    <th class="filter_input" v-for="(item,index) in columnDefs" :key="index">
                                        <div :data-id="item.field">
                                            <input type="text" @focus="setActive"  @keydown.enter="addFilter(index, $event)" >
                                            <button class="btn_add" ref="btnAddfilter" @click="addFilter(index, $event)" :data-index="index"></button>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in rowDataSliced" :key="index">
                                    <td v-for="(value, colIndex) in item" :key="colIndex" 
                                        v-bind:style="colIndex === 'prjId' 
                                            || colIndex === 'edgeId' 
                                            || colIndex === 'taskId' 
                                            || colIndex === 'teamId' 
                                            || colIndex === 'taskTypeId'
                                            || colIndex === 'aws_id' ? 'display:none' : '' 
                                        " >
                                        <span v-if="colIndex !== 'pipelineLink'" 
                                            @click="listLink($event, item)" 
                                            @mouseenter="cursorType = 'pointer'" 
                                            @mouseleave="cursorType = 'auto'"
                                            v-bind:style="{ cursor: cursorType }">{{value}}</span>
                                        <span class="piplog" @click="listLink($event, item)" 
                                            @mouseenter="cursorType = 'pointer'" 
                                            @mouseleave="cursorType = 'auto'"
                                            v-bind:style="{ cursor: cursorType }"
                                            v-else>바로가기 <img class="piplog" src="@/assets/images/common/external_link.svg" alt="External Link"></span>
                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="list_table_footer">
                        <!-- <button class="btn_list_delete"> 삭제 </button> -->
                        <!-- <div id="listPagination" class="list_pagination">
                            <button type="button" id="btnPrevious" disabled="disabled"></button>
                            
                        </div> -->
                        <PaginationUi
                            :totalItems="totalItems"
                            :itemsPerPage="itemsPerPage"
                            @page-changed="onPageChanged"
                        ></PaginationUi>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';

    import PaginationUi from "@/components/PagenationUi.vue";
    import '@/assets/js/filter';
    import gsap from 'gsap';
    import Cookies from 'js-cookie';
    import axios from 'axios';
    import common from '@/assets/js/common';

    export default {
        name: 'ListView',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            PaginationUi,
        },
        data(){
            const listType = this.$route.params.type;
            const columnDefs= ([]);
            const rowData= ([]);

            const rowDataSliced =([]);

            const filterList = ([]);
            const filterRowData =([]);
            const backUpRowData = ([]);
            const pageData =({});

            
            return{
                
                columnDefs,
                rowData,
                rowDataSliced,

                filterListIdx: 0,
                filterList,
                filterRowData,
                backUpRowData,
                listType,
                isFocused: false,
                pageData,
                
                perPageList: [],
                perPage: 0,

                totalItems: 100,
                itemsPerPage: 10,
                currentPage: 1,

                cursorType: 'auto'
            };
        },
        watch: {
            perPage() {
                if (this.listType == "dataList") {
                    this.makeDataList(this.perPage);
                } else if (this.listType == "taskList") {
                    this.makeTaskList(this.perPage);
                } else if (this.listType == "mlopsList"){
                    this.makeMlopsList(this.perPage);
                }
            }
        },
        mounted() {
            // console.log(this.listType);
            const listPram = this.listType;
            this.pageData.counts = 0;
            if(listPram == 'dataList'){
                console.log("데이터 목록");
                this.pageData.title = "데이터 목록";
                this.pageData.addBtnName = "주행기록 등록";

                // make selectbox
                this.makePrjSelList();

                // List column
                this.columnDefs = [
                    { field: "Edge Device ID" },
                    { field: "Edge Name" },
                    { field: "Project Name" },
                    { field: "Status" },
                    { field: "Recent Driving Record" },
                    { field: "Driving Record Count" }
                ]
                // dataList
                this.makeDataList(0);

            }else if(listPram == 'taskList'){
                console.log("분석 TASK");
                this.pageData.title = "모니터링 TASK 목록";
                this.pageData.addBtnName = "신규 모니터링 TASK 등록";

                // make selectbox
                this.makePrjSelList();

                this.columnDefs = [
                    { field: "Project Name" },
                    { field: "Task Name" },
                    { field: "Task Type" },
                    { field: "Manager" },
                    { field: "Team" },
                    { field: "Last Visit" },
                    { field: "Create Date" }
                ]

                // taskList
                this.makeTaskList(0);

            }else if(listPram == 'mlopsList'){
                console.log("MLOps TASK");

                this.pageData.title = "MLOps TASK 목록";
                this.pageData.addBtnName = "신규 MLOps TASK 생성";

                // make selectbox
                this.makePrjSelList();

                // List column
                this.columnDefs = [
                    { field: "Domain" },
                    // { field: "Project Name" },
                    { field: "Pipeline ID"},
                    { field: "Recent Run Date" },
                    { field: "Model Version" },
                    { field: "Pipeline Log 보기" },
                    // { field: "Mlops Task Id" },
                    // { field: "Project Id" },
                    
                ]


                // taskList
                this.makeMlopsList(0);
            }else{
                this.$router.push('/projectOps/workspace');
            }

            
        },
        computed: {
            
            currentItems() {
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                return Array.from(
                    { length: this.itemsPerPage },
                    (_, index) => startIndex + index + 1
                ).filter((item) => item <= this.totalItems);
            }
        },
        methods:{

            goTo(){
                this.$router.push('/monTASK/addTask');
            },

            onPageChanged(page) {
                this.currentPage = page;
                this.rowDataSliced = this.displayDataPerPage(this.rowData);
            },

            filterToggle(){
                const $listFilter = this.$refs.listFilter;
                const $filterBox = this.$refs.filterListBox;
                const $filterBtn = this.$refs.filterAllBtn;
                const hasOpenClass = $filterBox.classList.contains("open");
                if(hasOpenClass){
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.remove("open");
                            $filterBtn.classList.remove("active");
                            $filterBtn.innerText = '전체보기'
                        },
                        height: '70px',
                        duration: .3
                    });
                }else{
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.add("open");
                            $filterBtn.classList.add("active");
                            $filterBtn.innerText = '접기'
                        },
                        height: 'auto',
                        duration: .3
                    });
                }
                
            },

            addFilter(index, event){
                console.log("addFilter");
                
                const _this = event.target;
                const $thTag = _this.parentNode.parentNode;
                const $addBtn = $thTag.querySelector("button"); 
                const $input = $thTag.querySelector("input");
                const trimVal = $input.value.trim();
                const keyNameArr = _this.parentNode.parentNode.parentNode.parentNode.querySelector("tr:first-child").querySelectorAll(".btn_filter"); 
                let activeIndex = Array.from(keyNameArr).findIndex(element => element.classList.contains('active'));
                const keyName = String(keyNameArr[activeIndex].parentNode.textContent); 
                const obj = {};

                console.log(index);

                window.filterData.taget = index;

                if(0 < trimVal.length){
                    obj["keyName"] = keyName;
                    obj["value"] = trimVal;
                    $input.value = "";
                    this.filterList.push(obj);
                    this.$refs.filterAllBtn.style.display = "block";
                    $addBtn.parentNode.classList.add("disable");
                    $addBtn.previousElementSibling.disabled = true;
                    this.filterAction(false);
                }
                
            },

            removeFilter(index) {
                const $filterItem = this.$refs.filterItem;
                const $filterInputWrap = this.$refs.filterInputWrap;
                const dataId = this.filterList[index].keyName.trim();

                this.filterListIdx = index;
                
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).classList.remove("disable");
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).querySelector("input").disabled = false;
                this.filterList.splice(index, 1);
                if($filterItem.length <= 1){
                    this.$refs.filterAllBtn.style.display = "none";
                }
                console.log(this.filterList);

                this.filterAction(true);
            },
            //====project select를 하면 필터 클리어 하는 기능 필요
            filterClear() {
                this.filterList.splice(0, this.filterList.length); //배열 초기화

            },
            
            filterAction(remove){
                console.log("==== filterAction start ====");
                let _rowDataArray = [];

                for(let i = 0 ; i < window.filterData.workData.length ; i++){
                    let item = window.filterData.workData[i];
                    _rowDataArray.push(item);
                }
                
                const lodash = require('lodash');
                let _rowData = _rowDataArray;
                let _keyArray = this.filterRowData;
                const _filterList = this.filterList;
                let _filteredArray;
                
                // 엔터 칠때 동작하게 

                if(remove) {
                    const idx = this.filterListIdx;
                    _keyArray.splice(idx, 1);
                } else {
                    let _dataKey =  'Data' + (Number(window.filterData.taget)+1);
                    _keyArray.push(_dataKey);
                }

                const _originData = window.filterData.originalData;
                
                if(_filterList.length < 1){
                    // 마지막 클릭했을때 originData
                    _rowData = _originData;
                } else {
                    if(remove) {
                        _rowData = _originData;
                    }
                    for(let i = 0 ; i < _filterList.length ; i++){
                        let _dataVal = _filterList[i].value.trim();
                        let _dataKey = _keyArray[i];

                        if(this.listType == 'dataList' && _dataKey == 'Data6') {
                            _filteredArray = lodash.filter(_rowData, data => data.Data6 == _dataVal)
                        } else {
                            _filteredArray = lodash.filter(_rowData, function(obj) { return obj[_dataKey].indexOf(_dataVal) !== -1 })
                        }
                        _rowData = _filteredArray;
                    }
                }
                this.changePageValue(_rowData);    
            },  

            changePageValue(_rowData) {
                this.rowData = _rowData;
                this.rowDataSliced = this.displayDataPerPage(this.rowData);
                this.pageData.counts = this.rowData.length;
                this.currentPage = 1;
                this.totalItems = this.rowData.length;
            },


            checkdAll(event){
                const _this = event.target;
                const $allraw = document.querySelectorAll(".raw_chk"); 
                if(_this.checked){
                    // checked
                    for(var i = 0 ; i < $allraw.length ; i++){
                        $allraw[i].checked = true;
                    }
                }else{
                    // 해제
                    for(var j = 0 ; j < $allraw.length ; j++){
                        $allraw[j].checked = false;
                    }
                }
            },
            checkedChk(event){
                const _this = event.target;
                const $allChk = document.querySelector("#allChk");
                if($allChk.checked){
                    if(!_this.checked){
                        $allChk.checked = false
                    }
                }
            },
            setActive(event) {
                const _this = event.target;
                const $filterDiv = _this.parentNode.parentNode.parentNode.querySelectorAll("div");
                const $filterIcon = _this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                
                for(var i = 0 ; i < $filterDiv.length ; i ++){
                    $filterDiv[i].classList.remove("active");
                }
                _this.parentNode.classList.add("active");
                
                // filter icon 
                let activeIndex = Array.from($filterDiv).findIndex(element => element.classList.contains('active'));
                
                for(var j = 0 ; j < $filterIcon.length ; j ++){
                    $filterIcon[j].classList.remove("active");
                }
                $filterIcon[activeIndex].classList.add("active");

            },
            showFilter(event){
                const _this = event.target;
                
                const $filterBtn = _this.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                const $filterWrap = document.querySelector("#filterWrap");
                const $filterInput = $filterWrap.querySelectorAll(".filter_input");

                const hasShowClass = $filterWrap.classList.contains("show");
                const hasActiveClass = _this.classList.contains("active");
                
                for(var i = 0 ; i < $filterBtn.length ; i ++){
                    $filterBtn[i].classList.remove("active");
                }
                _this.classList.add("active");
                if(hasShowClass){
                    if(hasActiveClass){
                        $filterWrap.classList.remove("show");
                        _this.classList.remove("active");
                    }
                }else{
                    $filterWrap.classList.add("show");
                }
                let activeIndex = Array.from($filterBtn).findIndex(element => element.classList.contains('active'));
                // console.log(activeIndex);
                // input 
                for(var j = 0 ; j < $filterInput.length ; j ++){
                    $filterInput[j].querySelector("div").classList.remove("active");
                }
                // console.log($filterInput[activeIndex]);
                if(activeIndex > -1){
                    $filterInput[activeIndex].querySelector("div").classList.add("active");
                }
                
                
            },

            // axios 
            async getData(url) {

                const jwt = Cookies.get('jwt');

                try {
                    const response = await axios.get(url, {
                        headers: {Authorization: `Bearer ${jwt}`}
                    });
                    return response.data.data;
                } catch (error) {
                    return error;
                }
            },

            // make project select list
            async makePrjSelList() {
                const response = await common.apiGet("/project");
                const projectList = response.data.data;

                projectList.forEach(e => {
                    this.perPageList.push({
                        id: e.id,
                        value: e.projectName
                    });
                });
            },
            
            // make data list
            async makeDataList(id) {
                let url = "/edge-device/data/project";
                if(id > 0) {
                    url = url + "/" + id;
                }

                try {
                    const response = await common.apiGet(url);
                    const dataList = response.data.data;

                    let _rowData = [];
                    dataList.forEach(element => {
                        _rowData.push({
                            Data1: element.edgeDeviceId, 
                            Data2: element.edgeDeviceName, 
                            Data3: element.projectName, 
                            Data4: element.status, 
                            Data5: element.recentDrivingRecord, 
                            Data6: Number(element.drivingRecordCount),
                            prjId: element.projectId,
                            edgeId: element.id
                        });
                    });
                    this.rowData = _rowData;
                    this.rowDataSliced = this.displayDataPerPage(this.rowData);
                    this.pageData.counts = this.rowData.length.toLocaleString('ko-KR');
                    window.filterData.originalData = this.rowData;
                    window.filterData.workData = this.rowData;
                    this.currentPage = 1;
                    this.totalItems = this.rowData.length;


                    this.filterClear();
                } catch (error) {
                    console.log("datalist error : ",error);
                }

            },

            // make task list
            async makeTaskList(id) {
                let url = '/monitoring-task'
                if (id > 0) url = url + "/project/" + id;
                const response = await common.apiGet(url);
                const getDataList = response.data.data;

                let dataList = [];
                getDataList.forEach(element => {
                    dataList.push({
                        Data1: element.project_name, 
                        Data2: element.task_name, 
                        Data3: element.task_type, 
                        Data4: element.create_name, 
                        Data5: element.team_name, 
                        Data6: element.last_visit,
                        Data7: element.create_date,
                        prjId: element.project_id,
                        taskTypeId: element.monitoring_task_type_id,
                        taskId: element.task_id,
                        teamId: element.team_id
                    });
                });

                this.rowData = dataList;
                this.rowDataSliced = this.displayDataPerPage(this.rowData);

                this.pageData.counts = dataList.length.toLocaleString('ko-KR');
                window.filterData.originalData = dataList;
                window.filterData.workData = dataList;
                
                this.currentPage = 1;
                this.totalItems = dataList.length;
            },

            async getDlData(awsId) {
                // CF 시간 단위로 갱신
                let url = '/rds/vehicle/ml_list' ;
                let params = {
                    task: awsId
                }

                try {
                    const response = await common.apiDlGet(url, params);
                    let data = response.data.queryResult;
                    return data;    
                } catch (error) {
                    console.log("error in get dlData : ", error);
                }
            },
            
            async makeMlopsList(id) {
                let bffUrl_awsId = "/project/mlops-task";
                if (id > 0) bffUrl_awsId = `/project/mlops-task/${id}`

                let projectDataList = [];

                try {
                     const response_bff = await common.apiGet(bffUrl_awsId);
                     projectDataList = response_bff.data.data;
                } catch(error) {
                    console.log("error from project/aws-id : " , error);
                }



                function getCurrentDateTime() {
                    const now = new Date();
                    const year = now.getFullYear();
                    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 +1 해주고 2자리로 포맷팅
                    const day = now.getDate().toString().padStart(2, '0');
                    const hours = now.getHours().toString().padStart(2, '0');

                    return `${year}${month}${day}${hours}`;
                }

                let dlDataList = [];
                let cfDateTime = getCurrentDateTime();


                let dlUrl = '/rds/vehicle/ml_list?'+ cfDateTime;
                
                
                try {
                    console.log(dlUrl);
                    const responseDlData = await common.apiDlGet(dlUrl);
                    dlDataList = responseDlData.data.queryResult.results;
                } catch(error) {
                    console.log("error from /rds/vehicle/ml_list : " , error);
                }
                console.log(dlDataList);

                let mlopsDataList = [];

                for(let i = 0; i<projectDataList.length; i++) {
                    for(let j = 0; j<dlDataList.length; j++) {
                        let projectData = projectDataList[i];
                        let dlData = dlDataList[j];

                        if(projectData.aws_id == dlData.task) {
                            mlopsDataList.push({
                                Data1 : projectData.domain,
                                Data2 : dlData.pipeline_id,
                                Data3 : dlData.recent_date,
                                Data4 : dlData.model_version,
                                prjId : projectData.project_id,
                                pipelineLink:'',
                            });
                        }
                    }
                }

                this.rowData = mlopsDataList;
                console.log(mlopsDataList);

                this.rowDataSliced = this.displayDataPerPage(this.rowData);

                this.pageData.counts = mlopsDataList.length.toLocaleString('ko-KR');
                window.filterData.originalData = mlopsDataList;
                window.filterData.workData = mlopsDataList;
                
                this.currentPage = 1;
                this.totalItems = mlopsDataList.length;

            },

            displayDataPerPage(rowData) {
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                // console.log(rowData);
                return rowData.slice(startIndex, endIndex);
            },
            pipelineLink(item){
                const query= {prjId: item.prjId, mlopsTaskId: item.mlopsTaskId} 
                this.$router.push({
                    name: 'MlopsPiplog', query  });
            },

            listLink(e, item) { // 데이터 목록에서 리스크 클릭시(임시)
                if (this.listType === 'dataList') {
                    this.$router.push({ name: 'DetailDataSet', query: {prjId: item.prjId, edgeId: item.edgeId} });
                }
                else if(this.listType === 'taskList') {
                    // console.log("item : " + item.prjId);
                    const task_type = item.Data3;

                    const query= {
                        prjId: item.prjId, 
                        taskId: item.taskId, 
                        teamId: item.teamId,
                        taskTypeId: item.taskTypeId
                    } 

                    switch(task_type) {
                        case 'Remote Diagnostics': 
                            this.$router.push({
                                name: 'dctDashboard', query  });
                            break;
                        case 'Anomaly Detection': 
                            this.$router.push({
                                name: 'moaDetection' , query });
                            break;
                        case 'Vision Edge Inference Monitoring': 
                            this.$router.push({
                                name: 'EdgeInfer', query
                            });
                            break;
                        case 'Vision Auto Labeling Monitoring': 
                            this.$router.push({ name: 'AutoLabe', query });
                            break;
                        case 'Vision AI/ML Statistics': 
                            this.$router.push({ name: 'VisionData', query});
                            break;
                    }
                }
                else if(this.listType === 'mlopsList') {
                    // const task_type = item.Data3;
                    let query= {prjId: encodeURIComponent(item.prjId), 
                        domain: encodeURIComponent(item.Data1),
                        pipeId: encodeURIComponent(item.Data3), 
                        modelVersion: encodeURIComponent(item.Data5)};

                    
                    if(e.target.classList.contains('piplog')){
                        this.$router.push({
                                 name: 'MlopsPiplog' , query });
                    }else{
                        this.$router.push({
                                 name: 'MlopsObslog' , query });
                    }
                }
            },


        }
    }
</script>
<style scoped lang="scss">
.list_wrap{
    position: relative;
    .table_list{
        margin-top: 110px;
        height: 715px;
    }
    .list_filter_wrap{
        position: absolute;
        top: -90px;
    }
    .mando_table{
        tr{
            cursor: pointer;
        }
    }
    
}
.list_wrap .table_list .mando_table thead th .col_name{
        // min-width: auto !important;
        padding-right: 15px;
    }
</style>