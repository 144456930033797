
<template>
    <div id="loginWrap">
      <div class="login_img_box">
        <div class="txt_box">
          <img src="@/assets/images/login/hlmandologo_wh.svg" alt="HL Mando logo">          
          <span>Our vision is your freedom.</span>
        </div>
      </div>
      <div class="login_form_box">
        <div>
          <strong>비밀번호 변경</strong>
            <div class="input_box">
              <!-- <div id="account" class="float_box">
                <div class="input_title">계정</div>
                <input type="text" v-model="userAccont" class="input_txt" disabled>
              </div> -->
              <!-- <div id="email" class="float_box">
                <div class="input_title">이메일</div>
                <input type="email" v-model="email" class="input_txt" placeholder="이메일을 입력해 주세요.">
                <div class="call_msg"> * 이메일로 코드를 전송했습니다.</div>
                <button class="chk_btn" type="button">전송</button>
              </div> -->
              <div id="pw" class="float_box">
                <div class="input_title">
                    비밀번호 입력
                </div>
                <input type="password" name="pw" ref="pw" maxlength="30" v-model="pw" class="input_txt" placeholder="비밀번호는 영어 대/소문자, 숫자, 특수문자를 조합하여 8~30자로 입력해주세요">
                <div class="text_length">
                    <span>{{pwLength}}</span>/30
                </div>
                <div class="txt_msg">
                    <span v-if="pswdIsInvalid">영어 대/소문자, 숫자, 특수문자를 조합하여 8~30자 </span>
                    <span v-else-if="pswdIsValid">유효한 비밀번호입니다. </span>
                </div>
                
              </div>
              
              <div id="pw_check" class="float_box">
                <div class="input_title">
                    비밀번호 확인
                </div>
                <input type="password" name="pwcheck" ref="pwcheck" maxlength="30" v-model="pwcheck" class="input_txt" placeholder="비밀번호 확인">
                <div class="text_length">
                    <span>{{pwCheckLength}}</span>/30
                </div>
                <div class="txt_msg">
                    <span v-if="pswdIsNotMatch">비밀번호가 일치하지 않습니다. </span>
                    <span v-else-if="pswdIsMatch">비밀번호가 일치합니다. </span>
                </div>
              </div>
              
              <div class="btn_wrap">
                <button type="button" class="backBtn" @click="showModalAlert()">취소</button>
                <button type="button" @click="changePW()" :disabled="!requireCheck">확인</button>
              </div>
            </div>
        </div>
      </div>
      
    </div>
    <div id="modalWrap" ref="modalWrap" style="display: none;">
        <!-- 취소 -->
        <div class="radiusbox modal_contents message_alert message_modal" ref="modalAlert">
            <div class="message_box">
                변경 사항이 저장되지 않았습니다. <br>정말 취소하시겠습니까?
            </div>
            <button type="button" class="btn_check mcbtn" @click="goBackPage()">확인</button>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
    </div>  
</template>
  
<script>
  import { mapState, mapActions } from 'vuex';
  import common from '@/assets/js/common';
  import axios from 'axios';
  import conf from '@/assets/js/conf';

  export default {
    name: 'LoginView',
    computed:{
      ...mapState(['user', 'isLoggedIn']),
      requireCheck() {
        let pwValid = this.pswdIsValid;
        let pwMatch = this.pswdIsMatch;

        if(pwValid === true && pwMatch === true) {
          return true;
        } else {
          return false;
        }
      },
      pwLength() {
        return this.pw.length;
      },
      pwCheckLength() {
        return this.pwcheck.length;
      }
    },
    data(){
      const baseURL = conf.getBffBaseURL();

      return{
        userAccont: "",
        email: "",
        pw: "",
        pwcheck: "",
        pswdIsInvalid: true,
        pswdIsValid: false,
        pswdIsNotMatch: true,
        pswdIsMatch: false,

        pwCode: '',

        url: baseURL,
      };
    },
    watch: {
      pw: function() {
        let validation = (this.pw)
            .match(/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,30}$/);
        if(validation != null) {
            this.pswdIsInvalid = false;
            this.pswdIsValid = true;
        } else {
            this.pswdIsInvalid = true;
            this.pswdIsValid = false;
        }
      },
      pwcheck: function() {
        if(this.pw != "" && this.pw == this.pwcheck) {
            this.pswdIsNotMatch = false;
            this.pswdIsMatch = true;
        } else if(this.pw != "" && this.ps != this.pwcheck) {
            this.pswdIsNotMatch = true;
            this.pswdIsMatch = false;
        } else {
            this.pswdIsNotMatch = null;
            this.pswdIsMatch = false;
        }
    }
    },
    async mounted() {
      await common.loginCheck();
      
      console.log("login page1");

      console.log(this.$store.state.user);
      console.log(this.user);

      this.pwCode = decodeURIComponent(this.$route.params.data);
    },
    methods:{
      ...mapActions(['login', 'logout']), 

      // ----- Modal
      showModalAlert(){
          const $modal = this.$refs.modalWrap;
          const $modalAlert = this.$refs.modalAlert;
          $modal.style.display = "block";
          $modalAlert.style.display = "block";
      },
      closeModal(){
          const $modal = this.$refs.modalWrap;
          const $modalAlert = this.$refs.modalAlert;
          // const $modalMember = this.$refs.modalMember;
          $modal.style.display = "none";
          $modalAlert.style.display = "none";
          // $modalMember.style.display = "none";
      },
      goBackPage(){
          this.$router.push('/login');
      },

      async loginAction(){
      // bukang@breezelab.io
      // mando1234!!
        const credentials = {
          "email": this.userAccont,
          "password": this.password
        }; 
        // const credentials = {
        //   "email": 'bukang@breezelab.io',
        //   "password": 'mando1234!!'
        // }; 
        // console.log(credentials.email);
        // console.log(credentials.password);

        try {
          await this.login(credentials);
        } catch (error) {
          const errorCode = error.response.data.code;
          if(errorCode === 'BEC-F0037') {
            alert("활성화 되지 않은 이메일입니다. 운영자에게 문의하세요");
          }
          return;
        }
        
        common.goHome();
      },

      async changePW() {
        const pw = this.pw;
        const pwAuthCode = this.pwCode;

        const data = {
          password: pw,
          pwAuthCode: pwAuthCode
        };

        const res = await this.postApi("/auth/password", data);
        if(res.status === 200) {
          console.log("status ok");
          this.$router.push({
            name: "Login"
          });
        } else {
          return;
        }

      },

      async postApi(api,data) {
        try {
          const response = await axios.post(this.url + api, data);
          return response.data;

        } catch (error) {
          console.log("error : ", error);
        }
      },
    }
  
}




</script>
  
<style lang="scss" scoped>
    #loginWrap{
      min-width: 1440px;
      width: 100%;
      height: 100vh;
      &>div{
        float: left;
      }
      .login_img_box{
        width: 55%;
        height: 100%;
        background-image: url($baseURL+'login/login_background_1920.jpg');
        background-size: cover;
        background-position: center;
        position: relative;
        .txt_box{
          width: 268px;
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          &>span{
            width: 100%;
            color: $whiteColor;
            display: block;
            margin-top: 10px;
            font-family: $montserrat;
            font-size: $fontMenuTitle;
          }
        }
      }
      .login_form_box{
        width: 45%;
        height: 100%;
        background-color: $whiteColor; 
        position: relative;
        &>div{
          width: 440px;
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          strong{
            display: block;
            font-size: 30px;
            line-height: 35px;
            font-weight: $bold;
            margin-bottom: 11px;
          }
          span{
            color: $placeHolderColor;
            line-height: 18px;
          }
          .input_title{
            margin-bottom: 10px;
            text-align: left;
          }
          .text_length {
            float: right;
            position: absolute;
            bottom: 46px;
            right: 10px;
            font-size: 17px;
            color: #A8A8A8;
            span{
                font-size: 17px;
                color: #A8A8A8;
            }
          }
          .input_txt{
            margin-bottom: 15px;
          }
          .txt_msg{
            // margin-bottom: 15px;
            text-align: left;
          }
          .float_box{
            margin-bottom: 20px;
            position: relative;
          }
          .call_msg{
            height: 40px;
            text-align: left;
            line-height: 39px;
          }
          .chk_btn{
            width:90px;
            height: 40px;
            line-height: 39px;
            border: 1px solid #DEDEDE;
            font-weight: 400;
            background-color: #F2F4F7;
            border-radius: 10px;
            float: right;
          }
          #account{
            margin-top: 40px;
            text-align: left;
          }
          #password{
            margin-top: 30px;
            text-align: left;
          }
          .save_info{
            height: 22px;
            text-align: left;
            margin-top: 10px;
            span{
              display: inline-block;
              margin-left: 8px;
              position: relative;
              top: -5px;
              color: $blockColor;
            }
            a{
              float: right;
              position: relative;
              top: 3px;
              color: $placeHolderColor;
              text-decoration: underline;
            }
            &::after{
              content: "";
              display: block;
              clear: both;
            }
          }
        }
      }
      &::after{
        clear: both;
        content:"";
        display: block;
      }
      .btn_wrap{
        margin-top: 20px;
        button{
          width: 140px;
          height: 50px;
          font-size: 17px;
          font-weight: $bold;
          color: $whiteColor;
          text-align: center;
          box-sizing: border-box;
          background-color: $mainColor;
          border-radius: 10px 10px 10px 10px;
          margin:0 15px;
          &.backBtn{
            color: #020A0A;
            background-color: #fff;
            border: 1px solid #DEDEDE;
            font-weight: 400;
            background-color: #F2F4F7;
          }
        }
        a{
          color: $placeHolderColor;
          span{
            display: inline-block;
            color: $mainColor;
            text-decoration: underline;
            padding-left: 10px;
          }
        }
      }
    }
</style>
  