import { createRouter, createWebHistory } from 'vue-router'
import Cookies from 'js-cookie';

// 연결할 각 컴포넌트 import (src/views폴더 아래 컴포넌트들 생성해둠)
import LoginView from '@/views/LoginView.vue'
import FindView from '@/views/FindPWView.vue'
import ChangeView from '@/views/ChangePWView.vue'

import AddMember from '@/views/AddMember.vue'

import HomeView from '@/views/HomeView.vue'
import WorkspaceView from '@/views/projectOps/WorkspaceView.vue'
import ProjectListView from '@/views/projectOps/ProjectListView.vue'
import AddProjectView from '@/views/projectOps/AddProjectView.vue'
import ModProjectView from '@/views/projectOps/AddProjectView.vue'
import ListView from '@/views/ListView.vue'
import DataListView from '@/views/monTASK/DataListView.vue'
import AddDrivingRecordView from '@/views/dataList/AddDrivingRecordView.vue'
import ModDrivingRecordView from '@/views/dataList/AddDrivingRecordView.vue'
import DetailDataSetView from '@/views/dataList/DetailDataSetView.vue'
import AutoLabeView from '@/views/monTASK/AutoLabeView.vue'
import VisionDataView from '@/views/monTASK/VisionDataView.vue'
import VehicleDashboardView from '@/views/monTASK/VehicleDashboardView.vue'
import DCTDashboardView from '@/views/monTASK/DCTDashboardView.vue'
import MOADetectionView from '@/views/monTASK/MOADetectionView.vue'
import EdgeInferView from '@/views/monTASK/EdgeInferView.vue'
import MonTaskListView from '@/views/monTASK/MonTaskListView.vue'
import AddMonTaskView from '@/views/monTASK/AddMonTaskView.vue'
import MlopsListView from '@/views/monTASK/MlopsListView.vue'
import MLOPipelinelogView from '@/views/monTASK/MLOPipelinelogView.vue'
import MLOObservationlogView from '@/views/monTASK/MLOObservationlogView.vue'
import TeamListView from '@/views/sysOps/TeamListView.vue'
import AddTeamView from '@/views/sysOps/AddTeamView.vue'
import ModTeamView from '@/views/sysOps/AddTeamView.vue'
import DivisionListView from '@/views/sysOps/DivisionListView.vue'
import AddDivisionView from '@/views/sysOps/AddDivisionView.vue'
import ModDivisionView from '@/views/sysOps/AddDivisionView.vue'
import MemberListView from '@/views/sysOps/MemberListView.vue'
import DetailMemberView from '@/views/sysOps/DetailMemberView.vue'
import EdgeDeviceListView from '@/views/deviceNDeliveryManage/EdgeDeviceListView.vue'
import AddEdgeDeviceView from '@/views/deviceNDeliveryManage/AddEdgeDeviceView.vue'
import ModEdgeDeviceView from '@/views/deviceNDeliveryManage/AddEdgeDeviceView.vue'
import DetailEdgeDeviceView from '@/views/deviceNDeliveryManage/DetailEdgeDeviceView.vue'
import FleetGroupListView from '@/views/deviceNDeliveryManage/FleetGroupListView.vue'
import AddFleetGroupView from '@/views/deviceNDeliveryManage/AddFleetGroupView.vue'
import ModFleetGroupView from '@/views/deviceNDeliveryManage/AddFleetGroupView.vue'
import DetailFleetGroupView from '@/views/deviceNDeliveryManage/DetailFleetGroupView.vue'
import NoticeView from '@/views/communication/NoticeView.vue'
import AddNoticeView from '@/views/communication/AddNoticeView.vue'
import TermsView from '@/views/communication/TermsView.vue'
import AddTermsView from '@/views/communication/AddTermsView.vue'
import CSView from '@/views/communication/CSView.vue'
import AddCSView from '@/views/communication/AddCSView.vue'
import MessageView from '@/views/communication/NotificationView.vue'
import AddMessageView from '@/views/communication/AddNotificationView.vue'
import SmartMiningDashboardView from '@/views/monTASK/SmartMiningDashboardView.vue'


import DataView from '@/views/DataView.vue'
import GraghView from '@/views/test/GraghView.vue'
import GraghView2 from '@/views/test/GraghView2.vue'
import Controller from '@/views/test/TimeControlView.vue'
import NaverMap from '@/views/test/MapView.vue'
import NaverMap2 from '@/views/test/MapView2.vue'
import AllPage from '@/views/AllPageView.vue'


// 404 
import NotFoundView from '@/views/NotFoundView.vue'


// 라우터 설계
// requiresAuth true / 로그인이 필요한 페이지
// requiresAuth false / 로그인이 필요없는 페이지
const routes = [
    { path: '/login', name: "Login", component: LoginView},
    { path: "/", redirect: "/login",},
    { path: "/index", redirect: "/login",},
    { path: "/main", redirect: "/login",},

    { path: '/find', name: "Find", component: FindView ,meta: { requiresAuth: false }},
    { path: '/change/:data', name: "Change", component: ChangeView ,meta: { requiresAuth: false }},
    
    { path: '/home', name: "Home", component: HomeView, meta: { requiresAuth: true }},

    { path: '/showActivityLog', name: "showLogs", component: HomeView, meta: { requiresAuth: true }},

    { path: '/list/:type', name: "List", component: ListView, meta: { requiresAuth: true }},

    { path: '/addMember', name: "AddMember", component: AddMember},

    { path: '/projectOps/workspace/:index',name: 'Workspace', component: WorkspaceView, props: true, meta: { requiresAuth: true }},

    // { path: '/projectOps/workspace', name: "Workspace", component: WorkspaceView, meta: { requiresAuth: true }},
    { path: '/projectOps/projectList', name: "projectList", component: ProjectListView, meta: { requiresAuth: true }},
    { path: '/projectOps/addProject', name: "addProject", component: AddProjectView, meta: { requiresAuth: true }},
    { path: '/projectOps/modProject/:prjId', name: "modProject", component: ModProjectView, meta: { requiresAuth: true }},
    { path: '/dataList/addDriving', name: "AddDrivingRecord", component: AddDrivingRecordView, meta: { requiresAuth: true }},
    { path: '/dataList/modDriving', name: "ModDrivingRecord", component: ModDrivingRecordView, meta: { requiresAuth: true }},
    { path: '/dataList/detailDataSet', name: "DetailDataSet", component: DetailDataSetView, meta: { requiresAuth: true }},
    
    { path: '/vehicleDashboard', name: "vehicleDashboard", component: VehicleDashboardView, meta: { requiresAuth: true }},

    { path: '/monTASK/dataList', name: "dataList", component: DataListView, meta: { requiresAuth: true }},
    { path: '/monTASK/monTaskList', name: "monTaskList", component: MonTaskListView, meta: { requiresAuth: true }},
    { path: '/monTASK/addTask', name: "AddTask", component: AddMonTaskView, meta: { requiresAuth: true }},
    { path: '/monTASK/dctDashboard', name: "dctDashboard", component: DCTDashboardView, meta: { requiresAuth: true }},
    { path: '/monTASK/moaDetection', name: "moaDetection", component: MOADetectionView, meta: { requiresAuth: true }},
    { path: '/monTASK/edgeInferencn', name: "EdgeInfer", component: EdgeInferView, meta: { requiresAuth: true }},
    { path: '/monTASK/autoLabe', name: "AutoLabe", component: AutoLabeView, meta: { requiresAuth: true }},
    { path: '/monTASK/visionData', name: "VisionData", component: VisionDataView, meta: { requiresAuth: true }},
    
    { path: '/monTASK/SmartMiningDashboard', name: "SmartMiningDashboard", component: SmartMiningDashboardView, meta: { requiresAuth: true }},

    { path: '/monTASK/mlopsList', name: "mlopsList", component: MlopsListView, meta: { requiresAuth: true }},
    { path: '/monTASK/mlopsPiplog', name: "MlopsPiplog", component: MLOPipelinelogView, meta: { requiresAuth: true }},
    { path: '/monTASK/mlopsObslog', name: "MlopsObslog", component: MLOObservationlogView, meta: { requiresAuth: true }},

    { path: '/sysOps/teamList', name: "teamList", component: TeamListView, meta: { requiresAuth: true }},
    { path: '/sysOps/addTeam', name: "addTeam", component: AddTeamView, meta: { requiresAuth: true }},
    { path: '/sysOps/modTeam/:teamId', name: "modTeam", component: ModTeamView, meta: { requiresAuth: true }},
    
    { path: '/sysOps/memberList', name: "memberList", component: MemberListView, meta: { requiresAuth: true }},
    { path: '/sysOps/detailMember/:memberId', name: "detailMember", component: DetailMemberView, meta: { requiresAuth: true }},
    
    { path: '/sysOps/divisionList', name: "divisionList", component: DivisionListView, meta: { requiresAuth: true }},
    { path: '/sysOps/addDivision', name: "addDivision", component: AddDivisionView, meta: { requiresAuth: true }},
    { path: '/sysOps/modDivision/:divsonId', name: "modDivision", component: ModDivisionView, meta: { requiresAuth: true }},

    { path: '/deviceNDelivery/edgeDeviceList', name: "edgeDeviceList", component: EdgeDeviceListView, meta: { requiresAuth: true }},
    { path: '/deviceNDelivery/addEddgeDevice', name: "addEddgeDevice", component: AddEdgeDeviceView, meta: { requiresAuth: true }},
    { path: '/deviceNDelivery/modEddgeDevice/:edgeId', name: "modEddgeDevice", component: ModEdgeDeviceView, meta: { requiresAuth: true }},
    { path: '/deviceNDelivery/detailEdgeDevice/:edgeId', name: "detailEdgeDevice", component: DetailEdgeDeviceView, meta: { requiresAuth: true }},

    { path: '/deviceNDelivery/fleetGroupList', name: "fleetGroupList", component: FleetGroupListView, meta: { requiresAuth: true }},
    { path: '/deviceNDelivery/addFleetGroup', name: "addFleetGroup", component: AddFleetGroupView, meta: { requiresAuth: true }},
    { path: '/deviceNDelivery/modFleetGroup/:fleetGroupId', name: "modFleetGroup", component: ModFleetGroupView, meta: { requiresAuth: true }},
    { path: '/deviceNDelivery/detailFleetGroup/:fleetGroupId', name: "detailFleetGroup", component: DetailFleetGroupView, meta: { requiresAuth: true }},

    { path: '/commu/notice', name: "noticeList", component: NoticeView, meta: { requiresAuth: true }},
    { path: '/commu/addNotice', name: "addNotice", component: AddNoticeView, meta: { requiresAuth: true }},
    { path: '/commu/terms', name: "termsList", component: TermsView, meta: { requiresAuth: true }},
    { path: '/commu/addTerms', name: "addTerms", component: AddTermsView, meta: { requiresAuth: true }},
    { path: '/commu/cs', name: "csList", component: CSView, meta: { requiresAuth: true }},
    { path: '/commu/addCS', name: "addCS", component: AddCSView, meta: { requiresAuth: true }},
    { path: '/commu/message', name: "messageList", component: MessageView, meta: { requiresAuth: true }},
    { path: '/commu/addMessage', name: "addMessage", component: AddMessageView, meta: { requiresAuth: true }},
    
    { path: '/data', name: "Data", component: DataView, meta: { requiresAuth: false }},
    { path: '/gragh', name: "Gragh", component: GraghView, meta: { requiresAuth: false }},
    { path: '/gragh2', name: "Gragh2", component: GraghView2, meta: { requiresAuth: false }},
    { path: '/timeController', name: "Controller", component: Controller, meta: { requiresAuth: false }},
    { path: '/map', name: "NaverMap", component: NaverMap, meta: { requiresAuth: false }},
    { path: '/map2', name: "NaverMap2", component: NaverMap2, meta: { requiresAuth: false }},
    { path: '/allpage', name: "AllPage", component: AllPage, meta: { requiresAuth: false }},


    { path: '/404', name: "NotFound", component: NotFoundView, meta: { requiresAuth: true }},
    { path: "/:pathMatch(.*)*", redirect: "/404",},
    

]

// 라우터 생성
const router = createRouter({
    // history: createWebHistory(process.env.VUE_APP_S3_URL),
    history: createWebHistory(),
    // history: createWebHashHistory(process.env.BASE_URL),
    routes
});



router.beforeEach((to, from, next) => {
    const cookiesJWT = Cookies.get('jwt');
    if(to.meta.requiresAuth){
        // 해당 라우트가 인증을 필요로 하는 페이지일 경우
        if(cookiesJWT){
            // 로그인 됨 
            next();
        }else{
            // 로그인이 안됨
            console.log("로그인 필요");
            next("/login");
        }
    }else{
        // 해당 라우트가 인증을 필요로 하는 페이지가 안닐 경우
        console.log("로그인 불필요");
        next();
    }


    // if(to.meta.requiresAuth && !isLoggedIn) {
    //   // 로그인이 필요한 페이지에 접근하려고 하지만 로그인되어 있지 않을 때
    //   next('/login'); // 로그인 페이지로 리다이렉트
    // }else if(to.name === 'Login' && isLoggedIn) {
    //   // 로그인 상태에서 로그인 페이지로 접근하려고 할 때
    //   next('/home'); // 대시보드 페이지로 리다이렉트
    // }else{
    //   next(); // 로그인이 필요하지 않은 페이지이거나 로그인되어 있는 경우
    // }
    
});


// 라우터 추출 (main.js에서 import)
export {router} 