<template>
    <footer id="footer">
        <img src="@/assets/images/common/footer_hlmandologo.svg" alt="HL Mando footer logo" class="footer_logo">
        <span>Copy right 2023 HL Mando Corp. ALL RIGHTS RESERVED</span>
        <img src="@/assets/images/common/footer_micosa.svg" alt="micosa logo" class="micosa_logo">
    </footer>    
</template>
<script>
    export default{
        name: 'FooterLayout'
    }
</script>
<style lang="scss">
    #footer{
        width: 100%;
        height: 50px;
        background-color: #445053;
        display: flex;
        justify-items: center;
        justify-content: center;
        position: relative;
        .footer_logo{
            width: 120px;
            display: inline-block;
            position: absolute;
            top: 16px;
            left: 150px;
        }
        .micosa_logo{
            width: 74px;
            display: inline-block;
            position: absolute;
            top: 16px;
            right: 68px;
        }
        span{
            display: inline-block;

            color: #E3E3E3;
            display: block;
            text-align: center;
            padding-left: 40px;
            line-height: 50px;
        }
    }
    @media screen and (min-width: 1680px) {
    
    #footer{
        span{
            padding-left: 300px;
        }
        .footer_logo{
            left: 400px;
        }
    }

    

    }

</style>