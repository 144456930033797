
<script setup>
    import { ref } from "vue";
    import { NaverMap, NaverPolyline, NaverMarker} from "vue3-naver-maps";

    //path 
    const path = ref([
        { lat: 36.4145180, lng: 129.37381 },
        { lat: 36.414555, lng: 129.373835 },
        { lat: 36.414518, lng: 129.37381 },
    ]);


    const map = ref();
    const mapOptions = {
        latitude: 36.4145180, // 지도 중앙 위도
        longitude: 129.37381, // 지도 중앙 경도
        zoom: 17,
        zoomControl: false,
        zoomControlOptions: { position: "TOP_RIGHT" },
    };
    const initLayers = [
        "BACKGROUND",
        "BACKGROUND_DETAIL",
        "POI_KOREAN",
        "TRANSIT",
        "ENGLISH",
    ];

    const onLoadMap = (mapObject) => {
        map.value = mapObject;
    };
    

    const marker = ref();
    const onLoadMarker = (markerObject) => {
        marker.value = markerObject;
    };
</script>
<template>
    <h1>Naver Map API</h1>
    <ul>
        <li>센터움직이는 동작</li>
        <li>마커 등록</li>
        <li>폴리라인 그리기</li>
        <li>마킹 다중 폴리라인</li>
    </ul>
    <div>
        <naver-map id="map" style="width: 100%; height: 400px" :mapOptions="mapOptions" :initLayers="initLayers" @onLoad="onLoadMap($event)" >
            <naver-polyline :path="path"/>
            <naver-marker
            :latitude="36.4145180"
            :longitude="129.37381"
            @onLoad="onLoadMarker($event)"
            >
            </naver-marker>
        </naver-map>
    </div>
    
</template>


<script>
export default {
    data(){
        return {
           
        }
    },
    mounted(){
        
        console.log("asdasd");
        
    },
    methods:{
  
    }
}
</script>


<style scoped>

</style>
  

