<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap" v-bind:style="teamId === '' ? '' : 'display:none'">
                <div class="breadcrumb">
                    <span>시스템 운영</span>
                    <span class="active">Team 생성</span>
                </div>
                <h2>Team 생성</h2>
            </div>
            <div class="title_wrap" v-bind:style="teamId === '' ? 'display:none' : ''">
                <div class="breadcrumb">
                    <span>시스템 운영</span>
                    <span class="active">Team 관리</span>
                </div>
                <h2>Team 관리</h2>
                <div class="title_btn">
                    <button type="button" class="btn_view_log" v-if="isTeamManager" style="{display: none;}" @click="showLogModal()">Team 수정 로그 보기</button>
                </div>
            </div> -->
            <BreadcrumbLayout :pageId="pageId" :checkRole="viewRole"/>
            <div id="management">
                <div class="row_box">
                    <div class="data_key">Team Name</div>
                    <div class="data_val" v-bind:class="duplicateYN === true ? 'failure' : ''">
                        <input type="text" v-bind:disabled="!manageRole" name="teamNm" ref="teamNm" maxlength="50" v-model="teamNm" placeholder="입력해주세요.">
                        <div class="text_message" v-bind:style="duplicateYN === true ? '' : 'display:none'">중복된 Team Name입니다.</div>
                        <div class="text_length">
                            <span>{{teamNmLen}}</span>/50
                        </div>
                    </div>
                </div>
                <div class="row_box two_item">
                    <div>
                        <div class="data_key">Team Type</div>
                        <div class="data_val">
                            <select name="teamTypeId" v-bind:disabled="!manageRole" ref="teamTypeId" :value="teamTypeId" @change="setTeamType($event)">
                                <option value="" disabled selected>선택해주세요.</option>
                                <option v-for="(item, index) in teamTypeList" :key="index" :value="item.id">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <div class="data_key">Division</div>
                        <div class="data_val">
                            <select name="division" v-bind:disabled="!manageRole" ref="division" :value="divisonId" @change="setDivisonId($event)">
                                <option value="" disabled selected>선택해주세요.</option>
                                <option v-for="(item, index) in divisionList" :key="index" :value="item.id">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row_box textarea_item">
                    <div class="data_key">Team Description</div>
                    <div class="data_val">
                        <textarea name="desc" v-bind:disabled="!manageRole" ref="desc" maxlength="200" v-model="desc" placeholder="입력해주세요."></textarea>
                        <div class="text_length">
                            <span>{{descLen}}</span>/200
                        </div>
                    </div>
                </div>
                <div class="row_box toggle_item">
                    <div class="data_key">Permission Setting</div>
                    <div class="data_val">
                        <ul>
                            <li>
                                원격제어
                                <div class="toggle_box">
                                    <input type="checkbox" v-bind:disabled="!manageRole" name="remoteControl" id="remoteControl" value="remoteControl" v-model="permSetChecked">
                                    <label for="remoteControl"></label>
                                </div>
                            </li>
                            <li>
                                데이터 외부 공유
                                <div class="toggle_box">
                                    <input type="checkbox" v-bind:disabled="!manageRole" name="externalShare" id="externalShare" value="externalShare" v-model="permSetChecked">
                                    <label for="externalShare"></label>
                                </div>
                            </li>
                            <li>
                                데이터 분석/MLOps 활용
                                <div class="toggle_box">
                                    <input type="checkbox" v-bind:disabled="!manageRole" name="dataAnalyticsMLOps" id="dataAnalyticsMLOps" value="dataAnalyticsMLOps" v-model="permSetChecked">
                                    <label for="dataAnalyticsMLOps"></label>
                                </div>
                            </li>
                            <li>
                                외부 프레임워크 사용
                                <div class="toggle_box">
                                    <input type="checkbox" v-bind:disabled="!manageRole" name="externalFramework" id="externalFramework" value="externalFramework" v-model="permSetChecked">
                                    <label for="externalFramework"></label>
                                </div>
                            </li>
                            <li>
                                자체 API 외부 송출
                                <div class="toggle_box">
                                    <input type="checkbox" v-bind:disabled="!manageRole" name="externalFramework" id="externalAPI" value="externalAPI" v-model="permSetChecked">
                                    <label for="externalAPI"></label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row_box add_item">
                    <div class="data_key">
                        Team 멤버
                    </div>
                    <div class="data_val active">
                        <div class="add_box table_list">
                            <div class="total_item">
                                <span>{{ mainMemberList.length }} </span> Members
                            </div>
                            <button class="btn_add_item" v-if="manageRole" v-bind:style="divisonId === '' ? 'display:none' : ''" @click="showModalMember()"></button>
                            <div class="none_item" v-bind:style="mainMemberList.length > 0 ? 'display:none' : ''">
                                <div>프로젝트 멤버가 없습니다.</div>
                                <button class="btn_add_item" v-if="manageRole" v-bind:style="divisonId === '' ? 'display:none' : ''" @click="showModalMember()">멤버 추가</button>
                            </div>
                            <div class="mando_table_wrap" v-bind:style="mainMemberList.length > 0 ? '' : 'display:none'">
                                <table class="mando_table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div class="col_name">
                                                    Name
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Team
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Role
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Recent Login
                                                </div>
                                            </th>
                                            <th>
                                                <div class="col_name">
                                                    Email
                                                </div>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody id="mainMemberTbody">
                                        <tr v-for="(item, index) in mainMemberList" :key="index">
                                            <td>
                                                <div class="profile_img_box">
                                                    <img src="https://d2rsr3zb8mvgil.cloudfront.net/images/member/member_sample.png" alt="">
                                                </div>
                                                <span class="user_name">{{item.name}}</span>
                                                
                                            </td>
                                            <td>
                                                {{item.teamName}}
                                            </td>
                                            <td>
                                                <select name="" v-bind:disabled="!manageRole" :id="'selectRoleId' + item.memberId" style="width: 200px;background-position: 170px;" :value="item.teamRoleId" @change="setTeamRole(item, $event)">
                                                    <option v-for="(item, index) in memberRoleList" :key="index" :value="item.id">
                                                        {{item.role}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                {{item.recentLogin}}
                                            </td>
                                            <td>
                                                {{item.email}}
                                            </td>
                                            <td>
                                                <button type="button" v-if="manageRole" class="btn_del" :data-index="item.memberId" @click="clickDelMemberList(item.memberId)"></button>
                                            </td>
                                            <td data-index="memberId" style="display:none">
                                                {{item.memberId}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="save_box">
                    <button type="button" @click="showModalAlert()">취소</button>
                    <button type="button" class="mcbtn" @click="clickRegist()" :disabled="!requireCheck">저장</button>
                </div>
            </div>
        </div>
    </div>
    <div id="modalWrap" ref="modalWrap" style="display: none;">
        <!-- 취소 -->
        <div class="radiusbox modal_contents message_alert message_modal" ref="modalAlert">
            <div class="message_box">
                변경 사항이 저장되지 않았습니다. <br>정말 취소하시겠습니까?
            </div>
            <button type="button" class="btn_check mcbtn" @click="goBackPage()">확인</button>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>
        <!-- 맴버 등록 -->
        <div class="radiusbox modal_contents add_item_modal add_member" ref="modalMember" style="display : none;">
            <strong class="modal_title">
                프로젝트 멤버 추가
            </strong>
            <div class="filter_box">
                <input type="text" name="memberKeyword" ref="memberKeyword" @keydown.enter="clickSearchMember()" placeholder="이름, 팀, 이메일 주소 검색가능">
                <button type="button" class="btn_search" @click="clickSearchMember()"></button>
            </div>
            <div class="float_box table_list">
                <div class="select_box mando_table_wrap">
                    <table class="mando_table">
                        <colgroup>
                            <col style="width: 20%">
                            <col style="width: 20%">
                            <col style="width: 60%">
                        </colgroup>
                        <thead>
                                <tr>
                                    <th>
                                        <div class="all_chk">
                                            <input type="checkbox" id="allChkMember" v-model="allSelctMember">
                                            <label for="allChkMember"></label>
                                            <span>Name</span>  
                                        </div>
                                    </th>
                                    <th>Team</th>
                                    <th>E-mail</th>
                                </tr>
                            </thead>
                        <tbody id="memberTbody">
                            <tr v-for="(item, index) in memberRowData" :data-index="index" :key="index">
                                <td>
                                    <div class="chk_item">
                                        <input type="checkbox" :id="'MemberItem' + item.memberId" :value="item" v-model="unSelectMemberList">
                                        <label :for="'MemberItem' + item.memberId"></label>
                                        <span>{{item.name}}</span>
                                    </div>
                                </td>
                                <td>
                                    {{item.teamName}}
                                </td>
                                <td>
                                    {{item.email}}
                                </td>
                                <td style="display:none">
                                    {{ item.memberId }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="choose_box">
                    <div class="btn_wrap">
                        <button type="button" class="btn_add" @click="clickAddMember()">추가</button>
                        <button type="button" class="btn_add_del" @click="clickDelMember()">삭제</button>
                    </div>
                </div>
                <div class="select_box">
                    <div class="table_title">
                        <strong>선택 항목</strong>
                    </div>
                    <div class="mando_table_wrap">
                        <table class="mando_table">
                            <colgroup>
                                <col style="width: 20%">
                                <col style="width: 20%">
                                <col style="width: 60%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <div class="all_chk">
                                            <input type="checkbox" id="allChkSelectedMember" v-model="allSelctedMember">
                                            <label for="allChkSelectedMember"></label>
                                            <span>Name</span>  
                                        </div>
                                    </th>
                                    <th>Team</th>
                                    <th>E-mail</th>
                                </tr>
                            </thead>
                            
                            <tbody id="selectMemberTbody">
                                <tr v-for="(item, index) in selectMemberRowData" :data-index="index" :key="index">
                                    <td>
                                        <div class="chk_item">
                                            <input type="checkbox" :id="'MemberSelectedItem' + item.memberId" :value="item" v-model="selectedMemberList">
                                            <label :for="'MemberSelectedItem' + item.memberId"></label>
                                            <span>{{item.name}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        {{item.teamName}}
                                    </td>
                                    <td>
                                        {{item.email}}
                                    </td>
                                    <td style="display:none">
                                        {{ item.memberId }}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr class="hover_none" v-bind:style="selectMemberRowData.length > 0 ? 'display:none' : ''">
                                    <td colspan="3">
                                        <div class="none_itme">
                                            <div class="message">
                                                프로젝트 멤버를 추가 해 주세요.
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="check_wrap">
                <button type="button" class="btn_check mcbtn" @click="clickCompleteMember()" v-bind:disabled="selectMemberRowData.length === 0">완료</button>   
            </div>
            <button type="button" class="btn_close" @click="closeModal()"></button>


        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';

    import common from '@/assets/js/common'
    export default {
        name: 'addTeam',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            BreadcrumbLayout,
        },
        computed:{
            teamNmLen() {
                return this.teamNm.length;
            },
            descLen() {
                return this.desc.length;
            },

            allSelctMember: {
                //getter
                get: function() {
                    return this.memberRowData.length === this.unSelectMemberList.length;
                },
                //setter
                set: function(e) {
                    this.unSelectMemberList = e ? this.memberRowData : [];
                },
            },
            allSelctedMember: {
                //getter
                get: function() {
                    return this.selectMemberRowData.length === this.selectedMemberList.length;
                },
                //setter
                set: function(e) {
                    this.selectedMemberList = e ? this.selectMemberRowData : [];
                },
            },

            requireCheck() {
                return this.manageRole && this.teamNmLen > 0 && this.teamTypeId !== '' && this.divisonId !== '';
            },
        },
        data(){
            const teamId = "";
            const teamNm = "";
            const desc = "";
            const teamTypeId = "";
            const divisonId = "";

            const duplicateYN = false;

            const memberRowData = ([]);
            const selectMemberRowData= ([]);

            return{
                teamId,
                teamNm,
                desc,
                teamTypeId,
                divisonId,

                duplicateYN,

                permSetChecked: [],
                memberRoleList: [],

                teamTypeList: [],
                divisionList: [],

                memberRowData,
                selectMemberRowData,

                originMainMemberList: [],

                unSelectMemberList: [],
                selectedMemberList: [],
                mainMemberList: [],

                pageId: "sysOps_addTeam",
                bcParams: [],

                // role
                viewRole: false,
                manageRole: false,
            }
        },
        mounted() {
            // Role
            this.setRole();

            this.teamId = this.$route.params.teamId;

            if ( typeof this.teamId != "undefined" && this.teamId != null && this.teamId != "" ) {
                this.pageId = "sysOps_modTeam";
                this.setPage();
            } else {
                this.pageId = "sysOps_addTeam";
                this.teamId = "";
            }
            
            this.setSelectBox();
        },
        methods:{
            async setRole() {
                this.manageRole = await common.getUserRole('type3');
                this.viewRole = await common.getUserRole('type2');

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            async setSelectBox() {
                this.teamTypeList = await this.getTeamTypeList();
                this.divisionList = await this.getDivisionList();
                this.memberRoleList = await this.getTeamRoleList();
            },

            async setPage() {
                const teamData = await this.getTeamData(this.teamId);

                if(typeof teamData !== "undefined" && teamData !== null && teamData !== "") {
                    const team = teamData.team;
                    const memberDtoList = teamData.memberDtoList;
    
                    this.teamNm = team.name;
                    this.teamTypeId = team.team_type_id;
                    this.divisonId = team.division_id;
                    this.desc = team.description;
    
                    if ( team.remote_control === 'Y' ) {
                        this.permSetChecked.push('remoteControl');
                    }
                    if ( team.data_sharing === 'Y' ) {
                        this.permSetChecked.push('externalShare');
                    }
                    if ( team.data_mlops === 'Y' ) {
                        this.permSetChecked.push('dataAnalyticsMLOps');
                    }
                    if ( team.external_framework === 'Y' ) {
                        this.permSetChecked.push('externalFramework');
                    }
                    if ( team.external_api === 'Y' ) {
                        this.permSetChecked.push('externalAPI');
                    }
    
                    const mainMemberList = this.setMemberData(memberDtoList);
                    mainMemberList.forEach(item => {
                        this.mainMemberList.push(item);
                        this.selectMemberRowData.push(item);
                        this.originMainMemberList.push(item);
                    });
                }
            },

            // ----- Modal
            async showModalMember(){
                if ( this.manageRole ) {
                    const $modal = this.$refs.modalWrap;
                    const $modalMember = this.$refs.modalMember;
                    $modal.style.display = "block";
                    $modalMember.style.display = "block";
    
                    this.$refs.memberKeyword.value = "";
    
                    const divisonId = this.divisonId;
                    const getMemberList = this.getMemberList(divisonId);
    
                    this.selectMemberRowData = ([]);
                    this.mainMemberList.forEach(item => {
                        this.selectMemberRowData.push(item);
                    });
    
                    this.setMemberTable(getMemberList);
                }
            },
            showModalAlert(){
                if ( this.manageRole ) {
                    const $modal = this.$refs.modalWrap;
                    const $modalAlert = this.$refs.modalAlert;
                    $modal.style.display = "block";
                    $modalAlert.style.display = "block";
                } else {
                    this.goBackPage();
                }
            },
            closeModal(){
                const $modal = this.$refs.modalWrap;
                const $modalMember = this.$refs.modalMember;
                const $modalAlert = this.$refs.modalAlert;
                $modal.style.display = "none";
                $modalAlert.style.display = "none";
                $modalMember.style.display = "none";
            },
            goBackPage(){
                this.$router.push('/sysOps/teamList');
            },

            setTeamType(event) {
                this.teamTypeId = event.target.value;
            },

            setDivisonId(event) {
                this.divisonId = event.target.value;
            },

            setMemberTable(memberList) {
                memberList.then(list => {
                    const dataList = [];
                    list.forEach(element => {
                        dataList.push({
                            name: element.name,
                            teamName: element.teamName,
                            email: element.email,
                            memberId: element.id,
                            profileImage: element.profileImage,
                            recentLogin: element.recentLogin,
                            teamRoleId: element.roleId,
                        });
                    });

                    if ( this.originMainMemberList.length > 0 ) {
                        this.originMainMemberList.forEach(item => {
                            dataList.push(item);
                        });
                    }
                    
                    this.memberRowData = common.deleteRowData(dataList, this.selectMemberRowData, 'memberId');
                });
            },

            setMemberData(memberList) {
                const dataList = [];
                memberList.forEach(item => {
                    dataList.push({
                        name: item.name,
                        teamName: item.teamName,
                        email: item.email,
                        memberId: item.id,
                        profileImage: item.profileImage,
                        recentLogin: item.lastLogin,
                        teamRoleId: item.roleId
                    });

                });
                return dataList;
            },

            setTeamRole(item, event) {
                const memberId = item.memberId;
                const roleId = event.target.value;

                this.mainMemberList.forEach(item => {
                    if ( item.memberId === memberId ) {
                        item.teamRoleId = roleId;
                    }
                });
            },

            // ----- click
            async clickSearchMember() {
                const keyword = this.$refs.memberKeyword.value.toUpperCase();

                if(typeof keyword == "undefined" || keyword == null || keyword == "") {
                    const divisonId = this.divisonId;
                    const getMemberList = this.getMemberList(divisonId);
                    this.setMemberTable(getMemberList);

                } else {
                    this.setSearchMember(keyword);
                }
            },
            
            setSearchMember(keyword) {
                const searchRow = [];
    
                this.memberRowData.forEach(item => {
                    let name = common.getUpper(item['name']);
                    let teamName = common.getUpper(item['teamName']);
                    let email = common.getUpper(item['email']);

                    if ( name.includes(keyword) || teamName.includes(keyword) || email.includes(keyword) ) {
                        searchRow.push(item);
                    }
                });

                this.memberRowData = ([]);
                
                searchRow.forEach(item => {
                    this.memberRowData.push(item);
                });
            },

            clickAddMember() {
                if ( this.unSelectMemberList.length > 0 ) {
                    this.unSelectMemberList.forEach(item => {
                        this.selectMemberRowData.push(item);
                    });

                    this.memberRowData = common.deleteRowData(this.memberRowData, this.unSelectMemberList, "memberId");
                    this.unSelectMemberList = [];
                }
            },

            clickDelMember() {
                if ( this.selectedMemberList.length > 0 ) {
                    this.selectedMemberList.forEach(item => {
                        this.memberRowData.push(item);
                    });
                    
                    this.selectMemberRowData = common.deleteRowData(this.selectMemberRowData, this.selectedMemberList, "memberId");
                    this.selectedMemberList = [];

                    const keyword = this.$refs.memberKeyword.value.toUpperCase();
                    if ( typeof keyword != "undefined" && keyword != null && keyword != "" ) {
                        this.setSearchMember(keyword);
                    }
                }
            },
            
            clickCompleteMember() {
                this.mainMemberList = [];
                this.selectMemberRowData.forEach(item => {
                    this.mainMemberList.push(item);
                });
                this.closeModal();
            },

            clickDelMemberList(id) {
                let i = this.mainMemberList.length;
                while ( i-- ) {
                    let item = this.mainMemberList[i];
                    if ( item.memberId == id ) {
                        this.mainMemberList.splice(i, 1);
                        this.memberRowData.push(item);
                    }
                }
                let j = this.selectMemberRowData.length;
                while ( j-- ) {
                    let item = this.selectMemberRowData[j];
                    if ( item.memberId == id ) {
                        this.selectMemberRowData.splice(j, 1);
                    }
                }
            },

            async clickRegist() {
                if ( this.requireCheck === true ) {
                    const memberRoles = [];
                    const mainMemberTbody = document.querySelectorAll("#mainMemberTbody > tr");
                    mainMemberTbody.forEach(trElement => {
                        const memberId = parseInt(trElement.querySelector('td[data-index="memberId"]').innerHTML);
                        const teamRoleId = parseInt(trElement.querySelector('select').value);
    
                        memberRoles.push({
                            memberId: memberId,
                            roleId: teamRoleId
                        });
                    });

                    const postData = {
                        "name": this.$refs.teamNm.value,
                        "team_type_id": this.teamTypeId,
                        "division_id": this.divisonId,

                        "description": this.$refs.desc.value,

                        "remote_control": await this.getCheckYn(this.permSetChecked, 'remoteControl'),
                        "data_sharing": await this.getCheckYn(this.permSetChecked, 'externalShare'),
                        "data_mlops": await this.getCheckYn(this.permSetChecked, 'dataAnalyticsMLOps'),
                        "external_framework": await this.getCheckYn(this.permSetChecked, 'externalFramework'),
                        "external_api": await this.getCheckYn(this.permSetChecked, 'externalAPI'),

                        "memberRoles": memberRoles
                    };

                    let responseCode = "";
                    const teamId = this.teamId;
                    if ( teamId != '' && teamId > 0) {
                        responseCode = await this.updateTeam(teamId, postData);
                    } else {
                        responseCode = await this.addTeam(postData);
                    }

                    if ( responseCode === 200 || responseCode === "200" ) {
                        this.$router.push('/sysOps/teamList');
                    } else if ( responseCode === "BEC-F0025" ) {
                        this.duplicateYN = true;
                        this.$refs.teamNm.focus();
                    }
                }
            },

            async getCheckYn(list, value) {
                let result = 'N';

                list.forEach(item => {
                    if ( item === value ) {
                        result = 'Y';
                    }
                });

                return result;
            },

            // ----- API 
            async getTeamData(teamId){
                try {
                    const response = await common.apiGet('/team/info/' + teamId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                    const errStatus = error.response.status;
                    if(errStatus === 403) {
                        alert("권한 부족");
                        window.history.back();
                        return;
                    }
                }
            },
            async getTeamTypeList() {
                try {
                    const response = await common.apiGet('/team-type');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getDivisionList() {
                try {
                    const response = await common.apiGet('/division');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getTeamRoleList() {
                try {
                    const response = await common.apiGet('/member-role/team');
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getMemberList(divisonId) {
                try {
                    const response = await common.apiGet(`/member/division-only/${divisonId}/members`);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async addTeam(postData) {
                let responseCode = "";
                try {
                    const response = await common.apiPost('/team', postData);
                    responseCode = response.data.status;
                } catch (error) {
                    responseCode = error.response.data.code;
                    console.error('Error posting data:', error);
                }
                return responseCode;
            },

            async updateTeam(teamId, postData) {
                let responseCode = "";
                try {
                    const response = await common.apiPatch('/team/' + teamId, postData);
                    responseCode = response.data.status;
                } catch (error) {
                    responseCode = error.response.data.code;
                    console.error('Error posting data:', error);
                }
                return responseCode;
            },
            
            async getMemberRole() {
                let response;
                try {
                    let url = `/member/check-role`;
                    response = await common.apiGet(url);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return response;
                }
            },

            // showLogModal(){
            //     const $modal = this.$refs.modalWrap;
            //     $modal.style.display = "block";
            //     this.recent_project_activity_list_modal();
            // },
            // closeLogModal(){
            //     const $modal = this.$refs.modalWrap;
            //     $modal.style.display = "none";
            // },
        },
    }
</script>
<style scoped lang="scss">
#modalWrap{
    
    .log_filter_modal {
        .list_wrap {
            .table_list {
                .total_num{
                    padding-bottom: 20px;
                }
            }
        }
        #recentFilter{
            min-height: 70px;
            ul.float_box{
                margin-top: 0;
                height: auto;
            }
        }
        .table_list{
            .filter_box{
                width: 500px;
                position: absolute;
                top: 10px;
                right: 20px;
                .date_filter{
                    &::after{
                        display: none;
                    }
                }
            }
        }
    }
} 
</style>