const bffBaseURL = 'https://api.mcsp.micosa.io';

const dlConfig = {
    baseURL: 'https://api.mcsp.micosa.io',
    key: 'mando',
    role: 'arn:aws:iam::802844773267:role/c-apne2-dlfe-role-13-query_demo'
}
  
const dlMDConfig = {
    baseURL: 'https://x9eylgsqmg.execute-api.ap-northeast-2.amazonaws.com',
    key: 'vphmapi1',
    role: 'arn:aws:iam::802844773267:role/c-apne2-dlfe-role-13-query_demo'
}

const dlMBConfig = {
    baseURL: 'https://cd70xlwu1j.execute-api.ap-northeast-2.amazonaws.com',
    key: 'vehicle_key_1',
    role: 'arn:aws:iam::802844773267:role/c-apne2-dlfe-role-13-query_demo'
}

const dlMFConfig = {
    // 테스트 주소
    // baseURL: 'https://b5cpwcgot0.execute-api.ap-northeast-2.amazonaws.com',
    // 실 주소
    baseURL: 'https://637ye1vdp2.execute-api.ap-northeast-2.amazonaws.com',
    key: 'sfskey1',
    role: 'arn:aws:iam::802844773267:role/c-apne2-dlfe-role-13-query_demo'
}

const cdnAddrURL = 'https://cdn.mcsp.micosa.io';

const ai_dt_baseURL = "http://172.20.102.115:8000";

// const pdf_to_md_baseURL = "http://localhost:8000";
const pdf_to_md_baseURL = "https://f4pr4ra9og.execute-api.ap-northeast-2.amazonaws.com";

const conf = {
    getBffBaseURL() {
        return bffBaseURL;
    },
    getDlConfig() {
        return dlConfig;
    },
    getDlMDConfig() {
        return dlMDConfig;
    },
    getDlMBConfig() {
        return dlMBConfig;
    },
    getDlMFConfig() {
        return dlMFConfig;
    },
    getAIDTBaseURL() {
        return ai_dt_baseURL;
    },
    getPdfToMd_baseURL(){
        return pdf_to_md_baseURL;
    },
    getCdnAddrURL() {
        return cdnAddrURL;
    },
}

export default conf ;
