<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>데이터/ TASK 운영</span>
                    <span>MLOps TASK 목록</span>
                    <span class="active">MLOps – Pipeline Log 모니터링</span>
                </div>
                <h2>MLOps – Pipeline Log 모니터링</h2>
            </div> -->
            <BreadcrumbLayout pageId="monTASK_mloPipeline" />
            <div class="item_info">
                <div class="item_title">
                    <strong class="task_project">[PROJECT] {{pjtData.project_name}}</strong>
                    <div class="item_info_btn_box">
                        <button type="button" class="btn_fold" v-bind:style="prjInfoToggle ? 'display:none': ''" @click="clickTogglePrj()">접기</button>
                        <button type="button" class="btn_fold active" v-bind:style="!prjInfoToggle ? 'display:none': ''" @click="clickTogglePrj()">펼치기</button>
                        <button type="button" class="btn_evt_group" @click="evtBox($event)"></button>
                        <ul class="evt_btn_box radiusbox">
                            <li class="download_item">
                                <button type="button">데이터 다운로드</button>
                            </li>
                            <li class="share_item">
                                <button type="button">공유하기</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item_table radiusbox" v-bind:style="prjInfoToggle ? 'display:none': ''">
                    <table>
                        <thead>
                            <tr>
                                <th>Project Name</th>
                                <th>AWS ID</th>
                                <th>PM</th>
                                <th>Project Type</th>
                                <th>Unit</th>
                                <th>Last Activity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{pjtData.project_name}}</td>
                                <td>{{pjtData.aws_id}}</td>
                                <td>{{pjtData.pm}}</td>
                                <td>{{pjtData.project_type}}</td>
                                <td>{{pjtData.team_name}}</td>
                                <td>{{pjtData.last_activity}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="item_desc">
                        <div class="desc_title">Description</div>
                        <div class="desc_txt">{{pjtData.project_description}}</div>
                    </div>
                </div>
                <div class="float_box">
                    <div class="log_filter radiusbox">
                        <!-- <strong>Filter</strong> -->
                        <strong>Filter</strong>
                        <ul class="log_filter_list">
                            <li class="active" style="z-index: 5;">
                                <button type="button">기간</button>
                                <!-- <button @click="uiAcodion($event)" type="button">기간</button> -->
                                <div class="log_filter_item">
                                    <select @change="dateSelectChange" v-model="dateSelectOption" style="width: 100%; background-position: 230px center; margin-top: 5px;">
                                        <option value="1" selected>1일</option>
                                        <option value="7">7일</option>
                                        <!-- <option value="14">7일</option> -->
                                        <option value="30">30일</option>
                                    </select>
                                    <div class="filter_box">
                                        <div class="date_filter">
                                            <div class="date_period">
                                                <div class="date_box">
                                                    <span style="display:inline-block ; padding : 0 5px 0 5px;" :style="picStartTxtStyle" ref="picStartTxt">{{ picStartDate }}</span> ~ <span style="display:inline-block ; padding: 0 0 0 5px;" :style="picEndTxtStyle" ref="picEndTxt">{{picEndDate}}</span>
                                                </div>
                                                <button type="button" ref="datePicToggle" @click="datePicToggle()"></button>
                                                <div class="calender_box radiusbox" ref="calenderBox" style="display:none">
                                                    <div class="start_date date_box" ref="startDateBox" @click="onDatePic('S')" style="display:block">
                                                        <VCalendarUi :dateInterface="'start'" :setDate="dateSelectOption" />
                                                        <button type="button" @click="pickerNext()">다음</button>
                                                    </div>
                                                    <div class="end_date date_box" ref="endDateBox" @click="onDatePic('E')">
                                                        <VCalendarUi :dateInterface="'end'" :minDate="picStartDate"/>
                                                        <button type="button" @click="pickerPrev()">이전</button>
                                                        <button type="button" class="mcbtn" @click="datePicToggle()">완료</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!--  -->
                            <li class="active">
                                <!-- <button @click="uiAcodion($event)" type="button">Domain</button> -->
                                <button type="button">Domain</button>
                                <ul class="log_filter_item">
                                    <li class="input_radio">
                                        <input type="radio" checked v-model="pjtData.project_type" :value="pjtData.project_type" name="awsId" id="a1">
                                        <label for="a1">
                                            <div class="radio_icon"></div>
                                            {{pjtData.project_type}}
                                        </label>
                                    </li>
                                    <!-- <li class="input_radio">
                                        <input type="radio" v-model="fliterKey.awsIdVal" value="awsIdVal2" name="awsId" id="a2">
                                        <label for="a2">
                                            <div class="radio_icon"></div>
                                            Data2
                                        </label>
                                    </li>
                                    <li class="input_radio">
                                        <input type="radio" v-model="fliterKey.awsIdVal" value="awsIdVal3" name="awsId" id="a3">
                                        <label for="a3">
                                            <div class="radio_icon"></div>
                                            Data3
                                        </label>
                                    </li> -->
                                </ul>
                            </li>
                            <li class="active">
                                <!-- <button @click="uiAcodion($event)" type="button">TASK</button> -->
                                <button type="button">TASK</button>
                                <ul class="log_filter_item">
                                    <li class="input_radio">
                                        <input type="radio" checked v-model="pjtData.aws_id" :value="pjtData.aws_id" name="task" id="b1">
                                        <label for="b1">
                                            <div class="radio_icon"></div>
                                            {{pjtData.aws_id}}
                                        </label>
                                        
                                    </li>
                                    <!-- <li class="input_radio">
                                        <input type="radio" v-model="fliterKey.taskVal" value="taskVal2" name="task" id="b2">
                                        <label for="b2">
                                            <div class="radio_icon"></div>
                                            Data2
                                        </label>
                                    </li>
                                    <li class="input_radio">
                                        <input type="radio" v-model="fliterKey.taskVal" value="taskVal3" name="task" id="b3">
                                        <label for="b3">
                                            <div class="radio_icon"></div>
                                            Data3
                                        </label>
                                    </li> -->
                                </ul>
                            </li>
                            <li class="active">
                                <!-- <button @click="uiAcodion($event)" type="button">Pipeline ID</button> -->
                                <button type="button">Pipeline ID</button>
                                <ul class="log_filter_item">
                                    <li class="input_radio">
                                        <!-- <template  v-if="pjtData.aws_id == 'vphm01'"> -->
                                        <input type="radio" checked v-model="pipIdVal" :value="pipIdVal" name="pipId" id="c1">
                                        <label for="c1">
                                            <div class="radio_icon"></div>
                                            {{pipIdVal}}
                                        </label>
                                    </li>
                                    <!-- <li class="input_radio">
                                        <input type="radio" v-model="fliterKey.pipIdVal" value="pipIdVal2" name="pipId" id="c2">
                                        <label for="c2">
                                            <div class="radio_icon"></div>
                                            Data2
                                        </label>
                                    </li>
                                    <li class="input_radio">
                                        <input type="radio" v-model="fliterKey.pipIdVal" value="pipIdVal3" name="pipId" id="c3">
                                        <label for="c3">
                                            <div class="radio_icon"></div>
                                            Data3
                                        </label>
                                    </li> -->
                                </ul>
                            </li>
                            <!-- 
                            <li>
                                <button @click="uiAcodion($event)" type="button">상세 설정</button>
                                <ul class="log_filter_item detail_set">
                                    <li>
                                        <strong>Pipeline Version</strong>
                                        <div id="pipVer" class="renge_box">
                                            <input type="range" ref="pvThumbLeft" id="input-left" min="1" max="100" value="1" data-left="0"/>
                                            <input type="range" ref="pvThumbRight" id="input-right" min="1" max="100" value="100" data-right="30" />
                                            <div class="track">
                                                <div class="range"></div>
                                                <div class="thumb left"></div>
                                                <div class="thumb right"></div>
                                            </div>
                                            <div class="num_box">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                        <div class="renge_val">
                                            <span>{{ fliterKey.pipVerStart }}</span>~<span>{{ fliterKey.pipVerEnd }}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>Run ID</strong>
                                        <div id="runId" class="renge_box">
                                            <input type="range" ref="runThumbLeft" id="input-left" min="1" max="100" value="1" data-left="0"/>
                                            <input type="range" ref="runThumbRight" id="input-right" min="1" max="100" value="100" data-right="30"/>
                                            <div class="track">
                                                <div class="range"></div>
                                                <div class="thumb left"></div>
                                                <div class="thumb right"></div>
                                            </div>
                                            <div class="num_box">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                        <div class="renge_val">
                                            <span>{{ fliterKey.pipRunIdStart }}</span>~<span>{{ fliterKey.pipRunIdEnd }}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>Model Version</strong>
                                        <div class="search_from">
                                            <input type="text" name="" id="" placeholder="검색어를 입력해 주세요.">
                                            <button type="button" class="btn_search"></button>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            -->
                        </ul>
                        <!-- <button type="button" class="mcbtn" @click="checkRadios" :disabled="!areRadiosSelected">조회</button> -->
                        <button type="button" class="mcbtn" @click="checkRadios()">조회</button>
                    </div>
                    <div class="log_data_box">
                        <div class="error_count radiusbox">
                            <strong>Error Count Bar (3M)</strong>
                            <div class="date_btn_box float_box">
                                <button type="button" @click="updateChart($event ,'d')" class="active">Daily</button>
                                <button type="button" @click="updateChart($event ,'w')" >Weekly</button>
                                <button type="button" @click="updateChart($event ,'m')">Monthly</button>
                            </div>
                            <div class="chart_box">
                                <canvas ref="errorCountBar" width="956" height="200"></canvas>
                            </div>
                        </div>
                        <div id="listFilter" class="log_filter_item_list list_filter_wrap radiusbox" ref="listFilter">
                            <div class="filter_list_box" ref="filterListBox">
                                <ul class="float_box" ref="filterBox">
                                    <li v-if="filterList.length === 0" style="padding-right: 20px;"><span>필터</span> : <span>전체</span></li>
                                    <li v-for="(item, index) in filterList" :key="index" ref="filterItem">
                                        <span>{{ item.keyName }}</span> : <span>{{ item.value }}</span>
                                        <button class="btn_close" @click="removeFilter(index)"></button>
                                    </li>
                                </ul>
                            </div>
                            <button class="btn_all_filter" ref="filterAllBtn" @click="filterToggle">전체보기</button>
                        </div>
                        <div class="log_message_box radiusbox">
                            <strong>Log Message</strong>
                            <div class="mando_table_wrap">
                                <table class="mando_table">
                                    <thead>
                                        <tr>
                                            <th v-for="(item, index) in columnDefs" :key="index">
                                                <div class="col_name">
                                                    {{item.field}}
                                                    <button class="btn_filter" @click="showFilter"></button>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr id="filterWrap" ref="filterInputWrap">
                                            <th class="filter_input" v-for="(item,index) in columnId" :key="index">
                                                <div class="input_box" :data-id="item.id">
                                                    <input type="text" @focus="setActive"  @keydown.enter="addFilter" >
                                                    <button class="btn_add" ref="btnAddfilter" @click="addFilter" :data-index="index"></button>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in dataList" :key="index">
                                            <td>{{ item.log_date }}</td>
                                            <td>{{ item.version_id }}</td>
                                            <td>{{ item.run_id }}</td>
                                            <td>{{ item.log_title }}</td>
                                            <td style="text-align: left;">{{ item.log_msg }}</td>
                                            <td>{{ item.log_id }}</td>
                                            <td>{{ item.log_type }}</td>
                                            <td>{{ item.log_step }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';

    import VCalendarUi from '@/components/VCalendarUi.vue';

    import Chart from 'chart.js/auto';
    import mlops from '@/assets/js/mlops';
    
    import common from '@/assets/js/common';

    import gsap from 'gsap';
    export default {
        name: 'aaView',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            VCalendarUi,
            BreadcrumbLayout,
        },
        data(){
            const picStartDate = null;
            const picEndDate = null;
            return{
                // date select 
                dateSelectOption:'1',
                // -- VCalendarUi / .date_period
                picStartDate,
                picEndDate,
                picColor: {
                    startStatus: false,
                    endStatus: false,
                    active: '#00B4ED',
                    default: '#020A0A'
                },
                //----mlops filter
                fliterKey: {
                    awsIdVal: '',
                    taskVal: '',
                    pipIdVal: '',
                    pipVerMax: 30,
                    pipRunIdMax: 30,
                    pipVerStart: '0',
                    pipVerEnd: '30',
                    pipRunIdStart: '0',
                    pipRunIdEnd: '30',
                },
                isDragging: false,
                //----- Chart 
                chartData: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Error Count Bar',
                            data: [],
                            backgroundColor:[
                                'rgba(0, 180, 237, 1)'
                            ],
                        },
                    ],
                },
                // table filter 
                filterList: [],
                columnDefs:[
                    { field: "Log Date" },
                    { field: "Version ID" },
                    { field: "Run ID" },
                    { field: "Log Title" },
                    { field: "Log Message" },
                    { field: "Log ID" },
                    { field: "Log Type" },
                    { field: "Log Step" }
                ],
                columnId:[
                    { id: "log_date" },
                    { id: "version_id" },
                    { id: "run_id" },
                    { id: "log_title" },
                    { id: "log_msg" },
                    { id: "log_id" },
                    { id: "log_type" },
                    { id: "log_step" }
                ],
                isfilter: true,
                filterDataList:{
                    origin:{}
                },
                dataList:[],
                // project
                pjtData: {},
                prjInfoToggle: false,
                pipIdVal: '',                
                
                projectId:'',

                // role
                viewRole: false,
                manageRole: false,
            };
        },
        computed: {
            picStartTxtStyle() {
                return {
                    color: this.picColor.startStatus ? this.picColor.active : this.picColor.default
                };
            },
            picEndTxtStyle() {
                return {
                    color: this.picColor.endStatus ? this.picColor.active : this.picColor.default
                };
            },
            areRadiosSelected() {
              return this.pjtData.aws_id !== '' && this.pjtData.project_type !== '' && this.pipIdVal !== '';
            },

        },
        async mounted() {
            // Role
            this.setRole();

            // set date 
            const vm = this;
            const stoday = new Date();
            stoday.setDate(stoday.getDate() - 1); 
            const syear = stoday.getFullYear();
            const smonth = stoday.getMonth() + 1;
            const sday = stoday.getDate();
            const start_formattedDate = syear + '-' + (smonth < 10 ? '0' : '') + smonth + '-' + (sday < 10 ? '0' : '') + sday;

            const etoday = new Date();
            const eyear = etoday.getFullYear();
            const emonth = etoday.getMonth() + 1;
            const eday = etoday.getDate();
            const end_formattedDate = eyear + '-' + (emonth < 10 ? '0' : '') + emonth + '-' + (eday < 10 ? '0' : '') + eday;
            
            vm.picStartDate = start_formattedDate;
            vm.picEndDate = end_formattedDate;
            window.selectDate.startDate = start_formattedDate;
            window.selectDate.endDate = end_formattedDate;
            //
            // filter ui

            // mlops.uiSlider('pipVer',vm.fliterKey.pipVerMax);
            // mlops.uiSlider('runId', vm.fliterKey.pipRunIdMax);
            // this.rengeUiData();


            this.projectId = this.$route.query.prjId;
            
            //  ------ API CALL
            const prjId = this.$route.query.prjId;
            
            
            const getPjtData = await this.getPJTData(prjId)

            this.pjtData = await getPjtData.project;
            
            if(this.pjtData.aws_id == 'vphm01'){
                this.pipIdVal = 'c-apne2-vphm01-mpipe-02';
            }else{
                this.pipIdVal = 'c-apne2-sfs01-mpipe-fv-P01'
            }
            
            // this.motTaskList = await getPjtData.project;

            //  -------------
            
            //error count bar

            
            //  -----chart daily set

            function formatDate(date) {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); 
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            }

            function convertToKST(date) {
                const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
                const kstOffset = 9 * 60 * 60000; // UTC+9
                return new Date(utcTime + kstOffset);
            }
            const todayKST = convertToKST(new Date());

            const dateArray = [];

            // for (let i = 365; i >= 1; i--) {
            for (let i = 90; i >= 1; i--) {
                // const date = new Date(todayKST);
                const date = new Date(2023, 8, 30);
                date.setDate((todayKST.getDate()+1) - i);
                dateArray.push(formatDate(date));
            }
            
            // const dlArr = [
            //         {EDATE : '2023-08-07', FAIL_COUNT : 10},
            //         {EDATE : '2023-08-09', FAIL_COUNT : 2},
            //         {EDATE : '2023-08-16', FAIL_COUNT : 1},
            //         {EDATE : '2023-08-08', FAIL_COUNT : 1},
            //         {EDATE : '2023-08-18', FAIL_COUNT : 1},
            //         {EDATE : '2023-08-30', FAIL_COUNT : 1},
            //         {EDATE : '2023-09-07', FAIL_COUNT : 3}
            // ];
                
            const dlArr = await this.setErroCount('daily');
            console.log(dlArr);
            const newData = [];
            const newLabels = [];

            dateArray.forEach(date => {
                const match = dlArr.find(item => item.EDATE === date);
                if (match) {
                    newLabels.push(date);  
                    newData.push(Number(match.FAIL_COUNT));  
                } else {
                    newLabels.push(date);  
                    newData.push(0);
                }
            });

            this.chartData.labels = newLabels;
            this.chartData.datasets[0].data = newData;
            this.drawChart();

                
                

            // ------------

        },
        methods:{
            async setRole() {
                this.manageRole = await common.getUserRole('type1');
                this.viewRole = await common.getUserRole('type1');

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            // item_table ui
            evtBox(e) {
                const btn = e.target;
                if(btn.classList.contains('active')){
                    btn.classList.remove('active');
                    btn.nextElementSibling.style.display = 'none';
                }else{
                    btn.classList.add('active');
                    btn.nextElementSibling.style.display = 'block';
                }
            },
            clickTogglePrj() {
                const toggle = !this.prjInfoToggle;
                this.prjInfoToggle = toggle;
            },

            // ui call 
            
            async setTabs() {
                const headers = {
                    // startTime: this.picStartDate + ' 00:00:00',
                    // endTime: this.picEndDate + ' 00:00:00',
                    Domain: this.pjtData.project_type,
                    task: this.pjtData.aws_id,
                    PipelineId: this.pipIdVal,
                    startTime: this.picStartDate + ' 00:00:00',
                    endTime: this.picEndDate + ' 23:59:00',
                };
                
                 const _data = await this.getDLlogData(headers);
                 
                 this.dataList = _data;
                 console.log(_data);
                
            },
            async setErroCount(type) {
                const headers = {
                    // startTime: this.picStartDate + ' 00:00:00',
                    // endTime: this.picEndDate + ' 00:00:00',
                    startTime: '2022-07-01 00:00:00',
                    endTime: '2023-10-30 00:00:00',
                    HTW: type,
                    task: this.pjtData.aws_id,
                    PipelineId: this.pipIdVal,
                };
                const _data = await this.getDLErrorData(headers);

                return _data;                
            },
            
            // -------- API 
            async getPJTData(pjtId){
                try {
                    const _pjtId = encodeURIComponent(pjtId);
                    const response = await common.apiGet('/project/' + _pjtId);
                    
                    // console.log(response.data.data);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getDLlogData(headers){
                try {
                    console.log(headers);
                    const response = await common.apiDlGet('/dl/can/pipeline-log', headers);
                    // console.log(response.data.queryResult.results);
                    return response.data.queryResult.results;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getDLErrorData(headers){
                try {
                    const response = await common.apiDlGet('/rds/vehicle/error_count', headers);
                    console.log(response);
                    return response.data.queryResult;

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },


            // -------- Table Filter
            filterToggle(){
                const $listFilter = this.$refs.listFilter;
                const $filterBox = this.$refs.filterListBox;
                const $filterBtn = this.$refs.filterAllBtn;
                const hasOpenClass = $filterBox.classList.contains("open");
                if(hasOpenClass){
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.remove("open");
                            $filterBtn.classList.remove("active");
                            $filterBtn.innerText = '전체보기'
                        },
                        height: '70px',
                        duration: .3
                    });
                }else{
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.add("open");
                            $filterBtn.classList.add("active");
                            $filterBtn.innerText = '접기'
                        },
                        height: 'auto',
                        duration: .3
                    });
                }
                
            },
            removeFilter(index) {
                const $filterItem = this.$refs.filterItem;
                const $filterInputWrap = this.$refs.filterInputWrap;
                const dataId = this.filterList[index].keyId.trim();
                console.log(dataId);
                console.log($filterInputWrap.querySelector(`[data-id='${dataId}']`).classList);
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).classList.remove("disable");
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).querySelector("input").disabled = false;
                this.filterList.splice(index, 1);
                if($filterItem.length <= 1){
                    this.$refs.filterAllBtn.style.display = "none";
                }
                this.filterAction(true);
            },
            showFilter(event){
                const _this = event.target;
                
                const $filterBtn = _this.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                const $filterWrap = document.querySelector("#filterWrap");
                const $filterInput = $filterWrap.querySelectorAll(".filter_input");

                const hasShowClass = $filterWrap.classList.contains("show");
                const hasActiveClass = _this.classList.contains("active");
                
                for(var i = 0 ; i < $filterBtn.length ; i ++){
                    $filterBtn[i].classList.remove("active");
                }
                _this.classList.add("active");
                if(hasShowClass){
                    if(hasActiveClass){
                        $filterWrap.classList.remove("show");
                        _this.classList.remove("active");
                    }
                }else{
                    $filterWrap.classList.add("show");
                }
                let activeIndex = Array.from($filterBtn).findIndex(element => element.classList.contains('active'));
                // console.log(activeIndex);
                // input 
                for(var j = 0 ; j < $filterInput.length ; j ++){
                    $filterInput[j].querySelector("div").classList.remove("active");
                }
                // console.log($filterInput[activeIndex]);
                if(activeIndex > -1){
                    $filterInput[activeIndex].querySelector("div").classList.add("active");
                }
                
                
            },
            setActive(event) {
                const _this = event.target;
                const $filterDiv = _this.parentNode.parentNode.parentNode.querySelectorAll("div");
                const $filterIcon = _this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                
                for(var i = 0 ; i < $filterDiv.length ; i ++){
                    $filterDiv[i].classList.remove("active");
                }
                _this.parentNode.classList.add("active");
                
                // filter icon 
                let activeIndex = Array.from($filterDiv).findIndex(element => element.classList.contains('active'));
                
                for(var j = 0 ; j < $filterIcon.length ; j ++){
                    $filterIcon[j].classList.remove("active");
                }
                $filterIcon[activeIndex].classList.add("active");

            },
            async addFilter(event){
                console.log("addFilter");
                
                const _this = event.target;
                const $thTag = _this.parentNode.parentNode;
                const $addBtn = $thTag.querySelector("button"); 
                const $input = $thTag.querySelector("input");
                // input 글자수
                const trimVal = $input.value.trim();

                // 컬럼 이름 가져오기
                const keyNameArr = _this.parentNode.parentNode.parentNode.parentNode.querySelector("tr:first-child").querySelectorAll(".btn_filter"); 
                let activeIndex = Array.from(keyNameArr).findIndex(element => element.classList.contains('active'));
                const keyName = String(keyNameArr[activeIndex].parentNode.textContent);                 


                const dataKeyArr = _this.parentNode.parentNode.parentNode.querySelectorAll('.input_box');
                const dataKeyindex = Array.from(dataKeyArr).findIndex(element => element.classList.contains('active'))
                const keyId = String(dataKeyArr[dataKeyindex].getAttribute('data-id')) 

                const obj = {};
                console.log();
                // console.log(this.getAttribute('data-index'))
                // console.log($input);

                window.filterData.taget = _this.getAttribute('data-index')

                if(0 < trimVal.length){
                    obj["keyId"] = keyId;
                    obj["value"] = trimVal;
                    obj["keyName"] = keyName;
                    $input.value = "";
                    this.filterList.push(obj);
                    this.$refs.filterAllBtn.style.display = "block";
                    $addBtn.parentNode.classList.add("disable");
                    $addBtn.previousElementSibling.disabled = true;
                    this.filterAction();
                }
                
            },
            filterAction(remove){
                const vm = this;
                // 필터 리스트
                const _filterLsit =  vm.filterList;
                // console.log(_filterLsit);
                // 테이블 리스트
                let _tableList = vm.dataList;
                if(vm.isfilter){
                    vm.filterDataList.origin = _tableList;
                    vm.isfilter = false;
                }
                
                
                if(_filterLsit.length > 0){
                    
                    const lodash = require('lodash');
                    if(remove){
                        // 필터 삭제
                        for( var j = 0 ; j < _filterLsit.length; j++){

                            const searchString = _filterLsit[j].value;

                            const regexPattern = new RegExp(searchString);

                            // _tableList > 검색 1 리트스 > 검색 2 리스트

                            const keyid = _filterLsit[j].keyId;
                            const filteredData = lodash.filter(vm.filterDataList.origin, (item) => regexPattern.test(item[keyid]));
                            vm.dataList = filteredData;
                        }
                    }else{
                        // 필터 추가
                        for( var i = 0 ; i < _filterLsit.length; i++){

                            const searchString = _filterLsit[i].value;

                            const regexPattern = new RegExp(searchString);

                            // _tableList > 검색 1 리트스 > 검색 2 리스트
                            
                            const keyid = _filterLsit[i].keyId;
                            const filteredData = lodash.filter(_tableList, (item) => regexPattern.test(item[keyid]));
                            vm.dataList = filteredData;
                        }
                    }
                    
                    console.log("필터 동작 시작");
                }else{
                    console.log(vm.filterDataList.origin);
                    vm.dataList = vm.filterDataList.origin;
                }
                
               
                
                
            },
            // chart ------------
            async updateChart(e, date) {
                const target = e.target;
                const siblings = target.parentNode.querySelectorAll('button');
                siblings.forEach(element => {
                    element.classList.remove("active");
                });

                // date set

                function formatDate(date) {
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); 
                    const day = String(date.getDate()).padStart(2, '0');
                    return `${year}-${month}-${day}`;
                }

                // function formatDateM(date) {
                //     const year = date.getFullYear();
                //     const month = String(date.getMonth() + 1).padStart(2, '0');
                //     return `${year}-${month}`;
                // }


                function convertToKST(date) {
                    const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
                    const kstOffset = 9 * 60 * 60000; // UTC+9
                    return new Date(utcTime + kstOffset);
                }
                const todayKST = convertToKST(new Date());

                //---

                function getWeekNumber(date) {
                    const dayOfYear = (date - new Date(date.getFullYear(), 0, 1)) / 86400000;
                    return Math.ceil((dayOfYear + new Date(date.getFullYear(), 0, 1).getDay() + 1) / 7);
                }



                if(date == 'd'){
                    target.classList.add("active");
                    const dateArray = [];

                    // for (let i = 365; i >= 1; i--) {
                    for (let i = 90; i >= 1; i--) {
                        // const date = new Date(todayKST);
                        const date = new Date(2023, 8, 30);
                        date.setDate((todayKST.getDate()+1) - i);
                        dateArray.push(formatDate(date));
                    }

                    const dlArr = await this.setErroCount('daily');

                    
                    // const dlArr = this.setErroCount('monthly');

                    const newData = [];
                    const newLabels = [];

                    dateArray.forEach(date => {
                        const match = dlArr.find(item => item.EDATE === date);
                        if (match) {
                            newLabels.push(date);  
                            newData.push(Number(match.FAIL_COUNT));  
                        } else {
                            newLabels.push(date);  
                            newData.push(0);
                        }
                    });
                    this.chartData.labels = newLabels;
                    this.chartData.datasets[0].data = newData;
                    this.drawChart();
                }else if( date == 'w'){
                    target.classList.add("active");

                    const startDate = new Date(2023, 8, 30); // 해당 연도의 9월 30일
                   
                    
                    let weekNumber = getWeekNumber(startDate);
                    console.log(weekNumber);


                    const dateArray = [];

                    // for (var i = 1; i <= 53; i++) {
                    for (var i = 1; i <= 12; i++) {
                        const w52Value = weekNumber--;
                        let dataFrom = '2023-' +  w52Value;
                        dateArray.push(dataFrom);
                    }
                    console.log(dateArray);
                    const dlArr = await this.setErroCount('weekly');
                    console.log(dlArr);
                    const newData = [];
                    const newLabels = [];

                    dateArray.forEach(date => {
                        const match = dlArr.find(item => item.EDATE === date);
                        if (match) {
                            newLabels.push(date + 'w');  
                            newData.push(Number(match.FAIL_COUNT));  
                        } else {
                            newLabels.push(date + 'w');  
                            newData.push(0);
                        }
                    });

                    this.chartData.labels = newLabels.reverse();
                    this.chartData.datasets[0].data = newData.reverse();
                    this.drawChart();

                }else if(date == 'm'){
                    target.classList.add("active");

                    const dateArray = ['2023-07', '2023-08', '2023-09'];

                    // for (let i = 12; i >= 1; i--) {
                    // for (let i = 3; i >= 1; i--) {
                    //     const date = new Date(todayKST);
                    //     date.setMonth((todayKST.getMonth()+1) - i);
                    //     const formattedDate = formatDateM(date);
                    //     dateArray.push(formattedDate);
                    // }

                    const dlArr = await this.setErroCount('monthly');

                    const newData = [];
                    const newLabels = [];

                    dateArray.forEach(date => {
                        const match = dlArr.find(item => item.EDATE === date);
                        if (match) {
                            newLabels.push(date);  
                            newData.push(Number(match.FAIL_COUNT));  
                        } else {
                            newLabels.push(date);  
                            newData.push(0);
                        }
                    });

                    this.chartData.labels = newLabels;
                    this.chartData.datasets[0].data = newData;
                    this.drawChart();
                }
            },
            drawChart() {
                const vm = this;
                const canvas = vm.$refs.errorCountBar;
                const ctx = canvas.getContext('2d');
                // canvas.style.width = '100%';
                // canvas.style.height = '200px';
                if (this.chart) {
                    this.chart.destroy(); // 기존 차트 파기
                }

                this.chart = new Chart(ctx, {
                    type: 'bar',
                    data: this.chartData,
                    options: {
                        responsive: false,
                        maintainAspectRatio :false,
                        plugins: {
                            legend: {
                                display: false, 
                            }
                        },
                        scales: {
                            x: {
                                display: true,
                                beginAtZero: true,
                            },
                            y: {
                                display: true,
                                beginAtZero: true,
                            }
                        }
                    },
                });
            },
            

            // Fillter Start-------- 
            uiAcodion(e){
                mlops.uiAcodion(e.target);
            },
            checkRadios(){
                // 필터 없을때 

                // Error Count Bar

                console.log(this.pjtData.aws_id);
                console.log(this.pjtData.project_type);
                console.log(this.pipIdVal);

                // api call

                // 임시 추후 제거 요망
                // this.picStartDate = '2023-08-07';
                // this.picEndDate = '2023-08-08';
                // chart call

    
                // log call 
                this.setTabs();

                // 초기화
                // this.fliterKey.awsIdVal = '';
                // this.fliterKey.taskVal = '';
                // this.fliterKey.pipIdVal = '';

                // 필터 있을때
                /*
                if (this.areRadiosSelected) {
                    
                    console.log('Radio select');

                    //  -----chart set
                    const newData = [];
                    const newLabels = [];
                    
                    for (let i = 0; i < 30; i++) {
                        const randomNumber = Math.floor(Math.random() * 101); // 0부터 100 사이의 난수 생성
                        newData.push(randomNumber);
                        newLabels.push("Daily");
                    }

                    this.chartData.labels = newLabels;
                    this.chartData.datasets[0].data = newData;
                    this.drawChart();


                    // api call

                    // 임시 추후 제거 요망
                    // this.picStartDate = '2023-08-07';
                    // this.picEndDate = '2023-08-08';
                    this.fliterKey.awsIdVal = 'test';
                    this.fliterKey.taskVal = '802844773267';
                    this.fliterKey.pipIdVal = 'c-apne2-vphm01-mpipe-01';
        
                    this.setTabs();

                    // 초기화
                    this.fliterKey.awsIdVal = '';
                    this.fliterKey.taskVal = '';
                    this.fliterKey.pipIdVal = '';
                }
                */
            },
            rengeUiData(){
                const vm = this;
                vm.isDragging =false;
                // ---- Pipeline Version
                vm.$refs.pvThumbLeft.addEventListener('mousedown', function() {
                    vm.isDragging = true;
                });
                vm.$refs.pvThumbLeft.addEventListener('mousemove', function() {
                    if (vm.isDragging) {
                        vm.fliterKey.pipVerStart = this.getAttribute('data-left');
                    }
                });
                vm.$refs.pvThumbLeft.addEventListener('mouseup', function() {
                    if (vm.isDragging) {
                        vm.isDragging = false;
                    }
                });
                vm.$refs.pvThumbRight.addEventListener('mousedown', function() {
                    vm.isDragging = true;
                });
                vm.$refs.pvThumbRight.addEventListener('mousemove', function() {
                    if (vm.isDragging) {
                        vm.fliterKey.pipVerEnd = this.getAttribute('data-right');
                    }
                });
                vm.$refs.pvThumbRight.addEventListener('mouseup', function() {
                    if (vm.isDragging) {
                        vm.isDragging = false;
                    }
                });
                // ---- Run ID
                vm.$refs.runThumbLeft.addEventListener('mousedown', function() {
                    vm.isDragging = true;
                });
                vm.$refs.runThumbLeft.addEventListener('mousemove', function() {
                    if (vm.isDragging) {
                        vm.fliterKey.pipRunIdStart = this.getAttribute('data-left');
                    }
                });
                vm.$refs.runThumbLeft.addEventListener('mouseup', function() {
                    if (vm.isDragging) {
                        vm.isDragging = false;
                    }
                });
                vm.$refs.runThumbRight.addEventListener('mousedown', function() {
                    vm.isDragging = true;
                });
                vm.$refs.runThumbRight.addEventListener('mousemove', function() {
                    if (vm.isDragging) {
                        vm.fliterKey.pipRunIdEnd = this.getAttribute('data-right');
                    }
                });
                vm.$refs.runThumbRight.addEventListener('mouseup', function() {
                    if (vm.isDragging) {
                        vm.isDragging = false;
                    }
                });

            },
             // select option
             dateSelectChange(){
                const vm = this;

                function dateSet(select){
                    if(select == 1){
                        const stoday1 = new Date();
                        stoday1.setDate(stoday1.getDate() - 1); 
                        const syear1 = stoday1.getFullYear();
                        const smonth1 = stoday1.getMonth() + 1;
                        const sday1 = stoday1.getDate();
                        const formattedDate1 = syear1 + '-' + (smonth1 < 10 ? '0' : '') + smonth1 + '-' + (sday1 < 10 ? '0' : '') + sday1;

                        vm.picStartDate = formattedDate1;
                        window.selectDate.startDate = formattedDate1;

                    }else if(select == 7){
                        const stoday7 = new Date();
                        stoday7.setDate(stoday7.getDate() - 7); 
                        const syear7 = stoday7.getFullYear();
                        const smonth7 = stoday7.getMonth() + 1;
                        const sday7 = stoday7.getDate();
                        const formattedDate7 = syear7 + '-' + (smonth7 < 10 ? '0' : '') + smonth7 + '-' + (sday7 < 10 ? '0' : '') + sday7;

                        vm.picStartDate = formattedDate7;
                        window.selectDate.startDate = formattedDate7;

                    }else if(select == 14){
                        const stoday14 = new Date();
                        stoday14.setDate(stoday14.getDate() - 14); 
                        const syear14 = stoday14.getFullYear();
                        const smonth14 = stoday14.getMonth() + 1;
                        const sday14 = stoday14.getDate();
                        const formattedDate14 = syear14 + '-' + (smonth14 < 10 ? '0' : '') + smonth14 + '-' + (sday14 < 10 ? '0' : '') + sday14;

                        vm.picStartDate = formattedDate14;
                        window.selectDate.startDate = formattedDate14;

                    }else if(select == 30){
                        const stoday30 = new Date();
                        stoday30.setDate(stoday30.getDate() - 30); 
                        const syear30 = stoday30.getFullYear();
                        const smonth30 = stoday30.getMonth() + 1;
                        const sday30 = stoday30.getDate();
                        const formattedDate30 = syear30 + '-' + (smonth30 < 10 ? '0' : '') + smonth30 + '-' + (sday30 < 10 ? '0' : '') + sday30;

                        vm.picStartDate = formattedDate30;
                        window.selectDate.startDate = formattedDate30;

                    }
                }

                switch (this.dateSelectOption) {
                    case "7":
                        dateSet("7");
                        break;
                    case "30":
                        dateSet("30");
                        break;
                    default:
                        dateSet("1");
                        break;
                }
            },
             // -------- DATE PICKER -----
             datePicToggle(){
                const vm = this;
                const _display = vm.$refs.calenderBox.style.display;
                const _startDateBox = vm.$refs.startDateBox.style.display;
                // console.log(vm.$refs.datePicToggle.nextElementSibling.style.display);
                // console.log(_display);
                
                if(_display == 'block'){
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'none';
                    vm.picColor.startStatus = false;
                    vm.picColor.endStatus = false;

                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'block';
                    console.log(_startDateBox);
                    if(_startDateBox == 'block'){
                        vm.picColor.startStatus = true;
                        vm.picColor.endStatus = false;
                    }else if( _startDateBox == 'none'){
                        vm.picColor.startStatus = false;
                        vm.picColor.endStatus = true;
                    }
                }
            },
            async getDriList(){
                const vm = this;
                // this.getDriPrjListData('project 2', 'c-apne2-VPHM01-iotdvc-00', this.picStartDate, this.picEndDate);
                // 켈린더 display처리
                const $calenderBox = vm.$refs.calenderBox;
                const $startDateBox = vm.$refs.startDateBox;
                const $endDateBox = vm.$refs.endDateBox;
                const $drivReclistBox = vm.$refs.drivReclistBox;
                
                $calenderBox.style.display = 'none';
                $startDateBox.style.display = 'block';
                $endDateBox.style.display = 'none';
                $drivReclistBox.style.display = 'block';
                
                vm.picColor.startStatus = false;
                vm.picColor.endStatus = false;

                console.log(vm.prjData.projectName);
                console.log(vm.prjData.edgeDeviceId);
                console.log(vm.picStartDate);
                console.log(vm.picEndDate);

                vm.drivList = await vm.getDriPrjListData(vm.prjData.projectId, vm.prjData.id, vm.picStartDate, vm.picEndDate);
                
                console.log(vm.drivList);
                
            },
            onDatePic(flag){
                if(flag == 'S'){   
                    if(window.selectDate.startDate !== null ){
                        this.picStartDate = window.selectDate.startDate;
                    }
                    
                }else if(flag == 'E'){
                    if(window.selectDate.picEndDate !== null ){
                        this.picEndDate = window.selectDate.endDate;
                    }
                }
            },
            pickerNext(){
                this.$refs.startDateBox.style.display = 'none';
                this.$refs.endDateBox.style.display = 'block';

                this.picColor.startStatus = false;
                this.picColor.endStatus = true;
                console.log(this.picColor.endStatus);
                console.log(this.picColor.target);
                
            },
            pickerPrev(){
                this.$refs.startDateBox.style.display = 'block';
                this.$refs.endDateBox.style.display = 'none';

                this.picColor.startStatus = true;
                this.picColor.endStatus = false;
            },
            // ------------

            //----API
            async getMemberRole() {
                let response;
                try {
                    let url = `/member/check-role`;
                    response = await common.apiGet(url);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return null;
                }
            },
            async getProjectRole() {
                let response;
                try {
                    let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
                    response = await common.apiGet(checkProjectRoleUrl);
                    return response.data.data;
                } catch(error) {
                    response = null;
                    console.error("error", error);
                    return null;
                }
            },

        }
    }
</script>
<style scoped lang="scss">
.item_info{
    & > .float_box{
        width: 100%;
        height: 890px;
        margin-top: 20px;
        & > div{
            float: left;
        }
        .radiusbox{
            & > strong{
                width:100%;
                display: block;
                padding: 20px 0 0 20px;
                font-weight: $bold;

            }
        }
        .log_filter{
            width: 300px;
            height: 100%;
            background-color: $whiteColor;
            margin-right: 20px;
            box-sizing: border-box;
            position: relative;
            .log_filter_list{
                margin-top: 20px;
                & > li{
                    position: relative;
                    z-index: 1;
                    background-color: #fff;
                    border-bottom: 1px solid $line01Color;
                    &:last-child{
                        border: none;
                    }
                    & > button{
                        width: 100%;
                        padding: 20px;
                        display: block;
                        text-align: left;
                        font-weight: $bold;
                        position: relative;
                        background-repeat: no-repeat;
                        background-image: url($baseURL+'/common/select_arrowdown.svg');
                        background-position: 265px center;
                        
                    }
                    &.active{
                        z-index: 2;
                        // padding-bottom: 20px;
                        & > button{
                            background-image: url($baseURL+'/common/select_arrowup.svg');
                            background-image: none;
                        }
                        .log_filter_item{
                            height: auto;
                            overflow:visible;
                        }
                    }
                    .log_filter_item{
                        width: 100%;
                        height: 0;
                        overflow: hidden;
                        padding: 1px 20px 1px;
                        box-sizing: border-box;
                        &::after{
                            content: "";
                            display: block;
                            width: 100%;
                            height: 20px;
                        }
                        &.detail_set{
                            padding: 0;
                            li{
                                position: relative;
                                border-bottom: 1px solid $line01Color;
                                &:last-child{
                                    border-bottom: none;
                                }
                                strong{
                                    display: block;
                                    box-sizing: border-box;
                                    padding: 0 0 0 20px;
                                }
                                .renge_box{
                                    width: 100%;
                                    height: 60px;
                                    box-sizing: border-box;
                                    padding: 0 20px 0;
                                    margin: 10px 0;
                                }
                                .renge_val{
                                    position: absolute;
                                    top: 10px;
                                    right: 20px;
                                    color: $mainColor;
                                    span{
                                        color: $mainColor;
                                    }
                                }
                                .search_from{
                                    padding: 0 20px;
                                    margin: 20px 0;
                                    input{
                                        width: calc(100% - 55px);
                                    }
                                    .btn_search{
                                        width: 55px;
                                        right: 20px;
                                        
                                    }
                                }
                            }
                        }
                        .filter_box{
                            .date_filter{
                                position: relative;
                                padding: 0;
                                &::after{
                                    display: none;
                                }
                                .date_period{
                                    width: calc(100% - 40px);
                                    margin: 0;
                                    margin-top: 10px;
                                }
                            }
                        }
                        li{
                            padding: 10px 0;
                            &:last-child{
                                padding-bottom: 0;
                            }
                            label{
                                width: 100%;
                                height: 25px;
                                display: block;
                                line-height: 25px;
                                .radio_icon{
                                    position: relative;
                                    top: -2px;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
            &>button.mcbtn{
                // width: 60px;
                // height: 40px;
                // border-radius: 10px;                
                // position: absolute;
                // bottom: 20px;
                // right: 20px;

                width: 260px;
                height: 40px;
                border-radius: 10px;                
                margin: 0 auto;
                display: block;
                
            }
        }
        .log_data_box{  
            width: 956px;
            .error_count{
                width: 100%;
                // height: 144px;
                height: 288px;
                position: relative;
                .date_btn_box{
                    width: 264px;
                    height: 40px;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    text-align: 0;
                    button{
                        width: 88px;
                        height: 40px;
                        display: block;
                        float: left;
                        border: 1px solid $line01Color;
                        &.active{
                            color: $mainColor;
                            border-color: $mainColor;
                            background-color: #BEFAFF;
                        }
                        &:first-child{
                            border-radius: 10px 0 0 10px;
                        }
                        &:last-child{
                            border-radius: 0 10px 10px 0;
                        }
                    }
                }
                .chart_box{
                    width: 100%;
                    height: 70px;
                    margin-top: 30px;
                    
                }
            }
            .log_filter_item_list{
                position: relative;
                width: 100%;
                min-height: 70px;
                margin-top: 20px;
                
                
            }
            .log_message_box{
                width: 100%;
                // height: 636px;
                height: 492px;
                margin-top: 20px;
                .mando_table_wrap{
                    margin-top: 20px;
                    overflow: auto;
                    // height: 580px;
                    height: 436px;
                    .mando_table{
                        thead{
                            th{
                                &:nth-child(5){
                                    .col_name{
                                        text-align: left !important; 
                                    }
                                }
                                .all_chk{
                                    input[type="checkbox"]{
                                        display: none;
                                    }
                                    input[type="radio"]{
                                        display: none;
                                    }
                                    label{
                                        width: 15px;
                                        height: 15px;
                                        display: block;
                                        cursor: pointer;
                                        margin: 0 auto;
                                        background-repeat: no-repeat;
                                        background-position: right center;
                                        background-image: url($baseURL+'/common/checkbox_dis.svg');
                                        display: none;
                                    }
                                    input[type="checkbox"]:checked + label{
                                        background-image: url($baseURL+'/common/checkbox.svg');
                                    }
                                }
                                .col_name{
                                    min-width: 100px;
                                    position: relative;
                                    color: #6E7380;
                                    .btn_filter{
                                        width: 30px;
                                        height: 30px;
                                        position: absolute;
                                        top: -6px;
                                        right: -15px;
                                        background-image: url($baseURL + "common/filtericon_dis.svg");
                                        background-repeat: no-repeat;
                                        background-position: center;
                                        &.active{
                                            background-image: url($baseURL + "common/filtericon.svg");
                                        }
                                    }
                                }
                                &.filter_input{
                                    padding: 14px 10px;
                                    background-color: #F5F7FA;
                                    &::after{
                                        display: none;
                                    }
                                    div{
                                        width: 100%;
                                        height: 40px;
                                        background-color: $whiteColor;
                                        text-align: left;
                                        border-radius: 10px;
                                        box-sizing: border-box;
                                        border: 1px solid $disable02Color;
                                        overflow: hidden;
                                        position: relative;
                                        .btn_add{
                                            width: 30px;
                                            height: 40px;
                                            background-image: url($baseURL + "common/filter_plus.svg");
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            position: absolute;
                                            top: 0;
                                            right: 0;
                                            display: none;
                                        }
                                        input[type='text']{
                                            width: calc(100% - 26px);
                                            height: 100%;
                                            box-sizing: border-box;
                                            padding-left: 10px;
                                            border: 0;
                                        }
                                        &.active{
                                            border: 1px solid $mainColor;
                                            .btn_add{
                                                display: block;
                                            }
                                        }
                                        &.disable{
                                            background-color: $line02Color;
                                            input[disabled]{
                                                background-color: $line02Color;
                                            }
                                            .btn_add{
                                                display: none;
                                            }
                                        }

                                    }
                                    
                                }
                                // &.filter_input:first-child{
                                //     &::after{
                                //         display: none;
                                //     }
                                //     &>div{
                                //         display: none;
                                //     }
                                // }
                                
                            }
                        }
                        tbody{
                            td{
                                &:first-child{
                                    input[type="checkbox"]{
                                        display: none;
                                    }
                                    input[type="radio"]{
                                        display: none;
                                    }
                                    label{
                                        width: 15px;
                                        height: 15px;
                                        display: block;
                                        cursor: pointer;
                                        margin: 0 auto;
                                        background-repeat: no-repeat;
                                        background-position: right center;
                                        background-image: url($baseURL+'/common/radiobox_dis.svg');
                                    }
                                    input[type="checkbox"]:checked + label{
                                        background-image: url($baseURL+'/common/checkbox.svg');
                                    }
                                    input[type="radio"]:checked + label{
                                        background-image: url($baseURL+'/common/radiobox_select.svg');
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}
.list_filter_wrap {
    .filter_list_box{
        width: calc(100% - 100px);
    }
}

#filterWrap{
    display: none;
    &.show{
        display: table-row;
    }
}
</style>

