<template>
    <VDatePicker 
    locale="en-US" 
    expanded 
    v-model="date" 
    mode="dateTime" 
    is24hr 
    :time-accuracy='3'
    borderless
    trim-weeks
    :min-date="minDate"
    :max-date="maxDate"
    />
</template>

<script>
window.selectDate = {
    startDateTime: null,
    endDateTime: null,
}
export default {
    name: 'VCalendarUi',
    data(){
        const maxDate = new Date();

        const date = new Date();
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const end_formattedDate = year + '-' + (month < 10 ? '0' : '') + month + '-' + ((day + 7) < 10 ? '0' : '') + ( day + 7 );
        return{
            date,
            end_formattedDate,
            maxDate
        }
    },
    props:{
        dateInterface: String,
        setDate: String,
        minDate: String,
    },
    watch: {
        //(newValue, oldValue
        date(newValue) {
            if(newValue !== null){
                const year = newValue.getFullYear();
                const month = String(newValue.getMonth() + 1).padStart(2, '0'); 
                const day = String(newValue.getDate()).padStart(2, '0'); 
                const hours = String(newValue.getHours()).padStart(2, '0');
                const minutes = String(newValue.getMinutes()).padStart(2, '0');
                const seconds = String(newValue.getSeconds()).padStart(2, '0');

                const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

                if(this.dateInterface === 'start'){
                    window.selectDate.startDateTime = formattedDate;
                }else if(this.dateInterface === 'end'){
                    window.selectDate.endDateTime = formattedDate;
                }
            }
        },
        setDate(){
            this.dateSet();
        }
    },
    computed: {
        
        
    },
    mounted() {
        this.dateSet();
    },
    methods: {
        dateSet() {
            if ( this.setDate !== undefined && this.setDate !== null && this.setDate !== '' ) {
                if ( this.setDate != '1' && this.setDate != '7' && this.setDate != '14' && this.setDate != '30' ) {
                    const date = this.setDate;
                    const initialDate = this.getInitialDateTime(date);
                    this.date = initialDate;
                }
            }

        },

        getInitialDateTime(date) {
            const dayTime = new Date(date);

            const year = dayTime.getFullYear();
            const month = String(dayTime.getMonth() + 1).padStart(2,'0');
            const day = String(dayTime.getDate()).padStart(2,'0');
            const hour = String(dayTime.getHours()).padStart(2, '0');
            const min = String(dayTime.getMinutes()).padStart(2, '0');
            const sec = String(dayTime.getSeconds()).padStart(2, '0');

            const initialDate = new Date(year, month - 1, day, hour, min, sec);
            return initialDate;
        },
    }
}
</script>
<style >
    .vc-weeks{
        margin:14px 0 24px;
    }
    .vc-weekday, .vc-day-content{
        margin: 10px 0;
        font-weight: 400;
    }
    .vc-day-content{
        font-family: 'Montserrat', sans-serif !important;
    }
    .filter_box .date_filter .date_period .date_box .vc-title span{
        font-family: 'Montserrat', sans-serif !important;
        font-weight: 500;
    }
    .vc-time-select-group{
        width: 350px;
        height: 60px;
        margin-top: 30px;
        margin-bottom: 22px;
        box-sizing: border-box;
        padding-left: 32px;
    }
    .vc-time-select-group .vc-base-icon{
        width: 50px;
        height: 20px;
        margin-right: 10px;
        color: #9EA5B2;
    }
    .vc-base-select select{
        height: 40px;
        line-height: 40px;
        border: none;
        font-size: 30px;
        font-family: 'Montserrat', sans-serif !important;
        padding: 3px 0;
        text-align: center !important;
    }
    .filter_box .date_filter .date_period .date_box span.vc-time-colon{
        margin: 0 1px 2px 2px;
        font-size: 30px;
    }
    .vc-time-header{
        display: none;
    }
    .vc-container .vc-weekday-1{
        color: #EA726F !important;
    } 
    .vc-container .vc-weekday-7 {
        color: #4791FF !important;
    }
    .vc-blue{
        --vc-accent-600: #00B4ED;
    }
</style>