<template>
    <HeaderLayout/>
    <GlobalNavBar/>
    <div id="container">
        <div id="contents">
            <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>시스템 운영</span>
                    <span>사용자 정보 관리</span>
                    <span class="active">사용자 정보 관리 상세</span>
                </div>
                <h2>사용자 정보 관리 - 상세</h2>
            </div> -->
            <BreadcrumbLayout pageId="sysOps_detailMember" />
            <div class="item_info">
                <div class="item_title">
                    <strong>User Info</strong>
                    <div class="item_info_btn_box">
                        <button type="button" class="btn_fold" v-bind:style="infoToggle ? 'display:none': ''" @click="clickToggle()">접기</button>
                        <button type="button" class="btn_fold active" v-bind:style="!infoToggle ? 'display:none': ''" @click="clickToggle()">펼치기</button>
                        <button v-if="manageRole" type="button" class="btn_evt_group" @click="hideUtils"></button>
                        <ul class="evt_btn_box radiusbox" :style="{ display: InferenceUtil ? 'block' : 'none' }">
                            <li class="retouch_item" v-if="manageRole">
                                <button type="button" @click="showModal">수정</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item_table radiusbox" v-bind:style="infoToggle ? 'display:none': ''">
                    <table>
                        <thead>
                            <tr>
                                <th>User Name</th>
                                <th>E-mail</th>
                                <th>Contact</th>
                                <th>Company</th>
                                <th>CEO</th>
                                <th>Status</th>
                                <th>Last Visit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{memberData.name}}</td>
                                <td>{{memberData.email}}</td>
                                <td>{{memberData.contact}}</td>
                                <td>{{memberData.divisionName}}</td>
                                <td>{{memberData.ceoName}}</td>
                                <td>{{memberData.status}}</td>
                                <td>{{memberData.lastLogin}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div id="monCont" class="vision_data">
                <div class="edge_list_wrap">
                    <ul class="list_tab float_box" ref="listTab">
                        <li @click="tabEvent(0)" class="active">
                            <div>상세 정보</div>
                        </li>
                        <li @click="tabEvent(1)">
                            <div>사용자 이력</div>
                        </li>
                    </ul>
                    <div class="tab_data" ref="listTabBox">
                        <div class="data_list_box detail_info active">
                            <div class="float_box">
                                <div class="status_box profile_img">
                                    <strong>Profile Image</strong>
                                    <div class="img_box">
                                        <img :src="profileImage(memberData.image)" alt="">
                                    </div>
                                </div>
                                <div class="status_box manage_box">
                                    <strong>Profile</strong>
                                    <div class="txt_box">
                                        <div class="txt_title">소속 정보</div>
                                        <div class="txt_data">
                                            <div class="text_data_title">Division</div>
                                            <div>{{memberData.divisionName}}</div><br>
                                            <div class="text_data_title">Team</div>
                                            <div>{{memberData.teamName}}</div>
                                        </div>
                                    </div>
                                    <div class="txt_box">
                                        <div class="txt_title">가입 정보</div>
                                        <div class="txt_data">
                                            <div class="text_data_title">가입일</div>
                                            <div>{{memberData.createdAt}}</div><br>
                                            <div class="text_data_title">탈퇴일</div>
                                            <div>{{memberData.withdrawalAt}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="status_box manager_box">
                                    <strong>약관 동의</strong>
                                    <div class="terms_box">
                                        <table>
                                            <tr>
                                                <th rowspan="2">
                                                    개인정보취급 <br> 방침 동의
                                                </th>
                                                <td>
                                                    Version
                                                </td>
                                                <td class="val">
                                                    x.x.x Released
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Date
                                                </td>
                                                <td class="val">
                                                    yyyy.mm.dd
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <th rowspan="2">
                                                    서비스 이용 <br> 약관 동의
                                                </th>
                                                <td>
                                                    Version
                                                </td>
                                                <td class="val">
                                                    x.x.x Released
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Date
                                                </td>
                                                <td class="val">
                                                    yyyy.mm.dd
                                                </td>
                                            </tr>

                                            <tr>
                                                <th rowspan="2">
                                                    위치정보 이용 <br> 약관 동의
                                                </th>
                                                <td>
                                                    Version
                                                </td>
                                                <td class="val">
                                                    x.x.x Released
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Date
                                                </td>
                                                <td class="val">
                                                    yyyy.mm.dd
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="fleet_info">
                                    <strong>참여 프로젝트</strong>
                                    <div class="total_mem"><span>{{projectList.length}} </span> Projects</div>
                                    <div class="mando_table_wrap">
                                        <table class="mando_table">
                                            <thead>
                                                <tr>
                                                    <th>Project Name</th>
                                                    <th>Project Type</th>
                                                    <th>Division/Team</th>
                                                    <th>Status</th>
                                                    <th>My Role</th>
                                                    <th>Last Update</th>
                                                    <th>AWS ID</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in projectList" :key="index">
                                                    <td>
                                                        {{item.projectName}}
                                                    </td>
                                                    <td>
                                                        {{item.projectType}}
                                                    </td>
                                                    <td>
                                                        {{item.divisionName}}
                                                    </td>
                                                    <td>
                                                        {{item.status}}
                                                    </td>
                                                    <td>
                                                        {{item.myRole}}
                                                    </td>
                                                    <td>
                                                        {{item.lastUpdated}}
                                                    </td>
                                                    <td>
                                                        {{item.awsId}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="data_list_box user_history">
                            <div id="listFilter" class="log_filter_item_list list_filter_wrap radiusbox" ref="listFilter">
                                <div class="filter_list_box" ref="filterListBox">
                                    <ul class="float_box" ref="filterBox">
                                        <li v-if="filterList.length === 0" style="padding-right: 20px;"><span>필터</span> : <span>전체</span></li>
                                        <li v-for="(item, index) in filterList" :key="index" ref="filterItem">
                                            <span>{{ item.keyName }}</span> : <span>{{ item.value }}</span>
                                            <button class="btn_close" @click="removeFilter(index)"></button>
                                        </li>
                                    </ul>
                                </div>
                                <button class="btn_all_filter" ref="filterAllBtn" @click="filterToggle">전체보기</button>
                            </div>

                            <div class="user_hist_box radiusbox">
                                <div class="user_hist_cnt">
                                    총 <span class="cont_num">{{dataList.length}}</span>항목
                                </div>
                                <div class="filter_box">
                                    <div class="date_filter">
                                        <select name="datelist"  @change="dateSelectChange" style="width: 150px; background-position: 120px center;" v-model="selectDate">
                                            <option :value="''" selected>기간선택</option>
                                            <option value="'1'">1일</option>
                                            <option value="7">7일</option>
                                            <option value="14">14일</option>
                                            <option value="30">30일</option>
                                        </select>
                                        <div class="date_period">
                                            <div class="date_box">
                                                <span :style="picStartTxtStyle" ref="picStartTxt">{{ picStartDate }}</span> ~ <span :style="picEndTxtStyle" ref="picEndTxt">{{picEndDate}}</span>
                                            </div>
                                            <button type="button" ref="datePicToggle" @click="datePicToggle()"></button>
                                            <div class="calender_box radiusbox" ref="calenderBox" style="display:none">
                                                <div class="start_date date_box" ref="startDateBox" @click="onDatePic('S')" style="display:block" >
                                                    <VCalendarUi :dateInterface="'start'"/>
                                                    <button type="button" @click="pickerNext()">다음</button>
                                                </div>
                                                <div class="end_date date_box" ref="endDateBox" @click="onDatePic('E')">
                                                    <VCalendarUi :dateInterface="'end'" :minDate="picStartDate"/>
                                                    <button type="button" @click="pickerPrev()">이전</button>
                                                    <button type="button" class="mcbtn" @click="datePicToggle()">완료</button>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" class="btn_send mcbtn" @click="clickSearch()">조회</button>
                                    </div>    
                                </div>
                                <div class="list_wrap">
                                    <div class="table_list">
                                        <div class="mando_table_wrap">
                                            <table class="mando_table">
                                                <thead>
                                                    <tr>
                                                        <th v-for="(item, index) in columnDefs" :key="index">
                                                            <div class="col_name">
                                                                {{item.field}}
                                                                <button class="btn_filter" @click="showFilter"></button>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    <tr id="filterWrap" ref="filterInputWrap">
                                                        <th class="filter_input" v-for="(item,index) in columnId" :key="index">
                                                            <div class="input_box" :data-id="item.id">
                                                                <input type="text" @focus="setActive"  @keydown.enter="addFilter" >
                                                                <button class="btn_add" ref="btnAddfilter" @click="addFilter" :data-index="index"></button>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in dataListSliced" :key="index">
                                                        <td>{{ item.dateTime }}</td>
                                                        <td>{{ item.userName }}</td>
                                                        <td>{{ item.activityCode }}</td>
                                                        <td style="text-align: left;">{{ item.activityLog }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="list_table_footer">
                                            <PaginationUi
                                                :totalItems="totalItems"
                                                :itemsPerPage="itemsPerPage"
                                                @page-changed="onPageChanged"
                                            ></PaginationUi>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div id="modalWrap" ref="modalWrap" style="display: none;">
        <div class="radiusbox modal_contents" ref="modalModify" style="display : none;">
            <strong class="modal_title">
                사용자 처리/정보 변경
            </strong>
            <div class="col_box bn">
                <div class="col_title">E-mail</div>
                <input type="text" ref="email" :value="email" disabled>
            </div>
            <div class="col_box">
                <div class="col_title">이름</div>
                <input type="text" ref="name" :value="name">
            </div>
            <div class="col_box">
                <div class="col_title">연락처</div>
                <input type="text" ref="contact" :value="contact">
            </div>
            <div class="col_box radio_box">
                <div class="col_title">사용자 상태 변경</div>
                <div class="input_radio">
                    <input type="radio" name="personalInfoConsent" value="active" v-model="setCheck" id="activeRd">
                    <label for="activeRd">
                        <div class="radio_icon"></div>
                        Active
                    </label>
                    <input type="radio" name="personalInfoConsent" value="disable" v-model="setCheck" id="disableRd">
                    <label for="disableRd">
                        <div class="radio_icon"></div>
                        Disable
                    </label>
                    <input type="radio" name="personalInfoConsent" value="discipline" v-model="setCheck" id="disciplineRd">
                    <label for="disciplineRd">
                        <div class="radio_icon"></div>
                        Discipline
                    </label>    
                    <div class="filter_box">
                        <div class="date_filter">
                            <div class="date_period">
                                <div class="date_box">
                                    <span :style="picStartTxtStyle" ref="picStartTxt">{{modalPicStartDate}}</span> ~ <span :style="picEndTxtStyle" ref="picEndTxt">{{modalPicEndDate}}</span>
                                </div>
                                <button type="button" ref="datePicToggle2" @click="datePicToggle2()"></button>
                                <div class="calender_box radiusbox" ref="calenderBox2" style="display:none">
                                    <div class="start_date date_box" ref="startDateBox2" @click="onDatePic2('S')" style="display:block" >
                                        <VCalendarUi :dateInterface="'start'" :maxDate="maxDate"/>
                                        <button type="button" @click="pickerNext2()">다음</button>
                                    </div>
                                    <div class="end_date date_box" ref="endDateBox2" @click="onDatePic2('E')">
                                        <VCalendarUi :dateInterface="'end'" :minDate="modalPicStartDate" :maxDate="maxDate"/>
                                        <button type="button" @click="pickerPrev2()">이전</button>
                                        <button type="button" class="mcbtn" @click="datePicToggle2()">완료</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="btn_box">
                <button type="button" class="btn_check secession_btn" @click="clickWithdrawal()">사용자 탈퇴</button>
                <div class="check_wrap">
                    <button type="button" class="btn_check wbtn" @click="closeModal()">취소</button>
                    <button type="button" class="btn_check mcbtn" @click="clickRegist()">완료</button>
                </div>
            </div>
            <button type="button" class="btn_close" @click="closeModal()"></button>
        </div>

    </div>
    <FooterLayout/>
</template>
<script>
    import HeaderLayout from '@/components/HeaderLayout.vue';
    import GlobalNavBar from '@/components/GlobalNavBar.vue';
    import FooterLayout from '@/components/FooterLayout.vue';
    import PaginationUi from "@/components/PagenationUi.vue";
    import VCalendarUi from '@/components/VCalendarUi.vue';
    import BreadcrumbLayout from '@/components/BreadcrumbLayout.vue';
    
    import '@/assets/js/filter';
    import gsap from 'gsap';
    import common from '@/assets/js/common';

    export default {
        name: 'detailMember',
        components: {
            HeaderLayout,
            GlobalNavBar,
            FooterLayout,
            VCalendarUi,
            PaginationUi,
            BreadcrumbLayout,
        },
        computed:{
            // VCalendar 
            picStartTxtStyle() {
                return {
                    color: this.picColor.startStatus ? this.picColor.active : this.picColor.default
                };
            },
            picEndTxtStyle() {
                return {
                    color: this.picColor.endStatus ? this.picColor.active : this.picColor.default
                };
            },
        },
        data(){
            const picStartDate = null;
            const picEndDate = null;
            const modalPicStartDate = null;
            const modalPicEndDate = null;

            const filterList = ([]);
            const filterRowData =([]);

            return{
                memberId: "",
                memberData: [],
                projectList: [],

                email: "",
                name: "",
                contact: "",

                selectDate: "",

                // -- VCalendarUi / .date_period
                maxDate: "9999-12-31",
                picStartDate,
                picEndDate,
                modalPicStartDate,
                modalPicEndDate,
                picColor: {
                    startStatus: false,
                    endStatus: false,
                    active: '#00B4ED',
                    default: '#020A0A'
                },

                // table filter 
                filterList,
                filterRowData,

                perPageList: [],
                perPage: 0,

                totalItems: 100,
                itemsPerPage: 10,
                currentPage: 1,

                dataList: [],
                dataListSliced:[],

                columnDefs:[
                    { field: "Date Time" },
                    { field: "User Name" },
                    { field: "Activity Code" },
                    { field: "Activity Log" },
                ],
                columnId:[
                    { id: "dateTime" },
                    { id: "userName" },
                    { id: "activityCode" },
                    { id: "activityLog" },
                ],
                isfilter: true,
                filterDataList:{
                    origin:{}
                },

                setCheck: "",

                infoToggle: false,
                InferenceUtil: false,

                // role
                viewRole: false,
                manageRole: false,
            };
        },
        watch: {
        },
        mounted() {
            // Role
            this.setRole();

            this.memberId = this.$route.params.memberId;
            
            this.setUserInfo();
            this.setProjectList();
            
            const start_formattedDate = common.getNowDt();
            const end_formattedDate = common.getNowDt();
            
            this.picStartDate = start_formattedDate;
            this.picEndDate = end_formattedDate;
            window.selectDate.startDate = start_formattedDate;
            window.selectDate.endDate = end_formattedDate;

            this.modalPicStartDate = start_formattedDate;
            this.modalPicEndDate = end_formattedDate;
            
            this.setUserHstList(start_formattedDate, end_formattedDate);
        },
        methods:{
            async setRole() {
                this.manageRole = await common.getUserRole('type3');
                this.viewRole = await common.getUserRole('type3');

                if ( !this.viewRole ) {
                    common.goHome();
                }
            },

            async setUserInfo() {
                const memberId = this.memberId;
                const memberData = await this.getMemberData(memberId);

                if(typeof memberData !== "undefined" && memberData !== null && memberData !== "") {
                    this.memberData = memberData;
                }
            },
            async setProjectList() {
                const memberId = this.memberId;
                const projectList = await this.getProjectList(memberId);
                
                if(typeof projectList !== "undefined" && projectList !== null && projectList !== "") {
                    this.projectList = projectList;
                }
            },
            
            async setUserHstList(startDt, endDt) {
                const memberId = this.memberId;
                const dataList = await this.getUserHstList(memberId, startDt, endDt);
                
                if(typeof dataList !== "undefined" && dataList !== null && dataList !== "") {
                    this.dataList = dataList;
                }

                this.currentPage = 1;
                this.totalItems = this.dataList.length;
                this.displayDataPerPage(this.dataList);
            },

            goPage(){
                const memberId = this.memberId;
                this.$router.go({ 
                    name: 'detailMember',
                    params: { memberId: memberId}
                });
            },

            // profile img 
            profileImage(image) {
                if(image == null ||image == undefined || image == '') return require('@/assets/images/common/deviceimg_sample.svg');
                else {
                    return image;
                }
            },
            //

            tabEvent(index){
                const $listTab = this.$refs.listTab;
                const $listTabBox = this.$refs.listTabBox;
                let $listTabItem = $listTab.querySelectorAll('li');
                let $listTabBoxItem = $listTabBox.querySelectorAll('.data_list_box');
                for(var i=0 ; i < $listTabItem.length ; i ++ ){
                    $listTabItem[i].classList.remove("active");
                    $listTabBoxItem[i].classList.remove("active");
                }
                $listTabItem[index].classList.add("active");
                $listTabBoxItem[index].classList.add("active");
            },

            // -------- DATE PICKER -----
            datePicToggle(){
                const vm = this;
                const _display = vm.$refs.calenderBox.style.display;
                const _startDateBox = vm.$refs.startDateBox.style.display;
                
                if(_display == 'block'){
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'none';
                    vm.picColor.startStatus = false;
                    vm.picColor.endStatus = false;

                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle.nextElementSibling.style.display = 'block';
                    if(_startDateBox == 'block'){
                        vm.picColor.startStatus = true;
                        vm.picColor.endStatus = false;
                    }else if( _startDateBox == 'none'){
                        vm.picColor.startStatus = false;
                        vm.picColor.endStatus = true;
                    }
                }
            },

            datePicToggle2(){
                const vm = this;
                const _display = vm.$refs.calenderBox2.style.display;
                const _startDateBox = vm.$refs.startDateBox2.style.display;
                
                if(_display == 'block'){
                    vm.$refs.datePicToggle2.nextElementSibling.style.display = 'none';
                    vm.picColor.startStatus = false;
                    vm.picColor.endStatus = false;

                }else if(_display == 'none'){   
                    vm.$refs.datePicToggle2.nextElementSibling.style.display = 'block';
                    if(_startDateBox == 'block'){
                        vm.picColor.startStatus = true;
                        vm.picColor.endStatus = false;
                    }else if( _startDateBox == 'none'){
                        vm.picColor.startStatus = false;
                        vm.picColor.endStatus = true;
                    }
                }
            },

            onDatePic(flag){
                if(flag == 'S'){   
                    if(window.selectDate.startDate !== null ){
                        this.picStartDate = window.selectDate.startDate;
                    }
                    
                }else if(flag == 'E'){
                    if(window.selectDate.picEndDate !== null ){
                        this.picEndDate = window.selectDate.endDate;
                    }
                }
            },
            pickerNext(){
                this.$refs.startDateBox.style.display = 'none';
                this.$refs.endDateBox.style.display = 'block';

                this.picColor.startStatus = false;
                this.picColor.endStatus = true;
                
            },
            pickerPrev(){
                this.$refs.startDateBox.style.display = 'block';
                this.$refs.endDateBox.style.display = 'none';

                this.picColor.startStatus = true;
                this.picColor.endStatus = false;
            },

            onDatePic2(flag){
                if(flag == 'S'){   
                    if(window.selectDate.startDate !== null ){
                        this.modalPicStartDate = window.selectDate.startDate;
                    }
                    
                }else if(flag == 'E'){
                    if(window.selectDate.picEndDate !== null ){
                        this.modalPicEndDate = window.selectDate.endDate;
                    }
                }
            },
            pickerNext2(){
                this.$refs.startDateBox2.style.display = 'none';
                this.$refs.endDateBox2.style.display = 'block';

                this.picColor.startStatus = false;
                this.picColor.endStatus = true;
                
            },
            pickerPrev2(){
                this.$refs.startDateBox2.style.display = 'block';
                this.$refs.endDateBox2.style.display = 'none';

                this.picColor.startStatus = true;
                this.picColor.endStatus = false;
            },
            // ------------

            // ----- click 
            clickToggle() {
                const toggle = !this.infoToggle;
                this.infoToggle = toggle;
            },

            hideUtils() { // 부가기능 - 보이기 숨기기
                if(this.InferenceUtil == true) {
                    this.InferenceUtil = false;
                } else if(this.InferenceUtil == false){
                    this.InferenceUtil = true;
                }
            },
            async clipCoppy() { // 공유하기
                let dummy = document.createElement("textarea");
                document.body.appendChild(dummy);
                try {
                    const url = window.document.location.href;
                    dummy.value = url;
                    dummy.select();
                    document.execCommand("copy");
                    document.body.removeChild(dummy);
                    alert('url copy success')
                } catch(error) {
                    console.error(error);
                } 
            },

            async clickWithdrawal() {
                if ( this.manageRole ) {
                    const result = confirm("탈퇴 처리 하시겠습니까?");
                    
                    if ( result ) {
                        let statusCode = "";
                        const memberId = this.memberId;
                        const response = await this.deleteMember(memberId);
                        try {
                            statusCode = response['status'];
                        } catch (error) {
                            statusCode = 500;
                        }
    
                        if ( statusCode === 204 || statusCode === "204" ) {
                            this.goPage();
                        }
                    }
                }
            },

            clickSearch() {
                const startDt = this.picStartDate;
                const endDt = this.picEndDate;

                // filter 확인 해서 제거
                this.removeAllFilter();

                this.setUserHstList(startDt, endDt);
            },

            removeAllFilter() {
                const $filterItem = this.$refs.filterItem;
                const $filterInputWrap = this.$refs.filterInputWrap;

                if ( common.nullCheck($filterItem) !== '' ) {
                    this.$refs.filterAllBtn.style.display = "none";
                    this.filterList = [];
                    this.filterAction(true);

                    const list = $filterInputWrap.querySelectorAll('[data-id]');
                    list.forEach(item => {
                        item.classList.remove("disable");
                        item.querySelector("input").disabled = false;
                    });
                }
            },

            async clickRegist() {
                if ( this.manageRole ) {
                    const memberId = this.memberId;
    
                    let discipline_from = "";
                    let discipline_to = "";
                    let statusCode = '';
    
                    switch(this.setCheck) {
                        case 'active':
                            statusCode = 'A'
                            break;
                        case 'disable':
                            statusCode = 'DS';
                            break;
                        case 'discipline':
                            statusCode = 'DC';
                            discipline_from = this.modalPicStartDate + " 00:00:00";
                            discipline_to = this.modalPicEndDate + " 00:00:00";
                            break;
                    }
    
                    const postData = {
                        "name": this.$refs.name.value,
                        "phone": this.$refs.contact.value,
                        "admin_status": statusCode,
                        "discipline_from": discipline_from,
                        "discipline_to": discipline_to,
                    };
    
                    let responseCode = "";
                    const response = await this.updateMember(memberId, postData);
                    try {
                        responseCode = response['data']['code'];
                    } catch (error) {
                        responseCode = 500;
                    }
    
                    if ( responseCode === 200 || responseCode === "200" ) {
                        this.goPage();
                    }
                }
            },

            onPageChanged(page) {
                this.currentPage = page;
                this.displayDataPerPage(this.dataList);
            },
            // ----- Filter
            // column filter
            filterToggle(){
                const $listFilter = this.$refs.listFilter;
                const $filterBox = this.$refs.filterListBox;
                const $filterBtn = this.$refs.filterAllBtn;
                const hasOpenClass = $filterBox.classList.contains("open");
                if(hasOpenClass){
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.remove("open");
                            $filterBtn.classList.remove("active");
                            $filterBtn.innerText = '전체보기'
                        },
                        height: '70px',
                        duration: .3
                    });
                }else{
                    gsap.to($listFilter, {
                        onStart: () =>{
                            $filterBox.classList.add("open");
                            $filterBtn.classList.add("active");
                            $filterBtn.innerText = '접기'
                        },
                        height: 'auto',
                        duration: .3
                    });
                }
                
            },
            async addFilter(event){
                // console.log("addFilter");
                
                const _this = event.target;
                const $thTag = _this.parentNode.parentNode;
                const $addBtn = $thTag.querySelector("button"); 
                const $input = $thTag.querySelector("input");
                // input 글자수
                const trimVal = $input.value.trim();

                // 컬럼 이름 가져오기
                const keyNameArr = _this.parentNode.parentNode.parentNode.parentNode.querySelector("tr:first-child").querySelectorAll(".btn_filter"); 
                let activeIndex = Array.from(keyNameArr).findIndex(element => element.classList.contains('active'));
                const keyName = String(keyNameArr[activeIndex].parentNode.textContent);                 


                const dataKeyArr = _this.parentNode.parentNode.parentNode.querySelectorAll('.input_box');
                const dataKeyindex = Array.from(dataKeyArr).findIndex(element => element.classList.contains('active'))
                const keyId = String(dataKeyArr[dataKeyindex].getAttribute('data-id')) 

                const obj = {};
                // console.log();
                // console.log(this.getAttribute('data-index'))
                // console.log($input);

                window.filterData.taget = _this.getAttribute('data-index')

                if(0 < trimVal.length){
                    obj["keyId"] = keyId;
                    obj["value"] = trimVal;
                    obj["keyName"] = keyName;
                    $input.value = "";
                    this.filterList.push(obj);
                    this.$refs.filterAllBtn.style.display = "block";
                    $addBtn.parentNode.classList.add("disable");
                    $addBtn.previousElementSibling.disabled = true;
                    this.filterAction();
                }
                
            },
            removeFilter(index) {
                const $filterItem = this.$refs.filterItem;
                const $filterInputWrap = this.$refs.filterInputWrap;
                const dataId = this.filterList[index].keyId.trim();
                // console.log(dataId);
                // console.log($filterInputWrap.querySelector(`[data-id='${dataId}']`).classList);
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).classList.remove("disable");
                $filterInputWrap.querySelector(`[data-id='${dataId}']`).querySelector("input").disabled = false;
                this.filterList.splice(index, 1);
                if($filterItem.length <= 1){
                    this.$refs.filterAllBtn.style.display = "none";
                }
                this.filterAction(true);
            },
            setActive(event) {
                const _this = event.target;
                const $filterDiv = _this.parentNode.parentNode.parentNode.querySelectorAll("div");
                const $filterIcon = _this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                
                for(var i = 0 ; i < $filterDiv.length ; i ++){
                    $filterDiv[i].classList.remove("active");
                }
                _this.parentNode.classList.add("active");
                
                // filter icon 
                let activeIndex = Array.from($filterDiv).findIndex(element => element.classList.contains('active'));
                
                for(var j = 0 ; j < $filterIcon.length ; j ++){
                    $filterIcon[j].classList.remove("active");
                }
                $filterIcon[activeIndex].classList.add("active");

            },

            displayDataPerPage(rowData) {
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                this.dataListSliced =  rowData.slice(startIndex, endIndex);
            },
            filterAction(remove){
                const vm = this;
                // 필터 리스트
                const _filterLsit =  vm.filterList;
                // 테이블 리스트
                let _tableList = vm.dataList;
                if(vm.isfilter){
                    vm.filterDataList.origin = _tableList;
                    vm.isfilter = false;
                }
                
                
                if(_filterLsit.length > 0){
                    const lodash = require('lodash');
                    
                    // 필터 삭제
                    if(remove){
                        let _filterDataListOrign = [];
                        vm.filterDataList.origin.forEach(item => {
                            _filterDataListOrign.push(item);
                        });

                        for( var j = 0 ; j < _filterLsit.length; j++){

                            const searchString = common.getUpper(_filterLsit[j].value);
                            const regexPattern = new RegExp(searchString);

                            // _tableList > 검색 1 리트스 > 검색 2 리스트

                            const keyid = _filterLsit[j].keyId;
                            const filteredData = lodash.filter(_filterDataListOrign, (item) => regexPattern.test(common.getUpper(item[keyid])));
                            vm.dataList = filteredData;
                            _filterDataListOrign = filteredData;
                        }
                    // 필터 추가
                    }else{
                        for( var i = 0 ; i < _filterLsit.length; i++){

                            const searchString = common.getUpper(_filterLsit[i].value);
                            const regexPattern = new RegExp(searchString);

                            // _tableList > 검색 1 리트스 > 검색 2 리스트
                            
                            const keyid = _filterLsit[i].keyId;
                            const filteredData = lodash.filter(_tableList, (item) => regexPattern.test(common.getUpper(item[keyid])));
                            vm.dataList = filteredData;
                        }
                    }
                    
                    // console.log("필터 동작 시작");
                }else{
                    vm.dataList = vm.filterDataList.origin;
                }
                vm.currentPage = 1;
                vm.totalItems = vm.dataList.length;
                vm.displayDataPerPage(vm.dataList);
            },
            showFilter(event){
                const _this = event.target;
                
                const $filterBtn = _this.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
                const $filterWrap = document.querySelector("#filterWrap");
                const $filterInput = $filterWrap.querySelectorAll(".filter_input");

                const hasShowClass = $filterWrap.classList.contains("show");
                const hasActiveClass = _this.classList.contains("active");
                
                for(var i = 0 ; i < $filterBtn.length ; i ++){
                    $filterBtn[i].classList.remove("active");
                }
                _this.classList.add("active");
                if(hasShowClass){
                    if(hasActiveClass){
                        $filterWrap.classList.remove("show");
                        _this.classList.remove("active");
                    }
                }else{
                    $filterWrap.classList.add("show");
                }
                let activeIndex = Array.from($filterBtn).findIndex(element => element.classList.contains('active'));
                // console.log(activeIndex);
                // input 
                for(var j = 0 ; j < $filterInput.length ; j ++){
                    $filterInput[j].querySelector("div").classList.remove("active");
                }
                // console.log($filterInput[activeIndex]);
                if(activeIndex > -1){
                    $filterInput[activeIndex].querySelector("div").classList.add("active");
                }
                
                
            },

            dateSelectChange() {
                switch(this.selectDate) {
                    case "7":
                        this.dateSet = 7;
                        break;
                    case "14":
                        this.dateSet = 14;
                        break;
                    case "30":
                        this.dateSet = 30;
                        break;
                    default:
                        this.dateSet = 1;
                        break;
                }

                const todayDate = new Date();

                todayDate.setDate(todayDate.getDate() - this.dateSet);
                const year1 = todayDate.getFullYear();
                const month1 = todayDate.getMonth() + 1;
                const day1 = todayDate.getDate();
                const formattedDate1 = year1 + '-' + (month1 < 10 ? '0' : '') + month1 + '-' + (day1 < 10 ? '0' : '') + day1;

                this.picStartDate = formattedDate1;
                window.selectDate.startDate = formattedDate1;
            },

            // --- modal 
            async showModal(){
                const $modal = this.$refs.modalWrap;
                const $modalModify = this.$refs.modalModify;
                $modal.style.display = "block";
                $modalModify.style.display = "block";

                const memberData = this.memberData;
                if(typeof memberData !== "undefined" && memberData !== null && memberData !== "") {
                    this.email = memberData.email;
                    this.name = memberData.name;
                    this.contact = memberData.contact;
                    // this.setCheck = common.nullCheck(memberData.status).toLowerCase();

                    if ( common.nullCheck(memberData.status) !== "" ) {
                        this.setCheck = memberData.status.toLowerCase();

                        if ( this.setCheck === 'discipline' ) {
                            const disciplineFrom = new Date(memberData.disciplineFrom);
                            const disciplineTo = new Date(memberData.disciplineTo);

                            this.modalPicStartDate = common.getFormDate(disciplineFrom);
                            this.modalPicEndDate = common.getFormDate(disciplineTo);
                        }
                    }
                }
            },
            closeModal(){
                const $modal = this.$refs.modalWrap;
                const $modalModify = this.$refs.modalModify;
                $modal.style.display = "none";
                $modalModify.style.display = "none";
            },

            // ----- API 
            async getMemberData(memberId) {
                try {
                    const response = await common.apiGet('/member/'+memberId );
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getProjectList(memberId) {
                try {
                    const response = await common.apiGet('/project/list?member-id=' + memberId);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async getUserHstList(memberId, startDt, endDt) {
                try {
                    const response = await common.apiGet(`/activitylog/member/${memberId}?start-date=${startDt}&end-date=${endDt}`);
                    return response.data.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
            async deleteMember(memberId) {
                let response = "";
                try {                    
                    response = await common.apiDelete(`/member/${memberId}`);
                } catch (error) {
                    response = error.response;
                    console.error('Error posting data:', error);
                }
                return response;
            },
            async updateMember(memberId, postData) {
                let response = "";
                try {                    
                    response = await common.apiPatch(`/member/${memberId}`, postData);
                } catch (error) {
                    response = error.response;
                    console.error('Error posting data:', error);
                }
                return response;
            },
        }
    }
</script>
<style scoped lang="scss">

.vision_data{
    .list_tab li > div{
        text-indent: 0 !important;
    }
    .edge_list_wrap{
        margin-top: 14px;
        .tab_data{
            height: auto;
            background-color: $whiteColor;
            border-radius: 10px;
            box-shadow: 0px 0px 10px #002B6833;
            box-sizing: border-box;
        }
        .data_list_box{
            width: 100%;
            height: 750px;
            padding: 20px;
            box-sizing: border-box;
        }
        .detail_info{
            .status_box{
                & > strong{
                    font-weight: $bold;
                }
            }
            .profile_img{
                width: 28.5%;
                height: 100%;
                border-right: 1px solid $line01Color;
                .img_box{
                    width: 330px;
                    height: 250px;
                    margin: 20px 20px 0 0;
                    img{
                        width: 100%;
                    }
                }
            }
            .manage_box{
                width: 30%;
                height: 100%;
                padding-left: 20px;
                border-right: 1px solid $line01Color;
                .txt_box{
                    margin-top: 40px;
                    .txt_title{
                        color: #6E7380;
                        margin-bottom: 10px;
                    }
                    .txt_data{
                        &>div{
                            display: inline-block;
                            font-size: 15px;
                            height: 25px;
                        }
                        .text_data_title{
                            width: 60px;
                            font-weight: $bold;
                            margin-right: 20px;
                        }
                    }
                }
            }
            .manager_box{
                width: 41.5%;
                height: 100%;
                padding-left: 20px;
                position: relative;
                .manager_team{
                    margin-top: 20px;
                    font-size: 15px;
                    span{
                        display: inline-block;
                        font-size: 15px;
                        color: #00B4ED;
                        font-weight: $bold;
                        margin-left: 5px;
                    }
                }
                .total_mem{
                    font-size: $fontSub;
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: #8A8F9C;
                    span{
                        color: $mainColor;
                        font-weight: $bold;
                        font-size: $fontSub;
                    }
                }
                .member_list_wrap{
                    height: 215px;
                    overflow-y: auto;
                    border-top: 1px solid $line02Color;
                    margin-top: 15px;
                    li{
                        width: 100%;
                        height: 45px;
                        position: relative;
                        box-sizing: border-box;
                        padding-left: 60px;
                        margin: 14px 0 0;
                        .img_box{
                            position: absolute;
                            top: 2px;
                            left: 0;
                            width: 40px;
                            height: 40px;
                            border-radius: 44px;
                            overflow: hidden;
                            img{
                                width: 100%;
                            }
                        }
                        .mbr_name{
                            line-height: 25px;
                            span{
                                font-size: 15px;
                                display: inline-block;
                                padding-right: 10px;
                                &:last-child{
                                    padding:0 10px 0;
                                    border-left: 1px solid $line01Color;
                                }
                            }
                        }
                        .mbr_email{
                            color: $placeHolderColor;
                        }
                        .representative{
                            width: 40px;
                            height: 25px;
                            color: #fff;
                            background-color: #00B4ED;
                            border-radius: 5px;
                            line-height: 25px;
                            text-align: center;
                            font-size: 13px;
                            position: absolute;
                            top: 50%;
                            right: 40px;
                            transform: translateX(-50%);
                        }
                    }
                }
                .terms_box{
                    margin-top: 20px;
                    box-sizing: border-box;
                    padding-right: 20px;
                    table{ 
                        width: 100%;
                        border-collapse: collapse;
                        th, td{
                            padding: 10px ;
                            border: 1px solid $blockColor;
                            margin: 2px;
                            text-align: center;
                            vertical-align: middle;
                        }
                        th{
                            font-weight: $medium;
                            background-color: $disable02Color;
                        }
                        td{
                            background-color: $line02Color;
                        }
                        .val{
                            color: $mainColor;
                            background-color: $whiteColor;
                        }
                    }
                }
            }
            .fleet_info{
                width: 100%;
                height: 400px;
                margin-top: 20px;
                position: relative;
                border: 1px solid $line01Color;
                box-sizing: border-box;
                border-radius: 10px;
                & > strong{
                    display: block;
                    margin: 20px 0 0 20px;
                }
                .total_mem{
                    font-size: $fontSub;
                    display: inline-block;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    color: #8A8F9C;
                    span{
                        color: $mainColor;
                        font-weight: $bold;
                        font-size: $fontSub;
                    }
                }
                .mando_table_wrap{
                    width: 100%;
                    height: 340px;
                    overflow-y: auto;
                    margin-top: 20px;
                    .mando_table{
                        
                        thead{
                            box-shadow: none !important;
                        }
                    }
                }
                

            }
            .float_box{
                width: 100%;
                height: 290px;
                & > div{
                    float: left;
                    box-sizing: border-box;
                }
                
            }
        }

        .data_list_box > .mando_table{
            position: relative;
            & > thead{
                tr{
                &:first-child{
                    th{
                        vertical-align: middle;
                        &:nth-child(1){
                            border-right: 1px solid #E5E9EF;
                            &::after{
                                display: none;
                            }
                        }
                        &:nth-child(2){
                            border-right: 1px solid #E5E9EF;
                            &::after{
                                display: none;
                            }
                        }
                        &:nth-child(3){
                            border-right: 1px solid #E5E9EF;
                            &::after{
                                display: none;
                            }
                        }
                        &:nth-child(4){
                            border-right: 1px solid #E5E9EF;
                            &::after{
                                display: none;
                            }
                        }
                        &:nth-child(5){
                            border-right: 1px solid #E5E9EF;
                            &::after{
                                display: none;
                            }
                        }
                    }
                }
                &:last-child{
                    th{
                        &:nth-child(2n){
                            border-right: 1px solid #E5E9EF;
                            &::after{
                                display: none;
                            }
                        }
                    }
                }
                }
            }
            tbody{
                .item{
                    & + .item_detail{
                        display: none;
                        &:hover{
                            background-color: $whiteColor;
                        }
                        
                    }
                    &.active + .item_detail{
                        display: table-row;
                        td{
                            background-color: #F8F9FA;
                        }
                        td > div {
                            width: 1236px;
                            
                            box-sizing: border-box;
                            padding: 0 20px;
                            .chart_box{
                                width: 100%;
                                height: 200px;
                                margin-top: 20px;
                                .chart{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .mando_table{
                                position: relative;
                                thead{
                                    background-color: #F8F9FA;
                                    box-shadow: none;
                                }
                                tr{
                                    background-color: #F8F9FA;
                                }
                                th, td{
                                    text-align: center;
                                    vertical-align: middle;
                                }
                                td{
                                    &:first-child{
                                        padding: 14px 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
        .prj_list_box{
            overflow: hidden;
            .table_box{
                width: 100%;
                height: 345px;
                border: 1px solid $line01Color;
                box-sizing: border-box;
                border-radius: 10px;
                position: relative;
                
                strong{
                    display: block;
                    margin: 20px 0 0 20px;
                    font-size: 15px;
                    font-weight: $bold;
                }
                .total_mem{
                    font-size: $fontSub;
                    display: inline-block;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    color: #8A8F9C;
                    span{
                        color: $mainColor;
                        font-weight: $bold;
                        font-size: $fontSub;
                    }
                }
                .mando_table_wrap{
                    height: 290px;
                    margin-top: 20px;
                }
                &.last_box{
                    margin-top: 20px;
                }
                
            }
        }
        .user_history{
            padding: 20px;
            position: relative;
            box-sizing: border-box;

            .list_filter_wrap{
                width: 1234px;
                box-shadow: none;
                border: 1px solid #E5E9EF;
            }

            strong{
                height: 45px;
                display: block;
                margin: 20px 0 0 20px;
                font-size: 15px;
                font-weight: $bold;
                line-height: 45px;
            }
            
            .mando_table_wrap{
                height: 460px;
                margin-top: 10px;
                .map_btn{
                    width: 76px;
                    margin: 0 auto;
                    cursor: pointer;
                    background-image: url($baseURL + "common/external_link.svg");
                    background-repeat: no-repeat;
                    background-position-x: right;
                    background-position-y: center;
                }
            }

            .user_hist_box{
                width: 100%;
                height: 620px;
                margin-top: 20px;
                position: relative;
                border: 1px solid #E5E9EF;
                box-sizing: border-box;
                border-radius: 10px;
                .user_hist_cnt{
                    font-size: 15px;
                    display: inline-block;
                    position: absolute;
                    top: 26px;
                    left: 20px;
                        .cont_num{
                            color: #00B4ED;
                            font-weight: 700;
                            font-size: 15px;
                        }
                    
                }
                .filter_box{
                    width: 100%;
                    height: 40px;
                    position: relative;
                    padding-top: 20px;
                    .date_filter{
                        width: 490px;
                        height: 40px;
                        top: 15px;
                        &::after{
                            display: none;
                        }
                    }
                    
                    .search_from_box{
                        width: 270px;
                        display: inline-block;
                        input{
                            width: 200px;
                            height: 40px;
                            border: 1px solid $disable02Color;
                            border-radius: 10px;
                            box-sizing: border-box;
                            padding: 0 15px;
                        }
                        .btn_send{
                            height: 40px;
                            padding: 0 15px;
                            border-radius: 10px;
                            line-height: 37px;
                            font-size: 15px;
                            margin-left: 10px;
                        }
                    }
                }
                .list_wrap{
                    .table_list{
                        box-shadow: none;
                        border: none;
                        
                    }
                }
            }
        }

    }    
}
#modalWrap{
    .modal_contents{
        width: 800px;
        height: 560px;
        padding: 30px 40px;
        box-sizing: border-box;
        .modal_title{
            display: block;
            font-size: $fontMenuTitle;
            font-weight: $bold;
            margin-bottom: 30px;
        }
        .col_box{
            position: relative;
            padding: 25px 0;
            border-top: 1px solid $line01Color;
            &.bn{
                border: none;
            }
            .col_title{
                width: 160px;
                height: 45px;
                display: inline-block;
                font-weight: $bold;
                line-height: 45px;
                vertical-align: middle;
            }
            input[type=text]{
                width: 550px;
                height: 45px;
                line-height: 45px;
                box-sizing: border-box;
                border: 1px solid #D5DAE3;
                border-radius: 10px;
                background-color: #fff;
                font-size: 15px;
                padding: 10px 15px;
            }
            input:disabled{
                background-color: $line02Color;
            }
        }
        .radio_box{
            .input_radio{
                display: inline-block;
                label{
                    margin-right: 15px;
                    .radio_icon{
                        width: 20px;
                        height: 20px;
                        margin-top: -2px;
                    }
                }
            }
            input[type=radio]:checked + label + .filter_box{
                display: block;
            }
        }
        .filter_box{
            width: 260px;
            height: 40px;
            position: absolute;
            top: 25px;
            right: 0;
            padding: 0;
            display: none;
            .date_filter{
                &::after{
                    display: none;
                }
                .date_period{
                    .calender_box{
                        top: -470px;
                    }
                }
            }
        }
        .btn_box{
            width: 100%;
            height: 45px;
            position: relative;
            .secession_btn{
                width: 120px;
                height: 45px;
                border-radius: 10px;
                background-color: $failedColor;
                color: #fff;
                position: absolute;
            }
            .check_wrap{
                text-align: center;
                .btn_check{
                    margin: 0 10px;
                }
            }
            .wbtn{
                border: 1px solid $disable02Color;
            }
        }
    }
}
</style>